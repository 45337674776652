import { useEffect, useState } from "react";
import BP2022Service from "../../services/user.service";
import { getCurrentURL, getParam, getParamsURL, rootPath } from "../../services/utils";
import SelectorComboBox from "../Utilidades/Selector/SelectorComboBox";
import Emergente from "../Fichas/FichaMUH/Apartados/Cismed/Emergente";

const AddEmpresa = (props) => {

    const [estado, setEstado] = useState(0);
    const [idEmpresa, setIdEmpresa] = useState(0);
    const [listaEmpresas, setListaEmpresas] = useState([]);

    const getEmpresa = () =>{
        setEstado(1);
        BP2022Service.getFormAddEmpresa()
        .then((response) => {
            if (response.data){
                var aux = response.data.Empresas;
                aux.splice(0,0,{Codigo:0, Valor:''});
                setListaEmpresas(aux);
                setEstado(2);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
            setEstado(2);
        });
    }

    useEffect (() =>{
        if (estado===0){
            getEmpresa();
        }
    },[]);


    const setEmpresa = () =>{
        setEstado(3);
        BP2022Service.addEmpresa(idEmpresa)
        .then((response) => {
            if (response.data){
                props.alAnadir(response.data);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
            setEstado(2);
        });
    }


    const cambiaEmpresa = (nuevoId) =>{
        setIdEmpresa(nuevoId);
    }

    const guardaEmpresa = () =>
    {
        setEmpresa();
    }


    const listaEmpresasCodValor = listaEmpresas.map(e => ({codigo: e.Codigo, valor: e.Valor}));

    const contenido = (
        (estado === 0) ?
            <div style={{height: '100%', width: '100%', cursor:'wait', overflowY: 'hidden',}}>
                <p className="fs fs15 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '30%'}}>Iniciando...</p>
            </div>
        :(estado === 1) ?
            <div style={{height: '100%', width: '100%', cursor:'wait', overflowY: 'hidden',}}>
                <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '35px'}}>Buscando información...</p>
            </div>
        :(estado === 2) ?
            <form class="content__results content__results__filters" id="main-filter" onSubmit={(e) => e.preventDefault()}>
                <p className="fw fw100 FlamaBook greyColor"
                    style={{marginBottom:'20px', marginTop:'0px'}}>
                    <span class="fs fs18">Empresa colaboradora:</span>
                    <span class="fs fs16">
                        <SelectorComboBox 
                            lista = {listaEmpresasCodValor} 
                            idHTML="SeAddEmpConf" 
                            valueSelected={idEmpresa} 
                            alCambiar={cambiaEmpresa} 
                            ancho="100%"
                            maxLengthNormal={42}
                        ></SelectorComboBox>
                    </span>
                </p>
                <div className="cover cover__buttons" style={{textAlign: 'right', paddingTop: '10px'}} >
                    <button className="button button__purple" onClick={(e) => guardaEmpresa()} style={{height:'40px', paddingTop:'12px'}}> Añadir empresa</button>
                </div>
            </form>
        :(estado === 3) ?
            <div style={{height: '100%', width: '100%', cursor:'wait', overflowY: 'hidden',}}>
                <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '35px'}}>Guardando datos...</p>
            </div>
        :
            <div style={{height: '100%', width: '100%', overflowY: 'hidden',}}>
                <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '30%'}}>Este paso no está implementado</p>
            </div>
    );

    return(
        <Emergente 
            contenido={contenido} 
            keyName={"addColaboradora"} 
            titulo={"Añadir empresa colaboradora"} 
            activo={true} 
            ocultaAspa = {false}
            alto = {'320px'}
            ancho = {'420px'}
            cerrar={props.alCerrar}
            hiddenOverflow = {true}
        ></Emergente>
    );
}

export default AddEmpresa;
