import { useEffect, useState } from "react";
import { AddClassNameToChildClassItems, buscaOptionEnSelectByValue, createElementFromHTML
    , deepEqual, elementIsOfClass, ExistsClassNameChildClassItems, GetAntecesorWhithClassName
    , GetLastSucesorWhithClassName, getSelectValues, GetSucesorWhithClassName
    , primerHijoConClase, RemoveClassNameToChildClassItems, scrollSiNoVisible } from "../../../../services/utils";

function SelectorEnfRel(props){
    const [propiedades, setPropiedades] = useState({
        lista: props.lista,
        valuesSelected: props.valuesSelected,
    })
    const lista = propiedades.lista;
    const [listavisible, setListaVisible] = useState([]) ;
    const valuesSelected = (propiedades.valuesSelected) ? propiedades.valuesSelected : [] ;
    const idHTML = props.idHTML; 
    const optionsHTML = listavisible.map(item => (
        valuesSelected.includes(item.codigo) ? 
        <option selected value={item.codigo}>{item.valor}</option>
        :<option value={item.codigo}>{item.valor}</option> )
    );
    const [epigrafe, setEpigrafe] = useState(3);
    const [currentEnf, setCurrentEnf] = useState(0);
    const [negado, setNegado] = useState(false);

    const estiloModuleSelect = (props.ancho ? {width: props.ancho} : {});

    const optionsDisplayed = listavisible.map(item => (
        valuesSelected.includes(item.codiog) ?
            <label class="labeloption active " data-value={item.codigo} onClick={(e) => ClickEnOpcion(e)}>{item.valor}</label>
            :<label class="labeloption " data-value={item.codigo} onClick={(e) => ClickEnOpcion(e)}>{item.valor}</label>
        ));

    const tipoFiltradoLista = (props.tipoFiltradoLista>=0) ? props.tipoFiltradoLista : 1;

    const clasesCoverSelect = (props.disabled ? "cover cover__select cover__select cover__select__border disabled" : "cover cover__select cover__select cover__select__border");


    const textPlaceholder = (lista && lista.length>0) ? lista[0].valor : '';

    var itemsSelected = [];

/*    
    for (var item of lista){
        var vs = valuesSelected.find(vsitem=> vsitem.codigo === item.codigo);
        if (vs){
            itemsSelected.push({codigo: item.codigo, valor: item.valor, epi: vs.epi});
        }
    }
*/
    for (var vs of valuesSelected){
        var item = lista.find(vsitem=> vsitem.codigo === vs.codigo);
        if (item){
            itemsSelected.push({codigo: item.codigo, valor: item.valor, epi: Math.abs(vs.epi), negado: (vs.epi<0)});
        }
    }


    const EpigrafeByCod = (epi) =>{
        var res = '';
        switch (epi){
            case 3:
                res = 'Indicado';
            break;
            case 7:
                res = 'Reacción adversa';
            break;
            case 13:
                res = 'Contraindicado';
            break;
            case 14:
                res = 'Precaución';
            break;
        }
        return res;
    }


    
    const HTMLImagen = <img class="arrow imagenTagEliminar" src="./img/close-tag.svg" id="imgBorrar" alt="eliminar" onClick={(e) => BorraItem(e) } />
    const HTMLTagsSelected = itemsSelected.map(item => 
                                <span className={(item.codigo===currentEnf) ? "tag active": "tag"} data-value={item.codigo} onClick={(e)=> SeleccionaItem(e) } >
                                    {item.valor}
                                    {HTMLImagen}
                                    <span class="extra-field">
                                        <span class="row-subfield" data-epi={item.epi} data-negado={item.negado ? 'S': 'N'}>
                                            {item.negado ? <span>NO </span> : <></>}
                                            {EpigrafeByCod(item.epi)}
                                        </span>
                                    </span>
                                </span>
                            );
 
    useEffect(() => {
        /*var setValores = false;
        setValores = ((!propiedades.lista) || (!valuesSelected))
        if (!setValores)
            setValores = */
        
        if (!propiedades.lista || !valuesSelected || !deepEqual(props.lista, propiedades.lista) || !deepEqual(props.valuesSelected, valuesSelected)){
            setListaVisible([]);
            setPropiedades({
                lista: props.lista,
                valuesSelected: props.valuesSelected,
            })
        }
        
    },  [props.lista, props.valueSelected]);

    const ClickEpigrafe  = (e) =>{
        var check = e.target;
        var value = parseInt(check.value);
        if (check.checked){
            if (currentEnf>0){
                if (!negado)
                    props.alUpdate(currentEnf, value);
                else
                    props.alUpdate(currentEnf, (-1) * value);
            }
            setEpigrafe(value);
        }            
    }

    const ClickNegado  = (e) =>{
        var check = e.target;
        var value = check.checked;
        if (currentEnf>0){
            if (!value)
                props.alUpdate(currentEnf, epigrafe);
            else
                props.alUpdate(currentEnf, (-1) * epigrafe);
        }
        setNegado(value);
    }


    const ClickEnCoverSelect  = (e) =>{
        if (!props.disabled){
            const item = e.target;        
            const itemContainer = GetAntecesorWhithClassName(item, 'custom-select-container');
            itemContainer.classList.toggle('active');
        }
    }

    const ClickEnOpcion  = (e) =>{
    	var option = e.target;
        SeleccionaOpcion(option);
    }

    const SeleccionaOpcion = (option) =>{
        var value = option.getAttribute("data-value");
        var html = option.innerHTML;

        var container = GetAntecesorWhithClassName(option, "custom-select-container");
        var select = primerHijoConClase(container,'custom-select');
        var input = GetSucesorWhithClassName(container, 'inputSelector');

        container.classList.remove("active");

        //RemoveClassNameToChildClassItems(container, 'dropdown__radios', 'labeloption', 'active');
        RemoveClassNameToChildClassItems(container, 'dropdown__radios', 'labeloption', 'preselected');
        RemoveClassNameToChildClassItems(container, 'dropdown__radios', 'labeloption', 'hide');
        option.classList.add("active");

        if (input){
            input.value='';
        }

        if(!(ExistsClassNameChildClassItems(container,'dropdown','labeloption','hide') || ExistsClassNameChildClassItems(container,'dropdown','labeloption','active')))
            RemoveClassNameToChildClassItems(container,'dropdown',null,'show');

        if (!props.idEsStr)
            value = parseInt(value);
        if (props.alUpdate){
            if (!negado)
                props.alUpdate(value,epigrafe);
            else
                props.alUpdate(value, (-1) * epigrafe);
        }
           
    }

    const remove_accents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } 
  
    const KeyUpInput = (e) =>{
		var input = e.target;
        const container = GetAntecesorWhithClassName(input, 'custom-select-container');
        var texto = input.value;
        FiltraOpciones(container,texto);
    }

    const FiltraOpciones = (container, texto) =>{
        var search = remove_accents(texto.toLowerCase());
        var search2 = " " + search;

    	if(search != ""){
            var nuevalista = lista.slice(1);
            var textoDividido = search.split(' ');
            for(var i=0; i<textoDividido.length ; i++){
                var search1 = textoDividido[i];
                var search2 = ' ' + textoDividido[i];
                switch (tipoFiltradoLista){
                    case 0:
                        nuevalista = nuevalista.filter(item => remove_accents(item.valor.toLowerCase()).indexOf(search1)===0);
                        break;
                    case 1: 
                        nuevalista = nuevalista.filter(item => ((remove_accents(item.valor.toLowerCase()).indexOf(search1) === 0) || (remove_accents(item.valor.toLowerCase()).indexOf(search2)>0)));
                        break;
                    case 2:
                        nuevalista = nuevalista.filter(item => ((remove_accents(item.valor.toLowerCase()).indexOf(search1) === 0) || (item.codigo.toLowerCase().indexOf(search1) === 0)));
                        break;
                    case 3:
                        nuevalista = nuevalista.filter(item => ((remove_accents(item.valor.toLowerCase()).indexOf(search1) === 0) || (item.codigo.toLowerCase().indexOf(search1) === 0) || (remove_accents(item.valor.toLowerCase()).indexOf(search2)>0)));
                        break;
                }
            }
            setListaVisible(nuevalista);
        }else{
            setListaVisible([]);
        }
    }


    const KeyDownSelector = (e) =>{
        const container = GetAntecesorWhithClassName(e.target, 'custom-select-container');
        const ddown = GetSucesorWhithClassName(container, 'dropdown__radios');
        const input = primerHijoConClase(container, 'inputSelector');
       
        if (ddown.classList.contains('show')){
		    var keyCode = e.keyCode;
            var miItem;
            miItem = GetSucesorWhithClassName(ddown,'preselected');
            if (!miItem){
                miItem = GetSucesorWhithClassName(ddown,'active');
            }
            if (keyCode === 27){
                ddown.classList.remove('show');
                input.value = '';
            }

            if (keyCode ===38){
                //UP
                if (miItem)
                    miItem = miItem.previousSibling;
                if (!miItem)
                    miItem = GetLastSucesorWhithClassName(ddown,'labeloption')
                /*while (miItem && miItem.classList.contains('hide')){
                    miItem = miItem.previousSibling;
                    if (!miItem){
                        miItem = GetLastSucesorWhithClassName(ddown,'labeloption')
                    }
                }*/
                if (miItem){
                    scrollSiNoVisible(ddown,miItem);
                    RemoveClassNameToChildClassItems(ddown,'labeloption',null,'preselected');
                    miItem.classList.add('preselected');
                }
            }
            if (keyCode === 40){
                //DOWN
                if (miItem)
                    miItem = miItem.nextSibling;
                if (!miItem)
                    miItem = GetSucesorWhithClassName(ddown,'labeloption')
                /*while (miItem && miItem.classList.contains('hide')){
                    miItem = miItem.nextSibling;
                    if (!miItem){
                        miItem = GetSucesorWhithClassName(ddown,'labeloption')
                    }
                }*/
                if (miItem){
                    scrollSiNoVisible(ddown,miItem);
                    RemoveClassNameToChildClassItems(ddown,'labeloption',null,'preselected');
                    miItem.classList.add('preselected');
                }
            }
            if (keyCode === 13){
                e.preventDefault();
                e.stopPropagation();
                miItem =  GetSucesorWhithClassName(ddown,'preselected')
                if (miItem){
                    SeleccionaOpcion(miItem);
                }
            }
        }
    }

    const BorraItem = (e) =>{
        var img = e.target;
        const tag = GetAntecesorWhithClassName(img,'tag');
        var value = tag.getAttribute("data-value");
        setCurrentEnf(0);
        if (props.alBorrar){
            props.alBorrar(parseInt(value));
        }
        
    }

    const SeleccionaItem = (e) =>{
        var img = e.target;
        if (!img.classList.contains('imagenTagEliminar')){
            const tag = GetAntecesorWhithClassName(img,'tag');
            var value = tag.getAttribute("data-value");
            var itemepi = GetSucesorWhithClassName(tag,'row-subfield');
            var codEpi = parseInt(itemepi.getAttribute('data-epi'));
            var esNegado = (itemepi.getAttribute('data-negado')==='S');
            setCurrentEnf(parseInt(value));
            setEpigrafe(codEpi);
            setNegado(esNegado);
        }
    }

    const dropdownclases = (listavisible.length>0) ? "dropdown dropdown__radios show" : "dropdown dropdown__radios"


    return (
        <div class="cover cover__filters cover__filters__lab cover__filters__padTop" style={{justifyContent: 'flex-start'}}>
            <div className="module module__filters" style={{paddingRight:'25px'}}>
                <div class="search search__ratios"  style={estiloModuleSelect}>
                    <div class="selects" >
                        <div class="custom-select-container search target" id="custom-select-" onKeyDown={(e) => KeyDownSelector(e)}>
                            <div class="cover cover__search">
                                <img class="lupa" src="./img/ficha/lupaPurple.svg" />
                                <input type="text" placeholder={textPlaceholder} className="inputSelector" onKeyUp={(e) => KeyUpInput(e)}/>                        
                            </div>
                            <select class="custom-select" id={idHTML} style={{display:"none"}}  data-target='tags-1' multiple>
                                {optionsHTML}
                            </select>
                            <div class={clasesCoverSelect} onClick={(e) => ClickEnCoverSelect(e)}>
                                <div class="select-label" >
                        
                                </div>
                            </div>
                            <div class={dropdownclases}>
                                {optionsDisplayed}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tags" id="tags-1" >
                    {HTMLTagsSelected}
                </div>
            </div>
            <div class="module module__filters" style={{minWidth:'140px'}}>
                <div class="radios radios__full radio_subfield">
                    <label>
                        <input type="checkbox" name="negado" checked={negado}  onClick={(e) => ClickNegado(e)}/>
                        NO
                    </label>
                    <label>
                        <input type="radio" name="relacion" value={3} checked={epigrafe===3}  onClick={(e) => ClickEpigrafe(e)}/>
                        Indicado
                    </label>
                    <label>
                        <input type="radio" name="relacion" value={13} checked={epigrafe===13} onClick ={(e) => ClickEpigrafe(e)}/>
                        Contraindicado
                    </label>
                    <label>
                        <input type="radio" name="relacion" value={14} checked={epigrafe===14}  onClick={(e) => ClickEpigrafe(e)}/>
                        Precaución
                    </label>
                    <label>
                        <input type="radio" name="relacion" value={7} checked={epigrafe===7} onClick={(e) => ClickEpigrafe(e)}/>
                        Reacciones adversas
                    </label>
                </div>
            </div>

        </div>
    );
}

export default SelectorEnfRel;

