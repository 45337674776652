import GrupoPolimorfismos from "./GrupoPolimorfismos";

const FarmacogeneticaBioPA = (props) => {
    const biomarcador = props.biomarcador;
    const gruposPolimorfismos = biomarcador.Polimorfismos.map(item => 
        <GrupoPolimorfismos grupo={item} 
                            ultimaFila={(biomarcador.Polimorfismos.indexOf(item)===(biomarcador.Polimorfismos.length-1))}></GrupoPolimorfismos>);

    var contenido = (
        <div>
            <div class="cover cover__table">
                <h4 class="fs fs16 fw fw800 greyColor2 title">Biomarcador</h4>
                <div class="table" style={{width:'100%'}} >
                    <div class="table__row table__row__header table__row__header__noBg fs fs12 fw fw600 greenColor uppercase">
                        <div class="column" >
                            <p>Nombre</p>
                        </div>
                        <div class="column" >
                            <p>Tipo</p>
                        </div>
                    </div>
                    <div class="table__row table__row__normal fw fw300 uppercase">
                        <div class="column fs fs12 fw fw300 FlamaBook" >
                            <p>{biomarcador.Nombre}</p>
                        </div>
                        <div class="column fs fs12 fw fw300 FlamaBook" >
                            <p>{biomarcador.Tipo}</p>
                        </div>
                    </div>
                    {(biomarcador.Observaciones && biomarcador.Observaciones !== '') ?
                        <div>
                            <div class="table__row table__row__normal fw fw300 uppercase">
                                <div class="column fs fs12 fw fw600 greenColor" >
                                    <p>Observaciones</p>
                                </div>
                            </div>
                            <div class="table__row table__row__normal">
                                <div class="column fs fs14 fw fw300 FlamaBook blackColor" >
                                    <p class="alignLeft" >{biomarcador.Observaciones}</p>
                                </div>
                            </div>
                        </div>
                    :
                        <div></div>
                    }
                </div>
            </div>
            <div class="cover cover__table">
                <h4 class="fs fs16 fw fw800 greyColor2 title">Polimorfismos del biomarcador</h4>
                <div class="table"  style={{width:'100%'}} >
                    <div class="table__row table__row__header table__row__header__noBg fs fs12 fw fw600 greenColor uppercase">
                        <div class="column" >
                            <p>Polimorfismo</p>
                        </div>
                        <div class="column" >
                            <p>Fenotipo metabolizador</p>
                        </div>
                        <div class="column" >
                            <p>Efecto farmacológico</p>
                        </div>
                    </div>
                    {gruposPolimorfismos}
                </div>
            </div>
        </div>
    );
    return (
        <div>
            <h3 class="title fs fs16 fw fw600 greenColor">{props.titulo}</h3>
            {contenido}
        </div>
    )
}
export default FarmacogeneticaBioPA;
