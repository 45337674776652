import ItemMenuSecundarioMorado from "./ItemMenuSecundarioMorado"

const MenuSecundarioMorado = (props) => {
    const clasesItemUL = "list list__1 list__purple child__" + props.idx.toString() + " child__menu1"
    const items = props.lista.map(item => <ItemMenuSecundarioMorado 
        item={item}
        idxPadre = {props.idx} 
        idx={props.lista.indexOf(item)+1}
        cambiaPagina = {props.cambiaPagina}
        perfil = {props.perfil}> </ItemMenuSecundarioMorado>)
    return (
        <ul class={clasesItemUL}>
            {items}
        </ul>
    )
}
export default MenuSecundarioMorado;
