const FilaTitulosIdent = (props) => {
    const fila = props.fila;

    const htmlFila = fila.map(item=>
            <div class="column" >
                <p>{item}</p>
            </div>
    );

    const clasesDiv = (props.idxBloque === 0 ? "table__row table__row__header table__row__header__noBg fs fs12 fw fw600 greenColor uppercase" : "table__row table__row__header2 table__row__header__noBg fs fs12 fw fw600 greenColor uppercase")

    return (
        <div class={clasesDiv} style={{borderTopWidth: '1px !important'}}>
            {htmlFila}
        </div>
    )
}
export default FilaTitulosIdent;
