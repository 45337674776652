import AcordeonN2 from "./AcordeonN2/AccordeonN2";

function RamaSecundaria(props){
    const item = props.item; 
    const codigo = item.Codigo
    const titulo = (props.ocultaCodigo) ? item.Valor : item.Valor.substring(codigo.length).trim();
//    const contenidoHTML = item.hijos.map(hijo => <div item = {hijo}>{hijo.Valor}</div> ) 
//    const contenidoHTML = (item.hijos) ? item.hijos.map(hijo => <p item = {hijo}>{hijo.Valor}</p> ) : <div></div>
    const contenidoHTML =  (item.hijos) ? item.hijos.map(hijo => <RamaSecundaria item = {hijo} cambiaSeleccion = {props.cambiaSeleccion} ocultaCodigo = {props.ocultaCodigo} valueSelected={props.valueSelected}></RamaSecundaria> ) : <div></div>
    const clasDiv = 
    ((item.abierta && item.hijos && item.hijos.length>0) ? 
        "accordion__mas active" 
    : (item.abierta) ?
        "accordion__mas accordion__mas__only active" 
        : (item.hijos && item.hijos.length>0) ?
            "accordion__mas" 
        : 
            "accordion__mas accordion__mas__only"            
    );

    return (
            <AcordeonN2 titulo={titulo} codigo={codigo} contenido={contenidoHTML} checked = {item.checked} clasDiv = {clasDiv} cambiaSeleccion = {props.cambiaSeleccion} ocultaCodigo = {props.ocultaCodigo} valueSelected={props.valueSelected}></AcordeonN2>
    )
}
export default RamaSecundaria;
