import { AddClassNameToClassItems } from "../../../../../services/utils";
import Emergente from "./Emergente";
import GraficaCismed from "./GraficaCismed";
import SemaforosCismed from "./SemaforosCismed";
import TablaFParticipantes from "./TablaFParticipantes";

const CismedOK = (props) => {
    const datosCismed = props.datosCismed;
    const modalTitulo = "CRITERIOS CisMED.";
    const modalContent = (
        <div style={{textAlign: 'justify', textJustify: 'interWord'}}>
            <p>
                Esta información ha sido elaborada a partir de la información disponible en CisMED, 
                una infraestructura informática de comunicación que permite a la Organización 
                Farmacéutica Colegial y a las Administraciones sanitarias disponer de información 
                sobre la disponibilidad efectiva de medicamentos, a partir de los datos suministrados 
                por las farmacias adheridas voluntariamente, con la finalidad de ayudar tanto a la 
                profesión farmacéutica como en la adopción por las Administraciones de decisiones en el 
                marco de sus competencias que faciliten la continuación de los tratamientos a los pacientes.
            </p>
            <p>
                La Organización Farmacéutica Colegial recomienda utilizar esta información como una 
                herramienta de ayuda, pero el usuario siempre deberá valorarla con sentido crítico y 
                consultando también otras fuentes de información. Asimismo, la Organización Farmacéutica 
                Colegial no se hace responsable de las consecuencias que pudieran derivarse de la utilización 
                de la información, al estar elaborada con base en criterios opinables y para la exclusiva 
                finalidad de ayudar a la profesión farmacéutica y a las Administraciones sanitarias. 
                El usuario es el único responsable de las infracciones en las que pueda incurrir o de los 
                perjuicios que pueda causar por la utilización incorrecta de esta información, quedando la 
                Organización Farmacéutica Colegial exonerada de cualquier clase de responsabilidad que se 
                pudiera derivar por dicho uso.
            </p>
            <h3 style={{fontWeight: 'bold', marginBottom: '10px', marginTop: '20px'}}>CRITERIOS CISMED</h3>
            <p>                
                CisMED analiza la información comunicada por las farmacias sobre faltas de
                suministro de los medicamentos autorizados. El periodo de análisis es semanal,
                de lunes a domingo.
            </p>
            <p>
                Para cada provincia, y en base al número de farmacias informantes en CisMED,
                se establece un valor crítico de farmacias para generar una señal de falta de
                suministro diario. Si el número de farmacias que comunican una falta de
                suministro en un día concreto supera ese valor crítico, se genera una alerta en
                dicho día.
            </p>
            <p>
                En base al número de alertas diarias en el suministro del medicamento que se
                produzcan en la semana y en la provincia, se clasifican los medicamentos en los
                siguientes grupos:              
            </p>
            <ul>
                <li>
                    <span style={{fontWeight: 'bold'}}>Medicamentos con incidencias por faltas de suministro</span>: 
                    medicamentos que 
                    durante la semana observada han generado 3 o más alertas diarias en la
                    provincia.
                </li>
                <li>
                    <span style={{fontWeight: 'bold'}}>Medicamentos en observación por faltas de suministro</span>: 
                    medicamentos que
                    durante la semana observada han generado menos de 3 alertas diarias en la
                    provincia.
                </li>
                <li>
                    <span style={{fontWeight: 'bold'}}>Medicamentos sin incidencias por falta de suministro</span>: 
                    medicamentos que
                    durante la semana observada no han generado alertas diarias en la provincia.
                </li>
            </ul>
            <p>
                En el gráfico de barras se muestra el porcentaje de farmacias con faltas de
                suministro en la última fecha disponible en distintos ámbitos geográficos:
                Nacional, Autonómico y Provincial.
            </p>
            <p>
                En BOT PLUS se incluye esta información procedente de CisMED para aquellos
                medicamentos para los que la AEMPS no informa de un problema de suministro
                oficial.
            </p>
            <p>
                El Consejo General pone la información recogida a través de CisMED a
                disposición de otros colectivos sanitarios e instituciones, todo ello con el fin de
                que pueda ser aprovechada en beneficio del paciente y del sistema sanitario.
            </p>
            <p>
                Así, la información generada a través de CisMED, es compartida con el Ministerio
                de Sanidad y a la Agencia Española de Medicamentos y Productos Sanitarios
                (AEMPS) que es quien recoge la información oficial sobre los problemas de
                suministro existentes.
            </p>
        </div>
    );

    const modalKeyName="ModalCismedInfo"
    const AbreEmergenteCismed = () => {
        AddClassNameToClassItems(modalKeyName, null, 'active');
    }

    return (
        <div class="infoContent" >
            <Emergente contenido={modalContent} keyName={modalKeyName} titulo={modalTitulo} ></Emergente>
            <TablaFParticipantes datosCismed={datosCismed} abreEmergenteCismed={AbreEmergenteCismed}></TablaFParticipantes>
            <GraficaCismed datosCismed={datosCismed}></GraficaCismed>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <SemaforosCismed datosCismed={datosCismed}></SemaforosCismed>
        </div>
    )
}
export default CismedOK;

