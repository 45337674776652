function ItemResumenFiltros(props){
    const item = props.item;
    return (
        <span class="tag" data-key="tipo-1">
            {item.descripcion}
            <img class="arrow" src="./img/close-tag.svg" alt="eliminar" onClick={e => props.borraFiltro(item.idFiltro, item.valor)} />
        </span>
    );
}

export default ItemResumenFiltros;
