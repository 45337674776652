import FechaHistorico from "./FechaHistorico";

const Historico = (props) => {
    const datosHistorico = props.datosHistorico;
    const htmlHistorico = datosHistorico.map(fecha => <FechaHistorico fecha={fecha} esECoH={props.esECoH}></FechaHistorico>)
    return (
        <div class="infoContent" >
            {htmlHistorico}
        </div>
    )
}
export default Historico;
