const Sinonimias = (props) => {
    const sinonimias = props.sinonimias;
    const HTMLSinonimias = sinonimias.map(sino => <li>{sino}</li>);
    return (
        <div class="infoContent" >
            <div class="cover cover__text">
                <ul class="list list__normal fw fw400 FlamaBook" >
                    {HTMLSinonimias}
                </ul>
            </div>
        </div>
    )
}
export default Sinonimias;
