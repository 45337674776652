import React, { useState, useEffect, createRef } from "react";
import CabeceraResultados from "../../Resultados/CabeceraResultados/CabeceraResultados";
import BP2022Service from "../../../services/user.service";
import MenuIzquierda from "../FichaMUH/MenuIzquierda/MenuIzquierda";
import ContenedorApartados from "../FichaMUH/Apartados/ContenedorApartados";
import MensajesEmergentes from "../FichaMUH/MensajesEmergentes";
import { AnadeApartado, baseURLPictogramas, compruebaAnclas, RemoveClassNameToClassItems } from "../../../services/utils";
import LoadingPopup from "../../Resultados/Loading.Popup";
import InfoPrincipalExt from "./InfoPrincipalExt";
import MargenDerechoExt from "./MargenDerechoExt";

const FichaEXT = (props) => {
    const id = props.infoPagina.id;
    const [ficha, setFicha] = useState(null);
    const [anclas, setAnclas] = useState([]);
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        if ((ficha==null) || (ficha.Id !== id))
        {
            descargaFicha();
        }        
        else if (refresh==1){
            descargaCompos(ficha.Id, ficha.IdComposicionPor, 0);
        }
        else if (refresh==2){
            setRefresh(prev => prev + 2);
            /*descargaAdvertencias(ficha.Id);*/
        }
        else if (refresh==3){
            setRefresh(prev => prev + 1);
            /*descargaInformacionAdicional(ficha.Id)*/
        }
        else if (refresh == 18){
        }
    })

    const descargaFicha = () => {
        BP2022Service.getFichaEXT(id)
        .then((response) => {
            RemoveClassNameToClassItems('lightbox__popup',null,'active');
            var auxFicha = response.data;
            compruebaAnclas(auxFicha.Apartados,anclas);
            auxFicha.CodTipoRegistro="EXT";
            auxFicha.TipoRegistro="Medicamento extranjero"
            setFicha(auxFicha);
            setRefresh(1);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
    
    const descargaCompos = (miId, miIdCPor, miIdFF) => {
        BP2022Service.getComposM(miId,miIdCPor,miIdFF)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Composiciones){
                    if (response.data.Composiciones.length>0){
                        AnadeApartado({Titulo: "Composición",  Ancla:"COMP"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosComposicion = response.data;
                    }
                }
                setRefresh(prev => prev + 1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
    const descargaAdvertencias = (miId) => {
        BP2022Service.getAdvertenciasMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Advertencias){
                    if (response.data.Advertencias.length>0){
                        AnadeApartado({Titulo: "Advertencias",  Ancla:"ADV"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosAdvertencias = response.data;
                    }
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
   
    const descargaInformacionAdicional = (miId) => {
        BP2022Service.getInformacionAdicionalMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Información adicional",  Ancla:"DOCS"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.DatosInfoAdicional = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    if (ficha !== null) 
    {
        return (
            <div>
                <CabeceraResultados infoPagina={props.infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras}></CabeceraResultados>
                <InfoPrincipalExt ficha={ficha} cambiaPagina={props.cambiaPagina} ></InfoPrincipalExt>
                {
                    (ficha.MensajesEmergentes && ficha.MensajesEmergentes.length>0) ?
                        <MensajesEmergentes mensajes={ficha.MensajesEmergentes} id={ficha.Id}></MensajesEmergentes>
                    :
                        <div></div>
                }
                <section class="medicamento-infoGeneral">
                    <div class="container flex flex--justify-center">
                        <div class="contenido f-f-Flama-Book">
                            {(ficha.Apartados && ficha.Apartados.length>0) ?
                                <MenuIzquierda apartados={ficha.Apartados} activo={ficha.Apartados[0].Ancla} anclas={anclas}></MenuIzquierda>
                            : 
                                <div></div>
                            }
                            <ContenedorApartados cambiaPagina={props.cambiaPagina} ficha={ficha} anclas={anclas}></ContenedorApartados> 
                            <MargenDerechoExt cambiaPagina={props.cambiaPagina} ficha={ficha} ></MargenDerechoExt>

                        </div>
                    </div>
                </section>
            </div>
        )
    }
    else 
        return (
            <div>
                <LoadingPopup></LoadingPopup>
            </div>
        )
}
export default FichaEXT;
