import React, { useState } from "react";
import BusqMedsService from "../../../../services/user.service";
import SugerenciasService from "../../../../services/sugerencias.service";
import Sugerencias from "../../../Utilidades/Sugerencias/Sugerencias";

  const FormBusqueda = (props) => {  
    const [texto, setTexto] = useState("");
    const [loading, setLoading] = useState(false);
    const [listaSugerencias, setListaSugerencias] = useState(null);
    const [opcionBusqueda, setOpcionBusqueda] = useState(BusqMedsService.getOpcionBusqueda());
    const onChangeTexto = (e) => {
      const texto = e.target.value.replace('%',' ');
      setTexto(texto);
      SugerenciasService.getPalabras(encodeURIComponent(texto),"TOT").then((response) => {
        setListaSugerencias(response.data);
      })
    };

    const CambiaPagina = (codigoPagina, texto) => {
        props.cambiaPagina({
            newpagina: codigoPagina,
            texto: texto,
            opb: opcionBusqueda,
        });
    }

    if (!opcionBusqueda) setOpcionBusqueda(1);
    const busqueda = (e) => {
        var auxtexto = texto.replace('%',' '); //encodeURI(texto);
        if (auxtexto)
            if (auxtexto.trim().length>0){
                e.preventDefault();
                CambiaPagina("BInicio",auxtexto);
            }
    }

    const clickOptionInicio = () => {
        BusqMedsService.setOpcionBusqueda(2);
        setOpcionBusqueda(2);        
    }

    const clickOptionContenido = () => {
        BusqMedsService.setOpcionBusqueda(1);
        setOpcionBusqueda(1);        
    }

    const ancho = window.innerWidth;
    const isMobile = (ancho<768);

    const estiloOptions = (!isMobile)?{
        marginTop:'15px', 
        display: 'flex', 
        justifyContent:'space-around', 
        width:'100%', 
        fontFamily:'Flama', 
        color:'#ffffff', 
        fontSize:'14px' , 
        verticalAlign: 'baseline'
    }:{
        marginTop:'7px', 
        display: 'flex', 
        justifyContent:'space-around', 
        width:'100%', 
        fontFamily:'Flama', 
        color:'#ffffff', 
        fontSize:'14px' , 
        verticalAlign: 'baseline'
    }

    const estiloDiv = (isMobile) ? {margin: '20px'} : {};


    return (
			<div class="box box__text" style={estiloDiv}>
				<div class="text FlamaBook whiteColor" >
					{/*<h2 class="fs fs26" >Puedes buscar <strong class="greenColor3" >medicamentos</strong>, <strong class="greenColor3" >productos de <span class="break" ></span>parafarmacia</strong> o <strong class="greenColor3" >enfermedades</strong> por nombre.</h2>
					<div class="separator separator__20" ></div>
                    <p class="fs fs17" >También puedes introducir el <strong class="greenColor3" >código nacional</strong> del producto:</p>*/}
					<h2 class="fs fs26" >Puedes buscar medicamentos, productos de <span class="break" ></span>parafarmacia o enfermedades por nombre.</h2>
					<div class="separator separator__20" ></div>
                    <p class="fs fs17" >También puedes introducir el código nacional del producto:</p>
					<div class="separator separator__40" ></div>
				</div>
				<form class="search" onSubmit={(e) => e.preventDefault()}>
					<input type="search" 
                        autoFocus
                        name="" 
                        placeholder="Escribe tu búsqueda" 
                        value={texto}
                        onChange={onChangeTexto}
                        list="sugerenciasFB"
                    />
                    <Sugerencias missugerencias = {listaSugerencias} identificador="sugerenciasFB"></Sugerencias>
					<button  disabled={loading} onClick={(e) => busqueda(e)}></button>
                    <div className="content__results__filters" style={estiloOptions}>
                    <label>
                        <input type="radio" name="relacion" value={1} checked={opcionBusqueda===1}  onClick={(e) => clickOptionContenido(e)} style={{marginRight: '10px'}}/>
                        Que contengan
                    </label>
                    <label>
                        <input type="radio" name="relacion" value={99} checked={opcionBusqueda===2}  onClick={(e) => clickOptionInicio(e)} style={{marginRight: '10px'}} />
                        Que comiencen por
                    </label>
                    </div>
				</form>
      </div>
    );
  };
  
  export default FormBusqueda;