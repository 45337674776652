import ItemMenuTerciarioMorado from "./ItemMenuTerciarioMorado"


const MenuTerciarioMorado = (props) => {
    const clasesItemUL = "list list__2 list__purple child__"+ props.idxPadre.toString() +"__"+ props.idx.toString() +" child__menu2"; 
    const items = props.lista.map(item => <ItemMenuTerciarioMorado 
        item={item}
        idx = {props.idx} 
        idxPadre = {props.idxPadre} 
        cambiaPagina = {props.cambiaPagina}
        perfil = {props.perfil}> </ItemMenuTerciarioMorado>)

    return (
        <ul class={clasesItemUL} >
            {items}
        </ul>
    )
}
export default MenuTerciarioMorado;
