import React, { useState } from "react";
import { divideArrayEnPartes, numberWithCommas, rootPath } from "../../../services/utils";

import LinksFichaPAT from './LinksFichaPAT';

const MargenDerechoPAT = (props) => {
    const ficha = props.ficha;
    var imgDefecto = rootPath + "img/patHumana.svg" ;
    if (ficha.CodTipoRegistro==="PATV")
        imgDefecto = rootPath + "img/patVet.svg" ;

    const maxHeight=180;
    const maxWidth=256;

    const estildefecto = {maxWidth: '180px', maxHeight: maxHeight.toString() + 'px', width: '100%', height: 'auto'}

    const loadImage = (e) => {
        var h = e.target.height;
        if (h===maxHeight){
            e.target.style.height = '100%';
            e.target.style.width = 'auto';
            e.target.style.maxWidth = maxWidth.toString() + 'px';
            e.target.style.maxHeight = maxHeight.toString() + 'px';
        }
    }

    const imagenesHTML = <div style={{textAlign:'center'}}><p>&nbsp;</p><img class="imagen__medicamento" src={imgDefecto} style={estildefecto} /></div>
                                                                               
    return (
        <div class="medicamento">
            <div class="nombre f-f-Flama-Medium">{ficha.NombreCompleto}</div>
            <div class="contenido__imagen">
                {imagenesHTML}
            </div>
            <div class="detalles">
                {(ficha.CodTipoRegistro !== "ING") ?
                    <div class="condiciones">
                        <div class="fila">
                            <div class="col-i">Especialidad</div>
                            <div class="col-d" title={ficha.EspecialidadMedica}>{ficha.CodEspecialidadMedica}</div>
                        </div>
                    </div>
                :
                    <div></div>
                }
            </div>
            <LinksFichaPAT ficha={ficha} cambiaPagina={props.cambiaPagina} ></LinksFichaPAT>
        </div>
    )
}
export default MargenDerechoPAT;

/*
                        <div class="fila">
                            <div class="col-i">CodCIE</div>
                            <div class="col-d" title={ficha.DescripcionCIE}>{ficha.CodCIE}</div>
                        </div>
*/