import LoadingPopup from "../../Resultados/Loading.Popup";
import BP2022Service from "../../../services/user.service";
import { useEffect, useState } from "react";
import ResumenBusqueda from "../ResumenBusqueda";
import SelectorComposicion from "../../Utilidades/Selector/SelectorComposicion/SelectorComposicion";
import SelectorComboBox from "../../Utilidades/Selector/SelectorComboBox";
import { GetAntecesorWhithClassName, upperCaseFirstLetter } from "../../../services/utils";
import SelectorUno2 from "../../Utilidades/Selector/SelectorUno2";
import { OpcionesADM, OpcionesDG, OpcionesDISP, OpcionesESP, OpcionesOTR, OpcionesPAC, OpcionesPacientePI, OpcionesPatologiasPI } from "../../../services/objsAplicacion";
import SelectorCHK from "../../Utilidades/Selector/SelectorCHK";
import SelectorSNX from "../../Utilidades/Selector/SelectorSNX";
import Acordeon from "../../Fichas/FichaMUH/Apartados/Composicion/Acordeon";
import SelectorEdad from "../../Utilidades/Selector/SelectorEdad/SelectorEdad";
import SelectorEnfRel from "../../Utilidades/Selector/SelectorEnfRel/SelectorEnfRel";
import ItemResumenFiltros from "../../Resultados/FiltrosYBusqueda/ResumenFiltros/ItemResumenFiltros";
import SelectorMulti2 from "../../Utilidades/Selector/SelectorMulti2";

const FBPacienteIndicacion= (props) => {
    const [datosFormulario, setDatosFormulario] = useState({
        listaEnfs: null,
        listaMeds: null,
        listaAlgs: null,
        listaFFs: null,
        listaVias: null,
        listaIntsV: null,
        listaIntsNV: null,
    });
    const [seleccionFormulario, setSeleccionFormulario] = useState({
        idEnf: props.idEnf,
        idsPat: props.idsPat,
        idsMed: props.idsMed,
        idsAlg: props.idsAlg,
        idVia: props.idVia,
        idFF: props.idFF,
        ints: props.ints,
        edad: props.edad,
        undEdad: (props.undEdad ? props.undEdad : 'A'),
        ops: (props.ops ? props.ops : []),
    })

    const [ocultaIntolerancias, setOcultaIntolerancias] = useState(true);

    const claseIntoleranciasOcultas = (ocultaIntolerancias) ? "cover hide" : "cover hide active";
    const claseBotonIntolerancias = (ocultaIntolerancias) ? "button button__arrow button__purple__line noUppercase verMas" : "button button__arrow button__purple__line noUppercase verMas active";
    const txtbotonIntolerancias = (ocultaIntolerancias) ? "Ver más" : "Ver menos";

    useEffect(() => {
        if (datosFormulario.listaEnfs === null){
            DescargaDatos();
        }   
    },[]);

    const DescargaDatos = () => {      
        BP2022Service.datosFormularioPacienteIndicacion()
        .then((response) => {
            var df = {
                listaEnfs : response.data.Enfermedades,
                listaMeds : response.data.Medicamentos,
                listaAlgs : response.data.Alergias,
                listaVias : response.data.Vias,
                listaFFs : response.data.FormasFarma,
                listaIntsV : response.data.IntoleranciasV,
                listaIntsNV : response.data.IntoleranciasNoV,
            };
            df.listaMeds.splice(0,0,{Codigo:0, Valor:'Indique un medicamento o principio activo'});
            df.listaEnfs.splice(0,0,{Codigo:0, Valor:'Indique una enfermedad '});
            df.listaAlgs.splice(0,0,{Codigo:0, Valor:'Indique una alergia '});
            df.listaVias.splice(0,0,{Codigo:-1, Valor:'Seleccione una vía'},{Codigo:0, Valor:'-'});
            df.listaFFs.splice(0,0,{Codigo:-1, Valor:'Seleccione una forma farmacéutica'},{Codigo:0, Valor:'-'});
            setDatosFormulario(df);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const clickVerIntolerancias=(e) =>{
        setOcultaIntolerancias(!ocultaIntolerancias);
    }

    const obtenDatoResumen = (opciones) =>{
        var strValores=[]
        for(var i=0; i< opciones.length; i++){
            var itemOpcion = opciones[i];
            if (itemOpcion.tip==="CHK"){
                var itemSel = seleccionFormulario.ops.find(item => item.cod===itemOpcion.cod);
                if (itemSel && itemSel.valores && itemSel.valores.length>0){
                    var strVal = "";
                    for(var j=0; j<itemSel.valores.length; j++){
                        if (j>0) strVal += " o ";
                        else strVal += itemOpcion.tit + ": ";
                        var valSel = itemOpcion.ops.find(val=> val.val === itemSel.valores[j]);
                        strVal += valSel.tit;
                    }
                    if (strVal !== "")
                        strValores.push(strVal);
                }
            }else if (itemOpcion.tip==="SNX"){
                var itemSel = seleccionFormulario.ops.find(item =>item.cod===itemOpcion.cod);
                if (itemSel && itemSel.valor && itemSel.valor !== "" && itemSel.valor !== "X"){
                    if (itemSel.valor === "S"){
                        strValores.push(itemOpcion.tit);
                    }else if (itemSel.valor === "N"){
                        strValores.push("No " + itemOpcion.tit.toLowerCase());
                    }
                }
            }
        }
        var res="";
        if (strValores.length>0){
            var res = strValores[0];
            for (var i=1; i<strValores.length; i++){
                res += "\n" + strValores[i];
            }
        }
        return res;
    }

    const obtenDatoResumenPaciente = (itemOpcion) =>{
        var strValores=[]
        var itemSel = seleccionFormulario.ops.find(item => item.cod===itemOpcion.cod);
        if (itemSel && itemSel.valores && itemSel.valores.length>0){
            var strVal = "";
            for(var j=0; j<itemSel.valores.length; j++){
                if (j>0) strVal += ", ";
                else strVal += "";
                var valSel = itemOpcion.ops.find(val=> val.val === itemSel.valores[j]);
                strVal += valSel.tit;
                if (valSel.val === "NIN"){
                    if ((seleccionFormulario.edad>0) && (seleccionFormulario.undEdad) && (seleccionFormulario.undEdad!=="")){
                        strVal += " de " + seleccionFormulario.edad.toString() + (seleccionFormulario.undEdad==="M" ? " meses": " años");
                    }
                }
            }
            if (strVal !== "")
                strValores.push(strVal);
        }

        var res="";
        if (strValores.length>0){
            var res = strValores[0];
            for (var i=1; i<strValores.length; i++){
                res += "\n" + strValores[i];
            }
        }
        return res;
    }

    const obtenDatoResumenPatologias = (itemOpcion) =>{
        var strValores=[]
        var itemSel = seleccionFormulario.ops.find(item => item.cod===itemOpcion.cod);
        if (itemSel && itemSel.valores && itemSel.valores.length>0){
            var strVal = "";
            for(var j=0; j<itemSel.valores.length; j++){
                if (j>0) strVal += ", ";
                else strVal += "";
                var valSel = itemOpcion.ops.find(val=> val.val === itemSel.valores[j]);
                strVal += valSel.tit;
            }
            if (strVal !== "")
                strValores.push(strVal);
        }
        var res="";
        if (strValores.length>0){
            var res = strValores[0];
            for (var i=1; i<strValores.length; i++){
                res += "\n" + strValores[i];
            }
        }
        return res;
    }

    const construyeResumenBusqueda = () => {
        var res = [];

        if (seleccionFormulario.idEnf>0){
            if (datosFormulario.listaEnfs){
                var item = datosFormulario.listaEnfs.find(x => x.Codigo === seleccionFormulario.idEnf);
                if (item)
                    res.push ({titulo: "Medicamentos indicados para ", valor: item.Valor});
            }

            var itemPat = seleccionFormulario.ops.find(item => item.cod==="PAT");
            if (((seleccionFormulario.idsPat) && (seleccionFormulario.idsPat.length>0)) || itemPat){
                if (datosFormulario.listaEnfs){
                    var valor = obtenDatoResumenPatologias(OpcionesPatologiasPI[0]);
                    if (seleccionFormulario.idsPat){
                        for (var i =0; i<seleccionFormulario.idsPat.length; i++){
                            var item = datosFormulario.listaEnfs.find(x => x.Codigo === seleccionFormulario.idsPat[i]);
                            if (item){
                                if (valor !== '')
                                    valor += ", ";
                                valor += item.Valor;
                            }
                        }
                    }
                    if (valor != '')
                        res.push ({titulo: 'Otras patologías del paciente', valor: valor});
                }
            }

            if ((seleccionFormulario.idsMed) && (seleccionFormulario.idsMed.length>0)){
                if (datosFormulario.listaMeds){
                    var valor = '';
                    for (var i =0; i<seleccionFormulario.idsMed.length; i++){
                        var item = datosFormulario.listaMeds.find(x => x.Codigo === seleccionFormulario.idsMed[i]);
                        if (item){
                            if (valor !== '')
                                valor += ", ";
                            valor += item.Valor;
                        }
                    }
                    if (valor != '')
                        res.push ({titulo: "Otra medicación del paciente", valor: valor});
                }
            }

            var valor = obtenDatoResumenPaciente(OpcionesPacientePI[0]);
            if (valor != "")
                res.push ({titulo: "Características del paciente", valor: valor});
    
            if (seleccionFormulario.idFF>0){
                if (datosFormulario.listaFFs){
                    var item = datosFormulario.listaFFs.find(x => x.Codigo === seleccionFormulario.idFF);
                    if (item)
                        res.push ({titulo: "Forma farmacéutica", valor: item.Valor});
                }
            }
        
            if (seleccionFormulario.idVia>0){
                if (datosFormulario.listaVias){
                    var item = datosFormulario.listaVias.find(x => x.Codigo === seleccionFormulario.idVia);
                    if (item)
                        res.push ({titulo: "Vía", valor: item.Valor});
                }
            }

            if ((seleccionFormulario.ints) && (seleccionFormulario.ints.length>0)){
                if (datosFormulario.listaIntsV){
                    var valor = '';
                    for (var i =0; i<seleccionFormulario.ints.length; i++){
                        var item = datosFormulario.listaIntsV.find(x => x.Codigo === seleccionFormulario.ints[i]);
                        if (!item) item = datosFormulario.listaIntsNV.find(x => x.Codigo === seleccionFormulario.ints[i]);
                        if (item){
                            if (valor !== '')
                                valor += ", ";
                            valor += item.Valor;
                        }
                    }
                    if (valor != '')
                        res.push ({titulo: "Intolerancias del paciente", valor: valor});
                }
            }

            if ((seleccionFormulario.idsAlg) && (seleccionFormulario.idsAlg.length>0)){
                if (datosFormulario.listaAlgs){
                    var valor = '';
                    for (var i =0; i<seleccionFormulario.idsAlg.length; i++){
                        var item = datosFormulario.listaAlgs.find(x => x.Codigo === seleccionFormulario.idsAlg[i]);
                        if (item){
                            if (valor !== '')
                                valor += ", ";
                            valor += item.Valor;
                        }
                    }
                    if (valor != '')
                        res.push ({titulo: "Alergias del paciente", valor: valor});
                }
            }    
    
        }
        return res;
    }

    var resumen = construyeResumenBusqueda(seleccionFormulario);

    const hayBusqueda = seleccionFormulario.idEnf>0;

    const clickBuscar = (e) =>{
        if  ( hayBusqueda){
            var newpagina = "BPacienteIndicacionMUH";

            props.cambiaPagina({
                newpagina: newpagina,
                idEnf: seleccionFormulario.idEnf,
                idsPat: seleccionFormulario.idsPat,
                idsMed: seleccionFormulario.idsMed,
                idsAlg: seleccionFormulario.idsAlg,
                idVia: seleccionFormulario.idVia,
                idFF: seleccionFormulario.idFF,
                edad: seleccionFormulario.edad,
                undEdad: seleccionFormulario.undEdad,
                ints: seleccionFormulario.ints,
                ops: seleccionFormulario.ops,
            })
        }
    }

    const BorraBusqueda = (e) =>{
        const formularioVacio={
            idEnf: 0,
            idsPat: [],
            idsMed: [],
            idsAlg: [],
            idVia: 0,
            idFF: 0,
            edad: 0,
            undEdad: '',
            ints: [],
            ops: [],
        }
        setSeleccionFormulario(formularioVacio);
        var inputs = document.getElementsByClassName('inputSelector');
        if (inputs){
            for (var i=0; i<inputs.length; i++){
                inputs[i].value = "";
                const itemContainer = GetAntecesorWhithClassName(inputs[i], 'custom-select-container');
                if (itemContainer){
                    itemContainer.classList.remove('active');
                }
            }
        }
    }

    const CambioEnfermedad = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idEnf = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const BorraEnfermedad = () =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idEnf = 0;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddPatologia = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (!nuevaSeleccion.idsPat) { nuevaSeleccion.idsPat = []};
        nuevaSeleccion.idsPat.push(nuevovalor);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemovePatologia = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var i = nuevaSeleccion.idsPat.indexOf(parseInt(valor));
        nuevaSeleccion.idsPat.splice(i,1);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddMedicamento = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (!nuevaSeleccion.idsMed) { nuevaSeleccion.idsMed = []};
        nuevaSeleccion.idsMed.push(nuevovalor);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveMedicamento = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var i = nuevaSeleccion.idsMed.indexOf(parseInt(valor));
        nuevaSeleccion.idsMed.splice(i,1);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddAlergia = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (!nuevaSeleccion.idsAlg) { nuevaSeleccion.idsAlg = []};
        nuevaSeleccion.idsAlg.push(nuevovalor);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveAlergia = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var i = nuevaSeleccion.idsAlg.indexOf(parseInt(valor));
        nuevaSeleccion.idsAlg.splice(i,1);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioVia = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idVia = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioFF = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idFF = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioIntolerancia = (e, idInt) =>{
        var item = e.target;
        var idx = (seleccionFormulario.ints) ? seleccionFormulario.ints.indexOf(idInt) : -1;
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (item.checked){
            if (!nuevaSeleccion.ints) nuevaSeleccion.ints = [];
            if (idx<0) nuevaSeleccion.ints.push(idInt);
        }else{
            if (idx>=0) nuevaSeleccion.ints.splice(idx,1);
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioEdad = (nuevaedad, nuevaunidad) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.edad = nuevaedad;
        nuevaSeleccion.undEdad = nuevaunidad;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambiaValorSNX = (valorSNX, idSNX) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (!nuevaSeleccion.ops)
            nuevaSeleccion.ops = [];
        var item = nuevaSeleccion.ops.find(it => it.cod===idSNX);
        if (item){
            if (valorSNX !== 'X'){
                item.valor = valorSNX;
            }else{
                var idx = nuevaSeleccion.ops.indexOf(item);
                nuevaSeleccion.ops.splice(idx,1);
            }
        }else{
            if (valorSNX !=='X'){
                nuevaSeleccion.ops.push({cod:idSNX, valor:valorSNX});
            }
        }
        setSeleccionFormulario(nuevaSeleccion);
    }


    const CambiaValorCHK = (valoresCHK,idCHK) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (!nuevaSeleccion.ops)
            nuevaSeleccion.ops = [];
        var item = nuevaSeleccion.ops.find(it => it.cod===idCHK);
        if (item){
            if (valoresCHK.length>0){
                item.valores = valoresCHK;
            }else{
                var idx = nuevaSeleccion.ops.indexOf(item);
                nuevaSeleccion.ops.splice(idx,1);
            }
        }else{
            if (valoresCHK.length>0){
                var newitem = {
                    cod: idCHK,
                    valores: valoresCHK,
                }
                nuevaSeleccion.ops.push(newitem);
            }            
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const KeyDownForm = (e) =>{
        var keyCode = e.keyCode;
        if (keyCode === 13){
            clickBuscar(e);
        }
    }



    const listaCodValorEnfs   = (datosFormulario.listaEnfs) ? datosFormulario.listaEnfs.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorMeds   = (datosFormulario.listaMeds) ? datosFormulario.listaMeds.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorAlgs   = (datosFormulario.listaAlgs) ? datosFormulario.listaAlgs.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorFFs    = (datosFormulario.listaFFs)  ? datosFormulario.listaFFs.map(it =>  ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorVias   = (datosFormulario.listaVias) ? datosFormulario.listaVias.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];

    const HTMLIntoleranciasV = (((datosFormulario.listaIntsV) && (datosFormulario.listaIntsV.length>0)) ?
        datosFormulario.listaIntsV.map(int => 
            <label>
                <input type="checkbox" value={int.Codigo} checked = {(seleccionFormulario.ints && seleccionFormulario.ints.indexOf(int.Codigo)>=0)} onClick={(e) => CambioIntolerancia(e, int.Codigo)} />
                {upperCaseFirstLetter(int.Valor)}
            </label>
        )
    :
        <div></div>
    );
    const HTMLIntoleranciasNV = (((datosFormulario.listaIntsNV) && (datosFormulario.listaIntsNV.length>0)) ?
        datosFormulario.listaIntsNV.map(int => 
            <label>
                <input type="checkbox" value={int.Codigo} checked = {(seleccionFormulario.ints && seleccionFormulario.ints.indexOf(int.Codigo)>=0)} onClick={(e) => CambioIntolerancia(e, int.Codigo)} />
                {upperCaseFirstLetter(int.Valor)}
            </label>
        )
    :
        <div></div>
    );
    const clasedivmodulefilters = "cover cover__filters cover__filters__2 cover__filters__2--1 cover__filters__2--1__50";

   /* const HTMLOpcionesPat = OpcionesPatologiasPI.map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );*/

    const HTMLPiePat = (
        <div class="cover cover__tip">
            <img src="./img/tip.svg" alt="tip" />
            <p class="fs fs12 FlamaBook" >Puede añadir otras enfermedades diferentes a las anteriores buscándolas en el siguiente cuadro</p>
        </div>
    );

    const HTMLOpcionesPat = (
        <div class="cover cover__filters cover__filters__2 cover__filters__2--3" >
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===OpcionesPatologiasPI[0].cod) ? seleccionFormulario.ops.find(it => it.cod===OpcionesPatologiasPI[0].cod).valores : [])}
                opcionesCHK = {OpcionesPatologiasPI[0].ops}
                titulo = {OpcionesPatologiasPI[0].tit}
                idOrigen = {OpcionesPatologiasPI[0].cod}
                alCambiar = {CambiaValorCHK}
                pie = {HTMLPiePat}
            />
        </div>
    );

    const HTMLPiePac = ((seleccionFormulario.ops.find(it=>it.cod==="PAC")) 
            && (seleccionFormulario.ops.find(it=>it.cod==="PAC").valores)
            && (seleccionFormulario.ops.find(it=>it.cod==="PAC").valores.includes("NIN")) ?
            <div>
                <div class="cover cover__filters" style={{marginBottom:'0px'}}>
                    <div class="module module__filters module__units module__units__select" >
                        <SelectorEdad 
                            edad={seleccionFormulario.edad} 
                            unidad={seleccionFormulario.undEdad} 
                            alCambiar={CambioEdad
                        }></SelectorEdad>
                    </div>
                </div>
                {!seleccionFormulario.edad || seleccionFormulario.edad === 0 ?
                    <div class="cover cover__tip">
                        <img src="./img/warningYellow.svg" alt="tip" />
                        <p class="fs fs12 FlamaBook" >Debe indicar la edad del niño</p>
                    </div>
                :
                    <div></div>
                }
            </div>
    :
            <div></div>
        )

    const HTMLOpcionesPac = (
        <div class="cover cover__filters cover__filters__2" >
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===OpcionesPacientePI[0].cod) ? seleccionFormulario.ops.find(it => it.cod===OpcionesPacientePI[0].cod).valores : [])}
                opcionesCHK = {OpcionesPacientePI[0].ops}
                titulo = {OpcionesPacientePI[0].tit}
                idOrigen = {OpcionesPacientePI[0].cod}
                alCambiar = {CambiaValorCHK}
                pie = {HTMLPiePac}
            />
        </div>
    );

/*    const HTMLOpcionesPac = OpcionesPacientePI.map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );
*/

    const HTMLDatosGeneral = (
        <div>
            <div class="cover cover__filters cover__filters__2 cover__filters__2--1" >
                <div class="module module__filters" >
                    <h2 class="superTitle fs fs20 purpleColor" >Indicación</h2>
                    
                    <SelectorUno2 lista = {listaCodValorEnfs} 
                                idHTML="FBPacIndMUHLInd" 
                                valueSelected={seleccionFormulario.idEnf}
                                alCambiar={CambioEnfermedad} 
                                alBorrar={BorraEnfermedad} 
                                ancho="100%"
                    ></SelectorUno2>
                </div>
            </div>
            <div class="cover cover__filters cover__filters__2 cover__filters__2--1 cover__filters__2--1__50" >
                <div class="module module__filters">
                    <h3 class="title fs fs16 greyColor2"  style={{marginBottom:'12px'}}>Vía de administración</h3>
                    <SelectorComboBox lista = {listaCodValorVias} 
                            idHTML="FBComposVia" 
                            valueSelected={seleccionFormulario.idVia} 
                            alCambiar={CambioVia} 
                            ancho="100%"
                    ></SelectorComboBox>
                </div>
                <div class="module module__filters">
                    <h3 class="title fs fs16 greyColor2" style={{marginBottom:'12px'}}>Forma farmacéutica</h3>
                    <SelectorComboBox lista = {listaCodValorFFs} 
                            idHTML="FBComposFF" 
                            valueSelected={seleccionFormulario.idFF} 
                            alCambiar={CambioFF} 
                            ancho="100%"
                    ></SelectorComboBox>
                </div>
            </div>
        </div>
    );

    const HTMLColumna1 = (
        <div class="column">
            {HTMLOpcionesPat}
            <div class="cover cover__filters cover__filters__2" >   
                <div class="module module__filters" >
                    <SelectorMulti2 
                                lista = {listaCodValorEnfs} 
                                idHTML="FBPacIndLOP" 
                                valuesSelected={seleccionFormulario.idsPat}
                                alAnadir={AddPatologia }
                                alBorrar={RemovePatologia} 
                                ancho="100%"
                                tipoFiltradoLista={1}
                    ></SelectorMulti2>
                </div>
            </div>
            <div class="cover cover__filters cover__filters__2" >   
                <div class="module module__filters" >
                    <h3 class="title fs fs16 greyColor2" >Otros tratamientos</h3>
                    <SelectorMulti2 
                                lista = {listaCodValorMeds} 
                                idHTML="FBPacIndLOMed" 
                                valuesSelected={seleccionFormulario.idsMed}
                                alAnadir={AddMedicamento }
                                alBorrar={RemoveMedicamento} 
                                ancho="100%"
                                tipoFiltradoLista={1}
                    ></SelectorMulti2>
                </div>
            </div>
        </div>
    );

    const HTMLColumna2 = (
        <div class="column" >
            {HTMLOpcionesPac}
            <div class="cover cover__filters cover__filters__checks" >
                <div class="module module__checks"style={{width:'100%'}}>
                    <h3 class="fs fs16 greyColor2 title" >Intolerancias</h3>
                    <div class="checks" >
                        <div class="cover" >
                            {HTMLIntoleranciasV}
                        </div>
                        <div class={claseIntoleranciasOcultas} id="IntoleranciasOcultas">
                            {HTMLIntoleranciasNV}
                        </div>
                    </div>
                </div>
            </div>
            <div class="module module__buttons module__buttons__buscar">
                <span class={claseBotonIntolerancias}   onClick={(e) => clickVerIntolerancias(e)}>{txtbotonIntolerancias} <img src="./img/arrowPurpleDown2.svg" alt="arrowPurpleDown2" /></span>
            </div>
            <div class="cover cover__tip cover__tip__marBot">
                <img src="./img/tip.svg" alt="tip" /><p class="fs fs12 FlamaBook" >Puede seleccionar otras alergias en el listado inferior.</p>
            </div>

            <div class="cover cover__filters cover__filters__2" >   
                <div class="module module__filters" >
                    <SelectorMulti2 
                                lista = {listaCodValorAlgs} 
                                idHTML="FBPacIndLOAlg" 
                                valuesSelected={seleccionFormulario.idsAlg}
                                alAnadir={AddAlergia }
                                alBorrar={RemoveAlergia} 
                                ancho="100%"
                                tipoFiltradoLista={1}
                    ></SelectorMulti2>
                </div>
            </div>
        </div>
    );

    
    const HTMLDosColumnas = (
        <div class="cover cover__columns2" >
            <div class="cover cover__title" >
                <h2 class="title fs fs20 purpleColor" >Características del paciente</h2>
            </div>
            <div class="columns columns__2" >
                {HTMLColumna1}
                {HTMLColumna2}
            </div>
        </div>
    );

    const estiloBotonesSup = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'space-between'} : {height:'76px', width:'100%', justifyContent: 'space-between', visibility:'hidden'})
    const estiloBotonesInf = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'center'} : {height:'76px', width:'100%', justifyContent: 'center', display:'none'})

    return(
        <div class="content" >
            { (datosFormulario.listaEnfs === null) ?
                <LoadingPopup titulo="Un momento" texto="Preparando formulario" esPorcentaje={true}></LoadingPopup>
            :
                <form class="content__results content__results__filters" id="main-filter"  onSubmit={(e) => e.preventDefault()}  onKeyDown={(e) => KeyDownForm(e)} tabIndex="0">
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesSup}>
                        <span class="button button__arrow button__green noUppercase reset-form" data-form="#main-filter" title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} style={{visibility:'hidden'}} >
                            Vaciar búsqueda
                        </span>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                        <span class="button button__arrow button__green reset-form" data-form="#main-filter" style={{textTransform:'none', fontSize: '14px', width:'146px'}} title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} >
                            Vaciar búsqueda
                        </span>
                    </div>
                    {HTMLDatosGeneral}
                    {HTMLDosColumnas}
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesInf}>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                    </div>
                    <ResumenBusqueda condiciones={resumen}></ResumenBusqueda>
                </form>
            }
        </div>
    );
}
    
export default FBPacienteIndicacion;
    