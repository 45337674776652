import OptionSelect from "../../../Utilidades/Select/OptionSelect";
import SelectorComboBox from "../../../Utilidades/Selector/SelectorComboBox";

function FiltroListBox(props){
    const filtro = props.filtro;
    const opcionesLista = filtro.posibles.map(opcion => <OptionSelect opt={opcion}></OptionSelect>);
    const idInput = "input__" + filtro.idGrupo;
    
    const avoidEnter = (e) =>{
        if (e.which == '13') {
            e.preventDefault();
            e.stopPropagation();
            e.target.blur();
        }
    }

    const handleCambioLista=(valor) =>{
        props.cambiaLista(filtro.idGrupo, valor)
    }


    return (
        <form class="content__results content__results__filters " id="main-filter"  onSubmit={(e) => e.preventDefault()}>
            <SelectorComboBox lista = {filtro.posibles} 
                    idHTML={idInput} 
                    valueSelected={filtro.valor} 
                    alCambiar={handleCambioLista} 
                    ancho="100%"
            ></SelectorComboBox>
        </form>
    );
}    
export default FiltroListBox;