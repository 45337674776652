import SeccionMenuBlanco from "./SeccionMenuBlanco";


const MenuBlanco = (props) => {

    const seccionesMenu =  props.elementosMenu.map(item => <SeccionMenuBlanco cambiaPagina={props.cambiaPagina} item={item} perfil = {props.perfil}></SeccionMenuBlanco>)

    return (
        <ul class="menu fs fs15 fw fw400 blackColor" >
            {seccionesMenu}
        </ul>
    )
}
export default MenuBlanco;
