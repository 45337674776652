import Acordeon from "../../../FichaMUH/Apartados/Composicion/Acordeon";
import FilaLMR from "./FilaLMR";

const LMREspecie = (props) => {
    const lmr = props.lmr;
    const titulo = lmr.Especie;
    const HTMLLMRTejidos = lmr.LMRsTejidos.map(lmrtej => <FilaLMR lmr={lmrtej} ></FilaLMR>);

    const estiloUltimaFila = {borderBottom: '4px solid #e8e8e8'};
    var estiloPrimeraFila = {};
    var estiloObservaciones = {};
    if (lmr.Observaciones && lmr.Observaciones !== '')
        estiloObservaciones = estiloUltimaFila;
    else
        estiloPrimeraFila = estiloUltimaFila;

    var contenidoAcordeon = (
        <div class="table" style={{width:'100%'}}>
            <div class="table__row table__row__header table__row__header__noBg fs fs12 fw fw600 greenColor uppercase">
                <div class="column" >
                    <p>Tejido</p>
                </div>
                <div class="column" >
                    <p>LMR</p>
                </div>
            </div>
            {HTMLLMRTejidos}
            {(lmr.Observaciones && lmr.Observaciones !== '') ?
                <div>
                    <div class="table__row table__row__header table__row__header__noBg fs fs12 fw fw600 greenColor uppercase">
                        <div class="column" >
                            <p>Observaciones</p>
                        </div>
                    </div>
                    <div class="table__row table__row__normal" title="Observaciones">
                        <div class="column fs fs14 fw fw300 FlamaBook blackColor" >
                            <p class="alignLeft" >{lmr.Observaciones}</p>
                        </div>
                    </div>
                </div>
            :
                <div></div>
            }
         </div>
    );
    
    return (
        <Acordeon titulo = {titulo} contenido={contenidoAcordeon} activo={false}></Acordeon>
    )
}
export default LMREspecie;
 
