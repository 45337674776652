import { useEffect, useState } from "react";
import { deepEqual } from "../../../../services/utils";
import RamaPrincipal from "./RamaPrincipal";

function SelectorTree(props){
    const [propiedades, setPropiedades] = useState({
        arbol: props.arbol,
        valueSelected: props.valueSelected,
        textSelected: '',
    });
    const valueSelected = propiedades.valueSelected;
    const idHTML = props.idHTML; 

    const idInput = "Input__" + idHTML;
    
    
    useEffect(() => {
        var textSelected = props.textSelected;
        if ((props.arbol) && (props.valueSelected) && (props.valueSelected !== '')){
            for (var i1=0; i1< props.arbol.length; i1++){
                if (props.valueSelected.indexOf(props.arbol[i1].Codigo)===0 && (props.valueSelected !== props.arbol[i1].Codigo)){
                    props.arbol[i1].abierta = true;
                    for (var i2=0; i2< props.arbol[i1].hijos.length; i2++){
                        if (props.valueSelected.indexOf(props.arbol[i1].hijos[i2].Codigo)===0 && (props.valueSelected !== props.arbol[i1].hijos[i2].Codigo)){
                            props.arbol[i1].hijos[i2].abierta = true;
                            for (var i3=0; i3< props.arbol[i1].hijos[i2].hijos.length; i3++){
                                if (props.valueSelected.indexOf(props.arbol[i1].hijos[i2].hijos[i3].Codigo)===0 && (props.valueSelected !== props.arbol[i1].hijos[i2].hijos[i3].Codigo)){
                                    props.arbol[i1].hijos[i2].hijos[i3].abierta = true;
                                    for (var i4=0; i4< props.arbol[i1].hijos[i2].hijos[i3].hijos.length; i4++){
                                        if (props.valueSelected.indexOf(props.arbol[i1].hijos[i2].hijos[i3].hijos[i4].Codigo)===0 && (props.valueSelected !== props.arbol[i1].hijos[i2].hijos[i3].hijos[i4].Codigo)){
                                            props.arbol[i1].hijos[i2].hijos[i3].hijos[i4].abierta = true;
                                            for (var i5=0; i5< props.arbol[i1].hijos[i2].hijos[i3].hijos[i4].hijos.length; i5++){
                                                if (props.arbol[i1].hijos[i2].hijos[i3].hijos[i4].hijos[5].Codigo===valueSelected)
                                                    textSelected = props.arbol[i1].hijos[i2].hijos[i3].hijos[i4].hijos[5].Valor
                                            }
                                        }
                                        if (props.arbol[i1].hijos[i2].hijos[i3].hijos[i4].Codigo===valueSelected)
                                            textSelected = props.arbol[i1].hijos[i2].hijos[i3].hijos[i4].Valor
                                    }
                                }
                                if (props.arbol[i1].hijos[i2].hijos[i3].Codigo===valueSelected)
                                    textSelected = props.arbol[i1].hijos[i2].hijos[i3].Valor
                            }
                        }
                        if (props.arbol[i1].hijos[i2].Codigo===valueSelected)
                            textSelected = props.arbol[i1].hijos[i2].Valor
                    }
                }
                if (props.arbol[i1].Codigo===valueSelected)
                    textSelected = props.arbol[i1].Valor
            }
        }
        //if (props.textSelected === '' && props.valueSelected !== '' && textSelected !== '') 
        if (props.textSelected === '' && props.valueSelected !== '' && textSelected !== '') 
            cambiaSeleccion(valueSelected, textSelected.substring(props.valueSelected.length));
        setPropiedades({
            arbol: props.arbol,
            valueSelected: props.valueSelected,
            textSelected: textSelected,
        })
        
    },  [props.arbol, props.valueSelected]);


    const cambiaSeleccion = (codigo, valor) =>{
        if (codigo !== ""){
            var texto = valor;    
            if (!props.ocultaCodigo)
                texto = codigo + ' ' + valor;    
        }
        props.alCambiar(codigo,valor);
    }

    if ((props.arbol) && (props.arbol.length===1)){
        props.arbol[0].abierta=true;
    }

    const arbolHTML = ((propiedades.arbol) && (propiedades.arbol.length>0)) ?
        propiedades.arbol.slice(0,300).map(item => <RamaPrincipal item = {item} valueSelected={propiedades.valueSelected} cambiaSeleccion = {cambiaSeleccion} ocultaCodigo = {props.ocultaCodigo}></RamaPrincipal>)
        : <div></div>

    return (
        <div> 
            {arbolHTML}
        </div>
    )
}
export default SelectorTree;
