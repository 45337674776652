import FilaNombrePA from "./FilaNombrePA";


const ConfusionNombresPA = (props) => {
    const nombresPA = props.nombresPA;
    const HTMLNombresPA = nombresPA.map(nombre => <FilaNombrePA nombre={nombre} cambiaPagina={props.cambiaPagina}></FilaNombrePA>);
    return (
        <div class="infoContent" >
            <div class="table" style={{width:'100%'}}>
                <div class="table__row table__row__header table__row__header__noBg fs fs12 fw fw600 greenColor uppercase">
                    <div class="column" >
                        <p>Principios activos</p>
                    </div>
                </div>
                {HTMLNombresPA}
            </div>
        </div>
    )
}
export default ConfusionNombresPA;
