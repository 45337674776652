import React, { useState, useEffect } from "react";

import {
    BrowserRouter as Router,
    Link,
    useLocation,
    Switch,
    Route,
} from "react-router-dom";

import "./css/style.css"
import "./css/style.scss"
import "./css/fran.css"
import "./App.css";
import BP2022AuthService from "./services/auth.service";


import Login from "./components/Login";
import Contenido from "./components/Contenido";
import Credenciales from "./components/Credenciales";

import AuthService from "./services/auth.service";
import { getCurrentURL, getParam, getParamsURL, rootPath } from "./services/utils";

const App = () => {
    const [currentUser, setCurrentUser] = useState(undefined);
    const [sesionOK, setSesionOk] = useState(true);
    const [estado, setEstado] = useState(0);
    const [mensaje, setMensaje] = useState("");

    useEffect(() => {
        const user = AuthService.getLocalUser();
        if (!user || !user.botPlusUser || !sesionOK){
            var ruta = getCurrentURL();
            var params = getParamsURL(ruta);
            var keypass = getParam(params,'keypass');
            var token = getParam(params,'token');

            if (token && token !== ''){
                OpenDeferredSession(token);
            }else if (keypass && keypass !== ''){
                SpecialAuthentication(keypass);
            }else{
                IPAuthentication();
            }
        }else if (user != currentUser)  {
            setCurrentUser(user);
            CheckNetworkUser();
        }
    }, [sesionOK]);

    const CheckNetworkUser = () => {
        BP2022AuthService.getNetworkUser()
            .then((response) => {
                var bpu = response.botPlusUser;
                var lu = JSON.parse(localStorage.getItem("login"));
                if (lu.botPlusUser.Username === bpu.Username && response.Status===0){
                    console.log("Renovado TOKEN");
                    localStorage.setItem("login", JSON.stringify(response));
                    if (estado != 0)
                        setEstado(0);
                    if (mensaje != "")
                        setMensaje("");
                }
                else{
                    setEstado(response.Status);
                    setMensaje(response.Mensaje);
                    setSesionOk(false);
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
                setSesionOk(false);
            });
    };

    const OpenDeferredSession = (token) => {
        BP2022AuthService.openDeferredSesion(token)
            .then((response) => {
                //console.log(response);
                setCurrentUser(response.botPlusUser.Usuario)
                if (response.Status === 0){
                    setEstado(0);
                    window.location.reload();
                }
                if (mensaje != "")
                    setMensaje("");
            })
            .catch(function (error) {
                console.log('Error', error.message);
                setSesionOk(false);
            });
    };

    const SpecialAuthentication = (str) => {
        BP2022AuthService.getSpecialAuthentication(str)
            .then((response) => {
                if (response.Status===0){
                    localStorage.setItem("login", JSON.stringify(response));
                    setCurrentUser(response.botPlusUser.Usuario)
                    window.location.reload();
                }
                else{
                    setEstado(response.Status);
                    setMensaje(response.Mensaje);
                    setSesionOk(false);
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
                setSesionOk(false);
            });
    };

    const IPAuthentication = () => {
        BP2022AuthService.getIPAuthentication()
            .then((response) => {
                if (response.Status===0){
                    localStorage.setItem("login", JSON.stringify(response));
                    setCurrentUser(response.botPlusUser.Usuario)
                    window.location.reload();
                }
                else{
                    setEstado(response.Status);
                    setMensaje(response.Mensaje);
                    setSesionOk(false);
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
                setSesionOk(false);
            });
    };

    if (window.location.host === 'localhost:3000')
        document.title = "BOTPLUS Desarrollo";
    else if (window.location.host === 'botplus2022.nitramsoftware.es:8088')
        document.title = "BOTPLUS Nitram";
    else if (window.location.host === 'botpluswebdesa.consejo.es')
        document.title = "BOTPLUS Pruebas";
    else if (window.location.host === 'botpluswebpreproduccion.consejo.es')
        document.title = "BOTPLUS Preproducción";

    var ruta = getCurrentURL();
    if (ruta.length>=rootPath.length)
        ruta = ruta.substring(rootPath.length);
    var params = getParamsURL(ruta);
    var link = getParam(params,'link');


    return (
        <Router>
            <div style={{height:'100%'}}>
                <Switch>
                    <Route path="/credenciales">
                        <Credenciales></Credenciales>
                    </Route>
                    <Route path="/Recupera">
                        <Login status={estado} recupera={true} link={link}></Login>
                    </Route>
                    <Route path="/CambioPass">
                        {(currentUser) && sesionOK ? (
                            <Contenido idProvincia={currentUser.IdProvincia} checkSesion={CheckNetworkUser} cambiopass={true} link={link}></Contenido>
                        ) : (
                            <Login status={estado} mensaje={mensaje}  cambiopass={true} link={link}></Login>
                        ) } 
                    </Route>
                    <Route path="/CambioMail">
                        {(currentUser) && sesionOK ? (
                            <Contenido idProvincia={currentUser.IdProvincia} checkSesion={CheckNetworkUser} cambiomail={true} link={link}></Contenido>
                        ) : (
                            <Login status={estado} mensaje={mensaje}  cambiomail={true} link={link}></Login>
                        ) } 
                    </Route>
                    <Route path="/ConfirmaMail">
                        {(currentUser) && sesionOK ? (
                            <Contenido idProvincia={currentUser.IdProvincia} checkSesion={CheckNetworkUser} confirmamail={true} link={link}></Contenido>
                        ) : (
                            <Login status={estado} mensaje={mensaje}  confirmamail={true} link={link}></Login>
                        ) } 
                    </Route>
                    <Route>
                        {(currentUser) && sesionOK ? (
                            <Contenido idProvincia={currentUser.IdProvincia} checkSesion={CheckNetworkUser}></Contenido>
                        ) : (
                            <Login status={estado} mensaje={mensaje}></Login>
                        ) } 
                    </Route>
                </Switch>
            </div>
        </Router>
    );
};

export default App;