import { useEffect, useState } from "react";
import { AddClassNameToChildClassItems, buscaOptionEnSelectByValue, createElementFromHTML
    , deepEqual, elementIsOfClass, ExistsClassNameChildClassItems, GetAntecesorWhithClassName
    , GetLastSucesorWhithClassName, getSelectValues, GetSucesorWhithClassName
    , primerHijoConClase, RemoveClassNameToChildClassItems, scrollSiNoVisible } from "../../../services/utils";

function SelectorMulti2No(props){
    const [propiedades, setPropiedades] = useState({
        lista: props.lista,
        valuesSelected: props.valuesSelected,
        currentValue: null,
        negado: false,
    })
    const lista = propiedades.lista;
    const [listavisible, setListaVisible] = useState([]) ;
    const valuesSelected = (propiedades.valuesSelected) ? propiedades.valuesSelected : [] ;
    const idHTML = props.idHTML; 
    const optionsHTML = listavisible.map(item => (
        valuesSelected.includes(item.codigo) ? 
        <option selected value={item.codigo}>{item.valor}</option>
        :<option value={item.codigo}>{item.valor}</option> )
    );

    const estiloModuleSelect = (props.ancho ? {width: props.ancho} : {});

    //const estilolabel = (props.small ? {fontSize:'12px'} : {} ) ;

    const maxLengthNormal = (props.maxLengthNormal ? props.maxLengthNormal : 44);
    const estiloPetit = {fontSize:'12px', textTransform: 'none', whiteSpace: 'normal'};
    const estiloNormal = {textTransform: 'none', whiteSpace: 'normal'};

    const optionsDisplayed = listavisible.map(item => (
        <label class={"labeloption " + (valuesSelected.includes(item.codiog) ? "active" : "") } 
                style={(item.valor.length>maxLengthNormal || props.small)?estiloPetit:estiloNormal}
                data-value={item.codigo} 
                onClick={(e) => ClickEnOpcion(e)} 
                title = {item.valor} 
        >{item.valor}</label>
    ));

    const tipoFiltradoLista = (props.tipoFiltradoLista>=0) ? props.tipoFiltradoLista : 5;

    const clasesCoverSelect = (props.disabled ? "cover cover__select cover__select cover__select__border disabled" : "cover cover__select cover__select cover__select__border");

    const textPlaceholder = (lista && lista.length>0) ? lista[0].valor : '';

    var itemsSelected = [];
    
    for (var item of lista){
        if (valuesSelected.includes(item.codigo))
            itemsSelected.push(item);
        if (valuesSelected.includes(item.codigo * -1))
            itemsSelected.push({codigo : item.codigo * -1, valor : 'NO ' + item.valor});
    }

    //const itemsSelected = lista.filter(item => valuesSelected.includes(item.codigo));
        
    //              style = {(item.codigo<0) ? {backgroundColor:'#FFE3DF', borderColor: '#EBA7B9'} : {backgroundColor:'#E3EADC', borderColor: '#7EB04B'}}

    const HTMLTagsSelected = itemsSelected.map(item => 
        <span className={(item.codigo<0) ? (item.codigo===propiedades.currentValue) ? "tagred active" : "tagred" : (item.codigo===propiedades.currentValue) ? "taggreen active" : "taggreen"}
              data-value={item.codigo} 
              onClick={(e) => ClickTag(item.codigo)}>
            {item.valor}
            <img class="arrow imagenTagEliminar" src="./img/close-tag.svg" id="imgBorrar" alt="eliminar" onClick={(e) => props.alBorrar(item.codigo) } />
        </span>);
 
    useEffect(() => {
        if (!deepEqual(props.lista, propiedades.lista) || (props.valuesSelected !== valuesSelected)){
            setListaVisible([]);
            setPropiedades({
                lista: props.lista,
                valuesSelected: props.valuesSelected,
            })
        }
        
    },  [props.lista, props.valueSelected]);


    const ClickEnCoverSelect  = (e) =>{
        if (!props.disabled){
            const item = e.target;        
            const itemContainer = GetAntecesorWhithClassName(item, 'custom-select-container');
            itemContainer.classList.toggle('active');
        }
    }

    const ClickTag  = (codigo) =>{
        const nuevaProps = JSON.parse(JSON.stringify(propiedades));
        nuevaProps.negado = (codigo<0);
        nuevaProps.currentValue = codigo;
        setPropiedades(nuevaProps);
    }

    const AddItem  = (codigo) =>{
        if (props.alAnadir){
            if (propiedades.negado){
                props.alAnadir(codigo * -1);
            }else{
                props.alAnadir(codigo);
            }
        }
    }

    const ClickNegado  = (e) =>{
        const nuevaProps = JSON.parse(JSON.stringify(propiedades));
        nuevaProps.negado = !propiedades.negado;
        if (nuevaProps.currentValue && nuevaProps.currentValue !== 0 && props.alNegar)
            props.alNegar(nuevaProps.currentValue);
        else
            setPropiedades(nuevaProps);
    }

    const ClickEnOpcion  = (e) =>{
    	var option = e.target;
        SeleccionaOpcion(option);
    }

    const SeleccionaOpcion = (option) =>{
        var value = option.getAttribute("data-value");
        var html = option.innerHTML;

        var container = GetAntecesorWhithClassName(option, "custom-select-container");
        var select = primerHijoConClase(container,'custom-select');
        var input = GetSucesorWhithClassName(container, 'inputSelector');

        container.classList.remove("active");

        //RemoveClassNameToChildClassItems(container, 'dropdown__radios', 'labeloption', 'active');
        RemoveClassNameToChildClassItems(container, 'dropdown__radios', 'labeloption', 'preselected');
        RemoveClassNameToChildClassItems(container, 'dropdown__radios', 'labeloption', 'hide');
        option.classList.add("active");

        if (input){
            input.value='';
        }

        if(!(ExistsClassNameChildClassItems(container,'dropdown','labeloption','hide') || ExistsClassNameChildClassItems(container,'dropdown','labeloption','active')))
            RemoveClassNameToChildClassItems(container,'dropdown',null,'show');

        AddItem(parseInt(value));           
    }

    const remove_accents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } 
  
    const KeyUpInput = (e) =>{
		var input = e.target;
        const container = GetAntecesorWhithClassName(input, 'custom-select-container');
        var texto = input.value;
        FiltraOpciones(container,texto);
    }

    const FiltraOpciones = (container, texto) =>{
        var search = remove_accents(texto.toLowerCase());
        var search2 = " " + search;

    	if(search != ""){
            var nuevalista = lista.slice(1);
            var textoDividido = search.split(/[\s\/\\\[\]\(\)\{\},;:.\-\+\*=?¿¡!%$€&@\'\"']+/);
            for(var i=0; i<textoDividido.length ; i++){
                var search1 = textoDividido[i];
                var search2 = ' ' + textoDividido[i];
                switch (tipoFiltradoLista){
                    case 0:
                        nuevalista = nuevalista.filter(item => remove_accents(item.valor.toLowerCase()).indexOf(search1)===0);
                        break;
                    case 1: 
                        nuevalista = nuevalista.filter(item => ((remove_accents(item.valor.toLowerCase()).indexOf(search1) === 0) || (remove_accents(item.valor.toLowerCase()).indexOf(search2)>0)));
                        break;
                    case 2:
                        nuevalista = nuevalista.filter(item => ((remove_accents(item.valor.toLowerCase()).indexOf(search1) === 0) || (item.codigo.toLowerCase().indexOf(search1) === 0)));
                        break;
                    case 3:
                        nuevalista = nuevalista.filter(item => ((remove_accents(item.valor.toLowerCase()).indexOf(search1) === 0) || (item.codigo.toLowerCase().indexOf(search1) === 0) || (remove_accents(item.valor.toLowerCase()).indexOf(search2)>0)));
                        break;
                    case 4:
                        nuevalista = nuevalista.filter(item => (remove_accents(item.valor.toLowerCase()).indexOf(search1) >= 0));
                        break;
                    case 5:
                        nuevalista = nuevalista.filter(item => item.valor.split(/[\s\/\\\[\]\(\)\{\},;:.\-\+\*=?¿¡!%$€&@\'\"']+/).find(pal => remove_accents(pal.toLowerCase()).indexOf(search1)===0));
                        break;
                }
            }
            setListaVisible(nuevalista);
    	}else{
            setListaVisible([]);
        }
    }


    const KeyDownSelector = (e) =>{
        const container = GetAntecesorWhithClassName(e.target, 'custom-select-container');
        const ddown = GetSucesorWhithClassName(container, 'dropdown__radios');
        const input = primerHijoConClase(container, 'inputSelector');
       
        if (ddown.classList.contains('show')){
		    var keyCode = e.keyCode;
            var miItem;
            var offset = 160;
            miItem = GetSucesorWhithClassName(ddown,'preselected');
            if (!miItem){
                miItem = GetSucesorWhithClassName(ddown,'active');
            }
            if (keyCode === 27){
                setListaVisible([]);
                input.value = '';
            }

            if (keyCode ===38){
                //UP
                if (miItem)
                    miItem = miItem.previousSibling;
                if (!miItem)
                    miItem = GetLastSucesorWhithClassName(ddown,'labeloption')
                while (miItem && miItem.classList.contains('hide')){
                    miItem = miItem.previousSibling;
                    if (!miItem){
                        miItem = GetLastSucesorWhithClassName(ddown,'labeloption')
                    }
                }
                if (miItem){
                    scrollSiNoVisible(ddown,miItem,offset);
                    RemoveClassNameToChildClassItems(ddown,'labeloption',null,'preselected');
                    miItem.classList.add('preselected');
                }
            }
            if (keyCode === 40){
                //DOWN
                if (miItem)
                    miItem = miItem.nextSibling;
                if (!miItem)
                    miItem = GetSucesorWhithClassName(ddown,'labeloption')
                while (miItem && miItem.classList.contains('hide')){
                    miItem = miItem.nextSibling;
                    if (!miItem){
                        miItem = GetSucesorWhithClassName(ddown,'labeloption')
                    }
                }
                if (miItem){
                    scrollSiNoVisible(ddown,miItem,offset);
                    RemoveClassNameToChildClassItems(ddown,'labeloption',null,'preselected');
                    miItem.classList.add('preselected');
                }
            }
            if (keyCode === 13){
                e.preventDefault();
                e.stopPropagation();
                miItem =  GetSucesorWhithClassName(ddown,'preselected')
                if (miItem){
                    SeleccionaOpcion(miItem);
                }
            }
        }
    }

    const BorraItem = (e) =>{
        var img = e.target;
        const tag = GetAntecesorWhithClassName(img,'tag');
        var value = tag.getAttribute("data-value");
        if (props.alBorrar){
            props.alBorrar(value);
        }
        
    }

    const dropdownclases = (listavisible.length>0) ? "dropdown dropdown__radios show" : "dropdown dropdown__radios"

    var classInputName="inputSelector"
    if (props.estrecho)
        classInputName += " estrecho";

    return (
        <div style={{width:props.ancho}}>
            <div className="module module__filters" style={{paddingRight:'25px'}}>
                <h3 class="title fs fs16 greyColor2" >{props.titulo}</h3>
                <div class="search search__ratios"  style={estiloModuleSelect}>
                    <div class="selects" >
                        <div class="custom-select-container search target" id="custom-select-" onKeyDown={(e) => KeyDownSelector(e)}>
                            <div class="cover cover__search">
                                <img class="lupa" src="./img/ficha/lupaPurple.svg" />
                                <input type="text" placeholder={textPlaceholder} className={classInputName} onKeyUp={(e) => KeyUpInput(e)}/>                        
                            </div>
                            <select class="custom-select" id={idHTML} style={{display:"none"}}  data-target='tags-1' multiple>
                                {optionsHTML}
                            </select>
                            <div class={clasesCoverSelect} onClick={(e) => ClickEnCoverSelect(e)}>
                                <div class="select-label" >
                        
                                </div>
                            </div>
                            <div class={dropdownclases}>
                                {optionsDisplayed}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="radios radios__full radio_subfield">
                    <label>
                        <input type="checkbox" name="negado" checked={propiedades.negado}  onClick={(e) => ClickNegado(e)}/>
                        NO
                    </label>
                </div>
                {(!props.ocultaTags) ?
                <div class="tags" id="tags-1" >
                    {HTMLTagsSelected}
                </div>
                :
                <div class="tags" id="tags-1" ></div>
                }
            </div>
        </div>
    );
}

export default SelectorMulti2No;

