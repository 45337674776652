const FilaNombreMed = (props) => {
    const nombre = props.nombre;
    const clasesDivNombre = 'column fs fs12 fw greenColor4';
    const clasesDivCompos = 'column fs fs12 fw greenColor4 FlamaBook';

    const CambiaPaginaMed = (idMed) => {
        props.cambiaPagina({
            newpagina: 'BMismoNombreMUH',
            newid: idMed,
        });
    }

    const CambiaPaginaPA = (idPA) => {
        props.cambiaPagina({
            newpagina: 'FichaPAH',
            newid: idPA,
        });
    }

    const HTMLNombresComponentes = nombre.Componentes.map(pa => 
        <div class="table__row table__row__normal fw fw300 uppercase" style={{border:'none'}}>
        <div class={clasesDivCompos} style={{justifyContent:'left', padding:'0px'}}>
        <p>
            <span style={{cursor:'pointer', display: 'block', width: '100%', textAlign: 'left'}} 
                onClick={(e) => CambiaPaginaPA(pa.Codigo)}
                onMouseOver={(e) => e.target.style.fontWeight='bold'}
                onMouseOut={(e) => e.target.style.fontWeight='normal'}
            >
                {pa.Valor}
            </span>
        </p>
        </div>
        </div>
    );


    return (
        <div class="table__row table__row__normal fw fw300 uppercase">
            <div class={clasesDivNombre} style={{justifyContent:'left'}} >
                <p>
                    <span style={{cursor:'pointer'}} 
                        onClick={(e) => CambiaPaginaMed(nombre.IdMed)}
                        onMouseOver={(e) => e.target.style.fontWeight='bold'}
                        onMouseOut={(e) => e.target.style.fontWeight='normal'}
                    >
                        {nombre.NombreMed}
                    </span>
                </p>
            </div>
            <div class={clasesDivCompos} style={{justifyContent:'left'}}>
                <div class="table" style={{width:'100%', border:'none'}}>
                    {HTMLNombresComponentes}
                </div>
            </div>
        </div>
    )
}
export default FilaNombreMed;
