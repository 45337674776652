import axios from "axios";
import authHeader from "./auth-header";
import { fromListaOpsAvanzadaToParamURL, fromListaPAsComposToParamURL, getApiPath } from "./utils";

//const API_URL = "http://localhost:36823/botplusapi/";
//const API_URL = "http://nitramsoftware.linkpc.net:8899/botplusapi/";
//const API_URL = "/api/botplusapi/";
const API_URL = getApiPath();

const getMedsByName = (txt, opb, idxOrden, primero, max) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    var aux="&opb=1"
    if (opb === 2)
        aux ="&opb=2"
    return axios.get(API_URL + "BotPlus2022/porTexto?txt="+encodeURIComponent(txt) + aux + strorden, { headers: authHeader() });
};

const getFichaMUH = (id) => {
  return axios.get(API_URL + "BotPlus2022/fichaMUH?id="+id, { headers: authHeader() });
};

const getFichaEXT = (id) => {
  return axios.get(API_URL + "BotPlus2022/fichaEXT?id="+id, { headers: authHeader() });
};

const getFichaMUV = (id) => {
  return axios.get(API_URL + "BotPlus2022/fichaMUV?id="+id, { headers: authHeader() });
};

const getFichaFM = (id) => {
  return axios.get(API_URL + "BotPlus2022/fichaFM?id="+id, { headers: authHeader() });
};

const getFichaPF = (id) => {
  return axios.get(API_URL + "BotPlus2022/fichaPF?id="+id, { headers: authHeader() });
};

const getFichaPA = (id,tipo) => {
  return axios.get(API_URL + "BotPlus2022/fichaPA?id="+id + '&tipo=' + tipo, { headers: authHeader() });
};

const getFichaING = (id) => {
  return axios.get(API_URL + "BotPlus2022/fichaING?id="+id, { headers: authHeader() });
};

const getFichaASO = (id,tipo) => {
  return axios.get(API_URL + "BotPlus2022/fichaASO?id="+id + '&tipo=' + tipo, { headers: authHeader() });
};

const getFichaPAT = (id) => {
  return axios.get(API_URL + "BotPlus2022/fichaPAt?id="+id, { headers: authHeader() });
};

const getComposM = (id, idCPor, idFF) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosComposicion?id="+id+"&idComposPor="+idCPor+"&idff="+idFF, { headers: authHeader() });
};

const getComposPF = (id, idCPor, idFF) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosComposicionPF?id="+id+"&idComposPor="+idCPor+"&idff="+idFF, { headers: authHeader() });
};

const getGruposMUH = (id, codGrupoATC, nivelGrupo, codGrupoVMPP) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosGrupos?id="+id+"&codGrupoATC="+codGrupoATC+"&nivelGrupo="+nivelGrupo+"&codGrupoVMPP="+codGrupoVMPP, { headers: authHeader() });
};

const getGruposPF = (id, codGrupoATC, nivelGrupo, codGrupoVMPP) => {
  var strCodGrupoATC = (codGrupoATC ? codGrupoATC.toString() : '')
  return axios.get(API_URL + "BotPlus2022/GetDatosGruposPF?id="+id+"&codGrupoCon="+codGrupoATC+"&nivelGrupo="+nivelGrupo+"&codGrupoNom="+ codGrupoVMPP, { headers: authHeader() });
};

const getTextosMUH = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosTextos?id="+id, { headers: authHeader() });
};

const getTextosPF = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosTextosPF?id="+id, { headers: authHeader() });
};

const getTextosPA = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosTextosPA?id="+id, { headers: authHeader() });
};

const getTextosING = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosTextosING?id="+id, { headers: authHeader() });
};

const getAdvertenciasMUH = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosAdvertencias?id="+id, { headers: authHeader() });
};

const getAdvertenciasPF = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosAdvertenciasPF?id="+id, { headers: authHeader() });
};

const getAdvertenciasPA = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosAdvertenciasPA?id="+id, { headers: authHeader() });
};

const getAdvertenciasING = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosAdvertenciasING?id="+id, { headers: authHeader() });
};

const getAdvertenciasASO = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosAdvertenciasASO?id="+id, { headers: authHeader() });
};

const getConsejosMUH = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosConsejos?id="+id, { headers: authHeader() });
};

const getPrecaucionExtremaMUH = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosPrecaucionExtrema?id="+id, { headers: authHeader() });
};

const getPrecaucionExtremaPAH = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosPrecaucionExtremaPA?id="+id, { headers: authHeader() });
};

const getInterferenciasMUH = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosAnalisis?id="+id, { headers: authHeader() });
};

const getInterferenciasPA = (id) => {
    return axios.get(API_URL + "BotPlus2022/GetDatosAnalisisPA?id="+id, { headers: authHeader() });
};
    
const getInterferenciasASO = (id) => {
    return axios.get(API_URL + "BotPlus2022/GetDatosAnalisisASO?id="+id, { headers: authHeader() });
};
    
const getInterferenciasPAT = (id) => {
    return axios.get(API_URL + "BotPlus2022/GetDatosAnalisisPAT?id="+id, { headers: authHeader() });
};
    
const getNombresSimilaresPAH = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetNombresSimilaresPA?id="+id, { headers: authHeader() });
};

const getNombresSimilaresMUH = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetNombresSimilaresMed?id="+id, { headers: authHeader() });
};

const getFarmacogeneticaMUH = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetFarmacogeneticaMed?id="+id, { headers: authHeader() });
};

const getFarmacogeneticaPA = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetFarmacogeneticaPA?id="+id, { headers: authHeader() });
};

const getFarmacogeneticaASO = (id) => {
    return axios.get(API_URL + "BotPlus2022/GetFarmacogeneticaASO?id="+id, { headers: authHeader() });
 };
  
const getConservacionMUH = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosConservacion?id="+id, { headers: authHeader() });
};

const getConservacionPF = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosConservacionPF?id="+id, { headers: authHeader() });
};

const getHistoricoMUH = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosHistorico?id="+id, { headers: authHeader() });
};

const getHistoricoPF = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosHistoricoPF?id="+id, { headers: authHeader() });
};

const getHistoricoPA = (id) => {
    return axios.get(API_URL + "BotPlus2022/GetDatosHistoricoPA?id="+id, { headers: authHeader() });
};
    
const getHistoricoING = (id) => {
    return axios.get(API_URL + "BotPlus2022/GetDatosHistoricoING?id="+id, { headers: authHeader() });
};
    
const getHistoricoASO = (id) => {
    return axios.get(API_URL + "BotPlus2022/GetDatosHistoricoASO?id="+id, { headers: authHeader() });
};
    
const getAlertasCalidadMUH = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosAlertasC?id="+id, { headers: authHeader() });
};

const getAlertasCalidadPF = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosAlertasCPF?id="+id, { headers: authHeader() });
};

const getProblemasSuministroMUH = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosProblemasS?id="+id, { headers: authHeader() });
};

const getCismedMUH = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosCismed?id="+id, { headers: authHeader() });
};

const getInformacionAdicionalMUH = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosInfoAdicional?id="+id, { headers: authHeader() });
};

const getInformacionAdicionalPF = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosInfoAdicionalPF?id="+id, { headers: authHeader() });
};

const getInformacionAdicionalPA = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosInfoAdicionalPA?id="+id, { headers: authHeader() });
};

const getInformacionAdicionalING = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosInfoAdicionalING?id="+id, { headers: authHeader() });
};

const getInformacionAdicionalASO = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosInfoAdicionalASO?id="+id, { headers: authHeader() });
};

const getInformacionAdicionalPAT = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosInfoAdicionalPAT?id="+id, { headers: authHeader() });
};

const getVideosMUH = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosVideos?id="+id, { headers: authHeader() });
};

const getVideosPF = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosVideosPF?id="+id, { headers: authHeader() });
};

const getVideosPA = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosVideosPA?id="+id, { headers: authHeader() });
};

const getVideosING = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosVideosING?id="+id, { headers: authHeader() });
};

const getVideosASO = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosVideosASO?id="+id, { headers: authHeader() });
};

const getVideosPAT = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosVideosPAT?id="+id, { headers: authHeader() });
};

const getIdentificacionMUH = (id,idFF) => {
  return axios.get(API_URL + "BotPlus2022/GetDatosIdentificacion?id="+id+"&idFF="+idFF, { headers: authHeader() });
};

const getMensajesEmergentesMUH = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetMensajesEmergentes?id="+id, { headers: authHeader() });
};

const getMensajesEmergentesPPF = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetMensajesEmergentesPF?id="+id, { headers: authHeader() });
};

const getMensajesEmergentesPAH = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetMensajesEmergentesPA?id="+id, { headers: authHeader() });
};

const getSinonimiasPA = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetSinonimiasPA?id="+id, { headers: authHeader() });
};

const getSinonimiasING = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetSinonimiasING?id="+id, { headers: authHeader() });
};

const getSinonimiasPAT = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetSinonimiasPAT?id="+id, { headers: authHeader() });
};

const getDopajeInfoPA = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDopajeInfo?id="+id, { headers: authHeader() });
};

const getDopajeInfoASO = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetDopajeInfoASO?id="+id, { headers: authHeader() });
};

const getProductoresInfoPA = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetProductoresInfo?id="+id, { headers: authHeader() });
};

const getTiemposEsperaMUV = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetTiemposEspera?id="+id, { headers: authHeader() });
};

const getContenidoPackPF = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetContenidoPack?id="+id, { headers: authHeader() });
};

const getPacksContenedoresPF = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetPackContenedores?id="+id, { headers: authHeader() });
};

const getJerarquiaInfoPAT = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetJerarquiaInfo?id="+id, { headers: authHeader() });
};

const getCIEInfoPAT = (id) => {
  return axios.get(API_URL + "BotPlus2022/GetCIEInfo?id="+id, { headers: authHeader() });
};

const getBuscadoresPreferidos = () => {
  return axios.get(API_URL + "BotPlus2022/GetBuscadoresPreferidos", { headers: authHeader() });
};

const getNovedades = () => {
  return axios.get(API_URL + "BotPlus2022/Novedades?maxRegs=5", { headers: authHeader() });
};

const getProductosConPS = (num) => {
  return axios.get(API_URL + "BotPlus2022/ConPSuministro?maxRegs=" + num.toString(), { headers: authHeader() });
};

const getUltimasNotasYAlertas = (num) => {
  return axios.get(API_URL + "BotPlus2022/AlertasYNotas?maxRegs=" + num.toString(), { headers: authHeader() });
};

const getHistorial = (num) => {
    return axios.get(API_URL + "BotPlus2022/Historial?maxRegs=" + num.toString(), { headers: authHeader() });
  };
  
const emptyHistorial = () => {
    return axios.get(API_URL + "BotPlus2022/VaciaHistorial", { headers: authHeader() });
  };
  
const getDatosConfiguracion = () => {
  return axios.get(API_URL + "BotPlus2022/DatosConfiguracion", { headers: authHeader() });
};

const setDatosConfiguracion = (idProv, numFarm) => {
    return axios.get(API_URL + "BotPlus2022/SetDatosConfiguracion?idProv=" + idProv.toString() + "&numFarm=" + numFarm, { headers: authHeader() });
};
  
const getLaboratorios = (tipo) => {
    return axios.get(API_URL + "BotPlus2022/GetListaLaboratorios?tipo=" + tipo, { headers: authHeader() });
};
  
const medicamentosLaboratorio = (id,idxOrden) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/MedsPorLaboratorio?id=" + id + strorden, { headers: authHeader() });
};
  
const medicamentosMismoNombre = (id,idxOrden) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetMedicamentosMismoNombre?id=" + id + strorden, { headers: authHeader() });
};
  
const medicamentosLaboratorios = (ids,idxOrden) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/MedsPorLaboratorios?ids=" + ids.toString() + strorden, { headers: authHeader() });
};
  
const productosLaboratorio = (id,idxOrden) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/PFPorLaboratorio?id=" + id + strorden, { headers: authHeader() });
};
  
const productosLaboratorios = (ids,idxOrden) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/PFPorLaboratorios?ids=" + ids.toString() + strorden, { headers: authHeader() });
};
  
const getAgrupaciones = (tipo, auto) => {
    if ((tipo==="V") || (tipo==='H'))
        return axios.get(API_URL + "BotPlus2022/GetListaAgrupaciones?auto=" + auto, { headers: authHeader() });
    else
        return axios.get(API_URL + "BotPlus2022/GetListaAgrupacionesPF?auto=" + auto, { headers: authHeader() });
};
  
const getFormasFarma = (tipo) => {
    return axios.get(API_URL + "BotPlus2022/GetListaFormaFarma?tipo=" + tipo, { headers: authHeader() });
};
  
const getActividades = (tipo, tipoActividad) => {
    return axios.get(API_URL + "BotPlus2022/GetListaActividades?tipo=" + tipo + '&tipoActividad=' + tipoActividad, { headers: authHeader() });
};
  
const getAnalisis = () => {
    return axios.get(API_URL + "BotPlus2022/GetListaAnalisis", { headers: authHeader() });
};
  
const getBiomarcadores = () => {
    return axios.get(API_URL + "BotPlus2022/GetListaBiomarcadores", { headers: authHeader() });
};

const getGruposATC = (tipo) => {
    return axios.get(API_URL + "BotPlus2022/GetGruposATC?tipo=" + tipo, { headers: authHeader() });
};

const getGruposCon = () => {
    return axios.get(API_URL + "BotPlus2022/GetGruposCon", { headers: authHeader() });
};

const getGruposVMPP = () => {
    return axios.get(API_URL + "BotPlus2022/GetGruposVMPP", { headers: authHeader() });
};

const getArbolGruposVMPP = (tipo, aux, id, max) => {
    return axios.get(API_URL + "BotPlus2022/GetArbolGruposVMPP?id=" + id + "&max="+ max.toString(), { headers: authHeader() });
};

const getGruposSan = () => {
    return axios.get(API_URL + "BotPlus2022/GetGruposSan", { headers: authHeader() });
};

const medicamentosAgrupacionAuto = (id,idxOrden) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/MedsPorAgrupacionAuto?id=" + id + strorden, { headers: authHeader() });
};

const productosAgrupacionAuto = (id,idxOrden) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/PFPorAgrupacionAuto?id=" + id + strorden, { headers: authHeader() });
};  

const medicamentosAgrupacioNac = (id,idxOrden) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/MedsPorAgrupacionNacional?id=" + id + strorden, { headers: authHeader() });
};  

const productosAgrupacionNac = (id,idxOrden) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/PFPorAgrupacionNacional?id=" + id + strorden, { headers: authHeader() });
};  

const medicamentosFormaFarma = (id,idxOrden,max,idb) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/MedsPorFormaFarma?id=" + id + "&max=" + max + "&idBusqueda=" + idb + strorden, { headers: authHeader() });
};  

const productosFormaFarma = (id,idxOrden,max,idb) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/PFPorFormaFarma?id=" + id + "&max=" + max + "&idBusqueda=" + idb + strorden, { headers: authHeader() });
};  

const medicamentosFormasFarma = (ids,idxOrden,max,idb) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/MedsPorFormasFarma?ids=" + ids.toString() + "&max=" + max + "&idBusqueda=" + idb + strorden, { headers: authHeader() });
};  

const productosFormasFarma = (ids,idxOrden,max,idb) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/PFPorFormasFarma?ids=" + ids.toString() + "&max=" + max + "&idBusqueda=" + idb + strorden, { headers: authHeader() });
};  

const principiosActividad = (id,idxOrden) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/PAPorActividad?id=" + id + strorden, { headers: authHeader() });
};  

const ingredientesActividad = (id,idxOrden) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/IngPorActividad?id=" + id + strorden, { headers: authHeader() });
};  

const principiosActividadesY = (ids,idxOrden) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/PAPorActividadesY?ids=" + ids.toString() + strorden, { headers: authHeader() });
};  

const ingredientesActividadesY = (ids,idxOrden) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/IngPorActividadesY?ids=" + ids.toString() + strorden, { headers: authHeader() });
};  

const principiosActividadesO = (ids,idxOrden) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/PAPorActividadesO?ids=" + ids.toString() + strorden, { headers: authHeader() });
};  

const ingredientesActividadesO = (ids,idxOrden) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/IngPorActividadesO?ids=" + ids.toString() + strorden, { headers: authHeader() });
};  

const medicamentosAnalisis = (id,idxOrden,max,idb) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/PorAnalisis?id=" + id + "&max=" + max + "&idBusqueda=" + idb + strorden, { headers: authHeader() });
};  

const medicamentosBiomarcador = (id,idxOrden,max,idb) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/PorBiomarcador?id=" + id + "&max=" + max + "&idBusqueda=" + idb + strorden, { headers: authHeader() });
};  

const medicamentosConPA = (id,idxOrden) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/MedsPorComponentePA?id=" + id + strorden, { headers: authHeader() });
};  

const medicamentosConAso = (id,idxOrden) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/MedsPorComponenteAso?id=" + id + strorden, { headers: authHeader() });
};  

const productosConIng = (id,idxOrden) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/PFPorComponente?id=" + id + strorden, { headers: authHeader() });
};  

const productosTipo = (id,idxOrden,max,idb) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/PFPorClase?id=" + id + "&max=" + max + "&idBusqueda=" + idb + strorden, { headers: authHeader() });
};  

const productosSubtipo = (id,idxOrden,max,idb) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/PFPorSubClase?id=" + id + "&max=" + max + "&idBusqueda=" + idb + strorden, { headers: authHeader() });
};  

const medicamentosPorEnf = (id,idepi,idxOrden,max,idb) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/PorEnfermedad?id=" + id + "&idEpi=" + idepi + "&max=" + max + "&idBusqueda=" + idb + strorden, { headers: authHeader() });
};  

const productosPorEnf = (id,idxOrden,max,idb) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/PorEnfermedadPF?id=" + id + "&max=" + max + "&idBusqueda=" + idb + strorden, { headers: authHeader() });
};  

const medicamentosGTATC = (id,idxOrden,max,idb) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/PorGTATC?id=" + id + "&max=" + max + "&idBusqueda=" + idb + strorden, { headers: authHeader() });
};  

const medicamentos2GTATC = (ids,idxOrden,max,idb) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/PorGTATC2?ids=" +  ids.toString() + "&max=" + max + "&idBusqueda=" + idb + strorden, { headers: authHeader() });
};  

const productosGTCon = (id,idxOrden,max,idb) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/PorGTCon?id=" + id + "&max=" + max + "&idBusqueda=" + idb + strorden, { headers: authHeader() });
};  

const medicamentosGTVMPP = (id,idxOrden) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/PorGTVMPP?id=" + id + strorden, { headers: authHeader() });
};  

const productosGTSan = (id,idxOrden) => {
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/PorGTSan?id=" + id + strorden, { headers: authHeader() });
};  

const datosFormularioSaborEdad = () => {
    return axios.get(API_URL + "BotPlus2022/DatosFormSaborEdad", { headers: authHeader() });
};  

const datosFormularioTest = () => {
    return axios.get(API_URL + "BotPlus2022/DatosFormTest", { headers: authHeader() });
};  

const descargaInfoSalesYCantidades = (id,tipo) => {
    if (tipo==="P")
        return descargaInfoSalesYCantidadesIng(id);
    else
        return axios.get(API_URL + "BotPlus2022/GetInfoPosiblesSalesYCantidades?id=" + id, { headers: authHeader() });
};  

const descargaInfoSalesYCantidadesIng = (id) => {
    return axios.get(API_URL + "BotPlus2022/GetInfoPosiblesSalesYCantidadesIng?id=" + id, { headers: authHeader() });
};  
    
const descargaListaInfoSalesYCantidades = (ids,tipo) => {
    if (tipo==="P")
        return descargaListaInfoSalesYCantidadesIng(ids);
    else
        return axios.get(API_URL + "BotPlus2022/GetListaInfoPosiblesSalesYCantidades?ids=" + ids.toString(), { headers: authHeader() });
};  

const descargaListaInfoSalesYCantidadesIng = (ids) => {
    return axios.get(API_URL + "BotPlus2022/GetListaInfoPosiblesSalesYCantidadesIng?ids=" + ids.toString(), { headers: authHeader() });
};  

const productosSaborEdad = (info) => {
    var strParams = "?se";
    if (info.idDieto){
        strParams += "&idDieto=" + info.idDieto;
    }
    if (info.idsSabores && info.idsSabores.length>0){
        strParams += "&idsSabores=" + info.idsSabores.toString();
    }
    if (info.saborSolo){
        strParams += "&saborSolo=S"
    }
    if (info.edad){
        strParams += "&edad=" + info.edad.toString();
    }
    if (info.undEdad){
        strParams += "&undEdad=" + info.undEdad;
    }

    const strorden = (info.idxOrden>0) ? "&idxOrden="+info.idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetProductosSaborEdad" + strParams + strorden, { headers: authHeader() });
};  

const medicamentosEspecieIndicacion = (idEnf, idEsp, idxOrden) =>{
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetMedicamentosEspecieIndicacion?idEnf=" + idEnf + "&idEspecie=" + idEsp + strorden, { headers: authHeader() });
}

const datosFormularioEspecieIndicacion = () => {
    return axios.get(API_URL + "BotPlus2022/DatosFormEspecieIndicacion", { headers: authHeader() });
};  

const medicamentosProblemasSuministro = (info, idxOrden) =>{
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    var strParams = "?ps";
    if (info.txt && info.txt !== ''){
        strParams += "&txt=" + info.txt;
    }
    if (info.idLab>0){
        strParams += "&idLab=" + info.idLab.toString();
    }
    if (info.idPA>0){
        strParams += "&idPA=" + info.idPA.toString();
    }
    if (info.medExt){
        strParams += "&medExt=S"
    }
    if (info.noSust){
        strParams += "&noSust=S"
    }
    if (info.iniciod){
        strParams += "&fini0=" + Math.round(info.iniciod.getTime()/1000).toString();
    }
    if (info.inicioh){
        strParams += "&fini1=" + Math.round(info.inicioh.getTime()/1000).toString();
    }
    if (info.find){
        strParams += "&ffin0=" + Math.round(info.find.getTime()/1000).toString();
    }
    if (info.finh){
        strParams += "&ffin1=" + Math.round(info.finh.getTime()/1000).toString();
    }

    return axios.get(API_URL + "BotPlus2022/GetMedicamentosProblemasSuministro" + strParams + strorden, { headers: authHeader() });
}

const datosFormularioProblemasSuministro= () => {
    return axios.get(API_URL + "BotPlus2022/DatosFormProblemasSuministro", { headers: authHeader() });
};  

const medicamentosAlertasCalidad = (info, idxOrden, tipo) =>{
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    var strParams = "?ac";
    if (info.txt && info.txt !== ''){
        strParams += "&txt=" + info.txt;
    }
    if (info.txtLote && info.txtLote !== ''){
        strParams += "&txtLote=" + info.txtLote;
    }
    if (info.idLab>0){
        strParams += "&idLab=" + info.idLab.toString();
    }
    if (info.idPA>0){
        strParams += "&idPA=" + info.idPA.toString();
    }
    if (info.inicio){
        strParams += "&fini=" + Math.round(info.inicio.getTime()/1000).toString();
    }
    if (info.fin){
        strParams += "&ffin=" + Math.round(info.fin.getTime()/1000).toString();
    }
    if (tipo){
        strParams += "&tipo=" + tipo;
    }

    return axios.get(API_URL + "BotPlus2022/GetMedicamentosAlertasCalidad" + strParams + strorden, { headers: authHeader() });
}

const PFAlertasCalidad = (info, idxOrden) =>{
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    var strParams = "?ac";
    if (info.txt && info.txt !== ''){
        strParams += "&txt=" + info.txt;
    }
    if (info.txtLote && info.txtLote !== ''){
        strParams += "&txtLote=" + info.txtLote;
    }
    if (info.idLab>0){
        strParams += "&idLab=" + info.idLab.toString();
    }
    if (info.idPA>0){
        strParams += "&idPA=" + info.idPA.toString();
    }
    if (info.inicio){
        strParams += "&fini=" + Math.round(info.inicio.getTime()/1000).toString();
    }
    if (info.fin){
        strParams += "&ffin=" + Math.round(info.fin.getTime()/1000).toString();
    }

    return axios.get(API_URL + "BotPlus2022/GetPFAlertasCalidad" + strParams + strorden, { headers: authHeader() });
}

const datosFormularioAlertasCalidad= (tipo) => {
    return axios.get(API_URL + "BotPlus2022/DatosFormAlertasCalidad?tipo="+ tipo, { headers: authHeader() });
};  

const medicamentosReaccionesAdversas = (info,max,idb) => {
        var strParams = "?ra";
        if (info.idEpi){
            strParams += "&idEpi=" + info.idEpi;
        }
        if (info.idsEnfs && info.idsEnfs.length>0){
            strParams += "&idsEnfs=" + info.idsEnfs.toString();
        }
        if (info.idsMeds && info.idsMeds.length>0){
            strParams += "&idsMeds=" + info.idsMeds.toString();
        }
    
        const strorden = (info.idxOrden>0) ? "&idxOrden="+info.idxOrden.toString() : "";
        return axios.get(API_URL + "BotPlus2022/GetMedicamentosPorReaccionAdversa" + strParams + "&max=" + max + "&idBusqueda=" + idb + strorden, { headers: authHeader() });
    };  
    
const datosFormularioReaccionesAdversas = () => {
    return axios.get(API_URL + "BotPlus2022/DatosFormReaccionesAdversas", { headers: authHeader() });
};  

const productosPorComposicion = (info) => {
    var tipo = info.codigoPagina.substring(info.codigoPagina.length-1);
    if (tipo==="F")
        tipo = "P"
    var strParams = "?tipo=" + tipo;
    if (tipo==="P" && info.tipoProd){
        strParams += "&tipoProd=" + info.tipoProd;
    }
    if (info.pas && info.pas.length>0){
        strParams += "&pas=" + fromListaPAsComposToParamURL(info.pas);
    }
    if (info.solo){
        strParams += "&solo=S"
    }else{
        strParams += "&solo=N"
    }
    if (info.idVia>0){
        strParams += "&idVia=" + info.idVia.toString();
    }
    if (info.idFF>0){
        strParams += "&idFF=" + info.idFF.toString();
    }
    if (info.uds>0){
        strParams += "&uds=" + info.uds.toString();
    }
    if (info.pv>0){
        strParams += "&pv=" + info.pv.toString();
    }
    if (info.ints && info.ints.length>0){
        strParams += "&ints=" + info.ints.toString();
    }

    const strorden = (info.idxOrden>0) ? "&idxOrden="+info.idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/PorComposicion" + strParams + strorden, { headers: authHeader() });
};  

const datosFormularioComposicion = (tipo, tipoProd, pas) => {
    //var tipo = info.codigoPagina.substring(info.codigoPagina.length-1);
    var strTipoProd = "";
    if (tipo==="F"){
        tipo = "P"
    }
    if (tipo==="P"){
        strTipoProd = "&tipoProd=" + tipoProd.toString();
    }

    return axios.get(API_URL + "BotPlus2022/DatosFormComposicion?tipo=" + tipo + strTipoProd + "&pas=" + fromListaPAsComposToParamURL(pas), { headers: authHeader() });
};  

const actualizaFFyVias = (tipo, tipoProd, pas) => {
    //var tipo = info.codigoPagina.substring(info.codigoPagina.length-1);
    var strTipoProd = "";
    if (tipo==="F"){
        tipo = "P"
    }
    if (tipo==="P"){
        strTipoProd = "&tipoProd=" + tipoProd.toString();
    }

    return axios.get(API_URL + "BotPlus2022/FormasFarmaYVias?tipo=" + tipo + strTipoProd + "&pas=" + fromListaPAsComposToParamURL(pas), { headers: authHeader() });
};  

const datosFormularioAvanzadaMUH = () => {
    return axios.get(API_URL + "BotPlus2022/DatosFormAvanzadaMUH", { headers: authHeader() });
};  

const medicamentosAvanzadaMUH = (info,max,idb) => {
    var strParams = "?av";

    if (info.txt && info.txt !== '')
        strParams += "&med=" + info.txt;
    /*if (info.idLab && info.idLab > 0)
        strParams += "&idLab=" + info.idLab.toString();
    if (info.idGT && info.idGT !== '')
        strParams += "&idGT=" + info.idGT;*/
    if (info.idsLab && (info.idsLab.length > 0))
        strParams += "&idsLab=" + info.idsLab.toString();
    if (info.idsGT && (info.idsGT.length > 0))
        strParams += "&idsGT=" + info.idsGT.toString();

    if (info.pas && (info.pas.length > 0))
        strParams += "&pas=" + fromListaPAsComposToParamURL(info.pas);
    if (info.solo)
        strParams += "&solo=S";
    else
        strParams += "&solo=N";
    if (info.idVia>0)
        strParams += "&idVia=" + info.idVia.toString();
    if (info.idFF>0)
        strParams += "&idFF=" + info.idFF.toString();
    if (info.edad)
        strParams += "&edad=" + info.edad.toString();
    if (info.undEdad)
        strParams += "&undEdad=" + info.undEdad;
    if (info.ints && (info.ints.length > 0))
        strParams += "&ints=" + info.ints.toString();
    if (info.ops && (info.ops.length > 0))
        strParams += "&ops=" + fromListaOpsAvanzadaToParamURL(info.ops);
    if (info.uds && info.uds>0)
        strParams += "&uds=" + info.uds.toString();
    if (info.emr && info.emr>0)
        strParams += "&emr=" + info.emr.toString();
    if (info.fechaAutoIni){
        strParams += "&fechaAutoIni=" + Math.round(info.fechaAutoIni.getTime()/1000).toString();
    }
    if (info.fechaAutoFin){
        strParams += "&fechaAutoFin=" + Math.round(info.fechaAutoFin.getTime()/1000).toString();
    }
    if (info.fechaComerIni){
        strParams += "&fechaComerIni=" + Math.round(info.fechaComerIni.getTime()/1000).toString();
    }
    if (info.fechaComerFin){
        strParams += "&fechaComerFin=" + Math.round(info.fechaComerFin.getTime()/1000).toString();
    }
    if (info.fechaBajaIni){
        strParams += "&fechaBajaIni=" + Math.round(info.fechaBajaIni.getTime()/1000).toString();
    }
    if (info.fechaBajaFin){
        strParams += "&fechaBajaFin=" + Math.round(info.fechaBajaFin.getTime()/1000).toString();
    }
    if (info.fechaInSNSIni){
        strParams += "&fechaInSNSIni=" + Math.round(info.fechaInSNSIni.getTime()/1000).toString();
    }
    if (info.fechaInSNSFin){
        strParams += "&fechaInSNSFin=" + Math.round(info.fechaInSNSFin.getTime()/1000).toString();
    }
    if (info.fechaExSNSIni){
        strParams += "&fechaExSNSIni=" + Math.round(info.fechaExSNSIni.getTime()/1000).toString();
    }
    if (info.fechaExSNSFin){
        strParams += "&fechaExSNSFin=" + Math.round(info.fechaExSNSFin.getTime()/1000).toString();
    }
    if (info.fechaExFIIni){
        strParams += "&fechaExFIIni=" + Math.round(info.fechaExFIIni.getTime()/1000).toString();
    }
    if (info.fechaExFIFin){
        strParams += "&fechaExFIFin=" + Math.round(info.fechaExFIFin.getTime()/1000).toString();
    }
    if (info.pre && info.pre>0 && info.idOpP && info.idOpP>0 && info.idCampoP && info.idCampoP !== '')
    {
        strParams += "&pre=" + Math.trunc(info.pre*100).toString() + "&idOpP=" + info.idOpP.toString() + "&idCampoP=" + info.idCampoP;
    }
    if (info.idPredefP>0)
    {
        strParams += "&idPredefP=" + info.idPredefP.toString();
    }
    if (info.idsAA && (info.idsAA.length > 0))
        strParams += "&idsAA=" + info.idsAA.toString();
    if (info.idsAN && (info.idsAN.length > 0))
        strParams += "&idsAN=" + info.idsAN.toString();
    if (info.idsDC && (info.idsDC.length > 0))
        strParams += "&idsDC=" + info.idsDC.toString();
    if (info.idsMen && (info.idsMen.length > 0))
        strParams += "&idsMen=" + info.idsMen.toString();
    if (info.texto && info.texto !== '' && info.texto.length>2)
        strParams += "&texto=" + encodeURI(info.texto);
    if (info.noTexto)
        strParams += "&noTexto=S";
    if (info.idEpi>0)
        strParams += "&idEpi=" + info.idEpi.toString();

    if (info.enfsRel && (info.enfsRel.length > 0)){
        var strEnf = "";
        for (var i=0; i<info.enfsRel.length; i++){
            if (i>0) strEnf += ","
            strEnf += info.enfsRel[i].codigo + "_" + info.enfsRel[i].epi;
        }
        strParams += "&enfsRel=" + strEnf;
    }

    if (info.anasRel && (info.anasRel.length > 0)){
        var strAna = "";
        for (var i=0; i<info.anasRel.length; i++){
            if (i>0) strAna += ","
            strAna += info.anasRel[i].codigo + "_" + info.anasRel[i].epi;
        }
        strParams += "&anasRel=" + strAna;
    }

    const strorden = (info.idxOrden>0) ? "&idxOrden="+info.idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/AvanzadaMUH" + strParams + "&max=" + max + "&idBusqueda=" + idb + strorden, { headers: authHeader() });
};  

const datosFormularioAvanzadaMUV = () => {
    return axios.get(API_URL + "BotPlus2022/DatosFormAvanzadaMUV", { headers: authHeader() });
};  

const medicamentosAvanzadaMUV = (info,max,idb) => {
    var strParams = "?av";

    if (info.txt && info.txt !== '')
        strParams += "&med=" + info.txt;
    /*if (info.idLab && info.idLab > 0)
        strParams += "&idLab=" + info.idLab.toString();
    if (info.idGT && info.idGT !== '')
        strParams += "&idGT=" + info.idGT;*/
    if (info.idsLab && (info.idsLab.length > 0))
        strParams += "&idsLab=" + info.idsLab.toString();
    if (info.idsGT && (info.idsGT.length > 0))
        strParams += "&idsGT=" + info.idsGT.toString();
    if (info.pas && (info.pas.length > 0))
        strParams += "&pas=" + fromListaPAsComposToParamURL(info.pas);
    if (info.solo)
        strParams += "&solo=S";
    else
        strParams += "&solo=N";
    if (info.idVia>0)
        strParams += "&idVia=" + info.idVia.toString();
    if (info.idFF>0)
        strParams += "&idFF=" + info.idFF.toString();
    if (info.uds && info.uds>0)
        strParams += "&uds=" + info.uds.toString();
    if (info.fechaAutoIni){
        strParams += "&fechaAutoIni=" + Math.round(info.fechaAutoIni.getTime()/1000).toString();
    }
    if (info.fechaAutoFin){
        strParams += "&fechaAutoFin=" + Math.round(info.fechaAutoFin.getTime()/1000).toString();
    }
    if (info.fechaBajaIni){
        strParams += "&fechaBajaIni=" + Math.round(info.fechaBajaIni.getTime()/1000).toString();
    }
    if (info.fechaBajaFin){
        strParams += "&fechaBajaFin=" + Math.round(info.fechaBajaFin.getTime()/1000).toString();
    }
    if (info.ops && (info.ops.length > 0))
        strParams += "&ops=" + fromListaOpsAvanzadaToParamURL(info.ops);
    if (info.idEspecie && info.idEspecie > 0)
        strParams += "&idEspecie=" + info.idEspecie.toString();
    if (info.idEnf && info.idEnf > 0)
        strParams += "&idEnf=" + info.idEnf.toString();
    if (info.idsMen && (info.idsMen.length > 0))
        strParams += "&idsMen=" + info.idsMen.toString();
    if (info.texto && info.texto !== '' && info.texto.length>2)
        strParams += "&texto=" + encodeURI(info.texto);
    if (info.noTexto)
        strParams += "&noTexto=S";
    if (info.idEpi>0)
        strParams += "&idEpi=" + info.idEpi.toString();

    const strorden = (info.idxOrden>0) ? "&idxOrden="+info.idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/AvanzadaMUV" + strParams + "&max=" + max + "&idBusqueda=" + idb + strorden, { headers: authHeader() });
};  

const datosFormularioAvanzadaPAH = () => {
    return axios.get(API_URL + "BotPlus2022/DatosFormAvanzadaPAH", { headers: authHeader() });
};  

const principiosActivosAvanzadaPAH = (info) => {
    var strParams = "?av";

    if (info.txt && info.txt !== '')
        strParams += "&med=" + info.txt;
    if (info.idsAF && (info.idsAF.length > 0))
        strParams += "&idsAF=" + info.idsAF.toString();
    if (info.idsAM && (info.idsAM.length > 0))
        strParams += "&idsAM=" + info.idsAM.toString();
    /*if (info.idGT && info.idGT !== '')
        strParams += "&idGT=" + info.idGT;*/
    if (info.idsGT && (info.idsGT.length > 0))
        strParams += "&idsGT=" + info.idsGT.toString();
    if (info.ops && (info.ops.length > 0))
        strParams += "&ops=" + fromListaOpsAvanzadaToParamURL(info.ops);
    if (info.idsMen && (info.idsMen.length > 0))
        strParams += "&idsMen=" + info.idsMen.toString();
    if (info.texto && info.texto !== '' && info.texto.length>2)
        strParams += "&texto=" + encodeURI(info.texto);
    if (info.noTexto)
        strParams += "&noTexto=S";
    if (info.idEpi>0)
        strParams += "&idEpi=" + info.idEpi.toString();
    if (info.enfsRel && (info.enfsRel.length > 0)){
        var strEnf = "";
        for (var i=0; i<info.enfsRel.length; i++){
            if (i>0) strEnf += ","
            strEnf += info.enfsRel[i].codigo + "_" + info.enfsRel[i].epi;
        }
        strParams += "&enfsRel=" + strEnf;
    }
    if (info.anasRel && (info.anasRel.length > 0)){
        var strAna = "";
        for (var i=0; i<info.anasRel.length; i++){
            if (i>0) strAna += ","
            strAna += info.anasRel[i].codigo + "_" + info.anasRel[i].epi;
        }
        strParams += "&anasRel=" + strAna;
    }


    const strorden = (info.idxOrden>0) ? "&idxOrden="+info.idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/AvanzadaPAH" + strParams + strorden, { headers: authHeader() });
};  

const datosFormularioAvanzadaPPF = (idTipo) => {
    var strDieto = (idTipo === 6 ? "S" : "N")
    return axios.get(API_URL + "BotPlus2022/DatosFormAvanzadaPPF?dieto=" + strDieto, { headers: authHeader() });
};  

const viasFormularioAvanzadaPPF = (idTipo) => {
    var strDieto = (idTipo === 6 ? "S" : "N")
    return axios.get(API_URL + "BotPlus2022/AcutalizaViasPPF?dieto=" + strDieto, { headers: authHeader() });
};  

const productosAvanzadaPPF = (info,max,idb) => {
    var strParams = "?av";

    if (info.txt && info.txt !== '')
        strParams += "&med=" + info.txt;
    /*if (info.idLab && info.idLab > 0)
        strParams += "&idLab=" + info.idLab.toString();
    if (info.idGT && info.idGT !== '')
        strParams += "&idGT=" + info.idGT;*/
    if (info.idsLab && (info.idsLab.length > 0))
        strParams += "&idsLab=" + info.idsLab.toString();
    if (info.idsGT && (info.idsGT.length > 0))
        strParams += "&idsGT=" + info.idsGT.toString();

    if (info.pas && (info.pas.length > 0))
        strParams += "&pas=" + fromListaPAsComposToParamURL(info.pas);
    if (info.solo)
        strParams += "&solo=S";
    else
        strParams += "&solo=N";
    if (info.idVia>0)
        strParams += "&idVia=" + info.idVia.toString();
    if (info.idFF>0)
        strParams += "&idFF=" + info.idFF.toString();
    /*if (info.idTipo>0)
        strParams += "&idTipo=" + info.idTipo.toString();
    if (info.idSubtipo>0)
        strParams += "&idSubtipo=" + info.idSubtipo.toString();*/
    if (info.idsTipo && (info.idsTipo.length > 0))
        strParams += "&idsTipo=" + info.idsTipo.toString();
    if (info.idsSubtipo && (info.idsSubtipo.length > 0))
        strParams += "&idsSubtipo=" + info.idsSubtipo.toString();
    if (info.uds && info.uds>0)
        strParams += "&uds=" + info.uds.toString();
    if (info.fechaAutoIni){
        strParams += "&fechaAutoIni=" + Math.round(info.fechaAutoIni.getTime()/1000).toString();
    }
    if (info.fechaAutoFin){
        strParams += "&fechaAutoFin=" + Math.round(info.fechaAutoFin.getTime()/1000).toString();
    }
    if (info.fechaBajaIni){
        strParams += "&fechaBajaIni=" + Math.round(info.fechaBajaIni.getTime()/1000).toString();
    }
    if (info.fechaBajaFin){
        strParams += "&fechaBajaFin=" + Math.round(info.fechaBajaFin.getTime()/1000).toString();
    }
    if (info.ints && (info.ints.length > 0))
        strParams += "&ints=" + info.ints.toString();
    if (info.ops && (info.ops.length > 0))
        strParams += "&ops=" + fromListaOpsAvanzadaToParamURL(info.ops);
    if (info.idsMen && (info.idsMen.length > 0))
        strParams += "&idsMen=" + info.idsMen.toString();
    if (info.texto && info.texto !== '' && info.texto.length>2)
        strParams += "&texto=" + encodeURI(info.texto);
    if (info.noTexto)
        strParams += "&noTexto=S";
    if (info.idEpi>0)
        strParams += "&idEpi=" + info.idEpi.toString();

    const strorden = (info.idxOrden>0) ? "&idxOrden="+info.idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/AvanzadaPPF" + strParams + "&max=" + max + "&idBusqueda=" + idb + strorden, { headers: authHeader() });
};  

const datosFormularioPacienteIndicacion = () => {
    return axios.get(API_URL + "BotPlus2022/DatosFormPacienteIndicacion", { headers: authHeader() });
};  

const datosFormularioProductosIndicacion = () => {
    return axios.get(API_URL + "BotPlus2022/DatosFormProductosIndicacion", { headers: authHeader() });
};  

const medicamentosPacienteIndicacion = (info) => {
    var strParams = "?av";

    if (info.idEnf>0)
        strParams += "&idEnf=" + info.idEnf.toString();
    if (info.idsPat && (info.idsPat.length > 0))
        strParams += "&idsPat=" + info.idsPat.toString();
    if (info.idsMed && (info.idsMed.length > 0))
        strParams += "&idsMed=" + info.idsMed.toString();
    if (info.idVia>0)
        strParams += "&idVia=" + info.idVia.toString();
    if (info.idFF>0)
        strParams += "&idFF=" + info.idFF.toString();
    if (info.edad)
        strParams += "&edad=" + info.edad.toString();
    if (info.undEdad)
        strParams += "&undEdad=" + info.undEdad;
    if (info.ints && (info.ints.length > 0))
        strParams += "&ints=" + info.ints.toString();
    if (info.ops && (info.ops.length > 0))
        strParams += "&ops=" + fromListaOpsAvanzadaToParamURL(info.ops);
    if (info.idsAlg && (info.idsAlg.length > 0))
        strParams += "&idsAlg=" + info.idsAlg.toString();

    const strorden = (info.idxOrden>0) ? "&idxOrden="+info.idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/PacienteIndicacion" + strParams + strorden, { headers: authHeader() });
};  

const productosIndicacion = (info) => {
    var strParams = "?pi";

    if (info.idEnf>0)
        strParams += "&idEnf=" + info.idEnf.toString();
    if (info.idFF>0)
        strParams += "&idFF=" + info.idFF.toString();
    if (info.pas && info.pas.length>0)
        strParams += "&pas=" + fromListaPAsComposToParamURL(info.pas);
    if (info.ints && (info.ints.length > 0))
        strParams += "&ints=" + info.ints.toString();
    if (info.ops && (info.ops.length > 0))
        strParams += "&ops=" + fromListaOpsAvanzadaToParamURL(info.ops);

    const strorden = (info.idxOrden>0) ? "&idxOrden="+info.idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/ProductosIndicacion" + strParams + strorden, { headers: authHeader() });
};  

const listaPosiblesInteractores = (idsSel) => {
    var strParam="";
    if (idsSel && idsSel.length === 1)
        strParam += "?id=" + idsSel[0].toString();
    return axios.get(API_URL + "BotPlus2022/DatosFormInteracciones" + strParam, { headers: authHeader() });
};  

const buscaInteracciones = (idsSel) => {
    var param = "?idsSel="
    if (idsSel && idsSel.length>0)
        param += idsSel.toString();
    else
        param += "0";
    return axios.get(API_URL + "BotPlus2022/CalculoInteracciones"+param, { headers: authHeader() });
};  

const medicamentosFM = (idxOrden) => {
    const strorden = (idxOrden>0) ? "?idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetFormulasMagistrales" + strorden, { headers: authHeader() });
};  

const medicamentosHomeo = (idxOrden) => {
    const strorden = (idxOrden>0) ? "?idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetMedicamentosHomeopaticos" + strorden, { headers: authHeader() });
};  

const datosFormularioFormaColor = () => {
    return axios.get(API_URL + "BotPlus2022/DatosFormFormaColor", { headers: authHeader() });
};  


const medicamentosFormaColor = (info, max, idb) =>
{
    var strParams = "?fc";

    if (info.tipoFF!=='')
        strParams += "&tipoFF=" + info.tipoFF;
    if (info.idForma>0)
        strParams += "&idForma=" + info.idForma.toString();
    if (info.idColor1 !== "")
        strParams += "&idColor1=" + info.idColor1.toString();
    if (info.idColor2 !== "")
        strParams += "&idColor2=" + info.idColor2.toString();
    if (info.idSabor>0)
        strParams += "&idSabor=" + info.idSabor.toString();
    if (info.idEnf>0)
        strParams += "&idEnf=" + info.idEnf.toString();
    if (info.tamanos && (info.tamanos.length > 0))
        strParams += "&tamanos=" + info.tamanos.toString();
    if (info.CO1)
        strParams += "&CO1=" + info.CO1;
    if (info.CO2)
        strParams += "&CO2=" + info.CO2;
    if (info.ranura)
        strParams += "&ranura=S"                        
    if (info.logo)
        strParams += "&logo=S"                        
    if (info.difCol)
        strParams += "&difCol=S"                        
    if (info.col2)
        strParams += "&col2=S"                        
    if (info.anycol)
        strParams += "&anycol=S"                        
    if (info.ins1 && info.ins1 !== '')
        strParams += "&ins1=" + info.ins1;
    if (info.ins2 && info.ins2 !== '')
        strParams += "&ins2=" + info.ins2;
    /*if (info.pas && (info.pas.length > 0))
        strParams += "&pas=" + info.pas.toString();*/
    if (info.pas && info.pas.length>0){
        strParams += "&pas=" + fromListaPAsComposToParamURL(info.pas);
    }
    if (info.solo){
        strParams += "&solo=S"
    }else{
        strParams += "&solo=N"
    }
    

    const strorden = (info.idxOrden>0) ? "&idxOrden="+info.idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetMedicamentosPorFormaColor" + strParams + "&max=" + max + "&idBusqueda=" + idb + strorden, { headers: authHeader() });

}

const datosFormularioEXT = () => {
    return axios.get(API_URL + "BotPlus2022/DatosFormEXT", { headers: authHeader() });
};  


const medicamentosEXT = (info, max, idb) =>
{
        var strParams = "?ex";
        if (info.id>0)
            strParams += "&id=" + info.id.toString();
        if (info.txt && info.txt !== '')
            strParams += "&txt=" + info.txt;
        if (info.idPais>0)
            strParams += "&idPais=" + info.idPais.toString();
        if (info.pas && (info.pas.length > 0))
            strParams += "&pas=" + info.pas.toString();
        if (info.solo){
            strParams += "&solo=S"
        }
        
        const strorden = (info.idxOrden>0) ? "&idxOrden="+info.idxOrden.toString() : "";
        return axios.get(API_URL + "BotPlus2022/GetMedicamentosEXT" + strParams + "&max=" + max + "&idBusqueda=" + idb + strorden, { headers: authHeader() });
    
}

const medicamentosEspanoles = (id, idxOrden) =>
{
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetMedicamentosESP?id=" + id + strorden, { headers: authHeader() });
}

const productosConGluten                = (idxOrden, max, idb) =>
{
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetProductosConGluten?cg" + strorden + "&max=" + max + "&idBusqueda=" + idb, { headers: authHeader() });
}
const productosSinGluten                = (idxOrden, max, idb) =>
{
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetProductosSinGluten?cg" + strorden + "&max=" + max + "&idBusqueda=" + idb, { headers: authHeader() });
}
const productosConLactosa               = (idxOrden, max, idb) =>
{
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetProductosConLactosa?cl" + strorden + "&max=" + max + "&idBusqueda=" + idb, { headers: authHeader() });
}
const productosSinLactosa               = (idxOrden, max, idb) =>
{
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetProductosSinLactosa?cl" + strorden + "&max=" + max + "&idBusqueda=" + idb, { headers: authHeader() });
}
const medicamentosDopantes              = (idxOrden, max, idb) =>
{
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetMedicamentosDopantes?do" + strorden + "&max=" + max + "&idBusqueda=" + idb, { headers: authHeader() });
}
const productosNoLentes                 = (idxOrden) =>
{
    const strorden = (idxOrden>0) ? "?idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetProductosNoLentes" + strorden, { headers: authHeader() });
}
const medicamentosConPrecioSupMenor     = (idxOrden) =>
{
    const strorden = (idxOrden>0) ? "?idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetMedicamentosConPrecioSupMenor" + strorden, { headers: authHeader() });
}
const medicamentosConPrecioSupMasBajo     = (idxOrden) =>
{
    const strorden = (idxOrden>0) ? "?idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetMedicamentosConPrecioSupMasBajo" + strorden, { headers: authHeader() });
}
const medicamentosConPrecioNotificado   = (idxOrden) =>
{
    const strorden = (idxOrden>0) ? "?idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetMedicamentosConPrecioNotificado" + strorden, { headers: authHeader() });
}
const medicamentosSeguimientoAdicional  = (idxOrden) =>
{
    const strorden = (idxOrden>0) ? "?idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetMedicamentosSeguimientoAdicional" + strorden, { headers: authHeader() });
}
const medicamentosDispositivoSeguridad  = (idxOrden, max, idb) =>
{
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetMedicamentosDispositivoSeguridad?ds" + strorden + "&max=" + max + "&idBusqueda=" + idb, { headers: authHeader() });
}
const medicamentosLibroRecetario        = (idxOrden) =>
{
    const strorden = (idxOrden>0) ? "?idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetMedicamentosLibroRecetario" + strorden, { headers: authHeader() });
}
const medicamentosLibroEstupefacientes  = (idxOrden) =>
{
    const strorden = (idxOrden>0) ? "?idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetMedicamentosLibroEstupefacientes" + strorden, { headers: authHeader() });
}
const medicamentosNotificacionESVAC     = (idxOrden) =>
{
    const strorden = (idxOrden>0) ? "?idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetMedicamentosNotificacionESVAC" + strorden, { headers: authHeader() });
}
const enfermedadesH                     = (idxOrden) =>
{
    const strorden = (idxOrden>0) ? "?idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetEnfermedadesH" + strorden, { headers: authHeader() });
}
const enfermedadesV                     = (idxOrden) =>
{
    const strorden = (idxOrden>0) ? "?idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetEnfermedadesV" + strorden, { headers: authHeader() });
}

const medicamentosNoSustituibles  = (idxOrden, max, idb) =>
{
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetMedicamentosNoSustituibles?ds" + strorden + "&max=" + max + "&idBusqueda=" + idb, { headers: authHeader() });
}

const medicamentosAlertaCismed  = (idxOrden) =>
{
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/ConAlertaCismedMUH?ds" + strorden, { headers: authHeader() });
}
    

const medicamentosDeduccionesRDL  = (idxOrden, max, idb) =>
{
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetMedicamentosDeduccionesRDL?ds" + strorden + "&max=" + max + "&idBusqueda=" + idb, { headers: authHeader() });
}

const productosCambioNombre  = (info, max, idb) =>
{
    var strParams = "?cn";
    if (info.inicio){
        strParams += "&fini=" + Math.round(info.inicio.getTime()/1000).toString();
    }
    if (info.fin){
        strParams += "&ffin=" + Math.round(info.fin.getTime()/1000).toString();
    }
    const strorden = (info.idxOrden>0) ? "&idxOrden="+info.idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetProductosCambioNombre?ds" + strParams + strorden + "&max=" + max + "&idBusqueda=" + idb, { headers: authHeader() });
}
const productosCambioLaboratorio  = (info, max, idb) =>
{
    var strParams = "?cn";
    if (info.inicio){
        strParams += "&fini=" + Math.round(info.inicio.getTime()/1000).toString();
    }
    if (info.fin){
        strParams += "&ffin=" + Math.round(info.fin.getTime()/1000).toString();
    }
    const strorden = (info.idxOrden>0) ? "&idxOrden="+info.idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetProductosCambioLaboratorio?ds" + strParams + strorden + "&max=" + max + "&idBusqueda=" + idb, { headers: authHeader() });
}

const medicamentosCambioPrecio  = (info, max, idb) =>
{
    var strParams = "?cn";
    if (info.inicio){
        strParams += "&fini=" + Math.round(info.inicio.getTime()/1000).toString();
    }
    if (info.fin){
        strParams += "&ffin=" + Math.round(info.fin.getTime()/1000).toString();
    }
    const strorden = (info.idxOrden>0) ? "&idxOrden="+info.idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetMedicamentosCambioPrecio?ds" + strParams + strorden + "&max=" + max + "&idBusqueda=" + idb, { headers: authHeader() });
}

const medicamentosDesfinanciados  = (info, max, idb) =>
{
    var strParams = "?cn";
    if (info.inicio){
        strParams += "&fini=" + Math.round(info.inicio.getTime()/1000).toString();
    }
    if (info.fin){
        strParams += "&ffin=" + Math.round(info.fin.getTime()/1000).toString();
    }
    const strorden = (info.idxOrden>0) ? "&idxOrden="+info.idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetMedicamentosDesfinanciados?ds" + strParams + strorden + "&max=" + max + "&idBusqueda=" + idb, { headers: authHeader() });
}

const productosFechaAlta  = (info, max, idb) =>
{
    var strParams = "?cn";
    if (info.inicio){
        strParams += "&fini=" + Math.round(info.inicio.getTime()/1000).toString();
    }
    if (info.fin){
        strParams += "&ffin=" + Math.round(info.fin.getTime()/1000).toString();
    }
    const strorden = (info.idxOrden>0) ? "&idxOrden="+info.idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetProductosFechaAlta?ds" + strParams + strorden + "&max=" + max + "&idBusqueda=" + idb, { headers: authHeader() });
}

const productosUltimoMes  = (info, max, idb) =>
{
    var strParams = "?cn";
    
    var auxdate = new Date();
    auxdate.setMonth(auxdate.getMonth() - 1);
    strParams += "&fini=" + Math.round(auxdate.getTime()/1000).toString();

    const strorden = (info.idxOrden>0) ? "&idxOrden="+info.idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetProductosFechaAlta" + strParams + strorden + "&max=" + max + "&idBusqueda=" + idb, { headers: authHeader() });
}

const medicamentosPSUltimoMes  = (idxOrden) =>
{
    var strParams = "?ps";
    
    var auxdate = new Date();
    auxdate.setMonth(auxdate.getMonth() - 1);
    strParams += "&fini=" + Math.round(auxdate.getTime()/1000).toString();

    const strorden = (idxOrden>0) ? "&idxOrden="+ idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/ConPSuministro" + strParams + strorden , { headers: authHeader() });
}

const medicamentosCambioPrecioPrevisto = (idxOrden) =>
{
    const strorden = (idxOrden>0) ? "?idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/GetMedicamentosCambioPrecioPrevisto" + strorden, { headers: authHeader() });
}

const productosHistorialUsuario = (idxOrden) =>
{
    const maxRandom = 1000000
    const intRandom = Math.floor(Math.random() * maxRandom);
    const strrandom = "?" + intRandom;
    const strorden = (idxOrden>0) ? "&idxOrden="+idxOrden.toString() : "";
    return axios.get(API_URL + "BotPlus2022/HistorialUsuario" + strrandom + strorden, { headers: authHeader() });
}

const borraProductoHistorial = (tipo, id) =>
{
    return axios.get(API_URL + "BotPlus2022/BorraDeHistorial?tipo=" + tipo + "&id=" + id.toString(), { headers: authHeader() });
}

const datosFormularioDocumentos = () => {
    return axios.get(API_URL + "BotPlus2022/DatosFormDocumentos", { headers: authHeader() });
};  

const documentosPorMedFecha  = (info) =>
{
    var strParams = "?do";
    if (info.inicio){
        strParams += "&fini=" + Math.round(info.inicio.getTime()/1000).toString();
    }
    if (info.fin){
        strParams += "&ffin=" + Math.round(info.fin.getTime()/1000).toString();
    }
    if (info.idMed>0){
        strParams += "&idMed=" + info.idMed.toString();
    }
    return axios.get(API_URL + "BotPlus2022/DocumentosRelacionados" + strParams , { headers: authHeader() });
}

const datosFormularioBucadoresPreferidos = () => {
    return axios.get(API_URL + "BotPlus2022/DatosFormEditaBuscadores", { headers: authHeader() });
};  

const setDatosBuscadoresPreferidos = (ids) => {
    var strParams = "?do";
    if (ids){
        strParams += "&idsBusca=" + ids.toString();
    }
    return axios.get(API_URL + "BotPlus2022/SetDatosBuscadores" + strParams , { headers: authHeader() });
}

const obtenCamposExportables = (codTipoExporta, codBusqueda) => {
    var strCodBusqueda = "";
    if (codBusqueda)
        strCodBusqueda = "&codBusqueda=" + codBusqueda
    return axios.get(API_URL + "BotPlus2022/GetCamposExportables?tipoRegistro="+codTipoExporta+strCodBusqueda, { headers: authHeader() });
};  

const obtenRegistrosAExportar = (codTipoExporta, ids, campos, campoOrden, tipoBusqueda, ini, fin) => {
    if ((!campoOrden) || campoOrden===""){
        if (campos.includes("NOM" + codTipoExporta)){
            campoOrden = "NOM" + codTipoExporta
        }
        else{
            campoOrden = campos[0];
        }
    }
        
    return axios.post(API_URL + "BotPlus2022/ExportaPost"
                    , { tipoRegistros: codTipoExporta,
                        ids: ids,
                        campos: campos,
                        campoOrden: campoOrden,
                        tipoBusqueda: tipoBusqueda,
                        lfini: ini,
                        lffin: fin,
                    } 
                    , { headers: authHeader() });
};  

const obtenExportacionExcel = (codDocExporta) => {
        
    return axios.post(API_URL + "BotPlus2022/ExportExcelPost"
                    , { 
                        codDocExporta: codDocExporta,
                    } 
                    , { headers: authHeader() });
};  

const getEmpresa = () => {
    return axios.get(API_URL + "BotPlus2022/DatosFormEmpresas", { headers: authHeader() });
};  

const getUsuAdicional = () => {
    return axios.get(API_URL + "BotPlus2022/UsuAdicional", { headers: authHeader() });
};  

const getDirCorreo = () => {
    return axios.get(API_URL + "BotPlus2022/DirCorreo", { headers: authHeader() });
};  

const checkUser = (user) => {
    return axios.get(API_URL + "login/CheckUser?user="+user);
};  

const enviaCorreoRecPass = (user) => {
    return axios.get(API_URL + "login/EnviaCorreoRecPass?user="+user);
};  

const enviaCorreoCambioPass = () => {
    return axios.get(API_URL + "BotPlus2022/EnviaCorreoCambioPass",{ headers: authHeader() });
};  

const enviaCorreoCambioCorreo = () => {
    return axios.get(API_URL + "BotPlus2022/EnviaCorreoCambioCorreo",{ headers: authHeader() });
};  

const checkLink = (link) => {
    return axios.get(API_URL + "login/CheckLink?link="+link);
};  

const checkLinkCC = (link) => {
    return axios.get(API_URL + "login/CheckLinkCC?link="+link);
};  

const checkLinkCheckC = (link)  => {
    return axios.get(API_URL + "login/CheckLinkCheckC?link="+link);
};  

const setDirCorreo = (pass, correo, desea) => {
    return axios.post(API_URL + "BotPlus2022/CambiaDireccionCorreo"
                    , { Password: pass,
                        Correo: correo,
                        DeseaRecibirInfo: desea
                        } 
                    , { headers: authHeader() });

};

const setPasswordLink = (link, newpass) => {
    return axios.post(API_URL + "Login/CambiaPasswordConLink"
                    , { Password: link,
                        NewPassword: newpass
                    });
};

const setCorreoLink = (link, correo, desea) => {
    return axios.post(API_URL + "Login/CambiaCorreoConLink"
                    , { Password: link,
                        Correo: correo,
                        DeseaRecibirInfo: desea
                    });
};

/*const setCheckCorreoLink = (link) => {
    return axios.post(API_URL + "Login/ConfirmaCorreoConLink"
                    , { Password: link,
                    });
};*/

const setPassword = (pass, newpass) => {
    return axios.post(API_URL + "BotPlus2022/CambiaPassword"
                    , { Password: pass,
                        NewPassword: newpass
                        } 
                    , { headers: authHeader() });

};

const enableUsuAdicional = (user, pass) => {
    return axios.get(API_URL + "BotPlus2022/EnableUsuAdicional?user="+user+'&pass='+pass, { headers: authHeader() });
};  

const disableUsuAdicional = () => {
    return axios.get(API_URL + "BotPlus2022/DisableUsuAdicional", { headers: authHeader() });
};  

const setEmpresa = (idEmpresa) => {
    return axios.get(API_URL + "BotPlus2022/SetEmpresa?idEmpresa="+idEmpresa.toString(), { headers: authHeader() });
};  

const getFormAddEmpresa = () => {
    return axios.get(API_URL + "BotPlus2022/DatosFormAddEmpresa", { headers: authHeader() });
};  

const addEmpresa = (idEmpresa) => {
    return axios.get(API_URL + "BotPlus2022/AddEmpresaColaboradora?idEmpresa="+idEmpresa.toString(), { headers: authHeader() });
};  

const delEmpresa = (idEmpresa) => {
    return axios.get(API_URL + "BotPlus2022/DeleteEmpresaColaboradora?idEmpresa="+idEmpresa.toString(), { headers: authHeader() });
};  

const getDocumentosAyuda = () => {
    return axios.get(API_URL + "BotPlus2022/GetDocsAyuda", { headers: authHeader() });
};  

const setOpcionBusqueda = (i) => {
    localStorage.setItem("opcionBInicio",i);
}

const getOpcionBusqueda = () => {
    var i= parseInt(localStorage.getItem("opcionBInicio"));
    return i;
}

const obtenListadosExportables = () => {
    return axios.get(API_URL + "BotPlus2022/GetListadosExporta", { headers: authHeader() });
};  

const getBackVersion = () => {
    return axios.get(API_URL + "BotPlus2022/GetBackendVersion", { headers: authHeader() });
  };
  
const moduloIndicacionesPF = () => {
    return axios.get(API_URL + "BotPlus2022/HayModuloIndicacionesPF", { headers: authHeader() });
 };
  
const moduloCambioPass = () => {
    return axios.get(API_URL + "BotPlus2022/HayModuloCambioPass", { headers: authHeader() });
 };

 const getItemsFromEspecod = (especod) => {
    return axios.get(API_URL + "BotPlus2022/GetItemsFromEspecod?cod=" + especod, { headers: authHeader() })
 }

 const hayBuscadorCisMED = () => {
    return axios.get(API_URL + "BotPlus2022/HayBuscadorCisMED", { headers: authHeader() });
 };
  
  

/*
        .post(API_URL + "BotplusAuthenticate", {
            Username : username,
            Password : password,
            FuerzaBruta : fb,
            MantenerAbierta : ma
        }, { headers: noAuthHeader() })
*/

const userService = {
    getMedsByName
    ,getFichaMUH
    ,getFichaMUV
    ,getFichaFM
    ,getFichaPF
    ,getFichaPA
    ,getFichaING
    ,getFichaASO
    ,getFichaPAT
    ,getComposM
    ,getComposPF
    ,getGruposMUH
    ,getGruposPF
    ,getTextosMUH
    ,getTextosPF
    ,getTextosPA
    ,getTextosING
    ,getAdvertenciasMUH
    ,getAdvertenciasPF
    ,getAdvertenciasPA
    ,getAdvertenciasING
    ,getAdvertenciasASO
    ,getConsejosMUH
    ,getPrecaucionExtremaMUH
    ,getPrecaucionExtremaPAH
    ,getInterferenciasMUH 
    ,getInterferenciasPA
    ,getInterferenciasASO
    ,getInterferenciasPAT
    ,getCismedMUH
    ,getConservacionMUH
    ,getConservacionPF
    ,getFarmacogeneticaMUH
    ,getFarmacogeneticaPA
    ,getFarmacogeneticaASO
    ,getHistoricoMUH
    ,getHistoricoPF
    ,getHistoricoPA
    ,getHistoricoING
    ,getHistoricoASO
    ,getIdentificacionMUH
    ,getInformacionAdicionalMUH
    ,getInformacionAdicionalPF
    ,getInformacionAdicionalPA
    ,getInformacionAdicionalING
    ,getInformacionAdicionalASO
    ,getInformacionAdicionalPAT
    ,getProblemasSuministroMUH
    ,getVideosMUH
    ,getVideosPF
    ,getVideosPA
    ,getVideosING
    ,getVideosASO
    ,getVideosPAT
    ,getAlertasCalidadMUH
    ,getAlertasCalidadPF
    ,getMensajesEmergentesMUH
    ,getMensajesEmergentesPPF
    ,getMensajesEmergentesPAH
    ,getTiemposEsperaMUV
    ,getContenidoPackPF
    ,getPacksContenedoresPF
    ,getSinonimiasPA
    ,getSinonimiasING
    ,getSinonimiasPAT
    ,getDopajeInfoPA
    ,getDopajeInfoASO
    ,getProductoresInfoPA
    ,getJerarquiaInfoPAT
    ,getCIEInfoPAT
    ,getBuscadoresPreferidos
    ,getNovedades
    ,getProductosConPS
    ,getUltimasNotasYAlertas
    ,getHistorial
    ,emptyHistorial
    ,getDatosConfiguracion
    ,setDatosConfiguracion
    ,getLaboratorios
    ,medicamentosLaboratorio 
    ,medicamentosLaboratorios
    ,productosLaboratorio
    ,productosLaboratorios
    ,getAgrupaciones 
    ,getFormasFarma 
    , getActividades
    , getAnalisis
    , getBiomarcadores
    , getGruposATC
    , getGruposVMPP
    , getArbolGruposVMPP
    , getGruposCon
    , getGruposSan
    , medicamentosAgrupacionAuto
    , productosAgrupacionAuto 
    , medicamentosAgrupacioNac
    , productosAgrupacionNac 
    , medicamentosFormaFarma 
    , productosFormaFarma
    , medicamentosFormasFarma 
    , productosFormasFarma
    , principiosActividad
    , ingredientesActividad
    , principiosActividadesY
    , ingredientesActividadesY
    , principiosActividadesO
    , ingredientesActividadesO
    , medicamentosAnalisis
    , medicamentosBiomarcador
    , medicamentosConPA
    , medicamentosConAso
    , productosConIng
    , productosTipo
    , productosSubtipo
    , medicamentosPorEnf
    , productosPorEnf
    , medicamentosGTATC
    , medicamentos2GTATC
    , medicamentosGTVMPP
    , productosGTCon
    , productosGTSan
    , datosFormularioSaborEdad
    , datosFormularioTest
    , descargaInfoSalesYCantidades
    , descargaInfoSalesYCantidadesIng
    , descargaListaInfoSalesYCantidades
    , descargaListaInfoSalesYCantidadesIng
    , productosSaborEdad
    , datosFormularioEspecieIndicacion
    , medicamentosEspecieIndicacion
    , datosFormularioProblemasSuministro
    , medicamentosProblemasSuministro
    , datosFormularioAlertasCalidad
    , medicamentosAlertasCalidad
    , PFAlertasCalidad
    , datosFormularioReaccionesAdversas
    , medicamentosReaccionesAdversas
    , datosFormularioComposicion
    , actualizaFFyVias
    , productosPorComposicion
    , datosFormularioAvanzadaMUH
    , medicamentosAvanzadaMUH
    , datosFormularioAvanzadaMUV
    , medicamentosAvanzadaMUV
    , datosFormularioAvanzadaPAH
    , principiosActivosAvanzadaPAH
    , datosFormularioAvanzadaPPF
    , viasFormularioAvanzadaPPF 
    , productosAvanzadaPPF 
    , datosFormularioPacienteIndicacion
    , datosFormularioProductosIndicacion
    , medicamentosPacienteIndicacion
    , productosIndicacion
    , listaPosiblesInteractores
    , buscaInteracciones
    , medicamentosFM
    , medicamentosHomeo
    , datosFormularioFormaColor
    , medicamentosFormaColor
    , datosFormularioEXT
    , medicamentosEXT
    , getFichaEXT
    , medicamentosEspanoles
    , productosConGluten
    , productosConLactosa
    , productosSinGluten
    , productosSinLactosa
    , medicamentosDopantes
    , productosNoLentes
    , medicamentosConPrecioSupMenor
    , medicamentosConPrecioSupMasBajo
    , medicamentosConPrecioNotificado
    , medicamentosSeguimientoAdicional
    , medicamentosDispositivoSeguridad
    , medicamentosLibroRecetario
    , medicamentosLibroEstupefacientes
    , medicamentosNotificacionESVAC
    , enfermedadesH
    , enfermedadesV
    , medicamentosNoSustituibles
    , medicamentosDeduccionesRDL
    , productosCambioNombre
    , productosCambioLaboratorio
    , medicamentosCambioPrecio
    , medicamentosDesfinanciados
    , productosFechaAlta
    , productosUltimoMes
    , medicamentosCambioPrecioPrevisto
    , medicamentosPSUltimoMes
    , productosHistorialUsuario
    , datosFormularioDocumentos
    , documentosPorMedFecha
    , datosFormularioBucadoresPreferidos
    , setDatosBuscadoresPreferidos
    , obtenCamposExportables
    , obtenRegistrosAExportar
    , getEmpresa
    , setEmpresa
    , getFormAddEmpresa
    , addEmpresa    
    , delEmpresa
    , getDocumentosAyuda
    , getOpcionBusqueda
    , setOpcionBusqueda
    , borraProductoHistorial
    , getUsuAdicional 
    , enableUsuAdicional 
    , disableUsuAdicional 
    , getDirCorreo 
    , setDirCorreo
    , setPassword
    , obtenListadosExportables
    , obtenExportacionExcel
    , getNombresSimilaresPAH
    , getNombresSimilaresMUH
    , medicamentosMismoNombre
    , getBackVersion
    , moduloIndicacionesPF
    , moduloCambioPass
    , enviaCorreoRecPass
    , enviaCorreoCambioPass
    , enviaCorreoCambioCorreo
    , checkUser
    , checkLink
    , checkLinkCC
    , checkLinkCheckC
    , setPasswordLink
    , setCorreoLink
    //, setCheckCorreoLink
    , getItemsFromEspecod
    , hayBuscadorCisMED
    , medicamentosAlertaCismed
};  

export default userService;
