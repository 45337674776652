import { rootPath } from "../../../../../services/utils";

const SemaforosCismed = (props) => {
    const datosCismed = props.datosCismed;
    const inactivo = props.inactivo;
    const estilo = (inactivo ?
        {color:'transparent', textShadow: '0 0 10px rgba(0,0,0,0.5)'}
        :
        {}
    );
    const estiloImg = (inactivo ?
        {filter: 'blur(15px)' , WebkitFilter: 'blur(15px)', width: '100%', height: '300px'}
        :
        {width: '100%'}
    );


    const getImagenFromEstado = (estado) => {
        switch (estado){
            case 1:
                return 'semaforoRed.png'
            case 2:
                return 'semaforoYellow.png'
            case 3:
                return 'semaforoGreen.png'
            default:
                return 'semaforoRed.png'
        }
    }

    const getTextoFromEstado = (estado) => {
        switch (estado){
            case 1:
                return 'Problemas por faltas de suministro'
            case 2:
                return 'En observación por faltas de suministro'
            case 3:
                return 'Sin incidencias por faltas de suministro'
            default:
                return 'El número de farmacias adscritas a CisMED en su provincia no supera el umbral mánimo de farmacias activamente informantes para el análisis de medicamentos con incidencias de suministro.'
        }
    }

    const imgSemaforoAnt = rootPath + "img/ficha/"+getImagenFromEstado(datosCismed.EstadoSemanaAnterior);
    const imgSemaforoAct = rootPath + "img/ficha/"+getImagenFromEstado(datosCismed.EstadoSemanaActual);
    const txtSemaforoAnt = getTextoFromEstado(datosCismed.EstadoSemanaAnterior); 
    var txtSemaforoAct = getTextoFromEstado(datosCismed.EstadoSemanaActual); 
    if (datosCismed.EsInicioSemana)
        txtSemaforoAct = "Los datos consolidados en su provincia en la semana actual no alcanzan el umbral mínimo para el análisis de medicamentos con incidencias de suministro. La información recibida se procesa de manera diaria.";


    return (
        <div class="cover cover__table" >
            <div class="separator separator__10" ></div>
            <div class="table" style={estiloImg}>
                <div class="table__row table__row__header table__row__header__noBg fs fs12 fw fw600 greenColor uppercase">
                    <div class="column">
                        <p>Semana anterior</p>
                    </div>
                    <div class="column">
                        <p>Semana actual</p>
                    </div>
                </div>
                <div class="table__row table__row__normal fw fw300" style={{borderRadius: '0 0 0 0', borderBottom: 'none'}}>
                    <div class="column fs fs14 blackColor FlamaBook imgVertical">
                        <img src={imgSemaforoAnt} width="64"/>
                    </div>
                    <div class="column fs fs14 blackColor FlamaBook imgVertical">
                        <img src={imgSemaforoAct} width="64" />
                    </div>
                </div>
                <div class="table__row table__row__normal fw fw300" style={{borderRadius: '0 0 10px 10px', borderTop: 'none'}}>
                    <div class="column fs fs14 blackColor FlamaBook">
                        <p>{txtSemaforoAnt}</p>
                    </div>
                    <div class="column fs fs14 blackColor FlamaBook">
                        <p>{txtSemaforoAct}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SemaforosCismed;