import ItemSugerencia from './ItemSugerencia.js';

function Sugerencias(props){
    var identificador = props.identificador; //"sugerenciasFB";
    if (props.missugerencias != null)
    {
        const filas = props.missugerencias.map(fila => <ItemSugerencia item={fila}></ItemSugerencia>)
        return (
            <datalist id = {identificador}>
                {filas}
            </datalist>
        )
    }    
    else{
        return(
            <datalist id = {identificador}></datalist>
        )
    }
}

export default Sugerencias;