const DatoFarmaceutico = (props) => {
    const dato = props.dato;
    const tienePicto = (dato.URLPictograma && dato.URLPictograma != "");

    return (
        <div class="row fs fs14" >
            <div class="column" >
                {tienePicto ?
                    <img style={{maxHeight:'20px'}} src={dato.URLPictograma} alt="sandClock" />
                :
                    <span></span>
                }   
            </div>
            <div class="column" >
                <p>{dato.Dato}</p>
            </div>
        </div>
    )
}
export default DatoFarmaceutico;
