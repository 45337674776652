import { numberWithCommas } from "../../../../../services/utils";
import BloqueNombreValor from "../../../FichaMUH/Apartados/InfoGeneral/BloqueNombreValor";
import LMREspecie from "./LMREspecie";

const UsoAnimalesProductores = (props) => {
    const datosAnimalesProductores = props.datosAnimalesProductores;
    const LMR = datosAnimalesProductores.LMRsEspecies;
    const HTMLLMREspecie = LMR.map(lmre => <LMREspecie lmr={lmre} ></LMREspecie>);

    var ida = "-"

    if (datosAnimalesProductores.IngestaDiariaAceptada)
        if (datosAnimalesProductores.IngestaDiariaAceptada>0)
            ida = numberWithCommas(datosAnimalesProductores.IngestaDiariaAceptada);
    if (datosAnimalesProductores.Unidad)            
        ida += " " + datosAnimalesProductores.Unidad;


    const bloqueComun = [
        {
            nombre : 'Residuo marcador',
            valor: datosAnimalesProductores.ResiduoMarcador,
            link: null,
        },
       /*{
            nombre : 'Ingesta diaria aceptada',
            valor: ida,
            link: null,
        },*/
    ]

    const estiloEspecial = {borderBottom : 'none'}

    return (
        <div class="infoContent" >
            <BloqueNombreValor bloque={bloqueComun} estiloEspecial={estiloEspecial}></BloqueNombreValor>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            {HTMLLMREspecie}
        </div>
    )
}
export default UsoAnimalesProductores;
