import { useState } from "react";
import { GetAntecesorWhithClassName, rootPath } from "../../../../../services/utils";


const FilaAnalisis = (props) => {
    /*const [alturadivinicial, SetAlturadivinicial] = useState('');
    const [alturadivinicial2, SetAlturadivinicial2] = useState('');*/

    const interferencia = props.interferencia;
    const info = interferencia.Observaciones;
    const tieneInfo = (info !== null && info !=='')
    const tooltip =  (!tieneInfo ? '' :
        <span>
            <img src={rootPath + "img/infoGreen.svg"} alt="" onMouseOver={(e) => handleMouseOverIcon(e)} onMouseOut={(e) => handleMouseOutIcon(e)}/>
            <span class="tooltip" >
                {info}
            </span>
        </span>
    );
    const clasesDivAnalisis = (tieneInfo ? 'column fs fs12 fw greenColor4' : 'column fs fs12 fw greenColor4 ')
    const clasesDivEfecto = (tieneInfo ? 'column fs fs14 blackColor FlamaBook hasTooltip' : 'column fs fs14 blackColor FlamaBook');

    const CambiaPaginaInterferencias = (idAnalisis) => {
        props.cambiaPagina({
            newpagina: 'BAnalisisMUH',
            newid: idAnalisis,
        });
    }

    const handleMouseOverIcon = (e) =>{
        var itemImg = e.target;
        var itemTooltip = itemImg.parentElement.getElementsByClassName("tooltip")[0];
        var itemAccordionContent = GetAntecesorWhithClassName(itemImg,'dropdown__content');
        var itemInfoContent = GetAntecesorWhithClassName(itemImg,'infoContent');
        if (itemTooltip && itemAccordionContent){
            var rect = itemTooltip.getBoundingClientRect();
            var rectdiv = itemAccordionContent.getBoundingClientRect();
            var rectdiv2 = itemInfoContent.getBoundingClientRect();
            var finYdiv = (Math.round(rectdiv.y)+Math.round(rectdiv.height));
            var finYdiv2 = (Math.round(rectdiv2.y)+Math.round(rectdiv2.height));
            var finYtootip = (Math.round(rect.y)+Math.round(rect.height));
            if (finYtootip > finYdiv - 10){
                //SetAlturadivinicial(Math.round(rectdiv.height).toString() + 'px');
                var incremento = finYtootip - (finYdiv - 10);
                var newAltura = Math.round(rectdiv.height) + incremento;
                itemAccordionContent.style.height = newAltura.toString() + 'px';
            }
            if (finYtootip > finYdiv2 - 10){
                //SetAlturadivinicial2(Math.round(rectdiv2.height).toString() + 'px');
                var incremento = finYtootip - (finYdiv2 - 10);
                var newAltura = Math.round(rectdiv2.height) + incremento;
                itemInfoContent.style.height = newAltura.toString() + 'px';
            }
        }
    }

    const handleMouseOutIcon = (e) =>{
        var itemImg = e.target;
        var itemAccordionContent = GetAntecesorWhithClassName(itemImg,'dropdown__content');
        var itemInfoContent = GetAntecesorWhithClassName(itemImg,'infoContent');
        if (itemInfoContent){ //} && alturadivinicial2 !== ''){
            itemInfoContent.style.height = ''; //alturadivinicial2;
        }
        if (itemAccordionContent){ //&& alturadivinicial !== ''){
            itemAccordionContent.style.height = ''; //alturadivinicial;
        }
    }


    return (
        <div class="table__row table__row__normal fw fw300 uppercase">
            <div class={clasesDivAnalisis} >
                <p>
                    <span style={{cursor:'pointer'}} 
                        onClick={(e) => CambiaPaginaInterferencias(interferencia.IdAnalisis)}
                        onMouseOver={(e) => e.target.style.fontWeight='bold'}
                        onMouseOut={(e) => e.target.style.fontWeight='normal'}
                    >
                        {interferencia.Analisis.replace('-','\u2011')}
                    </span>
                </p>
            </div>
            <div class={clasesDivEfecto} >
                <p>
                    {interferencia.Efecto}
                    {tooltip}
                </p>
            </div>
        </div>
    )
}
export default FilaAnalisis;
