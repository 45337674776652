import FilaAnalisis from "./FilaAnalisis";


const Analisis = (props) => {
    const datosAnalisis = props.datosAnalisis;
    const HTMLAnalisis = datosAnalisis.map(interferencia => <FilaAnalisis interferencia={interferencia} cambiaPagina={props.cambiaPagina}></FilaAnalisis>);
    return (
        <div class="infoContent" >
            <div class="table" style={{width:'100%'}}>
                <div class="table__row table__row__header table__row__header__noBg fs fs12 fw fw600 greenColor uppercase">
                    <div class="column" >
                        <p>Análisis clínico</p>
                    </div>
                    <div class="column" >
                        <p>Efecto</p>
                    </div>
                </div>
                {HTMLAnalisis}
            </div>
        </div>
    )
}
export default Analisis;
