import CabeceraConfiguracion from "./CabeceraConfiguracion";
import PaginaConfiguracion from "./PaginaConfiguracion";

const Configuracion = (props) => {
    const infoPagina = props.infoPagina;

    return (
        <section class="section section__buscador" >  
            <CabeceraConfiguracion infoPagina={props.infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras}></CabeceraConfiguracion>
            <PaginaConfiguracion infoPagina={props.infoPagina} 
                                cambiaPagina={props.cambiaPagina}  refresca={props.refresca} atras ={props.atras}  
                                returnTo={props.returnTo} muestraAviso={props.muestraAviso}
                                cambiopass={props.cambiopass} 
                                cambiomail={props.cambiomail} 
                                confirmamail={props.confirmamail} 
                                link={props.link}
                                alTerminarCambio={props.alTerminarCambio}
            >                
            </PaginaConfiguracion>
        </section> 
    );
};

export default Configuracion;