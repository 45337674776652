import {AddClassNameToClassItems
        , RemoveClassNameToClassItems} from '../../../services/utils.js'
import MenuPrincipalGris from './MenuPrincipalGris.js';
import MenuPrincipalMorado from './MenuPrincipalMorado.js';
import MenuSecundarioMorado from './MenuSecundarioMorado.js';
import MenuTerciarioMorado from './MenuTerciarioMorado.js';
import ZonaContenidoHistorial from './ZonaContenidoHistorial.js';

const MenuHamburguesa = (props) => {

    const menusSecundarios =  props.opcionesMenu.map(item => <MenuSecundarioMorado 
        lista={item.apartados} 
        idx={props.opcionesMenu.indexOf(item)+1}
        cambiaPagina={props.cambiaPagina}
        perfil = {props.perfil}></MenuSecundarioMorado>)

    var itemsMenusTerciarios = []

    props.opcionesMenu.forEach(seccion => {
        seccion.apartados.forEach(apartado => {
            if (apartado.subapartados != null){
                itemsMenusTerciarios.push({
                    idx : seccion.apartados.indexOf(apartado) + 1,
                    idxPadre : props.opcionesMenu.indexOf(seccion) +1,
                    items : apartado.subapartados, 
                })
            }
        })
    });

    const menusTerciarios = itemsMenusTerciarios.map(item => <MenuTerciarioMorado
        lista = {item.items}
        idx = {item.idx}
        idxPadre = {item.idxPadre}
        cambiaPagina={props.cambiaPagina}
        perfil = {props.perfil}></MenuTerciarioMorado>)
    

    const HandleClick_BackItem1 = (e) =>{
        RemoveClassNameToClassItems('backItem__1',null, 'active');
        RemoveClassNameToClassItems("child__menu1",null, 'active');
        RemoveClassNameToClassItems("coverMenuHistorial",null, 'active');
        RemoveClassNameToClassItems("backItem__2",null, 'active');
        RemoveClassNameToClassItems("historial",null, 'active');
    }
    
    const HandleClick_BackItem2 = (e) =>{
        RemoveClassNameToClassItems('backItem__2',null, 'active');
        var padresanteriores = document.getElementsByClassName('active2');
        if (padresanteriores.length>0){
            var padre = padresanteriores[0];
            padre.classList.add('active');
        }
        RemoveClassNameToClassItems("child__menu2",null, 'active');
        RemoveClassNameToClassItems("child__menu1",null, 'active2');
        AddClassNameToClassItems('backItem__1',null, 'active');
        RemoveClassNameToClassItems("historial",null, 'active');
    }

    const HandleClick_BackItem3 = (e) =>{
        RemoveClassNameToClassItems('backItem__3',null, 'active');
        RemoveClassNameToClassItems("historial",null, 'active');
    }

    return (
        <div class="coverToggleMenu" id="miMenuBurguer__BP2022"> 
            <div class="coverMenuHistorial cover__30__70" >
                <div class="menu menu__1 cover__30" >
                    <MenuPrincipalMorado opcionesMenu = {props.opcionesMenu}  perfil = {props.perfil}/>
                    <MenuPrincipalGris opcionesMenu = {props.opcionesMenuGris}  cambiaPagina = {props.cambiaPagina}/>
                </div>
                <span class="backItem backItem__3" onClick={(e) => HandleClick_BackItem3(e)}>
                    &lt;- VOLVER
                </span>
                <ZonaContenidoHistorial cambiaPagina = {props.cambiaPagina} historial = {props.historial}/>
            </div>
            <div class="coverMenuChilds menu menu__2 cover__30__70" >
                <div class="cover__30" ></div>
                <div class="nenuChilds cover__70" >
                    <span class="backItem backItem__1" onClick={(e) => HandleClick_BackItem1(e)}>
                        &lt;- VOLVER
                    </span>
                    {menusSecundarios}
                    <span class="backItem backItem__2" onClick={(e) => HandleClick_BackItem2(e)}>
                        &lt;- VOLVER
                    </span>
                    {menusTerciarios}
                </div>
            </div>
        </div> 
    )
}
export default MenuHamburguesa;
