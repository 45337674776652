import EnfermedadesRelacionadas from "./EnfermedadesRelacionadas";
import Texto from "./Texto";
const Textos = (props) => {
    const codTipoRegistro = props.codTipoRegistro;
    const epigrafeActivo = (props.epigrafesActivos && props.epigrafesActivos.length>0) ? props.epigrafesActivos : (codTipoRegistro === "MUV" ? [83] : [3]);
    const datosTextos = props.datosTextos;
    //const epigrafeActivo = (datosTextos.Epigrafes.length>0 ? datosTextos.Epigrafes[0].IdEpigrafe : 1);

    const TextosHTML = datosTextos.Epigrafes.map(epi => <Texto cambiaPagina={props.cambiaPagina} 
                                                                epigrafe={epi} 
                                                                epigrafeActivo={epigrafeActivo}
                                                                ancla={(props.anclasEpis) ? props.anclasEpis.find(anc => anc.idAncla === "Epi" + epi.IdEpigrafe.toString()) : null}
                                                        ></Texto>);
    const hayIndicaciones = (datosTextos.PatologiasIndicadas) && (datosTextos.PatologiasIndicadas.length>0);
    const hayContraindicaciones = (datosTextos.PatologiasContraindicadas) && (datosTextos.PatologiasContraindicadas.length>0);
    const hayPrecauciones = (datosTextos.PatologiasPrecaucion) && (datosTextos.PatologiasPrecaucion.length>0);
    const hayRA = (datosTextos.EfectosAdversos) && (datosTextos.EfectosAdversos.length>0);
    const hayEnfermedades = (hayIndicaciones || hayContraindicaciones || hayPrecauciones || hayRA);
    return (
        <div class="infoContent" >
            {TextosHTML}

            {hayEnfermedades ?
                <div class="cover__subTitle" >
                    <h2 class="fs fs16 fw fw600 greenColor" >Patologías relacionadas</h2>
                </div>
            :
                <div></div>
            }
            {hayIndicaciones ?
                <EnfermedadesRelacionadas cambiaPagina={props.cambiaPagina} titulo='Indicaciones' enfermedades={datosTextos.PatologiasIndicadas} activo={true}></EnfermedadesRelacionadas>
                :
                <div></div>
            }
            {hayContraindicaciones ?
                <EnfermedadesRelacionadas cambiaPagina={props.cambiaPagina} titulo='Contraindicaciones' enfermedades={datosTextos.PatologiasContraindicadas} activo={false}></EnfermedadesRelacionadas>
                :
                <div></div>
            }
            {hayPrecauciones ?
                <EnfermedadesRelacionadas cambiaPagina={props.cambiaPagina} titulo='Precauciones' enfermedades={datosTextos.PatologiasPrecaucion} activo={false}></EnfermedadesRelacionadas>
                :
                <div></div>
            }
            {hayRA ?
                <EnfermedadesRelacionadas cambiaPagina={props.cambiaPagina} titulo='Reacciones adversas' enfermedades={datosTextos.EfectosAdversos} activo={false}></EnfermedadesRelacionadas>
                :
                <div></div>
            }
        </div>
    )
}
export default Textos;
