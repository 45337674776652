import React, { useState, useEffect } from "react";
import ListaResBusqueda from "./Listado/ListaResBusqueda";
import FiltrosBusqueda from "./Filtros/FiltrosBusqueda";
import ResumenFiltros from "./ResumenFiltros/ResumenFiltros";
import BusqMedsService from "../../../services/user.service";
import { elementIsOfClass, GetFiltrosStr, handleClasesCheckbox, PerfilUsuario, RemoveClassNameToClassItems } from "../../../services/utils";
import LoadingPopup from "../Loading.Popup";
import ColumnaSeccionMenuBlanco from "../../Cabecera/MenuBlanco/ColumnaSeccionMenuBlanco";
import CamposExportacion from "./Listado/Exportacion/CamposExportacion";
import AccesoRestringido from "../../Configuarcion/AccesoRestringido";
import BP2022Service from "../../../services/user.service";

var idUltimaBusqueda = 0;

const FiltrosYResultados = (props) => {
    const [filtrosYlista, setFiltrosYLista] = useState({
        listaFiltrada: props.infoPagina.listaRes, //aplicaFiltros(, props.filtros, props.infoPagina.idxOrden),
        filtros: props.filtros,
        idxOrden: props.infoPagina.idxOrden,
        numMostrar: props.infoPagina.numMostrar,
        numTotal: props.infoPagina.total,
        numDescargados: (props.infoPagina.listaRes) ? props.infoPagina.listaRes.length : 0,
    });
    const [codigoPagina, setCodigoPagina] = useState(props.infoPagina.codigoPagina);
    const [verCamposExporta, setVerCamposExporta] = useState (null);
    const [infoExportacion, setInfoExportacion] = useState (null);
    var loading = false;

    var campo = '';
    var ascendente = true;


    function compare (xa, xb) {
        var a = xa;
        var b = xb;
        if (a[campo] === null) 
        {
            if ((campo === 'PVPIVA')||(campo === 'Codigo'))
                a[campo] = 0;
            else
                a[campo]='';
        }
        else{
            if (campo === "FechaVisita")
                a[campo] = new Date(a[campo]).getTime();
        }

        if (b[campo] === null) 
        {
                if ((campo === 'PVPIVA')||(campo === 'Codigo'))
                    b[campo]=0;
                else
                    b[campo]='';
        }         
        else{
            if ((campo === "FechaVisita")||(campo === "Fecha"))
                b[campo] = new Date(b[campo]).getTime();
        }

        if(((a[campo]>b[campo]) && ascendente) || ((a[campo]<b[campo]) && !ascendente))
            return 1;
        else if (((a[campo]<b[campo]) && ascendente) || ((a[campo]>b[campo]) && !ascendente))
            return -1;
        else
            return 0
    }

    function cambioOrden (lista,idx) {

        if ((lista) && (idx>=0))
        {
            let templista = JSON.parse(JSON.stringify(lista));
            if (props.infoPagina.codigoPagina !== "BDocumentos"){
                switch(idx){
                    case 0:
                        campo = 'NombreCompleto';
                        ascendente = true;
                        break;
                    case 1:
                        campo = 'NombreCompleto';
                        ascendente = false;
                        break;
                    case 2:
                        campo = 'Codigo';
                        ascendente = true;
                        break;
                    case 3:
                        campo = 'Codigo';
                        ascendente = false;
                        break;
                    case 4:
                        campo = 'PVPIVA';
                        ascendente = true;
                        break;
                    case 5:
                        campo = 'PVPIVA';
                        ascendente = false;
                        break;     
                    case 6:
                        campo = 'FechaVisita';
                        ascendente = false;
                        break;     
                }
                lista =  lista.sort(compare);
                return lista;
            }
            else{
                switch(idx){
                    case 0:
                        campo = 'Titulo';
                        ascendente = true;
                        break;
                    case 1:
                        campo = 'Titulo';
                        ascendente = false;
                        break;
                    case 2:
                        campo = 'Fecha';
                        ascendente = true;
                        break;     
                    case 3:
                        campo = 'Fecha';
                        ascendente = false;
                        break;     
                }
                lista =  lista.sort(compare);
                return lista;
            }
        }
        return lista;
    }

    function aplicaFiltros(lista, filtros, orden)
    {
        if (filtros)
        {
            for (var i=0; i<filtros.length; i++)
            {
                lista = aplicaFiltro(lista,filtros[i]);
            }
            checkTipoProductoVisibility(filtros);
        }
        lista = cambioOrden(lista,orden);
        return lista;
    };

    function aplicaFiltro(lista, filtro)
    {
        if (lista){
            if (filtro.tipo==='List'){
                if (filtro.tipoCampolista==='codString')
                    if (filtro.valor && filtro.valor !== '')
                        lista = lista.filter(item => item[filtro.campolista]===filtro.valor);
                if (filtro.tipoCampolista==='int')
                    if (filtro.valor && filtro.valor !== -1)
                        lista = lista.filter(item => item[filtro.campolista]===filtro.valor);
                if (filtro.tipoCampolista==='string')
                    if (filtro.valor && filtro.valor !== ''){
                        lista = lista.filter(item => item[filtro.campolista]);
                        lista = lista.filter(item => item[filtro.campolista].toString().toLowerCase().includes(filtro.valor.toLowerCase()));
                    }
            }
            if (filtro.tipo==='ListBox'){
                if (filtro.tipoCampolista==='codString')
                    if (filtro.valor && filtro.valor !== '')
                        lista = lista.filter(item => item[filtro.campolista]===filtro.valor);
                if (filtro.tipoCampolista==='int')
                    if (filtro.valor && filtro.valor !== -1)
                        lista = lista.filter(item => item[filtro.campolista]===filtro.valor);
                if (filtro.tipoCampolista === 'array')
                    if (filtro.valor && filtro.valor !== -1)
                        lista = lista.filter(item => item[filtro.campolista].includes(filtro.valor));
            }
            if (filtro.tipo==='Text'){
                if (filtro.tipoCampolista==='string')
                    if (filtro.valor && filtro.valor !== ''){
                        //lista = lista.filter(item => item[filtro.campolista]);
                        lista = lista.filter(item => item[filtro.campolista].toString().toLowerCase().includes(filtro.valor.toLowerCase()));
                    }
            }
            if (filtro.tipo==='CN'){
                if (filtro.valor && filtro.valor > 0){
                    lista = lista.filter(item => (item[filtro.campolista].toString()===filtro.valor.toString() 
                        || item[filtro.campolista].toString().substring(0,6)===filtro.valor.toString().substring(0,6)
                        || item[filtro.campolista].toString().substring(0,filtro.valor.toString().length)===filtro.valor.toString()
                    ));
                }
            }
            if (filtro.tipo==='Check'){
                if ((filtro.tipoCampolista==='codString') || (filtro.tipoCampolista==='int') || (filtro.tipoCampolista==='array') || (filtro.tipoCampolista==='float')){
                    if (filtro.valores){
                        if (filtro.excluyente){
                            var enc = false;
                            for (var i=0; i<filtro.valores.length && !enc; i++){
                                if (filtro.valores[i].activo){
                                    enc = true;
                                    const relacion = (filtro.relacion) ? filtro.relacion : "igualdad";
                                    if (relacion === "igualdad"){
                                        if (filtro.tipoCampolista==='int')
                                            lista = lista.filter(item => parseInt(item[filtro.campolista])===parseInt(filtro.valores[i].value));
                                        else
                                            lista = lista.filter(item => item[filtro.campolista]===filtro.valores[i].value);
                                    }else if (relacion === "comparacion"){
                                        if (filtro.valores[i].comparacion===">"){
                                            lista = lista.filter(item => item[filtro.campolista]>filtro.valores[i].value);
                                        }else if (filtro.valores[i].comparacion===">="){
                                            lista = lista.filter(item => item[filtro.campolista]>=filtro.valores[i].value);
                                        }else if (filtro.valores[i].comparacion==="<"){
                                            lista = lista.filter(item => item[filtro.campolista]<filtro.valores[i].value);
                                        }else if (filtro.valores[i].comparacion==="<="){
                                            lista = lista.filter(item => item[filtro.campolista]<=filtro.valores[i].value);
                                        }else if (filtro.valores[i].comparacion==="<>"){
                                            lista = lista.filter(item => item[filtro.campolista]!==filtro.valores[i].value);
                                        }else if (filtro.valores[i].comparacion==="="){
                                            lista = lista.filter(item => item[filtro.campolista]===filtro.valores[i].value);
                                        }
                                    }else if (relacion === "AND"){
                                        lista = lista.filter(item => parseInt(filtro.valores[i].value)===(parseInt(filtro.valores[i].value) & item[filtro.campolista]));
                                    }else if (relacion === "OR"){
                                        lista = lista.filter(item => parseInt(filtro.valores[i].value)===(parseInt(filtro.valores[i].value) | item[filtro.campolista]));
                                    }
                                    
                                }
                            }
                        }
                        else{ //no excluyente
                            const relacion = (filtro.relacion) ? filtro.relacion : "igualdad";
                            if ((relacion === "igualdad") || (relacion === "notIn")){
                                var seleccionados = [];
                                for (var i=0; i<filtro.valores.length; i++){
                                    if (filtro.valores[i].activo){
                                        seleccionados.push(filtro.valores[i].value);
                                        if (filtro.valores[i].otrosValores)
                                            for (var j=0; j<filtro.valores[i].otrosValores.length; j++)
                                            {
                                                seleccionados.push(filtro.valores[i].otrosValores[j]);
                                            }
                                    }
                                }
                                if (seleccionados.length>0){
                                    if (filtro.tipoCampolista === 'array'){  
                                        if (filtro.relacion === "notIn"){                                  
                                            lista = lista.filter(item => (!item[filtro.campolista] || item[filtro.campolista].filter(valorlista => seleccionados.includes(valorlista) ).length==0));
                                        }else{
                                            lista = lista.filter(item => (item[filtro.campolista].filter(valorlista => seleccionados.includes(valorlista) ).length>0));
                                        }
                                    }
                                    else
                                        lista = lista.filter(item => seleccionados.includes(item[filtro.campolista]));
                                }
                            }else if (relacion === "comparacion"){
                                for (var i=0; i<filtro.valores.length; i++){
                                    if (filtro.valores[i].activo){
                                        if (filtro.valores[i].comparacion===">"){
                                            lista = lista.filter(item => item[filtro.campolista]>filtro.valores[i].value);
                                        }else if (filtro.valores[i].comparacion===">="){
                                            lista = lista.filter(item => item[filtro.campolista]>=filtro.valores[i].value);
                                        }else if (filtro.valores[i].comparacion==="<"){
                                            lista = lista.filter(item => item[filtro.campolista]<filtro.valores[i].value);
                                        }else if (filtro.valores[i].comparacion==="<="){
                                            lista = lista.filter(item => item[filtro.campolista]<=filtro.valores[i].value);
                                        }else if (filtro.valores[i].comparacion==="<>"){
                                            lista = lista.filter(item => item[filtro.campolista]!==filtro.valores[i].value);
                                        }else if (filtro.valores[i].comparacion==="="){
                                            lista = lista.filter(item => item[filtro.campolista]===filtro.valores[i].value);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return lista;
    };


    const mostrarCamposExporta= (valor) => {
        if (valor){
            var infoExport = [];
            var tieneMUH = false;
            var tieneMUV = false;
            var tienePPF = false;
            var tieneFM = false;
            var defaultTipoExporta = "";
            for (var i=0; i<filtrosYlista.listaFiltrada.length; i++){
                var item = filtrosYlista.listaFiltrada[i];
                var codTipoExporta = item.CodTipoRegistro;           
                switch (item.CodTipoRegistro){
                    case "PAH":
                    case "PAV":
                    case "PHO":    
                    case "ASO":
                    case "ASOH":
                    case "ASOV":
                        codTipoExporta = "PA";
                    break;
                    case "PATH":
                    case "PATV":
                        codTipoExporta = "PAT"
                    break;
                    case "MUH":
                    case "MHO":
                        codTipoExporta = "MUH"
                        tieneMUH = true;
                    break;
                    case "FM":
                        tieneFM = true;
                    break;
                    case "MUV":
                        tieneMUV = true;
                    break;
                    case "PPF":
                        tienePPF = true;
                    break;
                }
                var info = infoExport.find(ie => ie.codTipoExporta === codTipoExporta);
                if (!info){
                    info = {codTipoExporta : codTipoExporta, lista : []}
                    infoExport.push(info);
                }
                if (item.CodTipoRegistro==="ASO" || item.CodTipoRegistro==="ASOH" || item.CodTipoRegistro==="ASOV"){
                    info.lista.push(item.Id + 1000000);
                }else{
                    info.lista.push(item.Id);
                }
                if (i===0)
                    defaultTipoExporta = codTipoExporta;
            }
            if (tieneMUH) defaultTipoExporta = "MUH";
            else if (tieneMUV) defaultTipoExporta = "MUV";
            else if (tienePPF) defaultTipoExporta = "PPF";
            setVerCamposExporta(defaultTipoExporta);
            setInfoExportacion(infoExport);
        }
        else{
            setVerCamposExporta(null);
        }
    }

    const vaciarHistorial = () => {
        BP2022Service.emptyHistorial();
        let tempVar = JSON.parse(JSON.stringify(filtrosYlista));
        tempVar.listaFiltrada = [];
        tempVar.numTotal = 0;
        tempVar.numMostrar = 0;
        tempVar.numDescargados = 0;
        setFiltrosYLista(tempVar);
    };

    const cambioFiltroCheck = (idFiltro, idValor, marcado, ) => {
        let tempVar = JSON.parse(JSON.stringify(filtrosYlista));
        var enc = false;
        for (var i=0; i<tempVar.filtros.length && !enc; i++){
            if (tempVar.filtros[i].idGrupo === idFiltro){
                for (var j=0; j<tempVar.filtros[i].valores.length && !enc; j++){
                    if (tempVar.filtros[i].valores[j].idValor === idValor || tempVar.filtros[i].valores[j].value === idValor){
                        enc = true;
                        tempVar.filtros[i].valores[j].activo = marcado;
                        if (tempVar.filtros[i].excluyente && marcado){
                            for (var k=0; k<tempVar.filtros[i].valores.length; k++){
                                if (k!==j)
                                    tempVar.filtros[i].valores[k].activo = false;
                            }
                        }
                        handleClasesCheckbox(idFiltro, idValor, marcado, tempVar.filtros[i].excluyente);
                        tempVar.listaFiltrada = aplicaFiltros(props.infoPagina.listaRes, tempVar.filtros, tempVar.idxOrden);
                        setFiltrosYLista(tempVar);
                    }
                }
            }
        }
    };

    const checkTipoProductoVisibility = (fils) =>{
        var fTipoPPF = fils.find(f=>f.idGrupo === "TIPOPPF");
        if (fTipoPPF){
            fTipoPPF.oculto = true;
            if (fTipoPPF){
                var fTipo = fils.find(f=>f.idGrupo === "TIPO")
                if (fTipo){
                    var vPPF = fTipo.valores.find(v=>(v.value === "PPF"));
                    if (vPPF){
                        fTipoPPF.oculto = !vPPF.activo || !(fTipoPPF.posibles.length>2);
                        if (fTipoPPF.oculto){
                            fTipoPPF.valor = -1;
                        }else if (fTipoPPF.valor>0){
                            var item = fTipoPPF.posibles.find(item => item.codigo === fTipoPPF.valor)
                            if (item) fTipoPPF.displayValor = item.valor;
                        }
                    }                        
                }
                else{
                    fTipoPPF.oculto = !(fTipoPPF.posibles.length>2)
                    if (fTipoPPF.oculto){
                        fTipoPPF.valor = -1;
                    }else if (fTipoPPF.valor>0){
                        var item = fTipoPPF.posibles.find(item => item.codigo === fTipoPPF.valor)
                        if (item) fTipoPPF.displayValor = item.valor;
                    }
                }
            }
        }
    }

    const cambioFiltroList = (idFiltro, valor) => {
        let tempVar = JSON.parse(JSON.stringify(filtrosYlista));
        var enc = false;
        for (var i=0; i<tempVar.filtros.length && !enc; i++){
            if (tempVar.filtros[i].idGrupo === idFiltro){
                enc=true;
                tempVar.filtros[i].valor = valor;
                if (tempVar.filtros[i].tipo === "ListBox"){
                    var item = tempVar.filtros[i].posibles.find(item => item.codigo === valor)
                    if (item) tempVar.filtros[i].displayValor = item.valor;
                }
                tempVar.listaFiltrada = aplicaFiltros(props.infoPagina.listaRes, tempVar.filtros, tempVar.idxOrden);
                setFiltrosYLista(tempVar);
            }
        }
    };

    const cambioOrdenList = (idxOrden) => {
        let tempVar = JSON.parse(JSON.stringify(filtrosYlista));
        tempVar.idxOrden = idxOrden;
        tempVar.listaFiltrada = cambioOrden(tempVar.listaFiltrada,idxOrden);
        setFiltrosYLista(tempVar);
    };

    const borraFiltro = (idFiltro, idValor) =>
    {
        let tempVar = JSON.parse(JSON.stringify(filtrosYlista));
        var enc = false;
        for (var i=0; i<tempVar.filtros.length && !enc; i++){
            if (tempVar.filtros[i].idGrupo === idFiltro){
                if (tempVar.filtros[i].tipo==='List'){
                    enc = true;
                    document.getElementById('input__'+idFiltro).value='';
                    cambioFiltroList(idFiltro, '');
                }
                if (tempVar.filtros[i].tipo==='ListBox'){
                    enc = true;
                    var item = document.getElementById('input__'+tempVar.filtros[i].idGrupo);
                    item = item.nextSibling;
                    item = item.children[0];
                    item.placeholder = tempVar.filtros[i].placeholder;
                    cambioFiltroList(idFiltro, -1);
                }
                else if (tempVar.filtros[i].tipo==='Text'){
                    enc = true;
                    document.getElementById('input__'+idFiltro).value='';
                    cambioFiltroList(idFiltro, '');
                }
                else if (tempVar.filtros[i].tipo==='Check'){
                    enc = true;
                    var item = document.getElementById(idFiltro + '__' + idValor);
                    if (item){
                        item.checked = false;
                    }
                    cambioFiltroCheck(idFiltro,idValor,false);
                    handleClasesCheckbox(idFiltro,idValor,false,tempVar.filtros[i].excluyente);
                }
            }
        }
    }

    const borraTodosFiltros = () =>
    {
        let tempVar = JSON.parse(JSON.stringify(filtrosYlista));
        for (var i=0; i<tempVar.filtros.length; i++){
            if (tempVar.filtros[i].tipo==='List'){
                document.getElementById('input__'+tempVar.filtros[i].idGrupo).value='';
                tempVar.filtros[i].valor = '';
            }
            if (tempVar.filtros[i].tipo==='Text'){
                document.getElementById('input__'+tempVar.filtros[i].idGrupo).value='';
                tempVar.filtros[i].valor = '';
            }
            if (tempVar.filtros[i].tipo==='ListBox'){
                var item = document.getElementById('input__'+tempVar.filtros[i].idGrupo);
                item = item.nextSibling;
                item = item.children[0];
                item.placeholder = tempVar.filtros[i].placeholder;
                tempVar.filtros[i].valor = -1;
            }
            if (tempVar.filtros[i].tipo==='Check'){
                for(var j=0; j<tempVar.filtros[i].valores.length; j++){
                    var item = document.getElementById(tempVar.filtros[i].idGrupo + '__' + tempVar.filtros[i].valores[j].value);
                    if (item){
                        item.checked = false;
                    }
                    tempVar.filtros[i].valores[j].activo = false;
                    handleClasesCheckbox(tempVar.filtros[i].idGrupo,tempVar.filtros[i].valores[j].value,false,tempVar.filtros[i].excluyente);
                }
            }
        }
        tempVar.listaFiltrada = aplicaFiltros(props.infoPagina.listaRes, tempVar.filtros, tempVar.idxOrden);
        setFiltrosYLista(tempVar);
    }

    const ChequeaPosiblesFiltros = (filtros) =>{
        if ((props.infoPagina.listaRes != null) && (filtros != null))
        {
            for (var i=0; i<filtros.length; i++){
                if ((filtros[i].tipo === 'List') || (filtros[i].tipo === 'ListBox'))
                {
                    if (filtros[i].posibles.length===0){
                        if ((props.filtros[i]) && (props.filtros[i].posibles.length>0)){
                            filtros[i].posibles = props.filtros[i].posibles;
                            if (filtros[i].tipo === 'ListBox'){
                                if (filtros[i].valor>0){
                                    var item = filtros[i].posibles.find(item => item.codigo === filtros[i].valor)
                                    if (item) filtros[i].displayValor = item.valor;
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    useEffect(() => {
        let tempVar = JSON.parse(JSON.stringify(filtrosYlista));        
        if (props.infoPagina.codigoPagina !== codigoPagina){
            tempVar.filtros = props.filtros;
            setFiltrosYLista(tempVar);
            setCodigoPagina(props.codigoPagina);
            setVerCamposExporta(null);
        }
        if (JSON.stringify(tempVar.filtros) !== JSON.stringify(props.filtros)){
            tempVar.filtros = props.filtros;
            setFiltrosYLista(tempVar);
        }
        if (props.infoPagina.listaRes===null){
            tempVar.filtros = props.filtros;
            if (verCamposExporta)
                setVerCamposExporta(null);
        }
        if ((!props.infoPagina.listaRes) || (!props.infoPagina.completa && !loading)){
            busqueda();
            if (verCamposExporta)
                setVerCamposExporta(null);
        }
        if (props.infoPagina.listaRes){
            ChequeaPosiblesFiltros(tempVar.filtros);
            tempVar.listaFiltrada =  aplicaFiltros(props.infoPagina.listaRes, tempVar.filtros, tempVar.idxOrden);
            tempVar.numTotal = props.infoPagina.total;
            tempVar.numDescargados = props.infoPagina.listaRes.length;
            setFiltrosYLista(tempVar);
        }
        for (var i=0; i<tempVar.filtros.length; i++){
            if (tempVar.filtros[i].tipo==='Check'){
                handleClasesCheckbox(tempVar.filtros[i].idGrupo, tempVar.filtros[i].valores[0].value, tempVar.filtros[i].valores[0].activo, tempVar.filtros[i].excluyente);
            }
        }
    }, [props.infoPagina.listaRes, props.filtros ])

    const MuestraResultados = (resultados,titulo,incompleta,total,idBusqueda,pvpMenor,pvpMasBajo,literalPMenor,literalPMasBajo) => {
        if (rutaAlInicioBusqueda == window.location.href)
        {
            var completa = true;
            if (incompleta)
                completa = false;
            props.cambiaPagina({
                newpagina: props.infoPagina.codigoPagina,
                newid: props.infoPagina.id,
                newlista: resultados,
                texto: props.infoPagina.texto,
                filtros: filtrosYlista.filtros,
                titulo: titulo,
                filtrosActivos: props.infoPagina.filtrosActivos,
                numMostrar: props.infoPagina.numMostrar,
                idxOrden: filtrosYlista.idxOrden,
                tipoPagina: "ResultadosBusqueda",
                completa: completa,
                total: total,
                idEpi: props.infoPagina.idEpi,
                idsEnfs: props.infoPagina.idsEnfs,
                idsMeds: props.infoPagina.idsMeds,
                txt: props.infoPagina.txt,
                idLab: props.infoPagina.idLab,
                idGT: props.infoPagina.idGT,
                idsLab: props.infoPagina.idsLab,
                idsGT: props.infoPagina.idsGT,
                pas: props.infoPagina.pas,
                solo: props.infoPagina.solo,
                idVia: props.infoPagina.idVia,
                idFF: props.infoPagina.idFF,
                ints: props.infoPagina.ints,
                uds: props.infoPagina.uds,
                emr: props.infoPagina.emr,
                edad: props.infoPagina.edad,
                undEdad: props.infoPagina.undEdad,
                enfsRel: props.infoPagina.enfsRel,
                anasRel: props.infoPagina.anasRel,
                ops: props.infoPagina.ops,
                idEspecie: props.infoPagina.idEspecie,
                idEnf: props.infoPagina.idEnf,
                idTipo: props.infoPagina.idTipo,
                idSubtipo: props.infoPagina.idSubtipo,
                idsTipo: props.infoPagina.idsTipo,
                idsSubtipo: props.infoPagina.idsSubtipo,
                tipoFF: props.infoPagina.tipoFF,
                idForma: props.infoPagina.idForma,
                idColor1: props.infoPagina.idColor1,
                idColor2: props.infoPagina.idColor2,
                idSabor: props.infoPagina.idSabor,
                CO1: props.infoPagina.CO1,
                CO2: props.infoPagina.CO2,
                tamanos: props.infoPagina.tamanos,
                ranura: props.infoPagina.ranura,
                logo: props.infoPagina.logo,
                difCol: props.infoPagina.difCol,
                ins1: props.infoPagina.ins1,
                ins2: props.infoPagina.ins2,
                anycol: props.infoPagina.anycol,
                col2: props.infoPagina.col2,
                idPais: props.infoPagina.idPais,
                inicio: props.infoPagina.inicio,
                fin: props.infoPagina.fin,
                pvpMenor: pvpMenor,
                pvpMasBajo: pvpMasBajo,
                literalPMenor: literalPMenor,
                literalPMasBajo: literalPMasBajo,
                fechaAutoIni: props.infoPagina.fechaAutoIni,
                fechaAutoFin: props.infoPagina.fechaAutoFin,
                fechaBajaIni: props.infoPagina.fechaBajaIni,
                fechaBajaFin: props.infoPagina.fechaBajaFin,
                fechaExFIIni: props.infoPagina.fechaExFIIni,
                fechaExFIFin: props.infoPagina.fechaExFIFin,
                fechaExSNSIni: props.infoPagina.fechaExSNSIni,
                fechaExSNSFin: props.infoPagina.fechaExSNSFin,
                fechaInSNSIni: props.infoPagina.fechaInSNSIni,
                fechaInSNSFin: props.infoPagina.fechaInSNSFin,
                fechaComerIni: props.infoPagina.fechaComerIni,
                fechaComerFin: props.infoPagina.fechaComerFin,
                idCampoP: props.infoPagina.idCampoP,
                idOpP: props.infoPagina.idOpP,
                pre: props.infoPagina.pre,
                idPredefP: props.infoPagina.idPredefP,
                idsAA: props.infoPagina.idsAA,
                idsAN: props.infoPagina.idsAN,
                idsDC: props.infoPagina.idsDC,
                idsMen: props.infoPagina.idsMen,
                idEpi: props.infoPagina.idEpi,
                texto: props.infoPagina.texto,
                noTexto : props.infoPagina.noTexto,
                opb: props.infoPagina.opb,
                seleccionados: props.infoPagina.seleccionados,
            });
        }
    }


    var rutaAlInicioBusqueda = '';
   
    const busqueda = () => {
        var numMax = 500;
        if (props.infoPagina.listaRes){
            numMax = 100000;
        }
        loading = true;
        rutaAlInicioBusqueda = window.location.href;//history.location.pathname + history.location.search;
        var idBusqueda = Math.floor(Math.random() * (1000000));
        idUltimaBusqueda = idBusqueda;
        switch (props.infoPagina.codigoPagina){
            case 'BInicio':
                BusqMedsService.getMedsByName(props.infoPagina.texto, props.infoPagina.opb, props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
            break;
            case 'BLaboratorioMUV':
            case 'BLaboratorioMUH':
            case 'BLaboratorioMHO':
                BusqMedsService.medicamentosLaboratorio(props.infoPagina.id, props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
            break;
            case 'BLaboratorioPPF':
                BusqMedsService.productosLaboratorio(props.infoPagina.id, props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
            break;
            case "BAgrupHomoAutoMUH":
                BusqMedsService.medicamentosAgrupacionAuto (props.infoPagina.id, props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    var filtroscp = filtrosYlista.filtros.find(f => f.idGrupo === "COMPPRE");
                    if (filtroscp && filtroscp.valores){
                        var filtropm = filtroscp.valores.find(v => v.idValor === "SPM")
                        if (filtropm){
                            filtropm.value=response.data.AuxDbl;
                        }
                        filtropm = filtroscp.valores.find(v => v.idValor === "APM")
                        if (filtropm){
                            filtropm.value=response.data.AuxDbl;
                        }
                        var filtropmb = filtroscp.valores.find(v => v.idValor === "SPMB")
                        if (filtropmb){
                            filtropmb.value=response.data.AuxDbl2;
                        }
                        filtropmb = filtroscp.valores.find(v => v.idValor === "APMB")
                        if (filtropmb){
                            filtropmb.value=response.data.AuxDbl2;
                        }
                    }
                    MuestraResultados(miListaBusqeda, response.data.Titulo, false, response.data.Resultados.length, -1, response.data.AuxDbl, response.data.AuxDbl2, response.data.AuxStr, response.data.AuxStr2);
                });
            break;
            case "BAgrupHomoMUH":
                BusqMedsService.medicamentosAgrupacioNac(props.infoPagina.id, props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    var filtroscp = filtrosYlista.filtros.find(f => f.idGrupo === "COMPPRE");
                    if (filtroscp && filtroscp.valores){
                        var filtropm = filtroscp.valores.find(v => v.idValor === "SPM")
                        if (filtropm){
                            filtropm.value=response.data.AuxDbl;
                        }
                        filtropm = filtroscp.valores.find(v => v.idValor === "APM")
                        if (filtropm){
                            filtropm.value=response.data.AuxDbl;
                        }
                        var filtropmb = filtroscp.valores.find(v => v.idValor === "SPMB")
                        if (filtropmb){
                            filtropmb.value=response.data.AuxDbl2;
                        }
                        filtropmb = filtroscp.valores.find(v => v.idValor === "APMB")
                        if (filtropmb){
                            filtropmb.value=response.data.AuxDbl2;
                        }
                    }
                    MuestraResultados(miListaBusqeda, response.data.Titulo, false, response.data.Resultados.length, -1, response.data.AuxDbl, response.data.AuxDbl2, response.data.AuxStr, response.data.AuxStr2);
                });
                break;
            case "BAgrupHomoAutoPPF":
                BusqMedsService.productosAgrupacionAuto(props.infoPagina.id, props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo, false, response.data.Resultados.length, -1, response.data.AuxDbl, response.data.AuxDbl2, response.data.AuxStr, response.data.AuxStr2);
                });
                break;
            case "BAgrupHomoPPF":
                BusqMedsService.productosAgrupacionNac(props.infoPagina.id, props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo, false, response.data.Resultados.length, -1, response.data.AuxDbl, response.data.AuxDbl2, response.data.AuxStr, response.data.AuxStr2);
                });
                break;
            case "BFormaFarmaMUH":
            case "BFormaFarmaMUV":
                BusqMedsService.medicamentosFormaFarma(props.infoPagina.id, props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
                break;
            case "BFormaFarmaPPF":
                BusqMedsService.productosFormaFarma(props.infoPagina.id, props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
                break;
            case "BAFMUH":
            case "BAFMUV":
            case "BAMMUH":
                BusqMedsService.principiosActividad(props.infoPagina.id, props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
                break;
            case "BActPPF":
                BusqMedsService.ingredientesActividad(props.infoPagina.id, props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
                break;
            case 'BLaboratoriosMUH':
            case 'BLaboratoriosMUV':
            case 'BLaboratoriosMHO':
                BusqMedsService.medicamentosLaboratorios(props.infoPagina.seleccionados, props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
            break;
            case 'BLaboratoriosPPF':
                BusqMedsService.productosLaboratorios(props.infoPagina.seleccionados, props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
            break;
            case "BFormasFarmaMUH":
            case "BFormasFarmaMUV":
                BusqMedsService.medicamentosFormasFarma(props.infoPagina.seleccionados, props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
                break;
            case "BFormasFarmaPPF":
                BusqMedsService.productosFormasFarma(props.infoPagina.seleccionados, props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
                break;
            case "BAFsMUH":
            case "BAFsMUV":
                BusqMedsService.principiosActividadesY(props.infoPagina.seleccionados, props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
                break;
            case "BAMsMUH":
                BusqMedsService.principiosActividadesY(props.infoPagina.seleccionados, props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
                break;
            case "BActsPPF":
                BusqMedsService.ingredientesActividadesO(props.infoPagina.seleccionados, props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
                break;
            case "BAnalisisMUH":
                BusqMedsService.medicamentosAnalisis(props.infoPagina.id, props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
                break;
            case "BFarmacogeneticaMUH":
                BusqMedsService.medicamentosBiomarcador(props.infoPagina.id, props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
                break;
            case "BPAMUH":
            case "BPAMUV":
                BusqMedsService.medicamentosConPA(props.infoPagina.id, props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
                break;
            case "BAsoMUH":
            case "BAsoMUV":
                BusqMedsService.medicamentosConAso(props.infoPagina.id, props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
                break;
            case "BIngPPF":
                BusqMedsService.productosConIng(props.infoPagina.id, props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
                break;
            case "BTipoPPF":
                BusqMedsService.productosTipo(props.infoPagina.id, props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
                break;
            case "BSubtipoPPF":
                BusqMedsService.productosSubtipo(props.infoPagina.id, props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data);
                });
                break;
            case "BIndicacionMUH":
                BusqMedsService.medicamentosPorEnf(props.infoPagina.id, 3, props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
                break;
            case "BIndicacionMUV":
                BusqMedsService.medicamentosPorEnf(props.infoPagina.id, 83, props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                    MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
                break;
            case "BIndicacionPPF":
                BusqMedsService.productosPorEnf(props.infoPagina.id, props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
                break;
            case "BContraindicacionMUH":
                BusqMedsService.medicamentosPorEnf(props.infoPagina.id, 13, props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
                break;
            case "BPrecaucionMUH":
                BusqMedsService.medicamentosPorEnf(props.infoPagina.id, 14, props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
                break;
            case "BReaccionAdversaMUH":
                BusqMedsService.medicamentosPorEnf(props.infoPagina.id, 7, props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
                break;
            case "BGTsATCMUH":
            case "BGTsATCMUV":
                BusqMedsService.medicamentos2GTATC(props.infoPagina.seleccionados, props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
                break;
            case "BGTATCMUH":
            case "BGTATCMUV":
                BusqMedsService.medicamentosGTATC(props.infoPagina.id, props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
                break;
            case "BGTConPPF":
                BusqMedsService.productosGTCon(props.infoPagina.id, props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
                break;
            case "BGTSanPPF":
                BusqMedsService.productosGTSan(props.infoPagina.id, props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
                break;
            case "BGTVMPPMUH":
                BusqMedsService.medicamentosGTVMPP(props.infoPagina.id, props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
                break;
    
            case "BSaborEdadPPF":
                BusqMedsService.productosSaborEdad(props.infoPagina).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
                break;
    
            case "BEspecieIndicacionMUV":
                BusqMedsService.medicamentosEspecieIndicacion(props.infoPagina.idEnf, props.infoPagina.idEspecie, props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
                break;
                
            case "BProblemasSumMUH":
                BusqMedsService.medicamentosProblemasSuministro(props.infoPagina, props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
                break;
                                
            case "BAlertasCMUH":
                BusqMedsService.medicamentosAlertasCalidad(props.infoPagina, props.infoPagina.idxOrden, "H").then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
                break;
                                
            case "BAlertasCMUV":
                BusqMedsService.medicamentosAlertasCalidad(props.infoPagina, props.infoPagina.idxOrden,"V").then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
                break;
                                
            case "BAlertasCPPF":
                BusqMedsService.PFAlertasCalidad(props.infoPagina, props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
                break;
            case "BReaccionesAdversasMUH":
                BusqMedsService.medicamentosReaccionesAdversas(props.infoPagina,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
                break;
            case "BComposicionMUH":
            case "BComposicionMUV":
            case "BComposicionPPF":
                BusqMedsService.productosPorComposicion(props.infoPagina).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
                break;
            case "BAvanzadaMUH":
                BusqMedsService.medicamentosAvanzadaMUH(props.infoPagina,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
                break;
            case "BAvanzadaMUV":
                BusqMedsService.medicamentosAvanzadaMUV(props.infoPagina,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
                break;
            case "BAvanzadaPAH":
                BusqMedsService.principiosActivosAvanzadaPAH(props.infoPagina).then((response) => {
                    //console.log(response.data);
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
                break;
            case "BAvanzadaPPF":
                BusqMedsService.productosAvanzadaPPF(props.infoPagina,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
                break;
            case "BPacienteIndicacionMUH":
                BusqMedsService.medicamentosPacienteIndicacion(props.infoPagina).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
                break;

            case "BProductosIndicacionPPF":
                BusqMedsService.productosIndicacion(props.infoPagina).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
                break;

            case "BFormMagistralMUH":
                BusqMedsService.medicamentosFM(props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
                break;
            case "BMedsHomeoMUH":
                BusqMedsService.medicamentosHomeo(props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
                break;
            case "BFormaColorMUH":
                BusqMedsService.medicamentosFormaColor(props.infoPagina,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
                break;

            case "BExtranjerosEXT":
                BusqMedsService.medicamentosEXT(props.infoPagina,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
                break;
            case 'BMedEspanolesMUH':
                    BusqMedsService.medicamentosEspanoles(props.infoPagina.id, props.infoPagina.idxOrden).then((response) => {
                        const miListaBusqeda = response.data.Resultados;
                        MuestraResultados(miListaBusqeda, response.data.Titulo);
                    });
                break;
            case "BConGluten":
                    BusqMedsService.productosConGluten(props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                        const miListaBusqeda = response.data.Resultados;
                        if (idUltimaBusqueda === response.data.IdBusqueda)
                            MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                    });
                break;
            case "BConLactosa":
                    BusqMedsService.productosConLactosa(props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                        const miListaBusqeda = response.data.Resultados;
                        if (idUltimaBusqueda === response.data.IdBusqueda)
                            MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                    });
                break;
            case "BSinGluten":
                    BusqMedsService.productosSinGluten(props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                        const miListaBusqeda = response.data.Resultados;
                        if (idUltimaBusqueda === response.data.IdBusqueda)
                            MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                    });
                break;
            case "BSinLactosa":
                    BusqMedsService.productosSinLactosa(props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                        const miListaBusqeda = response.data.Resultados;
                        if (idUltimaBusqueda === response.data.IdBusqueda)
                            MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                    });
                break;
            case "BDopantesMUH":
                    BusqMedsService.medicamentosDopantes(props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                        const miListaBusqeda = response.data.Resultados;
                        if (idUltimaBusqueda === response.data.IdBusqueda)
                            MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                    });
                break;
            case "BNoLentes":
                    BusqMedsService.productosNoLentes(props.infoPagina.idxOrden).then((response) => {
                        const miListaBusqeda = response.data.Resultados;
                        MuestraResultados(miListaBusqeda, response.data.Titulo);
                    });
                break;
            case "BComparaPrecioMenorMUH":
                    BusqMedsService.medicamentosConPrecioSupMenor(props.infoPagina.idxOrden).then((response) => {
                        const miListaBusqeda = response.data.Resultados;
                        MuestraResultados(miListaBusqeda, response.data.Titulo);
                    });
                break;
            case "BComparaPrecioMasBajoMUH":
                    BusqMedsService.medicamentosConPrecioSupMasBajo(props.infoPagina.idxOrden).then((response) => {
                        const miListaBusqeda = response.data.Resultados;
                        MuestraResultados(miListaBusqeda, response.data.Titulo);
                    });
                break;
            case "BPrecioNotificadoMUH":
                    BusqMedsService.medicamentosConPrecioNotificado(props.infoPagina.idxOrden).then((response) => {
                        const miListaBusqeda = response.data.Resultados;
                        MuestraResultados(miListaBusqeda, response.data.Titulo);
                    });
                break;
            case "BSeguimientoAdicionalMUH":
                    BusqMedsService.medicamentosSeguimientoAdicional(props.infoPagina.idxOrden).then((response) => {
                        const miListaBusqeda = response.data.Resultados;
                        MuestraResultados(miListaBusqeda, response.data.Titulo);
                    });
                break;
            case "BDispositivoSeguridadMUH":
                    BusqMedsService.medicamentosDispositivoSeguridad(props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                        const miListaBusqeda = response.data.Resultados;
                        if (idUltimaBusqueda === response.data.IdBusqueda)
                            MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                    });
                break;
            case "BLibroRecetarioM":
                    BusqMedsService.medicamentosLibroRecetario(props.infoPagina.idxOrden).then((response) => {
                        const miListaBusqeda = response.data.Resultados;
                        MuestraResultados(miListaBusqeda, response.data.Titulo);
                    });
                break;
            case "BLibroEstupefacientesM":
                    BusqMedsService.medicamentosLibroEstupefacientes(props.infoPagina.idxOrden).then((response) => {
                        const miListaBusqeda = response.data.Resultados;
                        MuestraResultados(miListaBusqeda, response.data.Titulo);
                    });
                break;
            case "BNotificacionESVACMUV":
                    BusqMedsService.medicamentosNotificacionESVAC(props.infoPagina.idxOrden).then((response) => {
                        const miListaBusqeda = response.data.Resultados;
                        MuestraResultados(miListaBusqeda, response.data.Titulo);
                    });
                break;
            case "BEnfermedadesPATH":
                    BusqMedsService.enfermedadesH(props.infoPagina.idxOrden).then((response) => {
                        const miListaBusqeda = response.data.Resultados;
                        MuestraResultados(miListaBusqeda, response.data.Titulo);
                    });
                break;
            case "BEnfermedadesPATV":
                    BusqMedsService.enfermedadesV(props.infoPagina.idxOrden).then((response) => {
                        const miListaBusqeda = response.data.Resultados;
                        MuestraResultados(miListaBusqeda, response.data.Titulo);
                    });
                break;
            case "BDispositivoSeguridadMUH":
                    BusqMedsService.medicamentosDeduccionesRDL(props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                        const miListaBusqeda = response.data.Resultados;
                        if (idUltimaBusqueda === response.data.IdBusqueda)
                            MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                    });
                break;
            case "BNoSustituiblesMUH":
                    BusqMedsService.medicamentosNoSustituibles(props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                        const miListaBusqeda = response.data.Resultados;
                        if (idUltimaBusqueda === response.data.IdBusqueda)
                            MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                    });
                break;
            case "BAlertadosCismedMUH":
                BusqMedsService.medicamentosAlertaCismed(props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo, false, response.data.Total, 0);
                });
            break;
        case "BDeduccionesRDLMUH":
                    BusqMedsService.medicamentosDeduccionesRDL(props.infoPagina.idxOrden,numMax,idBusqueda).then((response) => {
                        const miListaBusqeda = response.data.Resultados;
                        if (idUltimaBusqueda === response.data.IdBusqueda)
                            MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                    });
                break;

            case "BCambioNombre":
                    BusqMedsService.productosCambioNombre(props.infoPagina,numMax,idBusqueda).then((response) => {
                        const miListaBusqeda = response.data.Resultados;
                        if (idUltimaBusqueda === response.data.IdBusqueda)
                            MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                    });
                break;

            case "BCambioLaboratorio":
                BusqMedsService.productosCambioLaboratorio(props.infoPagina,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
            break;

            case "BCambioPrecioMUH":
                    BusqMedsService.medicamentosCambioPrecio(props.infoPagina,numMax,idBusqueda).then((response) => {
                        const miListaBusqeda = response.data.Resultados;
                        if (idUltimaBusqueda === response.data.IdBusqueda)
                            MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                    });
                break;
            case "BDesfinanciadosMUH":
                    BusqMedsService.medicamentosDesfinanciados(props.infoPagina,numMax,idBusqueda).then((response) => {
                        const miListaBusqeda = response.data.Resultados;
                        if (idUltimaBusqueda === response.data.IdBusqueda)
                            MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                    });
                break;
            case "BFechaAlta":
                BusqMedsService.productosFechaAlta(props.infoPagina,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
            break;
            case "BNovedadesUltimoMes":
                BusqMedsService.productosUltimoMes(props.infoPagina,numMax,idBusqueda).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    if (idUltimaBusqueda === response.data.IdBusqueda)
                        MuestraResultados(miListaBusqeda, response.data.Titulo, !response.data.Completo, response.data.Total, response.data.IdBusqueda);
                });
            break;
            
            case "BMedicamentosPSUltimoMes":
                BusqMedsService.medicamentosPSUltimoMes(props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
            break;
            case "BCambioPrecioPrevistoMUH":
                BusqMedsService.medicamentosCambioPrecioPrevisto(props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
            break;
            case "BHistorialUsuario":
                BusqMedsService.productosHistorialUsuario(props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
            break;
            case "BDocumentos":
                BusqMedsService.documentosPorMedFecha(props.infoPagina).then((response) => {
                    const miListaBusqeda = response.data;
                    MuestraResultados(miListaBusqeda, "Notas de seguridad y alertas de calidad");
                });
            break;
            case "BMismoNombreMUH":
                BusqMedsService.medicamentosMismoNombre(props.infoPagina.id, props.infoPagina.idxOrden).then((response) => {
                    const miListaBusqeda = response.data.Resultados;
                    MuestraResultados(miListaBusqeda, response.data.Titulo);
                });
            break;


            default:
            break;
        }
    };

    var conPreciosMenores = (props.infoPagina.codigoPagina==="BAgrupHomoMUH"
                            || props.infoPagina.codigoPagina ==="BAgrupHomoAutoMUH"
                            || props.infoPagina.codigoPagina==="BAgrupHomoPPF"
                            || props.infoPagina.codigoPagina ==="BAgrupHomoAutoPPF");

    const hayFiltros = (GetFiltrosStr(filtrosYlista.filtros)!=="" ? true : false)

    if (filtrosYlista.listaFiltrada != null && props.infoPagina.listaRes != null)
    {
        if (props.infoPagina.listaRes.length >0){
            return (
                (verCamposExporta && infoExportacion) ? (
                    (PerfilUsuario() !=="COL") ?
                    <CamposExportacion 
                        codTipoExporta = {verCamposExporta}
                        listasResultados = {infoExportacion}
                        codBusqueda = {props.infoPagina.codigoPagina}
                        alSalir = {mostrarCamposExporta}
                        aux1 = {(props.infoPagina.codigoPagina==="BCambioPrecioMUH" && props.infoPagina.inicio) ? Math.round(props.infoPagina.inicio.getTime()/1000) : null}
                        aux2 = {(props.infoPagina.codigoPagina==="BCambioPrecioMUH" && props.infoPagina.fin) ? Math.round(props.infoPagina.fin.getTime()/1000) : null}
                    />
                    :
                    <AccesoRestringido atras = {mostrarCamposExporta}></AccesoRestringido>
                )
                :(
                    <div class="container" id="divContainerRes">
                        <FiltrosBusqueda 
                            filtros = {filtrosYlista.filtros} 
                            cambiaCheck = {cambioFiltroCheck} 
                            cambiaLista = {cambioFiltroList}
                            pvpMenor = {(conPreciosMenores) ? props.infoPagina.pvpMenor: null}
                            pvpMasBajo = {(conPreciosMenores) ? props.infoPagina.pvpMasBajo: null}
                            literalPMenor = {(conPreciosMenores) ? props.infoPagina.literalPMenor: null}
                            literalPMasBajo = {(conPreciosMenores) ? props.infoPagina.literalPMasBajo: null}
                            conPreciosMenores = {conPreciosMenores}
                        >
                        </FiltrosBusqueda>
                        <div class="content" >
                            <div class="content__results__historial" >
                                {hayFiltros ?
                                <ResumenFiltros 
                                    filtros = {filtrosYlista.filtros} 
                                    borraFiltro = {borraFiltro}
                                    borraTodosFiltros = {borraTodosFiltros}
                                >    
                                </ResumenFiltros>
                                :
                                <></>
                                }
                                <ListaResBusqueda filtrosYlista = {filtrosYlista} 
                                                    cambiaPagina={props.cambiaPagina} 
                                                    setOrden={cambioOrdenList} 
                                                    mostrarPI = {props.infoPagina.codigoPagina==="BPacienteIndicacionMUH" || props.infoPagina.codigoPagina === "BProductosIndicacionPPF"}
                                                    mostrarFM = {props.infoPagina.codigoPagina==="BFormMagistralMUH"}
                                                    mostrarEXT = {props.infoPagina.codigoPagina==="BExtranjerosEXT"}
                                                    mostrarAuxStr = {props.infoPagina.codigoPagina === "BNoSustituiblesMUH" 
                                                                    || props.infoPagina.codigoPagina === "BDeduccionesRDLMUH"}
                                                    mostrarHist = {props.infoPagina.codigoPagina === "BCambioLaboratorio" 
                                                                    || props.infoPagina.codigoPagina === "BCambioPrecioMUH"}
                                                    mostrarAuxFecha = {props.infoPagina.codigoPagina === "BDesfinanciadosMUH"}
                                                    mostrarFechaAlta = {props.infoPagina.codigoPagina === "BFechaAlta"
                                                                    || props.infoPagina.codigoPagina === "BNovedadesUltimoMes"}
                                                    mostrarFuturo = {props.infoPagina.codigoPagina === "BCambioPrecioPrevistoMUH"}
                                                    mostrarFVisita = {props.infoPagina.codigoPagina === "BHistorialUsuario"}
                                                    mostrarBotonBorrar = {props.infoPagina.codigoPagina === "BHistorialUsuario"}
                                                    mostrarDocumentos = {props.infoPagina.codigoPagina === "BDocumentos"}
                                                    mostrarListaAuxStr = {props.infoPagina.codigoPagina === "BReaccionesAdversasMUH"}
                                                    mostrarAvisoPrecio = {props.infoPagina.codigoPagina === "BAgrupHomoAutoMUH" || props.infoPagina.codigoPagina === "BAgrupHomoMUH"}
                                                    mostrarFechaEntrada = {props.infoPagina.codigoPagina === "BCambioPrecioPrevistoMUH"}
                                                    mostrarLimpiaHistorial = {props.infoPagina.codigoPagina === "BHistorialUsuario"}
                                                    codigoPagina = {props.infoPagina.codigoPagina}
                                                    pvpMenor   = {props.infoPagina.pvpMenor}
                                                    pvpMasBajo = {props.infoPagina.pvpMasBajo}
                                                    alExportar = {mostrarCamposExporta}
                                                    alVaciar = {vaciarHistorial}
                                                    borraDeLista = {props.borraDeLista}
                                ></ListaResBusqueda>
                            </div>
                        </div>
                    </div>
                )
            )
        }
        else{
            return(
                <div align="center">
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p> No se han encontrado resultados</p>
                </div>
            );
        }
    }    
    else{
            return(
                <LoadingPopup></LoadingPopup>      
            );
    }
}
export default FiltrosYResultados;

