import React, { useState, useEffect, createRef } from "react";
import CabeceraResultados from "../../Resultados/CabeceraResultados/CabeceraResultados";
import BP2022Service from "../../../services/user.service";
import InfoPrincipalMUH from "../FichaMUH/InfoPrincipal/InfoPrincipalMUH.js";
import MenuIzquierda from "../FichaMUH/MenuIzquierda/MenuIzquierda";
import ContenedorApartados from "../FichaMUH/Apartados/ContenedorApartados";
import MensajesEmergentes from "../FichaMUH/MensajesEmergentes";
import { AnadeApartado, baseURLPictogramas, compruebaAnclas, RemoveClassNameToClassItems } from "../../../services/utils";
import LoadingPopup from "../../Resultados/Loading.Popup";
import MargenDerechoPAT from "./MargenDerechoPAT";

const FichaPAT = (props) => {
    const id = props.infoPagina.id;
    //const tipo = props.tipo;
    const [ficha, setFicha] = useState(null);
    const [anclas, setAnclas] = useState([]);
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        if ((ficha==null) || (ficha.Id !== id)){
            window.scroll({ top: 0, behavior: 'smooth' })
            descargaFicha();
        }        
        else if (refresh==1){
            descargaJerarquiaInfo(ficha.Id)
        }
        else if (refresh==2){
            descargaSinonimias(ficha.Id)
        }
        else if (refresh==3){
            /*if (ficha.TipoPat==="H")
                descargaInterferencias(ficha.Id);
            else*/
            descargaCieInfo(ficha.Id);
            //setRefresh(prev => prev+1);
        }
        else if (refresh==4){
            descargaInformacionAdicional(ficha.Id)
        }
        else if (refresh==5){
            descargaVideos(ficha.Id)
        }
        else if (refresh == 6)
        {            
            //setRefresh(prev => prev + 1);
        }
    })
    const descargaFicha = () => {
        BP2022Service.getFichaPAT(id)
        .then((response) => {
            RemoveClassNameToClassItems('lightbox__popup',null,'active');
            //checkAnclas(response.data.Apartados);
            var auxFicha = response.data;
            compruebaAnclas(auxFicha.Apartados,anclas);
            auxFicha.CodTipoRegistro="PAT" + auxFicha.TipoPat;
            if (auxFicha.TipoPat === "H")
                auxFicha.TipoRegistro="Enfermedad humana";
            else
                auxFicha.TipoRegistro="Enfermedad veterinaria";
            setFicha(auxFicha);
            setRefresh(1);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
    
    const descargaJerarquiaInfo = (miId) => {
        BP2022Service.getJerarquiaInfoPAT (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                    if (response.data.NivelSuperior.length + response.data.NivelInferior.length>0){
                        AnadeApartado({Titulo: "Jerarquía enfermedades",  Ancla:"JENF"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosJerarquia = response.data;
                    }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
    
    const descargaCieInfo = (miId) => {
        BP2022Service.getCIEInfoPAT(miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                    if (response.data.length >0){
                        AnadeApartado({Titulo: "CIE 10",  Ancla:"CIE10"},miFicha.Apartados,anclas);
                        miFicha.DatosCIE = response.data;
                    }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
    
    const descargaInterferencias = (miId) => {
        BP2022Service.getInterferenciasPAT (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Análisis clínicos",  Ancla:"ANA"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.Interferencias = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
 

    const descargaInformacionAdicional = (miId) => {
        BP2022Service.getInformacionAdicionalPAT (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Información adicional",  Ancla:"DOCS"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.DatosInfoAdicional = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaVideos = (miId) => {
        BP2022Service.getVideosPAT (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Documentos){
                    if (response.data.Documentos.length>0){
                        AnadeApartado({Titulo: "Vídeos",  Ancla:"VID"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosVideos = response.data;
                    }
                }
                props.descargaHistorial();
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaSinonimias = (miId) => {
        BP2022Service.getSinonimiasPAT (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Sinonimias",  Ancla:"SINO"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.Sinonimias = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    /*const checkAnclas = (aptdos) => {
        for (var i=0; i<aptdos.length; i++){
            const idAncla = aptdos[i].Ancla;
            if (!anclas.find(ancla => ancla.idAncla===idAncla))
            {
                const anclaNueva = createRef();
                anclas.push({idAncla: idAncla, refAncla : anclaNueva});
            }
        }
    }*/

     
    if (ficha !== null) 
    {
        return (
            <div>
                <CabeceraResultados infoPagina={props.infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras}></CabeceraResultados>
                <InfoPrincipalMUH ficha={ficha} cambiaPagina={props.cambiaPagina} ></InfoPrincipalMUH>
                {
                    (ficha.MensajesEmergentes && ficha.MensajesEmergentes.length>0) ?
                        <MensajesEmergentes mensajes={ficha.MensajesEmergentes} id={ficha.Id}></MensajesEmergentes>
                    :
                        <div></div>
                }
                <section class="medicamento-infoGeneral">
                    <div class="container flex flex--justify-center">
                        <div class="contenido f-f-Flama-Book">
                            <MenuIzquierda apartados={ficha.Apartados} activo={ficha.Apartados[0].Ancla} anclas={anclas}></MenuIzquierda>

                            <ContenedorApartados cambiaPagina={props.cambiaPagina} ficha={ficha} anclas={anclas}></ContenedorApartados> 
                            <MargenDerechoPAT cambiaPagina={props.cambiaPagina} ficha={ficha} ></MargenDerechoPAT>

                        </div>
                    </div>
                </section>
            </div>
        )
    }
    else 
        return (
            <div>
                <LoadingPopup></LoadingPopup>
            </div>
        )
}
export default FichaPAT;
