import CismedKO from "./CismedKO";
import CismedOK from "./CismedOK";

const Cismed = (props) => {
    const datosCismed = props.datosCismed;
    if (datosCismed.EstaAutorizado)
        return (
            <CismedOK datosCismed={datosCismed} ></CismedOK>
        )
    else
        return (
            <CismedKO datosCismed={datosCismed} ></CismedKO>
        )
}
export default Cismed;

