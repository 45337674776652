import { validaAccesoPorRol } from '../../../services/objsAplicacion.js';
import {AddClassNameToClassItems
    , GetAntecesorWhithClassName
    , GetIndexFromIndexedClass
    , RemoveClassNameToClassItems} from '../../../services/utils.js'


const ItemMenuSecundarioMorado = (props) => {

    const HandleHover_OpcionMenunMoradaNivel2 = (e, hayclick) =>{
        if ((document.documentElement.clientWidth > 959) || hayclick)
        {
            const numPadres = 5;
            const numItems = 14;
            const itemMenuPadre = GetAntecesorWhithClassName(e.target, 'child__menu1');
            if (itemMenuPadre != null){
                const idxMenuPadre = GetIndexFromIndexedClass(itemMenuPadre,'child__',numPadres);
                const itemMenu = GetAntecesorWhithClassName(e.target,'menu__2_item');
                const idx = GetIndexFromIndexedClass(itemMenu,'menu__2_item__',numItems);
                const namechild = "child__" + idxMenuPadre.toString() + "__" + idx.toString();
                var i;
                for (i = 1; i<=numItems; i++)
                {
                    var namechildtmp = "child__" + idxMenuPadre.toString() + "__" + i.toString();
                    if (namechild != namechildtmp)
                        RemoveClassNameToClassItems(namechildtmp,null,'active');
                    else
                        AddClassNameToClassItems(namechildtmp,null, 'active');
                }
                AddClassNameToClassItems('nenuChilds',null, 'active');
                AddClassNameToClassItems('coverToggleMenu','historial', 'active');
            }
        }
    }
 
    const HandleClick_OpcionMenunMoradaNivel2 = (e) =>{
        HandleHover_OpcionMenunMoradaNivel2(e,true);
        const numPadres = 5;
        const itemMenuPadre = GetAntecesorWhithClassName(e.target, 'child__menu1');
        const idxMenuPadre = GetIndexFromIndexedClass(itemMenuPadre,'child__',numPadres);
        const nameParent = "child__" + idxMenuPadre.toString();
        var i;
        for (i = 1; i<=numPadres; i++)
        {
            var nameparenttmp = "child__" + idxMenuPadre.toString();
            if (nameParent != nameparenttmp)
                RemoveClassNameToClassItems(nameparenttmp,null,'active2');
            else
                AddClassNameToClassItems(nameparenttmp,null, 'active2');
        }
        RemoveClassNameToClassItems('backItem__1',null, 'active');
        AddClassNameToClassItems('backItem__2',null, 'active');
    }

    const CambiaPaginaSimple = (codigoPagina) => {
        props.cambiaPagina({
            newpagina: codigoPagina
        });
    }

    const tieneHijos = (props.item.subapartados != null ? true : false);
    const clasesItemA = "menu__2_item menu__2_item__" + props.idx.toString();
    const miCursor = (tieneHijos ? 'default' : 'pointer');

    const habilitado = (props.item.subapartados ? (props.item.subapartados.find(subitem => validaAccesoPorRol(subitem.pagina,props.perfil))) ? true : false : validaAccesoPorRol(props.item.pagina,props.perfil));


    //const estilo = (props.perfil === "COL") ? {cursor:'pointer', color:'#B9B9B9'} : {cursor:'pointer'}
    const estilo = (!habilitado) ? {cursor:'pointer', color:'#B9B9B9'} : {cursor:'pointer'}


    if (tieneHijos){        
        return (
            <li className="hasChild">
                <a className={clasesItemA} style={estilo} 
                        onClick={(e) => HandleClick_OpcionMenunMoradaNivel2(e)} 
                        onMouseOver={(e) => HandleHover_OpcionMenunMoradaNivel2(e,false)}                   
                        title={props.item.titulo} >{props.item.titulo}
                    <svg width="8px" height="13px" viewBox="0 0 8 13" >
                        <defs>
                            <path d="M6.38999457,2.72756493 L6.38999457,4.31855519 L-0.307623382,4.31756493 L-0.307299791,10.6825162 L-1.98162338,10.6825162 L-1.98162338,2.72756493 L6.38999457,2.72756493 Z" id="path-1"></path>
                        </defs>
                        <g id="Home" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <mask id="mask-2" >
                                <use xlinkHref="#path-1"></use>
                            </mask>
                            <use id="Combined-Shape" fill-rule="nonzero" transform="translate(2.204186, 6.705041) rotate(135.000000) translate(-2.204186, -6.705041) " xlinkHref="#path-1"></use>
                        </g>
                    </svg>
                </a>
            </li>
        )
    }
    else {
        return (
            <li class="noChild" >
                <a className={clasesItemA} style={estilo}  
                        onClick={(e) => CambiaPaginaSimple(props.item.pagina)} 
                        onMouseOver={(e) => HandleHover_OpcionMenunMoradaNivel2(e,false)}                   
                        title={props.item.titulo} >{props.item.titulo}</a>
            </li>
        )
    }
}
export default ItemMenuSecundarioMorado;
