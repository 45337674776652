import { useEffect, useState } from "react";
import SelectorTipo2 from "../../Utilidades/Selector/SelectorTipo2";
import { GetAntecesorWhithClassName, GetSucesorWhithClassName, RemoveClassNameToChildClassItems, RemoveClassNameToDoubleClassItems } from "../../../services/utils";
import SelectorArbol from "../../Utilidades/Selector/SelectorArbol/SelectorArbol";
import LoadingPopup from "../../Resultados/Loading.Popup";
import SelectorArbol2 from "../../Utilidades/Selector/SelectorArbol2/SelectorArbol2";
import SelectorArbol3 from "../../Utilidades/Selector/SelectorArbol3/SelectorArbol3";

const FBusquedaArbol = (props) => {
    //const [lista,setLista] = useState([]);
    const [datosFormulario, setDatosFormulario] = useState(props.datosFormulario);
    const [arbol, setArbol] = useState([]);
    const [refresh, setRefresh] = useState(1);
    const [txt, setTxt] = useState('');

    var seleccionado = datosFormulario.seleccionado;

    const clasedivmodulefilters = (datosFormulario.ancho) ? "cover cover__filters cover__filters__2 cover__filters__2--1 ancho" : "cover cover__filters cover__filters__2 cover__filters__2--1";

    var busqueda = datosFormulario.busqueda;

    const cambiaSeleccion = (cod, texto) => {
        const nuevaSeleccion = JSON.parse(JSON.stringify(datosFormulario));
        nuevaSeleccion.seleccionado = cod;
        setDatosFormulario(nuevaSeleccion);
    }

    useEffect(() => {
        if ((arbol.length === 0) 
            || props.datosFormulario.tipo !== datosFormulario.tipo 
            || props.datosFormulario.tipoForm !== datosFormulario.tipoForm 
            || props.datosFormulario.aux !== datosFormulario.aux)
        {
            DescargaLista(props.datosFormulario.tipo, props.datosFormulario.aux);
        }        
    },[props.datosFormulario]);

    const DescargaLista = (tipo, aux) => {      
        props.datosFormulario.descarga(tipo, aux, datosFormulario.seleccionado, 20)
        .then((response) => {
            if (props.datosFormulario.tipoForm === "FBGTVMPPMUH"){
                props.datosFormulario.descarga(tipo, aux, '', 0)
                .then((response) => {
                    var newlista = response.data;
                    setArbol(newlista);
                })
            }
            setDatosFormulario(props.datosFormulario);
            var newlista = response.data;
            setArbol(newlista);
            //ConstruyeArbol(newlista);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const cambiaTxt = (newtxt) =>{
        setTxt(newtxt);
    }

    const hayBusqueda= datosFormulario.seleccionado != '';

    const clickBuscar = (e) =>{
        if (hayBusqueda){
            props.cambiaPagina({
                newpagina: busqueda,
                newid: datosFormulario.seleccionado,
            })
        }
    }

    const setCheckedFalse = (r) =>{
        if (r){
            for (var i=0; i<r.length; i++){
                r[i].checked = false;
                setCheckedFalse(r[i].hijos);
            }
        }
    }

    const BorraBusqueda = (e) =>{
        setTxt('');
        cambiaSeleccion('','');
    }

    const KeyDownForm = (e) =>{
        var keyCode = e.keyCode;
        if (keyCode === 13){
            clickBuscar(e);
        }
    }

    const estiloBotonesSup = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'space-between'} : {height:'76px', width:'100%', justifyContent: 'space-between', visibility:'hidden'})
    const estiloBotonesInf = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'center'} : {height:'76px', width:'100%', justifyContent: 'center', display:'none'})



    return (
        <div class="content" >

            { ((arbol.length === 0) 
            || props.datosFormulario.tipo !== datosFormulario.tipo 
            || props.datosFormulario.tipoForm !== datosFormulario.tipoForm 
            || props.datosFormulario.aux !== datosFormulario.aux) ?
                <LoadingPopup titulo="Un momento" texto="Preparando formulario" esPorcentaje={true}></LoadingPopup>
            :
                <form class="content__results content__results__filters formulario__busqueda" id="main-filter"  onSubmit={(e) => e.preventDefault()}  onKeyDown={(e) => KeyDownForm(e)} tabIndex="0">
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesSup}>
                        <span class="button button__arrow button__green noUppercase reset-form" data-form="#main-filter" title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} style={{visibility:'hidden'}} >
                            Vaciar búsqueda
                        </span>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                        <span class="button button__arrow button__green reset-form" data-form="#main-filter" style={{textTransform:'none', fontSize: '14px', width:'146px'}} title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} >
                            Vaciar búsqueda
                        </span>
                    </div>
                    <div class="cover cover__filters cover__filters__full cover__filters__accordion cover__filters__accordion__checks" style={{marginTop:'20px'}} >
                        {(datosFormulario.tipoForm === "FBGTVMPPMUH")?
                            <h3 class="title fs fs16 greyColor2" style={{marginBottom:'20px'}} >
                                {datosFormulario.tituloSelect}:
                            </h3>
                        :
                            <></>
                        }
                        {/*<SelectorArbol arbol = {arbol} id={datosFormulario.idSelect } valueSelected={seleccionado} ocultaCodigo={props.datosFormulario.ocultaCodigo} 
                        placeholder={props.datosFormulario.placeholder} refresh={refresh}></SelectorArbol>*/}
                        {/*<SelectorArbol2 arbol= {arbol} id={datosFormulario.idSelect } 
                            valueSelected={seleccionado} 
                            alCambiar={cambiaSeleccion}
                            codigoJerarquico = {datosFormulario.tipoForm==="FBGTATCMUH" || datosFormulario.tipoForm==="FBGTATCMUV" || datosFormulario.tipoForm==="FBGTConPPF" || datosFormulario.tipoForm==="FBGTSanPPF"}
                            muestraCodigo = {!datosFormulario.ocultaCodigo}
                        />*/}
                        <SelectorArbol3 
                            listaCompleta= {arbol} 
                            valorSeleccionado={seleccionado} 
                            alCambiar={cambiaSeleccion}
                            codigoJerarquico = {datosFormulario.tipoForm==="FBGTATCMUH" || datosFormulario.tipoForm==="FBGTATCMUV" || datosFormulario.tipoForm==="FBGTConPPF" || datosFormulario.tipoForm==="FBGTSanPPF"}
                            muestraCodigo = {!datosFormulario.ocultaCodigo}
                            placeholder = {datosFormulario.placeholder}
                            texto = {txt}
                            cambiaTxt = {cambiaTxt}
                        />

                    </div>
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesInf}>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                    </div>
                </form>
            }
        </div>
    );
};

export default FBusquedaArbol;
