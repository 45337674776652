import { validaAccesoPorRol } from "../../../services/objsAplicacion.js";
import SubapartadoSeccionMenuBlanco from "./SubapartadoSeccionMenuBlanco.js";

const ApartadoMultipleSeccionMenuBlanco = (props) => {
    const habilitado = (props.item.subapartados ? props.item.subapartados.find(subitem => validaAccesoPorRol(subitem.pagina,props.perfil)) : false);
    const subapartados =  props.item.subapartados ? props.item.subapartados.map(item => 
                                <SubapartadoSeccionMenuBlanco cambiaPagina={props.cambiaPagina} item={item} perfil = {props.perfil}></SubapartadoSeccionMenuBlanco>) 
                            :   null;

    //const estilo = (props.perfil === "COL") ? {cursor:'default', color:'#999999'} : {cursor:'default'}
    const estilo = (!habilitado) ? {cursor:'pointer', color:'#999999'} : {cursor:'pointer'}

    return (
        <div class="grid__item">
            <div class="title">
                <span title={props.item.titulo} style={estilo} >{props.item.titulo}</span>
            </div>
            <ul class="list" >
                {subapartados}
            </ul>
        </div>
    )    
}
export default ApartadoMultipleSeccionMenuBlanco;
