import { useEffect } from 'react/cjs/react.development';
import { IconosListadosPI } from '../../../../services/objsAplicacion.js';
import {FormatoFechaBonito, numberWithCommas, rootPath} from '../../../../services/utils.js'

function FilaListaDOC(props){
    const item = props.item;
    const pictogramatipo =  rootPath + "img/alertas.svg" ;

    const ancho = window.innerWidth;
    const isMobile = (ancho<768);

    const ano = (item.Fecha) ? new Date(item.Fecha).getFullYear() : 0; //( item.Fecha ? item.Fecha.substring(0,4) : '');
    const fecha = ( ano>1900 ? FormatoFechaBonito (new Date(item.Fecha)) : '');
    /*const tipoDoc = (item.IdTipo===15 ? "Nota de seguridad": 
                        (item.IdTipo===30 ? "Alerta de calidad" : 
                            (item.IdTipo===41 ? "Nota de seguridad veterinaria" : item.Tipo)));*/
    const tipoDoc = (item.IdTipo===15 ? "Nota seguridad AEMPS": 
                    (item.IdTipo===30 ? "Alerta de calidad" : 
                    (item.IdTipo===41 ? "Nota de seguridad veterinaria" : 
                    (item.IdTipo===44 ? "Nota informativa AEMPS" : 
                    (item.IdTipo===48 ? "Nota informativa AESAN" : 
                    (item.IdTipo===64 ? "Nota informativa AEMPS" : 
                    (item.Tipo && item.Tipo !== "" ? item.Tipo.substring(0,1).toUpperCase() + item.Tipo.substring(1).toLowerCase()  :
                    "Otro"
                    )))))))

    var titulo = (item.Titulo.toLowerCase().endsWith(".pdf") ? item.Titulo.substring(0,item.Titulo.length - 4) : item.Titulo) 
    
    if (titulo.substring(0,17).toUpperCase()==="NOTA INFORMATIVA ")
        titulo = titulo.substring(17);
    else if (titulo.substring(0,15).toUpperCase()==="NOTA SEGURIDAD ")
        titulo = titulo.substring(15);
    else if (titulo.substring(0,14).toUpperCase()==="NOTA SEGURIDA ")
        titulo = titulo.substring(14);
    else if (titulo.substring(0,5).toUpperCase()==="NOTA ")
        titulo = titulo.substring(5);
    else if (titulo.substring(0,3).toUpperCase()==="NI ")
        titulo = titulo.substring(3);
    else if (titulo.substring(0,15).toUpperCase()==="ALERTA CALIDAD ")
        titulo = titulo.substring(15);
    else if (titulo.substring(0,23).toUpperCase()==="ALERTA DE MEDICAMENTOS ")
        titulo = titulo.substring(23);
    else if (titulo.substring(0,21).toUpperCase()==="ALERTA DE VIGILANCIA ")
        titulo = titulo.substring(21);
    else if (titulo.substring(0,7).toUpperCase()==="ALERTA ")
        titulo = titulo.substring(7);
    else if (titulo.substring(0,18).toUpperCase()==="AMPLIACION ALERTA ")
        titulo = "Ampliación alerta " + titulo.substring(18);
    else if (titulo.substring(0,11).toUpperCase()==="AMPLIACION ")
        titulo = "Ampliación " + titulo.substring(11);

    titulo = titulo.trim();

    if (titulo !== "")
        titulo = titulo.substring(0,1).toUpperCase() + titulo.substring(1);



    return (
        <div class="item " > 
            <div class="product" style={{width:'100%'}}>
                <div class="mask" >
                    <img src={pictogramatipo} alt="Documento" />
                </div>
                <a href={item.URL} target="_blank" title={titulo} >
                <div class="box box__text" style={{width:'100%'}}>
                    <span className="fs fs12 greenColor date" >
                        {fecha}
                    </span>
                    <div class="title" >
                        <h3 class="fs fs17 blackColor" >
                            <span title={titulo} >{titulo}</span>
                        </h3>
                    </div>
                    <div class="info" style={{borderBottom: 'none'}}>
                        <p class="fs fs15 purpleColor tag" >{tipoDoc}</p>
                    </div>
                </div>
                </a>
            </div>
        </div> 
    )    
}

export default FilaListaDOC;

/*
        <li>
            <a href={props.item.URL} target="_blank" title={props.item.Titulo} >
                <span className="fs fs12 greenColor date" >
                    {fecha}
                </span>
                <p class="fs fs15 FlamaBook greyColor2" />
                <h3 class="fs fs24 blackColor title" >
                    {titulo}
                </h3>
                <p class="fs fs15 FlamaBook greyColor2" >
                    {tipoDoc}
                </p>
            </a>
        </li>

*/