import React, { createRef } from "react";
import AuthService from "./auth.service";

const arrayComun = ['primera', 'segunda'];
export const variableComun = 1;
export const rootPath = '/';
export const apiProdPath = "/api/botplusapi/";
export const apiDesaPath = 'http://localhost:36823/botplusapi/';
export const baseURLPictogramas = "https://botplusweb.farmaceuticos.com/pictogramas/";


export function AnadeApartado(registro, lista, anclas){
    if (!lista.find(ap => ap.Ancla === registro.Ancla)){
        lista.push(registro);
        compruebaAnclas(lista,anclas);
    }
}

export function compruebaAnclas (aptdos, anclas) {
    for (var i=0; i<aptdos.length; i++){
        const idAncla = aptdos[i].Ancla;
        if (!anclas.find(ancla => ancla.idAncla===idAncla))
        {
            const anclaNueva = createRef();
            anclas.push({idAncla: idAncla, refAncla : anclaNueva});
        }
    }
}

export function anadeAncla (idAncla, anclas) {
    if (!anclas.find(ancla => ancla.idAncla===idAncla))
    {
        const anclaNueva = createRef();
        anclas.push({idAncla: idAncla, refAncla : anclaNueva});
    }
}


export function getApiPath() {
    const miHost = document.location.hostname;
    var res = ((document.location.hostname === '127.0.0.1' || document.location.hostname === 'localhost') ?
        apiDesaPath
    :
        apiProdPath
    );
    return res;
}

export const doSomethingWithInput = (theInput) => {
   //Do something with the input
   return theInput;
};

export const justAnAlert = () => {
   alert('hello');
};

export function divideArrayEnPartes(arr, n){
    var i, j;
    var res = [];
    for (i=0, j= arr.length; i < j; i+= n){
        res.push(arr.slice(i,i+n));
    }
    return res;        
}


export function numberWithCommas(x) {
    //x = Math.round(x * 100) / 100;
    //console.log(x);
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    if (parts.length===2){
        while (parts[1].length<2)
            parts[1] = parts[1] + '0';
        if (parts[1].length>2)
            if (parts[1].substring(0,1)=='0')
                if (parts[1].substring(0,2)=='00')
                    if (parseInt(parts[1].substring(2,3))>=5)
                        parts[1] = '01';
                    else
                        parts.splice(1,1);
                else
                    parts[1] = '0' + (Math.round((parseFloat(parts[1]) / (Math.pow(10 , parts[1].length-1)))*10)).toString();
            else{
                //(parts[1]);
                var aux = Math.round((parseFloat(parts[1]) / (Math.pow(10 , parts[1].length)))*100);
                //console.log(aux);
                if (aux<100)
                    parts[1] = aux.toString();
                else{
                    aux = aux - 100;
                    if (aux === 0)
                        parts.pop();
                    else
                        parts[1] = (aux - 100).toString();
                    parts[0] = (parseInt(parts[0]) + 1).toString();
                }

            }
    }
    return parts.join(",");
}

export function numberWithCommas2(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    if (parts.length===2){
        while (parts[1].length<2)
            parts[1] = parts[1] + '0';
        /*if (parts[1].length>2)
            parts[1] = (Math.round((parseFloat(parts[1]) / (Math.pow(10 , parts[1].length)))*(parts[1].length * 10))).toString();*/
    }
    return parts.join(",");
}

export function elementIsOfClass(element, clase) {
    return element.classList.contains(clase);
}

export const FormatoFechaBonito = (fecha) => {
    const meses = [
        "Enero", "Febrero", "Marzo",
        "Abril", "Mayo", "Junio", "Julio",
        "Agosto", "Septiembre", "Octubre",
        "Noviembre", "Diciembre"
    ]

    const mifecha = new Date(fecha);

    const dia = (mifecha ? mifecha.getDate() : null);
    const mes = (mifecha ? mifecha.getMonth() : null);
    const ano = (mifecha ? mifecha.getFullYear() : null);

    const res = ((dia && mes && ano) ? dia.toString() + " " + meses[mes].toUpperCase() + " " + ano.toString() : "");
    return res;
};

function padLeft(n){
    return ("00" + n).slice(-2);
 }

export const upperCaseFirstLetter = (str) => {
    return str.trim().substring(0,1).toUpperCase() + str.trim().substring(1).toLowerCase();
}

export const Redondea = (valor, decimales) => {
    var x = valor * (10 * decimales)
    x = Math.round(x);
    x = x / (10 * decimales);
    return x;
}

export const FormatoFechaDDMMYYYY = (fecha) => {
    const mifecha = new Date(fecha);

    const dia = (mifecha ? mifecha.getDate() : null);
    const mes = (mifecha ? mifecha.getMonth() + 1 : null);
    const ano = (mifecha ? mifecha.getFullYear() : null);
    const res = ((dia && mes && ano) ? padLeft(dia) + "/" + padLeft(mes) + "/" + ano.toString() : "");
    return res;
};

export const FormatoFechaDDMMYYYYHHMM = (fecha) => {
    const mifecha = new Date(fecha);

    const dia = (mifecha ? mifecha.getDate() : null);
    const mes = (mifecha ? mifecha.getMonth() + 1 : null);
    const ano = (mifecha ? mifecha.getFullYear() : null);
    const hora = (mifecha ? mifecha.getHours() : null);
    const minute = (mifecha ? mifecha.getMinutes() : null);
    const res = ((dia && mes && ano && hora && minute) ? padLeft(dia) + "/" + padLeft(mes) + "/" + ano.toString()+ " " + padLeft(hora) + ":" + padLeft(minute) : "");
    return res;
};

export const SetDisplayByClass = (disp, classModelName) =>{
    var elements = document.getElementsByClassName(classModelName);
    for (var i = 0, len = elements.length; i < len; i++) {
        elements[i].style.display = disp;
    }
}

export const GetIndexFromIndexedClass = (item, classModelName, maxIndex) =>{
    var enc = false;
    var i=1;
    while (!enc && i<=maxIndex){
        var name = classModelName + i.toString();
        enc = (item.classList.contains(name));
        if (!enc)
            i++;
    }
    if (enc)
        return i;
    else
        return -1;
}

export const GetAntecesorWhithClassName = (item, classModelName) =>{
    const maxIterations=10
    var enc = false;
    var i=0;
    while (!enc && i<maxIterations && item != null && item != undefined){
        if (item.classList)
            enc = (item.classList.contains(classModelName));
        if (!enc)
        {
            item = item.parentElement;
            i++;
        }
    }
    if (enc)
        return item;
    else
        return null;
}

export const GetSucesorWhithClassName = (item, classModelName) =>{
    var hijos = item.getElementsByClassName(classModelName);
    if ((hijos) && (hijos.length>0))
        return hijos[0];
    else
        return null;
}

export const GetLastSucesorWhithClassName = (item, classModelName) =>{
    var hijos = item.getElementsByClassName(classModelName);
    if ((hijos) && (hijos.length>0))
        return hijos[hijos.length-1];
    else
        return null;
}

export const AddClassNameToClassItems = (miClase, miClaseHija, miClassName) =>{
    var elements = document.getElementsByClassName(miClase);
    if (miClaseHija != null){
        for (var i = 0, len = elements.length; i < len; i++) {
            var element = elements[i];
            var hijos = element.getElementsByClassName(miClaseHija);
            for (var j = 0, len2 = hijos.length; j < len2; j++){
                var hijo = hijos[j];
                hijo.classList.add(miClassName);
            }
        }
    }
    else{
        for (var i = 0, len = elements.length; i < len; i++) {
            var element = elements[i];
            element.classList.add(miClassName);
        }
    }
}

export const RemoveClassNameToClassItems = (miClase, miClaseHija, miClassName) =>{
    var elements = document.getElementsByClassName(miClase);
    if (miClaseHija != null){
        for (var i = 0, len = elements.length; i < len; i++) {
            var element = elements[i];
            var hijos = element.getElementsByClassName(miClaseHija);
            for (var j = 0, len2 = hijos.length; j < len2; j++){
                var hijo = hijos[j];
                hijo.classList.remove(miClassName);
            }
        }
    }
    else{
        for (var i = 0, len = elements.length; i < len; i++) {
            var element = elements[i];
            element.classList.remove(miClassName);
        }
    }
}

export const RemoveClassNameToDoubleClassItems = (miClase, miClase2, miClassName) =>{
    var elements = document.getElementsByClassName(miClase);
    for (var i = 0; i < elements.length; i++) {
        var element = elements[i];
        if (element){
            if (element.classList.contains(miClase2)){
                element.classList.remove(miClassName);
            }
        }
    }
}

export const ToggleClassNameToClassItems = (miClase, miClaseHija, miClassName) =>{
    var elements = document.getElementsByClassName(miClase);
    if (miClaseHija != null){
        for (var i = 0, len = elements.length; i < len; i++) {
            var element = elements[i];
            var hijos = element.getElementsByClassName(miClaseHija);
            for (var j = 0, len2 = hijos.length; j < len2; j++){
                var hijo = hijos[j];
                hijo.classList.toggle(miClassName);
            }
        }
    }
    else{
        for (var i = 0, len = elements.length; i < len; i++) {
            var element = elements[i];
            element.classList.toggle(miClassName);
        }
    }
}

export const AddClassNameToChildClassItems = (padre, miClase, miClaseHija, miClassName) =>{
    var elements = padre.getElementsByClassName(miClase);
    if (miClaseHija != null){
        for (var i = 0, len = elements.length; i < len; i++) {
            var element = elements[i];
            var hijos = element.getElementsByClassName(miClaseHija);
            for (var j = 0, len2 = hijos.length; j < len2; j++){
                var hijo = hijos[j];
                hijo.classList.add(miClassName);
            }
        }
    }
    else{
        for (var i = 0, len = elements.length; i < len; i++) {
            var element = elements[i];
            element.classList.add(miClassName);
        }
    }
}

export const RemoveClassNameToChildClassItems = (padre, miClase, miClaseHija, miClassName) =>{
    var elements = padre.getElementsByClassName(miClase);
    if (miClaseHija != null){
        for (var i = 0, len = elements.length; i < len; i++) {
            var element = elements[i];
            var hijos = element.getElementsByClassName(miClaseHija);
            for (var j = 0, len2 = hijos.length; j < len2; j++){
                var hijo = hijos[j];
                hijo.classList.remove(miClassName);
            }
        }
    }
    else{
        for (var i = 0, len = elements.length; i < len; i++) {
            var element = elements[i];
            element.classList.remove(miClassName);
        }
    }
}

export const ToggleClassNameToChildClassItems = (padre, miClase, miClaseHija, miClassName) =>{
    var elements = padre.getElementsByClassName(miClase);
    if (miClaseHija != null){
        for (var i = 0, len = elements.length; i < len; i++) {
            var element = elements[i];
            var hijos = element.getElementsByClassName(miClaseHija);
            for (var j = 0, len2 = hijos.length; j < len2; j++){
                var hijo = hijos[j];
                hijo.classList.toggle(miClassName);
            }
        }
    }
    else{
        for (var i = 0, len = elements.length; i < len; i++) {
            var element = elements[i];
            element.classList.toggle(miClassName);
        }
    }
}

export const ExistsClassNameChildClassItems = (padre, miClase, miClaseHija, miClassName) =>{
    var elements = padre.getElementsByClassName(miClase);
    var result = false;
    if (miClaseHija != null){
        for (var i = 0, len = elements.length; i < len; i++) {
            var element = elements[i];
            var hijos = element.getElementsByClassName(miClaseHija);
            for (var j = 0, len2 = hijos.length; j < len2; j++){
                var hijo = hijos[j];
                var nietos = hijo.getElementsByClassName(miClassName);
                result = result || ((nietos) && (nietos.length>0));
            }
        }
    }
    else{
        for (var i = 0, len = elements.length; i < len; i++) {
            var element = elements[i];
            var hijos = element.getElementsByClassName(miClassName);
            result = result || ((hijos) && (hijos.length>0));
        }
    }
}



export function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            areObjects && !deepEqual(val1, val2) ||
            !areObjects && val1 !== val2
        ) {
            return false;
        }
    }
    return true;
}

  export function isObject(object) {
    return object != null && typeof object === 'object';
  }

export function getParam(str,param){
    if (str.length>1 && str.length>param.length){
        if (str.substring(0,1)==='?')
            str = str.substring(1);
        var params = str.split('&');
        var enc = false;
        var value = '';
        for (var i = 0; (i<params.length && !enc); i++)
        {
            var duo = params[i].split('=');
            if (duo.length>=2)
                if (duo[0].toLowerCase()===param.toLowerCase()){
                    enc = true;
                    value = duo[1];
                    for (var j=2; j<duo.length; j++)
                        value += "=" + duo[j];
                }
        }        
        return value;    
    }
    else{
        return '';
    }
 }

export function getCurrentURL(){
    var url = window.location.href;
    url = url.substring(window.location.origin.length);
    return url;
}

export function getPathURL(url){
    var aux = url.split('?');
    return aux[0];
}

export function getParamsURL(url){
    var aux = url.split('?');
    if (aux.length>1)
        return aux[1];
    else
        return '';
}

export function wrap(toWrap, wrapper) {
    wrapper = wrapper || document.createElement('div');
    toWrap.parentNode.appendChild(wrapper);
    return wrapper.appendChild(toWrap);
};

export function createElementFromHTML(htmlString) {
    var div = document.createElement('div');
    div.innerHTML = htmlString.trim();
    return div.firstChild;
}

export function buscaOptionEnSelectByValue(select, valor){
    var ret = undefined;
    var options = select.options ;
    for(var i = 0; i < options.length; i++)
    {
        if(options[i].value == valor)
        {
            ret = options[i];
            break;
        }
    }
    return ret;
}

export function primerHijoConClase(elemento, clase)
{
    var posibles = elemento.getElementsByClassName(clase);
    var res = (posibles.length>0) ? posibles[0] : null;
    return res;
}

export function getSelectValues(select) {
    var result = [];
    var options = select && select.options;
    var opt;
  
    for (var i=0, iLen=options.length; i<iLen; i++) {
      opt = options[i];
  
      if (opt.selected) {
        result.push(opt.value || opt.text);
      }
    }
    return result;
}

export function containsNumber(str) {
    return /\d/.test(str);
}
  
export const getIdProvincia = () => {
    var lu = JSON.parse(localStorage.getItem("login"));
    var res = 0;
    if (lu && lu.botPlusUser)
        res = lu.botPlusUser.idProvincia;
    return res;
}

export const getIdCCAA = () => {
    var lu = JSON.parse(localStorage.getItem("login"));
    var res = lu.botPlusUser.idCCAA;
    return res;
}

export const getStringProvincia = () => {
    var lu = JSON.parse(localStorage.getItem("login"));
    var res = '';
    if (lu && lu.botPlusUser)
        res = lu.botPlusUser.Provincia;
    return res;
}

export const getStringCCAA = () => {
    var lu = JSON.parse(localStorage.getItem("login"));
    var res = '';
    if (lu && lu.botPlusUser)
        res = lu.botPlusUser.CCAA;
    return res;
}

export function getListaFormularios(tipo){
    var res = [];
    switch (tipo){
        case "MUH":
        case "H":
            res.push({
                codigoPagina: "FBAvanzadaMUH",
                display: "Avanzada medicamentos",
            });
            res.push({
                codigoPagina: "FBAvanzadaPAH",
                display: "Avanzada principios activos",
            });
            res.push({
                codigoPagina: "FBComposicionMUH",
                display: "Composición",
            });
            res.push({
                codigoPagina: "FBFormaColorMUH",
                display: "Aspecto de forma farmacéutica",
            });
            res.push({
                codigoPagina: "FBAgrupHomoMUH",
                display: "Agrupación homogénea",
            });
            res.push({
                codigoPagina: "FBAgrupHomoAutoMUH",
                display: "A. homogénea autonómica",
            });
            res.push({
                codigoPagina: "FBGTATCMUH",
                display: "Grupo terapéutico ATC",
            });
            res.push({
                codigoPagina: "FBGTVMPPMUH",
                display: "Descripción clínica AEMPS",
            });
            res.push({
                codigoPagina: "FBFormaFarmaMUH",
                display: "Forma farmacéutica",
            });
            res.push({
                codigoPagina: "FBLaboratorioMUH",
                display: "Laboratorio",
            });
            res.push({
                codigoPagina: "FBAFMUH",
                display: "Actividad farmacológica",
            });
            res.push({
                codigoPagina: "FBAMMUH",
                display: "Actividad enzimática",
            });
            res.push({
                codigoPagina: "FBPacienteIndicacionMUH",
                display: "Para una enfermedad",
            });
            res.push({
                codigoPagina: "FBReaccionesAdversasMUH",
                display: "Reacciones adversas",
            });
            res.push({
                codigoPagina: "FBAnalisisMUH",
                display: "Análisis clínicos afectados",
            });
            res.push({
                codigoPagina: "FBProblemasSumMUH",
                display: "Problemas de suministro",
            });
            res.push({
                codigoPagina: "FBAlertasCMUH",
                display: "Alertas de calidad",
            });
            res.push({
                codigoPagina: "FBFarmacogeneticaMUH",
                display: "Farmacogenética",
            });
            break;
        case "MUV":
        case "V":
            res.push({
                codigoPagina: "FBAvanzadaMUV",
                display: "Avanzada",
            });
            res.push({
                codigoPagina: "FBComposicionMUV",
                display: "Composición",
            });
            res.push({
                codigoPagina: "FBFormaFarmaMUV",
                display: "Forma farmacéutica",
            });
            res.push({
                codigoPagina: "FBLaboratorioMUV",
                display: "Laboratorio",
            });
            res.push({
                codigoPagina: "FBAFMUV",
                display: "Actividad farmacológica",
            });
            res.push({
                codigoPagina: "FBGTATCMUV",
                display: "Grupo terapéutico ATC",
            });
            res.push({
                codigoPagina: "FBEspecieIndicacionMUV",
                display: "Indicados por especie",
            });
            res.push({
                codigoPagina: "FBAlertasCMUV",
                display: "Alertas de calidad",
            });
            break;
        case "PPF":
        case "F":
        case "P":
            res.push({
                codigoPagina: "FBAvanzadaPPF",
                display: "Avanzada",
            });
            res.push({
                codigoPagina: "FBComposicionPPF",
                display: "Composición",
            });
            res.push({
                codigoPagina: "FBFormaFarmaPPF",
                display: "Forma farmacéutica",
            });
            res.push({
                codigoPagina: "FBLaboratorioPPF",
                display: "Laboratorio",
            });
            res.push({
                codigoPagina: "FBActPPF",
                display: "Ingredientes cosméticos por actividad",
            });
            res.push({
                codigoPagina: "FBSaborEdadPPF",
                display: "Dietoterápicos",
            });
            res.push({
                codigoPagina: "FBAlertasCPPF",
                display: "Alertas de calidad",
            });
            res.push({
                codigoPagina: "FBAgrupHomoPPF",
                display: "Agrupación homogénea",
            });
            res.push({
                codigoPagina: "FBAgrupHomoAutoPPF",
                display: "A. homogénea autonómica",
            });
            res.push({
                codigoPagina: "FBGTConPPF",
                display: "Categoría del Consejo General",
            });
            res.push({
                codigoPagina: "FBGTSanPPF",
                display: "Clasificación Nomenclátor",
            });
            break;
    }
    return res;
}

export  function getDescripcionTipo(tipo, codFormulario){
    var res = "";
    switch (tipo){
        case "MUH":
        case "H":
            res = "Medicamentos de uso humano";
            if (codFormulario==="FBAFMUH" || codFormulario==="FBAFMUH" || codFormulario==="FBAvanzadaPAH")
                res = "Principios activos de uso humano";
            break;
        case "PAH":
            res = "Principios activos de uso humano";
            break;
        case "MUV":
        case "V":
            res = "Medicamentos veterinarios"
            break;
        case "PPF":
        case "F":
        case "P":
            res = "Productos de parafarmacia";
            if (codFormulario==="FBActPPF")
                res = "Ingredientes";
            if (codFormulario==="FBSaborEdadPPF")
                res = "Dietoterápicos";
            break;
    }
    return res;
}

export  function getTipoFromCodigoPagina(codFormulario){
    var res = codFormulario.substring(codFormulario.length - 1, codFormulario.length);
    if (res==="F")
        res ="P"
    return res;
}

export  function getTituloFromCodigoPagina(codFormulario){
    var res = ""
    if (codFormulario==="FBAvanzadaPAH"){
        res = "Búsqueda avanzada";
    }else if (codFormulario==="FBExtranjerosEXT"){
        res = "Medicamentos extranjeros";
    }else if (codFormulario==="FBCambioNombre"){
        res = "Productos con cambio de nombre";
    }else if (codFormulario==="FBCambioLaboratorio"){
        res = "Productos con cambio de laboratorio";
    }else if (codFormulario==="FBCambioPrecioMUH"){
        res = "Productos con cambio de precio";
    }else if (codFormulario==="FBDesfinanciadosMUH"){
        res = "Medicamentos desfinanciados";
    }else if (codFormulario==="FBFechaAlta"){
        res = "Productos por fecha de alta";
    }else if (codFormulario==="FBDocumentos"){
        res = "Notas de seguridad y alertas de calidad";
    }else if (codFormulario==="FBAvanzadaMUV"){
        res = "Búsqueda avanzada";
    }else if (codFormulario==="FBAvanzadaMUH"){
        res = "Búsqueda avanzada";
    }else if (codFormulario==="FBAvanzadaPPF"){
        res = "Búsqueda avanzada";
    }else if (codFormulario==="FBAFMUV"){
        res = "Principios activos veterinarios por actividad farmacológica";
    }else if (codFormulario==="FBEspecieIndicacionMUV"){
        res = "Medicamentos veterinarios indicados por especie";        
    }else if (codFormulario==="FBSaborEdadPPF"){
        res = "Buscador de dietoterápicos";        
    }else if (codFormulario==="FBProductosIndicacionPPF"){
        res = "Buscador de productos y complementos alimenticios";        
    }else if (codFormulario==="FBProblemasSumMUH"){
        res = "Con problemas de suministro AEMPS";        
    }else if (codFormulario==="FBAlertasCMUH" || codFormulario==="FBAlertasCMUV" || codFormulario==="FBAlertasCPPF"){
        res = "Lotes retirados por alertas de calidad";        
    }else if (codFormulario==="FBAgrupHomoAutoMUH"){
        var ccaa = getStringCCAA();
        if (!ccaa || ccaa == "")
            ccaa = "autonómica";
        res = "A. homogénea " + ccaa
    }else if (codFormulario==="FBAgrupHomoAutoPPF"){
        var ccaa = getStringCCAA();
        if (!ccaa || ccaa == "")
            ccaa = "autonómica";
        res = "A. homogénea " + ccaa
    }else if (codFormulario==="FBAnalisisMUH"){
        res = "Interferencias con análisis clínicos";        
    }else{
        var tipo = getTipoFromCodigoPagina(codFormulario);
        var lista = getListaFormularios(tipo);
        var item = lista.find(f => f.codigoPagina === codFormulario);
        if (item)
            res = item.display;
    }
    return res;
}

export function scrollSiNoVisible(container, element) {
    //Determine container top and bottom
    let offset = container.offsetTop;
    let cTop = container.scrollTop+offset;
    let cBottom = cTop + container.clientHeight;

    //Determine element top and bottom
    let eTop = element.offsetTop;
    let eBottom = eTop + element.clientHeight;

    //Check if out of view
    if (eTop < cTop) {
        container.scrollTop -= (cTop - eTop);
    }
    else if (eBottom > cBottom) {
        container.scrollTop += (eBottom - cBottom);
    }    
}

export function fromListaPAsComposToParamURL(pas){
    var res = '';
    for (var i=0; i<pas.length; i++){
        var pa = pas[i];
        if (i>0) res += ","
        res += pa.codigo;
        res += ";"
        if (pa.sales && pa.sales.length>0){
            for (var j=0; j<pa.sales.length; j++){
                var sal = pa.sales[j];
                if (j>0) res += "-";
                res+=sal.codigo;
            }
        }
        res += ";"
        if (pa.cants && pa.cants.length>0){
            for (var j=0; j<pa.cants.length; j++){
                var cant = pa.cants[j];
                if (j>0) res += "-";
                res+=cant.cant.toString();
                res+="_";
                res+=cant.cod;
            }
        }
    }

    return res;
}

export function fromListaOpsAvanzadaToParamURL(ops){
    var res = '';
    for (var i=0; i<ops.length; i++){
        var op = ops[i];
        if (i>0) res += ";"       
        res += op.cod + ":";
        if (op.valores){
            for (var j=0; j<op.valores.length; j++){
                if(j>0) res += ","
                res += op.valores[j];
            }
        }
        else if (op.valor){
            res += op.valor;
        }
    }
    return res;
}

export function fromParmaURLToListaPAsCompos(param){
    var listaPAs = [];
    if (param !== ''){
        var aux = param.split(',');
        for(var i=0; i<aux.length; i++){
            var sPA = aux[i];
            if (sPA){
                var partesPA = sPA.split(';');
                if (partesPA.length > 0){
                    var pa = {
                        codigo : parseInt(partesPA[0]),
                        sales : [],
                        cants : [],
                    }
                    if (partesPA.length == 3){
                        var strSales = partesPA[1];
                        if (strSales !== ""){
                            var codigosSales = strSales.split('-').map(it=> parseInt(it));
                            for (var j = 0; j< codigosSales.length; j++){
                                var codsal = codigosSales[j];
                                var cv = {
                                    codigo : codsal,
                                }
                                pa.sales.push(cv);
                            }
                        }
                        var strCants = partesPA[2];
                        if (strCants !== ""){
                            var cantsAsStr = strCants.split('-');
                            for (var k = 0; k < cantsAsStr.length; k++){
                                var cantAsStr = cantsAsStr[k];
                                var partesCant = cantAsStr.split('_');
                                if (partesCant.length == 2){
                                    var cant = {
                                        cant : parseFloat(partesCant[0]),
                                        cod : partesCant[1],
                                    }
                                    pa.cants.push(cant);
                                }
                            }
                        }
                    }
                    listaPAs.push(pa);
                }
            }
        }
    }
    return listaPAs;
}

export function fromParamURLToListaOpsAvanzada(param){
    var listaOps = [];
    if (param !== ''){
        var aux = param.split(';');
        for(var i=0; i<aux.length; i++){
            var sOp = aux[i];
            if (sOp){
                var partesOp = sOp.split(':');
                if (partesOp.length === 2){
                    if (partesOp[1] === 'S' || partesOp[1] === 'N' || partesOp[1] === 'X'){
                        var op = {
                            cod: partesOp[0],
                            valor: partesOp[1],
                        }
                        listaOps.push(op);
                    }else{
                        var valores = partesOp[1].split(',');
                        var op = {
                            cod: partesOp[0],
                            valores: valores,
                        }
                        listaOps.push(op);
                    }
                }
            }
        }
    }
    return listaOps;
}

export  const handleClasesCheckbox = (clase, valor, marcado, excluyente) => {
    var id = clase + '__' + valor;
    var items = document.getElementsByClassName(clase);
    var grupoActivo = false;
    var item = document.getElementById(id);

    for (var i = 0; i<items.length; i++){
        if (excluyente && marcado && items[i] !== item){
            items[i].parentElement.classList.remove('active');
        }
        grupoActivo = grupoActivo || items[i].checked;
    }
    if (item){ 
        if (marcado)
            item.parentElement.classList.add("active");
        else
            item.parentElement.classList.remove('active');
        if (grupoActivo)
            for (var i = 0; i<items.length; i++)
                items[i].parentElement.classList.add('grupoActivo');
        else
            for (var i = 0; i<items.length; i++)
                items[i].parentElement.classList.remove('grupoActivo');
    }
};

export  const GetFiltrosStr = (filtros) =>{
    var result = '';
    if (filtros){
        for (var i=0; i<filtros.length; i++){
            var filtro = filtros[i];
            if (filtro.tipo==="List" || filtro.tipo==="Text" || filtro.tipo==="CN"){
                if (filtro.valor !== ""){
                    if (result !== '')
                        result += "|";
                    result += filtro.idGrupo + '__' + filtro.valor;
                }
            }
            if (filtro.tipo==="ListBox"){
                if (filtro.valor > -1){
                    if (result !== '')
                        result += "|";
                    result += filtro.idGrupo + '__' + filtro.valor;
                }
            }
            else {// tipo==="Check"
                if (filtro.valores){
                    for (var j=0; j<filtro.valores.length; j++){
                        var valor = filtro.valores[j];
                        if (valor.activo){
                            if (result !== '')
                                result += "|";
                            if (valor.idValor)
                                result += filtro.idGrupo + '__' + valor.idValor;
                            else
                                result += filtro.idGrupo + '__' + valor.value;
                        }
                    }
                }
            }
        }
    }
    return result;
}

export const PerfilUsuario = () =>{
    return AuthService.getUserRol();
}

export const DevuelveTextoAsHTML = (info) =>{
    var textosInfo = [];
    var subs = [];
    var idxsubs = [];
    if (info){
        if (info.indexOf('^')>=0){
            var partes = info.split('^')
            for (var i=0; i<partes.length-1; i++){
                textosInfo.push(partes[i]);
                var subInfo = partes[i+1];
                for(var j=0; j<subInfo.length && subInfo[j]>='0' && subInfo[j]<='9'; j++);
                partes[i+1] = subInfo.substr(j);
                subs.push(subInfo.substr(0,j));
                idxsubs.push(i);
            }
            textosInfo.push(partes[partes.length-1]);
        }
        else
            textosInfo.push(info);
    }

    var HTMLAux = <span>{textosInfo[0]}</span>;
    for (var i=0; i<subs.length; i++)
        HTMLAux = <span>{HTMLAux}<sup>{subs[i]}</sup>{textosInfo[i+1]}</span>;
    return HTMLAux;
}

export function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}



