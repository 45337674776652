import { FormatoFechaDDMMYYYY, rootPath } from "../../../../../services/utils";

const TablaFParticipantes = (props) => {
    const inactivo = props.inactivo;
    const datosCismed = props.datosCismed;
    const estilo = (inactivo ?
        {color:'transparent', textShadow: '0 0 10px rgba(0,0,0,0.5)'}
        :
        {}
    );
    return (
        <div class="module module__infoColumns module__infoColumns__noBorder" > 
        <div class="text hasTooltip" >
            <h3 class="fs fs16 fw fw600 greyColor2 title" style={estilo}>Farmacias participantes en CisMED
                <span float="right" onClick={(e) => props.abreEmergenteCismed()}>
                    <img src={rootPath + "img/infoGreen.svg"} alt=""/>
                </span>
            </h3>
        </div>
        <div class="columns columns__2" >
            <div class="row fs fs14 FlamaBook" >
                <div class="column" >
                    <p>España</p>
                </div>
                <div class="column" >
                    <p>{datosCismed.NFarmaciasNacional} farmacias</p>
                </div>
            </div>
            <div class="row fs fs14 FlamaBook" >
                <div class="column" >
                    <p>{datosCismed.CCAA}</p>
                </div>
                <div class="column" >
                    <p>{datosCismed.NFarmaciasCCAA} farmacias</p>
                </div>
            </div>
            <div class="row fs fs14 FlamaBook" >
                <div class="column" >
                    <p>{datosCismed.Provincia}</p>
                </div>
                <div class="column" >
                    <p>{datosCismed.NFarmaciasProvincia} farmacias</p>
                </div>
            </div>
            <div class="row fs fs14 FlamaBook" >
                <div class="column" >
                    <p>Última fecha de consolidación de datos ({datosCismed.Provincia})</p>
                </div>
                {(!datosCismed.NoConsolidada) ?
                    <div class="column" >
                        <p>{FormatoFechaDDMMYYYY(datosCismed.FechaConsolidacion)}</p>
                    </div>
                :
                    <div class="column" >
                        <p>
                            El periodo transcurrido desde la última fecha de consolidación de datos procedentes de su provincia en CisMED, 
                            es superior a las 48 horas, por lo que la información que está visualizando puede no ajustarse a la situación actual 
                        </p>
                    </div>
                }
            </div>
        </div>
    </div>
)
}
export default TablaFParticipantes;