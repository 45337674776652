import LoadingPopup from "../../Resultados/Loading.Popup";
import SelectorUno from "../../Utilidades/Selector/SelectorUno";
import BP2022Service from "../../../services/user.service";
import { useEffect, useState } from "react";
import ResumenBusqueda from "../ResumenBusqueda";
import SelectorMulti2 from "../../Utilidades/Selector/SelectorMulti2";
import VolverCabeceraResultados from "../../Resultados/CabeceraResultados/VolverCabeceraResultados";
import { GetAntecesorWhithClassName } from "../../../services/utils";


const FBReaccionesAdversas= (props) => {
    const [datosFormulario, setDatosFormulario] = useState({
        listaEnf: null,
        listaMed: null,
    });
    const [seleccionFormulario, setSeleccionFormulario] = useState({
        idEpi: (props.idEpi ? props.idEpi : 7),
        idsEnfs: props.idsEnfs,
        idsMeds: props.idsMeds,
    })

    useEffect(() => {
        if (datosFormulario.listaEnf === null)
        {
            DescargaDatos();
        }        
    },[]);

    const DescargaDatos = () => {      
        BP2022Service.datosFormularioReaccionesAdversas()
        .then((response) => {
            var df = {
                listaEnf : response.data.Enfermedades,
                listaMed : response.data.Medicamentos,
            };
            df.listaEnf.splice(0,0,{Codigo:0, Valor:'Indique un síntoma o enfermedad'});
            df.listaMed.splice(0,0,{Codigo:0, Valor:'Indique una medicamento o principio activo'});
            setDatosFormulario(df);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const TituloByEpicod = (epi) =>{
        var res = '';
        switch (epi){
            case 3:
                res = 'Indicaciones';
            break;
            case 7:
                res = 'Reacciones adversas';
            break;
            case 13:
                res = 'Contraindicaciones';
            break;
            case 14:
                res = 'Uso precautorio';
            break;
        }
        return res;
    }



    const construyeResumenBusqueda = () => {
        var res = [];

        if ((seleccionFormulario.idsEnfs) && (seleccionFormulario.idsEnfs.length>0)){
            if (datosFormulario.listaEnf){
                var valor = '';
                for (var i =0; i<seleccionFormulario.idsEnfs.length; i++){
                    var item = datosFormulario.listaEnf.find(x => x.Codigo === seleccionFormulario.idsEnfs[i]);
                    if (item){
                        if (valor !== '')
                            valor += ", ";
                        valor += item.Valor;
                    }
                }
                if (valor != '')
                    res.push ({titulo: TituloByEpicod(seleccionFormulario.idEpi), valor: valor});
            }
        }

        if ((seleccionFormulario.idsMeds) && (seleccionFormulario.idsMeds.length>0)){
            if (datosFormulario.listaMed){
                var valor = '';
                for (var i =0; i<seleccionFormulario.idsMeds.length; i++){
                    var item = datosFormulario.listaMed.find(x => x.Codigo === seleccionFormulario.idsMeds[i]);
                    if (item){
                        if (valor !== '')
                            valor += ", ";
                        valor += item.Valor;
                    }
                }
                if (valor != '')
                    res.push ({titulo: "Medicamentos / Principios activos", valor: valor});
            }
        }

        return res;
    }

    const resumen = construyeResumenBusqueda(seleccionFormulario);

    const hayBusqueda = seleccionFormulario.idsEnfs && seleccionFormulario.idsEnfs.length>0 && seleccionFormulario.idsMeds && seleccionFormulario.idsMeds.length>0; 

    const clickBuscar = (e) =>{
        if (hayBusqueda){
            props.cambiaPagina({
                newpagina: 'BReaccionesAdversasMUH',
                idEpi: seleccionFormulario.idEpi,
                idsEnfs: seleccionFormulario.idsEnfs,
                idsMeds: seleccionFormulario.idsMeds,
            })
        }
    }

    const BorraBusqueda = (e) =>{
        const formularioVacio={
            idEpi: 7,
            idsEnfs: [],
            idsMeds: [],
        }
        setSeleccionFormulario(formularioVacio);
        var inputs = document.getElementsByClassName('inputSelector');
        if (inputs){
            for (var i=0; i<inputs.length; i++){
                inputs[i].value = "";
                const itemContainer = GetAntecesorWhithClassName(inputs[i], 'custom-select-container');
                if (itemContainer){
                    itemContainer.classList.remove('active');
                }
            }
        }
    }

    const CambioEpigrafe = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idEpi = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddEnfermedad = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (!nuevaSeleccion.idsEnfs) { nuevaSeleccion.idsEnfs = []};
        nuevaSeleccion.idsEnfs.push(nuevovalor);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveEnfermedad = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var i = nuevaSeleccion.idsEnfs.indexOf(parseInt(valor));
        nuevaSeleccion.idsEnfs.splice(i,1);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddMedicamento = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (!nuevaSeleccion.idsMeds) { nuevaSeleccion.idsMeds = []};
        nuevaSeleccion.idsMeds.push(nuevovalor);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveMedicamento = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var i = nuevaSeleccion.idsMeds.indexOf(parseInt(valor));
        nuevaSeleccion.idsMeds.splice(i,1);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const KeyDownForm = (e) =>{
        var keyCode = e.keyCode;
        if (keyCode === 13){
            clickBuscar(e);
        }
    }

    const estiloBotonesSup = (hayBusqueda ? {height:'76px', width:'100%'} : {height:'76px', width:'100%', visibility:'hidden'})
    const estiloBotonesInf = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'center'} : {height:'76px', width:'100%', justifyContent: 'center', display:'none'})

    const listaCodValorEnf = (datosFormulario.listaEnf) ? datosFormulario.listaEnf.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorMed = (datosFormulario.listaMed) ? datosFormulario.listaMed.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    
    const clasedivmodulefilters = "cover cover__filters cover__filters__2";

    return (
        <div class="content" >

            { (datosFormulario.arbol === null) ?
                <LoadingPopup titulo="Un momento" texto="Preparando formulario" esPorcentaje={true}></LoadingPopup>
            :
                <form class="content__results content__results__filters" id="main-filter"  onSubmit={(e) => e.preventDefault()}  onKeyDown={(e) => KeyDownForm(e)} tabIndex="0">
                    <div class="module module__buttons" style={estiloBotonesSup}>
                        <span class="button button__arrow button__green noUppercase reset-form" data-form="#main-filter" title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} >
                            Vaciar búsqueda
                        </span>
                    </div>
                    <div class={clasedivmodulefilters} >
                        <div class="module module__filters" >
                            <h3 class="title fs fs16 greyColor2" >Síntomas / Enfermedades:</h3>
                            <SelectorMulti2 
                                        lista = {listaCodValorEnf} 
                                        idHTML="FBRAEnf" 
                                        valuesSelected={seleccionFormulario.idsEnfs}
                                        alAnadir={AddEnfermedad} 
                                        alBorrar={RemoveEnfermedad} 
                                        ancho="100%"
                                        tipoFiltradoLista={1}
                            ></SelectorMulti2>
                        </div>
                        <div class="module module__filters" >
                            {/*<h3 class="title fs fs16 greyColor2" >Relación:</h3>
                            <div class="checks" >
                                 <label><input type="radio" value="3"  name="epi" checked={seleccionFormulario.idEpi === 3} onClick={(e) => CambioEpigrafe(3)} />Indicaciones </label>
                                 <label><input type="radio" value="13" name="epi" checked={seleccionFormulario.idEpi ===13} onClick={(e) => CambioEpigrafe(13)} />Contraindicaciones </label>
                                 <label><input type="radio" value="14" name="epi" checked={seleccionFormulario.idEpi ===14} onClick={(e) => CambioEpigrafe(14)} />Precauciones </label>
                                 <label><input type="radio" value="7"  name="epi" checked={seleccionFormulario.idEpi === 7} onClick={(e) => CambioEpigrafe(7)} />Reacciones adversas </label>
                            </div>*/}
                            <h3 class="title fs fs16 greyColor2" >Medicamentos y/o Principios activos</h3>
                            <SelectorMulti2 
                                        lista = {listaCodValorMed} 
                                        idHTML="FBRAMed" 
                                        valuesSelected={seleccionFormulario.idsMeds}
                                        alAnadir={AddMedicamento} 
                                        alBorrar={RemoveMedicamento} 
                                        ancho="100%"
                                        tipoFiltradoLista={1}
                            ></SelectorMulti2>
                        </div>
                    </div>
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesInf}>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                    </div>
                    <ResumenBusqueda condiciones={resumen}></ResumenBusqueda>
                </form>
            }
        </div>
    );
};

export default FBReaccionesAdversas;
