import { GetAntecesorWhithClassName, rootPath } from "../../../services/utils";
import ItemMenuIconos from "../../Cabecera/MenuIconos/ItemMenuIconos";

function Interaccion(props){
    const itemInteraccion = props.item
    var claseMask = "mask";
    if (itemInteraccion.IdSemaforo === 1)
        claseMask = "mask red";
    if (itemInteraccion.IdSemaforo === 2)
        claseMask = "mask yellow";
    if (itemInteraccion.IdSemaforo === 3)
        claseMask = "mask green";

    var imgSentido = rootPath + "img/ficha/arrowRight.svg";
    var txtSentido = itemInteraccion.PA1 + " interfiere con la acción de " + itemInteraccion.PA2;
    if (itemInteraccion.Sentido === 1){
        imgSentido = rootPath + "img/ficha/arrowLeft.svg";
        txtSentido = itemInteraccion.PA2 + " interfiere con la acción de " + itemInteraccion.PA1;
    }
    if (itemInteraccion.Sentido === 2){
        imgSentido = rootPath + "img/ficha/arrowEqual.svg";
        txtSentido = "Interferencia mutua";
    }

    const clickDetalles = (e) =>{
        var item = e.target;
        var header = GetAntecesorWhithClassName(item,'showCard');
        var covercard = GetAntecesorWhithClassName(item,'cover__card');
        if (header)
            header.classList.toggle('active');
        if (covercard)
            covercard.classList.toggle('active');
    }
        
    const clickDescripcion = (e) =>{
        var item = e.target;
        var header = GetAntecesorWhithClassName(item,'showCard__details');
        var covercard = GetAntecesorWhithClassName(item,'card__content');
        if (header)
            header.classList.toggle('active');
        if (covercard)
            covercard.classList.toggle('active');
    }

    const ancho = window.innerWidth;
    const isMobile = (ancho<768);

    const anchos = (isMobile) ? ['37%','26%','37%'] : ['39%','27%','34%'];

    const HTMLSemaforoInter = (
        <div className={claseMask} >
            <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <g id="desarollo_v3" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="-Búsqueda-MUH-por-interacciones-(búsquedas-extendidas)" transform="translate(-368.000000, -414.000000)" fill="#FFE3DF" stroke="#EBA7B9" stroke-width="2">
                        <g id="Group-5" transform="translate(344.000000, 390.000000)">
                            <circle id="Oval-Copy-8" style={{mixBlendMode: 'multiply'}} cx="39" cy="39" r="14"></circle>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
        
    return (
        <div class="cover cover__card">
            <div class="card card__box" >
                <div class="wrap wrap__top">
                    <div class="box box__left" style={{width: anchos[0]}}>
                        {
                            (!isMobile) ?
                                HTMLSemaforoInter
                            :
                                <></>                                
                        }
                        <div class="text" >
                            <h3 class="title fs fs16 blackColor uppercase" >{itemInteraccion.TxtSel1}</h3>
                            <p class="fs fs13 greyColor4 FlamaBook"  style={{marginBottom: '10px'}}>{itemInteraccion.TipoRegistro1}</p>
                            {
                                (itemInteraccion.IdSel1 !== itemInteraccion.IdPA1) ?
                                    <div class="text" style={{paddingTop:'10px'}} >
                                        <h3 class="title fs fs16 blackColor uppercase" >{itemInteraccion.PA1}</h3>
                                    </div>
                                :
                                    <div></div>
                            }
                        </div>
                    </div>
                    <div class="box box__center" style={{ width: anchos[1]}}>
                        {
                            (isMobile) ?
                                HTMLSemaforoInter
                            :
                                <></>                                
                        }
                        <div class="mask mask__auto" >
                            <img src={imgSentido} alt={imgSentido} />
                        </div>
                        <div class="text" >
                            <p class="fs fs16 blackColor FlamaBasic" style={{marginLeft:'4px', marginRight: '6px'}}>{itemInteraccion.DesSemaforo}</p>
                        </div>
                    </div>
                    <div class="box box__right" style={{width: anchos[2]}}>
                        <div class="text" >
                            <h3 class="title fs fs16 blackColor uppercase" >{itemInteraccion.TxtSel2}</h3>
                            <p class="fs fs13 greyColor4 FlamaBook"  style={{marginBottom: '10px'}}>{itemInteraccion.TipoRegistro2}</p>
                            {
                                (itemInteraccion.IdSel2 !== itemInteraccion.IdPA2) ?
                                    <div class="text" style={{paddingTop:'10px'}} >
                                        <h3 class="title fs fs16 blackColor uppercase" >{itemInteraccion.PA2}</h3>
                                    </div>
                                :
                                    <div></div>
                            }
                        </div>
                    </div>
                </div>
                { (itemInteraccion.IdSemaforo<=3) ?
                <div class="wrap wrap__bottom" >
                    <span class="fs fs11 fw fw400 purpleColor5 uppercase Flama showCard showCard__content" onClick={e => clickDetalles(e)} >Detalles 
                        <img src="./img/arrowPurple2.svg" />
                    </span>
                </div>
                :
                <div></div>
                }
            </div>
            { (itemInteraccion.IdSemaforo<=3) ?
                <div class="card card__content" >
                    <div class="module module__infoColumns module__infoColumns__noBorder"> 
                        <div class="columns columns__2">
                            <div class="row fs fs14 FlamaBook">
                                <div class="column">
                                    <p>Importancia clínica:</p>
                                </div>
                                <div class="column">
                                    <p>{itemInteraccion.Significacion}</p>
                                </div>
                            </div>
                            <div class="row fs fs14 FlamaBook">
                                <div class="column">
                                    <p>Sentido de la interacción:</p>
                                </div>
                                <div class="column">
                                    <p>{txtSentido}</p>
                                </div>
                            </div>
                            <div class="row fs fs14 FlamaBook">
                                <div class="column">
                                    <p>Efecto clínico:</p>
                                </div>
                                <div class="column">
                                    <p>{itemInteraccion.Naturaleza}</p>
                                </div>
                            </div>
                            <div class="row fs fs14 FlamaBook">
                                <div class="column">
                                    <p>Medidas a tomar:</p>
                                </div>
                                <div class="column">
                                    <p>{itemInteraccion.Medidas}</p>
                                </div>
                            </div>
                            { (itemInteraccion.Textos) ?
                            <div class="row fs fs14 FlamaBook">
                                <div class="column">
                                    <p>Descripción:</p>
                                </div>
                                <div class="column">
                                    <span class="fs fs11 fw fw400 purpleColor5 uppercase Flama showCard showCard__details"  onClick={e => clickDescripcion(e)} >
                                        Mostrar descripción 
                                        <img src="./img/arrowPurple2.svg" />
                                    </span>
                                    <div class="details" >
                                        <h3 class="title fs fs14 fw fw400 uppercase Flama" >{itemInteraccion.Titulo}</h3>
                                        <div class="text" >
                                            {(itemInteraccion.Textos.Efecto) ?
                                            <div>
                                            <h4>Efecto</h4>
                                            <p style={{whiteSpace: 'pre-line'}}>
                                                {itemInteraccion.Textos.Efecto}    
                                            </p>
                                            </div>
                                            :
                                            <div></div>
                                            }
                                            {(itemInteraccion.Textos.Importancia) ?
                                            <div>
                                            <h4>Importancia</h4>
                                            <p style={{whiteSpace: 'pre-line'}}>
                                                {itemInteraccion.Textos.Importancia}    
                                            </p>
                                            </div>
                                            :
                                            <div></div>
                                            }
                                            {(itemInteraccion.Textos.Mecanismo) ?
                                            <div>
                                            <h4>Mecanismo</h4>
                                            <p style={{whiteSpace: 'pre-line'}}>
                                                {itemInteraccion.Textos.Mecanismo}    
                                            </p>
                                            </div>
                                            :
                                            <div></div>
                                            }
                                            {(itemInteraccion.Textos.Evidencias) ?
                                            <div>
                                            <h4>Evidencias</h4>
                                            <p style={{whiteSpace: 'pre-line'}}>
                                                {itemInteraccion.Textos.Evidencias}    
                                            </p>
                                            </div>
                                            :
                                            <div></div>
                                            }
                                            {(itemInteraccion.Textos.Referencias) ?
                                            <div>
                                            <h4>Referencias</h4>
                                            <p style={{whiteSpace: 'pre-line'}}>
                                                {itemInteraccion.Textos.Referencias}
                                            </p>
                                            </div>
                                            :
                                            <div></div>
                                            }
                                        </div>
                                        <div class="cover cover__show" >
                                            <span class="fs fs11 fw fw400 purpleColor5 uppercase Flama showCard hide__details"  onClick={e => clickDescripcion(e)} >
                                                Ocultar descripción 
                                                <img src="./img/arrowPurple2.svg" /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div></div>
                            }
                        </div>
                    </div>
                </div>
            :
                <div></div>
            }
        </div>
    )
}    
export default Interaccion;
