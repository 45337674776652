import { elementIsOfClass, RemoveClassNameToDoubleClassItems, rootPath } from "../../../../../services/utils";

const AcordeonN1 = (props) => {
    const clasDiv = (props.activo ? "accordion__module active" : "accordion__module")
    var htmlTitulo = (props.ocultaCodigo) ? 
        (<label class="title fs fs16 greyColor2 uppercase">{props.titulo}</label>) 
        : (<label class="title fs fs16 greyColor2 uppercase">{props.codigo} {props.titulo}</label>)
    var htmlArrow = (<img class="arrow" src = {rootPath + "img/arrowGreen2.svg"} alt="arrowGreen2" />);
    var claseContenido = "accordion__content pad20";

    const handleClickAcordeonHeader = (e) =>{
        var item = e.target;
        while (item && !elementIsOfClass(item,'accordion__header__check'))
            item = item.parentElement;
        if (item)
            item.parentElement.classList.toggle('active');
    }

    const handleClickAcordeonCheck = (e) =>{
        var item = e.target;
        e.stopPropagation();
        var parent = item.parentElement;
        RemoveClassNameToDoubleClassItems('active','titulo__rama__arbol','active');
        var checks = document.getElementsByClassName('check__rama__arbol');
        for (var i = 0; i<checks.length; i++){
            if (checks[i] !== item)
                checks[i].checked = false;
        }
        if (item.checked)
            props.cambiaSeleccion(props.codigo, props.titulo);
        else
            props.cambiaSeleccion('','');

        if (parent){
            if (item.checked)
                parent.classList.add('active');
            else
                parent.classList.remove('active');
        }

    }


    return (
        <div class={clasDiv}> 
            <div class="accordion__header__check"  onClick={(e) =>  handleClickAcordeonHeader(e)}>
                {(props.contenido) ?
                    <span>{htmlArrow}</span>
                    :
                    <span></span>
                }                
                <div class="text titulo__rama__arbol">
                    {(props.checked) || (props.codigo===props.valueSelected) ?
                        <input type="checkbox" value={props.codigo} class="check__rama__arbol" onClick={(e) =>  handleClickAcordeonCheck(e)} checked />
                    : 
                        <input type="checkbox" value={props.codigo} class="check__rama__arbol" onClick={(e) =>  handleClickAcordeonCheck(e)} />
                    }
                    {htmlTitulo}
                </div>
            </div>
            {(props.contenido) ?
                <div class={claseContenido}>
                    {props.contenido}
                </div>
                :
                <div></div>
            }
        </div>
    )
}
export default AcordeonN1;
