import { elementIsOfClass, RemoveClassNameToClassItems, RemoveClassNameToDoubleClassItems, rootPath } from "../../../../../services/utils";

const AcordeonN2 = (props) => {
    const clasDiv = props.clasDiv;
    var htmlTitulo = (props.ocultaCodigo) ? 
        (<label class="title fs fs16 greyColor2 uppercase">{props.titulo}</label>)
        :(<label class="title fs fs16 greyColor2 uppercase"><span>{props.codigo}</span> {props.titulo}</label>)
    //var htmlArrow = (<img class="arrow" src = {rootPath + "img/arrowGreen2.svg"} alt="arrowGreen2" />);
    var claseContenido = "accordion__mas__content";
    var estiloContenido = {display: 'block', paddingTop: '2px', paddingBottom: '0px'};

    const handleClickAcordeonHeader = (e) =>{
        var item = e.target;
        while (item && !elementIsOfClass(item,'accordion__mas__header'))
            item = item.parentElement;
        if (item)
            item.parentElement.classList.toggle('active');
    }


    const handleClickAcordeonCheck = (e) =>{
        var item = e.target;
        e.stopPropagation();
        var parent = item.parentElement;
        RemoveClassNameToDoubleClassItems('active','titulo__rama__arbol','active');
        var checks = document.getElementsByClassName('check__rama__arbol');
        for (var i = 0; i<checks.length; i++){
            if (checks[i] !== item)
                checks[i].checked = false;
        }
        if (item.checked)
            props.cambiaSeleccion(props.codigo, props.titulo);
        else
            props.cambiaSeleccion('','');

        if (parent){
            if (item.checked)
                parent.classList.add('active');
            else
                parent.classList.remove('active');
        }
    }

    return (
        <div class={clasDiv}> 
            <div class="accordion__mas__header"  onClick={(e) =>  handleClickAcordeonHeader(e)}>
                <div class="text titulo__rama__arbol">
                {(props.checked)  || (props.codigo===props.valueSelected) ?
                        <input type="checkbox" value={props.codigo} class="check__rama__arbol"  onClick={(e) =>  handleClickAcordeonCheck(e)} checked />
                    : 
                        <input type="checkbox" value={props.codigo} class="check__rama__arbol"  onClick={(e) =>  handleClickAcordeonCheck(e)} />
                    }                    
                    {htmlTitulo}
                </div>
            </div>
            <div class={claseContenido} style={estiloContenido}>
                {props.contenido}
            </div>
        </div>
    )
}
export default AcordeonN2;
