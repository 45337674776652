import { useEffect } from 'react/cjs/react.development';
import { IconosListadosPI } from '../../../../services/objsAplicacion.js';
import {FormatoFechaDDMMYYYY, FormatoFechaDDMMYYYYHHMM, PerfilUsuario, numberWithCommas, rootPath} from '../../../../services/utils.js'
import BusqMedsService from "../../../../services/user.service";

function FilaListaResBusqueda(props){
    const item = props.item;
    const tiposPersona = new Array ("MUH","PAH","ASOH","FM","PO","MHO","PHO");
    const tiposAnimal = new Array ("MUV","PAV","ASOV");
    const tiposParafar = new Array ("PPF","ING");
    const patologias = new Array("PATH","PATV");
    const conPrecio = new Array("MUH", "MUV", "PPF","MHO","FM");
    const conEstado = new Array("MUH","MUV","PAH","PAV","ASOH","ASOV","PPF","ING","MHO","PHO");
    const conLaboratorio = new Array("MUH","MUV","PPF","MHO");
    const claseEstado = (item.IdEstadoAutorizacion === '0' ?
                                "estado__item estado__item__1 autorizado"
                            :   
                                (item.IdEstadoAutorizacion === '1' ?
                                    "estado__item estado__item__1 suspendido"
                                :
                                    (item.IdEstadoAutorizacion === '2' || item.IdEstadoAutorizacion === '4' ?
                                        "estado__item estado__item__1 anulado"
                                    :
                                        "estado__item estado__item__1"
                                    )
                                )
                            );
    const colorEstado = (item.IdEstadoAutorizacion === '0' ?
                            "greenColor4"
                        :   
                            (item.IdEstadoAutorizacion === '1' ?
                                "yellowColor2"
                            :
                                (item.IdEstadoAutorizacion === '2' || item.IdEstadoAutorizacion === '4' ?
                                    "redColor"
                                :
                                    "greyColor2"
                                )
                            )
                        );
    const claseTextoEstado = "fs fs12 " + colorEstado;

    const literalCodigo = (conPrecio.includes(item.CodTipoRegistro) ?
                                "Cód. Nacional:"
                            :
                                (patologias.includes(item.CodTipoRegistro) ?
                                    "Código CIE:"
                                :
                                    "Código"
                                )
                            );
    const pictogramatipo =  (tiposPersona.includes(item.CodTipoRegistro) ?
                                rootPath + "img/person.svg" 
                            :
                                (tiposAnimal.includes(item.CodTipoRegistro) ? 
                                    rootPath + "img/perro.svg" 
                                :
                                    (tiposParafar.includes(item.CodTipoRegistro) ?
                                        rootPath + "img/jabonLiquido.svg"
                                    :(item.CodTipoRegistro==="PATH") ?
                                        rootPath + "img/patHumana.svg"
                                    :
                                        rootPath + "img/patVet.svg"
                                    )
                                )
                            )
    const problemaSuministro = ((item.ProblemaSuministro==="S")?
                                    {
                                        img : rootPath + "img/icoTrouble.svg",
                                        leyenda : 'Medicamento con problemas de suministro',
                                        texto : 'Problema de suministro AEMPS'
                                    }
                                : ((item.ProblemaSuministro==="X") ? 
                                    {
                                        img : rootPath + "img/icoCee.svg",
                                        leyenda : 'Medicamento con problemas de suministro.\nLa AEMPS informa que se puede solicitar como medicamento extranjero',
                                        texto : 'Problema de suministro AEMPS'
                                    }
                                :
                                    null
                                ));

    const vesAFicha = (it) =>{
        props.cambiaPagina({
            newpagina: "Ficha"+it.CodTipoRegistro,
            newid: it.Id,
            filtros: props.filtros,
            numMostrar: props.numMostrar,
            idxOrden: props.idxOrden,
        });
    }

    const mostrarPI = props.mostrarPI;
    const infoPacienteIndica = ((mostrarPI && item.ListaAux1 && item.ListaAux1.length>0)) ?
        item.ListaAux1.map(icon => 
            <div class="info">
                <p class="fs fs12 greyColor17 misc" >
                    <img width="26" src={IconosListadosPI.find(aux => aux.cod === icon).img} alt={IconosListadosPI.find(aux => aux.cod === icon).txt} />
                    {IconosListadosPI.find(aux => aux.cod === icon).txt}
                </p>
            </div>)
    :
        <span></span>

    const mostrarAuxStr = props.mostrarAuxStr;
    const infoAuxStr = ((mostrarAuxStr && item.AuxStr && item.AuxStr != "") ?
        <div class="info">
            <p class="fs fs12 greyColor17 misc">
                {item.AuxStr}
            </p>
        </div>
    :
        <span></span>
    );

    var mensStrAux2 = item.AuxStr2;
    if (mensStrAux2==="NHUER")
        mensStrAux2 = "";
    else if (mensStrAux2==="SHUER")
        mensStrAux2 = "Medicamento huérfano";

    const infoAuxStr2 = ((mostrarAuxStr && item.AuxStr2 && item.AuxStr2 != "") ?
        <div class="info">
            <p class="fs fs12 greyColor17 misc">
                {mensStrAux2}
            </p>
        </div>
    :
        <span></span>
    );

    const mostrarListaAuxStr = props.mostrarListaAuxStr;
    const infoListaAuxStr = ((mostrarListaAuxStr && item.ListaStr && item.ListaStr.length != "") ?
        <div class="info">
            {item.ListaStr.map(x => 
                <p class="fs fs12 greyColor17 misc">
                    {x}
                </p>
            )}
        </div>
    :
        <span></span>
    );

    const mostrarAuxFecha = props.mostrarAuxFecha;
    const infoAuxFecha = ((mostrarAuxFecha && item.AuxFecha && new Date(item.AuxFecha).getTime() > 0) ?
        <div class="info">
            <p class="fs fs12 greyColor17 misc">
                Fecha exclusión financiación individualizada: {FormatoFechaDDMMYYYY(item.AuxFecha)}
            </p>
        </div>
    :
        <span></span>
    );

    const infoAuxFecha2 = ((mostrarAuxFecha && item.AuxFecha2 && new Date(item.AuxFecha2).getTime()> 0) ?
        <div class="info">
            <p class="fs fs12 greyColor17 misc">
                Fecha exclusión Insalud: {FormatoFechaDDMMYYYY(item.AuxFecha2)}
            </p>
        </div>
    :
        <span></span>
    );

    const mostrarFechaAlta = props.mostrarFechaAlta;
    const infoFechaAlta = ((mostrarFechaAlta && item.FechaAlta && new Date(item.FechaAlta).getTime() > 0) ?
        <div class="info">
            <p class="fs fs12 greyColor17 misc">
                Fecha de alta: {FormatoFechaDDMMYYYY(item.FechaAlta)}
            </p>
        </div>
    :
        <span></span>
    );

    const mostrarFechaEntrada = props.mostrarFechaEntrada;
    const infoFechaEntrada = ((mostrarFechaEntrada && item.AuxFecha && new Date(item.AuxFecha).getTime() > 0) ?
        <div class="info">
            <p class="fs fs12 greyColor17 misc">
                Fecha cambio de precio: {FormatoFechaDDMMYYYY(item.AuxFecha)}
            </p>
        </div>
    :
        <span></span>
    );



    const mostrarFVisita = props.mostrarFVisita;
    const infoFechaVisita = ((mostrarFVisita && item.FechaVisita && new Date(item.FechaVisita).getTime() > 0) ?
        <div class="info">
            <p class="fs fs12 greyColor17 misc">
                Fecha visita: {FormatoFechaDDMMYYYYHHMM(item.FechaVisita)}
            </p>
        </div>
    :
        <span></span>
    );

    const clickBorrar = (tipo,id) => {
        BusqMedsService.borraProductoHistorial(tipo,id);
        props.alBorrar(tipo,id);
    }


    const mostrarBotonBorrar = props.mostrarBotonBorrar;
    const infoBorrarItem = ((mostrarBotonBorrar) ?
        <div class="info">
            <p class="fs fs12 greyColor17 misc" style={{cursor: 'pointer'}} onMouseOver={(e) => mouseOverLink(e)} onMouseOut={(e) => mouseOutLink(e)} onClick={(e) => clickBorrar(item.CodTipoRegistro, item.Id)}>
                <img src={rootPath + "img/checkKoRed.svg" } style={{width:'14px'}}/> Borrar del historial
            </p>
        </div>
    :
        <span></span>
    );

    var rol = PerfilUsuario();

    const mostrarAvisoPrecio = props.mostrarAvisoPrecio && rol !== "EST";
    const mostrarAvisoPMenor = (props.pvpMenor && props.pvpMenor>0 && item.AuxDbl>props.pvpMenor);
    const mostrarAvisoPMasBajo = (props.pvpMasBajo && props.pvpMasBajo>0 && item.AuxDbl>props.pvpMasBajo);
    const infoAvisoPrecioMenor = ((mostrarAvisoPrecio) && (mostrarAvisoPMenor)?
        <div class="info">
            <p class="fs fs12 redColor misc">
                Supera el precio menor
            </p>
        </div>
    :
        <span></span>
    );

    const infoAvisoPrecioMasBajo = ((mostrarAvisoPrecio) && (mostrarAvisoPMasBajo)?
        <div class="info">
            <p class="fs fs12 redColor misc">
                Supera el precio más bajo
            </p>
        </div>
    :
        <span></span>
    );

    const ancho = window.innerWidth;
    const isMobile = (ancho<768);

    var literalPrecio = props.literalPVP;
    var precio = item.PVPIVA;
    if (item.AmbitoHospitalario === "S" && false){
        literalPrecio = (item.Notificado === "S") ? "PVL Notificado" : "PVL";
        precio = item.PVL
    }else{
        if (item.CodTipoRegistro === "FM") literalPrecio = "Precio";
        else literalPrecio = (mostrarAvisoPrecio && item.AuxStr==="PN" ? "Precio facturación": props.literalPVP);
        precio = (mostrarAvisoPrecio && item.AuxStr==="PN" ? item.PFacturacion : item.PVPIVA);
        if (props.mostrarHist && item.AuxDbl>0 && item.AuxStr2==='001' && item.CodTipoRegistro==='MUH' && item.AuxStr3 === "PN"){
            literalPrecio = "Precio Facturación"
            precio = item.PFacturacion
        }
    }
    const maxHeight=150;
    const maxWidth=228;

    const estilhorizontal = {maxWidth: maxWidth.toString() + 'px', maxHeight: maxHeight.toString() + 'px', width: '100%', height: 'auto'}
    const estilvertical = {maxWidth: maxWidth.toString() + 'px', maxHeight: maxHeight.toString() + 'px', height: '100%', width: 'auto'}

    const loadImage = (e) => {
        var h = e.target.height;
        if (h===maxHeight){
            e.target.style.height = '100%';
            e.target.style.width = 'auto';
            e.target.style.maxWidth = maxWidth.toString() + 'px';
            e.target.style.maxHeight = maxHeight.toString() + 'px';
        }
    }

    var estiloItem = (props.oculto) ? {visibility:'hidden'} : {};
    if (props.visitado)
        estiloItem.backgroundColor = '#fafafa';

    var displayTipoPack = item.TipoRegistro;
    if (item.CodTipoRegistro === "MUH" || item.CodTipoRegistro === "MUV") displayTipoPack = "Medicamento";
    if (item.CodTipoRegistro === "MHO") displayTipoPack = "Medicamento homeopático";
    if (item.CodTipoRegistro === "PAH" || item.CodTipoRegistro === "PAV") displayTipoPack = "Principio activo";
    if (item.CodTipoRegistro === "PPF") displayTipoPack = "Parafarmacia";
    if (item.CodTipoRegistro === "ING") displayTipoPack = "Ingrediente";
    if (item.CodTipoRegistro === "PATH" || item.CodTipoRegistro === "PATV") displayTipoPack = "Enfermedad";
    if (item.CodTipoRegistro === "ASOH" || item.CodTipoRegistro === "ASOV") displayTipoPack = "Asociación";

    const espacioPrimero = (props.primero? {paddingTop: '7px'} : {})
    const espacioPrimeroPrecio = (props.primero? {paddingTop: '7px', whiteSpace: 'nowrap'} : {whiteSpace: 'nowrap'})

    const estiloNombre = {...espacioPrimero , whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '406px'}
    const estiloLaborat = {...espacioPrimero , whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '140px'}


    const mouseOverLink = (e) => {
        e.target.style.fontWeight='bold';
        e.target.style.fontFamily='Flama-Bold, sanserif';
        e.target.style.textDecoration='none';
    }
    const mouseOutLink = (e) => {
        e.target.style.fontWeight='normal';
        e.target.style.fontFamily='Flama-Basic, sanserif';
    }

    if (!props.comoPackList){
        return (
            <div class="item" style={estiloItem} > 
                <div class="product">
                    {(((props.comoGrid || isMobile) && item.ImagenEnvase) ?
                        <div class="mask"  style={{'padding':'0px', cursor:'pointer'}} onClick={() => vesAFicha(props.item)} >
                            <img src={item.ImagenEnvase.URL} alt={item.TipoRegistro} style={{maxHeight:'150px'}} />
                        </div>
                    :(props.comoGrid || isMobile) ?
                        <div class="mask" onClick={() => vesAFicha(props.item)} style={{cursor:'pointer'}}>
                            <img src={pictogramatipo} alt={item.TipoRegistro} style={{height:'72px'}}/>
                        </div>
                    :
                        <div class="mask" onClick={() => vesAFicha(props.item)} style={{cursor:'pointer'}}>
                            <img src={pictogramatipo} alt={item.TipoRegistro} />
                        </div>
                    )}
                    <div class="box box__text" >
                        <div class="title" >
                            <h3 class="fs fs17 blackColor" >
                                <span title={item.NombreCompleto}  onClick={() => vesAFicha(props.item)} style={{cursor:'pointer'}}>{item.NombreCompleto}</span>
                            </h3>
                        </div>
                        {
                            (item.NombreAnterior ?
                                <div class="text" >
                                    <p class="fs fs15 greyColor2 FlamaBook" >Nombre anterior: <span>{item.NombreAnterior}</span></p>
                                </div>
                            :
                                <span></span>
                            )
                        }
                        {
                            (props.mostrarHist && item.AuxInt>0 ?
                                <div class="text" >
                                    <p class="fs fs15 greyColor2 FlamaBook" >Laboratorio anterior: <span>{item.AuxStr}</span></p>
                                </div>
                            :props.mostrarHist && item.AuxDbl>0 && rol !== "EST" ?
                                <div class="text" >
                                    <p class="fs fs15 greyColor2 FlamaBook" >Precio anterior: <span>{numberWithCommas(item.AuxDbl)} €</span></p>
                                </div>
                            :
                                <span></span>
                            )
                        }
                        <div class="info" >
                            <p class="fs fs15 purpleColor tag" >{item.TipoRegistro}</p>
                            {
                                (problemaSuministro && rol !== "EST" ? 
                                    <p class="fs fs12 greyColor17 misc" ><img src={problemaSuministro.img} alt={problemaSuministro.leyenda} />{problemaSuministro.texto}</p>
                                :
                                    <span></span>
                                )    
                            }
                        </div>
                        {infoPacienteIndica}
                        {infoAuxStr}
                        {infoAuxStr2}
                        {infoListaAuxStr}
                        {infoAuxFecha}
                        {infoAuxFecha2}
                        {infoFechaAlta}
                        {infoFechaVisita}
                        {infoBorrarItem}
                        {infoFechaEntrada}
                        {infoAvisoPrecioMenor}
                        {infoAvisoPrecioMasBajo}                    
                    </div>
                </div>
                <div class="product__right" >
                    <div class="module module__top" >
                        {
                            (conPrecio.includes(item.CodTipoRegistro) && (precio > 0) && (rol != "EST") ?
                                <div class="precio" style={{width:'35%'}} title = {literalPrecio}>
                                    <p class="fs fs12 greyColor17 iva" >{literalPrecio}</p>
                                    {
                                        (((precio>999999.99) || (precio>9999.99 && props.comoGrid)) ?
                                            <span class="fs fs19 purpleColor precio__item" >{numberWithCommas(precio)}</span>
                                        : ((precio>9999.99) || (precio>999.99 && props.comoGrid)) ?
                                            <span class="fs fs21 purpleColor precio__item" >{numberWithCommas(precio)}</span>
                                        :
                                            <span class="fs fs23 purpleColor precio__item" >{numberWithCommas(precio)}</span>
                                        )
                                    }
                                    {
                                        (props.mostrarFuturo && item.AuxDbl>0)?
                                        <p style={{color:'#d9710a', fontWeight: '400', fontSize: '14px'}}>{numberWithCommas(item.AuxDbl)}</p>
                                        :
                                        <span></span>
                                    }
                                    <p class="fs fs12 greyColor17" >EUR</p>
                                </div>
                            :
                                <div class="precio" style={{border:'none', width:'35%'}}></div>
                            )
                        }
                        {
                            (conEstado.includes(item.CodTipoRegistro) ?
                                (conLaboratorio.includes(item.CodTipoRegistro) ?
                                    <div class="estado" style={{width:'65%'}}>
                                        <div class={claseEstado}>{item.EstadoAutorizacion}</div>
                                        <div class="estado__item estado__item__2">{item.Laboratorio}</div>
                                    </div>
                                :
                                    <div class="estado" style={{width:'65%'}}>
                                        <div class={claseEstado} style={{height:'100%'}}>{item.EstadoAutorizacion}</div>
                                    </div>
                                )
                            :
                                <span></span>
                            )
                        }
                    </div>
                    {
                        ((item.Codigo != '') && (item.Codigo != null) ?
                            <div class="module module__bottom" >
                                <span class="codigo codigo__title" >{literalCodigo}</span>
                                <div class="codigo codigo__number" >{item.Codigo}</div>
                            </div>
                        :
                            <span></span>
                        )   
                    }
                </div>
            </div> 
        );
    }else{
        return(
                <div style={{display: 'table-row', width: '100%', margin: '0px'}}>
                    <div style={{display: 'table-cell', textAlign: 'left', fontWeight: '300', verticalAlign:'middle', borderBottom: '1px solid #e8e8e8'}} title={item.TipoRegistro}>
                        <p class="fs fs12 purpleColor" style={espacioPrimero}>
                            <img src={pictogramatipo} style={{width:'15px', marginRight:'3px', marginTop:'3px', minHeight: '16px'}}/>
                        </p>
                    </div>
                    {
                        (problemaSuministro) ? 
                            <div style={{display: 'table-cell'}} title={problemaSuministro.leyenda}>
                                <p class="fs fs12 purpleColor" style={espacioPrimero}>
                                    <img src={problemaSuministro.img} style={{width:'20px', marginTop:'3px', marginLeft:'3px'}} />
                                </p>
                            </div>
                        :
                            <div></div>
                    }
                    <div style={{display: 'table-cell', textAlign: 'center', fontWeight: '300', verticalAlign:'middle', borderBottom: '1px solid #e8e8e8' }}>
                        <p class="fs fs12 greyColor2"  style={espacioPrimero} >
                            {
                                ((item.Codigo != '') && (item.Codigo != null) ?
                                    item.Codigo
                                :
                                    <span></span>
                                )   
                            }
                        </p>
                    </div>
                    <div style={{display: 'table-cell', textAlign: 'left', verticalAlign:'middle', borderBottom: '1px solid #e8e8e8'}}>
                        <p class="fs fs14 blackColor"  style={estiloNombre}>
                            <span title={item.NombreCompleto}  onClick={() => vesAFicha(props.item)} style={{cursor:'pointer'}}>{item.NombreCompleto}</span>
                        </p>
                    </div>
                    {(rol !== "EST") ?
                        <div style={{display: 'table-cell', textAlign: 'right', fontWeight:'300', verticalAlign:'middle', borderBottom: '1px solid #e8e8e8'}}>
                            <p class="fs fs12 purpleColor" style={espacioPrimeroPrecio} >
                                {(conPrecio.includes(item.CodTipoRegistro) && (precio > 0)) ?
                                    <span  title = {literalPrecio}>{numberWithCommas(precio)}&nbsp;€</span>
                                :
                                    <span></span>
                                }
                            </p>
                        </div>
                    :
                        <></>
                    }
                    <div style={{display: 'table-cell', textAlign: 'center', fontWeight:'300', verticalAlign:'middle', borderBottom: '1px solid #e8e8e8'}}>
                        <p class="fs fs12" style={estiloLaborat} >
                            {(conLaboratorio.includes(item.CodTipoRegistro)) ?
                                <span  title={item.Laboratorio}>{item.Laboratorio}</span>
                            : 
                                <span></span>
                            }
                        
                        </p>
                    </div>
                    <div style={{display: 'table-cell', textAlign: 'center', fontWeight:'300', verticalAlign:'middle', borderBottom: '1px solid #e8e8e8'}}>
                        <p class={claseTextoEstado} style={espacioPrimero} >
                            {(conEstado.includes(item.CodTipoRegistro)) ?
                                <span>{item.EstadoAutorizacion}</span>
                            : 
                                <span></span>
                            }
                        </p>
                    </div>
                </div>
        );
    }
}

export default FilaListaResBusqueda;

