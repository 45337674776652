import React, { useState, useEffect, createRef, useRef } from "react";
import CabeceraResultados from "../../Resultados/CabeceraResultados/CabeceraResultados";
import BP2022Service from "../../../services/user.service";
import InfoPrincipalMUH from "./InfoPrincipal/InfoPrincipalMUH.js";
import MenuIzquierda from "./MenuIzquierda/MenuIzquierda";
import ContenedorApartados from "./Apartados/ContenedorApartados";
import MargenDerecho from "./MargenDerecho/MargenDerecho";
import MensajesEmergentes from "./MensajesEmergentes";
import { AnadeApartado, baseURLPictogramas, compruebaAnclas, RemoveClassNameToClassItems, rootPath, AddClassNameToClassItems, getParam, anadeAncla, PerfilUsuario } from "../../../services/utils";
import Emergente from "./Apartados/Cismed/Emergente";
import LoadingPopup from "../../Resultados/Loading.Popup";
import Slider from "react-slick";
import {createBrowserHistory} from 'history';
import { seccionesFichaRestringidasEST } from "../../../services/objsAplicacion.js";


const FichaMUH = (props) => {
    const id = props.infoPagina.id;
    const [ficha, setFicha] = useState(null);
    const [anclas, setAnclas] = useState([]);
    const [anclasEpis, setAnclasEpis] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [imgIndex, setImgIndex] = useState(0);
    const [muestraAmpliada, setMuestraAmpliada] = useState(false);
    //const [sliderRef2, setSliderRef2] = useState(null)
    const sliderRef2 = useRef();
    const [idxSlider, setIdxSlider] = useState(0);
    const [muestraEmergentePrecios, setMuestraEmergentePrecios] = useState(false);
    const history = createBrowserHistory();
    const sEpigrafes = getParam(history.location.search, 'epigrafes');
    const epigrafesActivos = sEpigrafes ? sEpigrafes.split(",").map(Number) : [];
    const [seHaHechoScroll, SetSeHaHechoScroll] = useState(false);

    const sliderSettings2 = {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        speed: 300,
        arrows: true,
        touchMove: true,
        accessibility: true,
        adaptativeHeight: true,
        dots: false,
        focusOnSelect: true,
        className: 'single-item slide__2',
    }

    const clickImagen = (e,idx) => {
        if (idx || idx===0){
            setIdxSlider(idx);
        }
        else{
            setIdxSlider(0);
        }
        setMuestraAmpliada(true);
    }

    const clickCierra = (e) => {
        setMuestraAmpliada(false);
    }

    const loadImageGrande = (e) => {
        var h = e.target.height;
        var w = e.target.width;
        var maxHeightG = window.innerHeight * 0.65;
        var maxWidthG = window.innerWidth * 0.65;
        if (h>=maxHeightG){
            e.target.style.height = maxHeightG.toString() + 'px';
            e.target.style.width = 'auto';
            e.target.style.maxWidth = maxWidthG.toString() + 'px';
            e.target.style.maxHeight = maxHeightG.toString() + 'px';
        }
        else if (w>=maxWidthG){
            e.target.style.height = 'auto';
            e.target.style.width = maxWidthG.toString() + 'px';
            e.target.style.maxWidth = maxWidthG.toString() + 'px';
            e.target.style.maxHeight = maxHeightG.toString() + 'px';
        }
    }

    const estiloImagenFlotante = {verticalAlign:'middle',width:'70%',alignContent:'center',maxHeight:'70%'}
    
    if (ficha && ((ficha.CodTipoRegistro==="PAV")||(ficha.CodTipoRegistro==="PAH"))){
        estiloImagenFlotante.backgroundColor = '#FFFFFF'
    }

    const imgDefecto = (ficha) ? 
        ((ficha.CodTipoRegistro === "MUV") || (ficha.CodTipoRegistro==="PAV") || (ficha.CodTipoRegistro==="ASOV")) ? 
        rootPath + "img/perro.svg" : 
        ((ficha.CodTipoRegistro==="PPF") || (ficha.CodTipoRegistro==="ING")) ? 
        rootPath + "img/jabonLiquido.svg" : 
        ((ficha.CodTipoRegistro==="PAT") || (ficha.CodTipoRegistro==="PATH")) ? 
        rootPath + "img/patHumana.svg" : 
        (ficha.CodTipoRegistro==="PATV") ? 
        rootPath + "img/patVet.svg" : 
        rootPath + "img/person.svg" 
    :
        undefined
    ;

    const srcImagen = (ficha) ?
        ((ficha.ImagenEnvase) ?
            (ficha.ImagenEnvase.URLFull != null ?
                ficha.ImagenEnvase.URLFull
            : 
                ficha.ImagenEnvase.URL
            )
        :
            ((ficha.ImagenFormula) ?
                ficha.ImagenFormula.URL
            :
                imgDefecto
            )
        )
    :
        undefined
    ;

    const srcImagenFF = (ficha) ?
        ((ficha.CodTipoRegistro === "PPF") ?
            ((ficha.ImagenMatAcond) ?
                (ficha.ImagenMatAcond.URLFull != null ?
                    ficha.ImagenMatAcond.URLFull
                : 
                    ficha.ImagenMatAcond.URL
                )
            :
                ''
            )
        :
            ((ficha.ImagenFormaF) ?
                (ficha.ImagenFormaF.URLFull != null ?
                    ficha.ImagenFormaF.URLFull
                : 
                    ficha.ImagenFormaF.URL
                )
            :
                ''
            )
        )
    :
        undefined
    ;

    const tieneImagenes = (srcImagen && srcImagen !== '') || (srcImagenFF && srcImagenFF !== '');    
    const arrayImgs = [];
    if (srcImagen && srcImagen != '')
        arrayImgs.push(srcImagen);
    if (srcImagenFF && srcImagenFF != '')
        arrayImgs.push(srcImagenFF);

    const imagenesHTML = tieneImagenes ? arrayImgs.map(srcImg => <div><img class="imagen__medicamento" src={srcImg} alt={srcImg} onLoad={(e) => loadImageGrande(e)}/></div>)
                                        : <div><p>&nbsp;</p><img class="imagen__medicamento" src={imgDefecto} /></div>



    useEffect(() => {
        if (sliderRef2)
            if(sliderRef2.current)
                sliderRef2.current.slickGoTo(idxSlider);

        if ((ficha==null) || (ficha.Id !== id))
        {
            descargaFicha();
        }        
        else if (refresh==1){
            /*(ficha.CodTipoRegistro === "MHO") ?
                setRefresh(refresh+1)
            :*/
                descargaMensajesEmergentes(ficha.Id);            
        }
        else if (refresh==2){
            descargaCompos(ficha.Id, ficha.IdComposicionPor, ficha.IdFormaFarma);
        }
        else if (refresh==3){
            if (ficha.CodTipoRegistro === "MHO")
                setRefresh(refresh+1)
            else{
                var codGrupo = ficha.CodATCN5;
                var nivel = 5;
                if ((codGrupo === null)||(codGrupo===''))
                {
                    codGrupo = ficha.CodATCN4;
                    nivel = 4;
                }
                if ((codGrupo === null)||(codGrupo===''))
                {
                    codGrupo = ficha.CodATCN3;
                    nivel = 3;
                }
                descargaGrupos(ficha.Id, codGrupo, nivel, ficha.CodVMPP);
            }
        }
        else if (refresh==4){
            descargaTextos(ficha.Id);
        }
        else if (refresh==5){
            descargaAdvertencias(ficha.Id);
        }
        else if (refresh==6){
            (ficha.CodTipoRegistro === "MHO") ?
                setRefresh(10)
            :
                descargaConsejos(ficha.Id);
        }
        else if (refresh==7){
            (ficha.CodTipoRegistro === "MHO") ?
                setRefresh(10)
            :
                descargaPrecaucionExtrema(ficha.Id);
        }
        else if (refresh==8){
            descargaInterferencias(ficha.Id)
        }
        else if (refresh==9){
            descargaFarmacogenetica(ficha.Id)
        }
        else if (refresh==10){
            descargaDatosConservacion(ficha.Id)
        }
        else if (refresh==11){
            descargaHistorico(ficha.Id)
        }
        else if (refresh==12){
            (ficha.CodTipoRegistro === "MHO") ?
                setRefresh(refresh+1)
            :
                descargaIdentificacion(ficha.Id, ficha.IdFormaFarma)
        }
        else if (refresh==13){
            (ficha.CodTipoRegistro !== "MUH") ?
                setRefresh(refresh+1)
            :
                descargaNombresSimilares(ficha.Id)
        }
        else if (refresh==14){
            descargaAlertasCalidad(ficha.Id)
        }
        else if (refresh==15){
            (ficha.CodTipoRegistro === "MHO") ?
                setRefresh(17)
            :
                descargaProblemasSuministro(ficha.Id)
        }
        else if (refresh==16){
            if (ficha.IdEstadoAuto === 0)
                descargaDatosCismed(ficha.Id);
            else
                setRefresh(prev => prev + 1);;
        }
        else if (refresh==17){
            descargaInformacionAdicional(ficha.Id)
        }
        else if (refresh==18){
            descargaVideos(ficha.Id)
        }
        else if (refresh == 19){
        }
        if (epigrafesActivos && epigrafesActivos.length>0 && !seHaHechoScroll && anclasEpis && anclasEpis.length>0){
            var currentAncla;
            for (var i=0; i<epigrafesActivos.length && !currentAncla; i++){
                currentAncla = anclasEpis.find(ancla => ancla.idAncla === "Epi" + epigrafesActivos[i].toString())
            }
            if (currentAncla){
                const linkRef = currentAncla.refAncla;
                linkRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                SetSeHaHechoScroll(true);
            }            
        }
    })

    const getLeyendaPictoCismed = (eAnt, eAct) => {
        const eAux = eAnt * 10 + eAct;
        var leyenda = "";
        switch (eAux){
            case 33: 
                leyenda = "Medicamento sin incidencias por faltas de suministro";
                break;
            case 32:
                leyenda = "Medicamento sin incidencias por faltas de suministro la semana anterior. Actualmente en observación por faltas de suministro";
                break;
            case 31:
                leyenda = "Medicamento sin incidencias por faltas de suministro la semana anterior. Actualmente con problemas por falta de suministro";
                break;
            case 23:
                leyenda = "Medicamento en observación por faltas de suministro la semana anterior. Actualmente sin incidencias por faltas de suministro";
                break;
            case 22:
                leyenda = "Medicamento en observación por faltas de suministro";
                break;
            case 21:
                leyenda = "Medicamento en observación por faltas de suministro la semana anterior. Actualmente con problemas por falta de suministro";
                break;
            case 13:
                leyenda = "Medicamento con problemas por faltas de suministro la semana anterior. Actualmente sin incidencias por falta de suministro";
                break;
            case 12:
                leyenda = "Medicamento con problemas por faltas de suministrola semana anterior. Actualmente en observación por faltas de suministro";
                break;
            case 11:
                leyenda = "Medicamento con problemas por faltas de suministro";
                break;
        }
        return leyenda;
    }

    const anadeIconoCismed = (ficha) => {
        var url = '';
        var txt = '';
        var id = 9999;
        if (ficha.DatosCismed.DatosVisibles)
            if (ficha.DatosCismed.EstaAutorizado)
                if (ficha.DatosCismed.EstadoSemanaActual>0 && ficha.DatosCismed.EstadoSemanaAnterior>0){
                    url = baseURLPictogramas + "cismed" + ficha.DatosCismed.EstadoSemanaAnterior.toString() + ficha.DatosCismed.EstadoSemanaActual.toString() + ".jpg"
                    txt = getLeyendaPictoCismed(ficha.DatosCismed.EstadoSemanaAnterior, ficha.DatosCismed.EstadoSemanaActual);
                }
                else{
                    url = baseURLPictogramas + "cismed00.jpg"
                }
            else{
                url = baseURLPictogramas + "cismed00.jpg"
                txt = 'Este medicamento figura con información en CisMED. Para visualizar esta información debe estar adherido a CisMED.'
            }
        else
            id = 0;
    
        if (id>0)    
            ficha.Pictogramas.push({
                IdPictograma: id,
                Archivo : url,
                Texto : txt,
            })
        return;
    }
  
    const descargaFicha = () => {
        BP2022Service.getFichaMUH(id)
        .then((response) => {
            RemoveClassNameToClassItems('lightbox__popup',null,'active');
            var auxFicha = response.data;
            compruebaAnclas(auxFicha.Apartados,anclas);
            //auxFicha.CodTipoRegistro="MUH";
            auxFicha.TipoRegistro="Medicamento de uso humano"
            if (auxFicha.CodTipoRegistro === "MHO"){
                auxFicha.TipoRegistro="Medicamento homeopático de uso humano"
            }
            setFicha(auxFicha);
            setRefresh(1);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
    
    const descargaCompos = (miId, miIdCPor, miIdFF) => {
        BP2022Service.getComposM(miId,miIdCPor,miIdFF)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Composiciones){
                    if (response.data.Composiciones.length>0){
                        AnadeApartado({Titulo: "Composición",  Ancla:"COMP"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosComposicion = response.data;
                    }
                }
                setRefresh(prev => prev + 1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
    
    const descargaGrupos = (miId, miGTATC, nivelGTATC, miGTVMPP) => {
        BP2022Service.getGruposMUH (miId,miGTATC,nivelGTATC,miGTVMPP)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                AnadeApartado({Titulo: "Medicamentos con igual composición",  Ancla:"GRUP"},miFicha.Apartados,anclas);
                //checkAnclas(miFicha.Apartados);
                miFicha.DatosGrupos = response.data;
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    
    const descargaTextos = (miId) => {
        BP2022Service.getTextosMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Epigrafes){
                    if (response.data.Epigrafes.length>0){
                        AnadeApartado({Titulo: "Información farmacológica",  Ancla:"TEXT"},miFicha.Apartados,anclas);
                        for (var i=0; i<response.data.Epigrafes.length; i++){
                            anadeAncla("Epi"+response.data.Epigrafes[i].IdEpigrafe,anclasEpis);                            
                        }
                        miFicha.DatosTextos = response.data;
                    }
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
    
    const descargaAdvertencias = (miId) => {

        BP2022Service.getAdvertenciasMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Advertencias){
                    if (response.data.Advertencias.length>0){
                        AnadeApartado({Titulo: "Advertencias",  Ancla:"ADV"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosAdvertencias = response.data;
                    }
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
   
    const descargaConsejos = (miId) => {
        /*BP2022Service.getConsejosMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Advertencias){
                    if (response.data.Advertencias.length>0){
                        AnadeApartado({Titulo: "Consejos al paciente",  Ancla:"CONS"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosConsejos = response.data;
                    }
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });*/
        setRefresh(prev => prev +1);
    };
    
    const descargaPrecaucionExtrema = (miId) => {
        BP2022Service.getPrecaucionExtremaMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Advertencias){
                    if (response.data.Advertencias.length>0){
                        AnadeApartado({Titulo: "Precaución extrema de uso",  Ancla:"PEXT"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosPrecaucionExtrema = response.data;
                    }
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaInterferencias = (miId) => {
        BP2022Service.getInterferenciasMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Análisis clínicos",  Ancla:"ANA"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.Interferencias = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
 
    const descargaFarmacogenetica = (miId) => {
        BP2022Service.getFarmacogeneticaMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Farmacogenética",  Ancla:"FGEN"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.FarmacogeneticaComponentes = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaDatosConservacion = (miId) => {
        BP2022Service.getConservacionMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                var l = 0;
                if (response.data.DatosConservacion)
                    l += response.data.DatosConservacion.length;
                if (response.data.MensajesConservacion)
                    l += response.data.MensajesConservacion.length;
                if (l>0){
                    AnadeApartado({Titulo: "Conservación y periodos de validez",  Ancla:"CPV"},miFicha.Apartados,anclas);
                    miFicha.InfoConservacion = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaHistorico = (miId) => {
        if (PerfilUsuario()!="EST" || !seccionesFichaRestringidasEST.includes("HIST")){
            BP2022Service.getHistoricoMUH (miId)
                .then((response) => {
                    if (ficha != null){
                        var miFicha = ficha;
                        if (response.data.length>0){
                            AnadeApartado({Titulo: "Histórico",  Ancla:"HIST"},miFicha.Apartados,anclas);
                            //checkAnclas(miFicha.Apartados);
                            miFicha.DatosHistorico = response.data;
                        }
                        setRefresh(prev => prev +1);
                    }
                })
                .catch(function (error) {
                    console.log('Error', error.message);
                });
        }else{
            setRefresh(prev => prev + 1);
        }
            
    };

    const descargaIdentificacion = (miId, idFF) => {
        BP2022Service.getIdentificacionMUH (miId, idFF)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Titulos){
                    if (response.data.Titulos.length>0){
                        AnadeApartado({Titulo: "Identificación",  Ancla:"IDEN"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosIdentificacion = response.data;
                    }
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaNombresSimilares = (miId) => {
        BP2022Service.getNombresSimilaresMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Confusiones de nombre",  Ancla:"SIMIL"},miFicha.Apartados,anclas);
                    miFicha.NombresSimilares = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaAlertasCalidad = (miId) => {
        if (PerfilUsuario()!="EST" || !seccionesFichaRestringidasEST.includes("ALER")){
            BP2022Service.getAlertasCalidadMUH (miId)
            .then((response) => {
                if (ficha != null){
                    var miFicha = ficha;
                    if (response.data.Alertas){
                        if (response.data.Alertas.length>0){
                            AnadeApartado({Titulo: "Alertas de calidad",  Ancla:"ALER"},miFicha.Apartados,anclas);
                            //checkAnclas(miFicha.Apartados);
                            miFicha.DatosAlertas = response.data;
                        }
                    }
                    setRefresh(prev => prev +1);
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
        }else{
            setRefresh(prev => prev + 1);
        }
    };

    const descargaProblemasSuministro = (miId) => {
        if (PerfilUsuario()!="EST" || !seccionesFichaRestringidasEST.includes("PSUM")){
            BP2022Service.getProblemasSuministroMUH (miId)
            .then((response) => {
                if (ficha != null){
                    var miFicha = ficha;
                    if (response.data.Problemas){
                        if (response.data.Problemas.length>0){
                            AnadeApartado({Titulo: "Problemas de suministro",  Ancla:"PSUM"},miFicha.Apartados,anclas);
                            miFicha.DatosProblemasS = response.data;
                        }
                    }
                    setRefresh(prev => prev +1);
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
        }else{
            setRefresh(prev => prev + 1);
        }
    };

    const descargaDatosCismed = (miId) => {
        if (PerfilUsuario()!="EST" || !seccionesFichaRestringidasEST.includes("CMED")){
            BP2022Service.getCismedMUH (miId)
            .then((response) => {
                if (ficha != null){
                    var miFicha = ficha;
                    if (response.data.DatosVisibles){
                    /*if (!miFicha.Apartados.find(ap => ap.Ancla === "CMED")){
                            miFicha.Apartados.push({Titulo: "CisMED",  Ancla:"CMED"});
                            checkAnclas(miFicha.Apartados);*/
                            AnadeApartado({Titulo: "CisMED",  Ancla:"CMED"},miFicha.Apartados,anclas)
                            miFicha.DatosCismed = response.data;
                            anadeIconoCismed(miFicha);
                        //}
                    }
                    setRefresh(prev => prev +1);
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
        }else{
            setRefresh(prev => prev + 1);
        }
    };

    const descargaInformacionAdicional = (miId) => {
        BP2022Service.getInformacionAdicionalMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Información adicional",  Ancla:"DOCS"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.DatosInfoAdicional = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaVideos = (miId) => {
        BP2022Service.getVideosMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Documentos){
                    if (response.data.Documentos.length>0){
                        AnadeApartado({Titulo: "Vídeos",  Ancla:"VID"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosVideos = response.data;
                    }
                }
                props.descargaHistorial();
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };


    const descargaMensajesEmergentes = (miId) => {
        BP2022Service.getMensajesEmergentesMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    miFicha.MensajesEmergentes = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const cambiaImgIndex = (idx) => {
        setImgIndex(idx);
    };


    /*const checkAnclas = (aptdos) => {
        for (var i=0; i<aptdos.length; i++){
            const idAncla = aptdos[i].Ancla;
            if (!anclas.find(ancla => ancla.idAncla===idAncla))
            {
                const anclaNueva = createRef();
                anclas.push({idAncla: idAncla, refAncla : anclaNueva});
            }
        }
    }
    */

    const modalTitulo = "";
    const modalContent =  (ficha && ficha.InfoPrecio && ficha.InfoPrecio !== '') ? 
        <div>
            <p class="fs fs16 FlamaBook blackColor" style={{whiteSpace: 'pre-line', textAlign: 'justify',}} >
                {ficha.InfoPrecio}
            </p>
        </div>
    : 
        <div></div>
    ;

    const modalKeyName="ModalInfoPrecio"
    const abreEmergenteInfoPrecio = (e) => {
        /*e.preventDefault();
        AddClassNameToClassItems(modalKeyName, null, 'active');*/
        setMuestraEmergentePrecios(true);
    }

    const cierraEmergenteInfoPrecio = (e) => {
        setMuestraEmergentePrecios(false);
    }


    if ((ficha !== null) && (ficha.Id === id))
    {
        return (
            <div>
                <CabeceraResultados infoPagina={props.infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras}></CabeceraResultados>
                {(muestraAmpliada) ? 
                    <div className="medicamento-info"><div className="contenido__imagen"><div className="imagen">
                    <div class="imagen-light show" style={{cursor: 'default'}} >
                        <div class="btn__cerrar" onClick={(e) => clickCierra(e)}  style={{cursor: 'pointer'}}>
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="22.512" cy="22.512" r="22.012" stroke="white" />
                                <path d="M22.5793 12.0959V32.2559" stroke="white" stroke-width="2" stroke-linecap="square" />
                                <path d="M32.2562 22.5791H12.0962" stroke="white" stroke-width="2" stroke-linecap="square" />
                            </svg>
                        </div>
                        <div style={estiloImagenFlotante} id="ContenedorImagenFlotante">
                            <Slider ref={sliderRef2} {...sliderSettings2}>
                                {imagenesHTML}
                            </Slider>
                        </div>
                    </div>
                    </div></div></div>
                :
                    <></>
                }
                {(muestraEmergentePrecios) ?
                    <Emergente contenido={modalContent} keyName={modalKeyName} titulo={modalTitulo} cerrar = {cierraEmergenteInfoPrecio} activo={muestraEmergentePrecios}></Emergente>
                :
                    <></>
                }

                <InfoPrincipalMUH ficha={ficha} cambiaPagina={props.cambiaPagina} clickImagen = {clickImagen} abreEmergenteInfoPrecio={abreEmergenteInfoPrecio}></InfoPrincipalMUH>
                {
                    (ficha.MensajesEmergentes && ficha.MensajesEmergentes.length>0) ?
                        <MensajesEmergentes mensajes={ficha.MensajesEmergentes} id={ficha.Id}></MensajesEmergentes>
                    :
                        <div></div>
                }
                <section class="medicamento-infoGeneral">
                    <div class="container flex flex--justify-center">
                        <div class="contenido f-f-Flama-Book">
                            <MenuIzquierda apartados={ficha.Apartados} activo={ficha.Apartados[0].Ancla} anclas={anclas} cambiaImgIndex = {cambiaImgIndex}></MenuIzquierda>
                            <ContenedorApartados cambiaPagina={props.cambiaPagina} ficha={ficha} anclas={anclas}  anclasEpis = {anclasEpis}
                                                cambiaImgIndex = {cambiaImgIndex} 
                                                abreEmergenteInfoPrecio={abreEmergenteInfoPrecio}
                                                epigrafesActivos = {epigrafesActivos}
                            ></ContenedorApartados> 
                            <MargenDerecho cambiaPagina={props.cambiaPagina} ficha={ficha} imgIndex={imgIndex} clickImagen = {clickImagen}></MargenDerecho>

                        </div>
                    </div>
                </section>
            </div>
        )
    }
    else 
        return (
            <div>
                <LoadingPopup></LoadingPopup>
            </div>
        )
}
export default FichaMUH;
