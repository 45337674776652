import { PerfilUsuario } from "../../../services/utils";
import NovedadesInicio from "./Novedades/NovedadesInicio";
import ProblemasSuministroInicio from "./ProblemasSuministro/ProblemasSuministroInicio";

const NovedadesYProblemasSuministro = (props) => {
    var perfil = PerfilUsuario();
    //perfil = "CLI";
    var anchoNovedades = (perfil === "EST")? '100%' : '';
    return (
        <section class="section section__contentColumnHome" >
            <div class="container" >
                <NovedadesInicio cambiaPagina={props.cambiaPagina} ancho = {anchoNovedades}></NovedadesInicio>
                {(perfil!=="EST")?
                    <ProblemasSuministroInicio cambiaPagina={props.cambiaPagina}></ProblemasSuministroInicio>
                :
                    <></>
                }
            </div>
        </section> 
    );
};

export default NovedadesYProblemasSuministro;
