import React, { useEffect } from "react";

function Animation(props){
    var imgIndex = props.initIdx;
    var fileNameActual = props.path +'/' + props.filename + imgIndex.toString() + '.' + props.ext;
    const msTotal = props.t;
    const nImg = props.n;
    const interval = (nImg>1) ? msTotal/(nImg-1) : 0;
    var timer = null;
    
    useEffect(() => {
        timer = setInterval(() => {
          CambiaNombreImg();
        }, interval);
    },[props.refresh]);

    const CambiaNombreImg = () =>{
        if ((props.up && imgIndex<(nImg-1)) || (!props.up && imgIndex>0))
        {
            if (props.up)
                imgIndex++;
            else
                imgIndex--;
            var fin = ((props.up && imgIndex >= (nImg-1)) || (!props.up && imgIndex <= 0))
            if (imgIndex >= 0 || imgIndex<=(nImg-1)){
                if (fileNameActual != props.path +'/' + props.filename + imgIndex.toString() + '.' + props.ext){
                    fileNameActual = props.path +'/' + props.filename + imgIndex.toString() + '.' + props.ext;
                    document.getElementById(props.id).src = fileNameActual;
                }                
            }
            if (fin)
                if (timer != null)
                    clearInterval(timer);
        }
        else{ 
            clearInterval(timer);
        }
    }

    return(
        <img border="0" src={fileNameActual} id={props.id}></img>
    )
}

export default Animation;