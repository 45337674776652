import FilaTablaTipoDyF from "../FichaMUH/InfoPrincipal/FilaTablaTipoDyF";

const TablaInfoPrincipalPA = (props) => {
    const ficha = props.ficha;
    const valorPsicotropo = (ficha.Psicotropo === "Sí") ? 'Sí. ' + ficha.EstupefacientePsicotropo.DescripcionPsicotropo : ficha.Psicotropo;
    const valorDopaje = (ficha.Dopaje === "Sí") ? 'Sí. ' + ficha.DescripcionDopaje.DescripcionPsicotropo : ficha.Dopaje;
    var tabla = [
        {
            titulo:'Dopaje',
            valor: valorDopaje,
            valorPositivo: (ficha.Dopaje === "Sí") ? 'Sí. ' + ficha.DescripcionDopaje.DescripcionPsicotropo : 'Sí',
        },
        {
            titulo:'Estupefaciente / Psicotropo',
            valor: valorPsicotropo,
            valorPositivo: (ficha.Psicotropo === "Sí") ? 'Sí. ' + ficha.EstupefacientePsicotropo.DescripcionPsicotropo : 'Sí',
        },
    ]

    if (ficha.CodTipoRegistro === "ASOV" || ficha.CodTipoRegistro=== "PAV"){
        tabla = tabla.filter(item => item.valor !== "No");
    }

    const estiloValor = {width : '90%'};

    const tablaHTML = ((ficha.CodTipoRegistro === "PAH") ? 
                        tabla.map(fila => <FilaTablaTipoDyF fila = {fila} estiloValor = {estiloValor}></FilaTablaTipoDyF>)
                    : 
                        tabla.map(fila => <FilaTablaTipoDyF fila = {fila} ></FilaTablaTipoDyF>)
                    );
    if (tabla && tabla.length>0){                    
    return (
        <div class="dispensacion-facturacion">
            <h3 class="titulo">Características especiales</h3>
            <div class="facturacion">
                {tablaHTML}
            </div>
        </div>
    )
    }else{
        return(<></>);
    }

}
export default TablaInfoPrincipalPA;
