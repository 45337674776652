import { getIdCCAA, numberWithCommas, rootPath } from "../../../services/utils";
import AvisoPreciosFuturos from "../FichaMUH/InfoPrincipal/AvisoPreciosFuturos";

const PreciosPPF = (props) => {
    const ficha = props.ficha;
    const tienePreciosFuturos = (ficha.PFuturos !== null)
    const tienePMenorCCAA = ((ficha.AgrupacionCCAA && ficha.AgrupacionCCAA.PVPMenor>0) || (ficha.PFuturos && ficha.PFuturos.AgrupacionCCAA && ficha.PFuturos.AgrupacionCCAA.PVPMenor>0))
    const tienePMasBajoCCAA = ((ficha.AgrupacionCCAA && ficha.AgrupacionCCAA.PVPMasBajo>0) || (ficha.PFuturos && ficha.PFuturos.AgrupacionCCAA && ficha.PFuturos.AgrupacionCCAA.PVPMasBajo>0))
    const literalPMenor = (ficha.AgrupacionCCAA ? ficha.AgrupacionCCAA.LiteralPMenor:
        tienePreciosFuturos ? 
            (ficha.PFuturos.AgrupacionCCAA ? ficha.PFuturos.AgrupacionCCAA.LiteralPMenor : 'Precio menor'):
            'Precio menor'
    )
    const LiteralPMasBajo = (ficha.AgrupacionCCAA ? ficha.AgrupacionCCAA.LiteralPMasBajo:
        tienePreciosFuturos ? 
            (ficha.PFuturos.AgrupacionCCAA ? ficha.PFuturos.AgrupacionCCAA.LiteralPMasBajo : 'Precio más bajo'):
            'Precio más bajo'
    )

    const estiloPreciosAltos = {fontSize:'25px'}
    const estiloVacio = {}
    const estiloPrecio = (ficha.PVPIVA>999999 ? estiloPreciosAltos : estiloVacio)

    const ancho = window.innerWidth;
    const isMobile = (ancho<768);
    const estiloCuadroPrecios = (isMobile) ? {} : {maxWidth: '160px'}
    const estiloOtrosPrecios = (isMobile) ? {} : {maxWidth: '300px'}


    var idCCAA = getIdCCAA();
    var literalPVPIVA = (ficha.LiteralPrecio) ? ficha.LiteralPrecio : (idCCAA === 5 || idCCAA === 18 || idCCAA === 19) ? 'PVP sin IVA facturación': 'PVP con IVA facturación';

    var precioAMostrar = ((idCCAA === 5 || idCCAA === 18 || idCCAA === 19) ? ficha.PVPsinIVA: ficha.PVPIVA);
    if ((idCCAA===18) && (ficha.CodTipoRegistro === 'PPF') && (ficha.IdTipoPF===6))
        literalPVPIVA = "Precio Ceuta";
    if ((idCCAA===19) && (ficha.CodTipoRegistro === 'PPF') && (ficha.IdTipoPF===6))
        literalPVPIVA = "Precio Melilla";

    var preciofuturoAMostrar = 0;
    if (tienePreciosFuturos){
        preciofuturoAMostrar = ((idCCAA === 5 || idCCAA === 18 || idCCAA === 19) ? ficha.PFuturos.PVPsinIVA: ficha.PFuturos.PVPIVA);
    }
    
    return (
        <div class="precios f-f-Flama-Basic">
        <div class="precios__iva" style={estiloCuadroPrecios}>
            <p class="nombre" style={{textTransform: 'none', display: 'flex', alignItems: 'center'}}>
                {literalPVPIVA}
                { 
                    (ficha.InfoPrecio && ficha.InfoPrecio !== '') ?
                        <span style={{cursor:'pointer', marginLeft:'10px'}}  float="right" onClick={(e) => props.abreEmergenteInfoPrecio(e)}>
                            <img src={rootPath + "img/infoGreen.svg"} alt=""/>
                        </span>
                    :
                        <></>
                }
            </p>
            {(precioAMostrar>0) ?
                <p class="precio" style={estiloPrecio}>{numberWithCommas(precioAMostrar)}</p>
            :
                <p class="precio">-</p>
            }
            {(tienePreciosFuturos && preciofuturoAMostrar !== precioAMostrar ?
                (preciofuturoAMostrar>0) ?
                    <p class="sin_iva">{numberWithCommas(preciofuturoAMostrar)}</p>
                    :
                    <p class="precio"></p>
                :
                <span></span>                            
            )}
        <p class="moneda">EUR</p>
        </div>
        <div class="otros__precios">
            {(tienePreciosFuturos ?
                <AvisoPreciosFuturos fecha={ficha.PFuturos.Fecha}></AvisoPreciosFuturos>
            :
                <div></div>                            
            )}
            <div class="otros__precios--precios">
                <div class="precios__comunidad" style={estiloOtrosPrecios}>
                    <h3 class="titulo">{ficha.CCAA}</h3>
                    <div class="precios__comunidad--precios">
                        <div style={{maxWidth:'50%'}}>
                            <p class="nombre">{literalPMenor}</p>                                        
                            {(ficha.AgrupacionCCAA ?
                                (ficha.AgrupacionCCAA.PVPMenor>0 ?
                                    <p class="precio">{numberWithCommas(ficha.AgrupacionCCAA.PVPMenor)}</p>
                                : 
                                    <p class="precio">-</p>
                                )
                            :
                                <p class="precio">-</p>
                            )}
                            {(tienePreciosFuturos ?
                                (ficha.PFuturos.AgrupacionCCAA ?
                                    (ficha.PFuturos.AgrupacionCCAA.PVPMenor>0 && (!ficha.AgrupacionCCAA || ficha.PFuturos.AgrupacionCCAA.PVPMenor!==ficha.AgrupacionCCAA.PVPMenor) ?
                                        <p class="sin_iva">{numberWithCommas(ficha.PFuturos.AgrupacionCCAA.PVPMenor)}</p>
                                    : 
                                        <p class="sin_iva"></p>
                                    )
                                :
                                    <p class="sin_iva"></p>
                                )
                            :
                                <span></span>                            
                            )}
                            {(tienePMenorCCAA ?
                                <p class="moneda">EUR</p>
                            :
                                <span></span>
                            )}
                        </div>
                        <div style={{maxWidth:'50%'}}>
                            <p class="nombre">{LiteralPMasBajo}</p>
                            {(ficha.AgrupacionCCAA ?
                                (ficha.AgrupacionCCAA.PVPMasBajo>0 ?
                                    <p class="precio">{numberWithCommas(ficha.AgrupacionCCAA.PVPMasBajo)}</p>
                                : 
                                    <p class="precio">-</p>
                                )
                            :
                                <p class="precio">-</p>
                            )}
                            {(tienePreciosFuturos ?
                                (ficha.PFuturos.AgrupacionCCAA ?
                                    (ficha.PFuturos.AgrupacionCCAA.PVPMasBajo>0 && (!ficha.AgrupacionCCAA || ficha.PFuturos.AgrupacionCCAA.PVPMasBajo !== ficha.AgrupacionCCAA.PVPMasBajo) ?
                                        <p class="sin_iva">{numberWithCommas(ficha.PFuturos.AgrupacionCCAA.PVPMasBajo)}</p>
                                    : 
                                        <p class="sin_iva"></p>
                                    )
                                :
                                    <p class="sin_iva"></p>
                                )
                            :
                                <span></span>                            
                            )}
                            {(tienePMasBajoCCAA ?
                                <p class="moneda">EUR</p>
                            :
                                <span></span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)
}
export default PreciosPPF;
