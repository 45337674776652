import { rootPath } from "../../services/utils";

const LogoF = () => {
    return (
        <div class="box box__left" >
            <span class="fs fs14 fw fw300 whiteColor" >
                <img src={rootPath + "img/efe.svg"} alt="efe" /> Farmacéuticos<br />
            </span>
        </div>
    )
}
export default LogoF;
