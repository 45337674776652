import { rootPath } from "../../../services/utils";

const LinksFichaPA = (props) => {
    const ficha = props.ficha;

    const CambiaPaginaPorId = (pagina, id) => {
        props.cambiaPagina({
            newpagina: pagina,
            newid: id,
        });
    }

    var link = "BPAMUH";
    switch (ficha.CodTipoRegistro){
        case "PAH":
            link = "BPAMUH";
            break;
        case "PAV":
            link = "BPAMUV";
            break;
        case "ASO":
        case "ASOH":
            link = "BAsoMUH";
            break;
        case "ASOV":
            link = "BAsoMUV";
            break;
        case "ING":
            link = "BIngPPF";
            break;
    }

    const CambiaPaginaInteracciones = (pagina, id) => {
        var idInter = id;
        switch (ficha.CodTipoRegistro){
            case "PAH":
                idInter = id;
                break;
            case "ASO":
            case "ASOH":
                idInter = id + 1000000;
                break;
            case "ING":
                idInter = id + 2000000;
                break;
            }
            props.cambiaPagina({
            newpagina: pagina,
            idsSel: [idInter,],
        });
    }

    const mouseOverLink = (e) => {
        e.target.style.fontWeight='bold';
        e.target.style.textDecoration='none';
    }
    const mouseOutLink = (e) => {
        e.target.style.fontWeight='normal';
    }



    return (
        <div class="dispensacion-facturacion">
            <div class="extras">
                {(ficha.CodTipoRegistro === "PAH" || ficha.CodTipoRegistro === "ASO" || ficha.CodTipoRegistro === "ASOH" || ficha.Interactua) ?
                    <div class="elemento" onClick={() => CambiaPaginaInteracciones('BInteraccionesMUH',ficha.Id)} style={{cursor:'pointer'}}>
                        <div class="mask" >
                            <img src={rootPath + "img/ficha/arrowsGreen.svg"} />
                        </div>
                        <span style={{textDecoration:'none'}}
                                onMouseOver={(e) => mouseOverLink(e)}
                                onMouseOut={(e) => mouseOutLink(e)}
                        >Interacciones</span>
                    </div>
                :
                    <div></div>
                }
                {(ficha.CodTipoRegistro !== "ING") ?
                    <div class="elemento" onClick={() => CambiaPaginaPorId(link,ficha.Id)} style={{cursor:'pointer'}}>
                        <div class="mask" >
                            <img src={rootPath + "img/ficha/lupaVerde.svg"} />
                        </div>
                        <span style={{textDecoration:'none'}}
                                onMouseOver={(e) => mouseOverLink(e)}
                                onMouseOut={(e) => mouseOutLink(e)}
                        >Medicamentos</span>                    
                    </div>
                :
                    <div class="elemento" onClick={() => CambiaPaginaPorId(link,ficha.Id)} style={{cursor:'pointer'}}>
                        <div class="mask" >
                            <img src={rootPath + "img/ficha/lupaVerde.svg"} />
                        </div>
                        <span style={{textDecoration:'none'}}
                                onMouseOver={(e) => mouseOverLink(e)}
                                onMouseOut={(e) => mouseOutLink(e)}
                        >Productos</span>                    
                    </div>
                }
            </div>
        </div>
    )
}
export default LinksFichaPA;
