import { rootPath } from "../../../../../services/utils";
import Acordeon from "../Composicion/Acordeon";

const DocumentosDeTipo = (props) => {
    const grupoDocs = props.grupoDocs;
    const titulo = grupoDocs.Tipo;
    const documentos = grupoDocs.Documentos.map(doc => 
        <li>
            <a href={doc.URL} title={doc.Titulo} target="_blank" 
                onMouseOver={(e) => e.target.style.fontWeight='bold'}
                onMouseOut={(e) => e.target.style.fontWeight='normal'}            
            >{doc.Titulo} <img src={rootPath + "img/blank.svg"} /></a>
        </li>    
    );
    const contenido = (
        <ul class="list fs fs14 greenColor4 FlamaBook" >
            {documentos}
        </ul>
    );

    return (
        <Acordeon titulo = {titulo} contenido={contenido} activo={false} tipo={2}></Acordeon>
    )
}
export default DocumentosDeTipo;

