import {FormatoFechaDDMMYYYY} from '../../../../services/utils.js'

const ProblemaSuministro = (props) => {
    const ano = ( props.item.FechaFinPS ? props.item.FechaFinPS.substring(0,4) : '');
    const fecha = ( ano>1900 ? "Finalización prevista: " + FormatoFechaDDMMYYYY (props.item.FechaFinPS) : '');

    const CambiaPaginaFicha = (it) =>{
        props.cambiaPagina({
            newpagina: "Ficha"+it.CodTipoRegistro,
            newid: it.Id,
        });
    }


    return (
        <li title={props.item.NombreCompleto} onClick={() => CambiaPaginaFicha(props.item)} style={{cursor:'pointer'}}>
            <h3 class="fs fs16 blackColor" >
                {props.item.NombreCompleto}
            </h3>
            <p class="fs fs15 FlamaBook greyColor2" >
                {fecha}
            </p>
        </li>
    );
};

export default ProblemaSuministro;
