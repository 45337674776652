import { useEffect, useState } from "react";
import { getDatosFormulario } from "../../services/objsAplicacion";
import { getTipoFromCodigoPagina, getTituloFromCodigoPagina } from "../../services/utils";
import CabeceraFormulario from "./CabeceraFormulario/CabeceraFormulario";
import FBAlertasCalidad from "./Formularios/FBAlertasCalidad";
import FBAvanzadaMUH from "./Formularios/FBAvanzadaMUH";
import FBAvanzadaMUV from "./Formularios/FBAvanzadaMUV";
import FBAvanzadaPAH from "./Formularios/FBAvanzadaPAH";
import FBAvanzadaPPF from "./Formularios/FBAvanzadaPPF";
import FBComposicion from "./Formularios/FBComposicion";
import FBDocumentos from "./Formularios/FBDocumentos";
import FBEspecieIndicacion from "./Formularios/FBEspecieIndicacion";
import FBExtranjeros from "./Formularios/FBExtranjeros";
import FBFechas from "./Formularios/FBFechas";
import FBFormaColor from "./Formularios/FBFormaColor";
import FBPacienteIndicacion from "./Formularios/FBPacienteIndicacion";
import FBProductosIndicacion from "./Formularios/FBProductosIndicacion";
import FBProblemasSum from "./Formularios/FBProblemasSum";
import FBReaccionesAdversas from "./Formularios/FBReaccionesAdversas";
import FBTest from "./Formularios/FBTest";
import FBusquedaArbol from "./Formularios/FBusquedaArbol";
import FBusquedaArbolMultiple from "./Formularios/FBusquedaArbolMultiple";
import FBusquedaSaborEdad from "./Formularios/FBusquedaSaborEdad";
import FBusquedaSimple from "./Formularios/FBusquedaSimple";
import FBusquedaMultiple from "./Formularios/FBusquedaMultiple";
import IndiceFormularios from "./IndiceFormularios/IndiceFormularios";

const FormularioBusqueda = (props) => {
    const [infoPagina, setInfoPagina] = useState(props.infoPagina);
    const tipo = getTipoFromCodigoPagina(infoPagina.codigoPagina);
    var titulo = getTituloFromCodigoPagina(infoPagina.codigoPagina);

    if ((infoPagina.codigoPagina) === "FBAFMUV")
        titulo = "Principios activos veterinarios por actividad farmacológica"

    const datosFormulario = (infoPagina.seleccionados) ? getDatosFormulario(infoPagina.codigoPagina, infoPagina.seleccionados) : getDatosFormulario(infoPagina.codigoPagina, infoPagina.id);

    const formulario = (
        (infoPagina.codigoPagina === "FBAgrupHomoAutoMUH")
        || (infoPagina.codigoPagina === "FBAgrupHomoMUH")
        || (infoPagina.codigoPagina === "FBAgrupHomoAutoPPF")
        || (infoPagina.codigoPagina === "FBAgrupHomoPPF")
        || (infoPagina.codigoPagina === "FBAnalisisMUH")
        || (infoPagina.codigoPagina === "FBGTVMPP2MUH") 
        || (infoPagina.codigoPagina === "FBFarmacogeneticaMUH")        ?
            <FBusquedaSimple datosFormulario = {datosFormulario} cambiaPagina = {props.cambiaPagina}></FBusquedaSimple>
        :
        (infoPagina.codigoPagina === "FBLaboratorioPPF") 
        || (infoPagina.codigoPagina === "FBLaboratorioMUV")
        || (infoPagina.codigoPagina === "FBLaboratorioMUH") 
        || (infoPagina.codigoPagina === "FBFormaFarmaMUH")
        || (infoPagina.codigoPagina === "FBFormaFarmaMUV")
        || (infoPagina.codigoPagina === "FBFormaFarmaPPF")
        || (infoPagina.codigoPagina === "FBAFMUH")
        || (infoPagina.codigoPagina === "FBAMMUH")
        || (infoPagina.codigoPagina === "FBActPPF")
        || (infoPagina.codigoPagina === "FBAFMUV")
        ?
            <FBusquedaMultiple datosFormulario = {datosFormulario} cambiaPagina = {props.cambiaPagina}></FBusquedaMultiple>
        :
       (infoPagina.codigoPagina === "FBGTConPPF")             
        || (infoPagina.codigoPagina === "FBGTSanPPF")             
        || (infoPagina.codigoPagina === "FBGTVMPPMUH") 
        ?
            <FBusquedaArbol datosFormulario = {datosFormulario} cambiaPagina = {props.cambiaPagina}></FBusquedaArbol>
        :
        (infoPagina.codigoPagina === "FBGTATCMUV")             
        || (infoPagina.codigoPagina === "FBGTATCMUH")
        ?
            <FBusquedaArbolMultiple datosFormulario = {datosFormulario} cambiaPagina = {props.cambiaPagina}></FBusquedaArbolMultiple>
        /*:
        (infoPagina.codigoPagina === "FBGTVMPPMUHxxxx")             ?
            <FBusquedaArbolBack datosFormulario = {datosFormulario} cambiaPagina = {props.cambiaPagina}></FBusquedaArbolBack>*/
        : (infoPagina.codigoPagina === "FBSaborEdadPPF") ?
            <FBusquedaSaborEdad
                idDieto = {infoPagina.idDieto}
                idsSabores = {infoPagina.idsSabores}
                edad = {infoPagina.edad}
                undEdad = {infoPagina.undEdad}
                saborSolo = {infoPagina.saborSolo} 
                cambiaPagina = {props.cambiaPagina}   
            ></FBusquedaSaborEdad>
        : (infoPagina.codigoPagina === "FBEspecieIndicacionMUV") ?
            <FBEspecieIndicacion
                idEnf = {infoPagina.idEnf}
                idEspecie = {infoPagina.idEspecie}
                cambiaPagina = {props.cambiaPagina}   
            ></FBEspecieIndicacion>
        : (infoPagina.codigoPagina === "FBTest") ?
            <FBTest
                idA = {infoPagina.idA}
                idL1 = {infoPagina.idL1}
                idL2 = {infoPagina.idL2}
                idsL3 = {infoPagina.idsL3}
                snx = {infoPagina.snx}
                edad = {infoPagina.edad}
                unidad = {infoPagina.unidad}
                inicio = {infoPagina.inicio}
                fin = {infoPagina.fin}
                enfsRel = {infoPagina.enfsRel}
            ></FBTest>
        : (infoPagina.codigoPagina === "FBProblemasSumMUH") ?
            <FBProblemasSum
            txt = {infoPagina.txt}
            idLab = {infoPagina.idLab}
            idPA = {infoPagina.idPA}
            iniciod = {infoPagina.iniciod}
            inicioh = {infoPagina.inicioh}
            find = {infoPagina.find}
            finh = {infoPagina.finh}
            noSust = {infoPagina.noSust}
            medExt = {infoPagina.medExt}
            cambiaPagina = {props.cambiaPagina}   
            ></FBProblemasSum>
        : (infoPagina.codigoPagina === "FBAlertasCMUH") 
        ||  (infoPagina.codigoPagina === "FBAlertasCMUV") 
        ||  (infoPagina.codigoPagina === "FBAlertasCPPF") ?
            <FBAlertasCalidad
            txt = {infoPagina.txt}
            txtLote = {infoPagina.txtLote}
            idLab = {infoPagina.idLab}
            idPA = {infoPagina.idPA}
            inicio = {infoPagina.inicio}
            fin = {infoPagina.fin}
            cambiaPagina = {props.cambiaPagina}  
            tipo = {infoPagina.codigoPagina.substring(infoPagina.codigoPagina.length-1)} 
            ></FBAlertasCalidad>
        : 
        (infoPagina.codigoPagina === "FBCambioNombre")
        || (infoPagina.codigoPagina === "FBCambioLaboratorio")             
        || (infoPagina.codigoPagina === "FBCambioPrecioMUH")             
        || (infoPagina.codigoPagina === "FBDesfinanciadosMUH")             
        || (infoPagina.codigoPagina === "FBFechaAlta") ?
            <FBFechas
            inicio = {infoPagina.inicio}
            fin = {infoPagina.fin}
            codigoPagina = {infoPagina.codigoPagina}
            cambiaPagina = {props.cambiaPagina}   
            ></FBFechas>

        : (infoPagina.codigoPagina === "FBReaccionesAdversasMUH") ?
            <FBReaccionesAdversas
            idEpi = {infoPagina.idEpi}
            idsEnfs = {infoPagina.idsEnfs}
            idsMeds = {infoPagina.idsMeds}
            cambiaPagina = {props.cambiaPagina}   
            ></FBReaccionesAdversas>
        : ((infoPagina.codigoPagina === "FBComposicionMUH") 
        ||(infoPagina.codigoPagina === "FBComposicionMUV") 
        ||(infoPagina.codigoPagina === "FBComposicionPPF") 
        )?
            <FBComposicion
            tipo = {tipo}
            tipoProd = {infoPagina.tipoProd}
            pas = {infoPagina.pas}
            solo = {infoPagina.solo}
            idVia = {infoPagina.idVia}
            idFF = {infoPagina.idFF}
            uds = {infoPagina.uds}
            pv = {infoPagina.pv}
            ints = {infoPagina.ints}
            cambiaPagina = {props.cambiaPagina}   
            ></FBComposicion>
        : ((infoPagina.codigoPagina === "FBAvanzadaMUH") 
        )?
            <FBAvanzadaMUH
            txt = {infoPagina.txt}
            idsLab = {infoPagina.idsLab}
            idsGT = {infoPagina.idsGT}
            pas = {infoPagina.pas}
            solo = {infoPagina.solo}
            idVia = {infoPagina.idVia}
            idFF = {infoPagina.idFF}
            ints = {infoPagina.ints}
            uds = {infoPagina.uds}
            emr = {infoPagina.emr}
            edad = {infoPagina.edad}
            undEdad = {infoPagina.undEdad}
            ops = {infoPagina.ops}
            enfsRel = {infoPagina.enfsRel}
            anasRel = {infoPagina.anasRel}
            fechaAutoIni = {infoPagina.fechaAutoIni}
            fechaAutoFin = {infoPagina.fechaAutoFin}
            fechaBajaIni = {infoPagina.fechaBajaIni}
            fechaBajaFin = {infoPagina.fechaBajaFin}
            fechaComerIni = {infoPagina.fechaComerIni}
            fechaComerFin = {infoPagina.fechaComerFin}
            fechaInSNSIni = {infoPagina.fechaInSNSIni}
            fechaInSNSFin = {infoPagina.fechaInSNSFin}
            fechaExSNSIni = {infoPagina.fechaExSNSIni}
            fechaExSNSFin = {infoPagina.fechaExSNSFin}
            fechaExFIIni = {infoPagina.fechaExFIIni}
            fechaExFIFin = {infoPagina.fechaExFIFin}
            idCampoP = {infoPagina.idCampoP}
            idOpP = {infoPagina.idOpP}
            pre = {infoPagina.pre}
            idPredefP = {infoPagina.idPredefP}
            cambiaPagina = {props.cambiaPagina}   
            idsAA = {infoPagina.idsAA}
            idsAN = {infoPagina.idsAN}
            idsDC = {infoPagina.idsDC}
            idsMen = {infoPagina.idsMen}    
            texto = {infoPagina.texto}
            idEpi = {infoPagina.idEpi}
            noTexto = {infoPagina.noTexto}
            ></FBAvanzadaMUH>
        : ((infoPagina.codigoPagina === "FBAvanzadaMUV") 
        )?
            <FBAvanzadaMUV
            txt = {infoPagina.txt}
            idsLab = {infoPagina.idsLab}
            idsGT = {infoPagina.idsGT}
            idEnf = {infoPagina.idEnf}
            idEspecie = {infoPagina.idEspecie}
            pas = {infoPagina.pas}
            solo = {infoPagina.solo}
            idVia = {infoPagina.idVia}
            idFF = {infoPagina.idFF}
            ops = {infoPagina.ops}
            uds = {infoPagina.uds}
            fechaAutoIni = {infoPagina.fechaAutoIni}
            fechaAutoFin = {infoPagina.fechaAutoFin}
            fechaBajaIni = {infoPagina.fechaBajaIni}
            fechaBajaFin = {infoPagina.fechaBajaFin}
            idsMen = {infoPagina.idsMen}    
            texto = {infoPagina.texto}
            idEpi = {infoPagina.idEpi}
            noTexto = {infoPagina.noTexto}
            cambiaPagina = {props.cambiaPagina}   
            ></FBAvanzadaMUV>
        : ((infoPagina.codigoPagina === "FBAvanzadaPAH") 
        )?
            <FBAvanzadaPAH
            txt = {infoPagina.txt}
            idsAF = {infoPagina.idsAF}
            idsAM = {infoPagina.idsAM}
            idsGT = {infoPagina.idsGT}
            ops = {infoPagina.ops}
            enfsRel = {infoPagina.enfsRel}
            anasRel = {infoPagina.anasRel}
            idsMen = {infoPagina.idsMen}    
            texto = {infoPagina.texto}
            idEpi = {infoPagina.idEpi}
            noTexto = {infoPagina.noTexto}
            cambiaPagina = {props.cambiaPagina}   
            ></FBAvanzadaPAH>
        : ((infoPagina.codigoPagina === "FBAvanzadaPPF") 
        )?
            <FBAvanzadaPPF
            txt = {infoPagina.txt}
            idsLab = {infoPagina.idsLab}
            idsGT = {infoPagina.idsGT}
            idsTipo = {infoPagina.idsTipo}
            idsSubtipo = {infoPagina.idsSubtipo}
            pas = {infoPagina.pas}
            solo = {infoPagina.solo}
            idVia = {infoPagina.idVia}
            idFF = {infoPagina.idFF}
            ints = {infoPagina.ints}
            ops = {infoPagina.ops}
            uds = {infoPagina.uds}
            fechaAutoIni = {infoPagina.fechaAutoIni}
            fechaAutoFin = {infoPagina.fechaAutoFin}
            fechaBajaIni = {infoPagina.fechaBajaIni}
            fechaBajaFin = {infoPagina.fechaBajaFin}
            cambiaPagina = {props.cambiaPagina}   
            idsMen = {infoPagina.idsMen}    
            texto = {infoPagina.texto}
            noTexto = {infoPagina.noTexto}
            idEpi = {infoPagina.idEpi}
            ></FBAvanzadaPPF>
        : ((infoPagina.codigoPagina === "FBPacienteIndicacionMUH") 
        )?
            <FBPacienteIndicacion
            idEnf = {infoPagina.idEnf}
            idsPat = {infoPagina.idsPat}
            idsMed = {infoPagina.idsMed}
            idsAlg = {infoPagina.idsAlg}
            idVia = {infoPagina.idVia}
            idFF = {infoPagina.idFF}
            ints = {infoPagina.ints}
            edad = {infoPagina.edad}
            undEdad = {infoPagina.undEdad}
            ops = {infoPagina.ops}
            cambiaPagina = {props.cambiaPagina}   
            ></FBPacienteIndicacion>
        : ((infoPagina.codigoPagina === "FBProductosIndicacionPPF") 
        )?
            <FBProductosIndicacion
            idEnf = {infoPagina.idEnf}
            idFF = {infoPagina.idFF}
            pas = {infoPagina.pas}
            ints = {infoPagina.ints}
            ops = {infoPagina.ops}
            cambiaPagina = {props.cambiaPagina}   
            ></FBProductosIndicacion>
        : ((infoPagina.codigoPagina === "FBFormaColorMUH") 
        )?
            <FBFormaColor
            tipoFF = {infoPagina.tipoFF}
            idEnf = {infoPagina.idEnf}
            idForma = {infoPagina.idForma}
            idColor1 = {infoPagina.idColor1}
            idColor2 = {infoPagina.idColor2}
            tamanos = {infoPagina.tamanos}
            CO1 = {infoPagina.CO1}
            CO2 = {infoPagina.CO2}
            col2 = {infoPagina.col2}
            anycol = {infoPagina.anycol}
            ranura = {infoPagina.ranura}
            logo = {infoPagina.logo}
            difCol = {infoPagina.difCol}
            ins1 = {infoPagina.ins1}
            ins2 = {infoPagina.ins2}
            idSabor = {infoPagina.idSabor}
            pas = {infoPagina.pas}
            solo = {infoPagina.solo}
            cambiaPagina = {props.cambiaPagina}   
            ></FBFormaColor>
        : ((infoPagina.codigoPagina === "FBExtranjerosEXT") 
        )?
            <FBExtranjeros
            txt = {infoPagina.txt}
            idPais = {infoPagina.idPais}
            pas = {infoPagina.pas}
            solo = {infoPagina.solo}
            cambiaPagina = {props.cambiaPagina}   
            ></FBExtranjeros>
        : (infoPagina.codigoPagina === "FBDocumentos") ?
            <FBDocumentos
            idMed = {infoPagina.idMed}
            inicio = {infoPagina.inicio}
            fin = {infoPagina.fin}
            cambiaPagina = {props.cambiaPagina}   
            ></FBDocumentos>
        :
            <div></div>
    );

    useEffect(() => {
        setInfoPagina(props.infoPagina);
    },[props.infoPagina]);

    const cambiaTab = (codTab) =>{
        if (infoPagina.codigoPagina=== "FBComposicionPPF"){
            const nuevaInfoPagina = JSON.parse(JSON.stringify(infoPagina));
            nuevaInfoPagina.tipoProd = codTab;
            setInfoPagina(nuevaInfoPagina);
        }else if (infoPagina.codigoPagina === "FBFormaColorMUH"){
            const nuevaInfoPagina = JSON.parse(JSON.stringify(infoPagina));
            nuevaInfoPagina.tipoFF = codTab;
            setInfoPagina(nuevaInfoPagina);
        }
    }


    const tabsComposPF = [
        { 
            cod: 2, 
            texto: "Dermofarmacia",
            clase: (infoPagina.tipoProd === 2) ? "active" : "",
        },
        {
            cod: 1, 
            texto: "Otros productos",
            clase: (infoPagina.tipoProd === 1) ? "active" : "",
        },
    ]

    const tiposFF = [
        { 
            cod: 'CO', 
            texto: "Comprimidos",
            clase: (infoPagina.tipoFF === 'CO') ? "active" : "",
        },
        {
            cod: 'CA', 
            texto: "Cápsulas",
            clase: (infoPagina.tipoFF === 'CA') ? "active" : "",
        },
        { 
            cod: 'JA', 
            texto: "Jarabes / Soluciones orales",
            clase: (infoPagina.tipoFF === 'JA') ? "active" : "",
        },
    ]


    const HTMLTabs = (
        (infoPagina.codigoPagina === "FBComposicionPPF") ?
            <div class="section section__tabs" >
                <div class="container" >
                    <ul class="tabs" >
                        {tabsComposPF.map(t => <li class={t.clase} onClick={(e) => cambiaTab(t.cod)}>{t.texto}</li>)}
                    </ul>
                </div>
            </div>
        :
        (infoPagina.codigoPagina === "FBFormaColorMUH") ?
            <div class="section section__tabs" >
                <div class="container" >
                    <ul class="tabs" >
                        {tiposFF.map(t => <li class={t.clase} onClick={(e) => cambiaTab(t.cod)}>{t.texto}</li>)}
                    </ul>
                </div>
            </div>
        :
            <div></div>
        );


    return ( 
        <section class="section section__buscador section__buscador__busqueda" >
            <CabeceraFormulario tipo={tipo} titulo={titulo} infoPagina = {infoPagina} cambiaPagina = {props.cambiaPagina}></CabeceraFormulario>
            {HTMLTabs}
            {(infoPagina.codigoPagina === "FBAvanzadaMUH") 
            ||(infoPagina.codigoPagina === "FBAvanzadaMUV") 
            ||(infoPagina.codigoPagina === "FBAvanzadaPAH") 
            ||(infoPagina.codigoPagina === "FBAvanzadaPPF") 
            ||(infoPagina.codigoPagina === "FBExtranjerosEXT") 
            ||(infoPagina.codigoPagina === "FBCambioNombre") 
            ||(infoPagina.codigoPagina === "FBCambioLaboratorio") 
            ||(infoPagina.codigoPagina === "FBCambioPrecio") 
            ||(infoPagina.codigoPagina === "FBDesfinanciados") 
            ||(infoPagina.codigoPagina === "FBFechaAlta") 
            ||(infoPagina.codigoPagina === "FBDocumentos") 
            ?
                <div class="container container__column" >
                    {formulario}
                </div>
            :
                <div class="container" >
                    <IndiceFormularios tipo={tipo} codigoPagina={infoPagina.codigoPagina} cambiaPagina={props.cambiaPagina}></IndiceFormularios>
                    {formulario}
                </div>
            }
        </section>
    );
};

export default FormularioBusqueda;
    
/*
            <FBLaboratorio infoPagina={infoPagina} tipo={tipo} cambiaPagina = {props.cambiaPagina}></FBLaboratorio>
*/
