import { rootPath } from "../../../../services/utils";

const ItemTablaDF = (props) => {
    const item = props.item;
    const claseDiv = item.valor ? "bloque green" : "bloque";
    const srcImgTooltip = item.valor ? rootPath + "img/checkGreen.svg" : rootPath + "img/checkKo.svg" ;
    return (
        <div class={claseDiv}>{item.sigla}
        <div class="tooltip" >
            <div class="mask mask__auto" >
                <img src={srcImgTooltip} alt="" />
            </div>
            <div class="text" >
                <p>{item.texto}</p>
            </div>
        </div>
    </div>
)
}
export default ItemTablaDF;
