import { AddClassNameToClassItems, numberWithCommas } from "../../../../../services/utils";
import Emergente from "./Emergente";
import GraficaCismed from "./GraficaCismed";
import SemaforosCismed from "./SemaforosCismed";
import TablaFParticipantes from "./TablaFParticipantes";

const CismedKO = (props) => {
    const yesterday = new Date(new Date().getTime() - 24*60*60*1000);
    const datosCismed = {
        NFarmaciasNacional : numberWithCommas(props.datosCismed.NFarmaciasNacional),
        HayConexion : props.datosCismed.HayConexion,
        EstaAutorizado : false,
        DatosVisibles : true,
        CCAA : props.datosCismed.CCAA,
        Provincia : props.datosCismed.Provincia,
        NFarmaciasCCAA : numberWithCommas(Math.round(props.datosCismed.NFarmaciasNacional / 10)),
        NFarmaciasProvincia : numberWithCommas(Math.round(props.datosCismed.NFarmaciasNacional / 40)),
        FechaConsolidacion : yesterday,
        PorcentajeNacional : 18.25,
        PorcentajeCCAA: 21.36,
        PorcentajeProvincia: 14.45,
        EstadoSemanaAnterior: 2,
        EstadoSemanaActual: 2,
        EsInicioSemana: false,
        NoConsolidado: false,
        CumpleCriterioDia: true,
        CumpleCriterioSemanaPasada: true,
        CumpleCriterioSemanaActual: true,
    }
    const modalTitulo = "Información CisMED";
    const modalContent = (
        <div>
            <p><big>El acceso a la información de CisMED en BOT PLUS es exclusivo para
                    farmacias adheridas a CisMED.</big></p>
            <p><big>CisMED es un sistema de información que permite a las farmacias
                    comunicar faltas de suministro de medicamentos.</big></p>
            <p><big>Actualmente {datosCismed.NFarmaciasNacional} farmacias participan en CisMED.</big></p>
            <p><big>En caso de que su farmacia esté adherida a CisMED, incluya el número de
                    farmacia dado en la adhesión a CisMED en la pantalla de configuración de
                    BOT PLUS para acceder a esta información.</big></p>
            <p><big>Si su farmacia no está adherida, puede solicitar la adhesión a CisMED a través
                    del <a href="https://adhesioncismed.nodofarma.es/Home/Login" target="_blank" style={{color:"#5c9029"}}>siguiente enlace</a>. 
                    </big></p>
        </div>
    );

    const modalKeyName="ModalCismedNo"
    const AbreEmergenteCismed = () => {
        AddClassNameToClassItems(modalKeyName, null, 'active');
    }

    return (
        <div class="infoContent" >
            <Emergente contenido={modalContent} keyName={modalKeyName} titulo={modalTitulo}></Emergente>
            <p> Este medicamento figura con información CisMED. Para visualizar esta información debe de estar adherido a CisMED.</p>
            <div  onClick={(e) => AbreEmergenteCismed()} style={{color:'transparent', textShadow: '0 0 10px rgba(0,0,0,0.5)'}}>
                <TablaFParticipantes datosCismed={datosCismed} abreEmergenteCismed={AbreEmergenteCismed} inactivo={true}></TablaFParticipantes>
                <GraficaCismed datosCismed={datosCismed} inactivo={true}></GraficaCismed>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <SemaforosCismed datosCismed={datosCismed} inactivo={true}></SemaforosCismed>
            </div>
        </div>
    )
}
export default CismedKO;