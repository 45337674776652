import Video from "./Video";

const Videos = (props) => {
    const datosVideos = props.datosVideos;
    const htmlVideos = datosVideos.Documentos.map(video => <Video video={video} abierto={(datosVideos.Documentos.indexOf(video)===0)}></Video>)
    return (
        <div class="infoContent" >
            {htmlVideos}
        </div>
    )
}
export default Videos;

