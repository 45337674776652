import DocumentosDeTipo from "../InfoAdicional/DocumentosDeTipo";
import AlertaCalidad from "./AlertaCalidad";

const AlertasCalidad = (props) => {
    const datosAlertas = props.datosAlertas;
    const htmlAlertas = datosAlertas.Alertas.map(alerta => <AlertaCalidad alerta={alerta}  codTipoRegistro={props.codTipoRegistro}></AlertaCalidad>)
    const htmlInfoAdicional = datosAlertas.InfoAdicional.map(grupodocs => <DocumentosDeTipo grupoDocs={grupodocs} ></DocumentosDeTipo>)
    return (
        <div class="infoContent" >
            {htmlAlertas}
            {(datosAlertas.InfoAdicional && datosAlertas.InfoAdicional.length>0) ?
                <div class="cover__subTitle">
                    <h2 class="fs fs16 fw fw600 greenColor">Otros documentos relacionados</h2>
                </div>
            :
                <span></span>
            }
            {htmlInfoAdicional}
        </div>
    )
}
export default AlertasCalidad;

