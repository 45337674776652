import RamaArbol3 from "./RamaArbol3";

function ItemRamaArbol3(props){

    const item = props.item;
    const abierto = item.abierto;
    const hijos = item.hijos;
    const nombre = item.valor;
    const codigo = item.codigo;
    const seleccionado = item.seleccionado;
    const tieneHijos = item.tieneHijos;

    const accion = (abierto ? props.cierraRama :props.abreRama)

    var clasesDiv = "accordion__mas";
    var clasesSubdiv = "text titulo__rama__arbol";
    if (abierto) clasesDiv += " active";
    if (!tieneHijos)
        clasesDiv += " accordion__mas__only";

    if (seleccionado) clasesSubdiv += " active2";

    const estilodisabled = (item.disabled) ? {backgroundColor: '#D6D2D2', border: '1px solid #d6d2d2'} : {}
    const estilodisabledtexto = (item.disabled) ? {color: '#D6D2D2'} : {}

    const miHTML = (
        <div class={clasesDiv}>
            <div class="accordion__mas__header" onClick={(e) =>  accion(codigo)}>
                <div class={clasesSubdiv}>
                    <input type="checkbox" class="check__rama__arbol" value={codigo} checked={seleccionado} disabled={item.disabled} style={estilodisabled} onClick={(e) => props.cambiaSeleccion(e.target.checked,codigo,nombre)}/>
                    <label class="title fs fs16 greyColor2 uppercase" style={estilodisabledtexto}>
                        {(props.muestraCodigo) ? <span>{codigo}&nbsp;</span> : <></>}
                        {nombre}
                    </label>
                </div>
            </div>
            <div class="accordion__mas__content" style={{display: 'block', paddingTop: '2px', paddingBottom: '0px'}}>
                {(abierto)?
                    <RamaArbol3 arbol={hijos} abreRama = {props.abreRama} cierraRama = {props.cierraRama} cambiaSeleccion = {props.cambiaSeleccion} muestraCodigo = {props.muestraCodigo}
                    ></RamaArbol3>
                :
                    <></>
                }
            </div>
        </div>    
    );

    return (
        <div> 
            {miHTML}
        </div>
    )
}
export default ItemRamaArbol3;
