import React, { useState, useEffect } from "react";
import { rootPath } from "../../../services/utils";
import ItemContenidoHistorial from "./ItemContenidoHistorial";

const ZonaContenidoHistorial = (props) => {
    const listaItems = props.historial

    const CambiaPaginaSimple = (codigoPagina) => {
        props.cambiaPagina({
            newpagina: codigoPagina
        });
    }

    const miHistorial = listaItems ? (
        listaItems.map(fila => <ItemContenidoHistorial item={fila} cambiaPagina={props.cambiaPagina}></ItemContenidoHistorial>)
      ):(
        <div></div>
      );
    
    return (
        <div class="historial cover__70" >
            <div class="historial__content historial__content__1" >
                <div class="module module__title" >
                    <h3 class="title" >Historial de navegación</h3>
                </div>
                <div class="module module__list" >
                    {miHistorial}
                </div>
                <div class="module module__buttons">
                    <span class="button button__arrow button__purple" title="Ver más" style={{cursor:'pointer'}} onClick={(e) => CambiaPaginaSimple('BHistorialUsuario')} >
                        Ver más <img src={rootPath + "img/arrowRightWhite.svg"} alt="arrowRightWhite" />
                    </span>
                </div>
            </div>
        </div>
    )
}
export default ZonaContenidoHistorial;
