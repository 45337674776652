import ItemMenuPrincipalGris from "./ItemMenuPrincipalGris";

const MenuPrincipalGris = (props) => {
    const itemsMenu =  props.opcionesMenu.map(item => <ItemMenuPrincipalGris  item={item} cambiaPagina = {props.cambiaPagina}></ItemMenuPrincipalGris>)

    return (
        <ul class="list list__grey" >
            {itemsMenu}
        </ul>
    )
}
export default MenuPrincipalGris;
