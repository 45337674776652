import {FormatoFechaBonito, rootPath} from '../../../../services/utils.js'

const NovedadResto = (props) => {

    const picto = ( props.item.CodTipoRegistro === "MUV" ? rootPath + "img/novedad2.svg" : (props.item.CodTipoRegistro === "PPF" ? rootPath + "img/novedad3.svg" : rootPath + "img/novedad1.svg"));
    const fecha = ( props.item.FechaAlta ? FormatoFechaBonito(props.item.FechaAlta) : '');

    const CambiaPaginaFicha = (it) =>{
        props.cambiaPagina({
            newpagina: "Ficha"+it.CodTipoRegistro,
            newid: it.Id,
        });
    }

    
    return (
        <li title={props.item.NombreCompleto} onClick={() => CambiaPaginaFicha(props.item)} style={{cursor:'pointer'}}>
            <div class="mask" style = {{width : '76px' }} >
                <img src={picto}/>
            </div>
            <div class="text" style={{ width: 'calc(100% - 96px)'}}>
                <span class="fs fs12 greenColor" >{fecha}</span>
                <h3 class="fs fs18 blackColor" >
                    {props.item.NombreCompleto}
                </h3>
                <p class="fs fs15 FlamaBook greyColor2" >
                    {props.item.TipoRegistro}
                </p>
            </div>
        </li>
    );
};

export default NovedadResto;
