import FilaTablaTipoDyF from "../FichaMUH/InfoPrincipal/FilaTablaTipoDyF";

const TablaInfoPrincipalPAT = (props) => {
    const ficha = props.ficha;
    const tabla = [
        {
            titulo:'Especialidad',
            valor: ficha.EspecialidadMedica,
            valorPositivo: 'Sí',
        },
    ]
    const estiloValor = {width : '90%'};
    const tablaHTML = tabla.map(fila => <FilaTablaTipoDyF fila = {fila}  estiloValor = {estiloValor}></FilaTablaTipoDyF>)

    return (
        <div class="dispensacion-facturacion">
            <h3 class="titulo">Características generales</h3>
            <div class="facturacion" style={{borderBottom:'none'}}>
                {tablaHTML}
            </div>
        </div>
    )
}
export default TablaInfoPrincipalPAT;
