import React, { useState, useEffect, createRef } from "react";
import Apartado from "./Apartado";

const ContenedorApartados = (props) => {
    const [refresh, setRefresh] = useState(0);
    const anclas = props.anclas;
    const apartadosHTML = 
        props.ficha.Apartados.map(aptdo => <Apartado ancla={anclas.find(ancla => ancla.idAncla===aptdo.Ancla)} 
                                                    anclasEpis={props.anclasEpis}
                                                    apartado={aptdo} ficha={props.ficha} 
                                                    cambiaPagina={props.cambiaPagina} cambiaImgIndex = {props.cambiaImgIndex}
                                                    abreEmergenteInfoPrecio={props.abreEmergenteInfoPrecio}
                                                    epigrafesActivos = {props.epigrafesActivos}
                                            ></Apartado>);

    useEffect(() => {
        setRefresh(prev => prev +1);
    }, [props.ficha.Apartados])
  
    return (
        <div class="infoGeneral"> 
            {apartadosHTML}
        </div> 
    )
}
export default ContenedorApartados;

