import { FormatoFechaDDMMYYYY } from "../../../../../services/utils";
import DocumentosDeTipo from "../InfoAdicional/DocumentosDeTipo";
import BloqueNombreValor from "../InfoGeneral/BloqueNombreValor";
import TablaLotes from "./TablaLotes";

const AlertaCalidad = (props) => {
    const alerta = props.alerta;
    const bloqueAlerta = [
        {
            nombre : 'Nº Alerta',
            valor: alerta.NumAlerta,
        },
        {
            nombre : 'Fecha',
            valor: FormatoFechaDDMMYYYY(alerta.FechaAlerta),
        },
        {
            nombre : 'Clasificación del defecto',
            valor: alerta.Tipo,
        },
        {
            nombre : 'Descripción del defecto',
            valor: alerta.Observaciones,
        },
        {
            nombre : 'Medidas cautelares',
            valor: alerta.Descripcion,
        },
    ]


    if (alerta.Todos)
        bloqueAlerta.push({
            nombre : 'Lotes afectados',
            valor : 'Todos',
        });
    
    if (props.codTipoRegistro === "MUV")
        bloqueAlerta.splice(2,1);

    const lotes = ((alerta.LotesAfectados && alerta.LotesAfectados.length>0) ?
        <TablaLotes lotes={alerta.LotesAfectados}></TablaLotes>
    :
        <span></span>
    );

    const htmlInfoAdicional = ((alerta.InfoAdicional && alerta.InfoAdicional.length>0) ? alerta.InfoAdicional.map(grupodocs => <div><div style={{height:"40px"}}></div><DocumentosDeTipo grupoDocs={grupodocs}></DocumentosDeTipo></div>) : <div></div>);


    return (
        <div>
            <div class="module module__infoColumns module__infoColumns__noBorder" > 
                <BloqueNombreValor bloque={bloqueAlerta} 
                                   claseBloque={"columns columns__2"} 
                                   claseFila={"row fs fs14 FlamaBook"}
                                   claseCol={"column"}
                                   filasNumericas ={[0,1]}
                 ></BloqueNombreValor>
                 {lotes}
                 {htmlInfoAdicional}
            </div>
        </div>
    )
}
export default AlertaCalidad;

/*
<div class="module module__infoColumns module__infoColumns__noBorder" > 
    <div class="columns columns__2" >
        <div class="row fs fs14 FlamaBook" >
            <div class="column" >
                <p>Nº Alerta</p>
            </div>
            <div class="column number" >
                <p>R_17/2021/</p>
            </div>
        </div>
        <div class="row fs fs14 FlamaBook" >
            <div class="column" >
                <p>Fecha alerta</p>
            </div>
            <div class="column number" >
                <p>03/09/2021</p>
            </div>
        </div>
        <div class="row fs fs14 FlamaBook" >
            <div class="column" >
                <p>Descripción del defecto</p>
            </div>
            <div class="column" >
                <p>Posible obtención de un resultado fuera de especificaciones en el ensayo de disolución antes de finalizar su plazo de validez.</p>
            </div>
        </div>
        <div class="row fs fs14 FlamaBook" >
            <div class="column" >
                <p>Clasificación del defecto</p>
            </div>
            <div class="column" >
                <p>CLASE 2</p>
            </div>
        </div>
        <div class="row fs fs14 FlamaBook" >
            <div class="column" >
                <p>Medidas cautelares</p>
            </div>
            <div class="column" >
                <p>Retirada del mercado de todas las unidades distribuidas de los lotes afectados y devolución al laboratorio por los cauces habituales.</p>
            </div>
        </div>
    </div>
</div> 
<div class="cover cover__table" >
    <h4 class="fs fs16 fw fw800 greyColor2 title">Lotes afectados</h4>
    <div class="table">
        <div class="table__row table__row__header table__row__header__noBg fs fs12 fw fw600 greenColor uppercase">
            <div class="column">
                <p>Nº de lote</p>
            </div>
            <div class="column">
                <p>Fecha de caducidad lote</p>
            </div>
        </div>
        <div class="table__row table__row__normal fw fw300 uppercase">
            <div class="column fs fs14 blackColor FlamaBook number">
                <p>V08540A</p>
            </div>
            <div class="column fs fs14 blackColor FlamaBook number">
                <p>31/03/2022</p>
            </div>
        </div>
        <div class="table__row table__row__normal fw fw300 uppercase">
            <div class="column fs fs14 blackColor FlamaBook number">
                <p>W06660B</p>
            </div>
            <div class="column fs fs14 blackColor FlamaBook number">
                <p>28/02/2023</p>
            </div>
        </div>
        <div class="table__row table__row__normal fw fw300 uppercase">
            <div class="column fs fs14 blackColor FlamaBook number">
                <p>W16689A</p>
            </div>
            <div class="column fs fs14 blackColor FlamaBook number">
                <p>31/07/2023</p>
            </div>
        </div>
    </div>
</div>
*/