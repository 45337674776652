import { useEffect, useState } from "react";
import BP2022Service from "../../services/user.service";
import { getCurrentURL, getParam, getParamsURL, rootPath } from "../../services/utils";
import SelectorComboBox from "../Utilidades/Selector/SelectorComboBox";
import Emergente from "../Fichas/FichaMUH/Apartados/Cismed/Emergente";
import AddEmpresa from "./AddEmpresa";

const RecuperaPassword0 = (props) => {

    const [mensaje, setMensaje] = useState('');
    const [usuario, setUsuario] = useState('');
    const [dirCorreo, setDirCorreo] = useState('');
    const [alto, setAlto] = useState('230px');
    const [estado, setEstado] = useState(0);

    const siguiente = () =>{
        if (estado === 0)
            compruebaUsuario();
        else if (estado === 2)
            enviaCorreoUsuario();        
        else if (estado === 3)
            props.alCerrar();        
    }

    const compruebaUsuario = () =>{
        if (usuario != ""){
            BP2022Service.checkUser(usuario)
            .then((response) => {
                if (response.data && response.data.Ok){
                    setEstado(2);
                    setDirCorreo(response.data.DirCorreo);
                    setMensaje('');
                }
                else{
                    setEstado(1);
                    setMensaje(response.data.Mensaje)
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
                setEstado(1);
                setMensaje(error.message);
            });
        }
        else{
            setMensaje('Debe indicar un nombre de usuario');
        }
    }

    const enviaCorreoUsuario = () =>{
        if (usuario != ""){
            BP2022Service.enviaCorreoRecPass(usuario)
            .then((response) => {
                if (response.data && response.data.Ok){
                    setEstado(3);
                    setMensaje('Se ha enviado un correo electrónico para realizar el cambio de contraseña. Consulte su buzón y siga las instrucciones');
                }
                else{
                    setEstado(2);
                    setMensaje(response.data.Mensaje)
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
                setEstado(2);
                setMensaje(error.message);
            });
        }
        else{
            setMensaje('Debe indicar un nombre de usuario');
        }
    }

    useEffect (() =>{
    },[]);


    const CambiaUsuario = (s) => {
        setMensaje('');
        setUsuario(s);
    };

    var newalto;
    var txtBoton = "Siguiente";
    var titulo = "Recuperación de contraseña";
    switch (estado){
        case 0:
            newalto = '300px';
            break;
        case 1:
            newalto = '230px';
            break;
        case 2:
            newalto = '500px';
            break;
        case 3:
            newalto = '230px';
            txtBoton = 'Cerrar';
            break;
    }
    if (alto != newalto)
        setAlto(newalto);
            
    const contenido = 
        <form class="content__results content__results__filters" id="main-filter" onSubmit={(e) => e.preventDefault()} autocomplete="off" >
            {(estado === 0) ?
                <div className="text" style={{width:'100%', paddingBottom:'6px', fontSize:'16px', lineHeight:'20px', verticalAlign:'baseline'}}  >
                    {(mensaje) && (mensaje !== "") ? 
                        <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '6px', color:'#F05341'}}>{mensaje}</p>
                    :
                        <></>                
                    }
                    <p className="fs fs18 fw fw100 FlamaBook greyColor"
                        style={{marginBottom:'30px'}}>
                            Nombre de usuario:
                            <div class="coverModule">
                                <div class="module module__filters">
                                    <div class="box box__inputs">
                                        <div class="cover cover__input">
                                        <input type="text" name="RPUser" value={usuario} disabled = {estado !== 0} 
                                                onChange={ e => CambiaUsuario(e.target.value)} 
                                                placeholder="Introduzca el nombre de usuario" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </p>
                </div>
            :(estado == 1) ?
                <div className="text" style={{width:'100%', paddingBottom:'6px', fontSize:'16px', lineHeight:'20px', verticalAlign:'baseline'}}  >
                    {(mensaje) && (mensaje !== "") ? 
                        <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '6px', color:'#F05341'}}>{mensaje}</p>
                    :
                        <></>                
                    }
                    <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'justify', marginTop: '24px'}}>
                        Para continuar con el proceso puede <a target="_blank" href="http://farmaceuticos.com/contacto/" className="greenColor">contactar con el Consejo General</a>.
                    </p>
                </div>
            :(estado == 2) ?
                <div className="text" style={{width:'100%', paddingBottom:'6px', fontSize:'16px', lineHeight:'20px', verticalAlign:'baseline'}}  >
                    <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'justify', marginTop: '6px'}}>
                        Le enviaremos un correo electrónico a la cuenta de correo que tiene configurada en BOT PLUS, en el que le indicaremos las instrucciones
                        a seguir para recuperar la contraseña.
                    </p>
                    <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'justify', marginTop: '10px'}}>
                        En el caso de que no llegase dicho correo, compruebe que no estuviera en la carpeta de correo no deseado.
                    </p>
                    <p className="fs fs18 fw fw100 FlamaBook greyColor"
                        style={{marginBottom:'30px'}}>
                            Este correo se enviará a la siguiente dirección:
                            <div class="coverModule">
                                <div class="module module__filters">
                                    <div class="box box__inputs">
                                        <div class="cover cover__input">
                                        <input type="text" name="RPCorreo" value={dirCorreo} disabled = {true} style={{color:'#C0C0C0'}} 
                                                placeholder="Dirección de correo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </p>
                    <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'justify', marginTop: '0px'}}>
                        Si tuviera cualquier incidencia, póngase en <a target="_blank"  className="greenColor" href="http://farmaceuticos.com/contacto/">contacto con el Consejo General</a>.
                    </p>
                </div>
            :(estado === 3) ?            
                <div className="text" style={{width:'100%', paddingBottom:'6px', fontSize:'16px', lineHeight:'20px', verticalAlign:'baseline'}}  >
                    {(mensaje) && (mensaje !== "") ? 
                        <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'justify', marginTop: '6px'}}>{mensaje}</p>
                    :
                        <></>                
                    }
                </div>
            :
                <></>
            }
            {((estado === 0) || (estado === 2) || (estado === 3))?
                <div className="cover cover__buttons" style={{textAlign: 'right', paddingTop: '10px'}} >
                        <button className="button button__purple" onClick={(e) => siguiente()} style={{height:'40px', paddingTop:'12px'}}> {txtBoton}</button>
                </div>
            :
                <></>
            }
        </form>


    return(
        <Emergente 
            contenido={contenido} 
            keyName={"cambioPass"} 
            titulo={titulo} 
            activo={true} 
            ocultaAspa = {false}
            alto = {alto}
            ancho = {'680px'}
            cerrar={props.alCerrar}
        ></Emergente>
    );
}

export default RecuperaPassword0;
