import React, { useState, useEffect } from "react";
import {createBrowserHistory} from 'history';
import AuthService from "../services/auth.service";
  

import Cabecera from "./Cabecera/Cabecera";
import Cuerpo from "./Cuerpo";
import Pie from "./Pie";
import Licencia from "./Licencia";
import {RemoveClassNameToClassItems, AddClassNameToClassItems, rootPath, deepEqual, getParam, getCurrentURL
    , getParamsURL, getPathURL, getIdProvincia, getStringProvincia, fromParmaURLToListaPAsCompos, fromListaPAsComposToParamURL
    , fromListaOpsAvanzadaToParamURL, fromParamURLToListaOpsAvanzada, GetFiltrosStr, PerfilUsuario, 
    elementIsOfClass} from '../services/utils.js'
import BP2022Service from "./../services/user.service";
import Configuracion from "./Configuarcion/Configuracion";
import Emergente from "./Fichas/FichaMUH/Apartados/Cismed/Emergente";
import { validaAccesoPorRol } from "../services/objsAplicacion";
import AccesoRestringido from "./Configuarcion/AccesoRestringido";


const Contenido = (props) => {
    const [infoPagina, setInfoPagina] = useState({
                                            codigoPagina:'Inicio',
                                            tipoPagina:'Inicio',
                                            listaRes: null,
                                            texto: '',
                                            id:0, 
                                            fuerzaTexto : true,
                                        })
    const [isTopWindow, setIsTopWindow] = useState(window.pageYOffset === 0);
    const [historial, setHistorial] = useState([]);
    const [provincia, setProvincia] = useState(props.provincia);

    const history = createBrowserHistory();

    const [refresh, setRefresh] = useState(1);
    const [lastVisitedPath, setLastVisitedPath] = useState('');
    const [haAceptadoLicencia, setHaAceptadoLicencia] = useState(true);
    const [licenciaAAceptar, setLicenciaAAceptar] = useState('');

    const tiempoCheck = 60 * 60 * 1000 

    const [accesoRestringido, setAccesoRestringido] = useState(false);
    const [cambiopass, setCambiopass] = useState(props.cambiopass);
    const [cambiomail, setCambiomail] = useState(props.cambiomail);
    const [confirmamail, setConfirmamail] = useState(props.confirmamail);

    var myTimeout;

    const BorraCambioMailPass = () =>{
        setCambiomail(false);
        setCambiopass(false);
        setConfirmamail(false);
    }

    function ChequeaSesion() {
        props.checkSesion();
        myTimeout = setTimeout(ChequeaSesion, tiempoCheck);
    }

    useEffect(() => {
        if (myTimeout)
            clearTimeout(myTimeout);
        myTimeout = setTimeout(ChequeaSesion, tiempoCheck);
        construyeInfoPaginaFromPath();
        window.removeEventListener('scroll', AlHacerScroll);
        window.removeEventListener('popstate', onBackButtonEvent);  
        window.addEventListener('scroll', AlHacerScroll, { passive: true });
        window.addEventListener('popstate', onBackButtonEvent);
        RefrescaProvincia(getStringProvincia());
        const user = AuthService.getLocalUser();
        if (user && user.botPlusUser){
            if (!user.botPlusUser.HaAceptadoLicencia){
                setLicenciaAAceptar(user.botPlusUser.LicenciaAAceptar);
                setHaAceptadoLicencia(false);
                window.removeEventListener('scroll', AlHacerScroll);
                window.removeEventListener('popstate', onBackButtonEvent);  
            }
        }
        return () => {
            window.removeEventListener('scroll', AlHacerScroll);
            window.removeEventListener('popstate', onBackButtonEvent);  
        }
    },  [refresh]);

    const borraDeLista = (tipo,id) =>{
        if (infoPagina.listaRes){
            var pos = infoPagina.listaRes.findIndex(item => item.CodTipoRegistro === tipo && item.Id === id);
            if (pos>=0){
                var infoAux = JSON.parse(JSON.stringify(infoPagina));
                infoAux.listaRes.splice(pos,1);
                setInfoPagina(infoAux);
            }
        }
    }

    const RefrescaProvincia = (prov) =>{
        if (provincia !== prov)
            setProvincia(prov);
    }

    const ClickAceptaLicencia = () =>{
        AuthService.aceptaLicencia();
        const user = AuthService.getLocalUser();
        if (user && user.botPlusUser){
            user.botPlusUser.HaAceptadoLicencia = true;
            localStorage.setItem("login", JSON.stringify(user));
        }
        setHaAceptadoLicencia(true);
    }

    const DescargaHistorial = () => {
        BP2022Service.getHistorial(8)
      .then((response) => {
        setHistorial(response.data.Resultados);
      })
      .catch(function (error) {
        console.log('Error', error.message);
      });
    };


    const ParseaFiltros = (strFiltros) => {
        var arrayFiltros = strFiltros.split('|');
        var res =[];
        for (var j=0; j<arrayFiltros.length; j++){
            var strFiltro = arrayFiltros[j];
            var arrayFiltro = strFiltro.split('__');
            if (arrayFiltro.length == 2){
                res.push({nombre: arrayFiltro[0], valor: arrayFiltro[1]});
            }            
        }
        return res;
    }

    const validaAcceso = (pagina) => {
        var rol = PerfilUsuario();
        var res = validaAccesoPorRol(pagina, rol);
        //var res = true;
        /*if (rol === "COL"){
            if (paginasRestringidas.includes(pagina)){
                res = false;
            }
        }else if (rol === "EST"){
            if (paginasRestringidasEST.includes(pagina)){
                res = false;
            }
        }*/
        return res;
    }

    
    const construyeInfoPaginaFromPath = () => {
        var newInfoPagina = {
            codigoPagina:'Inicio',
            tipoPagina:'Inicio',
            listaRes: null,
            texto: '',
            fuerzaTexto : false,
        };
        var ruta = getCurrentURL();
        if (ruta.length>=rootPath.length)
            ruta = ruta.substring(rootPath.length);
        //console.log(ruta);
        if (ruta.length>0){
            var params = getParamsURL(ruta);
            ruta = getPathURL(ruta);
            var partes = ruta.split('/');
            if (accesoRestringido !== validaAcceso(partes[0]))
                setAccesoRestringido(validaAcceso(partes[0]));
            switch (partes[0].toLowerCase()){
                case "ayuda":
                case "help":
                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'Ayuda',
                    }
                break;
                case "exportaws":
                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'Exporta',
                    }
                break;
                case "fichamuh":
                case "fichamuv":
                case "fichafm":
                case "fichappf":
                case "fichapah":
                case "fichapav":
                case "fichaaso":
                case "fichaasoh":
                case "fichaasov":
                case "fichaing":
                case "fichamho":                    
                case "fichapho":                    
                case "fichapat":
                case "fichapath":
                case "fichapatv":
                case "fichaext":
                        newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'Ficha',
                        listaRes: null,
                        texto: '',
                        id: parseInt(partes[1]), 
                        fuerzaTexto : false,
                    }
                break;
                case "fbagruphomoautomuh":
                case "fbagruphomomuh":
                case "fbagruphomoautoppf":
                case "fbagruphomoppf":
                case "fbfarmacogeneticamuh":
                case "fbanalisismuh":
                        var id = getParam(params,'id');
                        if (!id)
                            id = 0;
                        newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'FormularioBusqueda',
                        id: id,
                    }
                break;
                case "fblaboratoriomuv":
                case "fblaboratorioppf":
                case "fblaboratoriomuh":
                case "fbformafarmamuh":
                case "fbformafarmamuv":
                case "fbformafarmappf":
                case "fbafmuh":
                case "fbammuh":
                case "fbactppf":
                case "fbafmuv":
                        var auxIds = getParam(params,'seleccionados');
                        var seleccionados = (auxIds!=='') ? auxIds.split(',') : [];
                        seleccionados = (seleccionados) && (seleccionados.length>0) ? seleccionados.map(id=> parseInt(id))  : [];                    
                        newInfoPagina = {
                            codigoPagina:partes[0],
                            tipoPagina:'FormularioBusqueda',
                            seleccionados: seleccionados,
                        }
                break;
                case "fbsaboredadppf":
                case "bsaboredadppf":
                    var idDieto  = getParam(params,'idDieto');
                    var auxIdsSabores = getParam(params,'idsSabores');
                    var idsSabores = (auxIdsSabores!=='') ? auxIdsSabores.split(',') : [];
                    var edad = getParam(params, 'edad');
                    var undEdad = getParam(params, 'undEdad');
                    var saborSolo = getParam(params, 'saborSolo');
                    idDieto   = (idDieto)   ? idDieto   : '';
                    idsSabores = (idsSabores) && (idsSabores.length>0) ? idsSabores.map(id=> parseInt(id))  : [];
                    edad     = (edad)     ? parseInt(edad)     : null;
                    undEdad  = (undEdad) ? undEdad : '';
                    saborSolo = (saborSolo==='S');
                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'FormularioBusqueda',
                        idDieto: idDieto,
                        idsSabores: idsSabores,
                        edad: edad,
                        undEdad: undEdad,
                        saborSolo: saborSolo,
                    }
                    if (newInfoPagina.codigoPagina.toLowerCase().substring(0,1)==="b"){
                        //Es la búsqueda
                        var filtros = getParam(params, 'filtro');
                        if (filtros)
                            var misFiltros = ParseaFiltros(filtros);
                        var snumMostrar = getParam(params, 'numMostrar');
                        var sidxOrden = getParam(params, 'idxOrden');
                        newInfoPagina.tipoPagina = 'ResultadosBusqueda';
                        newInfoPagina.numMostrar = (snumMostrar) ? parseInt(snumMostrar) : 10;
                        newInfoPagina.idxOrden = (sidxOrden) ? parseInt(sidxOrden) : 0
                        newInfoPagina.filtrosActivos = misFiltros;
                    }
                break;
                case "fbespecieindicacionmuv":
                case "bespecieindicacionmuv":
                    var idEspecie  = getParam(params,'idEspecie');
                    var idEnf = getParam(params,'idEnf');
                    idEspecie   = (idEspecie)   ?  parseInt(idEspecie)   : 0;
                    idEnf   = (idEnf)   ?  parseInt(idEnf)   : 0;
                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'FormularioBusqueda',
                        idEspecie: idEspecie,
                        idEnf: idEnf,
                    }
                    if (newInfoPagina.codigoPagina.toLowerCase().substring(0,1)==="b"){
                        //Es la búsqueda
                        var filtros = getParam(params, 'filtro');
                        if (filtros)
                            var misFiltros = ParseaFiltros(filtros);
                        var snumMostrar = getParam(params, 'numMostrar');
                        var sidxOrden = getParam(params, 'idxOrden');
                        newInfoPagina.tipoPagina = 'ResultadosBusqueda';
                        newInfoPagina.numMostrar = (snumMostrar) ? parseInt(snumMostrar) : 10;
                        newInfoPagina.idxOrden = (sidxOrden) ? parseInt(sidxOrden) : 0
                        newInfoPagina.filtrosActivos = misFiltros;
                    }
                break;
                case "fbproblemassummuh":
                case "bproblemassummuh":
                    var txt  = getParam(params,'med');
                    var idLab = getParam(params,'idLab');
                    var idPA = getParam(params,'idPA');
                    var noSust = getParam(params, 'noSust');
                    var medExt = getParam(params, 'medExt');
                    var iniciod = getParam(params, 'iniciod');
                    var inicioh = getParam(params, 'inicioh');
                    var find = getParam(params, 'find');
                    var finh = getParam(params, 'finh');
                    txt = (txt) ? decodeURI(txt) : '';
                    idLab  = (idLab)  ? parseInt(idLab)  : 0;
                    idPA  = (idPA)  ? parseInt(idPA)  : 0;
                    noSust = (noSust==='S');
                    medExt = (medExt==='S');
                    iniciod = (iniciod) ? new Date(parseInt(iniciod)*1000) : null;
                    inicioh = (inicioh) ? new Date(parseInt(inicioh)*1000) : null;
                    find = (find) ? new Date(parseInt(find)*1000) : null;
                    finh = (finh) ? new Date(parseInt(finh)*1000) : null;

                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'FormularioBusqueda',
                        txt: txt,
                        idLab: idLab,
                        idPA: idPA,
                        noSust: noSust,
                        medExt: medExt,
                        iniciod: iniciod,
                        inicioh: inicioh,
                        find: find,
                        finh: finh,
                    }
                    if (newInfoPagina.codigoPagina.toLowerCase().substring(0,1)==="b"){
                        //Es la búsqueda
                        var filtros = getParam(params, 'filtro');
                        if (filtros)
                            var misFiltros = ParseaFiltros(filtros);
                        var snumMostrar = getParam(params, 'numMostrar');
                        var sidxOrden = getParam(params, 'idxOrden');
                        newInfoPagina.tipoPagina = 'ResultadosBusqueda';
                        newInfoPagina.numMostrar = (snumMostrar) ? parseInt(snumMostrar) : 10;
                        newInfoPagina.idxOrden = (sidxOrden) ? parseInt(sidxOrden) : 0
                        newInfoPagina.filtrosActivos = misFiltros;
                    }
                break;
                case "fbalertascmuh":
                case "balertascmuh":
                case "fbalertascmuv":
                case "balertascmuv":
                case "fbalertascppf":
                case "balertascppf":
                    var txt  = getParam(params,'med');
                    var txtLote  = getParam(params,'txtLote');
                    var idLab = getParam(params,'idLab');
                    var idPA = getParam(params,'idPA');
                    var inicio = getParam(params, 'inicio');
                    var fin = getParam(params, 'fin');
                    txt = (txt) ? decodeURI(txt) : '';
                    txtLote = (txtLote) ? decodeURI(txtLote) : '';
                    idLab  = (idLab)  ? parseInt(idLab)  : 0;
                    idPA  = (idPA)  ? parseInt(idPA)  : 0;
                    inicio = (inicio) ? new Date(parseInt(inicio)*1000) : null;
                    fin = (fin) ? new Date(parseInt(fin)*1000) : null;

                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'FormularioBusqueda',
                        txt: txt,
                        txtLote: txtLote,
                        idLab: idLab,
                        idPA: idPA,
                        inicio: inicio,
                        fin: fin,
                    }
                    if (newInfoPagina.codigoPagina.toLowerCase().substring(0,1)==="b"){
                        //Es la búsqueda
                        var filtros = getParam(params, 'filtro');
                        if (filtros)
                            var misFiltros = ParseaFiltros(filtros);
                        var snumMostrar = getParam(params, 'numMostrar');
                        var sidxOrden = getParam(params, 'idxOrden');
                        newInfoPagina.tipoPagina = 'ResultadosBusqueda';
                        newInfoPagina.numMostrar = (snumMostrar) ? parseInt(snumMostrar) : 10;
                        newInfoPagina.idxOrden = (sidxOrden) ? parseInt(sidxOrden) : 0
                        newInfoPagina.filtrosActivos = misFiltros;
                    }
                break;
                case "fbcambionombre":
                case "bcambionombre":
                case "fbcambiolaboratorio":
                case "bcambiolaboratorio":
                case "fbcambiopreciomuh":
                case "bcambiopreciomuh":
                case "fbdesfinanciadosmuh":
                case "bdesfinanciadosmuh":
                case "fbfechaalta":
                case "bfechaalta":
                case "bnovedadesultimomes":
                case "bmedicamentospsultimomes":
                case "bhistorialusuario":
                    var inicio = getParam(params, 'inicio');
                    var fin = getParam(params, 'fin');
                    inicio = (inicio) ? new Date(parseInt(inicio)*1000) : null;
                    fin = (fin) ? new Date(parseInt(fin)*1000) : null;
                    if (partes[0].toLowerCase() === "bnovedadesultimomes"){
                        inicio = new Date();
                        inicio.setMonth(inicio.getMonth() - 1);
                        fin = null;
                    }
                    if (partes[0].toLowerCase() === "bmedicamentospsultimomes"){
                        inicio = new Date();
                        inicio.setMonth(inicio.getMonth() - 1);
                        fin = null;
                    }


                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'FormularioBusqueda',
                        inicio: inicio,
                        fin: fin,
                    }
                    if (newInfoPagina.codigoPagina.toLowerCase().substring(0,1)==="b"){
                        //Es la búsqueda
                        var filtros = getParam(params, 'filtro');
                        if (filtros)
                            var misFiltros = ParseaFiltros(filtros);
                        var snumMostrar = getParam(params, 'numMostrar');
                        var sidxOrden = getParam(params, 'idxOrden');
                        newInfoPagina.tipoPagina = 'ResultadosBusqueda';
                        newInfoPagina.numMostrar = (snumMostrar) ? parseInt(snumMostrar) : 10;
                        newInfoPagina.idxOrden = (sidxOrden) ? parseInt(sidxOrden) : (partes[0].toLowerCase() === "bhistorialusuario") ? 6 : 0;
                        newInfoPagina.filtrosActivos = misFiltros;
                    }
                break;
                case "fbextranjerosext":
                case "bextranjerosext":
                    var id = getParam(params,'id');
                    id  = (id)  ? parseInt(id)  : 0;
                    var txt  = getParam(params,'med');
                    txt = (txt) ? decodeURI(txt) : '';
                    var idPais = getParam(params,'idPais');
                    idPais  = (idPais)  ? parseInt(idPais)  : 0;
                    var auxPas = getParam(params,'pas');
                    var pas= (auxPas!=='') ? auxPas.split(',') : [];
                    pas = (pas) && (pas.length>0) ? pas.map(id=> parseInt(id))  : [];                    
                    var solo = getParam(params, 'solo');
                    solo = (solo ? (solo === "S") : true );

                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'FormularioBusqueda',
                        id:id,
                        txt: txt,
                        idPais: idPais,
                        solo: solo,
                        pas: pas,
                    }
                    if (newInfoPagina.codigoPagina.toLowerCase().substring(0,1)==="b"){
                        //Es la búsqueda
                        var filtros = getParam(params, 'filtro');
                        if (filtros)
                            var misFiltros = ParseaFiltros(filtros);
                        var snumMostrar = getParam(params, 'numMostrar');
                        var sidxOrden = getParam(params, 'idxOrden');
                        newInfoPagina.tipoPagina = 'ResultadosBusqueda';
                        newInfoPagina.numMostrar = (snumMostrar) ? parseInt(snumMostrar) : 10;
                        newInfoPagina.idxOrden = (sidxOrden) ? parseInt(sidxOrden) : 0
                        newInfoPagina.filtrosActivos = misFiltros;
                    }
                break;
                case "fbreaccionesadversasmuh":
                case "breaccionesadversasmuh":
                    var idEpi  = getParam(params,'idEpi');
                    var auxIdsEnfs = getParam(params,'idsEnfs');
                    var idsEnfs = (auxIdsEnfs!=='') ? auxIdsEnfs.split(',') : [];
                    var auxIdsMeds = getParam(params,'idsMeds');
                    var idsMeds = (auxIdsMeds!=='') ? auxIdsMeds.split(',') : [];
                    idEpi   = (idEpi)   ?  parseInt(idEpi)   : 0;
                    idsEnfs = (idsEnfs) && (idsEnfs.length>0) ? idsEnfs.map(id=> parseInt(id))  : [];
                    idsMeds = (idsMeds) && (idsMeds.length>0) ? idsMeds.map(id=> parseInt(id))  : [];
                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'FormularioBusqueda',
                        idEpi: idEpi,
                        idsEnfs: idsEnfs,
                        idsMeds: idsMeds,
                    }
                    if (newInfoPagina.codigoPagina.toLowerCase().substring(0,1)==="b"){
                        //Es la búsqueda
                        var filtros = getParam(params, 'filtro');
                        if (filtros)
                            var misFiltros = ParseaFiltros(filtros);
                        var snumMostrar = getParam(params, 'numMostrar');
                        var sidxOrden = getParam(params, 'idxOrden');
                        newInfoPagina.tipoPagina = 'ResultadosBusqueda';
                        newInfoPagina.numMostrar = (snumMostrar) ? parseInt(snumMostrar) : 10;
                        newInfoPagina.idxOrden = (sidxOrden) ? parseInt(sidxOrden) : 0
                        newInfoPagina.filtrosActivos = misFiltros;
                    }
                break;

                case "fbcomposicionmuh":
                case "bcomposicionmuh":
                case "fbcomposicionmuv":
                case "bcomposicionmuv":
                case "fbcomposicionppf":
                case "bcomposicionppf":   
                    var tipo  = partes[0].substring(partes[0].length-1).toUpperCase();                     
                    var tipoProd  = getParam(params,'tipoProd');
                    tipoProd = (tipoProd ? parseInt(tipoProd) : 1);
                    var auxPAs = getParam(params,'pas');
                    var pas = fromParmaURLToListaPAsCompos(auxPAs);
                    pas = (pas && pas.length>0 ? pas : [] );
                    var solo = getParam(params, 'solo');
                    solo = (solo ? (solo === "S") : (tipo === "F") ? false : true );
                    var idVia = getParam(params, 'idVia');
                    idVia = (idVia ? parseInt(idVia) : 0);
                    var idFF = getParam(params, 'idFF');
                    idFF = (idFF ? parseInt(idFF) : 0);
                    var uds = getParam(params, 'uds');
                    uds =  (uds ? parseInt(uds) : 0);
                    var pv = getParam(params, 'pv');
                    pv = (pv ? parseFloat(pv) : 0.0);
                    var auxInts = getParam(params,'ints');
                    var ints = (auxInts!=='') ? auxInts.split(',') : [];
                    ints = (ints) && (ints.length>0) ? ints.map(id=> parseInt(id))  : [];                    

                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'FormularioBusqueda',
                        tipoProd: tipoProd,
                        pas: pas,
                        solo: solo,
                        idVia: idVia,
                        idFF: idFF,
                        uds: uds,
                        pv: pv,
                        ints: ints
                    }
                    if (newInfoPagina.codigoPagina.toLowerCase().substring(0,1)==="b"){
                        //Es la búsqueda
                        var filtros = getParam(params, 'filtro');
                        if (filtros)
                            var misFiltros = ParseaFiltros(filtros);
                        var snumMostrar = getParam(params, 'numMostrar');
                        var sidxOrden = getParam(params, 'idxOrden');
                        newInfoPagina.tipoPagina = 'ResultadosBusqueda';
                        newInfoPagina.numMostrar = (snumMostrar) ? parseInt(snumMostrar) : 10;
                        newInfoPagina.idxOrden = (sidxOrden) ? parseInt(sidxOrden) : 0
                        newInfoPagina.filtrosActivos = misFiltros;
                    }
                break;
                case "fbpacienteindicacionmuh":
                case "bpacienteindicacionmuh":
                    var idEnf = getParam(params,'idEnf');
                    idEnf  = (idEnf)  ? parseInt(idEnf)  : 0;
                    var auxIdsPat = getParam(params,'idsPat');
                    var idsPat = (auxIdsPat!=='') ? auxIdsPat.split(',') : [];
                    idsPat = (idsPat) && (idsPat.length>0) ? idsPat.map(id=> parseInt(id))  : [];                    
                    var auxIdsMed = getParam(params,'idsMed');
                    var idsMed = (auxIdsMed!=='') ? auxIdsMed.split(',') : [];
                    idsMed = (idsMed) && (idsMed.length>0) ? idsMed.map(id=> parseInt(id))  : [];                    
                    var idVia = getParam(params, 'idVia');
                    idVia = (idVia ? parseInt(idVia) : 0);
                    var idFF = getParam(params, 'idFF');
                    idFF = (idFF ? parseInt(idFF) : 0);
                    var edad = getParam(params, 'edad');
                    var undEdad = getParam(params, 'undEdad');
                    edad     = (edad)     ? parseInt(edad)     : null;
                    undEdad  = (undEdad) ? undEdad : '';
                    var auxInts = getParam(params,'ints');
                    var ints = (auxInts!=='') ? auxInts.split(',') : [];
                    ints = (ints) && (ints.length>0) ? ints.map(id=> parseInt(id))  : [];  
                    var auxIdsAlg = getParam(params,'idsAlg');
                    var idsAlg = (auxIdsAlg!=='') ? auxIdsAlg.split(',') : [];
                    idsAlg = (idsAlg) && (idsAlg.length>0) ? idsAlg.map(id=> parseInt(id))  : [];                                                          
                    var auxOps = getParam(params,'ops');
                    var ops = fromParamURLToListaOpsAvanzada(auxOps);
                    ops = (ops) && (ops.length>0) ? ops : [];

                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'FormularioBusqueda',
                        idEnf: idEnf,
                        idsPat: idsPat,
                        idsMed: idsMed,
                        idVia: idVia,
                        idFF: idFF,
                        edad: edad,
                        undEdad: undEdad,
                        ints: ints,
                        idsAlg: idsAlg,
                        ops: ops,
                    }
                    if (newInfoPagina.codigoPagina.toLowerCase().substring(0,1)==="b"){
                        //Es la búsqueda
                        var filtros = getParam(params, 'filtro');
                        if (filtros)
                            var misFiltros = ParseaFiltros(filtros);
                        var snumMostrar = getParam(params, 'numMostrar');
                        var sidxOrden = getParam(params, 'idxOrden');
                        newInfoPagina.tipoPagina = 'ResultadosBusqueda';
                        newInfoPagina.numMostrar = (snumMostrar) ? parseInt(snumMostrar) : 10;
                        newInfoPagina.idxOrden = (sidxOrden) ? parseInt(sidxOrden) : 0
                        newInfoPagina.filtrosActivos = misFiltros;
                    }
                break;

                case "fbproductosindicacionppf":
                case "bproductosindicacionppf":
                    var idEnf = getParam(params,'idEnf');
                    idEnf  = (idEnf)  ? parseInt(idEnf)  : 0;
                    var idFF = getParam(params, 'idFF');
                    idFF = (idFF ? parseInt(idFF) : 0);
                    var auxPAs = getParam(params,'pas');
                    var pas = fromParmaURLToListaPAsCompos(auxPAs);
                    pas = (pas && pas.length>0 ? pas : [] );
                    var auxInts = getParam(params,'ints');
                    var ints = (auxInts!=='') ? auxInts.split(',') : [];
                    ints = (ints) && (ints.length>0) ? ints.map(id=> parseInt(id))  : [];  
                    var auxOps = getParam(params,'ops');
                    var ops = fromParamURLToListaOpsAvanzada(auxOps);
                    ops = (ops) && (ops.length>0) ? ops : [];

                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'FormularioBusqueda',
                        idEnf: idEnf,
                        idFF: idFF,
                        pas: pas,
                        ints: ints,
                        ops: ops,
                    }
                    if (newInfoPagina.codigoPagina.toLowerCase().substring(0,1)==="b"){
                        //Es la búsqueda
                        var filtros = getParam(params, 'filtro');
                        if (filtros)
                            var misFiltros = ParseaFiltros(filtros);
                        var snumMostrar = getParam(params, 'numMostrar');
                        var sidxOrden = getParam(params, 'idxOrden');
                        newInfoPagina.tipoPagina = 'ResultadosBusqueda';
                        newInfoPagina.numMostrar = (snumMostrar) ? parseInt(snumMostrar) : 10;
                        newInfoPagina.idxOrden = (sidxOrden) ? parseInt(sidxOrden) : 0
                        newInfoPagina.filtrosActivos = misFiltros;
                    }
                break;


                case "fbformacolormuh":
                case "bformacolormuh":
                    var tipoFF = getParam(params,'tipoFF');
                    tipoFF  = (tipoFF) ? tipoFF : 'CO';
                    var idEnf = getParam(params,'idEnf');
                    idEnf  = (idEnf)  ? parseInt(idEnf)  : 0;
                    var idForma = getParam(params, 'idForma');
                    idForma = (idForma ? parseInt(idForma) : 0);
                    var idColor1 = getParam(params, 'idColor1');
                    idColor1 = (idColor1 ? idColor1 : '');
                    var idColor2 = getParam(params, 'idColor2');
                    idColor2 = (idColor2 ? idColor2 : '');
                    var idSabor = getParam(params, 'idSabor');
                    idSabor = (idSabor ? parseInt(idSabor) : 0);
                    var CO1 = getParam(params, 'CO1');
                    CO1 = (CO1 ? CO1 : '');
                    var CO2 = getParam(params, 'CO2');
                    CO2 = (CO2 ? CO2 : '');
                    var auxTamanos = getParam(params,'tamanos');
                    var tamanos = (auxTamanos!=='') ? auxTamanos.split(',') : [];
                    var ranura = getParam(params,'ranura');
                    ranura = (ranura === "S" ?  true : false);
                    var logo = getParam(params,'logo');
                    logo = (logo === "S" ? true : false)
                    var difCol = getParam(params,'difCol');
                    difCol = (difCol === "S" ? true : false)
                    var anycol = getParam(params,'anycol');
                    anycol = (anycol === "S" ? true : false)
                    var col2 = getParam(params,'col2');
                    col2 = (col2 === "S" ? true : false)
                    var ins1  = getParam(params,'ins1');
                    ins1 = (ins1) ? ins1 : '';
                    var ins2  = getParam(params,'ins2');
                    ins2 = (ins2) ? ins2 : '';
                    var auxPAs = getParam(params,'pas');
                    var pas = fromParmaURLToListaPAsCompos(auxPAs);
                    pas = (pas && pas.length>0 ? pas : [] );
                    var solo = getParam(params, 'solo');
                    solo = (solo ? (solo === "S") : true );


                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'FormularioBusqueda',
                        tipoFF: tipoFF,
                        idForma: idForma,
                        idColor1: idColor1,
                        idColor2: idColor2,
                        idSabor: idSabor,
                        CO1: CO1,
                        CO2: CO2,
                        tamanos: tamanos,
                        ranura: ranura,
                        difCol: difCol,
                        anycol: anycol,
                        col2: col2,
                        logo: logo,
                        ins1: ins1,
                        ins2: ins2,
                        idEnf: idEnf,
                        pas: pas,
                        solo: solo
                    }
                    if (newInfoPagina.codigoPagina.toLowerCase().substring(0,1)==="b"){
                        //Es la búsqueda
                        var filtros = getParam(params, 'filtro');
                        if (filtros)
                            var misFiltros = ParseaFiltros(filtros);
                        var snumMostrar = getParam(params, 'numMostrar');
                        var sidxOrden = getParam(params, 'idxOrden');
                        newInfoPagina.tipoPagina = 'ResultadosBusqueda';
                        newInfoPagina.numMostrar = (snumMostrar) ? parseInt(snumMostrar) : 10;
                        newInfoPagina.idxOrden = (sidxOrden) ? parseInt(sidxOrden) : 0
                        newInfoPagina.filtrosActivos = misFiltros;
                    }
                break;


                case "fbavanzadamuh":
                case "bavanzadamuh":
                    var txt  = getParam(params,'med');
                    txt = (txt) ? decodeURI(txt) : '';
                    /*var idLab = getParam(params,'idLab');
                    idLab  = (idLab)  ? parseInt(idLab)  : 0;
                    var idGT = getParam(params,'idGT');
                    idGT  = (idGT)  ? idGT  : "";*/
                    var auxIdsLab = getParam(params,'idsLab');
                    var idsLab = (auxIdsLab!=='') ? auxIdsLab.split(',') : [];
                    idsLab = (idsLab) && (idsLab.length>0) ? idsLab.map(id=> parseInt(id))  : [];                    
                    var auxIdsGT = getParam(params,'idsGT');
                    var idsGT = (auxIdsGT!=='') ? auxIdsGT.split(',') : [];
                    idsGT = (idsGT) && (idsGT.length>0) ? idsGT.map(id=> id)  : [];                    

                    var auxPAs = getParam(params,'pas');
                    var pas = fromParmaURLToListaPAsCompos(auxPAs);
                    pas = (pas && pas.length>0 ? pas : [] );
                    var solo = getParam(params, 'solo');
                    solo = (solo ? (solo === "S") : (tipo === "F") ? false : true );
                    var idVia = getParam(params, 'idVia');
                    idVia = (idVia ? parseInt(idVia) : 0);
                    var idFF = getParam(params, 'idFF');
                    idFF = (idFF ? parseInt(idFF) : 0);
                    var uds = getParam(params, 'uds');
                    uds =  (uds ? parseInt(uds) : 0);
                    var emr = getParam(params, 'emr');
                    emr =  (emr ? parseInt(emr) : 0);
                    var edad = getParam(params, 'edad');
                    var undEdad = getParam(params, 'undEdad');
                    edad     = (edad)     ? parseInt(edad)     : null;
                    undEdad  = (undEdad) ? undEdad : '';
                    var auxInts = getParam(params,'ints');
                    var ints = (auxInts!=='') ? auxInts.split(',') : [];
                    ints = (ints) && (ints.length>0) ? ints.map(id=> parseInt(id))  : [];                    
                    var listaEnfs = getParam(params,'enfsRel');
                    var enfsRelAux = (listaEnfs!=='') ? listaEnfs.split(',') : [];
                    var enfsRel = [];
                    for (var i=0; i<enfsRelAux.length; i++){
                        var aux = enfsRelAux[i].split('_');
                        enfsRel.push({codigo: parseInt(aux[0]), epi: parseInt(aux[1])});
                    }
                    enfsRel = (enfsRel) && (enfsRel.length>0) ? enfsRel  : [];
                    var listaAnas = getParam(params,'anasRel');
                    var anasRelAux = (listaAnas!=='') ? listaAnas.split(',') : [];
                    var anasRel = [];
                    for (var i=0; i<anasRelAux.length; i++){
                        var aux = anasRelAux[i].split('_');
                        anasRel.push({codigo: parseInt(aux[0]), epi: parseInt(aux[1])});
                    }
                    anasRel = (anasRel) && (anasRel.length>0) ? anasRel  : [];
                    var auxOps = getParam(params,'ops');
                    var ops = fromParamURLToListaOpsAvanzada(auxOps);
                    ops = (ops) && (ops.length>0) ? ops : [];
                    var fechaAutoIni = getParam(params, 'fechaAutoIni');
                    fechaAutoIni = (fechaAutoIni) ? new Date(parseInt(fechaAutoIni)*1000) : null;
                    var fechaAutoFin = getParam(params, 'fechaAutoFin');
                    fechaAutoFin = (fechaAutoFin) ? new Date(parseInt(fechaAutoFin)*1000) : null;
                    var fechaInSNSIni = getParam(params, 'fechaInSNSIni');
                    fechaInSNSIni = (fechaInSNSIni) ? new Date(parseInt(fechaInSNSIni)*1000) : null;
                    var fechaInSNSFin = getParam(params, 'fechaInSNSFin');
                    fechaInSNSFin = (fechaInSNSFin) ? new Date(parseInt(fechaInSNSFin)*1000) : null;
                    var fechaExSNSIni = getParam(params, 'fechaExSNSIni');
                    fechaExSNSIni = (fechaExSNSIni) ? new Date(parseInt(fechaExSNSIni)*1000) : null;
                    var fechaExSNSFin = getParam(params, 'fechaExSNSFin');
                    fechaExSNSFin = (fechaExSNSFin) ? new Date(parseInt(fechaExSNSFin)*1000) : null;
                    var fechaExFIIni = getParam(params, 'fechaExFIIni');
                    fechaExFIIni = (fechaExFIIni) ? new Date(parseInt(fechaExFIIni)*1000) : null;
                    var fechaExFIFin = getParam(params, 'fechaExFIFin');
                    fechaExFIFin = (fechaExFIFin) ? new Date(parseInt(fechaExFIFin)*1000) : null;
                    var fechaBajaIni = getParam(params, 'fechaBajaIni');
                    fechaBajaIni = (fechaBajaIni) ? new Date(parseInt(fechaBajaIni)*1000) : null;
                    var fechaBajaFin = getParam(params, 'fechaBajaFin');
                    fechaBajaFin = (fechaBajaFin) ? new Date(parseInt(fechaBajaFin)*1000) : null;
                    var fechaComerIni = getParam(params, 'fechaComerIni');
                    fechaComerIni = (fechaComerIni) ? new Date(parseInt(fechaComerIni)*1000) : null;
                    var fechaComerFin = getParam(params, 'fechaComerFin');
                    fechaComerFin = (fechaComerFin) ? new Date(parseInt(fechaComerFin)*1000) : null;
                    var idPredefP = getParam(params, 'idPredefP');
                    idPredefP = (idPredefP) ? parseInt(idPredefP) : 0;
                    var idOpP = getParam(params, 'idOpP');
                    idOpP = (idOpP) ? parseInt(idOpP) : 0;
                    var idCampoP = getParam(params, 'idCampoP');
                    idCampoP = (idCampoP) ? idCampoP.trim() : '';
                    var pre = getParam(params, 'pre');
                    pre = (pre) ? parseFloat(pre)/100 : 0;
                    var auxIdsAA = getParam(params,'idsAA');
                    var idsAA = (auxIdsAA!=='') ? auxIdsAA.split(',') : [];
                    idsAA = (idsAA) && (idsAA.length>0) ? idsAA.map(id=> id)  : [];                    
                    var auxIdsAN = getParam(params,'idsAN');
                    var idsAN = (auxIdsAN!=='') ? auxIdsAN.split(',') : [];
                    idsAN = (idsAN) && (idsAN.length>0) ? idsAN.map(id=> id)  : [];                    
                    var auxIdsDC = getParam(params,'idsDC');
                    var idsDC = (auxIdsDC!=='') ? auxIdsDC.split(',') : [];
                    idsDC = (idsDC) && (idsDC.length>0) ? idsDC.map(id=> id)  : [];                    
                    var auxIdsMen = getParam(params,'idsMen');
                    var idsMen = (auxIdsMen!=='') ? auxIdsMen.split(',') : [];
                    idsMen = (idsMen) && (idsMen.length>0) ? idsMen.map(id=> parseInt(id))  : [];                    
                    var texto  = getParam(params,'texto');
                    texto = (texto) ? decodeURI(texto) : '';
                    var noTexto = getParam(params,'noTexto');
                    noTexto = (noTexto) ? (noTexto==="S" || noTexto === "s") : false;
                    var idEpi = getParam(params, 'idEpi');
                    idEpi = (idEpi) ? parseInt(idEpi) : 0;

                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'FormularioBusqueda',
                        txt: txt,
                        idsLab: idsLab,
                        idsGT: idsGT,
                        pas: pas,
                        solo: solo,
                        idVia: idVia,
                        idFF: idFF,
                        uds: uds,
                        emr: emr,
                        edad: edad,
                        undEdad: undEdad,
                        ints: ints,
                        enfsRel: enfsRel,
                        anasRel: anasRel,
                        ops: ops,
                        fechaAutoIni: fechaAutoIni,
                        fechaAutoFin: fechaAutoFin,
                        fechaBajaFin: fechaBajaFin,
                        fechaBajaIni: fechaBajaIni,
                        fechaComerFin: fechaComerFin,
                        fechaComerIni: fechaComerIni,
                        fechaExFIFin: fechaExFIFin,
                        fechaExFIIni: fechaExFIIni,
                        fechaExSNSIni: fechaExSNSIni,
                        fechaExSNSFin: fechaExSNSFin,
                        fechaInSNSFin: fechaInSNSFin,
                        fechaInSNSIni: fechaInSNSIni,
                        idCampoP: idCampoP,
                        idOpP: idOpP,
                        pre: pre,
                        idPredefP: idPredefP,
                        idsAA: idsAA,
                        idsAN: idsAN,
                        idsDC: idsDC,
                        idsMen: idsMen,
                        idEpi: idEpi,
                        texto: texto,        
                        noTexto: noTexto,
                    }
                    if (newInfoPagina.codigoPagina.toLowerCase().substring(0,1)==="b"){
                        //Es la búsqueda
                        var filtros = getParam(params, 'filtro');
                        if (filtros)
                            var misFiltros = ParseaFiltros(filtros);
                        var snumMostrar = getParam(params, 'numMostrar');
                        var sidxOrden = getParam(params, 'idxOrden');
                        newInfoPagina.tipoPagina = 'ResultadosBusqueda';
                        newInfoPagina.numMostrar = (snumMostrar) ? parseInt(snumMostrar) : 10;
                        newInfoPagina.idxOrden = (sidxOrden) ? parseInt(sidxOrden) : 0
                        newInfoPagina.filtrosActivos = misFiltros;
                    }
                break;

                case "fbavanzadamuv":
                case "bavanzadamuv":
                    var txt  = getParam(params,'med');
                    txt = (txt) ? decodeURI(txt) : '';
                    /*var idLab = getParam(params,'idLab');
                    idLab  = (idLab)  ? parseInt(idLab)  : 0;
                    var idGT = getParam(params,'idGT');
                    idGT  = (idGT)  ? idGT  : "";*/
                    var auxIdsLab = getParam(params,'idsLab');
                    var idsLab = (auxIdsLab!=='') ? auxIdsLab.split(',') : [];
                    idsLab = (idsLab) && (idsLab.length>0) ? idsLab.map(id=> parseInt(id))  : [];                    
                    var auxIdsGT = getParam(params,'idsGT');
                    var idsGT = (auxIdsGT!=='') ? auxIdsGT.split(',') : [];
                    idsGT = (idsGT) && (idsGT.length>0) ? idsGT.map(id=> id)  : [];                    
                    var auxPAs = getParam(params,'pas');
                    var pas = fromParmaURLToListaPAsCompos(auxPAs);
                    pas = (pas && pas.length>0 ? pas : [] );
                    var solo = getParam(params, 'solo');
                    solo = (solo ? (solo === "S") : (tipo === "F") ? false : true );
                    var idVia = getParam(params, 'idVia');
                    idVia = (idVia ? parseInt(idVia) : 0);
                    var idFF = getParam(params, 'idFF');
                    idFF = (idFF ? parseInt(idFF) : 0);
                    var uds = getParam(params, 'uds');
                    uds =  (uds ? parseInt(uds) : 0);
                    var fechaAutoIni = getParam(params, 'fechaAutoIni');
                    fechaAutoIni = (fechaAutoIni) ? new Date(parseInt(fechaAutoIni)*1000) : null;
                    var fechaAutoFin = getParam(params, 'fechaAutoFin');
                    fechaAutoFin = (fechaAutoFin) ? new Date(parseInt(fechaAutoFin)*1000) : null;
                    var fechaBajaIni = getParam(params, 'fechaBajaIni');
                    fechaBajaIni = (fechaBajaIni) ? new Date(parseInt(fechaBajaIni)*1000) : null;
                    var fechaBajaFin = getParam(params, 'fechaBajaFin');
                    fechaBajaFin = (fechaBajaFin) ? new Date(parseInt(fechaBajaFin)*1000) : null;
                    var auxOps = getParam(params,'ops');
                    var ops = fromParamURLToListaOpsAvanzada(auxOps);
                    ops = (ops) && (ops.length>0) ? ops : [];
                    var idEspecie  = getParam(params,'idEspecie');
                    idEspecie   = (idEspecie)   ?  parseInt(idEspecie)   : 0;
                    var idEnf = getParam(params,'idEnf');
                    idEnf   = (idEnf)   ?  parseInt(idEnf)   : 0;
                    var auxIdsMen = getParam(params,'idsMen');
                    var idsMen = (auxIdsMen!=='') ? auxIdsMen.split(',') : [];
                    idsMen = (idsMen) && (idsMen.length>0) ? idsMen.map(id=> parseInt(id))  : [];                    
                    var texto  = getParam(params,'texto');
                    texto = (texto) ? decodeURI(texto) : '';
                    var noTexto = getParam(params,'noTexto');
                    noTexto = (noTexto) ? (noTexto==="S" || noTexto === "s") : false;
                    var idEpi = getParam(params, 'idEpi');
                    idEpi = (idEpi) ? parseInt(idEpi) : 0;

                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'FormularioBusqueda',
                        txt: txt,
                        idsLab: idsLab,
                        idsGT: idsGT,
                        pas: pas,
                        solo: solo,
                        idVia: idVia,
                        idFF: idFF,
                        uds: uds,
                        fechaAutoIni: fechaAutoIni,
                        fechaAutoFin: fechaAutoFin,
                        fechaBajaFin: fechaBajaFin,
                        fechaBajaIni: fechaBajaIni,
                        ops: ops,
                        idEspecie: idEspecie,
                        idEnf: idEnf,
                        idsMen: idsMen,
                        idEpi: idEpi,
                        texto: texto,        
                        noTexto: noTexto,
                    }
                    if (newInfoPagina.codigoPagina.toLowerCase().substring(0,1)==="b"){
                        //Es la búsqueda
                        var filtros = getParam(params, 'filtro');
                        if (filtros)
                            var misFiltros = ParseaFiltros(filtros);
                        var snumMostrar = getParam(params, 'numMostrar');
                        var sidxOrden = getParam(params, 'idxOrden');
                        newInfoPagina.tipoPagina = 'ResultadosBusqueda';
                        newInfoPagina.numMostrar = (snumMostrar) ? parseInt(snumMostrar) : 10;
                        newInfoPagina.idxOrden = (sidxOrden) ? parseInt(sidxOrden) : 0
                        newInfoPagina.filtrosActivos = misFiltros;
                    }
                break;
                case "fbavanzadapah":
                case "bavanzadapah":
                    var txt  = getParam(params,'med');
                    txt = (txt) ? decodeURI(txt) : '';
                    var auxAF = getParam(params,'idsAF');
                    var idsAF = (auxAF!=='') ? auxAF.split(',') : [];
                    idsAF = (idsAF) && (idsAF.length>0) ? idsAF.map(id=> parseInt(id))  : [];                    
                    var auxAM = getParam(params,'idsAM');
                    var idsAM = (auxAM!=='') ? auxAM.split(',') : [];
                    idsAM = (idsAM) && (idsAM.length>0) ? idsAM.map(id=> parseInt(id))  : [];                    
                    /*var idGT = getParam(params,'idGT');
                    idGT  = (idGT)  ? idGT  : "";*/
                    var auxIdsGT = getParam(params,'idsGT');
                    var idsGT = (auxIdsGT!=='') ? auxIdsGT.split(',') : [];
                    idsGT = (idsGT) && (idsGT.length>0) ? idsGT.map(id=> id)  : [];                    
                    var listaEnfs = getParam(params,'enfsRel');
                    var enfsRelAux = (listaEnfs!=='') ? listaEnfs.split(',') : [];
                    var enfsRel = [];
                    for (var i=0; i<enfsRelAux.length; i++){
                        var aux = enfsRelAux[i].split('_');
                        enfsRel.push({codigo: parseInt(aux[0]), epi: parseInt(aux[1])});
                    }
                    enfsRel = (enfsRel) && (enfsRel.length>0) ? enfsRel  : [];
                    var listaAnas = getParam(params,'anasRel');
                    var anasRelAux = (listaAnas!=='') ? listaAnas.split(',') : [];
                    var anasRel = [];
                    for (var i=0; i<anasRelAux.length; i++){
                        var aux = anasRelAux[i].split('_');
                        anasRel.push({codigo: parseInt(aux[0]), epi: parseInt(aux[1])});
                    }
                    anasRel = (anasRel) && (anasRel.length>0) ? anasRel  : [];
                    var auxOps = getParam(params,'ops');
                    var ops = fromParamURLToListaOpsAvanzada(auxOps);
                    ops = (ops) && (ops.length>0) ? ops : [];
                    var auxIdsMen = getParam(params,'idsMen');
                    var idsMen = (auxIdsMen!=='') ? auxIdsMen.split(',') : [];
                    idsMen = (idsMen) && (idsMen.length>0) ? idsMen.map(id=> parseInt(id))  : [];                    
                    var texto  = getParam(params,'texto');
                    texto = (texto) ? decodeURI(texto) : '';
                    var noTexto = getParam(params,'noTexto');
                    noTexto = (noTexto) ? (noTexto==="S" || noTexto === "s") : false;
                    var idEpi = getParam(params, 'idEpi');
                    idEpi = (idEpi) ? parseInt(idEpi) : 0;

                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'FormularioBusqueda',
                        txt: txt,
                        idsAF: idsAF,
                        idsAM: idsAM,
                        idsGT: idsGT,
                        enfsRel: enfsRel,
                        anasRel: anasRel,
                        ops: ops,
                        idsMen: idsMen,
                        idEpi: idEpi,
                        texto: texto,        
                        noTexto: noTexto,
                    }
                    if (newInfoPagina.codigoPagina.toLowerCase().substring(0,1)==="b"){
                        //Es la búsqueda
                        var filtros = getParam(params, 'filtro');
                        if (filtros)
                            var misFiltros = ParseaFiltros(filtros);
                        var snumMostrar = getParam(params, 'numMostrar');
                        var sidxOrden = getParam(params, 'idxOrden');
                        newInfoPagina.tipoPagina = 'ResultadosBusqueda';
                        newInfoPagina.numMostrar = (snumMostrar) ? parseInt(snumMostrar) : 10;
                        newInfoPagina.idxOrden = (sidxOrden) ? parseInt(sidxOrden) : 0
                        newInfoPagina.filtrosActivos = misFiltros;
                    }
                break;

                case "fbavanzadappf":
                case "bavanzadappf":
                    var txt  = getParam(params,'med');
                    txt = (txt) ? decodeURI(txt) : '';
                    /*var idLab = getParam(params,'idLab');
                    idLab  = (idLab)  ? parseInt(idLab)  : 0;
                    var idGT = getParam(params,'idGT');
                    idGT  = (idGT)  ? idGT  : "";*/
                    var auxIdsLab = getParam(params,'idsLab');
                    var idsLab = (auxIdsLab!=='') ? auxIdsLab.split(',') : [];
                    idsLab = (idsLab) && (idsLab.length>0) ? idsLab.map(id=> parseInt(id))  : [];                    
                    var auxIdsGT = getParam(params,'idsGT');
                    var idsGT = (auxIdsGT!=='') ? auxIdsGT.split(',') : [];
                    idsGT = (idsGT) && (idsGT.length>0) ? idsGT.map(id=> id)  : [];                    
                    var auxPAs = getParam(params,'pas');
                    var pas = fromParmaURLToListaPAsCompos(auxPAs);
                    pas = (pas && pas.length>0 ? pas : [] );
                    var solo = getParam(params, 'solo');
                    solo = (solo ? (solo === "S") : false);
                    var idVia = getParam(params, 'idVia');
                    idVia = (idVia ? parseInt(idVia) : 0);
                    var idFF = getParam(params, 'idFF');
                    idFF = (idFF ? parseInt(idFF) : 0);
                    /*var idTipo = getParam(params, 'idTipo');
                    idTipo = (idTipo ? parseInt(idTipo) : 0);
                    var idSubtipo = getParam(params, 'idSubtipo');
                    idSubtipo = (idSubtipo ? parseInt(idSubtipo) : 0);*/
                    var auxIdsTipo = getParam(params,'idsTipo');
                    var idsTipo = (auxIdsTipo!=='') ? auxIdsTipo.split(',') : [];
                    idsTipo = (idsTipo) && (idsTipo.length>0) ? idsTipo.map(id=> parseInt(id))  : [];                    
                    var auxIdsSubtipo = getParam(params,'idsSubtipo');
                    var idsSubtipo = (auxIdsSubtipo!=='') ? auxIdsSubtipo.split(',') : [];
                    idsSubtipo = (idsSubtipo) && (idsSubtipo.length>0) ? idsSubtipo.map(id=> parseInt(id))  : [];                    
                    var uds = getParam(params, 'uds');
                    uds =  (uds ? parseInt(uds) : 0);
                    var fechaAutoIni = getParam(params, 'fechaAutoIni');
                    fechaAutoIni = (fechaAutoIni) ? new Date(parseInt(fechaAutoIni)*1000) : null;
                    var fechaAutoFin = getParam(params, 'fechaAutoFin');
                    fechaAutoFin = (fechaAutoFin) ? new Date(parseInt(fechaAutoFin)*1000) : null;
                    var fechaBajaIni = getParam(params, 'fechaBajaIni');
                    fechaBajaIni = (fechaBajaIni) ? new Date(parseInt(fechaBajaIni)*1000) : null;
                    var fechaBajaFin = getParam(params, 'fechaBajaFin');
                    fechaBajaFin = (fechaBajaFin) ? new Date(parseInt(fechaBajaFin)*1000) : null;
                    var auxInts = getParam(params,'ints');
                    var ints = (auxInts!=='') ? auxInts.split(',') : [];
                    ints = (ints) && (ints.length>0) ? ints.map(id=> parseInt(id))  : [];                    
                    var auxOps = getParam(params,'ops');
                    var ops = fromParamURLToListaOpsAvanzada(auxOps);
                    ops = (ops) && (ops.length>0) ? ops : [];
                    var auxIdsMen = getParam(params,'idsMen');
                    var idsMen = (auxIdsMen!=='') ? auxIdsMen.split(',') : [];
                    idsMen = (idsMen) && (idsMen.length>0) ? idsMen.map(id=> parseInt(id))  : [];                    
                    var texto  = getParam(params,'texto');
                    texto = (texto) ? decodeURI(texto) : '';
                    var noTexto = getParam(params,'noTexto');
                    noTexto = (noTexto) ? (noTexto==="S" || noTexto === "s") : false;
                    var idEpi = getParam(params, 'idEpi');
                    idEpi = (idEpi) ? parseInt(idEpi) : 0;

                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'FormularioBusqueda',
                        txt: txt,
                        idsLab: idsLab,
                        idsGT: idsGT,
                        pas: pas,
                        solo: solo,
                        idVia: idVia,
                        idFF: idFF,
                        idsTipo: idsTipo,
                        idsSubtipo: idsSubtipo,
                        uds: uds,
                        fechaAutoIni: fechaAutoIni,
                        fechaAutoFin: fechaAutoFin,
                        fechaBajaFin: fechaBajaFin,
                        fechaBajaIni: fechaBajaIni,
                        ints: ints,
                        ops: ops,
                        idsMen: idsMen,
                        idEpi: idEpi,
                        texto: texto,        
                        noTexto: noTexto,
                    }
                    if (newInfoPagina.codigoPagina.toLowerCase().substring(0,1)==="b"){
                        //Es la búsqueda
                        var filtros = getParam(params, 'filtro');
                        if (filtros)
                            var misFiltros = ParseaFiltros(filtros);
                        var snumMostrar = getParam(params, 'numMostrar');
                        var sidxOrden = getParam(params, 'idxOrden');
                        newInfoPagina.tipoPagina = 'ResultadosBusqueda';
                        newInfoPagina.numMostrar = (snumMostrar) ? parseInt(snumMostrar) : 10;
                        newInfoPagina.idxOrden = (sidxOrden) ? parseInt(sidxOrden) : 0
                        newInfoPagina.filtrosActivos = misFiltros;
                    }
                break;

                case "binteraccionesmuh":
                case "binteraccionesppf":
                        var auxIdsSel = getParam(params,'idsSel');
                    var idsSel = (auxIdsSel!=='') ? auxIdsSel.split(',') : [];
                    idsSel = (idsSel) && (idsSel.length>0) ? idsSel.map(id=> parseInt(id))  : [];                    

                    var filtros = getParam(params, 'filtro');
                    if (filtros)
                        var misFiltros = ParseaFiltros(filtros);
                    var snumMostrar = getParam(params, 'numMostrar');
                    var sidxOrden = getParam(params, 'idxOrden');

                    var numMostrar = (snumMostrar) ? parseInt(snumMostrar) : 10;
                    var idxOrden = (sidxOrden) ? parseInt(sidxOrden) : 0
                    var filtrosActivos = misFiltros;

                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'ResultadosBusqueda',
                        idsSel: idsSel,
                        titulo: 'Búsqueda de interacciones',
                        numMostrar: numMostrar,
                        idxOrden: idxOrden,
                        filtrosActivos: filtrosActivos                        
                    }
                break;

                case "fbtest":
                    var idA  = getParam(params,'idA');
                    var idL1 = getParam(params,'idL1');
                    var idL2 = getParam(params,'idL2');
                    var listaIds = getParam(params,'idsL3');
                    var idsL3 = (listaIds!=='') ? listaIds.split('--') : [];
                    var listaEnfs = getParam(params,'enfsRel');
                    var enfsRelAux = (listaEnfs!=='') ? listaIds.split('--') : [];
                    var enfsRel = [];
                    for (var i=0; i<enfsRelAux.length; i++){
                        var aux = enfsRelAux[i].split('_');
                        enfsRel.push({codigo: aux[0], epi: aux[1]});
                    }
                    var snx = getParam(params,'snx');
                    var edad = getParam(params, 'edad');
                    var unidad = getParam(params, 'unidad');
                    var inicio = getParam(params, 'inicio');
                    var fin = getParam(params, 'fin');
                    idA   = (idA)   ? idA   : '';
                    idL1  = (idL1)  ? parseInt(idL1)  : 0;
                    idL2  = (idL2)  ? parseInt(idL2)  : 0;
                    idsL3 = (idsL3) && (idsL3.length>0) ? idsL3  : [];
                    enfsRel = (enfsRel) && (enfsRel.length>0) ? enfsRel  : [];
                    snx   = (snx)   ? snx   : 'X';
                    edad     = (edad)     ? parseInt(edad)     : null;
                    unidad  = (unidad) ? unidad : '';
                    inicio     = (inicio)     ? new Date(parseInt(inicio)*1000)     : null;
                    fin     = (fin)     ? new Date(parseInt(fin)*1000)     : null;
                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'FormularioBusqueda',
                        idA: idA,
                        idL1: idL1,
                        idL2: idL2,
                        idsL3: idsL3,
                        snx: snx,
                        edad: edad,
                        unidad: unidad,
                        inicio: inicio,
                        fin: fin,
                        enfsRel: enfsRel,
                    }
                break;
                case "fbgtvmppmuh":
                case "fbgtvmpp2muh":
                case "fbgtconppf":
                case "fbgtsanppf":
                        var id = getParam(params,'id');
                        if (!id)
                            id = '';
                        newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'FormularioBusqueda',
                        id: id,
                    }
                break;
                case "fbgtatcmuh":
                case "fbgtatcmuv":
                        var auxIds = getParam(params,'seleccionados');
                        var seleccionados = (auxIds!=='') ? auxIds.split(',') : [];
                        seleccionados = (seleccionados) && (seleccionados.length>0) ? seleccionados : [];   
                        newInfoPagina = {
                            codigoPagina:partes[0],
                            tipoPagina:'FormularioBusqueda',
                            seleccionados: seleccionados,
                        }
                break;
                case "configuracion":
                case "cambiopass":
                case "cambiomail":
                case "confirmamail":
                        newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'Conf',
                    }
                break;
                case "editabuscadores":
                        newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'EditaBuscadores',
                    }
                break;
                case "binicio":
                case "blaboratoriomuv":
                case "blaboratorioppf":
                case "blaboratoriomuh":
                case "blaboratoriomho":    
                case "bformafarmamuh":
                case "bformafarmamuv":
                case "bformafarmappf":
                case "bafmuh":
                case "bammuh":
                case "bactppf":
                case "bafmuv":
                case "bagruphomoautomuh":
                case "bagruphomomuh":
                case "bagruphomoautoppf":
                case "bagruphomoppf":
                case "banalisismuh":
                case "bfarmacogeneticamuh":
                case "bpamuh":
                case "basomuh":
                case "bpamuv":
                case "basomuv":
                case "bingppf":
                case "btipoppf":
                case "bsubtipoppf":
                case "bindicacionmuh":
                case "bindicacionmuv":
                case "bindicacionppf":    
                case "bcontraindicacionmuh":
                case "bprecaucionmuh":
                case "breaccionadversamuh":
                case "bgtatcmuh":
                case "bgtatcmuv":
                case "bgtvmppmuh":
                case "bgtconppf":
                case "bgtsanppf":
                case "bformmagistralmuh":
                case "bmedshomeomuh": 
                case "bmedespanolesmuh":     
                case "bmismonombremuh":                              
                    var txt = getParam(params,'txt');
                    txt = (txt) ? decodeURI(txt) : '';
                    var opb = getParam(params,'opb');
                    opb  = (opb)  ? parseInt(opb)  : 0;
                    var id = getParam(params,'id');
                    if (!id)
                        id = 0;
                    var filtros = getParam(params, 'filtro');
                    if (filtros)
                        var misFiltros = ParseaFiltros(filtros);
                    var snumMostrar = getParam(params, 'numMostrar');
                    var sidxOrden = getParam(params, 'idxOrden');
                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'ResultadosBusqueda',
                        listaRes: null,
                        texto: txt,
                        id: id, 
                        fuerzaTexto : true,
                        numMostrar : (snumMostrar) ? parseInt(snumMostrar) : 10,
                        idxOrden : (sidxOrden) ? parseInt(sidxOrden) : 0,
                        filtrosActivos : misFiltros,
                        opb: opb,
                    }
                break;
                case "bgtsatcmuh":
                case "bgtsatcmuv":
                    var auxIds = getParam(params,'seleccionados');
                    var seleccionados = (auxIds!=='') ? auxIds.split(',') : [];
                    seleccionados = (seleccionados) && (seleccionados.length>0) ? seleccionados  : [];                    
                    var filtros = getParam(params, 'filtro');
                    if (filtros)
                        var misFiltros = ParseaFiltros(filtros);
                    var snumMostrar = getParam(params, 'numMostrar');
                    var sidxOrden = getParam(params, 'idxOrden');
                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'ResultadosBusqueda',
                        listaRes: null,
                        texto: txt,
                        seleccionados: seleccionados, 
                        fuerzaTexto : true,
                        numMostrar : (snumMostrar) ? parseInt(snumMostrar) : 10,
                        idxOrden : (sidxOrden) ? parseInt(sidxOrden) : 0,
                        filtrosActivos : misFiltros,
                        opb: opb,
                    }
                break;
                case "blaboratoriosmuv":
                case "blaboratoriosppf":
                case "blaboratoriosmuh":
                case "blaboratoriosmho":
                case "bformasfarmamuh":
                case "bformasfarmamuv":
                case "bformasfarmappf":
                case "bafsmuh":
                case "bamsmuh":
                case "bactsppf":
                case "bafsmuv":
                    var auxIds = getParam(params,'seleccionados');
                    var seleccionados = (auxIds!=='') ? auxIds.split(',') : [];
                    seleccionados = (seleccionados) && (seleccionados.length>0) ? seleccionados.map(id=> parseInt(id))  : [];                    
                    var filtros = getParam(params, 'filtro');
                    if (filtros)
                        var misFiltros = ParseaFiltros(filtros);
                    var snumMostrar = getParam(params, 'numMostrar');
                    var sidxOrden = getParam(params, 'idxOrden');
                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'ResultadosBusqueda',
                        listaRes: null,
                        texto: txt,
                        seleccionados: seleccionados, 
                        fuerzaTexto : true,
                        numMostrar : (snumMostrar) ? parseInt(snumMostrar) : 10,
                        idxOrden : (sidxOrden) ? parseInt(sidxOrden) : 0,
                        filtrosActivos : misFiltros,
                        opb: opb,
                    }
                break;
                case "bcongluten":
                case "bconlactosa":
                case "bsingluten":
                case "bsinlactosa":
                case "bdopantesmuh":
                case "bnolentes":
                case "bcomparapreciomenormuh":
                case "bcomparapreciomasbajomuh":
                case "bprecionotificadomuh":
                case "bseguimientoadicionalmuh":
                case "bdispositivoseguridadmuh":
                case "blibrorecetariom":
                case "blibroestupefacientesm":
                case "bnotificacionesvacmuv":
                case "benfermedadespath":
                case "benfermedadespatv":
                case "bdeduccionesrdlmuh":
                case "bnosustituiblesmuh":
                case "bcambioprecioprevistomuh":
                case "balertadoscismedmuh":

                    var filtros = getParam(params, 'filtro');
                    if (filtros)
                        var misFiltros = ParseaFiltros(filtros);
                    var snumMostrar = getParam(params, 'numMostrar');
                    var sidxOrden = getParam(params, 'idxOrden');
                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'ResultadosBusqueda',
                        listaRes: null,
                        numMostrar : (snumMostrar) ? parseInt(snumMostrar) : 10,
                        idxOrden : (sidxOrden) ? parseInt(sidxOrden) : 0,
                        filtrosActivos : misFiltros,
                    }
                break;
                case "fbdocumentos":
                case "bdocumentos":
                    var idMed = getParam(params,'idMed');
                    var inicio = getParam(params, 'inicio');
                    var fin = getParam(params, 'fin');
                    idMed  = (idMed)  ? parseInt(idMed)  : 0;
                    inicio = (inicio) ? new Date(parseInt(inicio)*1000) : null;
                    fin = (fin) ? new Date(parseInt(fin)*1000) : null;

                    newInfoPagina = {
                        codigoPagina:partes[0],
                        tipoPagina:'FormularioBusqueda',
                        idMed: idMed,
                        inicio: inicio,
                        fin: fin,
                    }
                    if (newInfoPagina.codigoPagina.toLowerCase().substring(0,1)==="b"){
                        //Es la búsqueda
                        var filtros = getParam(params, 'filtro');
                        if (filtros)
                            var misFiltros = ParseaFiltros(filtros);
                        var snumMostrar = getParam(params, 'numMostrar');
                        var sidxOrden = getParam(params, 'idxOrden');
                        newInfoPagina.tipoPagina = 'ResultadosBusqueda';
                        newInfoPagina.numMostrar = (snumMostrar) ? parseInt(snumMostrar) : 10;
                        newInfoPagina.idxOrden = (sidxOrden) ? parseInt(sidxOrden) : 3
                        newInfoPagina.filtrosActivos = misFiltros;
                    }
                break;
            }
        }

        if (newInfoPagina)
            if (!deepEqual(newInfoPagina, infoPagina)){
                setInfoPagina(newInfoPagina);                
            }                
        return;
    }
      
    const onBackButtonEvent = (e) => {
        construyeInfoPaginaFromPath();
    }
    
    const AccionesComunesBusqueda = (datosPagina) =>{
        if (datosPagina.newlista != infoPagina.listaRes){
            var newInfoPagina = {
                codigoPagina: datosPagina.newpagina,
                tipoPagina:'ResultadosBusqueda',
                listaRes: datosPagina.newlista,
                titulo: datosPagina.titulo,
                texto: datosPagina.texto,
                id: datosPagina.newid, 
                seleccionados: datosPagina.seleccionados,
                fuerzaTexto : false,
                filtros : datosPagina.filtros,
                numMostrar : (datosPagina.numMostrar) ? datosPagina.numMostrar : 10,
                idxOrden : (datosPagina.idxOrden) ? datosPagina.idxOrden : 0,
                filtrosActivos : (datosPagina.filtrosActivos) ? datosPagina.filtrosActivos : [],
                completa: datosPagina.completa,
                total: datosPagina.total,
                idEpi: datosPagina.idEpi,
                idsEnfs: datosPagina.idsEnfs,
                idsMeds: datosPagina.idsMeds,
                txt: datosPagina.txt,
                idLab: datosPagina.idLab,
                idGT: datosPagina.idGT,
                idsLab: datosPagina.idsLab,
                idsGT: datosPagina.idsGT,
                pas: datosPagina.pas,
                solo: datosPagina.solo,
                idVia: datosPagina.idVia,
                idFF: datosPagina.idFF,
                ints: datosPagina.ints,
                uds: datosPagina.uds,
                emr: datosPagina.emr,
                edad: datosPagina.edad,
                undEdad: datosPagina.undEdad,
                enfsRel: datosPagina.enfsRel,
                anasRel: datosPagina.anasRel,
                ops: datosPagina.ops,
                idEspecie: datosPagina.idEspecie,
                idEnf: datosPagina.idEnf,
                idTipo: datosPagina.idTipo,
                idSubtipo: datosPagina.idSubtipo,
                idsTipo: datosPagina.idsTipo,
                idsSubtipo: datosPagina.idsSubtipo,
                tipoFF: datosPagina.tipoFF,
                idForma: datosPagina.idForma,
                idColor1: datosPagina.idColor1,
                idColor2: datosPagina.idColor2,
                idSabor: datosPagina.idSabor,
                CO1: datosPagina.CO1,
                CO2: datosPagina.CO2,
                tamanos: datosPagina.tamanos,
                ranura: datosPagina.ranura,
                logo: datosPagina.logo,
                difCol: datosPagina.difCol,
                anycol: datosPagina.anycol,
                col2: datosPagina.col2,
                ins1: datosPagina.ins1,
                ins2: datosPagina.ins2,
                idPais: datosPagina.idPais,
                inicio: datosPagina.inicio,
                fin: datosPagina.fin,
                pvpMenor : datosPagina.pvpMenor,
                pvpMasBajo : datosPagina.pvpMasBajo,
                literalPMenor : datosPagina.literalPMenor,
                literalPMasBajo : datosPagina.literalPMasBajo,
                fechaAutoIni: datosPagina.fechaAutoIni,
                fechaAutoFin: datosPagina.fechaAutoFin,
                fechaBajaIni: datosPagina.fechaBajaIni,
                fechaBajaFin: datosPagina.fechaBajaFin,
                fechaExFIIni: datosPagina.fechaExFIIni,
                fechaExFIFin: datosPagina.fechaExFIFin,
                fechaExSNSIni: datosPagina.fechaExSNSIni,
                fechaExSNSFin: datosPagina.fechaExSNSFin,
                fechaInSNSIni: datosPagina.fechaInSNSIni,
                fechaInSNSFin: datosPagina.fechaInSNSFin,
                fechaComerIni: datosPagina.fechaComerIni,
                fechaComerFin: datosPagina.fechaComerFin,
                idCampoP: datosPagina.idCampoP,
                idOpP: datosPagina.idOpP,
                pre: datosPagina.pre,
                idPredefP: datosPagina.idPredefP,
                idsAA: datosPagina.idsAA,
                idsAN: datosPagina.idsAN,
                idsDC: datosPagina.idsDC,
                idsMen: datosPagina.idsMen,
                idEpi: datosPagina.idEpi,
                texto: datosPagina.texto,
                noTexto : datosPagina.noTexto,
                opb: datosPagina.opb,
            }
            setInfoPagina(newInfoPagina);
        }
    }

    const QuitaFiltrosURL = (miUrl) =>{
        var idx = miUrl.indexOf("&filtro=");
        var newUrl = miUrl;
        if (idx > 0)
            newUrl = miUrl.substr(0,idx);
        idx = newUrl.indexOf("&numMostrar=");
        if (idx>0)
            newUrl = newUrl.substr(0,idx);
        idx = newUrl.indexOf("&idxOrden=");
        if (idx>0)
            newUrl = newUrl.substr(0,idx);
        return newUrl;    
    }

    const MismaURL = (url1, url2) =>{
        url1 = decodeURI(url1);
        url2 = decodeURI(url2);
        var sinparametros1 = url1.split('?')[0];
        var sinparametros2 = url2.split('?')[0];
        var params1 = getParamsURL(url1);
        var params2 = getParamsURL(url2);
        var txt1 = getParam(params1, 'txt');
        var txt2 = getParam(params2, 'txt');
        var res = (sinparametros1 === sinparametros2 && txt1===txt2);
        return res;
    }

    const CompletaURLBusqueda = (datosPagina) =>{
        var addToUrl = '';
        if (infoPagina.tipoPagina==="ResultadosBusqueda"){
            if (datosPagina.filtros){
                var filtrosStr = GetFiltrosStr(datosPagina.filtros);
                if (filtrosStr!=='')
                    addToUrl += '&filtro=' + filtrosStr;
            }
            if (datosPagina.numMostrar>=0){
                addToUrl += '&numMostrar=' + datosPagina.numMostrar.toString();
            }
            if (datosPagina.idxOrden>=0){
                addToUrl += '&idxOrden=' + datosPagina.idxOrden.toString();
            }
        }
        return addToUrl;
    }

    const AccionesComunesFicha = (currentpath, datosPagina) =>{
        if (infoPagina.tipoPagina==="ResultadosBusqueda"){
            var changedpath = QuitaFiltrosURL(currentpath);
            var newpath = changedpath + CompletaURLBusqueda(datosPagina);
            if (newpath !== currentpath)
                window.history.replaceState(null,'',newpath);
        }
    }

    const checkSesion = (datosPagina) => {
        AuthService.isSesionOk()
            .then((response) => {
                if (!response)
                    props.checkSesion()
            });
    }
        
    const CambiaAPagina = (datosPagina) => {
        checkSesion();
        CierraMenuBurguer();
        const currentpath = history.location.pathname + history.location.search;
        var newpath = currentpath;
        var newpagina = datosPagina.newpagina;
        //console.log(datosPagina.newpagina);
        if (accesoRestringido !== validaAcceso(newpagina))
            setAccesoRestringido(validaAcceso(newpagina));

        if (getIdProvincia()>0 || newpagina==="Configuracion"){                
            switch(newpagina){
                case "Inicio":
                    newpath = rootPath;
                break;
                case "Ayuda":
                case "Help":
                    newpath = rootPath  + newpagina;
                break;
                case "ExportaWS":
                    newpath = rootPath  + newpagina;
                break;
                case "BInicio":
                    newpath = rootPath;
                    newpath += newpagina + "?txt=" + datosPagina.texto;
                    if (datosPagina.opb > 0)
                        newpath += "&opb=" + datosPagina.opb.toString();
                    newpath += CompletaURLBusqueda(datosPagina);
                    AccionesComunesBusqueda(datosPagina);
                    break;
                case "BLaboratorioMUV":
                case "BLaboratorioMUH":
                case "BLaboratorioMHO":
                case "BLaboratorioPPF":
                case "BFormaFarmaMUH":
                case "BFormaFarmaMUV":
                case "BFormaFarmaPPF":
                case "BAFMUH":
                case "BAMMUH":
                case "BActPPF":
                case "BAFMUV":
                case "BAgrupHomoAutoMUH":
                case "BAgrupHomoMUH":
                case "BAgrupHomoAutoPPF":
                case "BAgrupHomoPPF":
                case "BAnalisisMUH":
                case "BFarmacogeneticaMUH":
                case "BPAMUH":
                case "BAsoMUH":
                case "BPAMUV":
                case "BAsoMUV":
                case "BIngPPF":
                case "BTipoPPF":
                case "BSubtipoPPF":
                case "BIndicacionMUH":
                case "BIndicacionMUV":
                case "BIndicacionPPF":    
                case "BContraindicacionMUH":
                case "BPrecaucionMUH":
                case "BReaccionAdversaMUH":
                case "BGTATCMUH":
                case "BGTATCMUV":
                case "BGTVMPPMUH":
                case "BGTConPPF":
                case "BGTSanPPF":
                case "BFormMagistralMUH":
                case "BMedsHomeoMUH":
                case "BMedEspanolesMUH":
                case "BMismoNombreMUH":
                    newpath = rootPath;
                    newpath += newpagina + "?id=" + datosPagina.newid;
                    newpath += CompletaURLBusqueda(datosPagina);
                    if (infoPagina.tipoPagina==="FormularioBusqueda"){
                        window.history.replaceState(null,'',history.location.pathname+ "?id=" + datosPagina.newid);
                    }
                    AccionesComunesBusqueda(datosPagina);
                    break;
                case "BLaboratoriosMUV":
                case "BLaboratoriosMUH":
                case "BLaboratoriosMHO":
                case "BLaboratoriosPPF":
                case "BFormasFarmaMUH":
                case "BFormasFarmaMUV":
                case "BFormasFarmaPPF":
                case "BAFsMUH":
                case "BAMsMUH":
                case "BActsPPF":
                case "BAFsMUV":
                case "BGTsATCMUH":
                case "BGTsATCMUV":
                    newpath = rootPath;
                    newpath += newpagina + "?seleccionados=" + datosPagina.seleccionados.toString();
                    newpath += CompletaURLBusqueda(datosPagina);
                    if (infoPagina.tipoPagina==="FormularioBusqueda"){
                        window.history.replaceState(null,'',history.location.pathname+ "?seleccionados=" + datosPagina.seleccionados.toString());
                    }
                    AccionesComunesBusqueda(datosPagina);
                    break;
                case "BConGluten":
                case "BConLactosa":
                case "BSinGluten":
                case "BSinLactosa":
                case "BDopantesMUH":
                case "BNoLentes":
                case "BComparaPrecioMenorMUH":
                case "BComparaPrecioMasBajoMUH":
                case "BPrecioNotificadoMUH":
                case "BSeguimientoAdicionalMUH":
                case "BDispositivoSeguridadMUH":
                case "BLibroRecetarioM":
                case "BLibroEstupefacientesM":
                case "BNotificacionESVACMUV":
                case "BEnfermedadesPATH":
                case "BEnfermedadesPATV":
                case "BNoSustituiblesMUH":
                case "BDeduccionesRDLMUH":
                case "BCambioPrecioPrevistoMUH":
                case "BAlertadosCismedMUH":
                    newpath = rootPath;
                    newpath += newpagina + "?lst";
                    newpath += CompletaURLBusqueda(datosPagina);
                    AccionesComunesBusqueda(datosPagina);
                break;

                case "BSaborEdadPPF":
                    newpath = rootPath + newpagina + "?se";
                    if (datosPagina.idDieto != "")
                        newpath += "&idDieto=" + datosPagina.idDieto;
                    if (datosPagina.idsSabores && (datosPagina.idsSabores.length > 0))
                        newpath += "&idsSabores=" + datosPagina.idsSabores.toString();
                    if (datosPagina.saborSolo)
                        newpath += "&saborSolo=S";
                    if (datosPagina.edad)
                        newpath += "&edad=" + datosPagina.edad.toString();
                    if (datosPagina.undEdad)
                        newpath += "&undEdad=" + datosPagina.undEdad;
                    newpath += CompletaURLBusqueda(datosPagina);
                    if (infoPagina.tipoPagina==="FormularioBusqueda"){
                        window.history.replaceState(null,'',newpath.replace(newpagina, infoPagina.codigoPagina));
                    }
                    AccionesComunesBusqueda(datosPagina);
                    break;

                case "BProblemasSumMUH":
                    newpath = rootPath + newpagina + "?ps";
                    if (datosPagina.txt && datosPagina.txt !== '')
                        newpath += "&med=" + datosPagina.txt;
                    if (datosPagina.idLab > 0)
                        newpath += "&idLab=" + datosPagina.idLab.toString();
                    if (datosPagina.idPA > 0)
                        newpath += "&idPA=" + datosPagina.idPA.toString();
                    if (datosPagina.noSust)
                        newpath += "&noSust=S";
                    if (datosPagina.medExt)
                        newpath += "&medExt=S";
                    if (datosPagina.iniciod)
                        newpath += "&iniciod=" + Math.round(datosPagina.iniciod.getTime()/1000).toString();
                    if (datosPagina.inicioh)
                        newpath += "&inicioh=" + Math.round(datosPagina.inicioh.getTime()/1000).toString();
                    if (datosPagina.find)
                        newpath += "&find=" + Math.round(datosPagina.find.getTime()/1000).toString();
                    if (datosPagina.finh)
                        newpath += "&finh=" + Math.round(datosPagina.finh.getTime()/1000).toString();
                    newpath += CompletaURLBusqueda(datosPagina);
                    if (infoPagina.tipoPagina==="FormularioBusqueda"){
                            window.history.replaceState(null,'',newpath.replace(newpagina, infoPagina.codigoPagina));
                    }
                    AccionesComunesBusqueda(datosPagina);
                    break;

                case "BAlertasCMUH":
                case "BAlertasCMUV":
                case "BAlertasCPPF":
                    newpath = rootPath + newpagina + "?ac";
                    if (datosPagina.txt && datosPagina.txt !== '')
                        newpath += "&med=" + datosPagina.txt;
                    if (datosPagina.txtLote && datosPagina.txtLote !== '')
                        newpath += "&txtLote=" + datosPagina.txtLote;
                    if (datosPagina.idLab > 0)
                        newpath += "&idLab=" + datosPagina.idLab.toString();
                    if (datosPagina.idPA > 0)
                        newpath += "&idPA=" + datosPagina.idPA.toString();
                    if (datosPagina.inicio)
                        newpath += "&inicio=" + Math.round(datosPagina.inicio.getTime()/1000).toString();
                    if (datosPagina.fin)
                        newpath += "&fin=" + Math.round(datosPagina.fin.getTime()/1000).toString();
                    newpath += CompletaURLBusqueda(datosPagina);
                    if (infoPagina.tipoPagina==="FormularioBusqueda"){
                            window.history.replaceState(null,'',newpath.replace(newpagina, infoPagina.codigoPagina));
                    }
                    AccionesComunesBusqueda(datosPagina);
                    break;

                case "BCambioNombre":
                case "BCambioLaboratorio":
                case "BCambioPrecioMUH":
                case "BDesfinanciadosMUH":
                case "BFechaAlta":
                    newpath = rootPath + newpagina + "?bf";
                    if (datosPagina.inicio)
                        newpath += "&inicio=" + Math.round(datosPagina.inicio.getTime()/1000).toString();
                    if (datosPagina.fin)
                        newpath += "&fin=" + Math.round(datosPagina.fin.getTime()/1000).toString();
                    newpath += CompletaURLBusqueda(datosPagina);
                    if (infoPagina.tipoPagina==="FormularioBusqueda"){
                            window.history.replaceState(null,'',newpath.replace(newpagina, infoPagina.codigoPagina));
                    }
                    AccionesComunesBusqueda(datosPagina);
                break;

                case "BNovedadesUltimoMes":
                case "BMedicamentosPSUltimoMes":
                case "BHistorialUsuario":
                    newpath = rootPath + newpagina + "?um";
                    newpath += CompletaURLBusqueda(datosPagina);
                    AccionesComunesBusqueda(datosPagina);
                break;

                case "BExtranjerosEXT":
                    newpath = rootPath + newpagina + "?ex";
                    if (datosPagina.newid > 0)
                        newpath += "&id=" + datosPagina.newid.toString();
                    if (datosPagina.txt && datosPagina.txt !== '')
                        newpath += "&med=" + datosPagina.txt;
                    if (datosPagina.idPais > 0)
                        newpath += "&idPais=" + datosPagina.idPais.toString();
                    if (datosPagina.pas && (datosPagina.pas.length > 0))
                        newpath += "&pas=" +  datosPagina.pas.toString();
                    if (datosPagina.solo)
                        newpath += "&solo=S";
                    else
                        newpath += "&solo=N";
                    newpath += CompletaURLBusqueda(datosPagina);
                    if (infoPagina.tipoPagina==="FormularioBusqueda"){
                            window.history.replaceState(null,'',newpath.replace(newpagina, infoPagina.codigoPagina));
                    }
                    AccionesComunesBusqueda(datosPagina);
                    break;


                case "BEspecieIndicacionMUV":
                    newpath = rootPath + newpagina + "?ei";
                    if (datosPagina.idEspecie != "")
                        newpath += "&idEspecie=" + datosPagina.idEspecie;
                    if (datosPagina.idEnf != "")
                        newpath += "&idEnf=" + datosPagina.idEnf;
                    newpath += CompletaURLBusqueda(datosPagina);
                    if (infoPagina.tipoPagina==="FormularioBusqueda"){
                        window.history.replaceState(null,'',newpath.replace(newpagina, infoPagina.codigoPagina));
                    }
                    AccionesComunesBusqueda(datosPagina);
                    break;
    
                case "BReaccionesAdversasMUH":
                    newpath = rootPath + newpagina + "?se";
                    if (datosPagina.idEpi > 0)
                        newpath += "&idEpi=" + datosPagina.idEpi;
                    if (datosPagina.idsEnfs && (datosPagina.idsEnfs.length > 0))
                        newpath += "&idsEnfs=" + datosPagina.idsEnfs.toString();
                    if (datosPagina.idsMeds && (datosPagina.idsMeds.length > 0))
                        newpath += "&idsMeds=" + datosPagina.idsMeds.toString();
                    newpath += CompletaURLBusqueda(datosPagina);
                    if (infoPagina.tipoPagina==="FormularioBusqueda"){
                        window.history.replaceState(null,'',newpath.replace(newpagina, infoPagina.codigoPagina));
                    }
                    AccionesComunesBusqueda(datosPagina);
                    break;

                case "BComposicionMUH":
                case "BComposicionMUV":
                case "BComposicionPPF":
                    newpath = rootPath + newpagina + "?c";
                    if (datosPagina.tipoProd != "")
                        newpath += "&tipoProd=" + datosPagina.tipoProd;
                    if (datosPagina.pas && (datosPagina.pas.length > 0))
                        newpath += "&pas=" + fromListaPAsComposToParamURL(datosPagina.pas);
                    if (datosPagina.solo)
                        newpath += "&solo=S";
                    else
                        newpath += "&solo=N";
                    if (datosPagina.idVia>0)
                        newpath += "&idVia=" + datosPagina.idVia.toString();
                    if (datosPagina.idFF>0)
                        newpath += "&idFF=" + datosPagina.idFF.toString();
                    if (datosPagina.uds>0)
                        newpath += "&uds=" + datosPagina.uds.toString();
                    if (datosPagina.pv>0)
                        newpath += "&pv=" + datosPagina.pv.toString();
                    if (datosPagina.ints && (datosPagina.ints.length > 0))
                        newpath += "&ints=" + datosPagina.ints.toString();
                    newpath += CompletaURLBusqueda(datosPagina);
                    if (infoPagina.tipoPagina==="FormularioBusqueda"){
                        window.history.replaceState(null,'',newpath.replace(newpagina, infoPagina.codigoPagina));
                    }
                    AccionesComunesBusqueda(datosPagina);
                    break;

                case "BPacienteIndicacionMUH":
                    newpath = rootPath + newpagina + "?av";
                    if (datosPagina.idEnf>0)
                        newpath += "&idEnf=" + datosPagina.idEnf.toString();
                    if (datosPagina.idsPat && (datosPagina.idsPat.length > 0))
                        newpath += "&idsPat=" + datosPagina.idsPat.toString();
                    if (datosPagina.idsMed && (datosPagina.idsMed.length > 0))
                        newpath += "&idsMed=" + datosPagina.idsMed.toString();
                    if (datosPagina.idVia>0)
                        newpath += "&idVia=" + datosPagina.idVia.toString();
                    if (datosPagina.idFF>0)
                        newpath += "&idFF=" + datosPagina.idFF.toString();
                    if (datosPagina.edad)
                        newpath += "&edad=" + datosPagina.edad.toString();
                    if (datosPagina.undEdad)
                        newpath += "&undEdad=" + datosPagina.undEdad;
                    if (datosPagina.ints && (datosPagina.ints.length > 0))
                        newpath += "&ints=" + datosPagina.ints.toString();
                    if (datosPagina.ops && (datosPagina.ops.length > 0))
                        newpath += "&ops=" + fromListaOpsAvanzadaToParamURL(datosPagina.ops);
                    if (datosPagina.idsAlg && (datosPagina.idsAlg.length > 0))
                        newpath += "&idsAlg=" + datosPagina.idsAlg.toString();
                    newpath += CompletaURLBusqueda(datosPagina);
                    if (infoPagina.tipoPagina==="FormularioBusqueda"){
                        window.history.replaceState(null,'',newpath.replace(newpagina, infoPagina.codigoPagina));
                    }
                    AccionesComunesBusqueda(datosPagina);
                break;

                case "BProductosIndicacionPPF":
                    newpath = rootPath + newpagina + "?pi";
                    if (datosPagina.idEnf>0)
                        newpath += "&idEnf=" + datosPagina.idEnf.toString();
                    if (datosPagina.idFF>0)
                        newpath += "&idFF=" + datosPagina.idFF.toString();
                    if (datosPagina.pas && (datosPagina.pas.length > 0))
                        newpath += "&pas=" + fromListaPAsComposToParamURL(datosPagina.pas);
                    if (datosPagina.ints && (datosPagina.ints.length > 0))
                        newpath += "&ints=" + datosPagina.ints.toString();
                    if (datosPagina.ops && (datosPagina.ops.length > 0))
                        newpath += "&ops=" + fromListaOpsAvanzadaToParamURL(datosPagina.ops);
                    newpath += CompletaURLBusqueda(datosPagina);
                    if (infoPagina.tipoPagina==="FormularioBusqueda"){
                        window.history.replaceState(null,'',newpath.replace(newpagina, infoPagina.codigoPagina));
                    }
                    AccionesComunesBusqueda(datosPagina);
                break;

                case "BFormaColorMUH":
                    newpath = rootPath + newpagina + "?fc";
                    if (datosPagina.tipoFF!=='')
                        newpath += "&tipoFF=" + datosPagina.tipoFF;
                    if (datosPagina.idForma>0)
                        newpath += "&idForma=" + datosPagina.idForma.toString();
                    if (datosPagina.idColor1!=="")
                        newpath += "&idColor1=" + datosPagina.idColor1.toString();
                    if (datosPagina.idColor2!=="")
                        newpath += "&idColor2=" + datosPagina.idColor2.toString();
                    if (datosPagina.idSabor>0)
                        newpath += "&idSabor=" + datosPagina.idSabor.toString();
                    if (datosPagina.idEnf>0)
                        newpath += "&idEnf=" + datosPagina.idEnf.toString();
                    if (datosPagina.tamanos && (datosPagina.tamanos.length > 0))
                        newpath += "&tamanos=" + datosPagina.tamanos.toString();
                    if (datosPagina.CO1)
                        newpath += "&CO1=" + datosPagina.CO1;
                    if (datosPagina.CO2)
                        newpath += "&CO2=" + datosPagina.CO2;
                    if (datosPagina.col2)
                        newpath += "&col2=S"                        
                    if (datosPagina.anycol)
                        newpath += "&anycol=S"                        
                    if (datosPagina.ranura)
                        newpath += "&ranura=S"                        
                    if (datosPagina.logo)
                        newpath += "&logo=S"                        
                    if (datosPagina.difCol)
                        newpath += "&difCol=S"                        
                    if (datosPagina.ins1 && datosPagina.ins1 !== '')
                        newpath += "&ins1=" + datosPagina.ins1;
                    if (datosPagina.ins2 && datosPagina.ins2 !== '')
                        newpath += "&ins2=" + datosPagina.ins2;
                    if (datosPagina.pas && (datosPagina.pas.length > 0))
                        newpath += "&pas=" + fromListaPAsComposToParamURL(datosPagina.pas);
                    if (datosPagina.solo)
                        newpath += "&solo=S";
                    else
                        newpath += "&solo=N";
                    newpath += CompletaURLBusqueda(datosPagina);
                    if (infoPagina.tipoPagina==="FormularioBusqueda"){
                        window.history.replaceState(null,'',newpath.replace(newpagina, infoPagina.codigoPagina));
                    }
                    AccionesComunesBusqueda(datosPagina);
                break;

                case "BAvanzadaMUH":
                    newpath = rootPath + newpagina + "?av";
                    if (datosPagina.txt && datosPagina.txt !== '')
                        newpath += "&med=" + datosPagina.txt;
                    /*if (datosPagina.idLab > 0)
                        newpath += "&idLab=" + datosPagina.idLab.toString();
                    if (datosPagina.idGT && datosPagina.idGT !== '')
                        newpath += "&idGT=" + datosPagina.idGT;*/

                    if (datosPagina.idsLab && (datosPagina.idsLab.length > 0))
                        newpath += "&idsLab=" + datosPagina.idsLab.toString();
                    if (datosPagina.idsGT && (datosPagina.idsGT.length > 0))
                        newpath += "&idsGT=" + datosPagina.idsGT.toString();

                    if (datosPagina.pas && (datosPagina.pas.length > 0))
                        newpath += "&pas=" + fromListaPAsComposToParamURL(datosPagina.pas);
                    if (datosPagina.solo)
                        newpath += "&solo=S";
                    else
                        newpath += "&solo=N";
                    if (datosPagina.idVia>0)
                        newpath += "&idVia=" + datosPagina.idVia.toString();
                    if (datosPagina.idFF>0)
                        newpath += "&idFF=" + datosPagina.idFF.toString();
                    if (datosPagina.uds)
                        newpath += "&uds=" + datosPagina.uds.toString();
                    if (datosPagina.emr)
                        newpath += "&emr=" + datosPagina.emr.toString();
                    if (datosPagina.edad)
                        newpath += "&edad=" + datosPagina.edad.toString();
                    if (datosPagina.undEdad)
                        newpath += "&undEdad=" + datosPagina.undEdad;
                    if (datosPagina.ints && (datosPagina.ints.length > 0))
                        newpath += "&ints=" + datosPagina.ints.toString();
                    if (datosPagina.ops && (datosPagina.ops.length > 0))
                        newpath += "&ops=" + fromListaOpsAvanzadaToParamURL(datosPagina.ops);

                    if (datosPagina.fechaAutoIni)
                        newpath += "&fechaAutoIni=" + Math.round(datosPagina.fechaAutoIni.getTime()/1000).toString();
                    if (datosPagina.fechaAutoFin)
                        newpath += "&fechaAutoFin=" + Math.round(datosPagina.fechaAutoFin.getTime()/1000).toString();
                    if (datosPagina.fechaBajaIni)
                        newpath += "&fechaBajaIni=" + Math.round(datosPagina.fechaBajaIni.getTime()/1000).toString();
                    if (datosPagina.fechaBajaFin)
                        newpath += "&fechaBajaFin=" + Math.round(datosPagina.fechaBajaFin.getTime()/1000).toString();
                    if (datosPagina.fechaComerIni)
                        newpath += "&fechaComerIni=" + Math.round(datosPagina.fechaComerIni.getTime()/1000).toString();
                    if (datosPagina.fechaComerFin)
                        newpath += "&fechaComerFin=" + Math.round(datosPagina.fechaComerFin.getTime()/1000).toString();
                    if (datosPagina.fechaInSNSIni)
                        newpath += "&fechaInSNSIni=" + Math.round(datosPagina.fechaInSNSIni.getTime()/1000).toString();
                    if (datosPagina.fechaInSNSFin)
                        newpath += "&fechaInSNSFin=" + Math.round(datosPagina.fechaInSNSFin.getTime()/1000).toString();
                    if (datosPagina.fechaExSNSIni)
                        newpath += "&fechaExSNSIni=" + Math.round(datosPagina.fechaExSNSIni.getTime()/1000).toString();
                    if (datosPagina.fechaExSNSFin)
                        newpath += "&fechaExSNSFin=" + Math.round(datosPagina.fechaExSNSFin.getTime()/1000).toString();
                    if (datosPagina.fechaExFIIni)
                        newpath += "&fechaExFIIni=" + Math.round(datosPagina.fechaExFIIni.getTime()/1000).toString();
                    if (datosPagina.fechaExFIFin)
                        newpath += "&fechaExFIFin=" + Math.round(datosPagina.fechaExFIFin.getTime()/1000).toString();
                    if (datosPagina.idCampoP)
                        newpath += "&idCampoP=" + datosPagina.idCampoP;
                    if (datosPagina.idOpP)
                        newpath += "&idOpP=" + datosPagina.idOpP.toString();
                    if (datosPagina.idPredefP)
                        newpath += "&idPredefP=" + datosPagina.idPredefP.toString();
                    if (datosPagina.pre)
                        newpath += "&pre=" + Math.trunc(datosPagina.pre * 100).toString();
                    if (datosPagina.idsAA && (datosPagina.idsAA.length > 0))
                        newpath += "&idsAA=" + datosPagina.idsAA.toString();
                    if (datosPagina.idsAN && (datosPagina.idsAN.length > 0))
                        newpath += "&idsAN=" + datosPagina.idsAN.toString();
                    if (datosPagina.idsDC && (datosPagina.idsDC.length > 0))
                        newpath += "&idsDC=" + datosPagina.idsDC.toString();
                    if (datosPagina.idsMen && (datosPagina.idsMen.length > 0))
                        newpath += "&idsMen=" + datosPagina.idsMen.toString();
                    if (datosPagina.texto && datosPagina.texto !== '')
                        newpath += "&texto=" + encodeURI(datosPagina.texto);
                    if (datosPagina.noTexto)
                        newpath += "&noTexto=S"
                    if (datosPagina.idEpi)
                        newpath += "&idEpi=" + datosPagina.idEpi.toString();

                    if (datosPagina.enfsRel && (datosPagina.enfsRel.length > 0)){
                        var strEnf = "";
                        for (var i=0; i<datosPagina.enfsRel.length; i++){
                            if (i>0) strEnf += ","
                            strEnf += datosPagina.enfsRel[i].codigo + "_" + datosPagina.enfsRel[i].epi;
                        }
                        newpath += "&enfsRel=" + strEnf;
                    }                        
                    if (datosPagina.anasRel && (datosPagina.anasRel.length > 0)){
                        var strAna = "";
                        for (var i=0; i<datosPagina.anasRel.length; i++){
                            if (i>0) strAna += ","
                            strAna += datosPagina.anasRel[i].codigo + "_" + datosPagina.anasRel[i].epi;
                        }
                        newpath += "&anasRel=" + strAna;
                    }                        
                    newpath += CompletaURLBusqueda(datosPagina);
                    if (infoPagina.tipoPagina==="FormularioBusqueda"){
                        window.history.replaceState(null,'',newpath.replace(newpagina, infoPagina.codigoPagina));
                    }
                    AccionesComunesBusqueda(datosPagina);
                break;
                case "BAvanzadaMUV":
                    newpath = rootPath + newpagina + "?av";
                    if (datosPagina.txt && datosPagina.txt !== '')
                        newpath += "&med=" + datosPagina.txt;
                    /*if (datosPagina.idLab > 0)
                        newpath += "&idLab=" + datosPagina.idLab.toString();
                    if (datosPagina.idGT && datosPagina.idGT !== '')
                        newpath += "&idGT=" + datosPagina.idGT;*/

                    if (datosPagina.idsLab && (datosPagina.idsLab.length > 0))
                        newpath += "&idsLab=" + datosPagina.idsLab.toString();
                    if (datosPagina.idsGT && (datosPagina.idsGT.length > 0))
                        newpath += "&idsGT=" + datosPagina.idsGT.toString();

                    if (datosPagina.pas && (datosPagina.pas.length > 0))
                        newpath += "&pas=" + fromListaPAsComposToParamURL(datosPagina.pas);
                    if (datosPagina.solo)
                        newpath += "&solo=S";
                    else
                        newpath += "&solo=N";
                    if (datosPagina.idVia>0)
                        newpath += "&idVia=" + datosPagina.idVia.toString();
                    if (datosPagina.idFF>0)
                        newpath += "&idFF=" + datosPagina.idFF.toString();
                    if (datosPagina.uds)
                        newpath += "&uds=" + datosPagina.uds.toString();
                    if (datosPagina.fechaAutoIni)
                        newpath += "&fechaAutoIni=" + Math.round(datosPagina.fechaAutoIni.getTime()/1000).toString();
                    if (datosPagina.fechaAutoFin)
                        newpath += "&fechaAutoFin=" + Math.round(datosPagina.fechaAutoFin.getTime()/1000).toString();
                    if (datosPagina.fechaBajaIni)
                        newpath += "&fechaBajaIni=" + Math.round(datosPagina.fechaBajaIni.getTime()/1000).toString();
                    if (datosPagina.fechaBajaFin)
                        newpath += "&fechaBajaFin=" + Math.round(datosPagina.fechaBajaFin.getTime()/1000).toString();
                    if (datosPagina.ops && (datosPagina.ops.length > 0))
                        newpath += "&ops=" + fromListaOpsAvanzadaToParamURL(datosPagina.ops);
                    if (datosPagina.idEspecie != "")
                        newpath += "&idEspecie=" + datosPagina.idEspecie;
                    if (datosPagina.idEnf != "")
                        newpath += "&idEnf=" + datosPagina.idEnf;
                    if (datosPagina.idsMen && (datosPagina.idsMen.length > 0))
                        newpath += "&idsMen=" + datosPagina.idsMen.toString();
                    if (datosPagina.texto && datosPagina.texto !== '')
                        newpath += "&texto=" + encodeURI(datosPagina.texto);
                    if (datosPagina.noTexto)
                        newpath += "&noTexto=S"
                    if (datosPagina.idEpi)
                        newpath += "&idEpi=" + datosPagina.idEpi.toString();
                    newpath += CompletaURLBusqueda(datosPagina);
                    if (infoPagina.tipoPagina==="FormularioBusqueda"){
                        window.history.replaceState(null,'',newpath.replace(newpagina, infoPagina.codigoPagina));
                    }
                    AccionesComunesBusqueda(datosPagina);
                break;
    
                case "BAvanzadaPAH":
                    newpath = rootPath + newpagina + "?av";
                    if (datosPagina.txt && datosPagina.txt !== '')
                        newpath += "&med=" + datosPagina.txt;
                    if (datosPagina.idsAF && (datosPagina.idsAF.length > 0))
                        newpath += "&idsAF=" + datosPagina.idsAF.toString();
                    if (datosPagina.idsAM && (datosPagina.idsAM.length > 0))
                        newpath += "&idsAM=" + datosPagina.idsAM.toString();
                    /*if (datosPagina.idGT && datosPagina.idGT !== '')
                        newpath += "&idGT=" + datosPagina.idGT;*/
                    if (datosPagina.idsGT && (datosPagina.idsGT.length > 0))
                        newpath += "&idsGT=" + datosPagina.idsGT.toString();
                    if (datosPagina.ops && (datosPagina.ops.length > 0))
                        newpath += "&ops=" + fromListaOpsAvanzadaToParamURL(datosPagina.ops);
                    if (datosPagina.idsMen && (datosPagina.idsMen.length > 0))
                        newpath += "&idsMen=" + datosPagina.idsMen.toString();
                    if (datosPagina.texto && datosPagina.texto !== '')
                        newpath += "&texto=" + encodeURI(datosPagina.texto);
                    if (datosPagina.noTexto)
                        newpath += "&noTexto=S"
                    if (datosPagina.idEpi)
                        newpath += "&idEpi=" + datosPagina.idEpi.toString();
                    if (datosPagina.enfsRel && (datosPagina.enfsRel.length > 0)){
                        var strEnf = "";
                        for (var i=0; i<datosPagina.enfsRel.length; i++){
                            if (i>0) strEnf += ","
                            strEnf += datosPagina.enfsRel[i].codigo + "_" + datosPagina.enfsRel[i].epi;
                        }
                        newpath += "&enfsRel=" + strEnf;
                    }                        
                    if (datosPagina.anasRel && (datosPagina.anasRel.length > 0)){
                        var strAna = "";
                        for (var i=0; i<datosPagina.anasRel.length; i++){
                            if (i>0) strAna += ","
                            strAna += datosPagina.anasRel[i].codigo + "_" + datosPagina.anasRel[i].epi;
                        }
                        newpath += "&anasRel=" + strAna;
                    }                        
                    newpath += CompletaURLBusqueda(datosPagina);
                    if (infoPagina.tipoPagina==="FormularioBusqueda"){
                        window.history.replaceState(null,'',newpath.replace(newpagina, infoPagina.codigoPagina));
                    }
                    AccionesComunesBusqueda(datosPagina);
                break;
                case "BAvanzadaPPF":
                    newpath = rootPath + newpagina + "?av";
                    if (datosPagina.txt && datosPagina.txt !== '')
                        newpath += "&med=" + datosPagina.txt;
                    /*if (datosPagina.idLab > 0)
                        newpath += "&idLab=" + datosPagina.idLab.toString();
                    if (datosPagina.idGT && datosPagina.idGT !== '')
                        newpath += "&idGT=" + datosPagina.idGT;*/

                    if (datosPagina.idsLab && (datosPagina.idsLab.length > 0))
                        newpath += "&idsLab=" + datosPagina.idsLab.toString();
                    if (datosPagina.idsGT && (datosPagina.idsGT.length > 0))
                        newpath += "&idsGT=" + datosPagina.idsGT.toString();

                    if (datosPagina.pas && (datosPagina.pas.length > 0))
                        newpath += "&pas=" + fromListaPAsComposToParamURL(datosPagina.pas);
                    if (datosPagina.solo)
                        newpath += "&solo=S";
                    else
                        newpath += "&solo=N";
                    if (datosPagina.idVia>0)
                        newpath += "&idVia=" + datosPagina.idVia.toString();
                    if (datosPagina.idFF>0)
                        newpath += "&idFF=" + datosPagina.idFF.toString();
                    /*if (datosPagina.idTipo>0)
                        newpath += "&idTipo=" + datosPagina.idTipo.toString();
                    if (datosPagina.idSubtipo>0)
                        newpath += "&idSubtipo=" + datosPagina.idSubtipo.toString();*/
                    if (datosPagina.idsTipo && (datosPagina.idsTipo.length > 0))
                        newpath += "&idsTipo=" + datosPagina.idsTipo.toString();
                    if (datosPagina.idsSubtipo && (datosPagina.idsSubtipo.length > 0))
                        newpath += "&idsSubtipo=" + datosPagina.idsSubtipo.toString();
                    if (datosPagina.uds)
                        newpath += "&uds=" + datosPagina.uds.toString();
                    if (datosPagina.fechaAutoIni)
                        newpath += "&fechaAutoIni=" + Math.round(datosPagina.fechaAutoIni.getTime()/1000).toString();
                    if (datosPagina.fechaAutoFin)
                        newpath += "&fechaAutoFin=" + Math.round(datosPagina.fechaAutoFin.getTime()/1000).toString();
                    if (datosPagina.fechaBajaIni)
                        newpath += "&fechaBajaIni=" + Math.round(datosPagina.fechaBajaIni.getTime()/1000).toString();
                    if (datosPagina.fechaBajaFin)
                        newpath += "&fechaBajaFin=" + Math.round(datosPagina.fechaBajaFin.getTime()/1000).toString();

                    if (datosPagina.ints && (datosPagina.ints.length > 0))
                        newpath += "&ints=" + datosPagina.ints.toString();
                    if (datosPagina.ops && (datosPagina.ops.length > 0))
                        newpath += "&ops=" + fromListaOpsAvanzadaToParamURL(datosPagina.ops);
                    if (datosPagina.idsMen && (datosPagina.idsMen.length > 0))
                        newpath += "&idsMen=" + datosPagina.idsMen.toString();
                    if (datosPagina.texto && datosPagina.texto !== '')
                        newpath += "&texto=" + encodeURI(datosPagina.texto);
                    if (datosPagina.noTexto)
                        newpath += "&noTexto=S"
                    if (datosPagina.idEpi)
                        newpath += "&idEpi=" + datosPagina.idEpi.toString();
                    newpath += CompletaURLBusqueda(datosPagina);
                    if (infoPagina.tipoPagina==="FormularioBusqueda"){
                        window.history.replaceState(null,'',newpath.replace(newpagina, infoPagina.codigoPagina));
                    }
                    AccionesComunesBusqueda(datosPagina);
                break;
    
                case "BInteraccionesMUH":
                case "BInteraccionesPPF":
                        newpath = rootPath + newpagina + "?ia";
                    if (datosPagina.idsSel && (datosPagina.idsSel.length > 0))
                        newpath += "&idsSel=" + datosPagina.idsSel.toString();
                    newpath += CompletaURLBusqueda(datosPagina);
                break;

                case "BDocumentos":
                    newpath = rootPath + newpagina + "?do";
                    if (datosPagina.idMed > 0)
                        newpath += "&idMed=" + datosPagina.idMed.toString();
                    if (datosPagina.inicio)
                        newpath += "&inicio=" + Math.round(datosPagina.inicio.getTime()/1000).toString();
                    if (datosPagina.fin)
                        newpath += "&fin=" + Math.round(datosPagina.fin.getTime()/1000).toString();
                    newpath += CompletaURLBusqueda(datosPagina);
                    if (infoPagina.tipoPagina==="FormularioBusqueda"){
                            window.history.replaceState(null,'',newpath.replace(newpagina, infoPagina.codigoPagina));
                    }
                    AccionesComunesBusqueda(datosPagina);
                break;

                case "FichaMUH":
                case "FichaMUV":
                case "FichaFM":
                case "FichaPPF":
                case "FichaPAH":
                case "FichaPAV":
                case "FichaASO":
                case "FichaASOH":
                case "FichaASOV":
                case "FichaING": 
                case "FichaMHO": 
                case "FichaPHO": 
                case "FichaPAT":
                case "FichaPATH":
                case "FichaPATV":
                case "FichaEXT":
                    var newpath = rootPath + newpagina + "/" +datosPagina.newid.toString();
                    AccionesComunesFicha(currentpath, datosPagina);
                    break;
                case "Configuracion":
                case "EditaBuscadores":
                case "FBLaboratorioMUH":
                case "FBLaboratorioMUV":
                case "FBLaboratorioPPF":
                case "FBAgrupHomoAutoMUH":
                case "FBAgrupHomoMUH":
                case "FBAgrupHomoAutoPPF":
                case "FBAgrupHomoPPF":
                case "FBFormaFarmaMUH":
                case "FBFormaFarmaMUV":
                case "FBFormaFarmaPPF":
                case "FBAFMUH":
                case "FBAMMUH":
                case "FBActPPF":
                case "FBAFMUV":
                case "FBAnalisisMUH":
                case "FBFarmacogeneticaMUH":
                case "FBGTATCMUH":
                case "FBGTATCMUV":
                case "FBGTVMPPMUH":
                case "FBGTVMPP2MUH":
                case "FBGTConPPF":
                case "FBGTSanPPF":    
                case "FBSaborEdadPPF":
                case "FBEspecieIndicacionMUV":
                case "FBProblemasSumMUH":
                case "FBAlertasCMUH":
                case "FBAlertasCMUV":
                case "FBAlertasCPPF":
                case "FBReaccionesAdversasMUH":
                case "FBComposicionMUH":
                case "FBComposicionMUV":
                case "FBComposicionPPF":
                case "FBPacienteIndicacionMUH":
                case "FBProductosIndicacionPPF":
                case "FBFormaColorMUH":                    
                case "FBAvanzadaMUH":
                case "FBAvanzadaMUV":
                case "FBAvanzadaPAH":
                case "FBAvanzadaPPF":
                case "FBExtranjerosEXT":
                case "FBCambioNombre":
                case "FBCambioLaboratorio":
                case "FBCambioPrecioMUH":
                case "FBDesfinanciadosMUH":
                case "FBFechaAlta":
                case "FBDocumentos":
                
                case "FBTest":    
                    newpath = rootPath;
                    newpath += newpagina;
                    break;

                default:
                    console.log(newpagina);
                    alert("Página no implementada");
                    break;
            }
        }
        else{
            newpath = rootPath;
            newpath += newpagina;
        }
        
        //if (QuitaFiltrosURL(newpath) != QuitaFiltrosURL(currentpath)){
        if (!MismaURL(newpath, currentpath)){
            setLastVisitedPath(currentpath);
            if (newpagina === "BNovedadesUltimoMes"){
                var auxdate = new Date();
                auxdate.setMonth(auxdate.getMonth() - 1);
                var aux = "FBFechaAlta?inicio="+Math.round(auxdate.getTime()/1000).toString();
                history.push(aux);
            }
            if (newpagina === "BMedicamentosPSUltimoMes"){
                var auxdate = new Date();
                auxdate.setMonth(auxdate.getMonth() - 1);
                var aux = "FBProblemasSumMUH?iniciod="+Math.round(auxdate.getTime()/1000).toString();
                history.push(aux);
            }
            history.push(newpath);
            window.scroll({ top: 0, behavior: 'smooth' })
            construyeInfoPaginaFromPath();
        }
        else{
        }
    }

    const Atras = () => {        
        history.goBack();
        //setRefresh (prev => prev + 1 );
    }

    function checkAltoFiltros(e)
    {
        try{
            if (infoPagina && (infoPagina.tipoPagina = 'ResultadosBusqueda')){
                var itemRes = document.getElementById('divContainerRes');
                var itemFil = document.getElementById('divContainerFiltros');
                var offsetsRes =itemRes.getBoundingClientRect();
                var offsetsFil =itemFil.getBoundingClientRect();
                if (itemRes && itemFil && itemRes!==null && itemFil !== null){
                    var topRes = offsetsRes.top;
                    var topFil = offsetsFil.top;
                    var altoFil = itemFil.clientHeight;
                    var altoWin = document.documentElement.clientHeight;
                    var offsetsRes =itemRes.getBoundingClientRect();
                    var offsetsFil =itemFil.getBoundingClientRect();
                    var topRes = offsetsRes.top;
                    var topFil = offsetsFil.top;
                    var altoFil = itemFil.clientHeight;
                    var altoWin = document.documentElement.clientHeight;
                    var minTop = (altoWin - altoFil)-16;
                    if (minTop<topRes)
                        minTop = topRes;
                    var maxTop = 200;
                    var newTopStyle = ''
                    var currentScroll = window.oldScroll-window.scrollY;
                    var newTop = topFil + currentScroll;
                    
                    if (elementIsOfClass(itemRes,'active')){
                        if ((topFil+altoFil>altoWin)||(topFil<200)){
                            if (maxTop + altoFil<=altoWin){
                                newTop = maxTop
                            }else{
                                if (newTop<minTop){
                                    newTop = minTop;
                                }
                                else if (newTop > maxTop){
                                    newTop = maxTop;
                                }
                                else{
                                }
                            }
                            newTopStyle = newTop.toString() + 'px';
                            itemFil.style.top = newTopStyle;
                        }
                    }else{
                        itemFil.style.top = itemRes.style.top;
                    } 
                    window.oldScroll = window.scrollY;       
                }
            }
        }
        catch{}
    };

    const AlHacerScroll = (e) => {
        if (window.pageYOffset === 0){
            if (!isTopWindow)
                setIsTopWindow(true);
            RemoveClassNameToClassItems('header', null, 'active');
            RemoveClassNameToClassItems('contenedor__buscador__sticky.', null, 'active');
            var elements = document.getElementsByTagName('section');
            if (elements.length >0)
            {
                if (!elements[0].classList.contains("AccessNotAllowed"))
                    elements[0].classList.remove('active');
            }
            RemoveClassNameToClassItems('section__buscador', 'container', 'active');
            RemoveClassNameToClassItems('medicamento-infoGeneral', 'nav__info', 'active');
            RemoveClassNameToClassItems('medicamento-infoGeneral', 'medicamento', 'active');
        }
        else{
            if (isTopWindow)
                setIsTopWindow(false);
            AddClassNameToClassItems('header', null, 'active');
            AddClassNameToClassItems('contenedor__buscador__sticky.', null, 'active');
            var elements = document.getElementsByTagName('section');
            if (elements.length >0)
            {
                elements[0].classList.add('active');
            }
            AddClassNameToClassItems('section__buscador', 'container', 'active');
            AddClassNameToClassItems('medicamento-infoGeneral', 'nav__info', 'active');
            AddClassNameToClassItems('medicamento-infoGeneral', 'medicamento', 'active');
        }
        var infogenItems = document.getElementsByClassName('medicamento-infoGeneral');
        var infoGenItem = null;
        if (infogenItems.length>0)
        {
            infoGenItem = infogenItems[0];
            var secciones = document.getElementsByClassName('activeAside');
            var menus = document.getElementsByClassName(' elemento__menu__ficha');
            
            if (window.pageYOffset>=100)
            {
                for (var i = 0, len = secciones.length; i < len; i++) {
                    var y0 = secciones[i].getBoundingClientRect().top;
                    //if (y0>0 && y<= ((window.pageYOffset + 100) - offsetY ))
                    if (y0>-80 && y0<= 200)
                    {
                        RemoveClassNameToClassItems('nav__info', 'elemento', 'active');
                        if (menus.length>i)
                            menus[i].classList.add('active');
                        break;
                    }
                }
            }
            else
            {
                RemoveClassNameToClassItems('nav__info', 'elemento', 'active');
                if (menus.length>0)
                    menus[0].classList.add('active')
            }
        }
        checkAltoFiltros(e);
    }

    const CierraMenuBurguer = () => {
        
        RemoveClassNameToClassItems('child__menu1',null, 'active');
        RemoveClassNameToClassItems('child__menu1',null, 'active2');
        RemoveClassNameToClassItems('child__menu2',null, 'active');
        RemoveClassNameToClassItems('historial__content',null, 'active');
        RemoveClassNameToClassItems('child__1',null, 'active');
        RemoveClassNameToClassItems('child__2',null, 'active');
        RemoveClassNameToClassItems('nenuChilds',null, 'active');
        RemoveClassNameToClassItems('coverToggleMenu',null, 'active');
        RemoveClassNameToClassItems('coverToggleMenu','historial', 'active');
        RemoveClassNameToClassItems('toggle',null, 'active');
        RemoveClassNameToClassItems('backItem__3',null, 'active');
        RemoveClassNameToClassItems("historial",null, 'active');
        RemoveClassNameToClassItems('backItem__2',null, 'active');
        RemoveClassNameToClassItems('backItem__1',null, 'active');
        RemoveClassNameToClassItems("coverMenuHistorial",null, 'active');
        RemoveClassNameToClassItems('list__1',null,'displayblock');
        AddClassNameToClassItems('list__1',null,'displaynone');
        var imagen = document.getElementById('imgMenuBurger');
        if (imagen)
            imagen.src = rootPath + 'img/MBTran0.png';
    }

    const logOut = () => {
        AuthService.logout();
    };    

    const modalContentLicencia = (
        <Licencia licencia = {licenciaAAceptar} alAceptar = {ClickAceptaLicencia} alLogout = {logOut}></Licencia>
    );

    return (
        <div>
            {!haAceptadoLicencia ?
                <Emergente 
                    contenido={modalContentLicencia} 
                    keyName="LicenciaKN" 
                    titulo="Licencia BOT PLUS" 
                    activo={true} 
                    alto={'80%'}
                    ancho={'80%'}
                    ocultaAspa = {true}
                ></Emergente>
            :
                <></>
            }
            <Cabecera cambiaPagina={CambiaAPagina} historial = {historial} cierraMenuBurguer= {CierraMenuBurguer} provincia = {provincia}></Cabecera>
            <div onClick={(e) => CierraMenuBurguer()}>
                {(getIdProvincia()>0) ?
                    (validaAcceso(infoPagina.codigoPagina) ?
                        <Cuerpo infoPagina={infoPagina} 
                                cambiaPagina={CambiaAPagina} 
                                descargaHistorial={DescargaHistorial} 
                                atras={Atras} 
                                refresca={RefrescaProvincia} 
                                returnTo={lastVisitedPath} 
                                borraDeLista={borraDeLista}
                                cambiopass={cambiopass} 
                                cambiomail={cambiomail} 
                                confirmamail={confirmamail} 
                                link={props.link}
                                alTerminarCambio={BorraCambioMailPass}
                        ></Cuerpo>
                    :
                        <AccesoRestringido atras={Atras} rol={PerfilUsuario()}></AccesoRestringido>
                    )
                :
                    <Configuracion infoPagina={infoPagina} cambiaPagina={CambiaAPagina} atras={Atras}  refresca={RefrescaProvincia} muestraAviso={true}></Configuracion>
                }
            </div>
            <Pie codigoPagina={infoPagina.codigoPagina} cambiaPagina={CambiaAPagina}></Pie>
        </div>
    );

};

export default Contenido;
