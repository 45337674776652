import Acordeon from "../Composicion/Acordeon";

const EnfermedadesRelacionadas = (props) => {
    const titulo = props.titulo;
    const enfermedades = props.enfermedades;
    const enfermedadesHTML = enfermedades.map(enf => <li style={{cursor:'pointer', color:'#5C9029'}} 
                                                        onClick={(e) => CambiaAFichaEnf(enf.Id)}
                                                        onMouseOver={(e) => e.target.style.fontWeight='bold'}
                                                        onMouseOut={(e) => e.target.style.fontWeight='normal'}
                                                    >{enf.Enfermedad}</li>)

    const CambiaAFichaEnf = (codigoEnf) => {
        props.cambiaPagina({
            newpagina: "FichaPAT",
            newid: codigoEnf
        });
    }



    var contenidoAcordeon = (
        <ul class="list fs fs14 greenColor4 FlamaBook" >
            {enfermedadesHTML}
        </ul>
    );
    return (
        <Acordeon titulo = {titulo} contenido={contenidoAcordeon} activo = {props.activo}></Acordeon>
    )
}
export default EnfermedadesRelacionadas;

