function OpcionOrden(props){
    const index = props.index;
    const currentIndex = props.currentIndex;
    const texto = props.texto

    const getIndexSetOrden = (obj) =>{
        var idx = obj.index;
        props.setOrden(idx);
    }

    if (currentIndex===index)
        return (
            <span onClick={() => getIndexSetOrden({index})}><strong>{texto}</strong></span>    
        )
    else
        return (
            <span onClick={() => getIndexSetOrden({index})}>{texto}</span>    
        )
}

export default OpcionOrden;