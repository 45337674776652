import NovedadResto from "./NovedadResto";

const NovedadesInicioCuerpo = (props) => {
    const listaNovedades = props.lista;

    const novedades = listaNovedades ? (
        listaNovedades.map(fila => <NovedadResto item={fila} cambiaPagina={props.cambiaPagina}></NovedadResto>)
      ):(
        <div></div>
      );
    

    
    return (
        <div class="module module__list" >
            <ul class="list" >
                {novedades}
            </ul>
        </div>
    );
};

  export default NovedadesInicioCuerpo;
  
  