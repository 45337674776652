import { useEffect, useState } from "react";
import BP2022Service from "../../services/user.service";
import AuthService from "../../services/auth.service";
import { getCurrentURL, getParam, getParamsURL, rootPath } from "../../services/utils";
import SelectorComboBox from "../Utilidades/Selector/SelectorComboBox";
import Emergente from "../Fichas/FichaMUH/Apartados/Cismed/Emergente";
import AddEmpresa from "./AddEmpresa";
import {createBrowserHistory} from 'history';

const RecuperaPassword2 = (props) => {

    const [mensaje, setMensaje] = useState('Leyendo datos...');
    const [alto, setAlto] = useState('230px');
    const [estado, setEstado] = useState(0);
    const [user, setUser] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [link, setLink] = useState(props.link)

    const siguiente = () =>{
        if (estado === 2)
            cambiaPassword();
        else if (estado === 4)
            props.alCerrar();
        else if (estado === 5){
            props.alCerrar();
            /*const history = createBrowserHistory();
            history.push(rootPath);*/
        }
    }

    const compruebaLink = () =>{
        if (link != ""){
            BP2022Service.checkLink(link)
            .then((response) => {
                if (response.data && response.data.Ok){
                    var localUser = AuthService.getLocalUser()
                    if (props.esCambioPass && localUser && localUser.botPlusUser && localUser.botPlusUser.Username && localUser.botPlusUser.Username.toLowerCase() !== response.data.DirCorreo.toLowerCase()){
                        setEstado(1);
                        setMensaje('El usuario con el que ha iniciado sesión no se corresponde con el usuario que solicitó el cambio de contraseña. Inicie sesión con el usuario adecuado.')
                    }else{
                        setUser(response.data.DirCorreo);
                        setEstado(2);
                        setMensaje('');
                    }
                }
                else{
                    setEstado(1);
                    setMensaje(response.data.Mensaje)
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
                setEstado(1);
                setMensaje(error.message);
            });
        }
        else{
            setMensaje('La URL no está completa');
        }
    }

    const cambiaPassword = () =>{
        if (link != ""){
            if (newPassword != ""){
                if (validatePassword(newPassword)){
                    if (newPassword === confPassword){
                        setEstado(3);
                        setMensaje('Enviando datos...')
                        BP2022Service.setPasswordLink(link, newPassword)
                        .then((response) => {
                            if (response.data){
                                if (response.data.Exito){
                                    setEstado(5);
                                    setMensaje('Contraseña cambiada correctamente');
                                }else{
                                    setEstado(2);
                                    setMensaje(response.data.Mensaje);
                                }
                            }else{
                                setEstado(2);
                                setMensaje('No se ha podido cambiar la contraseña.')
                            }
                        })
                        .catch(function (error) {
                            console.log('Error', error.message);
                            setMensaje(error.message);
                        });
                    }
                    else{
                        setMensaje('La nueva contraseña y su confirmación no coinciden')
                    }
                }
                else{
                    setMensaje('La nueva contraseña no cumple las especificaciones')
                }
            }
            else{
                setMensaje('La contraseña no puede ser vacia')
            }
        }
        else{
            setMensaje('La URL no está completa');
        }
    }

    useEffect (() =>{
        if (estado===0)
            compruebaLink();
    },[]);


    const CambiaNewPassword = (s) => {
        setMensaje("");
        setNewPassword(s);
    };

    const CambiaConfirmacion = (s) => {
        setMensaje("");
        setConfPassword(s);
    };

    const validatePassword = (str) => {
        //var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        return re.test(str);    
    };

    var newalto;
    var txtBoton = "Siguiente";
    var titulo = "Recuperación de contraseña";
    if (props.esCambioPass){
        titulo = "Cambio de contraseña"
    }
    switch (estado){
        case 0:
            newalto = '230px';
            break;
        case 1:
            newalto = '230px';
            break;
        case 2:
            newalto = '550px';
            break;
        case 3:
            newalto = '230px';
            break;
        case 4:
            newalto = '230px';
            txtBoton= "Cerrar";
            break;
        case 5:
            newalto = '230px';
            txtBoton= "Cerrar";
            break;
    }
    if (alto != newalto)
        setAlto(newalto);
            
    const contenido = 
        <form class="content__results content__results__filters" id="main-filter" onSubmit={(e) => e.preventDefault()} autocomplete="off" >
            {(estado === 0)||(estado === 3)||(estado === 4)||(estado === 5) ?            
                <div className="text" style={{width:'100%', paddingBottom:'6px', fontSize:'16px', lineHeight:'20px', verticalAlign:'baseline'}}  >
                    {(mensaje) && (mensaje !== "") ? 
                        <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '6px'}}>{mensaje}</p>
                    :
                        <></>                
                    }
                </div>
            :(estado == 1) ?
                <div className="text" style={{width:'100%', paddingBottom:'6px', fontSize:'16px', lineHeight:'20px', verticalAlign:'baseline'}}  >
                    {(mensaje) && (mensaje !== "") ? 
                        <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '6px', color:'#F05341'}}>{mensaje}</p>
                    :
                        <></>                
                    }
                </div>
            :(estado == 2) ?
                <div className="text" style={{width:'100%', paddingBottom:'6px', fontSize:'16px', lineHeight:'20px', verticalAlign:'baseline'}}  >
                    {(mensaje) && (mensaje !== "") ? 
                        <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '6px', color:'#F05341'}}>{mensaje}</p>
                    :
                        <></>                
                    }
                    <p className="fs fs18 fw fw100 FlamaBook greyColor"
                        style={{marginBottom:'30px'}}>
                            Usuario:
                            <div class="coverModule">
                                <div class="module module__filters">
                                    <div class="box box__inputs">
                                        <div class="cover cover__input">
                                            <input type="text" name="CPUser" value={user} disabled = {true} style={{color:"#C0C0C0"}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </p>
                    <p className="fs fs18 fw fw100 FlamaBook greyColor"
                        style={{marginBottom:'10px'}}>
                            <span style={{paddingLeft:'10px', fontSize:'14px'}}> 
                                La nueva contraseña debe tener una longitud mínima de 8 caracteres y debe contener al menos una mayúscula, una minúscula y un dígito
                            </span>
                    </p>
                    <p className="fs fs18 fw fw100 FlamaBook greyColor"
                        style={{marginBottom:'30px'}}>
                            Nueva contraseña:
                            <div class="coverModule">
                                <div class="module module__filters">
                                    <div class="box box__inputs">
                                        <div class="cover cover__input">
                                            <input type="password" name="CPNewPass" value={newPassword} disabled = {estado !== 2} 
                                                onChange={ e => CambiaNewPassword(e.target.value)} 
                                                placeholder="Introduzca la nueva contraseña" autocomplete="new-password"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </p>
                    <p className="fs fs18 fw fw100 FlamaBook greyColor"
                        style={{marginBottom:'30px'}}>
                            Confirmación nueva contraseña:
                            <div class="coverModule">
                                <div class="module module__filters">
                                    <div class="box box__inputs">
                                        <div class="cover cover__input">
                                            <input type="password" name="CPConf" value={confPassword} disabled = {estado !== 2}  
                                                onPaste ={ e =>{e.preventDefault(); return false;}} 
                                                onChange={ e => CambiaConfirmacion(e.target.value)} 
                                                placeholder="Confirme la nueva contraseña" autocomplete="new-password"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </p>
                </div>
            :
                <></>
            }
            {((estado === 2) || (estado === 4) || (estado === 5))?
                <div className="cover cover__buttons" style={{textAlign: 'right', paddingTop: '10px'}} >
                        <button className="button button__purple" onClick={(e) => siguiente()} style={{height:'40px', paddingTop:'12px'}}> {txtBoton}</button>
                </div>
            :
                <></>
            }
        </form>


    return(
        <Emergente 
            contenido={contenido} 
            keyName={"cambioPass"} 
            titulo={titulo} 
            activo={true} 
            ocultaAspa = {false}
            alto = {alto}
            ancho = {'680px'}
            cerrar={props.alCerrar}
        ></Emergente>
    );
}

export default RecuperaPassword2;
