import Acordeon from "../../../FichaMUH/Apartados/Composicion/Acordeon";
import BloqueNombreValor from "../../../FichaMUH/Apartados/InfoGeneral/BloqueNombreValor";

const InfoGeneralPAT = (props) => {
    const ficha = props.ficha;
    const bloque1 = 
        [
            {
                nombre : 'Código CIE',
                valor: ficha.CodCIE,
                link: null,
            },
            {
                nombre : 'Descripción CIE',
                valor: ficha.DescripcionCIE,
                link: null,
            },
            {
                nombre : 'Especialidad',
                valor: ficha.EspecialidadMedica,
                link: null,
            },
        ];

    const tituloEP = "Descripción";
    const contEP = (    
        <p class="fs fs16 FlamaBook blackColor">
            {(ficha.Descripcion) ?
                ficha.Descripcion
            :
                ''
            }
        </p>
    );
    const DenQuiHTML = ((ficha.Descripcion)?
        <div class="cover cover__text" >
            <p class="fs fs16 FlamaBook blackColor" style={{whiteSpace: 'pre-line'}} >{ficha.Descripcion}</p>
        </div>
    :
        <div></div>
    );

    /*<BloqueNombreValor bloque={bloque1} sonValoresNumericos={false} cambiaPagina={props.cambiaPagina}></BloqueNombreValor>*/
    return (
        <div class="infoContent">
            {(ficha.Descripcion) && false ?
                    <Acordeon titulo={tituloEP} contenido={contEP}></Acordeon>
                :
                    <div></div>
            }
            {DenQuiHTML}
        </div>
    )
}
export default InfoGeneralPAT;
