import LoadingPopup from "../../Resultados/Loading.Popup";
import BP2022Service from "../../../services/user.service";
import { useEffect, useState } from "react";
import ResumenBusqueda from "../ResumenBusqueda";
import SelectorComposicion from "../../Utilidades/Selector/SelectorComposicion/SelectorComposicion";
import SelectorComboBox from "../../Utilidades/Selector/SelectorComboBox";
import { GetAntecesorWhithClassName, upperCaseFirstLetter } from "../../../services/utils";
import SelectorNumero from "../../Utilidades/Selector/SelectorNumero";
import Emergente from "../../Fichas/FichaMUH/Apartados/Cismed/Emergente";


const FBComposicion= (props) => {
    const [datosFormulario, setDatosFormulario] = useState({
        listaPAs: null,
        listaFFs: null,
        listaVias: null,
        listaIntsV: null,
        listaIntsNV: null,
        tipo: (props.tipo),
    });
    const [seleccionFormulario, setSeleccionFormulario] = useState({
        tipoProd: props.tipoProd,
        pas: props.pas,
        solo: props.solo,
        idVia: props.idVia,
        idFF: props.idFF,
        uds: props.uds,
        pv: props.pv,
        ints: props.ints,
    })
    const [ocultaIntolerancias, setOcultaIntolerancias] = useState(true);
    const [muestraAviso, setMuestraAviso] = useState(false);

    const claseIntoleranciasOcultas = (ocultaIntolerancias) ? "cover hide" : "cover hide active";
    const claseBotonIntolerancias = (ocultaIntolerancias) ? "button button__arrow button__purple__line noUppercase verMas" : "button button__arrow button__purple__line noUppercase verMas active";
    const txtbotonIntolerancias = (ocultaIntolerancias) ? "Ver más" : "Ver menos";

    useEffect(() => {
        if ((datosFormulario.listaPAs === null) || props.tipoProd != seleccionFormulario.tipoProd || props.tipo != datosFormulario.tipo)
        {
            if ((seleccionFormulario.tipoProd != props.tipoProd || props.tipo != datosFormulario.tipo)){
                const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
                nuevaSeleccion.pas=[];
                nuevaSeleccion.tipoProd = props.tipoProd;
                setSeleccionFormulario(nuevaSeleccion);
            }
            DescargaDatos();
        }   
    },[props.tipoProd,props.tipo]);

    const nombreComponente =  (datosFormulario.tipo==="P") ?  "Ingrediente" : "Principio activo";
    const nombreComponentePlural =  (datosFormulario.tipo==="P") ?  "Ingredientes" : "Principios activos";

    const DescargaDatos = () => {      
        BP2022Service.datosFormularioComposicion(props.tipo, props.tipoProd, props.pas)
        .then((response) => {
            var df = {
                tipo: props.tipo,
                listaPAs : response.data.Componentes,
                listaVias : response.data.Vias,
                listaFFs : response.data.FormasFarma,
                listaIntsV : response.data.IntoleranciasV,
                listaIntsNV : response.data.IntoleranciasNoV,
            };
            df.listaPAs.splice(0,0,{Codigo:0, Valor:'Indique ' + nombreComponente.toLowerCase()});
            df.listaVias.splice(0,0,{Codigo:-1, Valor:'Seleccione una vía'},{Codigo:0, Valor:'-'});
            df.listaFFs.splice(0,0,{Codigo:-1, Valor:'Seleccione una forma farmacéutica'},{Codigo:0, Valor:'-'});
            setDatosFormulario(df);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const ActualizaFormasYVias = (pas) => {      
        BP2022Service.actualizaFFyVias(props.tipo, props.tipoProd, pas)
        .then((response) => {
            var df = {
                tipo: datosFormulario.tipo,
                listaPAs : datosFormulario.listaPAs,
                listaVias : response.data.Vias,
                listaFFs : response.data.FormasFarma,
                listaIntsV : datosFormulario.listaIntsV,
                listaIntsNV : datosFormulario.listaIntsNV,
            };
            df.listaVias.splice(0,0,{Codigo:-1, Valor:'Seleccione una vía'},{Codigo:0, Valor:'-'});
            df.listaFFs.splice(0,0,{Codigo:-1, Valor:'Seleccione una forma farmacéutica'},{Codigo:0, Valor:'-'});
            setDatosFormulario(df);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const ActualizaListaIngredientesYVias = () => {      
        BP2022Service.datosFormularioComposicion(datosFormulario.tipo)
        .then((response) => {
            var df = JSON.parse(JSON.stringify(datosFormulario));
            df.listaPAs =  response.data.Componentes;
            df.listaVias = response.data.Vias;
            if (datosFormulario.tipo==="P")
                df.listaPAs.splice(0,0,{Codigo:0, Valor:'Seleccione una ingrediente'});
            df.listaVias.splice(0,0,{Codigo:0, Valor:''});
            setDatosFormulario(df);
            setSeleccionFormulario(seleccionFormulario);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const clickVerIntolerancias=(e) =>{
        setOcultaIntolerancias(!ocultaIntolerancias);
    }

    const construyeResumenBusqueda = () => {
        var res = [];

        if ((seleccionFormulario.pas) && (seleccionFormulario.pas.length>0)){
            if (datosFormulario.listaPAs){
                var titulo = "Composición";
                for (var i =0; i<seleccionFormulario.pas.length; i++){
                    var valor = '';
                    var item = datosFormulario.listaPAs.find(x => x.Codigo === Math.abs(seleccionFormulario.pas[i].codigo));
                    if (item){
                        if (seleccionFormulario.pas[i].codigo<0)
                            valor += "No contiene ";
                        valor += item.Valor;
                        var sales = seleccionFormulario.pas[i].sales;
                        if (sales && sales.length>0){
                            for (var j=0; j<sales.length; j++){
                                if (j>0)
                                    valor += " /"
                                else
                                    valor += "        "
                                valor += " " + sales[j].valor;
                            }
                        }    
                        var cants = seleccionFormulario.pas[i].cants;
                        if (cants && cants.length>0){
                            for (var j=0; j<cants.length; j++){
                                if (j>0)
                                    valor += " /"
                                else
                                    valor += "        "
                                valor += " " + cants[j].des;
                            }
                        }    
                    }
                    res.push({titulo:titulo, valor: valor});
                    titulo = "";
                }
                if (seleccionFormulario.solo){                    
                    res.push({titulo: "", valor: "Únicamente los " + nombreComponentePlural.toLowerCase() + " seleccionados"});
                }
                else{
                    res.push({titulo: "", valor: "Puede contener " + nombreComponentePlural.toLowerCase() + " adicionales"});
                }

                if (seleccionFormulario.idVia>0){
                    if (datosFormulario.listaVias){
                        var item = datosFormulario.listaVias.find(x => x.Codigo === seleccionFormulario.idVia);
                        if (item)
                            res.push ({titulo: "Vía", valor: item.Valor});
                    }
                }

                if (seleccionFormulario.idFF>0){
                    if (datosFormulario.listaFFs){
                        var item = datosFormulario.listaFFs.find(x => x.Codigo === seleccionFormulario.idFF);
                        if (item)
                            res.push ({titulo: "Forma farmacéutica", valor: item.Valor});
                    }
                }
            
                if (seleccionFormulario.uds>0){
                    res.push ({titulo: "Unidades envase", valor: seleccionFormulario.uds.toString()});
                }
            
                if (seleccionFormulario.pv>0){
                    res.push ({titulo: "Peso / volumen", valor: seleccionFormulario.pv.toString() + ' g o ml'});
                }
            

                if ((seleccionFormulario.ints) && (seleccionFormulario.ints.length>0)){
                    if (datosFormulario.listaIntsV){
                        var valor = '';
                        for (var i =0; i<seleccionFormulario.ints.length; i++){
                            var item = datosFormulario.listaIntsV.find(x => x.Codigo === seleccionFormulario.ints[i]);
                            if (!item) item = datosFormulario.listaIntsNV.find(x => x.Codigo === seleccionFormulario.ints[i]);
                            if (item){
                                if (valor !== '')
                                    valor += ", ";
                                valor += item.Valor;
                            }
                        }
                        if (valor != '')
                            res.push ({titulo: "Intolerancias del paciente", valor: valor});
                    }
                }
            }
        }
        return res;
    }

    var resumen = construyeResumenBusqueda(seleccionFormulario);

    const hayBusqueda = seleccionFormulario.pas && (seleccionFormulario.pas.filter(item => item.codigo>0).length>0);

    const clickBuscar = (e) =>{
        if (hayBusqueda){
            var newpagina = "BComposicion";
            if (datosFormulario.tipo==="P")
                newpagina += "PPF";
            else if (datosFormulario.tipo==="V")
                newpagina += "MUV";
            else
                newpagina += "MUH";

            props.cambiaPagina({
                newpagina: newpagina,
                pas: seleccionFormulario.pas,
                tipoProd: seleccionFormulario.tipoProd,
                solo: seleccionFormulario.solo,
                idVia: seleccionFormulario.idVia,
                idFF: seleccionFormulario.idFF,
                uds: seleccionFormulario.uds,
                pv: seleccionFormulario.pv,
                ints: seleccionFormulario.ints,
            })
        }
        else{
            setMuestraAviso(true);
        }
    }

    const BorraBusqueda = (e) =>{
        const formularioVacio={
            pas: [],
            tipoProd: seleccionFormulario.tipoProd,
            solo: (datosFormulario.tipo==="P" ? "N":"S"),
            idVia: 0,
            idFF: 0,
            uds: 0,
            pv: 0,
            ints: [],
        }
        setSeleccionFormulario(formularioVacio);
        ActualizaFormasYVias([]);
        var inputs = document.getElementsByClassName('inputSelector');
        if (inputs){
            for (var i=0; i<inputs.length; i++){
                inputs[i].value = "";
                const itemContainer = GetAntecesorWhithClassName(inputs[i], 'custom-select-container');
                if (itemContainer){
                    itemContainer.classList.remove('active');
                }
            }
        }
    }

    const AddSal = (idPA, idSal, des) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (pa){
            if (!pa.sales){
                pa.sales = []
            }
            var sal = pa.sales.find(item => item.codigo === idSal);
            if (!sal){
                pa.sales.push({codigo:idSal, valor: des});
            }
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveSal = (idPA, idSal) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (pa){
            if (!pa.sales){
                pa.sales = []
            }
            pa.sales = pa.sales.filter(item => item.codigo !== idSal);
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddCant = (idPA, idUnd, cantidad, des) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (pa){
            if (!pa.cants){
                pa.cants = []
            }
            var cant = pa.cants.find(item => item.cod === idUnd && item.cant === cantidad);
            if (!cant){
                pa.cants.push({cod: idUnd, cant: cantidad, des: des});
            }
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveCant = (idPA, idUnd, cantidad) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (pa){
            if (!pa.cants){
                pa.cants = []
            }
            pa.cants = pa.cants.filter(item => item.cod !== idUnd || item.cant !== cantidad);
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddPA = (idPA) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (!pa){
            nuevaSeleccion.pas.push({
                codigo: idPA,
                sales: [],
                cants: [],
            })
        }
        setSeleccionFormulario(nuevaSeleccion);
        ActualizaFormasYVias(nuevaSeleccion.pas);
    }

    const UpdatePA = (idPA, negado) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => Math.abs(item.codigo)===Math.abs(idPA));
        if (pa){
            if (negado){
                pa.codigo = Math.abs(idPA) * (-1);
                pa.sales = [];
                pa.cants = [];
            }else{
                pa.codigo = Math.abs(idPA);
            }
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemovePA = (idPA) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.pas = nuevaSeleccion.pas.filter(item => Math.abs(item.codigo)!==Math.abs(idPA));
        setSeleccionFormulario(nuevaSeleccion);
        ActualizaFormasYVias(nuevaSeleccion.pas);
    }

    const CambiaPASolo = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.solo = valor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioVia = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idVia = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioFF = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idFF = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioUnidad = (nuevonumero) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.uds = nuevonumero;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioPV = (nuevonumero) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.pv = nuevonumero;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioIntolerancia = (e, idInt) =>{
        var item = e.target;
        var idx = (seleccionFormulario.ints) ? seleccionFormulario.ints.indexOf(idInt) : -1;
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (item.checked){
            if (!nuevaSeleccion.ints) nuevaSeleccion.ints = [];
            if (idx<0) nuevaSeleccion.ints.push(idInt);
        }else{
            if (idx>=0) nuevaSeleccion.ints.splice(idx,1);
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const KeyDownForm = (e) =>{
        var keyCode = e.keyCode;
        if (keyCode === 13){
            clickBuscar(e);
        }
    }



    const listaCodValorPAs = (datosFormulario.listaPAs) ? datosFormulario.listaPAs.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorFFs = (datosFormulario.listaFFs) ? datosFormulario.listaFFs.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorVias = (datosFormulario.listaVias) ? datosFormulario.listaVias.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    /*const listaCodValorIntV = (datosFormulario.listaIntsV) ? datosFormulario.listaIntsV.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorIntNV = (datosFormulario.listaIntsNV) ? datosFormulario.listaIntsNV.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];*/

    const HTMLIntoleranciasV = (((datosFormulario.listaIntsV) && (datosFormulario.listaIntsV.length>0)) ?
        datosFormulario.listaIntsV.map(int => 
            <label>
                <input type="checkbox" value={int.Codigo} checked = {(seleccionFormulario.ints && seleccionFormulario.ints.indexOf(int.Codigo)>=0)} onClick={(e) => CambioIntolerancia(e, int.Codigo)} />
                {upperCaseFirstLetter(int.Valor)}
            </label>
        )
    :
        <div></div>
    );
    const HTMLIntoleranciasNV = (((datosFormulario.listaIntsNV) && (datosFormulario.listaIntsNV.length>0)) ?
        datosFormulario.listaIntsNV.map(int => 
            <label>
                <input type="checkbox" value={int.Codigo} checked = {(seleccionFormulario.ints && seleccionFormulario.ints.indexOf(int.Codigo)>=0)} onClick={(e) => CambioIntolerancia(e, int.Codigo)} />
                {upperCaseFirstLetter(int.Valor)}
            </label>
        )
    :
        <div></div>
    );
    const clasedivmodulefilters = "cover cover__filters cover__filters__2 cover__filters__2--1 cover__filters__2--1__50";


    const modalKeyName = "NoDataFCompos"
    const modalContent = (
            <div>
                <p className="fs fs15 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '0px'}}>Debe indicar al menos un {nombreComponente.toLowerCase()}</p>
            </div>
    );
    const cierraAviso = () =>{
        setMuestraAviso(false);
    }

    const estiloBotonesSup = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'space-between'} : {height:'76px', width:'100%', justifyContent: 'space-between', visibility:'hidden'})
    const estiloBotonesInf = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'center'} : {height:'76px', width:'100%', justifyContent: 'center', display:'none'})

    return (
        <div class="content" >

            { (datosFormulario.listaPAs === null) ?
                <LoadingPopup titulo="Un momento" texto="Preparando formulario" esPorcentaje={true}></LoadingPopup>
            :
                <form class="content__results content__results__filters" id="main-filter"  onSubmit={(e) => e.preventDefault()}  onKeyDown={(e) => KeyDownForm(e)} tabIndex="0">
                    {muestraAviso ?
                        <Emergente
                            contenido={modalContent} 
                            keyName={modalKeyName} 
                            titulo="" 
                            activo={true} 
                            alto={"160px"}
                            cerrar={cierraAviso}></Emergente>
                    :
                        <></>
                    }
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesSup}>
                        <span class="button button__arrow button__green noUppercase reset-form" data-form="#main-filter" title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} style={{visibility:'hidden'}} >
                            Vaciar búsqueda
                        </span>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                        <span class="button button__arrow button__green reset-form" data-form="#main-filter" style={{textTransform:'none', fontSize: '14px', width:'146px'}} title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} >
                            Vaciar búsqueda
                        </span>
                    </div>
                    <SelectorComposicion lista = {listaCodValorPAs} 
                                idHTML="FBComposCompos" 
                                valuesSelected={seleccionFormulario.pas} 
                                pasolo={seleccionFormulario.solo}
                                alAnadirPA={AddPA} 
                                alBorrarPA={RemovePA} 
                                alUpdatePA={UpdatePA}
                                alAnadirSal={AddSal} 
                                alBorrarSal={RemoveSal} 
                                alAnadirCant={AddCant} 
                                alBorrarCant={RemoveCant} 
                                alCambiarPASolo={CambiaPASolo}
                                ancho="100%"
                                titulo={nombreComponentePlural}
                                muestraChecks={true}
                                nombreComponentes = {nombreComponentePlural}
                                tipo={datosFormulario.tipo}
                                tipoFiltradoLista={1}
                    ></SelectorComposicion>
                    <div class={clasedivmodulefilters} >
                    <div class="module module__filters">
                            <h3 class="title fs fs16 greyColor2"  style={{marginBottom:'12px'}}>Vía de administración</h3>
                            <SelectorComboBox lista = {listaCodValorVias} 
                                    idHTML="FBComposVia" 
                                    valueSelected={seleccionFormulario.idVia} 
                                    alCambiar={CambioVia} 
                                    ancho="100%"
                            ></SelectorComboBox>
                        </div>
                        <div class="module module__filters">
                            <h3 class="title fs fs16 greyColor2" style={{marginBottom:'12px'}}>Forma farmacéutica</h3>
                            <SelectorComboBox lista = {listaCodValorFFs} 
                                    idHTML="FBComposFF" 
                                    valueSelected={seleccionFormulario.idFF} 
                                    alCambiar={CambioFF} 
                                    ancho="100%"
                            ></SelectorComboBox>
                        </div>
                    </div>
                    <div class="cover cover__filters cover__filters__units" >
                        <SelectorNumero 
                            alCambiar={CambioUnidad} 
                            num={seleccionFormulario.uds} 
                            valorVacio={0}
                            titulo = "Unidades por envase"
                        ></SelectorNumero>
                        <SelectorNumero 
                            alCambiar={CambioPV} 
                            num={seleccionFormulario.pv} 
                            titulo = "Peso (g) / Volumen (ml)"
                        ></SelectorNumero>
                    </div>
                    {(props.tipo === "H" || (props.tipo === "P" && props.tipoProd === 1)) ?
                        <div>
                        <div class="cover cover__filters cover__filters__checks" >
                            <div class="module module__checks"style={{width:'100%'}}>
                                <h3 class="fs fs16 greyColor2 title" >Intolerancias</h3>
                                <div class="checks" >
                                    <div class="cover" >
                                        {HTMLIntoleranciasV}
                                    </div>
                                    <div class={claseIntoleranciasOcultas} id="IntoleranciasOcultas">
                                        {HTMLIntoleranciasNV}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="module module__buttons module__buttons__buscar">
                            <span class={claseBotonIntolerancias}   onClick={(e) => clickVerIntolerancias(e)}>{txtbotonIntolerancias} <img src="./img/arrowPurpleDown2.svg" alt="arrowPurpleDown2" /></span>
                        </div>
                        </div>
                    :
                        <div></div>
                    }
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesInf}>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                    </div>
                    <ResumenBusqueda condiciones={resumen}></ResumenBusqueda>
                </form>
            }
        </div>
    );
};

export default FBComposicion;
