import { useEffect, useState } from "react";
import { AddClassNameToClassItems } from "../../../services/utils";
import Emergente from "./Apartados/Cismed/Emergente";

const MensajesEmergentes = (props) => {
    const restoMensajes = props.mensajes.slice(1);
    const primerMensaje = props.mensajes[0];
    const keyME = "ME_" + props.id.toString();
    const titulo = primerMensaje.Titulo;
    const imagen = primerMensaje.URLImagen;
    const htmlResto = restoMensajes.map(mensa => <p dangerouslySetInnerHTML={{__html: mensa.Texto}}></p>);
    const [mostrado, setMostrado] = useState(false)
    const contenido = (
        <div>
            <p dangerouslySetInnerHTML={{__html: primerMensaje.Texto}}></p>
            {htmlResto}
        </div>
    );

    const AbreEmergente = () => {
        AddClassNameToClassItems(keyME, null, 'active');
    }


    useEffect(() => {
        if (!mostrado)
            AbreEmergente();
        setMostrado(true);
    });

    return (
        <Emergente titulo = {titulo} contenido= {contenido} imagen = {imagen} keyName={keyME}></Emergente>
    )
}
export default MensajesEmergentes;

