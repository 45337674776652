import { GetAntecesorWhithClassName, numberWithCommas, numberWithCommas2, rootPath } from "../../../../../services/utils";

const Componente = (props) => {
    const PA = props.componente.PrincipioActivo;
    const idPA = props.componente.IdPrincipioActivo;
    const sal = props.componente.Sal;
    const cant = props.componente.Cantidad
    var uni = props.componente.Unidad;
    var codUnidad = props.componente.CodUnidad;
    var info = props.componente.Info;
    const tieneInfo = (info !== null && info !=='')
    const tipoProd = props.tipo;
    var alturadivinicial;

    const handleMouseOverIcon = (e) =>{
        var itemImg = e.target;
        var itemTooltip = itemImg.parentElement.getElementsByClassName("tooltip")[0];
        var itemAccordionContent = GetAntecesorWhithClassName(itemImg,'accordion__content');
        if (itemTooltip && itemAccordionContent){
            var rect = itemTooltip.getBoundingClientRect();
            var rectdiv = itemAccordionContent.getBoundingClientRect();
            var finYdiv = (Math.round(rectdiv.y)+Math.round(rectdiv.height));
            var finYtootip = (Math.round(rect.y)+Math.round(rect.height));
            if (finYtootip > finYdiv){
                alturadivinicial = Math.round(rectdiv.height).toString() + 'px';
                var incremento = finYtootip - finYdiv;
                var newAltura = Math.round(rectdiv.height) + incremento;
                itemAccordionContent.style.height = newAltura.toString() + 'px';
            }
        }
    }

    const handleMouseOutIcon = (e) =>{
        var itemImg = e.target;
        var itemAccordionContent = GetAntecesorWhithClassName(itemImg,'accordion__content');
        if (itemAccordionContent && alturadivinicial){
            itemAccordionContent.style.height = alturadivinicial;
        }
    }

    if (codUnidad)
        codUnidad = codUnidad.trim();
    var sub = '';
    var resto = '';
    if (uni){
        if (uni.indexOf('^')>=0){
            var partes = uni.split('^')
            uni = partes[0];
            sub = partes[1];
            for(var i=0; i<sub.length && sub[i]>='0' && sub[i]<='9'; i++);
            resto = sub.substr(i);
            sub = sub.substr(0,i);
        }
    }

    var textosInfo = [];
    var subs = [];
    var idxsubs = [];
    if (info){
        if (info.indexOf('^')>=0){
            var partes = info.split('^')
            for (var i=0; i<partes.length-1; i++){
                textosInfo.push(partes[i]);
                var subInfo = partes[i+1];
                for(var j=0; j<subInfo.length && subInfo[j]>='0' && subInfo[j]<='9'; j++);
                partes[i+1] = subInfo.substr(j);
                subs.push(subInfo.substr(0,j));
                idxsubs.push(i);
            }
            textosInfo.push(partes[partes.length-1]);
        }
        else
            textosInfo.push(info);
    }

    /*if (info)
        if (info.indexOf('^')>=0){
            info = info.replace('^','&#94');
        }*/

    var HTMLAux = textosInfo[0];
    for (var i=0; i<subs.length; i++)
        HTMLAux += "<sup>"+ subs[i] + "</sup>" + textosInfo[i+1];

    const tooltip =  (!tieneInfo ? '' :
        <span>
            <img src={rootPath + "img/infoGreen.svg"} alt="" onMouseOver={(e) => handleMouseOverIcon(e)} onMouseOut={(e) => handleMouseOutIcon(e)} />
            <span class="tooltip" style={{display:'block'}} dangerouslySetInnerHTML={{__html: HTMLAux}}>
            </span>
        </span>
    );
    const classdivPA = (tieneInfo ? 'column hasTooltip' : 'column');

    const CambiaPaginaFichaPA = (id) => {
        var newpagina = 'FichaPAH';
        if (tipoProd === "MUV") 
            newpagina = 'FichaPAV';
        if (tipoProd === "PPF") 
            newpagina = 'FichaING';
        props.cambiaPagina({
            newpagina: newpagina,
            newid: id,
        });
    }

    const unidadesLargas = ['MQ','TR','UC']

    const sizeFontUnidades = (unidadesLargas.includes(codUnidad) ? {fontSize : '10px'} : (props.componente.IdPrincipal===0) ? {} :  {fontSize : '12px'});

    const estiloNombreComponente = (props.componente.IdPrincipal === 0) && (!props.siguiente || (props.siguiente.IdPrincipal === 0)) ?
        {
            justifyContent: 'flex-start',
            textAlign: 'left',
            fontSize: '14px',
        }
        : (props.componente.IdPrincipal === 0) && props.siguiente && (props.siguiente.IdPrincipal > 0) ?
        {
            justifyContent: 'flex-start',
            textAlign: 'left',
            fontSize: '14px',
            paddingBottom: '6px',
        }
        : 
        {
            justifyContent: 'flex-start',
            textAlign: 'left',
            fontSize: '12px',
            paddingTop: '8px',
            paddingLeft: '28px',
            paddingBottom: '8px',
        }
    ;

    const estiloCantidadComponente = (props.componente.IdPrincipal === 0) && (!props.siguiente || (props.siguiente.IdPrincipal === 0)) ?
        {
            display:'table-cell',
            justifyContent: 'center',
            fontSize: '14px',
        }
        : (props.componente.IdPrincipal === 0) && props.siguiente && (props.siguiente.IdPrincipal > 0) ?
        {
            display:'table-cell',
            justifyContent: 'center',
            fontSize: '14px',
            paddingBottom: '6px',
        }
        :
        {
            display:'table-cell',
            justifyContent: 'center',
            fontSize: '12px',
            paddingTop: '8px',
            paddingBottom: '8px',
        }
    ;

    const estiloUnidadComponente = (unidadesLargas.includes(codUnidad) ? 
        (props.componente.IdPrincipal === 0) && (!props.siguiente || (props.siguiente.IdPrincipal === 0)) ?
        {
            display:'table-cell',
            justifyContent: 'center',
            fontSize: '10px',
        }
        :(props.componente.IdPrincipal === 0) && props.siguiente && (props.siguiente.IdPrincipal > 0) ?
        {
            display:'table-cell',
            justifyContent: 'center',
            fontSize: '10px',
            paddingBottom: '6px',
        }
        :
        {
            display:'table-cell',
            justifyContent: 'center',
            fontSize: '10px',
            paddingTop: '8px',
            paddingBottom: '8px',
        }
        :(props.componente.IdPrincipal === 0) &&(!props.siguiente || (props.siguiente.IdPrincipal === 0)) ?
        {
            display:'table-cell',
            justifyContent: 'center',
            fontSize: '14px',
        }
        :(props.componente.IdPrincipal === 0) && props.siguiente && (props.siguiente.IdPrincipal > 0) ?
        {
            display:'table-cell',
            justifyContent: 'center',
            fontSize: '14px',
            paddingBottom: '6px',
        }
        :
        {
            display:'table-cell',
            justifyContent: 'center',
            fontSize: '12px',
            paddingTop: '8px',
            paddingBottom: '8px',
        }
    );

    const estiloP =  (props.componente.IdPrincipal === 0) ?
        {marginLeft:'0px'}
    :
        {marginLeft:'0px'}
    ;


    
    return (
        <div class="table__row table__row__normal fs fs14 fw fw300 blackColor uppercase" style={{display:'table-row'}}>
            <div class={classdivPA} style={estiloNombreComponente}>
                <p style={estiloP}>
                    <span style={{cursor:'pointer', color:'#5C9029', marginLeft:'0px'}} 
                        onClick={(e) => CambiaPaginaFichaPA(idPA)}
                        onMouseOver={(e) => e.target.style.fontWeight='bold'}
                        onMouseOut={(e) => e.target.style.fontWeight='normal'}
                    >
                        {PA}&nbsp;
                        {sal}&nbsp;  
                    </span>
                    {tooltip}
                </p>
            </div>
            <div class="column number"  style={estiloCantidadComponente}>
                <p>
                    {(cant>0 ? numberWithCommas2(cant) : '-')}
                </p>
            </div>
            <div class="column" style={estiloUnidadComponente}>
                <p style={sizeFontUnidades}>
                    {((uni !== null && uni!=='') ? 
                        ((sub!=='') ?
                            <span>{uni}<sup>{sub}</sup>{resto}</span>
                        :
                            ((codUnidad === 'DT') ?
                                <span>DICT<sub>50</sub></span>
                            :
                                ((codUnidad === 'XX') ?
                                    <span style={sizeFontUnidades}>LOG<sub>10</sub>&nbsp;&nbsp;UNIDADES INFECCIOSAS</span>
                                :
                                    ((codUnidad === 'YS') ?
                                        <span>YSLD<sub>50</sub></span>
                                    :
                                        ((codUnidad === 'ZX') ?
                                            <span>LOG<sub>10</sub>&nbsp;&nbsp;UFP</span>
                                        :
                                            ((codUnidad === 'ZZ') ?
                                                <span>LOG<sub>10</sub>&nbsp;&nbsp;DICC<sub>50</sub></span>
                                            :
                                                uni
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    : '-')}
                </p>
            </div>
        </div>
    )
}
export default Componente;
