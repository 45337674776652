import { useEffect, useState } from "react";
import { deepEqual } from "../../../services/utils";
import SelectorComboBox from "./SelectorComboBox";

function SelectorTexto(props){
    const [propiedades, setPropiedades] = useState({
        listaEpigrafes: props.listaEpigrafes,
        texto: props.texto,
        idEpi: props.idEpi,
        negado: props.negado,
    })
    const idHTML = props.idHTML;
    const ancho = window.innerWidth;
    const isMobile = (ancho<768);

    useEffect(() => {
        if (!deepEqual(props.listaEpigrafes, propiedades.listaEpigrafes) || (props.idEpi !== propiedades.idEpi) || (props.texto !== propiedades.texto)){
            setPropiedades({
                listaEpigrafes: props.listaEpigrafes,
                texto: props.texto,
                idEpi: props.idEpi,
            })
        }
        
    },  [props.listaEpigrafes, props.texto, props.idEpi]);

    const remove_accents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } 
    const clasedivmodulefilters = "cover cover__filters cover__filters__2 cover__filters__2--1 cover__filters__2--1__50";  
    const estiloLab = (isMobile) ? {} : {width: '50%'} ;

    const CambioEpi = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(propiedades));
        nuevaSeleccion.idEpi = nuevovalor;
        setPropiedades(nuevaSeleccion);
        if (props.alCambiar)
            props.alCambiar(nuevovalor,nuevaSeleccion.texto);
    }

    const CambiaTxt = (e) =>{
        const item = e.target;
        if (item){
            const valor = item.value;
            const nuevaSeleccion = JSON.parse(JSON.stringify(propiedades));
            nuevaSeleccion.texto = valor;
            setPropiedades(nuevaSeleccion);
            if (props.alCambiar)
                props.alCambiar(nuevaSeleccion.idEpi,nuevaSeleccion.texto);
        }
    }
   
    const ClickNegado  = (e) =>{
        var check = e.target;
        var value = check.checked;
        const nuevaSeleccion = JSON.parse(JSON.stringify(propiedades));
        nuevaSeleccion.negado = value;
        setPropiedades(nuevaSeleccion);
        if (props.alCambiar)
            props.alCambiar(nuevaSeleccion.idEpi,nuevaSeleccion.texto,nuevaSeleccion.negado);
    }



    return (
        <div>
        <div class={clasedivmodulefilters} style={{marginBottom:'10px'}} >
            <div class="coverModule">
                <h3 class="title fs fs16 greyColor2">{props.tituloTexto}</h3>
                <div class="module module__filters">
                    <div class="box box__inputs">
                        <div class="cover">
                            <input type="text" name="" value={propiedades.texto} placeholder={props.placeholder} onChange={(e) => CambiaTxt(e)}  />
                        </div>
                    </div>
                </div>
            </div>
            <div class="module module__selects" style={estiloLab}>
                <h3 class="title fs fs16 greyColor2" style={{marginBottom:'12px'}}>{props.tituloEpigrafe}</h3>
                <SelectorComboBox lista = {propiedades.listaEpigrafes} 
                        idHTML={idHTML + "cbEpi"} 
                        valueSelected={propiedades.idEpi} 
                        alCambiar={CambioEpi} 
                        ancho="100%"
                        maxLengthNormal = {45}
                ></SelectorComboBox>
            </div>
        </div>
        <label style={{color: '#4e4e4e'}}>
            <input type="checkbox" name="negado" checked={propiedades.negado}  onClick={(e) => ClickNegado(e)}/>
            No contiene
        </label>
        </div>
);
}

export default SelectorTexto;

