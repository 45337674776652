import ImagenConTooltip from "../../../Utilidades/Tooltip/ImagenConTooltip";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/botplustt.css';

const FilaPictogramas = (props) => {
    const pictogramas = props.fila.map( picto =>
                                <Tippy trigger="click" arrow={true} theme = 'botplustt' content=
                                    {picto.Texto.toUpperCase()}
                                >
                                    <img src={picto.Archivo} title={picto.Texto} /> 
                                </Tippy>
                            )
    /*const pictogramas = props.fila.map( picto =>
                                        <img src={picto.Archivo} title={picto.Texto} onClick = {(e) => muestraHint(e)} /> 
                            )*/

    const muestraHint = (e) => {
        var element = e.target;
        element.dispatchEvent(new Event("onmouseover"))
    }   

    return (
        <div class="advertencias" style={{textAlign:'justify', width:'100%'}}>
            {pictogramas}    
        </div>
    )
}
export default FilaPictogramas;
