
import LinksFichaExt from "./LinksFichaExt";
import TablaInfoPrincipalExt from "./TablaInfoPrincipalExt";

const ResumenInfoExt = (props) => {
    const ficha = props.ficha;
    var textoEncabezado = ficha.TipoRegistro;
    const svgImagenH = (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18.001" viewBox="0 0 15 18.001">
            <path id="Combined_Shape" data-name="Combined Shape"
                d="M.882,18H0V14.5A4.939,4.939,0,0,1,5.023,9.659H9.976A4.94,4.94,0,0,1,15,14.5V18h-.883V14.5a4.059,4.059,0,0,0-4.141-3.962H5.023A4.059,4.059,0,0,0,.882,14.5V18Zm11.129,0h-.882V15.472h.882V18ZM3.785,18H2.9V15.472h.882V18ZM7.5,7.9A4.031,4.031,0,0,1,3.4,3.951,4.031,4.031,0,0,1,7.5,0a4.031,4.031,0,0,1,4.1,3.951A4.031,4.031,0,0,1,7.5,7.9ZM7.5.878A3.15,3.15,0,0,0,4.285,3.951,3.151,3.151,0,0,0,7.5,7.024a3.151,3.151,0,0,0,3.216-3.074A3.15,3.15,0,0,0,7.5.878Z"
                fill="#81509a" />
        </svg>
    );

    return (
        <div class="contenido__desc">
            <div class="objetivo f-f-Flama-Book">
                {svgImagenH}
                <span>{textoEncabezado}</span>
            </div>
            <div class="detalles">
                <div class="nombre f-f-Flama-Medium">{ficha.NombreCompleto}</div>
                    <div></div>
            </div>

            <TablaInfoPrincipalExt ficha={ficha}></TablaInfoPrincipalExt>
            
            <LinksFichaExt ficha={ficha} cambiaPagina={props.cambiaPagina} ></LinksFichaExt>
        
        </div>
    )
}
export default ResumenInfoExt;

