import FilaNombreValor from "./FilaNombreValor";

const BloqueNombreValor = (props) => {
    const claseBloque = props.claseBloque ? props.claseBloque : 'bloque'
    //const claseDiv = props.sonValoresNumericos ? claseBloque + " num" : claseBloque;
    const claseDiv = claseBloque
    const estilo = (props.estiloEspecial) ? props.estiloEspecial : {}

    var filasNumericas = [];
    if (props.filasNumericas)
        filasNumericas = props.filasNumericas;
    const filasHTML = props.bloque.map(fila => <FilaNombreValor 
                                                    fila={fila} 
                                                    cambiaPagina={props.cambiaPagina}
                                                    muestraAviso={props.muestraAviso}
                                                    claseFila={props.claseFila}
                                                    claseCol={props.claseCol}
                                                    esNumero={filasNumericas.includes(props.bloque.indexOf(fila))}
                                                    conValoresNumericos={props.conValoresNumericos}
                                                ></FilaNombreValor>);
    return (
        <div class={claseDiv} style={estilo}>
            {filasHTML}
        </div>
    )
}
export default BloqueNombreValor;
