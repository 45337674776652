const FilaBloquePrecios = (props) => {
    const estilopfuturo = (props.fila.valor2 && props.fila.valor2.length>9) ? {fontSize: '12px', paddingTop: '5px'} : {};
    
    if (props.fila.colSpan){
        if (props.tienePFuturos){
            return (
                <div class="fila filaspan">
                    <div class="col">{props.fila.titulo}</div>
                    <div className="col colspan" style={{paddingTop: '5px'}}>{props.fila.valor1}</div>
                </div>
            )
        }
        else{
            return (
                <div class="fila filaspan" style={{paddingRight: '10px'}}>
                    <div class="col">{props.fila.titulo}</div>
                    <div className="col colspan" style={{paddingTop: '5px'}}>{props.fila.valor1}</div>
                </div>
            )
        }
    }
    else{
        if (props.tienePFuturos){
            return (
                <div class="fila" style = {{gridTemplateColumns : props.gridTemplateColumns}}>
                    <div class="col">{props.fila.titulo}</div>
                    <div className="col">{props.fila.valor1}</div>
                    <div className="col" style={estilopfuturo}>{props.fila.valor2}</div>
                    <div className="col" style={{paddingTop: '7px'}}>{props.fila.valor3}</div>
                </div>
            )
        }
        else{
            return (
                <div class="fila" style = {{gridTemplateColumns : props.gridTemplateColumns}}>
                    <div class="col">{props.fila.titulo}</div>
                    <div className="col" style={{}}>{props.fila.valor1}</div>
                    <div className="col" style={{paddingTop: '7px'}}>{props.fila.valor2}</div>
                </div>
            )
        }
    }
}
export default FilaBloquePrecios;
