import {AddClassNameToClassItems
    , GetAntecesorWhithClassName
    , GetIndexFromIndexedClass
    , RemoveClassNameToClassItems} from '../../../services/utils.js'

const ItemMenuPrincipalGris = (props) => {

    const CambiaPaginaSimple = (codigoPagina) => {
        props.cambiaPagina({
            newpagina: codigoPagina
        });
    }


    const HandleHover_OpcionMenunGris = (e, hayclick, param) =>{
        if ((document.documentElement.clientWidth > 959) || hayclick)
        {
            RemoveClassNameToClassItems('child__menu1',null,'active');
            RemoveClassNameToClassItems('child__menu2',null,'active');
            RemoveClassNameToClassItems('nenuChilds',null,'active');
            RemoveClassNameToClassItems('coverToggleMenu','historial','active');
            RemoveClassNameToClassItems('list__1',null,'displayblock');
            AddClassNameToClassItems('list__1',null,'displaynone');

            if (param==="BHistorialUsuario"){
                AddClassNameToClassItems('historial__content__1', null, 'active');
            }
            else{
                //AddClassNameToClassItems('historial__content__1', null, 'active');
                AddClassNameToClassItems('coverToggleMenu','historial', 'active');
            }

        }
    }
 
    const HandleClick_OpcionMenunGris = (e, param, AlClick) =>{
        if (param !== "Historial")
            AlClick(param);
        else{
            //RemoveClassNameToClassItems('coverToggleMenu','historial', 'active');
            HandleHover_OpcionMenunGris(e,true,param);
            AddClassNameToClassItems('backItem__3',null, 'active');
            if (document.documentElement.clientWidth <= 959) {
                AddClassNameToClassItems('historial',null, 'active');
            }
        }
    }

    const miCursor = (props.item.parametro==="Historial" ? 'default' : 'pointer');


    return (
        <li>
            <a title={props.item.titulo} style={{cursor:miCursor}} 
                onClick={(e) => HandleClick_OpcionMenunGris(e,props.item.parametro, props.item.alHacerClick)} 
                onMouseOver={(e) => HandleHover_OpcionMenunGris(e,false,props.item.parametro)} 
            >
                <span class="mask mask__auto" >
                    <img src={props.item.imagenGris} alt={props.item.titulo}  />
                </span>
                {props.item.titulo}
            </a>
        </li>
    )
}
export default ItemMenuPrincipalGris;
