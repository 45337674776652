import { PerfilUsuario, rootPath } from "../../../../services/utils";
import Ficha from "../../Ficha";
import LinksFichaFM from "../../FichaFM/LinksFichaFM";
import PreciosFM from "../../FichaFM/PreciosFM";
import LinksFichaMUV from "../../FichaMUV/LinksFichaMUV";
import LinksFichaPA from "../../FichaPA/LinksFichaPA";
import TablaInfoPrincipalPA from "../../FichaPA/TablaInfoPrincipalPA";
import LinksFichaPAT from "../../FichaPAT/LinksFichaPAT";
import TablaInfoPrincipalPAT from "../../FichaPAT/TablaInfoPrincipalPAT";
import LinksFichaPPF from "../../FichaPPF/LinksFichaPPF";
import PreciosPPF from "../../FichaPPF/PreciosPPF";
import DispensacionFacturacion from "./DispensacionFacturacion";
import LinksFichaMUH from "./LinksFichaMUH";
import PreciosResumen from "./PreciosResumen";
import TablaDatosFarmaceuticos from "./TablaDatosFarmaceuticos";

const ResumenInfo = (props) => {
    const ficha = props.ficha;
    var textoEncabezado = ficha.TipoRegistro;
    if (ficha.CodTipoRegistro === "PPF")
        textoEncabezado = ficha.TipoPF;
    if (ficha.CodTipoRegistro === "MUV")
        if (ficha.Homeopatico === "S")
            textoEncabezado = "Medicamento homeopático veterinario";
    const svgImagenH = (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18.001" viewBox="0 0 15 18.001">
            <path id="Combined_Shape" data-name="Combined Shape"
                d="M.882,18H0V14.5A4.939,4.939,0,0,1,5.023,9.659H9.976A4.94,4.94,0,0,1,15,14.5V18h-.883V14.5a4.059,4.059,0,0,0-4.141-3.962H5.023A4.059,4.059,0,0,0,.882,14.5V18Zm11.129,0h-.882V15.472h.882V18ZM3.785,18H2.9V15.472h.882V18ZM7.5,7.9A4.031,4.031,0,0,1,3.4,3.951,4.031,4.031,0,0,1,7.5,0a4.031,4.031,0,0,1,4.1,3.951A4.031,4.031,0,0,1,7.5,7.9ZM7.5.878A3.15,3.15,0,0,0,4.285,3.951,3.151,3.151,0,0,0,7.5,7.024a3.151,3.151,0,0,0,3.216-3.074A3.15,3.15,0,0,0,7.5.878Z"
                fill="#81509a" />
        </svg>
    );
    const svgImagenV = (
        <img width="15" src={rootPath + "img/perro.svg"}/>
    );
    const svgImagenPF = (
        <img width="15" src={rootPath + "img/jabonLiquido.svg"}/>
    );
    const svgImagenPAT = (
        <img width="15" src={rootPath + "img/patHumana.svg"}/>
    );

    const svgImagenPATV = (
        <img width="15" src={rootPath + "img/patVet.svg"}/>
    );
    var svgImagen = svgImagenH;
    if ((ficha.CodTipoRegistro==="MUV") || (ficha.CodTipoRegistro==="PAV") || (ficha.CodTipoRegistro==="ASOV"))
        svgImagen = svgImagenV;
    if ((ficha.CodTipoRegistro==="PPF") || (ficha.CodTipoRegistro==="ING"))
        svgImagen = svgImagenPF;
    if ((ficha.CodTipoRegistro==="PAT")||(ficha.CodTipoRegistro==="PATH"))
        svgImagen = svgImagenPAT;
    if (ficha.CodTipoRegistro==="PATV")
        svgImagen = svgImagenPATV;

    return (
        <div class="contenido__desc">
            <div class="objetivo f-f-Flama-Book">
                {svgImagen}
                <span>{textoEncabezado}</span>
            </div>
            <div class="detalles">
                <div class="nombre f-f-Flama-Medium">{ficha.NombreCompleto}</div>
                {((ficha.CodTipoRegistro === "MUV") 
                || (ficha.CodTipoRegistro === "PAH")
                || (ficha.CodTipoRegistro === "ASO")
                || (ficha.CodTipoRegistro === "ASOH")
                || (ficha.CodTipoRegistro === "ASOV")
                || (ficha.CodTipoRegistro === "PAT")
                || (ficha.CodTipoRegistro === "PATH")
                || (ficha.CodTipoRegistro === "PATV")
                || (ficha.CodTipoRegistro === "ING")
                || ((ficha.CodTipoRegistro === "MHO") && (ficha.PVPIVA === 0) && (ficha.PVPsinIVA === 0) && (!ficha.PreciosFuturos))
                || (ficha.CodTipoRegistro === "PHO")
                || (ficha.CodTipoRegistro === "PAV")) ?
                    <div></div>
                :
                    (((ficha.CodTipoRegistro === "FM") || (ficha.CodTipoRegistro === "PO") || (ficha.CodTipoRegistro === "VI")) ?
                        <PreciosFM ficha={ficha}></PreciosFM>
                    :
                        ((ficha.CodTipoRegistro === "PPF") ?
                            (((ficha.IdTipoPF === 1) || (ficha.IdTipoPF === 13) || (ficha.IdTipoPF === 25) || (ficha.IdTipoPF === 6)) ?
                                <PreciosPPF ficha={ficha} abreEmergenteInfoPrecio={props.abreEmergenteInfoPrecio}></PreciosPPF>
                            :
                                <div></div>
                            )
                        :
                            ((PerfilUsuario()!=="EST")?
                                <PreciosResumen ficha={ficha} abreEmergenteInfoPrecio={props.abreEmergenteInfoPrecio}></PreciosResumen>
                            :
                                <></>
                            )
                        )
                    )
                }
            </div>

            {
                (((ficha.CodTipoRegistro === "PAH") 
                || (ficha.CodTipoRegistro === "ASO")
                || (ficha.CodTipoRegistro === "PAV")
                || (ficha.CodTipoRegistro === "ASOV")
                || (ficha.CodTipoRegistro === "ASOH")) ?
                    <TablaInfoPrincipalPA ficha={ficha}></TablaInfoPrincipalPA>
                :
                    (((ficha.CodTipoRegistro === "ING")
                    )?
                        <div></div>
                    :
                        ((ficha.CodTipoRegistro === "PAT" || ficha.CodTipoRegistro === "PATV" || ficha.CodTipoRegistro === "PATH") ?
                            <TablaInfoPrincipalPAT ficha={ficha}></TablaInfoPrincipalPAT>
                        :
                            ((ficha.CodTipoRegistro === "PHO") ?
                                <></>
                            :
                                ((PerfilUsuario()!=="EST")?
                                    <DispensacionFacturacion ficha={ficha}></DispensacionFacturacion>
                                :
                                    <></>
                                )
                            )
                        )
                    )
                )
            }
            
            {
                (((ficha.CodTipoRegistro === "MUV") || (ficha.CodTipoRegistro === "MUH") || (ficha.CodTipoRegistro === "PPF") 
                || (ficha.CodTipoRegistro === "FM") || (ficha.CodTipoRegistro === "PO") || (ficha.CodTipoRegistro === "VI")) ?
                    ((PerfilUsuario()!=="EST")?
                        <TablaDatosFarmaceuticos ficha={ficha}></TablaDatosFarmaceuticos>
                    :
                        <></>
                    )
                :
                    <span></span>
                )
            }
            {
                (((ficha.CodTipoRegistro === "FM") || (ficha.CodTipoRegistro === "PO") || (ficha.CodTipoRegistro === "VI")) ?
                    <LinksFichaFM ficha={ficha} cambiaPagina={props.cambiaPagina} ></LinksFichaFM>
                :
                    ((ficha.CodTipoRegistro === "MUV") ?
                        <LinksFichaMUV ficha={ficha} cambiaPagina={props.cambiaPagina} ></LinksFichaMUV>
                    : 
                        ((ficha.CodTipoRegistro === "PPF") ?
                            <LinksFichaPPF ficha={ficha} cambiaPagina={props.cambiaPagina} ></LinksFichaPPF>
                        : 
                            (((ficha.CodTipoRegistro === "PAH") || (ficha.CodTipoRegistro === "PAV")|| (ficha.CodTipoRegistro === "PHO") 
                            || (ficha.CodTipoRegistro === "ASO") || (ficha.CodTipoRegistro === "ASOH") || (ficha.CodTipoRegistro === "ASOV")
                            || (ficha.CodTipoRegistro === "ING")
                            )?
                                <LinksFichaPA ficha={ficha} cambiaPagina={props.cambiaPagina} ></LinksFichaPA>
                            : 
                                (((ficha.CodTipoRegistro === "PAT") || (ficha.CodTipoRegistro === "PATH") || (ficha.CodTipoRegistro === "PATV")
                                )?
                                    <LinksFichaPAT ficha={ficha} cambiaPagina={props.cambiaPagina} ></LinksFichaPAT>
                                : 
                                    <LinksFichaMUH ficha={ficha} cambiaPagina={props.cambiaPagina} ></LinksFichaMUH>
                                )
                            )
                        )
                    )
                )
            }
        
        </div>
    )
}
export default ResumenInfo;

