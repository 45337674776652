import ColumnaSeccionMenuBlanco from "./ColumnaSeccionMenuBlanco.js";

const SeccionMenuBlanco = (props) => {
    const estilo1Col = { width: '30%', left: '70%'};
    const estilo2Col = { width: '55%', left: '45%'};
    const estilo3Col = { width: '80%', left: '20%'};
    const estilo4Col = { width: '100%', left: '0%'};

    const nCol = props.item.numColumnas

    const estiloMenu = (nCol === 1 ? estilo1Col : nCol === 2 ? estilo2Col : nCol === 3 ? estilo3Col : estilo4Col);

    var itemscolumnas = []

    var idx=0;
    for (var i = 0; i<nCol; i++){
        itemscolumnas.push(props.item.apartados.slice(idx,idx + props.item.filasPorColumna[i]));
        idx += props.item.filasPorColumna[i];
    }

    const columnas =  itemscolumnas.map(item => <ColumnaSeccionMenuBlanco cambiaPagina={props.cambiaPagina} item={item} perfil = {props.perfil}></ColumnaSeccionMenuBlanco>)

    return (
        <li><a title={props.item.titulo} style={{cursor : 'default'}}>{props.item.titulo}</a>
            <div class="menuHover"  style={estiloMenu} >
                <div class="grid" >
                    {columnas}
                </div>
            </div>
        </li>
    )
}
export default SeccionMenuBlanco;

