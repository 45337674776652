import ResumenInfoExt from "./ResumenInfoExt";
import ZonaImagenYPictosExt from "./ZonaImagenYPictosExt";

const InfoPrincipalExt = (props) => {
    return (
        <section class="medicamento-info">
            <div class="container flex flex--justify-center">
                <div class="contenido" style={{width:'100%'}}>
                    <ZonaImagenYPictosExt ficha = {props.ficha}></ZonaImagenYPictosExt>
                    <ResumenInfoExt ficha = {props.ficha} cambiaPagina={props.cambiaPagina} ></ResumenInfoExt>
                </div>
            </div>
        </section>
    )
}
export default InfoPrincipalExt;
