import ItemTablaDF from "./ItemTablaDF";

const TablaDatosFarmaceuticos = (props) => {
    const ficha = props.ficha;
    const tabla = [
        /*{
            sigla: 'A',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'A').length > 0),
            texto: 'Psicotropo anexo 2',
        },*/
        {
            sigla: 'AR',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'AR').length > 0),
            texto: 'Aportación reducida',
        },
        {
            sigla: 'CPD',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'CPD').length > 0),
            texto: 'Visado de inspección',
        },
        {
            sigla: 'CPD75',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'CPD75').length > 0),
            texto: 'Visado de inspección en mayores de 75 años',
        },
        {
            sigla: 'DH',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'DH').length > 0),
            texto: 'Diagnóstico hospitalario',
        },
        {
            sigla: 'DIHSC',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'DIHSC').length > 0),
            texto: 'Reserva singular financiado solo en hospital',
        },
        {
            sigla: 'E',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'E').length > 0),
            texto: 'Estupefaciente',
        },
        {
            sigla: 'EC',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'EC').length > 0),
            texto: 'Envase clínico',
        },
        {
            sigla: 'EFG',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'EFG').length > 0),
            texto: 'Medicamento genérico',
        },
        {
            sigla: 'EXO',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'EXO').length > 0),
            texto: 'Excluido SNS',
        },
        {
            sigla: 'EXOI',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'EXOI').length > 0),
            texto: 'Excluido SNS con indicaciones financiadas',
        },
        {
            sigla: 'H',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'H').length > 0),
            texto: 'Uso hospitalario',
        },
        {
            sigla: 'INSNS',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'INSNS').length > 0),
            texto: 'Incluido SNS',
        },
        {
            sigla: 'IP',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'IP').length > 0),
            texto: 'Importación paralela',
        },
        {
            sigla: 'LE',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'LE').length > 0),
            texto: 'Anotación en libro estupefacientes',
        },
        {
            sigla: 'LR',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'LR').length > 0),
            texto: 'Anotación en libro recetario',
        },
        {
            sigla: 'MSP',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'MSP').length > 0),
            texto: 'Medicamento susceptible de publicidad al público',
        },
        {
            sigla: 'MSR',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'MSR').length > 0),
            texto: 'Medicamento sin receta médica',
        },
        /*{
            sigla: 'MSR_L',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'MSR_L').length > 0),
            texto: 'Medicamento sin receta limitación AEMPS venta web',
        },*/
        {
            sigla: 'MTP',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'MTP').length > 0),
            texto: 'Medicamento tradicional plantas',
        },
        {
            sigla: 'P',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'P').length > 0),
            texto: 'Psicótropo',
        },
        {
            sigla: 'PN',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'PN').length > 0),
            texto: 'Medicamento con precio notificado',
        },
        {
            sigla: 'R',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'R').length > 0),
            texto: 'Receta médica',
        },
        {
            sigla: 'R_GIE',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'R_GIE').length > 0),
            texto: 'Medicamento con receta en guía indicación enfermería',
        },
        {
            sigla: 'TLD',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'TLD').length > 0),
            texto: 'Tratamiento de larga duración',
        },
        {
            sigla: 'UNI-D',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'UNI-D').length > 0),
            texto: 'Medicamento autorizado en unidosis',
        },
        {
            sigla: 'VERIF',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'VERIF').length > 0),
            texto: 'Debe llevar dispositivos de seguridad',
        },
        {
            sigla: 'FI_RE',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'FI_RE').length > 0),
            texto: 'Financiado en receta electrónica',
        },
    ]


    if (ficha.IdCCAA === 1){
        tabla.push({
            sigla: 'CPD_A',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'CPD_A').length > 0),
            texto: 'Visado de inspección SAS',
        })
    }
    /*
    if (ficha.IdCCAA === 2){
        tabla.push({
            sigla: 'FI_RE',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'FI_RE').length > 0),
            texto: 'Financiado en receta electrónica',
        })
    }

    if (ficha.IdCCAA === 3){
        tabla.push({
            sigla: 'FI_RE',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'FI_RE').length > 0),
            texto: 'Financiado en receta electrónica',
        })
    }
    */

    if (ficha.IdCCAA === 5){
        tabla.push({
            sigla: 'FI_CA',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'FI_CA').length > 0),
            texto: 'Financiado Canarias (PAFCAN)',
        })
    }

    if (ficha.IdCCAA === 8){
        tabla.push({
            sigla: 'NO_D',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'NO_D').length > 0),
            texto: 'No dispensable, sustitución obligatoria',
        })
    }

    if (ficha.IdCCAA === 12){
        tabla.push({
            sigla: 'CATPR',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'CATPR').length > 0),
            texto: 'Medicamento en catálogo priorizado',
        });
        tabla.push({
            sigla: 'NODIS',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'NODIS').length > 0),
            texto: 'No dispensable por catálogo priorizado',
        });
    }

    if (ficha.IdCCAA === 13){
        tabla.push({
            sigla: 'CPDM',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'CPDM').length > 0),
            texto: 'Autovisado. Comunidad de Madrid',
        })
    }

    if (ficha.IdCCAA === 10){
        tabla.push({
            sigla: 'MF_VA',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'MF_VA').length > 0),
            texto: 'Incluido disfunción eréctil lesionados medulares',
        })
    }

    if (ficha.IdCCAA === 7){
        tabla.push({
            sigla: 'MF_VA',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'MF_VA').length > 0),
            texto: 'Incluido disfunción eréctil lesionados medulares',
        })
    }

    if (ficha.IdCCAA === 15){
        tabla.push({
            sigla: 'IN_NA',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'IN_NA').length > 0),
            texto: 'Prestación farmacológica de ayuda a dejar de fumar',
        })
    }

    /*
    if (ficha.IdCCAA === 17){
        tabla.push({
            sigla: 'FI_RE',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'FI_RE').length > 0),
            texto: 'Financiado en receta electrónica',
        })
    }
    */


    const tablaV = [
        {
            sigla: 'ACV',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'ACV').length > 0),
            texto: 'Administración bajo control del veterinario',
        },
        {
            sigla: 'AEV',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'AEV').length > 0),
            texto: 'Administración exclusiva por el veterinario',
        },
        {
            sigla: 'E',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'E').length > 0),
            texto: 'Estupefaciente',
        },
        {
            sigla: 'P',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'P').length > 0),
            texto: 'Psicótropo',
        },
        {
            sigla: 'LE',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'LE').length > 0),
            texto: 'Anotación en libro estupefacientes',
        },
        {
            sigla: 'LR',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'LR').length > 0),
            texto: 'Anotación en libro recetario',
        },
        {
            sigla: 'IP',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'IP').length > 0),
            texto: 'Importación paralela',
        },
        {
            sigla: 'MSR',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'MSR').length > 0),
            texto: 'Medicamento sin receta veterinaria',
        },
        {
            sigla: 'R',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'R').length > 0),
            texto: 'Receta veterinaria',
        },
        {
            sigla: 'HSI',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'HSI').length > 0),
            texto: 'Medicamento homeopático sin indicaciones aprobadas',
        }
    ]

    const tablaPF = [
        {
            sigla: 'INSNS',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'INSNS').length > 0),
            texto: 'Incluido SNS',
        },
        {
            sigla: 'EXO',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'EXO').length > 0),
            texto: 'Excluido SNS',
        },
        {
            sigla: 'R',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'R').length > 0),
            texto: 'Receta médica',
        },
        {
            sigla: 'ANUL',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'ANUL').length > 0),
            texto: 'Aportación nula',
        },
        {
            sigla: 'AR',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'AR').length > 0),
            texto: 'Aportación reducida',
        },
        {
            sigla: 'AN',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'AN').length > 0),
            texto: 'Aportación normal',
        },
        {
            sigla: 'CPD',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'CPD').length > 0),
            texto: 'Visado de inspección',
        },
    ]

    if (ficha.IdCCAA === 1){
        tablaPF.push({
            sigla: 'SAS-B',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'SAS-B').length > 0),
            texto: 'Andalucia, baja facturable un año',
        })
        tablaPF.push({
            sigla: 'SAS-V',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'SAS-V').length > 0),
            texto: 'Andalucia, visado',
        })
    }

    if (ficha.IdCCAA === 2){
        tablaPF.push({
            sigla: 'H ARA',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'H ARA').length > 0),
            texto: 'Aragón, dieta hospitalaria para salud',
        })
    }

    if (ficha.IdCCAA === 5){
        tablaPF.push({
            sigla: 'EXCAN',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'EXCAN').length > 0),
            texto: 'Canarias, excluido SCS',
        })
        tablaPF.push({
            sigla: 'INCAN',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'INCAN').length > 0),
            texto: 'MUFACE, ISFAS, MUGEJU: visado y aportación nula',
        })
        tablaPF.push({
            sigla: 'IN-CA',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'IN-CA').length > 0),
            texto: 'SCS sin visado y aportación nula',
        })
    }

    if (ficha.IdCCAA === 8){
        tablaPF.push({
            sigla: 'NO_D',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'NO_D').length > 0),
            texto: 'No dispensable, sustitución obligatoria',
        });
    }

    if (ficha.IdCCAA === 9){
        tablaPF.push({
            sigla: 'EXCAT',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'EXCAT').length > 0),
            texto: 'Excluido oferta CatSalut',
        })
        tablaPF.push({
            sigla: 'INCAT',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'INCAT').length > 0),
            texto: 'Incluido oferta CatSalut, validació sanit',
        })
    }

    if (ficha.IdCCAA === 12){
        tablaPF.push({
            sigla: 'GA-LI',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'GA-LI').length > 0),
            texto: 'Financiado en oficina de farmacia SERGAS',
        })
        tablaPF.push({
            sigla: 'GA-PS',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'GA-PS').length > 0),
            texto: 'No financiado en oficina de farmacia SERGAS',
        })
    }

    if (ficha.IdCCAA === 16){
        tablaPF.push({
            sigla: 'NDD_R',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'NDD_R').length > 0),
            texto: 'No dispensar dietoterápicos paciente residenciado',
        })
    }

    const tablaFM = [
        {
            sigla: 'R',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'R').length > 0),
            texto: 'Receta médica',
        },
        {
            sigla: 'MSR',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'MSR').length > 0),
            texto: 'Medicamento sin receta médica',
        },
        {
            sigla: 'LR',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'LR').length > 0),
            texto: 'Anotación en libro recetario',
        },
        {
            sigla: 'FM_FI',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'FM_FI').length > 0),
            texto: 'Incluido SNS',
        },
        {
            sigla: 'FM_PI',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'FM_PI').length > 0),
            texto: 'Incluido SNS por indicación',
        },
        {
            sigla: 'AR',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'FM_AR').length > 0),
            texto: 'Aportación reducida',
        },
        {
            sigla: 'AN',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'FM_AN').length > 0),
            texto: 'Aportación normal',
        },
        {
            sigla: 'CPD',
            valor: (ficha.DatosF.filter(e => e.CodigoDato === 'CPD').length > 0),
            texto: 'Visado de inspección',
        },
    ]

    if (ficha.versionFM && ficha.versionFM === 2){
        tablaFM.splice(3,1,{sigla: 'INSNS', valor: (ficha.DatosF.filter(e => e.CodigoDato === 'INSNS').length > 0), texto: 'Incluido SNS'})
        tablaFM.splice(5,2,{sigla: 'AR', valor: (ficha.DatosF.filter(e => e.CodigoDato === 'AR').length > 0), texto: 'Aportación reducida'})
    }

    const tablaHTML = ((ficha.CodTipoRegistro === "MUV") ?
            tablaV.map(item => <ItemTablaDF item={item}></ItemTablaDF>)
        :(ficha.CodTipoRegistro === "PPF") ?            
            tablaPF.map(item => <ItemTablaDF item={item}></ItemTablaDF>)
        :(ficha.CodTipoRegistro === "FM") || (ficha.CodTipoRegistro === "PO") || (ficha.CodTipoRegistro === "VI")?            
            tablaFM.map(item => <ItemTablaDF item={item}></ItemTablaDF>)
        :
            tabla.map(item => <ItemTablaDF item={item}></ItemTablaDF>)
        );
    return (
        <div class="dispensacion-facturacion">
        <div class="dispensacion">
            {tablaHTML}
        </div>
    </div>
)
}
export default TablaDatosFarmaceuticos;
