import React, { useState } from "react";
import VolverCabeceraResultados from "./VolverCabeceraResultados";
import FormCabeceraResultados from "./FormCabeceraResultados";
import MisBuscadoresCabeceraResultados from "./MisBuscadoresCabeceraResultados";
import TituloBusqueda from "./TituloBusqueda";


const CabeceraResultados = (props) => {
    var textoVolver = ""
    if (props.infoPagina.tipoPagina==="Ficha")
        textoVolver="Volver";

    if (props.infoPagina.tipoPagina==="ResultadosBusqueda")
        textoVolver = "Volver";

    if ((props.infoPagina.codigoPagina === "BNovedadesUltimoMes")
        ||(props.infoPagina.codigoPagina === "BMedicamentosPSUltimoMes"))
        textoVolver = "Modificar fechas búsqueda"

    return (
        <div class="contenedor__buscador f-f-Flama-Book">
            <div class="container flex flex--justify-center">
                <VolverCabeceraResultados atras={props.atras} textoVolver={textoVolver}></VolverCabeceraResultados>
                {
                    (props.infoPagina.titulo) && (props.infoPagina.titulo!="") ?
                        <TituloBusqueda  infoPagina={props.infoPagina}></TituloBusqueda>
                    :
                        <FormCabeceraResultados  infoPagina={props.infoPagina} cambiaPagina={props.cambiaPagina}></FormCabeceraResultados>
                }
                <MisBuscadoresCabeceraResultados  infoPagina={props.infoPagina} cambiaPagina={props.cambiaPagina}></MisBuscadoresCabeceraResultados>
            </div>
        </div>
    );
};

export default CabeceraResultados;
