const ResumenBusqueda = (props) => {
    const condiciones = props.condiciones;
    const HTMLCondicionesBusqueda = (condiciones && condiciones.length>0) ?
            condiciones.map(cond => 
                (cond.valor && cond.valor !== "") ? 
                    <div class="type" >
                        {(cond.titulo && cond.titulo !== '')?
                            <h4 class="title">{cond.titulo}:</h4>
                        :
                            <span></span>
                        }
                        <p><pre>{cond.valor}</pre></p>
                    </div>
                :
                    <div class="type" >
                        <h4 class="title">{cond.titulo}</h4>
                    </div>
            )
        :
            <div></div>
           

    return(
        <div>
            {(condiciones && condiciones.length>0) ?
                <div class="mySearch" >
                    <div class="title" >
                        <h2 class="fs fs29 blackColor" >Mi búsqueda:</h2>
                    </div>
                    <div class="text" >
                        {HTMLCondicionesBusqueda}
                    </div>
                </div>
            :
                <div></div>
            }
        </div>
    );
}

export default ResumenBusqueda;