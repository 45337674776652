import React, { useState, useEffect, createRef } from "react";
import CabeceraResultados from "../../Resultados/CabeceraResultados/CabeceraResultados";
import BP2022Service from "../../../services/user.service";
import InfoPrincipalMUH from "../FichaMUH/InfoPrincipal/InfoPrincipalMUH.js";
import MenuIzquierda from "../FichaMUH/MenuIzquierda/MenuIzquierda";
import ContenedorApartados from "../FichaMUH/Apartados/ContenedorApartados";
import MensajesEmergentes from "../FichaMUH/MensajesEmergentes";
import { AnadeApartado, baseURLPictogramas, compruebaAnclas, RemoveClassNameToClassItems } from "../../../services/utils";
import LoadingPopup from "../../Resultados/Loading.Popup";
import MargenDerechoFM from "./MargenDerechoFM";

const FichaFM = (props) => {
    const id = props.infoPagina.id;
    const [ficha, setFicha] = useState(null);
    const [anclas, setAnclas] = useState([]);
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        if ((ficha==null) || (ficha.Id !== id))
        {
            descargaFicha();
        }        
        else if (refresh==0){
            descargaMensajesEmergentes(ficha.Id);
        }
        else if (refresh==1){
            descargaCompos(ficha.Id, ficha.IdComposicionPor, ficha.IdFormaFarma);
        }
        else if (refresh==2){
            var codGrupo = ficha.CodATCN5;
            var nivel = 5;
            if ((codGrupo === null)||(codGrupo===''))
            {
                codGrupo = ficha.CodATCN4;
                nivel = 4;
            }
            if ((codGrupo === null)||(codGrupo===''))
            {
                codGrupo = ficha.CodATCN3;
                nivel = 3;
            }
            descargaGrupos(ficha.Id, codGrupo, nivel, ficha.CodVMPP);
        }
        else if (refresh==3){
            //descargaAdvertencias(ficha.Id);
            setRefresh(prev => prev + 1);
        }
        else if (refresh==4){
            descargaInterferencias(ficha.Id)
        }
        else if (refresh==5){
            descargaFarmacogenetica(ficha.Id)
        }
        else if (refresh==6){
            descargaDatosConservacion(ficha.Id)
        }
        else if (refresh==7){
            descargaHistorico(ficha.Id)
        }
        else if (refresh==8){
            descargaInformacionAdicional(ficha.Id)
        }
        else if (refresh==9){
            descargaVideos(ficha.Id)
        }
        else if (refresh == 10)
        {
            
            //setRefresh(prev => prev + 1);
        }
    })

    const descargaFicha = () => {
        BP2022Service.getFichaFM(id)
        .then((response) => {
            RemoveClassNameToClassItems('lightbox__popup',null,'active');
            //checkAnclas(response.data.Apartados);            
            var auxFicha = response.data;
            compruebaAnclas(auxFicha.Apartados,anclas);
            auxFicha.TipoRegistro = (auxFicha.CodTipoRegistro==="PO") ? "Preparado oficinal" : (auxFicha.CodTipoRegistro==="VI") ? "Vacuna individualizada" : "Fórmula magistral";
            setFicha(auxFicha);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
    
    const descargaCompos = (miId, miIdCPor, miIdFF) => {
        BP2022Service.getComposM(miId,miIdCPor,miIdFF)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Composiciones){
                    if (response.data.Composiciones.length>0){
                        AnadeApartado({Titulo: "Composición",  Ancla:"COMP"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosComposicion = response.data;
                    }
                }
                setRefresh(prev => prev + 1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
    
    const descargaGrupos = (miId, miGTATC, nivelGTATC, miGTVMPP) => {
        if (ficha.Interoperable)
            setRefresh(prev => prev +1);
        else{
            BP2022Service.getGruposMUH (miId,miGTATC,nivelGTATC,miGTVMPP)
            .then((response) => {
                if (ficha != null){
                    var miFicha = ficha;
                    AnadeApartado({Titulo: "Medicamentos con igual composición",  Ancla:"GRUP"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.DatosGrupos = response.data;
                    setRefresh(prev => prev +1);
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
        }
    };
    
    const descargaAdvertencias = (miId) => {
        BP2022Service.getAdvertenciasMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Advertencias){
                    if (response.data.Advertencias.length>0){
                        AnadeApartado({Titulo: "Advertencias",  Ancla:"ADV"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosAdvertencias = response.data;
                    }
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
   
    const descargaInterferencias = (miId) => {
        BP2022Service.getInterferenciasMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Análisis clínicos",  Ancla:"ANA"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.Interferencias = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
 
    const descargaFarmacogenetica = (miId) => {
        BP2022Service.getFarmacogeneticaMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Farmacogenética componentes",  Ancla:"FGEN"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.FarmacogeneticaComponentes = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaDatosConservacion = (miId) => {
        BP2022Service.getConservacionMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                var l = 0;
                if (response.data.DatosConservacion)
                    l += response.data.DatosConservacion.length;
                if (response.data.MensajesConservacion)
                    l += response.data.MensajesConservacion.length;
                if (l>0){
                    AnadeApartado({Titulo: "Conservación y periodos de validez",  Ancla:"CPV"},miFicha.Apartados,anclas);
                    miFicha.InfoConservacion = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaHistorico = (miId) => {
        BP2022Service.getHistoricoMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Histórico",  Ancla:"HIST"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.DatosHistorico = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaInformacionAdicional = (miId) => {
        BP2022Service.getInformacionAdicionalMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Información adicional",  Ancla:"DOCS"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.DatosInfoAdicional = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaVideos = (miId) => {
        BP2022Service.getVideosMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Documentos){
                    if (response.data.Documentos.length>0){
                        AnadeApartado({Titulo: "Vídeos",  Ancla:"VID"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosVideos = response.data;
                    }
                }
                props.descargaHistorial();
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };


    const descargaMensajesEmergentes = (miId) => {
        BP2022Service.getMensajesEmergentesMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    miFicha.MensajesEmergentes = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };


    /*const checkAnclas = (aptdos) => {
        for (var i=0; i<aptdos.length; i++){
            const idAncla = aptdos[i].Ancla;
            if (!anclas.find(ancla => ancla.idAncla===idAncla))
            {
                const anclaNueva = createRef();
                anclas.push({idAncla: idAncla, refAncla : anclaNueva});
            }
        }
    }*/
    
    if (ficha !== null) 
    {
        return (
            <div>
                <CabeceraResultados infoPagina={props.infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras}></CabeceraResultados>
                <InfoPrincipalMUH ficha={ficha} cambiaPagina={props.cambiaPagina} ></InfoPrincipalMUH>
                {
                    (ficha.MensajesEmergentes && ficha.MensajesEmergentes.length>0) ?
                        <MensajesEmergentes mensajes={ficha.MensajesEmergentes} id={ficha.Id}></MensajesEmergentes>
                    :
                        <div></div>
                }
                <section class="medicamento-infoGeneral">
                    <div class="container flex flex--justify-center">
                        <div class="contenido f-f-Flama-Book">
                            <MenuIzquierda apartados={ficha.Apartados} activo={ficha.Apartados[0].Ancla} anclas={anclas}></MenuIzquierda>

                            <ContenedorApartados cambiaPagina={props.cambiaPagina} ficha={ficha} anclas={anclas}></ContenedorApartados> 
                            <MargenDerechoFM cambiaPagina={props.cambiaPagina} ficha={ficha} ></MargenDerechoFM>

                        </div>
                    </div>
                </section>
            </div>
        )
    }
    else 
        return (
            <div>
                <LoadingPopup></LoadingPopup>
            </div>
        )
}
export default FichaFM;
