import DocumentosDeTipo from "./DocumentosDeTipo";

const InformacionAdicional = (props) => {
    const datosInfoAdicional = props.datosInfoAdicional;
    const htmlInfoAdicional = datosInfoAdicional.map(grupodocs => <DocumentosDeTipo grupoDocs={grupodocs}></DocumentosDeTipo>)
    return (
        <div class="infoContent" >
            {htmlInfoAdicional}
        </div>
    )
}
export default InformacionAdicional;

