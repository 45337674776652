import React, { useState, useEffect } from "react";

function CheckBoxFiltro(props){
    var claseCheckBox = props.idGrupo;
    if (props.excluyente)
        claseCheckBox = claseCheckBox + ' excluyente';

    var claseLabel = (props.nivel===1 ? 'filter' : 'filter filter__child');
    if (props.grupoActivo)
        claseLabel += " grupoActivo";
    const idCheckBox = props.idGrupo + '__' + (props.idValor ? props.idValor : props.value);
    const valueCheckBox = props.value;
    const texto = props.texto;

    const handleCambioCheckBox=(e) =>{
        var marcado = e.target.checked;
        props.cambiaCheck(props.idGrupo, (props.idValor ? props.idValor : props.value), marcado);
    }


    return(
        <label class={claseLabel} >
            {props.activo ? 
                <input type="checkbox" 
                    name={idCheckBox} 
                    id={idCheckBox} 
                    value={valueCheckBox} 
                    className={claseCheckBox} 
                    onChange={e => handleCambioCheckBox(e)}
                    checked={true}
                />
            :
                <input type="checkbox" 
                    name={idCheckBox} 
                    id={idCheckBox} 
                    value={valueCheckBox} 
                    className={claseCheckBox} 
                    onChange={e => handleCambioCheckBox(e)}
                    checked={false}
            />
        }
            <p>{texto}</p>
        </label>
    )
}


export default CheckBoxFiltro;