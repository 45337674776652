import AdvertenciasTipo from "../Advertencias/AdvertenciasTipo";

const PrecaucionExtrema = (props) => {
    const datosAdver = props.datosAdvertencias;
    const advertenciasPorTipo = datosAdver.Advertencias.map(tipoAdver => <AdvertenciasTipo advertenciasTipo={tipoAdver}></AdvertenciasTipo>);

    const textos = (datosAdver.Textos && datosAdver.Textos.length>0) ? datosAdver.Textos[0].Texto.split("####") : [];
    var formatedtexto = (textos.length>0 ? <span>{textos[0]}</span>: <span></span>);
    for (var i=1; i<textos.length; i++){
        if  (i % 2 === 0){
            formatedtexto = <span>{formatedtexto}{textos[i]}</span>
        }
        else{
            formatedtexto = <span>{formatedtexto}<span style={{fontWeight:'bold'}}>&nbsp;&nbsp;&nbsp;&nbsp;{textos[i]}</span></span>
        }
    }
/*
    const textosSeccion = datosAdver.Textos.map(texto => 
        <div class="cover cover__text" >
            <h4 class="fs fs16 fw fw700 greyColor2 uppercase title" >{texto.Epigrafe}</h4>
                <p class="fs fs16 FlamaBook blackColor" style={{whiteSpace: 'pre-line'}} >{texto.Texto}</p>
        </div>
    );
*/    
    return (
        <div class="infoContent" >
            <div class="cover cover__text" >
                <h4 class="fs fs16 fw fw700 greyColor2 title" >Mensaje para el paciente</h4>
                <p class="fs fs16 FlamaBook blackColor" style={{whiteSpace: 'pre-line'}} >
                    {formatedtexto}
                </p>
            </div>            
        </div>
    )
}
export default PrecaucionExtrema;
