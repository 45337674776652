import LoadingPopup from "../../Resultados/Loading.Popup";
import BP2022Service from "../../../services/user.service";
import { useEffect, useState } from "react";
import ResumenBusqueda from "../ResumenBusqueda";
import SelectorComposicion from "../../Utilidades/Selector/SelectorComposicion/SelectorComposicion";
import SelectorComboBox from "../../Utilidades/Selector/SelectorComboBox";
import { GetAntecesorWhithClassName, upperCaseFirstLetter } from "../../../services/utils";
import SelectorUno2 from "../../Utilidades/Selector/SelectorUno2";
import { OpcionesADM, OpcionesDG, OpcionesDGPA, OpcionesDISP, OpcionesESP, OpcionesOTR, OpcionesPAC } from "../../../services/objsAplicacion";
import SelectorCHK from "../../Utilidades/Selector/SelectorCHK";
import SelectorSNX from "../../Utilidades/Selector/SelectorSNX";
import Acordeon from "../../Fichas/FichaMUH/Apartados/Composicion/Acordeon";
import SelectorEdad from "../../Utilidades/Selector/SelectorEdad/SelectorEdad";
import SelectorEnfRel from "../../Utilidades/Selector/SelectorEnfRel/SelectorEnfRel";
import ItemResumenFiltros from "../../Resultados/FiltrosYBusqueda/ResumenFiltros/ItemResumenFiltros";
import SelectorMulti2 from "../../Utilidades/Selector/SelectorMulti2";
import SelectorMulti2No from "../../Utilidades/Selector/SelectorMulti2No";
import SelectorAnaRel from "../../Utilidades/Selector/SelectorEnfRel/SelectorAnaRel";
import SelectorTexto from "../../Utilidades/Selector/SelectorTexto";

const FBAvanzadaPAH= (props) => {
    const [datosFormulario, setDatosFormulario] = useState({
        listaEnfs: null,
        listaGTs: null,
        listaAFs: null,
        listaAMs: null,
        listaAnas: null,
        listaMens: null,
        listaEpis: null,
    });
    const [seleccionFormulario, setSeleccionFormulario] = useState({
        txt: props.txt,
        idsAF: props.idsAF,
        idsAM: props.idsAM,
        idsGT: props.idsGT,
        enfsRel: (props.enfsRel ? props.enfsRel : []),
        anasRel: (props.anasRel ? props.anasRel : []),
        ops: (props.ops ? props.ops : []),
        idsMen: props.idsMen,
        texto: props.texto,
        idEpi: props.idEpi,
        noTexto: props.noTexto,
    })

    useEffect(() => {
        if (datosFormulario.listaGTs === null){
            DescargaDatos();
        }   
    },[]);

    const DescargaDatos = () => {      
        BP2022Service.datosFormularioAvanzadaPAH()
        .then((response) => {
            var df = {
                listaAFs: response.data.ActividadesF,
                listaAMs: response.data.ActividadesM,
                listaGTs: response.data.Grupos,
                listaEnfs : response.data.Enfermedades,
                listaAnas : response.data.Analisis,
                listaMens: response.data.Mensajes,
                listaEpis: response.data.Epigrafes,
            };
            df.listaGTs.splice(0,0,{Codigo:0, Valor:'Indique nombre o código del grupo'});
            df.listaEnfs.splice(0,0,{Codigo:0, Valor:'Indique la enfermedad '});
            df.listaAFs.splice(0,0,{Codigo:0, Valor:'Indique la actividad '});
            df.listaAMs.splice(0,0,{Codigo:0, Valor:'Indique un sistema enzimático'});
            df.listaAnas.splice(0,0,{Codigo:0, Valor:'Indique análisis '});
            df.listaMens.splice(0,0,{Codigo:0, Valor:'Indique advertencia'});
            df.listaEpis.splice(0,0,{Codigo:-1, Valor:'Seleccione una epígrafe'},{Codigo:0, Valor:'-'});
            setDatosFormulario(df);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const obtenDatoResumen = (opciones) =>{
        var strValores=[]
        for(var i=0; i< opciones.length; i++){
            var itemOpcion = opciones[i];
            if (itemOpcion.tip==="CHK"){
                var itemSel = seleccionFormulario.ops.find(item => item.cod===itemOpcion.cod);
                if (itemSel && itemSel.valores && itemSel.valores.length>0){
                    var strVal = "";
                    for(var j=0; j<itemSel.valores.length; j++){
                        if (j>0) strVal += " o ";
                        else strVal += itemOpcion.tit + ": ";
                        var valSel = itemOpcion.ops.find(val=> val.val === itemSel.valores[j]);
                        strVal += valSel.tit;
                    }
                    if (strVal !== "")
                        strValores.push(strVal);
                }
            }else if (itemOpcion.tip==="SNX"){
                var itemSel = seleccionFormulario.ops.find(item =>item.cod===itemOpcion.cod);
                if (itemSel && itemSel.valor && itemSel.valor !== "" && itemSel.valor !== "X"){
                    if (itemSel.valor === "S"){
                        strValores.push(itemOpcion.tit);
                    }else if (itemSel.valor === "N"){
                        strValores.push("No " + itemOpcion.tit.toLowerCase());
                    }
                }
            }
        }
        var res="";
        if (strValores.length>0){
            var res = strValores[0];
            for (var i=1; i<strValores.length; i++){
                res += "\n" + strValores[i];
            }
        }
        return res;
    }

    const construyeResumenBusqueda = () => {
        var res = [];

        if (seleccionFormulario.txt && seleccionFormulario.txt != ""){
            res.push ({titulo: 'Nombre contiene', valor: seleccionFormulario.txt});
        }

        /*
        if (seleccionFormulario.idGT!==''){
            if (datosFormulario.listaGTs){
                var item = datosFormulario.listaGTs.find(x => x.Codigo === seleccionFormulario.idGT);
                if (item)
                    res.push ({titulo: "Grupo terapéutico ATC", valor: item.Valor});
            }
        }
        */

        if ((seleccionFormulario.idsGT) && (seleccionFormulario.idsGT.length>0)){
            if (datosFormulario.listaGTs){
                var valor = '';
                for (var i =0; i<seleccionFormulario.idsGT.length; i++){
                    var item = datosFormulario.listaGTs.find(x => x.Codigo === seleccionFormulario.idsGT[i]);
                    if (item){
                        if (valor !== '')
                            valor += ", ";
                        valor += item.Valor;
                    }
                }
                if (valor != '')
                    res.push ({titulo: "Grupo/s terapéutico/s ATC", valor: valor});
            }
        }    

        if ((seleccionFormulario.idsAF) && (seleccionFormulario.idsAF.length>0)){
            if (datosFormulario.listaAFs){
                var valor = '';
                for (var i =0; i<seleccionFormulario.idsAF.length; i++){
                    var item = datosFormulario.listaAFs.find(x => x.Codigo === seleccionFormulario.idsAF[i]);
                    if (item){
                        if (valor !== '')
                            valor += ", ";
                        valor += item.Valor;
                    }
                }
                if (valor != '')
                    res.push ({titulo: "Actividades farmacológicas (todas las siguientes)", valor: valor});
            }
        }

        if ((seleccionFormulario.idsAM) && (seleccionFormulario.idsAM.length>0)){
            if (datosFormulario.listaAMs){
                var valor = '';
                for (var i =0; i<seleccionFormulario.idsAM.length; i++){
                    var item = datosFormulario.listaAMs.find(x => x.Codigo === seleccionFormulario.idsAM[i]);
                    if (item){
                        if (valor !== '')
                            valor += ", ";
                        valor += item.Valor;
                    }
                }
                if (valor != '')
                    res.push ({titulo: "Capacidades inductoras/inhibidoras metabólicas (alguna de las siguientes)", valor: valor});
            }
        }

        
        var idxEstado = seleccionFormulario.ops.findIndex(op => op.cod==="EST");
        if (idxEstado >= 0){
            var itemEstado=seleccionFormulario.ops[idxEstado];
            if (itemEstado.valores.length>0){
                var strValor = '';
                for (var i=0; i<itemEstado.valores.length; i++){
                    if (i>0) strValor += " o "
                    strValor += OpcionesDG[0].ops.find(op=> op.val === itemEstado.valores[i]).tit;
                }
                res.push({titulo: "Estado", valor: strValor});
            }
        }

        var idxCom = seleccionFormulario.ops.findIndex(op => op.cod==="COM");
        if (idxCom >= 0){
            var itemCom=seleccionFormulario.ops[idxCom];
            if (itemCom.valor==="S"){
                res.push({titulo: "Comercializado", valor: "Sí"});
            }else if (itemCom.valor==="N"){
                res.push({titulo: "Comercializado", valor: "No"});
            }
        }

        var str = obtenDatoResumen(OpcionesDISP);
        if (str !== "")
            res.push({titulo: "Opciones de dispensación y faturación", valor:str});

        var str = obtenDatoResumen(OpcionesPAC);
        if (str !== "")
            res.push({titulo: "Características del paciente", valor:str});

        var str = obtenDatoResumen(OpcionesESP);
        if (str !== "")
            res.push({titulo: "Advertencias especiales", valor:str});


        if ((seleccionFormulario.idsMen) && (seleccionFormulario.idsMen.length>0)){
            if (datosFormulario.listaMens){
                var valor = '';
                for (var i =0; i<seleccionFormulario.idsMen.length; i++){
                    var item = datosFormulario.listaMens.find(x => x.Codigo === Math.abs(seleccionFormulario.idsMen[i]));
                    if (item){
                        if (valor !== '')
                            valor += ",\n";
                        if (seleccionFormulario.idsMen[i]<0)                            
                            valor += "NO "
                        valor += item.Valor;
                    }
                }
                if (valor != '')
                    res.push ({titulo: "Advertencias/s", valor: valor});
            }
        }    

        if (seleccionFormulario.enfsRel && seleccionFormulario.enfsRel.length>0){
            if (datosFormulario.listaEnfs){
                var valor = '';
                for (var i =0; i<seleccionFormulario.enfsRel.length; i++){
                    var item = datosFormulario.listaEnfs.find(x => x.Codigo === seleccionFormulario.enfsRel[i].codigo);
                    if (item){
                        if (valor !== "") valor += "\n";
                        var epi = seleccionFormulario.enfsRel[i].epi;
                        if (Math.abs(epi)===3){
                            if (epi<0)
                                valor += "No indicado para " + item.Valor;
                            else
                                valor += "Indicado para " + item.Valor;
                        }else if (Math.abs(epi)===13){
                            if (epi<0)
                                valor += "No contraindicado en " + item.Valor;
                            else                            
                                valor += "Contraindicado en " + item.Valor;
                        }else if (Math.abs(epi)===14){
                            if (epi<0)
                                valor += "Sin uso precautorio en " + item.Valor;
                            else                            
                                valor += "Uso precautorio en " + item.Valor;
                        }else if (Math.abs(epi)===7){
                            if (epi<0)
                                valor += "Sin " + item.Valor + " como reacción adversa";
                            else                            
                                valor += "Con " + item.Valor + " como reacción adversa";
                        }
                    }
                }
                if (valor != '')
                    res.push ({titulo: "Relación con enfermedades", valor: valor});
            }
        }

        if (seleccionFormulario.anasRel && seleccionFormulario.anasRel.length>0){
            if (datosFormulario.listaAnas){
                var valor = '';
                for (var i =0; i<seleccionFormulario.anasRel.length; i++){
                    var item = datosFormulario.listaAnas.find(x => x.Codigo === seleccionFormulario.anasRel[i].codigo);
                    if (item){
                        if (valor !== "") valor += "\n";
                        var epi = seleccionFormulario.anasRel[i].epi;
                        if (Math.abs(epi)===99){
                            if (epi<0)
                                valor += "No interfiere en " + item.Valor;
                            else
                                valor += "Interfiere en " + item.Valor;
                        }else if (Math.abs(epi)===1){
                            if (epi<0)
                                valor += "No provoca aumento de " + item.Valor;
                            else
                                valor += "Provoca aumento de " + item.Valor;
                        }else if (Math.abs(epi)===2){
                            if (epi<0)
                                valor += "No provoca disminución de " + item.Valor;
                            else                            
                                valor += "Provoca disminución de " + item.Valor;
                        }else if (Math.abs(epi)===3){
                            if (epi<0)
                                valor += "No provoca positivo en " + item.Valor;
                            else                            
                                valor += "Provoca positivo " + item.Valor;
                        }else if (Math.abs(epi)===4){
                            if (epi<0)
                                valor += "No provoca negativo en " + item.Valor;
                            else                            
                                valor += "Provoca negativo " + item.Valor;
                        }
                    }
                }
                if (valor != '')
                    res.push ({titulo: "Interferencia en análisis clínicos", valor: valor});
            }
        }

        if (seleccionFormulario.texto && seleccionFormulario.texto.trim().length>2){
            if (datosFormulario.listaEpis){
                var aux = "Algún epígrafe contiene el texto ";
                if (seleccionFormulario.noTexto)
                    aux = "Ningún epígrafe contiene el texto";
                if (seleccionFormulario.idEpi && seleccionFormulario.idEpi>0){
                    var item = datosFormulario.listaEpis.find(x => x.Codigo === seleccionFormulario.idEpi);
                    if (item){
                        if (seleccionFormulario.noTexto)
                            aux = item.Valor.trim() + " no contiene el texto ";
                        else
                            aux = item.Valor.trim() + " contiene el texto ";
                    }
                }
                aux += "\"" + seleccionFormulario.texto + "\"";
                res.push({titulo: "Búsquedas por texto", valor: aux});
            }
        }
        return res;
    }

    var resumen = construyeResumenBusqueda(seleccionFormulario);

    const hayBusqueda = (seleccionFormulario.txt.length>=2)
        || (seleccionFormulario.idsGT && seleccionFormulario.idsGT.length>0)
        || (seleccionFormulario.idsAF && seleccionFormulario.idsAF.length>0)
        || (seleccionFormulario.idsAM && seleccionFormulario.idsAM.length>0)
        || (seleccionFormulario.ops && seleccionFormulario.ops.length>0)
        || (seleccionFormulario.enfsRel && seleccionFormulario.enfsRel.length>0
        || (seleccionFormulario.anasRel && seleccionFormulario.anasRel.length>0)
        || (seleccionFormulario.idsMen && seleccionFormulario.idsMen.length>0)
        || (seleccionFormulario.texto && seleccionFormulario.texto.length>2)
    );
 

    const clickBuscar = (e) =>{
        if (hayBusqueda){
            var newpagina = "BAvanzadaPAH";

            props.cambiaPagina({
                newpagina: newpagina,
                txt: seleccionFormulario.txt,
                idsAF: seleccionFormulario.idsAF,
                idsAM: seleccionFormulario.idsAM,
                idsGT: seleccionFormulario.idsGT,
                enfsRel: seleccionFormulario.enfsRel,
                anasRel: seleccionFormulario.anasRel,
                ops: seleccionFormulario.ops,
                idsMen: seleccionFormulario.idsMen,
                texto: seleccionFormulario.texto,
                idEpi: seleccionFormulario.idEpi,
                noTexto: seleccionFormulario.noTexto,
            })
        }
    }

    const BorraBusqueda = (e) =>{
        const formularioVacio={
            txt: '',
            idsGT: [],
            idsAF: [],
            idsAM: [],
            enfsRel: [],
            anasRel: [],
            ops: [],
            idsMen: [],
            texto: '',
            idEpi: 0,
            noTexto: false,
        }
        setSeleccionFormulario(formularioVacio);
        var inputs = document.getElementsByClassName('inputSelector');
        if (inputs){
            for (var i=0; i<inputs.length; i++){
                inputs[i].value = "";
                const itemContainer = GetAntecesorWhithClassName(inputs[i], 'custom-select-container');
                if (itemContainer){
                    itemContainer.classList.remove('active');
                }
            }
        }

    }

    const AddActividadM = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (!nuevaSeleccion.idsAM) { nuevaSeleccion.idsAM = []};
        nuevaSeleccion.idsAM.push(nuevovalor);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveActividadM = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var i = nuevaSeleccion.idsAM.indexOf(parseInt(valor));
        nuevaSeleccion.idsAM.splice(i,1);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddActividadF = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (!nuevaSeleccion.idsAF) { nuevaSeleccion.idsAF = []};
        nuevaSeleccion.idsAF.push(nuevovalor);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveActividadF = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var i = nuevaSeleccion.idsAF.indexOf(parseInt(valor));
        nuevaSeleccion.idsAF.splice(i,1);
        setSeleccionFormulario(nuevaSeleccion);
    }

    /*
    const CambioGrupo = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idGT= nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const BorraGrupo = () =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idGT = '';
        setSeleccionFormulario(nuevaSeleccion);
    }
    */
    const AddGrupo = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (!nuevaSeleccion.idsGT) { nuevaSeleccion.idsGT = []};
        nuevaSeleccion.idsGT.push(nuevovalor.trim());
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveGrupo = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var i = nuevaSeleccion.idsGT.indexOf(valor.trim());
        nuevaSeleccion.idsGT.splice(i,1);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambiaTxt = (e) =>{
        const item = e.target;
        if (item){
            const valor = item.value;
            const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
            nuevaSeleccion.txt = valor;
            setSeleccionFormulario(nuevaSeleccion);
        }
    }

    const UpdateEnfermedad = (idEnfermedad, epigrafe) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var item = nuevaSeleccion.enfsRel.find(item => item.codigo===idEnfermedad);
        if (item){
            item.epi = epigrafe;
        }else{
            nuevaSeleccion.enfsRel.push({codigo: idEnfermedad, epi: epigrafe});
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveEnfermedad = (idEnfermedad) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.enfsRel = nuevaSeleccion.enfsRel.filter(item => item.codigo!==idEnfermedad);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddAdvertencia = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (!nuevaSeleccion.idsMen) { nuevaSeleccion.idsMen = []};
        if (!nuevaSeleccion.idsMen.includes(nuevovalor)){
            nuevaSeleccion.idsMen.push(nuevovalor);
            setSeleccionFormulario(nuevaSeleccion);
        }
    }

    const NiegaAdvertencia = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (!nuevaSeleccion.idsMen) { nuevaSeleccion.idsMen = []};
        if (nuevaSeleccion.idsMen.includes(nuevovalor)){
            var idx = nuevaSeleccion.idsMen.indexOf(nuevovalor);
            nuevaSeleccion.idsMen.splice(idx,1,nuevovalor * -1);
            setSeleccionFormulario(nuevaSeleccion);
        }
    }

    const RemoveAdvertencia = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var i = nuevaSeleccion.idsMen.indexOf(parseInt(valor));
        nuevaSeleccion.idsMen.splice(i,1);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const UpdateAnalisis = (idEnfermedad, epigrafe) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var item = nuevaSeleccion.anasRel.find(item => item.codigo===idEnfermedad);
        if (item){
            item.epi = epigrafe;
        }else{
            nuevaSeleccion.anasRel.push({codigo: idEnfermedad, epi: epigrafe});
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveAnalisis = (idEnfermedad) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.anasRel = nuevaSeleccion.anasRel.filter(item => item.codigo!==idEnfermedad);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioBusquedaTexto = (nuevoepi, nuevotexto, negado) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idEpi = nuevoepi;
        nuevaSeleccion.texto = nuevotexto;
        nuevaSeleccion.noTexto = negado;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambiaValorSNX = (valorSNX, idSNX) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (!nuevaSeleccion.ops)
            nuevaSeleccion.ops = [];
        var item = nuevaSeleccion.ops.find(it => it.cod===idSNX);
        if (item){
            if (valorSNX !== 'X'){
                item.valor = valorSNX;
            }else{
                var idx = nuevaSeleccion.ops.indexOf(item);
                nuevaSeleccion.ops.splice(idx,1);
            }
        }else{
            if (valorSNX !=='X'){
                nuevaSeleccion.ops.push({cod:idSNX, valor:valorSNX});
            }
        }
        setSeleccionFormulario(nuevaSeleccion);
    }


    const CambiaValorCHK = (valoresCHK,idCHK) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (!nuevaSeleccion.ops)
            nuevaSeleccion.ops = [];
        var item = nuevaSeleccion.ops.find(it => it.cod===idCHK);
        if (item){
            if (valoresCHK.length>0){
                item.valores = valoresCHK;
            }else{
                var idx = nuevaSeleccion.ops.indexOf(item);
                nuevaSeleccion.ops.splice(idx,1);
            }
        }else{
            if (valoresCHK.length>0){
                var newitem = {
                    cod: idCHK,
                    valores: valoresCHK,
                }
                nuevaSeleccion.ops.push(newitem);
            }            
        }
        setSeleccionFormulario(nuevaSeleccion);
    }


    const listaCodValorAFs    = (datosFormulario.listaAFs) ? datosFormulario.listaAFs.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorAMs    = (datosFormulario.listaAMs) ? datosFormulario.listaAMs.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorGTs    = (datosFormulario.listaGTs)  ? datosFormulario.listaGTs.map(it =>  ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorEnfs   = (datosFormulario.listaEnfs) ? datosFormulario.listaEnfs.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorAnas   = (datosFormulario.listaAnas) ? datosFormulario.listaAnas.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorMens   = (datosFormulario.listaMens) ? datosFormulario.listaMens.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorEpis   = (datosFormulario.listaEpis) ? datosFormulario.listaEpis.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];

    const clasedivmodulefilters = "cover cover__filters cover__filters__2 cover__filters__2--1 ";

    const HTMLOpcionesDG = OpcionesDGPA.map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );

    const HTMLOpcionesPAC = OpcionesPAC.map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );

    const HTMLOpcionesESP = OpcionesESP.map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );

    const KeyDownForm = (e) =>{
        var keyCode = e.keyCode;
        if (keyCode === 13){
            clickBuscar(e);
        }
    }



    const HTMLDatosGenContenido = (
        <div>
            <div class={clasedivmodulefilters} style={{marginBottom: '20px'}}>
                <div class="coverModule" >
                    <h3 class="title fs fs16 greyColor2" style={{paddingTop: '20px', marginBottom: '10px'}}>Nombre</h3>
                    <div class="module module__filters">
                        <div class="box box__inputs">
                            <div class="cover" style={{marginLeft:'1px', marginRight: '1px'}}>
                                <input type="text" name="" value={seleccionFormulario.txt} placeholder="Nombre del principio activo"   onChange={(e) => CambiaTxt(e)}  />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="module module__filters"  style={{width: '50%'}}>
                    <h3 class="title fs fs16 greyColor2"  style={{paddingTop: '20px', marginBottom: '10px'}}>Grupo terapéutico ATC</h3>
                    <SelectorMulti2 lista = {listaCodValorGTs} 
                                idHTML="FBAvanzadaMUHLGTs" 
                                valuesSelected={seleccionFormulario.idsGT} 
                                alAnadir={AddGrupo} 
                                alBorrar={RemoveGrupo} 
                                ancho="100%"
                                idEsStr={true}
                    ></SelectorMulti2>
                </div>
            </div>
            <div class={clasedivmodulefilters} >
                <div class="module module__filters" >
                    <h3 class="title fs fs16 greyColor2" >Actividades farmacológicas:</h3>
                    <SelectorMulti2 
                                lista = {listaCodValorAFs} 
                                idHTML="FBAvaPAHAF" 
                                valuesSelected={seleccionFormulario.idsAF}
                                alAnadir={AddActividadF} 
                                alBorrar={RemoveActividadF} 
                                ancho="100%"
                                tipoFiltradoLista={1}
                    ></SelectorMulti2>
                </div>
                <div class="module module__filters" >
                    <h3 class="title fs fs16 greyColor2" >Efectos metabólicos:</h3>
                    <SelectorMulti2 
                                lista = {listaCodValorAMs} 
                                idHTML="FBAvaPAHAM" 
                                valuesSelected={seleccionFormulario.idsAM}
                                alAnadir={AddActividadM} 
                                alBorrar={RemoveActividadM} 
                                ancho="100%"
                                tipoFiltradoLista={1}
                    ></SelectorMulti2>
                </div>
            </div>
            <div class="cover cover__filters cover__filters__4" >
                {HTMLOpcionesDG}
                {/*HTMLOpcionesPAC*/}
            </div>
        </div>
    );

    const HTMLDatosEsp = (
        <div class="cover cover__filters cover__filters__4" >
            {HTMLOpcionesESP}
        </div>
    );

    const HTMLDatosPac = (
        <div>
            <div class="cover cover__filters cover__filters__4 cover__filters__padTop cover__filters__4__3" >
                {HTMLOpcionesPAC}
            </div>
        </div>
    );

    const HTMLAdvertenciasContenido = (
        <div>
            <div className="cover cover__filters" >
                <SelectorMulti2No lista = {listaCodValorMens} 
                            idHTML="FBAvanzadaMUHMAs" 
                            valuesSelected={seleccionFormulario.idsMen}
                            alAnadir={AddAdvertencia} 
                            alBorrar={RemoveAdvertencia}
                            alNegar={NiegaAdvertencia}
                            titulo={"Advertencias especiales"} 
                            ancho="100%"
                            maxLengthNormal = {85}
                ></SelectorMulti2No>
            </div>
        </div>
    );

    const HTMLDatosEnf = (
        <SelectorEnfRel lista = {listaCodValorEnfs} 
            idHTML="FBAvanzadaMUH_EnfRel" 
            valuesSelected={seleccionFormulario.enfsRel} 
            alUpdate={UpdateEnfermedad} 
            alBorrar={RemoveEnfermedad} 
            ancho="100%"
        ></SelectorEnfRel>
    );

    const HTMLDatosAna = (
        <SelectorAnaRel lista = {listaCodValorAnas} 
            idHTML="FBAvanzadaMUH_AnaRel" 
            valuesSelected={seleccionFormulario.anasRel} 
            alUpdate={UpdateAnalisis} 
            alBorrar={RemoveAnalisis} 
            ancho="100%"
        ></SelectorAnaRel>
    );

    const HTMLDatosBusquedaTexto = (
        <SelectorTexto listaEpigrafes = {listaCodValorEpis} 
            idHTML="FBAvanzadaMUH_BTexto" 
            texto = {seleccionFormulario.texto}
            idEpi = {seleccionFormulario.idEpi}
            negado = {seleccionFormulario.noTexto}
            placeholder = "Introduzca el texto a buscar"
            tituloTexto = "Términos a buscar"
            tituloEpigrafe = "Buscar en"
            alCambiar={CambioBusquedaTexto} 
            ancho="100%"
        ></SelectorTexto>
    );

    const estiloBotonesSup = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'space-between'} : {height:'76px', width:'100%', justifyContent: 'space-between', visibility:'hidden'})
    const estiloBotonesInf = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'center'} : {height:'76px', width:'100%', justifyContent: 'center', display:'none'})

    return(
        <div class="content" >
            { (datosFormulario.listaGTs === null) ?
                <LoadingPopup titulo="Un momento" texto="Preparando formulario" esPorcentaje={true}></LoadingPopup>
            :
                <form class="content__results content__results__filters" id="main-filter"  onSubmit={(e) => e.preventDefault()}  onKeyDown={(e) => KeyDownForm(e)} tabIndex="0">
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesSup}>
                        <span class="button button__arrow button__green noUppercase reset-form" data-form="#main-filter" title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} style={{visibility:'hidden'}} >
                            Vaciar búsqueda
                        </span>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                        <span class="button button__arrow button__green reset-form" data-form="#main-filter" style={{textTransform:'none', fontSize: '14px', width:'146px'}} title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} >
                            Vaciar búsqueda
                        </span>
                    </div>
                    <div class="column">
                        <Acordeon 
                            activo={true}
                            titulo = "DATOS GENERALES"
                            contenido = {HTMLDatosGenContenido}
                            tipo = {3}
                        />

                        <Acordeon 
                            activo={false}
                            titulo = "ADVERTENCIAS ESPECIALES"
                            contenido = {HTMLAdvertenciasContenido}
                            tipo = {3}
                        />

                        <Acordeon 
                            activo={false}
                            titulo = "CARACTERÍSTICAS DEL PACIENTE"
                            contenido = {HTMLDatosPac}
                            tipo = {3}
                        />

                        <Acordeon 
                            activo={false}
                            titulo = "ENFERMEDADES RELACIONADAS"
                            contenido = {HTMLDatosEnf}
                            tipo = {3}
                        />

                        <Acordeon 
                            activo={false}
                            titulo = "ANÁLISIS CLÍNICOS"
                            contenido = {HTMLDatosAna}
                            tipo = {3}
                        />

                        <Acordeon 
                            activo={false}
                            titulo = "TEXTO"
                            contenido = {HTMLDatosBusquedaTexto}
                            tipo = {3}
                        />

                    </div>
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesInf}>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                    </div>
                    <ResumenBusqueda condiciones={resumen}></ResumenBusqueda>
                </form>
            }
        </div>
    );
}
    
export default FBAvanzadaPAH;
    