import { useState } from "react";
import { PerfilUsuario, elementIsOfClass, rootPath } from "../../../../services/utils";
import AnalisisAso from "../../FichaASO/Apartados/Analisis/AnalisisAso";
import TiemposEspera from "../../FichaMUV/Apartados/TiemposEspera/TiemposEspera";
import ConfusionNombresPA from "../../FichaPA/Apartados/ConfusionNombres/ConfusionNombresPA";
import Dopaje from "../../FichaPA/Apartados/Dopaje/Dopaje";
import InfoGeneralPA from "../../FichaPA/Apartados/InfoGeneral/InfoGeneralPA";
import Sinonimias from "../../FichaPA/Apartados/Sinonimias/Sinonimias";
import UsoAnimalesProductores from "../../FichaPA/Apartados/UsoAnimalesProductores/UsoAnimalesProductores";
import Cie from "../../FichaPAT/Apartados/CIE/cie";
import InfoGeneralPAT from "../../FichaPAT/Apartados/InfoGeneral/InfoGeneralPAT";
import Jerarquias from "../../FichaPAT/Apartados/Jerarquia/Jerarquia";
import TablaProductos from "../../FichaPPF/Apartados/ContenidoPack/TablaProductos";
import Advertencias from "./Advertencias/Advertencias";
import AlertasCalidad from "./AlertasCalidad/AlertasCalidad";
import Analisis from "./Analisis/Analisis";
import Cismed from "./Cismed/Cismed";
import Emergente from "./Cismed/Emergente";
import Composiciones from "./Composicion/Composiciones";
import ConfusionNombresMed from "./ConfusionNombres/ConfusionNombresMed";
import Conservacion from "./Conservacion/Conservacion";
import Farmacogenetica from "./Farmacogenetica/Farmacogenetica";
import FarmacogeneticaPA from "./Farmacogenetica/FarmacogeneticaPA";
import Grupos from "./Grupos/Grupos";
import Historico from "./Historico/HIstorico";
import Identificacion from "./Identificacion/Identificacion";
import InformacionAdicional from "./InfoAdicional/InformacionAdicional";
import InfoGeneral from "./InfoGeneral/InfoGeneral";
import PrecaucionExtrema from "./PrecaucionExtrema/PrecaucionExtrema";
import ProblemasS from "./ProblemasS/ProblemasS";
import Textos from "./Textos/Textos";
import Videos from "./Videos/Videos";
import { seccionesFichaRestringidasEST } from "../../../../services/objsAplicacion";

const Apartado = (props) => {

    const [muestraEmergenteSimil, setMuestraEmergenteSimil] = useState(false);
    const handleClickTitulo = (e) =>{
        var item = e.target;
        while (item && !elementIsOfClass(item,'dropdown__button'))
            item = item.parentElement;
        if (item)
            item.parentElement.classList.toggle('active');
    }

    var detalleApartado;
    switch (props.apartado.Ancla){
        case "GEN":
            if ((props.ficha.CodTipoRegistro==="PAH") || (props.ficha.CodTipoRegistro==="PAV")
                ||(props.ficha.CodTipoRegistro==="ASOH") || (props.ficha.CodTipoRegistro==="ASOV") || (props.ficha.CodTipoRegistro==="ASO")
                ||(props.ficha.CodTipoRegistro==="ING"))
                detalleApartado = <InfoGeneralPA cambiaPagina={props.cambiaPagina} ficha={props.ficha}></InfoGeneralPA>
            else if ((props.ficha.CodTipoRegistro === "PAT") ||(props.ficha.CodTipoRegistro === "PATH") ||(props.ficha.CodTipoRegistro === "PATV"))
                detalleApartado = <InfoGeneralPAT cambiaPagina={props.cambiaPagina} ficha={props.ficha}></InfoGeneralPAT>
            else
                detalleApartado = <InfoGeneral cambiaPagina={props.cambiaPagina} ficha={props.ficha} abreEmergenteInfoPrecio={props.abreEmergenteInfoPrecio}></InfoGeneral>
            break;
        case "COMP":
            detalleApartado = <Composiciones cambiaPagina={props.cambiaPagina} datosCompos={props.ficha.DatosComposicion} tipo={props.ficha.CodTipoRegistro}></Composiciones>
            break;
        case "GRUP":
            if (props.ficha.CodTipoRegistro === "PPF"){
                detalleApartado = <Grupos 
                    cambiaPagina={props.cambiaPagina} 
                    datosGrupos={props.ficha.DatosGrupos} 
                    agrupCCAA = {props.ficha.AgrupacionCCAA} 
                    agrupNac = {props.ficha.AgrupacionNacional} 
                    pFuturos={props.ficha.PFuturos}
                    ccaa = {props.ficha.CCAA}
                    nombreGrupos = 'Con'>
                    </Grupos>
            }
            else{
                detalleApartado = <Grupos 
                    cambiaPagina={props.cambiaPagina} 
                    datosGrupos={props.ficha.DatosGrupos} 
                    agrupCCAA = {props.ficha.AgrupacionCCAA} 
                    agrupNac = {props.ficha.AgrupacionNacional} 
                    pFuturos={props.ficha.PFuturos}
                    ccaa = {props.ficha.CCAA}
                    codTipoRegistro = {props.ficha.CodTipoRegistro}
                    mostrarPExtra = {props.ficha.IdCCAA===10 && props.ficha.CodTipoRegistro==="MUH" && props.ficha.DatosF.some(dato => dato.CodigoDato==="MF_VA")}
                    sustituible = {(!props.ficha.Sustituible || props.ficha.Sustituible === "Sí" || props.ficha.Sustituible === "S" || props.ficha.Sustituible === "")}
                    exceptSust = {(props.ficha.DatosF.some(dato => dato.CodigoDato ==="SUST"))}
                    >
                    </Grupos>
            }
            break;
        case "TEXT":
            detalleApartado = <Textos cambiaPagina={props.cambiaPagina} 
                                        datosTextos={props.ficha.DatosTextos} 
                                        codTipoRegistro={props.ficha.CodTipoRegistro} 
                                        epigrafesActivos = {props.epigrafesActivos}
                                        anclasEpis = {props.anclasEpis}></Textos>
            break;

        case "ADV":
            detalleApartado = <Advertencias datosAdvertencias={props.ficha.DatosAdvertencias}></Advertencias>
            break;

        case "CONS":
            detalleApartado = <Advertencias datosAdvertencias={props.ficha.DatosConsejos}></Advertencias>
            break;

        case "PEXT":
            detalleApartado = <PrecaucionExtrema datosAdvertencias={props.ficha.DatosPrecaucionExtrema}></PrecaucionExtrema>
            break;

        case "ANA":
            detalleApartado = <Analisis datosAnalisis={props.ficha.Interferencias} cambiaPagina={props.cambiaPagina}></Analisis>
            break;
        
        case "SIMIL":
            if ((props.ficha.CodTipoRegistro === "PAH")||(props.ficha.CodTipoRegistro === "PA")){
                detalleApartado = <ConfusionNombresPA nombresPA={props.ficha.NombresSimilares} cambiaPagina={props.cambiaPagina}></ConfusionNombresPA>
            }else if (props.ficha.CodTipoRegistro === "MUH"){
                detalleApartado = <ConfusionNombresMed nombresMed={props.ficha.NombresSimilares} cambiaPagina={props.cambiaPagina}></ConfusionNombresMed>
            }
            break
        case "ANAASO":
            detalleApartado = <AnalisisAso datosAnalisisAso={props.ficha.Interferencias} cambiaPagina={props.cambiaPagina}></AnalisisAso>
            break;

        case "FGEN":
            detalleApartado = <Farmacogenetica farmacogeneticaComponentes={props.ficha.FarmacogeneticaComponentes}></Farmacogenetica>
            break;  

        case "FGENPA":
            detalleApartado = <FarmacogeneticaPA farmacogenetica={props.ficha.Farmacogenetica} esAcordeon={false}></FarmacogeneticaPA>
            break;  

        case "CPV":
            detalleApartado = <Conservacion infoConservacion={props.ficha.InfoConservacion}></Conservacion>
            break;

        case "HIST":
            detalleApartado = <Historico datosHistorico={props.ficha.DatosHistorico} esECoH = {props.ficha.DatosF && props.ficha.DatosF.filter(e => e.CodigoDato === 'EC' || e.CodigoDato === 'H').length > 0}></Historico>
            break;
        
        case "IDEN":
            detalleApartado = <Identificacion datosIdentificacion={props.ficha.DatosIdentificacion}></Identificacion>
            break;
        
        case "ALER":
            detalleApartado = <AlertasCalidad datosAlertas={props.ficha.DatosAlertas} codTipoRegistro={props.ficha.CodTipoRegistro}></AlertasCalidad>
            break;

        case "PSUM":
            detalleApartado = <ProblemasS datosProblemasS={props.ficha.DatosProblemasS}></ProblemasS>
            break;

        case "DOCS":
            detalleApartado = <InformacionAdicional datosInfoAdicional={props.ficha.DatosInfoAdicional}></InformacionAdicional>
            break;

        case "CMED":
            detalleApartado = <Cismed datosCismed={props.ficha.DatosCismed}></Cismed>
            break;
            
        case "VID":
            detalleApartado = <Videos datosVideos={props.ficha.DatosVideos}></Videos>
            break;

        case "TESP":
            detalleApartado = <TiemposEspera tiemposEspera={props.ficha.TiemposEspera}></TiemposEspera>
            break;

        case "PACK":
            detalleApartado = <TablaProductos productos={props.ficha.PackContiene} cambiaPagina={props.cambiaPagina}></TablaProductos>
            break;

        case "ENPACKS":
            detalleApartado = <TablaProductos productos={props.ficha.PackContenidoEn} cambiaPagina={props.cambiaPagina}></TablaProductos>
            break;

        case "SINO":
            detalleApartado = <Sinonimias sinonimias={props.ficha.Sinonimias}></Sinonimias>
            break;

        case "DOP":
            detalleApartado = <Dopaje datosDopaje={props.ficha.DatosDopaje}></Dopaje>
            break;

        case "UAP":
            detalleApartado = <UsoAnimalesProductores datosAnimalesProductores={props.ficha.DatosAnimalesProductores}></UsoAnimalesProductores>
            break;

        case "JENF":
            detalleApartado = <Jerarquias datosJerarquia={props.ficha.DatosJerarquia} cambiaPagina={props.cambiaPagina}></Jerarquias>
            break;

        case "CIE10":
            detalleApartado = <Cie lista={props.ficha.DatosCIE} cambiaPagina={props.cambiaPagina}></Cie>
            break;

        default:
            break;
    }

    const clickTop = () => {
        window.scroll({ top: 0, behavior: 'smooth' }) 
        props.cambiaImgIndex(0);
    }

    const abreEmergenteInfoSimil = (e) => {
        setMuestraEmergenteSimil(true);
    }

    const cierraEmergenteInfoSimil = (e) => {
        setMuestraEmergenteSimil(false);
    }


    const modalTitulo = "";
    const modalContent =  
        <div>
            <p class="fs fs16 FlamaBook blackColor" style={{whiteSpace: 'pre-line', textAlign: 'justify',}} >
                La información sobre confusiones de nombre en BOT PLUS procede de las notificaciones de errores de medicación 
                entre medicamentos diferentes producidas a nivel de prescripción, administración o dispensación, debido a la 
                similitud fonética u ortográfica de los nombres de los medicamentos o principios activos, que han sido notificados
                 por profesionales sanitarios al Instituto para el Uso Seguro de los Medicamentos.
            </p>
        </div>

    const modalKeyName="ModalInfoSimil"

    return (
        /*(PerfilUsuario()!="EST" || !seccionesFichaRestringidasEST.includes(props.apartado.Ancla))?*/
            <div  class="infoCentral infoCentral__general activeAside"> 
                {(muestraEmergenteSimil) ?
                    <Emergente contenido={modalContent} keyName={modalKeyName} titulo={modalTitulo} cerrar = {cierraEmergenteInfoSimil} activo={muestraEmergenteSimil} alto='300px'></Emergente>
                :
                    <></>
                }
                <div style={{position : 'relative', top: '-114px'}} ref={props.ancla.refAncla} key={props.ancla.idAncla} id={props.ancla.idAncla}></div> 
                <div class="infoGeneral__info">
                    <div className="dropdown">
                            {props.apartado.Ancla === "SIMIL" ?
                                <h3 class="titulo dropdown__button" onClick={(e) =>  handleClickTitulo(e)}>
                                    <div>
                                        {props.apartado.Titulo}
                                        <span style={{cursor:'pointer', marginLeft:'10px'}}  float="right" onClick={(e) => abreEmergenteInfoSimil(e)}>
                                            <img src={rootPath + "img/infoGreen.svg"} alt=""/>
                                        </span>
                                    </div>                                
                                    <a onClick={(e) => clickTop()} class="up" >
                                        Subir 
                                        <img src={rootPath + "img/arrowUp.svg"} alt="arrowUp" />
                                    </a>
                                </h3>
                            :
                                <h3 class="titulo dropdown__button" onClick={(e) =>  handleClickTitulo(e)}>
                                    <div>{props.apartado.Titulo}</div>
                                    <a onClick={(e) => clickTop()} class="up" >
                                        Subir 
                                        <img src={rootPath + "img/arrowUp.svg"} alt="arrowUp" />
                                    </a>
                                </h3>
                            }
                        <div className="dropdown__content">
                            {detalleApartado}         
                        </div>                    
                    </div>       
                </div>
            </div> 
        /*:
            <></>*/
    )
}
export default Apartado;
