import { useEffect, useState } from "react";
import { getDescripcionTipo, getListaFormularios } from "../../../services/utils";
import ItemIndiceFormularios from "./ItemIndiceFormularios";
import BP2022Service from "./../../../services/user.service";

const IndiceFormularios = (props) => {
    const tipo = props.tipo;
    const codigoPagina = props.codigoPagina;
    const [listaFormularios, setListaFormularios] = useState(getListaFormularios(tipo));
    const tipoDesc = getDescripcionTipo(tipo);
    const HTMLIndice = listaFormularios.map(item => 
        <ItemIndiceFormularios item = {item} activo={item.codigoPagina === codigoPagina} cambiaPagina = {props.cambiaPagina}></ItemIndiceFormularios>
    );

    useEffect(() => {
        if (tipo === "P"){
            const nuevaLista = JSON.parse(JSON.stringify(listaFormularios));
            BP2022Service.moduloIndicacionesPF()
            .then((response) => {
                if (response.data){
                    nuevaLista.splice(5,0,
                        {
                            codigoPagina: "FBProductosIndicacionPPF",
                            display: "Productos y complementos alimenticios",
                        });
                    setListaFormularios(nuevaLista);
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
        }
    },  []);



    return (
        <aside class="aside aside__search" >
            
            <div class="search" >
                <h2 class="fs fs15 FlamaBook blackColor" >Búsquedas específicas de <span class="Flama fw fw400">{tipoDesc}</span></h2>
            </div>
            <ul class="searchTypes fs fs15 purpleColor4 FlamaBook" >
                {HTMLIndice}
            </ul>
        </aside>
    );
};

export default IndiceFormularios;
