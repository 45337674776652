import { useState } from "react";
import Slider from "react-slick";
import { divideArrayEnPartes, rootPath } from "../../../../services/utils";
import FilaPictogramas from "./FilaPictogramas";

const ZonaImagenYPictos = (props) => {
    const [sliderRef, setSliderRef] = useState(null)
    //const [sliderRef2, setSliderRef2] = useState(null)
    //const [muestraAmpliada, setMuestraAmpliada] = useState(false);
    const ficha = props.ficha;
    var literalCodigo = "Cód. Nacional";
    if ((ficha.CodTipoRegistro === "PAH") || (ficha.CodTipoRegistro === "PAV") || (ficha.CodTipoRegistro === "PHO"))
        literalCodigo = "Cód. principio activo"

    if ((ficha.CodTipoRegistro === "ASOH") || (ficha.CodTipoRegistro === "ASOV") || (ficha.CodTipoRegistro === "ASO"))
        literalCodigo = "Cód. asociación"

    if ((ficha.CodTipoRegistro === "FM") || (ficha.CodTipoRegistro === "PO") || (ficha.CodTipoRegistro === "VI"))
        literalCodigo = "Código"

    var textoEncabezado = ficha.TipoRegistro;
    if (ficha.CodTipoRegistro === "PPF")
        textoEncabezado = ficha.TipoPF;
    if (ficha.CodTipoRegistro === "MUV")
        if (ficha.Homeopatico === "S")
            textoEncabezado = "Medicamento homeopático veterinario";
    const svgImagenH = (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18.001" viewBox="0 0 15 18.001">
            <path id="Combined_Shape" data-name="Combined Shape"
                d="M.882,18H0V14.5A4.939,4.939,0,0,1,5.023,9.659H9.976A4.94,4.94,0,0,1,15,14.5V18h-.883V14.5a4.059,4.059,0,0,0-4.141-3.962H5.023A4.059,4.059,0,0,0,.882,14.5V18Zm11.129,0h-.882V15.472h.882V18ZM3.785,18H2.9V15.472h.882V18ZM7.5,7.9A4.031,4.031,0,0,1,3.4,3.951,4.031,4.031,0,0,1,7.5,0a4.031,4.031,0,0,1,4.1,3.951A4.031,4.031,0,0,1,7.5,7.9ZM7.5.878A3.15,3.15,0,0,0,4.285,3.951,3.151,3.151,0,0,0,7.5,7.024a3.151,3.151,0,0,0,3.216-3.074A3.15,3.15,0,0,0,7.5.878Z"
                fill="#81509a" />
        </svg>
    );
    const svgImagenV = (
        <img width="15" src={rootPath + "img/perro.svg"}/>
    );
    const svgImagenPF = (
        <img width="15" src={rootPath + "img/jabonLiquido.svg"}/>
    );

    const svgImagenPAT = (
        <img width="15" src={rootPath + "img/patHumana.svg"}/>
    );

    const svgImagenPATV = (
        <img width="15" src={rootPath + "img/patVet.svg"}/>
    );

    var svgImagen = svgImagenH;
    if ((ficha.CodTipoRegistro==="MUV") || (ficha.CodTipoRegistro==="PAV") || (ficha.CodTipoRegistro==="ASOV"))
        svgImagen = svgImagenV;
    if ((ficha.CodTipoRegistro==="PPF") || (ficha.CodTipoRegistro==="ING"))
        svgImagen = svgImagenPF;
    if ((ficha.CodTipoRegistro==="PAT") || (ficha.CodTipoRegistro==="PATH"))
        svgImagen = svgImagenPAT;
    if (ficha.CodTipoRegistro==="PATV")
        svgImagen = svgImagenPATV;

    const imgDefecto = 
        ((ficha.CodTipoRegistro === "MUV") || (ficha.CodTipoRegistro==="PAV") || (ficha.CodTipoRegistro==="ASOV")) ? 
        rootPath + "img/perro.svg" : 
        ((ficha.CodTipoRegistro==="PPF") || (ficha.CodTipoRegistro==="ING")) ? 
        rootPath + "img/jabonLiquido.svg" : 
        ((ficha.CodTipoRegistro==="PAT") || (ficha.CodTipoRegistro==="PATH")) ? 
        rootPath + "img/patHumana.svg" : 
        (ficha.CodTipoRegistro==="PATV") ? 
        rootPath + "img/patVet.svg" : 
        rootPath + "img/person.svg" ;
    const srcImagen =   ((props.ficha.ImagenEnvase) ?
                            (props.ficha.ImagenEnvase.URLFull != null ?
                                props.ficha.ImagenEnvase.URLFull
                            : 
                                props.ficha.ImagenEnvase.URL
                            )
                        :
                            ((props.ficha.ImagenFormula) ?
                                props.ficha.ImagenFormula.URL
                            :
                                imgDefecto
                            )
                        );

    const srcImagenFF = (
        (ficha.CodTipoRegistro === "PPF") ?
            ((props.ficha.ImagenMatAcond) ?
                (props.ficha.ImagenMatAcond.URLFull != null ?
                    props.ficha.ImagenMatAcond.URLFull
                : 
                    props.ficha.ImagenMatAcond.URL
                )
            :
                ''
            )
        :
            ((props.ficha.ImagenFormaF) ?
                (props.ficha.ImagenFormaF.URLFull != null ?
                    props.ficha.ImagenFormaF.URLFull
                : 
                    props.ficha.ImagenFormaF.URL
                )
            :
                ''
            )
        );
    const tieneImagenes = srcImagen !== '' || srcImagenFF !== '';    
    const arrayImgs = [];
    if (srcImagen != '')
        arrayImgs.push(srcImagen);
    if (srcImagenFF != '')
        arrayImgs.push(srcImagenFF);

    const imagenesHTML = tieneImagenes ? arrayImgs.map(srcImg => <div><img class="imagen__medicamento" src={srcImg} alt={srcImg} onLoad={(e) => loadImageGrande(e)}/></div>)
                                        : <div><p>&nbsp;</p><img class="imagen__medicamento" src={imgDefecto}/></div>

    const sliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        speed: 300,
        arrows: true,
        touchMove: true,
        accessibility: true,
        adaptativeHeight: true,
        dots: false,
        focusOnSelect: true,
        className: 'single-item slide__1',
    }
/*
    const sliderSettings2 = {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        speed: 300,
        arrows: true,
        touchMove: true,
        accessibility: true,
        adaptativeHeight: true,
        dots: false,
        focusOnSelect: true,
        className: 'single-item slide__2',
    }
*/                    
    const tieneImagen = !(imgDefecto === srcImagen);

    /*const clickImagenMed = (e) => {
        const src = e.target.src;
        //const imagenLight = document.querySelector('.agregar-imagen');
        const contenedorLight = document.querySelector('.imagen-light');
        //imagenLight.src = src;
        contenedorLight.classList.toggle('show');
        //imagenLight.classList.toggle('showImage');
    }*/

    /*
    const clickContenedor = (e) => {
        const contenedorLight = document.querySelector('.imagen-light');
        //const imagenLight = document.querySelector('.agregar-imagen');
        //if (e.target !== imagenLight) {
            contenedorLight.classList.toggle('show');
            //imagenLight.classList.toggle('showImage');
        //}
    }
    */
/*
    const clickImagen = (e) => {
        setMuestraAmpliada(true);
    }

    const clickCierra = (e) => {
        setMuestraAmpliada(false);
    }
*/
    /*
    const clickContenedor = (e) => {
        const contenedorLight = document.querySelector('.imagen-light');
        //const imagenLight = document.querySelector('.agregar-imagen');
        //if (e.target !== imagenLight) {
            contenedorLight.classList.toggle('show');
            //imagenLight.classList.toggle('showImage');
        //}
    }
    */


    const maxHeight=350;
    const maxWidth=300;

    const estilhorizontal = {maxWidth: maxWidth.toString() + 'px', maxHeight: maxHeight.toString() + 'px', width: '100%', height: 'auto'}
    const estilvertical = {maxWidth: maxWidth.toString() + 'px', maxHeight: maxHeight.toString() + 'px', height: '100%', width: 'auto'}

/*
    const estiloImagenFlotante = {verticalAlign:'middle',width:'70%',alignContent:'center',maxHeight:'70%'}
    if ((ficha.CodTipoRegistro==="PAV")||(ficha.CodTipoRegistro==="PAH")){
        estiloImagenFlotante.backgroundColor = '#FFFFFF'
    }
*/

    const loadImage = (e) => {
        var h = e.target.height;
        if (h===maxHeight){
            e.target.style.height = '100%';
            e.target.style.width = 'auto';
            e.target.style.maxWidth = maxWidth.toString() + 'px';
            e.target.style.maxHeight = maxHeight.toString() + 'px';
        }
    }

    const loadImageGrande = (e) => {
        var h = e.target.height;
        var w = e.target.width;
        var maxHeightG = window.innerHeight * 0.65;
        var maxWidthG = window.innerWidth * 0.65;
        if (h>=maxHeightG){
            e.target.style.height = maxHeightG.toString() + 'px';
            e.target.style.width = 'auto';
            e.target.style.maxWidth = maxWidthG.toString() + 'px';
            e.target.style.maxHeight = maxHeightG.toString() + 'px';
        }
        else if (w>=maxWidthG){
            e.target.style.height = 'auto';
            e.target.style.width = maxWidthG.toString() + 'px';
            e.target.style.maxWidth = maxWidthG.toString() + 'px';
            e.target.style.maxHeight = maxHeightG.toString() + 'px';
        }
    }

    const seccionImagen = (tieneImagen ? 
        <div class="imagen" style={{textAlign:'center'}}>
            <img class="imagen__medicamento" src={srcImagen} 
                style={estilhorizontal} 
                alt={props.ficha.NombreCompleto} 
                onClick={(e) => props.clickImagen(e)}
                onLoad={(e) => loadImage(e)}
            />
            <Slider ref={sliderRef} {...sliderSettings}>
                {imagenesHTML}
            </Slider>
            <img class="imagen__lupa" src={rootPath + "img/ficha/lupa-gris.svg"} alt="lupa" />
        </div>
    :
        <div class="imagen" style={{cursor:'default'}}>
            <center>
                <img class="imagen__medicamento" src={srcImagen} alt={props.ficha.NombreCompleto} style={{width:'50%'}} />
            </center>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </div>
    )


    const pictogramasEnFilas = (((ficha.CodTipoRegistro === 'PAT') || (ficha.CodTipoRegistro === 'PATH') || (ficha.CodTipoRegistro === 'PATV') 
                                || (ficha.CodTipoRegistro === 'FM') || (ficha.CodTipoRegistro === 'PO') || (ficha.CodTipoRegistro === 'VI')) ?
        null
        :
        divideArrayEnPartes(ficha.Pictogramas,5)   
    );

    const pictogramas = (pictogramasEnFilas) ? pictogramasEnFilas.map( fila => <FilaPictogramas fila={fila}></FilaPictogramas>) : <div></div>;
    var literalNumRegistro = <span>Núm. registro</span>;
    if (ficha.CodTipoRegistro === 'PPF')
        literalNumRegistro = <span>Cód.&nbsp;&nbsp;registro</span>;
    /*if (ficha.LiteralCodigoRegistro)
        literalNumRegistro = ficha.LiteralCodigoRegistro;*/

    const tieneCodigoUdi =  ((ficha.CodTipoRegistro === "PPF") && (ficha.IdTipoPF) && ((ficha.IdTipoPF === 1 || ficha.IdTipoPF === 13 || ficha.IdTipoPF === 25))  && (ficha.CodUDIDI) && (ficha.CodUDIDI !== ''))
    const tieneCodigo2 = tieneCodigoUdi;
    const estiloCodigos1 = (tieneCodigo2) ?
        {borderBottom : 'none'}        
        :
        {};
    const estiloCodigos2 = (tieneCodigo2) ?
        {paddingTop : '5px'}        
        :
        {}

    //ficha.CodUDIDI = ficha.CodUDIDI.substring(0,20)        
                                
    return (
        <div class="contenido__imagen">
            <div class="objetivo f-f-Flama-Book">
                {svgImagen}
                <span>{textoEncabezado}</span>
            </div>
            <div class="detalles" >
                <div class="nombre" >
                    {ficha.NombreCompleto}
                </div>
            </div>
            {seccionImagen}
            {((ficha.CodTipoRegistro !== "ING" 
                        && ficha.CodTipoRegistro !== "PAT" 
                        && ficha.CodTipoRegistro !== "PATH" 
                        && ficha.CodTipoRegistro !== "PATV"
                        && ficha.CodTipoRegistro !== "PAH"
                        && ficha.CodTipoRegistro !== "PAV"
                        && ficha.CodTipoRegistro !== "PHO"
                    ) 
                    || ((ficha.CodTipoRegistro !== "ING") && (ficha.CodCAS) && (ficha.CodCAS != ''))
                    || ((ficha.CodCIE) && (ficha.CodCIE != '') && false) 
                ) ?
                <div class="codigos" style={estiloCodigos1}>
                    {(ficha.CodTipoRegistro !== "ING" 
                        && ficha.CodTipoRegistro !== "PAT" 
                        && ficha.CodTipoRegistro !== "PATH" 
                        && ficha.CodTipoRegistro !== "PATV"
                        && ficha.CodTipoRegistro !== "PAH"
                        && ficha.CodTipoRegistro !== "PAV"
                        && ficha.CodTipoRegistro !== "PHO"
                    ) ?
                        <div class="codigo f-f-Flama-Basic">
                            <span>{literalCodigo}:</span>
                            <span class="f-f-Flama-Book spancodigo" >{ficha.Codigo}</span>
                        </div>
                    :
                        (ficha.CodTipoRegistro === "PHO") ?
                            (ficha.Codigo && ficha.Codigo !== "") ?
                                <div class="codigo f-f-Flama-Basic">
                                    <span>{literalCodigo}:</span>
                                    <span class="f-f-Flama-Book spancodigo" >{ficha.Codigo}</span>
                                </div>
                            :
                                <></>
                            
                        :
                            ((ficha.CodCAS && ficha.CodCAS != '') ?
                            <div class="codigo f-f-Flama-Basic">Código CAS: 
                                <span class="f-f-Flama-Book spancodigo">{ficha.CodCAS}</span>
                            </div>
                        :
                            ((ficha.CodCIE && ficha.CodCIE != '' && false) ?
                                <div class="codigo f-f-Flama-Basic">Código CIE: 
                                    <span class="f-f-Flama-Book spancodigo">{ficha.CodCIE}</span>
                                </div>
                            :
                                <div></div>
                            )
                        )
                    }
                    {((ficha.NumRegistro && ficha.NumRegistro != '' && ficha.CodTipoRegistro !== "FM" && ficha.CodTipoRegistro !== "PO" && ficha.CodTipoRegistro !== "VI") ?
                        <div class="codigo f-f-Flama-Basic">
                            <span>{literalNumRegistro}:</span>
                            <span class="f-f-Flama-Book spancodigo" >{ficha.NumRegistro}</span>
                        </div>
                    :
                        <div></div>
                    )}
                </div>
            :
                <div></div>
            }
            {((tieneCodigoUdi)?
                (
                    (ficha.CodUDIDI.length>20) ?
                        <div class="codigos" style = {estiloCodigos2}>
                            <div class="codigo f-f-Flama-Basic" style={{display:'flex'}}>
                                <div style = {{width: '80px', minWidth: '77px', paddingTop: '10px'}}>Código UDI-DI:</div>
                                <div class="f-f-Flama-Book spancodigo" style = {{overflowWrap: 'break-word', wordWrap: 'break-word', fontSize:'12px', minHeight:'34px', paddingLeft:'18px', paddingRight:'18px'}}>
                                    <div style={{marginTop:'4px'}}>
                                    {ficha.CodUDIDI}
                                    </div>
                                </div>
                            </div>
                        </div>
                    :
                        <div class="codigos" style = {estiloCodigos2}>
                            <div class="codigo f-f-Flama-Basic" style={{display:'flex'}}>
                                <div style = {{width: '80px', minWidth: '77px', paddingTop: '10px'}}>Código UDI-DI:</div>
                                <div class="f-f-Flama-Book spancodigo">
                                    <div>
                                    {ficha.CodUDIDI}
                                    </div>
                                </div>
                            </div>
                        </div>
                )
            :
                <></>
            )}
            {pictogramas}
        </div>
    )
}
export default ZonaImagenYPictos;
