import { useEffect, useState } from "react";
import BP2022Service from "../../services/user.service";
import { getCurrentURL, getParam, getParamsURL, rootPath } from "../../services/utils";
import SelectorComboBox from "../Utilidades/Selector/SelectorComboBox";
import Emergente from "../Fichas/FichaMUH/Apartados/Cismed/Emergente";
import AddEmpresa from "./AddEmpresa";

const UsuAdicional = (props) => {

    const [mensaje, setMensaje] = useState('');
    const [estado, setEstado] = useState(0);
    const [enabledUsuAdicional, setEnableUsuAdicional] = useState(false);
    const [usuAdicional, setUsuAdicional] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [alto, setAlto] = useState('230px');

    const getDatosUsuAdicional = () =>{
        setEstado(1);
        BP2022Service.getUsuAdicional()
        .then((response) => {
            if (response.data){
                setEnableUsuAdicional(response.data.Habilitado)
                setUsuAdicional(response.data.Usuario);
                if (response.data.Habilitado)
                    setAlto('520px')
                else
                    setAlto('230px');
                setEstado(2);
                setMensaje('');
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
            setMensaje(error.message);
            setEstado(2);
        });
    }

    useEffect (() =>{
        if (estado===0){
            getDatosUsuAdicional();
        }
    },[]);


    const setDatosUsuAdicional = () =>{
        if (enabledUsuAdicional){
            if (newPassword && newPassword != ""){
                if (newPassword === confPassword){
                    setMensaje("Enviando datos...");
                    setAlto('230px');
                    setEstado(3);
                    BP2022Service.enableUsuAdicional(usuAdicional,newPassword)
                    .then((response) => {
                        props.alCerrar();
                    })
                }else{
                    setMensaje("Las contraseñas no coínciden")
                }
            }else{
                setMensaje("Debe proporcionar una contraseña")
            }
        }else{
            setMensaje("Enviando datos...");
            setEstado(3);
            BP2022Service.disableUsuAdicional()
            .then((response) => {
                props.alCerrar();
            });
        }
    }

    const CambiaPassword = (s) => {
        setMensaje("");
        setNewPassword(s);
    };

    const CambiaUser = (s) => {
        setUsuAdicional(s);
    };

    const CambiaConfirmacion = (s) => {
        setMensaje("");
        setConfPassword(s);
    };

    const CambiaEnabled = (e) => {
        if (e)
            if (mensaje && mensaje != "")
                setAlto('540px')
            else
                setAlto('520px')
        else
            setAlto('230px')
        setEnableUsuAdicional(e);
    }

    const txtboton = enabledUsuAdicional ? "Habilitar usuario adicional" : "Deshabilitar usuario adicional";

    const contenido = (
        (estado === 0) ?
            <div style={{height: '100%', width: '100%', cursor:'wait', overflowY: 'hidden',}}>
                <p className="fs fs15 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '30%'}}>Iniciando...</p>
            </div>
        :(estado === 1) ?
            <div style={{height: '100%', width: '100%', cursor:'wait', overflowY: 'hidden',}}>
                <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '35px'}}>Buscando información...</p>
            </div>
        :(estado === 2) ?
            <form class="content__results content__results__filters" id="main-filter" onSubmit={(e) => e.preventDefault()} autocomplete="off" >
                {(enabledUsuAdicional) && (mensaje) && (mensaje !== "") ? 
                    <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '6px'}}>{mensaje}</p>
                :
                    <></>
                }
                <p className="fw fw100 FlamaBook greyColor"
                    style={{marginBottom:'20px', marginTop:'20px'}}>
                    <label style={{marginLeft:'2px'}} className="fs fs18 fw fw100 FlamaBook greyColor">
                        <input 
                            type="checkbox" 
                            name={"chkEnableUsuAdicional"} 
                            value={enabledUsuAdicional} 
                            checked={enabledUsuAdicional} 
                            onClick={e => CambiaEnabled(e.target.checked)} />
                        Habilita usuario adicional
                    </label>
                </p>
                {(enabledUsuAdicional) ? 
                <div>
                <p className="fs fs18 fw fw100 FlamaBook greyColor"
                    style={{marginBottom:'30px'}}>
                        Usuario adicional:
                        <div class="coverModule">
                            <div class="module module__filters">
                                <div class="box box__inputs">
                                    <div class="cover cover__input">
                                        <input type="text" name="UAUser" value={usuAdicional} disabled = {true} onChange={ e => CambiaUser(e.target.value)} 
                                            placeholder="Usuario adicional" />
                                    </div>
                                </div>
                            </div>
                        </div>
                </p>
                <p className="fs fs18 fw fw100 FlamaBook greyColor"
                    style={{marginBottom:'30px'}}>
                        Contraseña:
                        <div class="coverModule">
                            <div class="module module__filters">
                                <div class="box box__inputs">
                                    <div class="cover cover__input">
                                        <input type="password" name="UAPass" value={newPassword} disabled = {!enabledUsuAdicional} onChange={ e => CambiaPassword(e.target.value)} 
                                            placeholder="Introduzca la contraseña del usuario adicional" autocomplete="new-password"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                </p>
                <p className="fs fs18 fw fw100 FlamaBook greyColor"
                    style={{marginBottom:'30px'}}>
                        Confirmación contraseña:
                        <div class="coverModule">
                            <div class="module module__filters">
                                <div class="box box__inputs">
                                    <div class="cover cover__input">
                                        <input type="password" name="UAConf" value={confPassword} disabled = {!enabledUsuAdicional}  onChange={ e => CambiaConfirmacion(e.target.value)} 
                                            placeholder="Confirme la contraseña" autocomplete="new-password"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                </p>
                </div>
                :
                <></>
                }
                <div className="cover cover__buttons" style={{textAlign: 'right', paddingTop: '10px'}} >
                    <button className="button button__purple" onClick={(e) => setDatosUsuAdicional()} style={{height:'40px', paddingTop:'12px'}}> {txtboton}</button>
                </div>
            </form>
        :(estado === 3) ?
            <div style={{height: '100%', width: '100%', cursor:'wait', overflowY: 'hidden',}}>
                <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '35px'}}>{mensaje}</p>
            </div>
        :
            <div style={{height: '100%', width: '100%', overflowY: 'hidden',}}>
                <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '30%'}}>Este paso no está implementado</p>
            </div>
    );


    return(
        <Emergente 
            contenido={contenido} 
            keyName={"usuAdicional"} 
            titulo={"Configuracion usuario adicional"} 
            activo={true} 
            ocultaAspa = {false}
            alto = {alto}
            ancho = {'600px'}
            cerrar={props.alCerrar}
        ></Emergente>
    );
}

export default UsuAdicional;
