const TituloBusqueda = (props) => {
    const tipo = props.infoPagina.codigoPagina.substring(props.infoPagina.codigoPagina.length-1,props.infoPagina.codigoPagina.length);


    const imagen = (props.infoPagina.codigoPagina==="BEnfermedadesPATH") ?
        "/img/ficha/enfHumana.png"
        : (props.infoPagina.codigoPagina==="BEnfermedadesPATV") ?
        "/img/ficha/enfVet.png"
        : (props.infoPagina.codigoPagina==="BDocumentos") ?
        "/img/ficha/alertas.svg"
        : (tipo === "V") ?
        "/img/ficha/dog.svg"
        : (tipo==="F") ?
        "/img/ficha/paraf.png"
        : 
        "/img/ficha/human.svg";
        
    return (
        <div class="contenedor__buscador--buscador">
            <div class="box box__busqueda" >
                <div class="mask" >
                    <img src={imagen} alt="" />
                </div>
                <div class="text" >
                    <p class="fs fs20 fw fw400 blackColor" ><strong>{props.infoPagina.titulo}</strong></p>
                </div>
            </div>
        </div>
    );
};

export default TituloBusqueda;
