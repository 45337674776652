import { validaAccesoPorRol } from "../../../services/objsAplicacion";

const ApartadoSimpleSeccionMenuBlanco = (props) => {
    const CambiaPaginaSimple = (codigoPagina) => {
        props.cambiaPagina({
            newpagina: codigoPagina
        });
    }

    //const estilo = (props.perfil === "COL") ? {cursor:'pointer', color:'#999999'} : {cursor:'pointer'}
    const estilo = (!validaAccesoPorRol(props.item.pagina,props.perfil)) ? {cursor:'pointer', color:'#999999'} : {cursor:'pointer'}
    
    return (
        <div class="grid__item">
            <div class="title">
                <span style={estilo} onClick={(e) => CambiaPaginaSimple(props.item.pagina)} title={props.item.titulo} >{props.item.titulo}</span>
            </div>
        </div>
    )    
}
export default ApartadoSimpleSeccionMenuBlanco;
