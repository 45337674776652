import React, { useState } from "react";
import BandaMoradaZonaMenus from "./BandaMoradaZonaMenus.js";
import Animation from "../Utilidades/Animation.js";
import { RemoveClassNameToClassItems, ToggleClassNameToClassItems} from '../../services/utils'
import MenuBlanco from "./MenuBlanco/MenuBlanco.js";


const ZonaMenus = (props) => {
    const [estadoMenu, setEstadoMenu] = useState(false);
    const [cargaInicial, setCargaInicial] = useState(true);
    const [refresh, doRefresh] = useState(0);
    var up = false;
    var miImgIndex = 0;
    const miPath='../img';
    const miFiename = 'MBTran';
    const miExt = 'png';
    const miT = 300;
    const miN = 7;
    var ok=true;
    

    const elem = document.getElementById('divMenuBurger');
    if (elem != undefined)
        if (elem != null)
            if (elem.classList.contains('active')){
                if (!estadoMenu){
                    setEstadoMenu(true);
                    ok = false;
                    miImgIndex = miN-1;
                    up = true;
                }
            }

    if (cargaInicial){
        miImgIndex = 0;
        up = false;
    }
    else if (estadoMenu){
        miImgIndex = 0;
        up = true;
    }
    else{
        miImgIndex = miN-1;
        up = false;
    }

    const handleClickMenuBurger = (e) =>{

        var itemMenu = document.getElementById("miMenuBurguer__BP2022");
        var estaActivo = estadoMenu;
        if (itemMenu)
            estaActivo = itemMenu.classList.contains('active');
        if (cargaInicial)
            setCargaInicial(false);
        if (estaActivo){
            up = true;
            miImgIndex = 0;
        }
        else{
            up = false;
            miImgIndex = miN - 1;
        }
        e.target.classList.toggle('active');
        document.getElementById('divMenuBurger').classList.toggle('active');
        ToggleClassNameToClassItems('coverToggleMenu',null,'active');
        if (estaActivo){
            /*RemoveClassNameToClassItems('child__menu1',null, 'active');
            RemoveClassNameToClassItems('child__menu2',null, 'active');
            RemoveClassNameToClassItems('historial__content',null, 'active');    */
            props.cierraMenuBurguer();
        }

        doRefresh(prev => prev + 1);        
        setEstadoMenu(!estaActivo);
    }

    
    return (
        <div class="module module__menu" >
            <BandaMoradaZonaMenus elementosMenuIconos = {props.elementosMenuIconos} cambiaPagina = {props.cambiaPagina}  provincia = {props.provincia}/>
            <div class="module module__bottom">
                <MenuBlanco cambiaPagina={props.cambiaPagina} elementosMenu={props.opcionesMenu} perfil = {props.perfil}/>
                <div class="toggle toggle__menu" onClick={(e) => handleClickMenuBurger(e)}  id='divMenuBurger'>
                    <div>
                        <Animation path={miPath} filename={miFiename} ext={miExt} t={miT} n={miN} up={up} initIdx={miImgIndex} id='imgMenuBurger' refresh={refresh}></Animation>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ZonaMenus;
