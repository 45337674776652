import { useEffect, useState } from "react";
import BusqMedsService from "../../services/user.service";
import BInteracciones from "./BInteracciones/BInteracciones";
import CabeceraResultados from "./CabeceraResultados/CabeceraResultados";
import FiltrosYResultados from "./FiltrosYBusqueda/FiltrosYResultados";
import LoadingPopup from "./Loading.Popup";
import { PerfilUsuario } from "../../services/utils";

const SeccionResultados = (props) => {
    const infoPagina = props.infoPagina;

    const filtroNombre = {
        idGrupo : 'NOMBRE',
        nombre : 'Nombre',
        tipo: 'Text',
        campolista: 'NombreCompleto',
        tipoCampolista: 'string',
        valor : (infoPagina.filtrosActivos ? 
            (infoPagina.filtrosActivos.find(x => x.nombre === 'NOMBRE')) ? infoPagina.filtrosActivos.find(x => x.nombre === 'NOMBRE').valor : ''
            : ''),
        display: 'Nombre: ',
        abierto: true,
    }

    const filtroCodigo = {
        idGrupo : 'CODIGO',
        nombre : 'Codigo',
        tipo: 'CN',
        campolista: 'Codigo',
        tipoCampolista: 'string',
        valor : (infoPagina.filtrosActivos ? 
            (infoPagina.filtrosActivos.find(x => x.nombre === 'CODIGO')) ? infoPagina.filtrosActivos.find(x => x.nombre === 'CODIGO').valor : ''
            : ''),
        display: 'Código: ',
        abierto: true,
    }

    const filtroTitulo = {
        idGrupo : 'TITULO',
        nombre : 'Titulo',
        tipo: 'Text',
        campolista: 'Titulo',
        tipoCampolista: 'string',
        valor : (infoPagina.filtrosActivos ? 
            (infoPagina.filtrosActivos.find(x => x.nombre === 'TITULO')) ? infoPagina.filtrosActivos.find(x => x.nombre === 'TITULO').valor : ''
            : ''),
        display: 'Titulo: ',
        abierto: true,
    }

    const valorFiltroFinanciadoSegun = {
            texto:'Según indicación',
            display: 'Financiado',
            value:'X',
            activo: (infoPagina.filtrosActivos ? 
                (infoPagina.filtrosActivos.find(x => x.nombre === 'FINAN' && x.valor ==='X') ? true : false)
                : false),
            nivel:1
    }

    const filtroAportacionFM = {
            idGrupo : 'APORT',
            nombre : 'Aportación',
            tipo: 'Check',
            excluyente : false,
            campolista: 'AuxStr',
            tipoCampolista: 'codString',
            abierto: false,
            valores : [
                {
                    texto:'Normal',
                    display: 'Aportación normal',
                    value:'AN',
                    activo: (infoPagina.filtrosActivos ? 
                        (infoPagina.filtrosActivos.find(x => x.nombre === 'APORT' && x.valor ==='AN') ? true : false)
                        : false),
                    nivel:1
                },
                {
                    texto:'Reducida',
                    display: 'Aportación reducida',
                    value:'AR',
                    activo: (infoPagina.filtrosActivos ? 
                        (infoPagina.filtrosActivos.find(x => x.nombre === 'APORT' && x.valor ==='AR') ? true : false)
                        : false),
                    nivel:1
                },
            ]
    }
    const filtroVisadoFM = {
            idGrupo : 'VISA',
            nombre : 'Visado',
            tipo: 'Check',
            excluyente : true,
            campolista: 'AuxStr2',
            tipoCampolista: 'codString',
            abierto: false,
            valores : [
                {
                    texto:'Sí',
                    display: 'Visado',
                    value:'S',
                    activo: (infoPagina.filtrosActivos ? 
                        (infoPagina.filtrosActivos.find(x => x.nombre === 'VISA' && x.valor ==='S') ? true : false)
                        : false),
                    nivel:1
                },
                {
                    texto:'No',
                    display: 'No visado',
                    value:'N',
                    activo: (infoPagina.filtrosActivos ? 
                        (infoPagina.filtrosActivos.find(x => x.nombre === 'VISA' && x.valor ==='N') ? true : false)
                        : false),
                    nivel:1
                },
            ]
    }

    const filtroTipoFM = {
        idGrupo : 'TIPOFM',
        nombre : 'Tipo',
        tipo: 'Check',
        excluyente : true,
        campolista: 'AuxInt',
        tipoCampolista: 'int',
        abierto: true,
        valores : [
            {
                texto:'Fórmula magistral',
                display: 'Fórmula magistral',
                value: 1,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'TIPOFM' && x.valor.toString() === '1') ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Preparado oficinal',
                display: 'Preparado oficinal',
                value: 2,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'TIPOFM' && x.valor.toString() === '2') ? true : false)
                    : false),
                nivel:1
            },
        ]
    }

    const filtroPaises = {
        idGrupo : 'PAIS',
        nombre : 'País',
        tipo: 'ListBox',
        getPosibles : getPosiblesPaises,
        posibles:[],
        excluyente : true,
        campolista: 'AuxInt',
        tipoCampolista: 'int',
        placeholder: 'Seleccione un pais',
        valor : (infoPagina.filtrosActivos ? 
            (infoPagina.filtrosActivos.find(x => x.nombre === 'PAIS')) ? parseInt(infoPagina.filtrosActivos.find(x => x.nombre === 'PAIS').valor) : -1
            : -1),
        display: 'País: ',
        abierto: true,
    }

    const filtroEspecies = {
        idGrupo : 'ESPECIE',
        nombre : 'Especie',
        tipo: 'ListBox',
        getPosibles : getPosiblesEspecies,
        posibles:[],
        excluyente : true,
        campolista: 'ListaAux1',
        tipoCampolista: 'array',
        placeholder: 'Seleccione una especie',
        valor : (infoPagina.filtrosActivos ? 
            (infoPagina.filtrosActivos.find(x => x.nombre === 'ESPECIE')) ? parseInt(infoPagina.filtrosActivos.find(x => x.nombre === 'ESPECIE').valor) : -1
            : -1),
        display: 'Especie: ',
        abierto: true,
    }

    const filtroPAHomeo = {
        idGrupo : 'PAHOMEO',
        nombre : 'Principio activo',
        tipo: 'ListBox',
        getPosibles : getPosiblesPAHomeo,
        posibles:[],
        excluyente : true,
        campolista: 'ListaAux1',
        tipoCampolista: 'array',
        placeholder: 'Indique principio activo',
        valor : (infoPagina.filtrosActivos ? 
            (infoPagina.filtrosActivos.find(x => x.nombre === 'PAHOMEO')) ? parseInt(infoPagina.filtrosActivos.find(x => x.nombre === 'PAHOMEO').valor) : -1
            : -1),
        display: 'Principio activo: ',
        abierto: true,
    }

    const filtroTipoProductoPPF = {
        idGrupo : 'TIPOPPF',
        nombre : 'Tipo producto parafarmacia',
        tipo: 'ListBox',
        getPosibles : getPosiblesTiposProd,
        posibles:[],
        excluyente : true,
        campolista: 'AuxInt',
        tipoCampolista: 'int',
        placeholder: 'Indique tipo producto parafarmacia',
        valor : (infoPagina.filtrosActivos ? 
            (infoPagina.filtrosActivos.find(x => x.nombre === 'TIPOPPF')) ? parseInt(infoPagina.filtrosActivos.find(x => x.nombre === 'TIPOPPF').valor) : -1
            : -1),
        display: 'Tipo producto parafarmacia: ',
        abierto: true,
        oculto: true,
    }

    const filtroCisMEDFecha = {
        idGrupo : 'CISMEDFECHA',
        nombre : 'Fecha incidenica',
        tipo: 'Check',
        excluyente : true,
        campolista: 'AuxInt2',
        tipoCampolista: 'int',
        abierto: false,
        relacion: 'AND',
        valores : [
            {
                texto:'Semana actual',
                display: 'Incidencias en la semana actual',
                value: 1,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'CISMEDFECHA' && parseInt(x.valor) ===1) ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Semana pasada',
                display: 'Incidencias durante la semana pasada',
                value: 2,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'CISMEDFECHA' && parseInt(x.valor) ===2) ? true : false)
                    : false),
                nivel:1
            },
        ]
    }

    const filtroCisMEDEstado = {
        idGrupo : 'CISMEDESTADO',
        nombre : 'Estado incidencia',
        tipo: 'Check',
        excluyente : true,
        campolista: 'AuxInt',
        tipoCampolista: 'int',
        abierto: false,
        valores : [
            {
                texto:'Con incidencias',
                display: 'Con incidencias CisMED',
                value: 1,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'CISMEDESTADO' && parseInt(x.valor) ===1) ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'En seguimiento',
                display: 'En seguimiento CisMED',
                value: 2,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'CISMEDESTADO' && parseInt(x.valor) ===2) ? true : false)
                    : false),
                nivel:1
            },
        ]
    }

    
    const filtrosStandard = [
        {
            idGrupo : 'LABO',
            nombre : 'Laboratorio',
            tipo: 'List',
            getPosibles : getPosiblesLaboratorios,
            posibles:[],
            excluyente : true,
            campolista: 'Laboratorio',
            tipoCampolista: 'string',
            valor : (infoPagina.filtrosActivos ? 
                (infoPagina.filtrosActivos.find(x => x.nombre === 'LABO')) ? infoPagina.filtrosActivos.find(x => x.nombre === 'LABO').valor : ''
                : ''),
            display: 'Laboratorio: ',
            abierto: true,
        },
        {
            idGrupo : 'TIPO',
            nombre : 'Tipo de producto',
            tipo: 'Check',
            excluyente : false,
            campolista: 'CodTipoRegistro',
            tipoCampolista: 'codString',
            abierto: true,
            valores : [
                {
                    texto:'Medicamentos uso humano',
                    value:'MUH',
                    otrosValores: ['FM','MHO'],
                    activo: (infoPagina.filtrosActivos ? 
                        (infoPagina.filtrosActivos.find(x => x.nombre === 'TIPO' && x.valor ==='MUH') ? true : false)
                        : false),
                    nivel:1,
                },
                {
                    texto:'Principios activos',
                    value:'PAH',
                    otrosValores: ['ASOH','PHO'],
                    activo: (infoPagina.filtrosActivos ? 
                        (infoPagina.filtrosActivos.find(x => x.nombre === 'TIPO' && x.valor ==='PAH') ? true : false)
                        : false),
                    nivel:2
                },
                {
                    texto:'Medicamentos veterinarios',
                    value:'MUV',
                    activo: (infoPagina.filtrosActivos ? 
                        (infoPagina.filtrosActivos.find(x => x.nombre === 'TIPO' && x.valor ==='MUV') ? true : false)
                        : false),
                    nivel:1
                },
                {
                    texto:'Principios activos',
                    value:'PAV',
                    otrosValores: ['ASOV'],
                    activo: (infoPagina.filtrosActivos ? 
                        (infoPagina.filtrosActivos.find(x => x.nombre === 'TIPO' && x.valor ==='PAV') ? true : false)
                        : false),
                    nivel:2
                },
                {
                    texto:'Parafarmacia',
                    value:'PPF',
                    activo: (infoPagina.filtrosActivos ? 
                        (infoPagina.filtrosActivos.find(x => x.nombre === 'TIPO' && x.valor ==='PPF') ? true : false)
                        : false),
                    nivel:1
                },
                {
                    texto:'Ingredientes',
                    value:'ING',
                    activo: (infoPagina.filtrosActivos ? 
                        (infoPagina.filtrosActivos.find(x => x.nombre === 'TIPO' && x.valor ==='ING') ? true : false)
                        : false),
                    nivel:2
                },
                {
                    texto:'Enfermedades',
                    value:'PAT',
                    otrosValores: ['PATH','PATV'],
                    activo: (infoPagina.filtrosActivos ? 
                        (infoPagina.filtrosActivos.find(x => x.nombre === 'TIPO' && x.valor ==='PAT') ? true : false)
                        : false),
                    nivel:1
                },
            ]
        },
        {
            idGrupo : 'ESTADO',
            nombre : 'Estado',
            tipo: 'Check',
            excluyente : false,
            campolista: 'IdEstadoAutorizacion',
            tipoCampolista: 'codString',
            abierto: true,
            valores : [
                {
                    texto:'Comercializado',
                    value:'0',
                    activo: (infoPagina.filtrosActivos ? 
                        (infoPagina.filtrosActivos.find(x => x.nombre === 'ESTADO' && x.valor ==='0') ? true : false)
                        : false),
                    nivel:1
                },
                {
                    texto:'No comercializado',
                    value:'3',
                    activo: (infoPagina.filtrosActivos ? 
                        (infoPagina.filtrosActivos.find(x => x.nombre === 'ESTADO' && x.valor ==='3') ? true : false)
                        : false),
                    nivel:2
                },
                {
                    texto:'Suspendido',
                    value:'1',
                    activo: (infoPagina.filtrosActivos ? 
                        (infoPagina.filtrosActivos.find(x => x.nombre === 'ESTADO' && x.valor ==='1') ? true : false)
                        : false),
                    nivel:1
                },
                {
                    texto:'Anulado',
                    value:'2',
                    otrosValores: ['4'],
                    activo: (infoPagina.filtrosActivos ? 
                        (infoPagina.filtrosActivos.find(x => x.nombre === 'ESTADO' && x.valor ==='2') ? true : false)
                        : false),
                    nivel:1
                },
            ]
        },
        {
            idGrupo : 'RECETA',
            nombre : 'Receta',
            tipo: 'Check',
            excluyente : true,
            campolista: 'Receta',
            tipoCampolista: 'codString',
            abierto: false,
            valores : [
                {
                    texto:'Con receta',
                    value:'R',
                    activo: (infoPagina.filtrosActivos ? 
                        (infoPagina.filtrosActivos.find(x => x.nombre === 'RECETA' && x.valor ==='R') ? true : false)
                        : false),
                    nivel:1
                },
                {
                    texto:'Sin receta',
                    value:'MSR',
                    activo: (infoPagina.filtrosActivos ? 
                        (infoPagina.filtrosActivos.find(x => x.nombre === 'RECETA' && x.valor ==='MSR') ? true : false)
                        : false),
                    nivel:1
                },
            ]
        },
        {
            idGrupo : 'FINAN',
            nombre : 'Financiado en farmacia comunitaria',
            tipo: 'Check',
            excluyente : true,
            campolista: 'Financiado',
            tipoCampolista: 'codString',
            abierto: false,
            valores : [
                {
                    texto:'Sí',
                    display: 'Financiado',
                    value:'S',
                    activo: (infoPagina.filtrosActivos ? 
                        (infoPagina.filtrosActivos.find(x => x.nombre === 'FINAN' && x.valor ==='S') ? true : false)
                        : false),
                    nivel:1
                },
                {
                    texto:'No',
                    display: 'No financiado',
                    value:'N',
                    activo: (infoPagina.filtrosActivos ? 
                        (infoPagina.filtrosActivos.find(x => x.nombre === 'FINAN' && x.valor ==='N') ? true : false)
                        : false),
                    nivel:1
                },
            ]
        },
        {
            idGrupo : 'PSUM',
            nombre : 'Problemas de suministro',
            tipo: 'Check',
            excluyente : true,
            campolista: 'ProblemaSuministro',
            tipoCampolista: 'codString',
            abierto: false,
            valores : [
                {
                    texto:'Sí',
                    display: 'Con problemas de suministro',
                    value:'S',
                    otrosValores: ['X'],
                    activo: (infoPagina.filtrosActivos ? 
                        (infoPagina.filtrosActivos.find(x => x.nombre === 'PSUM' && x.valor ==='S') ? true : false)
                        : false),
                    nivel:1
                },
                {
                    texto:'No',
                    display: 'Sin problemas de suministro',
                    value:'N',
                    activo: (infoPagina.filtrosActivos ? 
                        (infoPagina.filtrosActivos.find(x => x.nombre === 'PSUM' && x.valor ==='N') ? true : false)
                        : false),
                    nivel:1
                },
            ]
        },
    ];

    function getPosiblesLaboratorios() {
        var listaLaboratorios = [];
        var listaitems = props.infoPagina.listaRes;
        if (listaitems != null){
            for (var i=0; i<listaitems.length; i++){
                if (listaitems[i].IdLaboratorio>0){                    
                    var laboratorio = {nombre: listaitems[i].Laboratorio ,id: listaitems[i].IdLaboratorio}
                    if (! listaLaboratorios.some((element => element.id === laboratorio.id)))
                        listaLaboratorios.push(laboratorio);
                }
            }
        }
        var aux = listaLaboratorios.sort((a, b) => b.nombre < a.nombre ? 1 : -1);
        return listaLaboratorios;
    };


    function getPosiblesPaises() {
        var listaPaises = [];
        var listaitems = props.infoPagina.listaRes;
        if (listaitems != null){
            for (var i=0; i<listaitems.length; i++){
                if (listaitems[i].AuxInt>0){                    
                    var pais = {codigo: listaitems[i].AuxInt ,valor: listaitems[i].AuxStr}
                    if (! listaPaises.some((element => element.codigo === pais.codigo))){
                        listaPaises.push(pais);
                    }
                }
            }
        }
        var aux = listaPaises.sort((a, b) => b.valor < a.valor ? 1 : -1);
        if (listaPaises.length>0){
            listaPaises.splice(0,0,{codigo:-1, valor: "Seleccione un país"});
        }
        return listaPaises;
    };

    function getPosiblesEspecies() {
        var listaEspecies = [];
        var listaitems = props.infoPagina.listaRes;
        if (listaitems != null){
            for (var i=0; i<listaitems.length; i++){
                if ((listaitems[i].ListaAux1)&&(listaitems[i].ListaStr)){
                    for (var j=0; j<listaitems[i].ListaAux1.length; j++){
                        if (listaitems[i].ListaAux1[j]>0){            
                            if (j<listaitems[i].ListaStr.length){        
                                var especie = {codigo: listaitems[i].ListaAux1[j] ,valor: listaitems[i].ListaStr[j]}
                                if (! listaEspecies.some((element => element.codigo === especie.codigo))){
                                    listaEspecies.push(especie);
                                }
                            }
                        }
                    }
                }
            }
        }
        var aux = listaEspecies.sort((a, b) => b.valor < a.valor ? 1 : -1);
        if (listaEspecies.length>0){
            listaEspecies.splice(0,0,{codigo:-1, valor: "Seleccione una especie"});
        }
        return listaEspecies;
    };

    function getPosiblesPAHomeo() {
        var listaEspecies = [];
        var listaitems = props.infoPagina.listaRes;
        if (listaitems != null){
            for (var i=0; i<listaitems.length; i++){
                if ((listaitems[i].ListaAux1)&&(listaitems[i].ListaStr)){
                    for (var j=0; j<listaitems[i].ListaAux1.length; j++){
                        if (listaitems[i].ListaAux1[j]>0){            
                            if (j<listaitems[i].ListaStr.length){        
                                var especie = {codigo: listaitems[i].ListaAux1[j] ,valor: listaitems[i].ListaStr[j]}
                                if (! listaEspecies.some((element => element.codigo === especie.codigo))){
                                    listaEspecies.push(especie);
                                }
                            }
                        }
                    }
                }
            }
        }
        var aux = listaEspecies.sort((a, b) => b.valor < a.valor ? 1 : -1);
        if (listaEspecies.length>0){
            listaEspecies.splice(0,0,{codigo:-1, valor: "Indique principio activo"});
        }
        return listaEspecies;
    };

    function getPosiblesTiposProd() {
        var listaFiltro = [];
        var listaitems = props.infoPagina.listaRes;
        if (listaitems != null){
            for (var i=0; i<listaitems.length; i++){
                if (listaitems[i].AuxInt>0){                    
                    var itemLista = {codigo: listaitems[i].AuxInt ,valor: listaitems[i].TipoRegistro}
                    if (! listaFiltro.some((element => element.codigo === itemLista.codigo))){
                        listaFiltro.push(itemLista);
                    }
                }
            }
        }
        var aux = listaFiltro.sort((a, b) => b.valor < a.valor ? 1 : -1);
        if (listaFiltro.length>0){
            listaFiltro.splice(0,0,{codigo:-1, valor: "Indique tipo de producto"});
        }
        return listaFiltro;
    };

    function anadeSiProcedeTiposPPF(){
        var exclusivoPPF = false;
        if (infoPagina.codigoPagina.substring(infoPagina.codigoPagina.length - 3)==="PPF"){
            exclusivoPPF = true;
        }
        var fTipo = filtros.find(f=>f.idGrupo === "TIPO")
        if (fTipo || exclusivoPPF){
            var fSubtipoIndex = (fTipo ? filtros.findIndex(f=>f.idGrupo === "TIPO") : filtros.findIndex(f=>f.idGrupo === "LABO")) + 1;
            filtroTipoProductoPPF.posibles = filtroTipoProductoPPF.getPosibles();
            if (filtroTipoProductoPPF.posibles.length>2){
                if (exclusivoPPF || fTipo.valores.find(v=>(v.value === "PPF"))){
                    filtroTipoProductoPPF.oculto = !exclusivoPPF;
                    filtros.splice(fSubtipoIndex,0,filtroTipoProductoPPF);
                }
            }
        }
    }

    const filtroCompos =
    {
        idGrupo : 'NUMCOMPOS',
        nombre : 'Composición',
        tipo: 'Check',
        excluyente : true,
        campolista: 'AuxStr',
        tipoCampolista: 'codString',
        abierto: true,
        valores : [
            {
                texto:'Sólo',
                value:'S',
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'NUMCOMPOS' && x.valor ==='S') ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'En asociación',
                value:'N',
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'NUMCOMPOS' && x.valor ==='N') ? true : false)
                    : false),
                nivel:1
            },
        ]
    }

    const filtroAnalisis =
    {
        idGrupo : 'RELACION',
        nombre : 'Efecto en el análisis',
        tipo: 'Check',
        excluyente : false,
        campolista: 'ListaStr',
        tipoCampolista: 'array',
        abierto: true,
        valores : [
            {
                texto:'Aumento',
                value:'1',
                otrosValores: ['5','7','8','E','F'],
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'RELACION' && x.valor ==='1') ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Disminución',
                value:'2',
                otrosValores: ['6','7','8','E','F'],
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'RELACION' && x.valor ==='2') ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Positivo',
                value:'3',
                otrosValores: ['B'],
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'RELACION' && x.valor ==='3') ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Negativo',
                value:'4',
                otrosValores: ['C'],
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'RELACION' && x.valor ==='4') ? true : false)
                    : false),
                nivel:1
            },
        ]
    }

    const filtroEfectoFG =
    {
        idGrupo : 'EFECTOFG',
        nombre : 'Efecto farmacológico',
        tipo: 'Check',
        excluyente : false,
        campolista: 'ListaAux1',
        tipoCampolista: 'array',
        abierto: true,
        valores : [
            {
                texto:'Respuesta terapéutica favorable',
                value: 1,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'EFECTOFG' && x.valor ===1) ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Respuesta terapéutica desfavorable',
                value:2,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'EFECTOFG' && x.valor ===2) ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Mayor riesgo de reacciones adversas',
                value:3,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'EFECTOFG' && x.valor ===3) ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Mayor riesgo de perdida de eficacia',
                value:4,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'EFECTOFG' && x.valor ===4) ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Sin efecto farmacológico significativo',
                value:5,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'EFECTOFG' && x.valor ===5) ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Sin datos concluyentes',
                value:6,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'EFECTOFG' && x.valor ===6) ? true : false)
                    : false),
                nivel:1
            },
        ]
    }
    const filtroRecoFG =
    {
        idGrupo : 'RECOFG',
        nombre : 'Recomendación terapéutica',
        tipo: 'Check',
        excluyente : false,
        campolista: 'ListaAux2',
        tipoCampolista: 'array',
        abierto: true,
        valores : [
            {
                texto:'Considerar la administración de otro fármaco',
                value: 1,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'RECOFG' && x.valor ===1) ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Debe realizarse un test genético previo',
                value:2,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'RECOFG' && x.valor ===2) ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Monitorizar al paciente',
                value:3,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'RECOFG' && x.valor ===3) ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'No existe información suficiente para una recomendación terapéutica',
                value:4,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'RECOFG' && x.valor ===4) ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Posible necesidad de ajuste posológico con aumento de la dosis',
                value:5,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'RECOFG' && x.valor ===5) ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Posible necesidad de ajuste posológico con disminución de la dosis',
                value:6,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'RECOFG' && x.valor ===6) ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Seleccionar farmaco alternativo',
                value:7,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'RECOFG' && x.valor ===7) ? true : false)
                    : false),
                nivel:1
            },
        ]
    }
    const filtroIntolerancias =
    {
        idGrupo : 'INTOL',
        nombre : 'Intolerancias',
        tipo: 'Check',
        excluyente : false,
        campolista: 'ListaIntolerancias',
        tipoCampolista: 'array',
        abierto: false,
        relacion: 'notIn',
        valores : [
            {
                texto:'Gluten',
                display:'Permitido en pacientes celíacos',
                value: 1,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'INTOL' && x.valor ===1) ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Lactosa',
                display:'Permitido en intolerancia a la lactosa',
                value: 2,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'INTOL' && x.valor ===2) ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Azúcares y polialcoholes',
                display:'Permitido en intolerancia a azúcares y polialcoholes',
                value: 3,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'INTOL' && x.valor ===3) ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Etanol',
                display:'Permitido en intolerancia al etanol',
                value: 4,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'INTOL' && x.valor ===4) ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Aceite de cacahuete o soja',
                display:'Permitido en intolerancia al aceite de cacahuete o soja',
                value: 5,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'INTOL' && x.valor ===5) ? true : false)
                    : false),
                nivel:1
            },
        ]
    }
    const filtroDeduccionesRDL =
    {
        idGrupo : 'DEDUCCION',
        nombre : 'Tipo de deducción',
        tipo: 'Check',
        excluyente : false,
        campolista: 'AuxInt',
        tipoCampolista: 'int',
        abierto: true,
        valores : [
            {
                texto:'4 %',
                display: 'Deducción del 4 %',
                value: 888,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'DEDUCCION' && x.valor ===888) ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'7,5 %',
                display: 'Deducción del 7,5 %',
                value: 936,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'DEDUCCION' && x.valor ===936) ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'15 %',
                display: 'Deducción del 15 %',
                value: 889,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'DEDUCCION' && x.valor ===889) ? true : false)
                    : false),
                nivel:1
            },
        ]
    }

    const filtroHuerfano = {
        idGrupo : 'HUER',
        nombre : 'Huérfano',
        tipo: 'Check',
        excluyente : true,
        campolista: 'AuxStr2',
        tipoCampolista: 'codString',
        abierto: true,
        valores : [
            {
                texto:'Sí',
                display: 'Huérfano',
                value:'SHUER',
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'HUER' && x.valor ==='SHUER') ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'No',
                display: 'No huérfano',
                value:'NHUER',
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'HUER' && x.valor ==='NHUER') ? true : false)
                    : false),
                nivel:1
            },
        ]
    }

    const filtroNoSustituible =
    {
        idGrupo : 'NOSUST',
        nombre : 'Causa',
        tipo: 'Check',
        excluyente : false,
        campolista: 'AuxInt',
        tipoCampolista: 'int',
        abierto: true,
        valores : [
            {
                texto:'Biológico',
                value: 2175,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'NOSUST' && x.valor ===2175) ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Inhalatorio',
                value: 2178,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'NOSUST' && x.valor ===2178) ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Estrecho margen terapéutico',
                value: 2176,
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'NOSUST' && x.valor ===2176) ? true : false)
                    : false),
                nivel:1
            },
        ]
    }


    const filtroComparaPrecios = {
        idGrupo : 'COMPPRE',
        nombre : 'Comparación precios',
        tipo: 'Check',
        excluyente : true,
        campolista: 'AuxDbl',
        tipoCampolista: 'float',
        abierto: true,
        relacion: 'comparacion',
        valores : [
            {
                texto:'Ajustado a precio menor',
                display: 'Ajustado a precio menor',
                value: infoPagina.pvpMenor,
                idValor:'APM',
                comparacion: '<=',
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'COMPPRE' && x.valor ==='APM') ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Supera precio menor',
                display: 'Precio mayor que precio menor',
                value: infoPagina.pvpMenor,
                idValor:'SPM',
                comparacion: '>',
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'COMPPRE' && x.valor ==='SPM') ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Ajustado a precio más bajo',
                display: 'Ajustado a precio más bajo',
                value:infoPagina.pvpMasBajo,
                idValor:'APMB',
                comparacion: '<=',
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'COMPPRE' && x.valor ==='APMB') ? true : false)
                    : false),
                nivel:1
            },
            {
                texto:'Supera precio más bajo',
                display: 'Supera precio más bajo',
                value:infoPagina.pvpMasBajo,
                idValor:'SPMB',
                comparacion: '>',
                activo: (infoPagina.filtrosActivos ? 
                    (infoPagina.filtrosActivos.find(x => x.nombre === 'COMPPRE' && x.valor ==='SPMB') ? true : false)
                    : false),
                nivel:1
            },
        ]
    }

    const filtros = filtrosStandard

    switch (infoPagina.codigoPagina){
        case 'BInicio':
        case 'BHistorialUsuario':
            filtros.push(filtroIntolerancias);
        break;
        case 'BLaboratorioMUH':
        case 'BLaboratorioMHO':
            //Sólo productos de un laboratorio 
            filtros.splice(0,2,filtroNombre);
            filtros.push(filtroIntolerancias);
        break;
        case 'BLaboratorioPPF':
            //Sólo productos de un laboratorio. Quitamos PS
            filtros.splice(0,2,filtroNombre);
            filtros.splice(4,1);
            filtros.push(filtroIntolerancias);
        break;
        case 'BLaboratorioMUV':
            //Sólo productos de un laboratorio. Quitamos financiado y PS 
            filtros.splice(0,2,filtroNombre);
            filtros.splice(3,2);
        break;
        case "BFormaFarmaMUH":
        case "BGTVMPPMUH": 
        case "BComposicionMUH": 
        case "BAvanzadaMUH":
        case "BMedEspanolesMUH":  
        case "BComparaPrecioMenorMUH":
        case "BComparaPrecioMasBajoMUH":
        case "BPrecioNotificadoMUH":
        case "BSeguimientoAdicionalMUH": 
        case "BDispositivoSeguridadMUH": 
        //case "BCambioPrecioPrevistoMUH":
        case "BDesfinanciadosMUH":
        case "BFormaColorMUH":
        case 'BLaboratoriosMUH':
        case "BFormasFarmaMUH":
                //Sólo medicamentos (H o V pero sólo 1)
            filtros.splice(1,1);
            filtros.splice(0,0,filtroNombre);
            filtros.push(filtroIntolerancias);
        break;
        case "BNoSustituiblesMUH":
            filtros.splice(1,1);
            filtros.splice(0,0,filtroNombre);
            filtros.splice(2,0,filtroNoSustituible);
            filtros[3].abierto=false;
            filtros.push(filtroIntolerancias);
        break;
        case "BDeduccionesRDLMUH":
            filtros.splice(1,1);
            filtros.splice(0,0,filtroNombre);
            filtros.splice(2,0,filtroDeduccionesRDL);
            filtros.splice(3,0,filtroHuerfano);
            filtros[1].abierto=false;
            filtros[4].abierto=false;
            filtros.push(filtroIntolerancias);
        break;

        case "BNotificacionESVACMUV":
            filtros[1].valores.splice(3,4);
            filtros[1].valores.splice(1,1);
            filtros.splice(4,2);
            filtros.splice(0,0,filtroNombre)
            break;
        case "BComposicionMUV": 
        case "BFormaFarmaMUV":
        case "BEspecieIndicacionMUV": 
        case "BAvanzadaMUV":     
        case "BAlertasCMUV":    
        case 'BLaboratoriosMUV':
        case "BFormasFarmaMUV":
            //Sólo medicamentos Sin financiados ni PS. Con receta
            filtros.splice(1,1);
            filtros.splice(0,0,filtroNombre)
            filtros.splice(4,2);
        break;
        case "BProblemasSumMUH":
        case "BAlertasCMUH":         
            //Sólo medicamentos. Sin financiado/PS/Receta
            filtros.splice(3,3);
            filtros.splice(1,1);
            filtros.splice(0,0,filtroNombre)
            filtros.push(filtroIntolerancias);
        break;
        case "BAgrupHomoAutoMUH":
        case "BAgrupHomoMUH":
            //Sólo medicamentos. No filtro financiado
            filtros.splice(1,1);
            filtros.splice(0,0,filtroNombre)
            filtros.splice(4,1);
            filtros.splice(2,0,filtroComparaPrecios);
            filtros.push(filtroIntolerancias);
        break;
        case "BPAMUH":
        case "BAsoMUH":
            //Sólo medicamentos (H o V pero sólo 1) pero añade filtro sólo/asociación
            filtros.splice(1,1,filtroCompos);
            filtros.splice(0,0,filtroNombre);
            filtros.push(filtroIntolerancias);
        break;
        case "BPAMUV":
        case "BAsoMUV":
            //Sólo medicamentos (H o V pero sólo 1) pero añade filtro sólo/asociación
            filtros.splice(1,1,filtroCompos);
            filtros.splice(0,0,filtroNombre);
            filtros.splice(5,2);
        break;
        //case "BAgrupHomoAutoPPF":
        //case "BAgrupHomoPPF":
        case "BFormaFarmaPPF":
        case "BIngPPF":
        case "BTipoPPF":
        case "BSubtipoPPF":
        case "BGTConPPF": 
        case "BSaborEdadPPF":   
        case "BComposicionPPF":  
        case "BAvanzadaPPF":  
        case "BAlertasCPPF":
        case 'BLaboratoriosPPF':
        case "BFormasFarmaPPF":
            //Sólo productos de PF
            filtros.splice(1,1);
            filtros.splice(0,0,filtroNombre);
            filtros.splice(5,1);
            filtros.push(filtroIntolerancias);
        break;
        case "BAgrupHomoAutoPPF":
        case "BAgrupHomoPPF":
        case "BGTSanPPF":   
            //Sólo productos de PF. Quita filtro financiado
            filtros.splice(1,1);
            filtros.splice(0,0,filtroNombre);
            filtros.splice(4,2);
            filtros.push(filtroIntolerancias);
        break;
        case "BAFMUH":
        case "BAMMUH":
        case "BActPPF":
        case "BAFMUV":
        case "BAFsMUH":
        case "BAMsMUH":
        case "BActsPPF":
        case "BAFsMUV":
        case "BAvanzadaPAH":
            //Sólo PA
            filtros.splice(0,2,filtroNombre);
            filtros.splice(2,3);
        break;
        case "BAnalisisMUH":
            //Medicamentos, PA y Enfermedades de H. Añade la relación
            filtros[1].valores.splice(2,5);
            filtros.splice(1,0,filtroAnalisis);
            filtros.splice(0,0,filtroNombre);
            filtros.push(filtroIntolerancias);
        break;
        case "BIndicacionMUH":
        case "BContraindicacionMUH":
        case "BPrecaucionMUH":
        case "BReaccionAdversaMUH":
        case "BGTATCMUH":
        case "BGTsATCMUH":
        case "BReaccionesAdversasMUH":  
        case "BPacienteIndicacionMUH":          
            //Medicamentos y PA de H
            filtros[1].valores.splice(2,5);
            filtros.splice(0,0,filtroNombre);
            filtros.push(filtroIntolerancias);
        break;
        case "BFarmacogeneticaMUH":
            //Medicamentos y PA de H. Añade filtros de farmacogenética
            filtros[1].valores.splice(2,5);
            filtros.splice(1,0,filtroEfectoFG);
            filtros.splice(2,0,filtroRecoFG);
            filtros.splice(0,0,filtroNombre);
            filtros[0].abierto=false;
            filtros[1].abierto=false;
            filtros[4].abierto=false;
            filtros[5].abierto=false;
            filtros.push(filtroIntolerancias);
        break;
        case "BDopantesMUH":  
            //Medicamentos y PA de H
            filtros[1].valores.splice(4,3);
            filtros[1].valores.splice(2,2);
            filtros.splice(0,0,filtroNombre);
            filtros.push(filtroIntolerancias);
        break;
        case "BGTATCMUV":
        case "BGTsATCMUV":
            //Medicamentos y PA de V
            filtros[1].valores.splice(4,3);
            filtros[1].valores.splice(0,2);
            filtros.splice(0,0,filtroNombre);
            filtros.splice(5,2);
        break;
        case "BIndicacionMUV":
            //Medicamentos y PA de V
            filtros[1].valores.splice(4,3);
            filtros[1].valores.splice(0,2);
            filtros.splice(0,0,filtroNombre);
            filtros.splice(5,2);
            filtros.splice(2,0,filtroEspecies);
        break;
        case "BFormMagistralMUH":
            filtros.splice(0,3,filtroNombre);
            filtros[2].valores.push(valorFiltroFinanciadoSegun);
            filtros[2].excluyente = false;
            filtros.splice(3,1,filtroAportacionFM);
            filtros.splice(4,0,filtroVisadoFM);
            filtros.splice(1,0,filtroTipoFM);
        break;
        case "BMedsHomeoMUH":
            filtros.splice(0,0,filtroNombre);
            filtros.splice(2,1,filtroPAHomeo);
            filtros.splice(4,3);
        break;
        case "BExtranjerosEXT":
            filtros.splice(0,6,filtroNombre);
            filtros.push(filtroPaises);
        break;
        case "BConGluten":
        case "BConLactosa":
        case "BSinGluten":
        case "BSinLactosa":
        case "BCambioPrecioMUH":
        case "BCambioPrecioPrevistoMUH":            
        case "BNoLentes":
            //Medicamentos de humana o parafarmacia
            filtros[1].valores.splice(5,2);
            filtros[1].valores.splice(1,3);
            filtros.splice(0,0,filtroNombre);
            filtros.push(filtroIntolerancias);
        break;
        case "BLibroRecetarioM":
        case "BLibroEstupefacientesM":
            //Medicamentos de humana y veterinarios
            filtros[1].valores.splice(3,4);
            filtros[1].valores.splice(1,1);
            filtros.splice(0,0,filtroNombre);
            filtros.push(filtroIntolerancias);
        break;
        case "BCambioNombre":
        case "BCambioLaboratorio":
            //Medicamentos de humana, Medicamentos de veterinaría o parafarmacia
            filtros[1].valores.splice(5,2);
            filtros[1].valores.splice(3,1);
            filtros[1].valores.splice(1,1);
            filtros.splice(0,0,filtroNombre);
            filtros.push(filtroIntolerancias);
        break;
        case "BFechaAlta":
        case "BNovedadesUltimoMes":
            //Medicamentos de humana, Medicamentos de veterinaría o parafarmacia
            filtros[1].valores.splice(5,2);
            filtros[1].valores.splice(3,1);
            filtros[1].valores.splice(1,1);
            filtros.splice(0,0,filtroNombre);
            filtros.splice(0,0,filtroCodigo);
            filtros.push(filtroIntolerancias);
        break;
        case "BEnfermedadesPATH":
        case "BEnfermedadesPATV":
            filtros.splice(0,6,filtroNombre);
        break;
        case "BDocumentos":
            filtros.splice(0,6,filtroTitulo);
        break;
        case "BAlertadosCismedMUH":
            filtros.splice(3,0,filtroCisMEDEstado);
            filtros.splice(3,0,filtroCisMEDFecha);            
            filtros.splice(1,1);
            filtros.splice(0,0,filtroNombre);
        break;
    }

    if (PerfilUsuario() == "EST"){
        var idx = filtros.findIndex(item => item.idGrupo === "RECETA");
        if (idx>=0)
            filtros.splice(idx,1);
        idx = filtros.findIndex(item => item.idGrupo === "FINAN");
        if (idx>=0)
            filtros.splice(idx,1);
        idx = filtros.findIndex(item => item.idGrupo === "PSUM");
        if (idx>=0)
            filtros.splice(idx,1);
    }


    if ((props.infoPagina.listaRes != null) && (filtros != null))
    {
        for (var i=0; i<filtros.length; i++){
            if ((filtros[i].tipo === 'List') || (filtros[i].tipo === 'ListBox'))
            {
                filtros[i].posibles = filtros[i].getPosibles();
            }
        }
    }

    anadeSiProcedeTiposPPF();

    
    return (
        <section class="section section__buscador" >  
            <CabeceraResultados infoPagina={props.infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras}></CabeceraResultados>
            {(infoPagina.codigoPagina==="BInteraccionesMUH" || infoPagina.codigoPagina==="BInteraccionesPPF") ?
                <BInteracciones infoPagina={props.infoPagina} cambiaPagina={props.cambiaPagina}></BInteracciones>
            :
                <FiltrosYResultados infoPagina={props.infoPagina} cambiaPagina={props.cambiaPagina} filtros={filtros} atras={props.atras} borraDeLista={props.borraDeLista}></FiltrosYResultados>
            }
        </section> 
    );
};

export default SeccionResultados;
