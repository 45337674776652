import React, { useState, useEffect, createRef, useRef } from "react";
import CabeceraResultados from "../../Resultados/CabeceraResultados/CabeceraResultados";
import BP2022Service from "../../../services/user.service";
import InfoPrincipalMUH from "../FichaMUH/InfoPrincipal/InfoPrincipalMUH.js";
import MenuIzquierda from "../FichaMUH/MenuIzquierda/MenuIzquierda";
import ContenedorApartados from "../FichaMUH/Apartados/ContenedorApartados";
import MargenDerecho from "../FichaMUH/MargenDerecho/MargenDerecho";
import MensajesEmergentes from "../FichaMUH/MensajesEmergentes";
import { AnadeApartado, compruebaAnclas, RemoveClassNameToClassItems, rootPath , getParam, anadeAncla } from "../../../services/utils";
import LoadingPopup from "../../Resultados/Loading.Popup";
import MargenDerchoMUV from "./MargenDerechoMUV";
import Slider from "react-slick";
import {createBrowserHistory} from 'history';


const FichaMUV = (props) => {
    const id = props.infoPagina.id;
    const [ficha, setFicha] = useState(null);
    const [anclas, setAnclas] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [muestraAmpliada, setMuestraAmpliada] = useState(false);
    //const [sliderRef2, setSliderRef2] = useState(null)
    const sliderRef2 = useRef();
    const [idxSlider, setIdxSlider] = useState(0);
    const [anclasEpis, setAnclasEpis] = useState([]);
    const history = createBrowserHistory();
    const sEpigrafes = getParam(history.location.search, 'epigrafes');
    const epigrafesActivos = sEpigrafes ? sEpigrafes.split(",").map(Number) : [];
    const [seHaHechoScroll, SetSeHaHechoScroll] = useState(false);


    const sliderSettings2 = {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        speed: 300,
        arrows: true,
        touchMove: true,
        accessibility: true,
        adaptativeHeight: true,
        dots: false,
        focusOnSelect: true,
        className: 'single-item slide__2',
    }

    const clickImagen = (e, idx) => {
        if (idx || idx===0){
            setIdxSlider(idx);
        }
        else{
            setIdxSlider(0);
        }
        setMuestraAmpliada(true);
    }

    const clickCierra = (e) => {
        setMuestraAmpliada(false);
    }

    const loadImageGrande = (e) => {
        var h = e.target.height;
        var w = e.target.width;
        var maxHeightG = window.innerHeight * 0.65;
        var maxWidthG = window.innerWidth * 0.65;
        if (h>=maxHeightG){
            e.target.style.height = maxHeightG.toString() + 'px';
            e.target.style.width = 'auto';
            e.target.style.maxWidth = maxWidthG.toString() + 'px';
            e.target.style.maxHeight = maxHeightG.toString() + 'px';
        }
        else if (w>=maxWidthG){
            e.target.style.height = 'auto';
            e.target.style.width = maxWidthG.toString() + 'px';
            e.target.style.maxWidth = maxWidthG.toString() + 'px';
            e.target.style.maxHeight = maxHeightG.toString() + 'px';
        }
    }

    const estiloImagenFlotante = {verticalAlign:'middle',width:'70%',alignContent:'center',maxHeight:'70%'}
    
    if (ficha && ((ficha.CodTipoRegistro==="PAV")||(ficha.CodTipoRegistro==="PAH"))){
        estiloImagenFlotante.backgroundColor = '#FFFFFF'
    }

    const imgDefecto = (ficha) ? 
        ((ficha.CodTipoRegistro === "MUV") || (ficha.CodTipoRegistro==="PAV") || (ficha.CodTipoRegistro==="ASOV")) ? 
        rootPath + "img/perro.svg" : 
        ((ficha.CodTipoRegistro==="PPF") || (ficha.CodTipoRegistro==="ING")) ? 
        rootPath + "img/jabonLiquido.svg" : 
        ((ficha.CodTipoRegistro==="PAT") || (ficha.CodTipoRegistro==="PATH")) ? 
        rootPath + "img/patHumana.svg" : 
        (ficha.CodTipoRegistro==="PATV") ? 
        rootPath + "img/patVet.svg" : 
        rootPath + "img/person.svg" 
    :
        undefined
    ;

    const srcImagen = (ficha) ?
        ((ficha.ImagenEnvase) ?
            (ficha.ImagenEnvase.URLFull != null ?
                ficha.ImagenEnvase.URLFull
            : 
                ficha.ImagenEnvase.URL
            )
        :
            ((ficha.ImagenFormula) ?
                ficha.ImagenFormula.URL
            :
                imgDefecto
            )
        )
    :
        undefined
    ;

    const srcImagenFF = (ficha) ?
        ((ficha.CodTipoRegistro === "PPF") ?
            ((ficha.ImagenMatAcond) ?
                (ficha.ImagenMatAcond.URLFull != null ?
                    ficha.ImagenMatAcond.URLFull
                : 
                    ficha.ImagenMatAcond.URL
                )
            :
                ''
            )
        :
            ((ficha.ImagenFormaF) ?
                (ficha.ImagenFormaF.URLFull != null ?
                    ficha.ImagenFormaF.URLFull
                : 
                    ficha.ImagenFormaF.URL
                )
            :
                ''
            )
        )
    :
        undefined
    ;

    const tieneImagenes = (srcImagen && srcImagen !== '') || (srcImagenFF && srcImagenFF !== '');    
    const arrayImgs = [];
    if (srcImagen && srcImagen != '')
        arrayImgs.push(srcImagen);
    if (srcImagenFF && srcImagenFF != '')
        arrayImgs.push(srcImagenFF);

    const imagenesHTML = tieneImagenes ? arrayImgs.map(srcImg => <div><img class="imagen__medicamento" src={srcImg} alt={srcImg} onLoad={(e) => loadImageGrande(e)}/></div>)
                                        : <div><p>&nbsp;</p><img class="imagen__medicamento" src={imgDefecto} /></div>


    useEffect(() => {
        if (sliderRef2)
            if(sliderRef2.current)
                sliderRef2.current.slickGoTo(idxSlider);

        if ((ficha==null) || (ficha.Id !== id))
        {
            descargaFicha();
        }        
        else if (refresh==0){
            descargaMensajesEmergentes(ficha.Id);
        }
        else if (refresh==1){
            descargaCompos(ficha.Id, ficha.IdComposicionPor, ficha.IdFormaFarma);
        }
        else if (refresh==2){
            var codGrupo = ficha.CodATCN5;
            var nivel = 5;
            if ((codGrupo === null)||(codGrupo===''))
            {
                codGrupo = ficha.CodATCN4;
                nivel = 4;
            }
            if ((codGrupo === null)||(codGrupo===''))
            {
                codGrupo = ficha.CodATCN3;
                nivel = 3;
            }
            descargaGrupos(ficha.Id, codGrupo, nivel, null);
        }
        else if (refresh==3){
            descargaTextos(ficha.Id);
        }
        else if (refresh==4){
            descargaAdvertencias(ficha.Id);
        }
        else if (refresh==5){
            descargaDatosConservacion(ficha.Id)
        }
        else if (refresh==6){
            descargaTiemposEspera(ficha.Id)
        }
        else if (refresh==7){
            descargaHistorico(ficha.Id)
        }
        else if (refresh==8){
            descargaAlertasCalidad(ficha.Id)
        }
        else if (refresh==9){
            descargaProblemasSuministro(ficha.Id)
        }
        else if (refresh==10){
            descargaInformacionAdicional(ficha.Id)
        }
        else if (refresh == 11)
        {
            descargaVideos(ficha.Id)
        }
        else if (refresh == 12)
        {
        }
        if (epigrafesActivos && epigrafesActivos.length>0 && !seHaHechoScroll && anclasEpis && anclasEpis.length>0){
            var currentAncla;
            for (var i=0; i<epigrafesActivos.length && !currentAncla; i++){
                currentAncla = anclasEpis.find(ancla => ancla.idAncla === "Epi" + epigrafesActivos[i].toString())
            }
            if (currentAncla){
                const linkRef = currentAncla.refAncla;
                linkRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                SetSeHaHechoScroll(true);
            }            
        }
    })

    const descargaFicha = () => {
        BP2022Service.getFichaMUV(id)
        .then((response) => {
            RemoveClassNameToClassItems('lightbox__popup',null,'active');
            var auxFicha = response.data;
            compruebaAnclas(auxFicha.Apartados,anclas);
            auxFicha.CodTipoRegistro="MUV";
            auxFicha.TipoRegistro="Medicamento veterinario"
            setFicha(auxFicha);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
    
    const descargaCompos = (miId, miIdCPor, miIdFF) => {
        BP2022Service.getComposM(miId,miIdCPor,miIdFF)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Composiciones){
                    if (response.data.Composiciones.length>0){
                        AnadeApartado({Titulo: "Composición",  Ancla:"COMP"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosComposicion = response.data;
                    }
                }
                setRefresh(prev => prev + 1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
    
    const descargaGrupos = (miId, miGTATC, nivelGTATC, miGTVMPP) => {
        BP2022Service.getGruposMUH (miId,miGTATC,nivelGTATC,miGTVMPP)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                AnadeApartado({Titulo: "Medicamentos con igual composición",  Ancla:"GRUP"},miFicha.Apartados,anclas);
                //checkAnclas(miFicha.Apartados);
                miFicha.DatosGrupos = response.data;
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
    
    const descargaTextos = (miId) => {
        BP2022Service.getTextosMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Epigrafes){
                    if (response.data.Epigrafes.length>0){
                        AnadeApartado({Titulo: "Información farmacológica",  Ancla:"TEXT"},miFicha.Apartados,anclas);
                        for (var i=0; i<response.data.Epigrafes.length; i++){
                            anadeAncla("Epi"+response.data.Epigrafes[i].IdEpigrafe,anclasEpis);                            
                        }
                        miFicha.DatosTextos = response.data;
                    }
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
    
    const descargaAdvertencias = (miId) => {
        BP2022Service.getAdvertenciasMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Advertencias){
                    if (response.data.Advertencias.length>0){
                        AnadeApartado({Titulo: "Advertencias",  Ancla:"ADV"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosAdvertencias = response.data;
                    }
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
   
 
    const descargaTiemposEspera = (miId) => {
        BP2022Service.getTiemposEsperaMUV (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Tiempos de espera",  Ancla:"TESP"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.TiemposEspera = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaDatosConservacion = (miId) => {
        BP2022Service.getConservacionMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                var l = 0;
                if (response.data.DatosConservacion)
                    l += response.data.DatosConservacion.length;
                if (response.data.MensajesConservacion)
                    l += response.data.MensajesConservacion.length;
                if (l>0){
                    AnadeApartado({Titulo: "Conservación y periodos de validez",  Ancla:"CPV"},miFicha.Apartados,anclas);
                    miFicha.InfoConservacion = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaHistorico = (miId) => {
        BP2022Service.getHistoricoMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Histórico",  Ancla:"HIST"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.DatosHistorico = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaAlertasCalidad = (miId) => {
        BP2022Service.getAlertasCalidadMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Alertas){
                    if (response.data.Alertas.length>0){
                        AnadeApartado({Titulo: "Alertas de calidad",  Ancla:"ALER"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosAlertas = response.data;
                    }
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaProblemasSuministro = (miId) => {
        BP2022Service.getProblemasSuministroMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Problemas){
                    if (response.data.Problemas.length>0){
                        AnadeApartado.push({Titulo: "Problemas de suministro",  Ancla:"PSUM"},miFicha.Apartados,anclas);
                        miFicha.DatosProblemasS = response.data;
                    }
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };


    const descargaInformacionAdicional = (miId) => {
        BP2022Service.getInformacionAdicionalMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Información adicional",  Ancla:"DOCS"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.DatosInfoAdicional = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };


    const descargaMensajesEmergentes = (miId) => {
        BP2022Service.getMensajesEmergentesMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    miFicha.MensajesEmergentes = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaVideos = (miId) => {
        BP2022Service.getVideosMUH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Documentos){
                    if (response.data.Documentos.length>0){
                        AnadeApartado({Titulo: "Vídeos",  Ancla:"VID"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosVideos = response.data;
                    }
                }
                props.descargaHistorial();
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };



    /*const checkAnclas = (aptdos) => {
        for (var i=0; i<aptdos.length; i++){
            const idAncla = aptdos[i].Ancla;
            if (!anclas.find(ancla => ancla.idAncla===idAncla))
            {
                const anclaNueva = createRef();
                anclas.push({idAncla: idAncla, refAncla : anclaNueva});
            }
        }
    }*/
    
    if (ficha !== null) 
    {
        return (
            <div>
                <CabeceraResultados infoPagina={props.infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras}></CabeceraResultados>
                {(muestraAmpliada) ? 
                    <div className="medicamento-info"><div className="contenido__imagen"><div className="imagen">
                    <div class="imagen-light show" style={{cursor: 'default'}} >
                        <div class="btn__cerrar" onClick={(e) => clickCierra(e)}  style={{cursor: 'pointer'}}>
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="22.512" cy="22.512" r="22.012" stroke="white" />
                                <path d="M22.5793 12.0959V32.2559" stroke="white" stroke-width="2" stroke-linecap="square" />
                                <path d="M32.2562 22.5791H12.0962" stroke="white" stroke-width="2" stroke-linecap="square" />
                            </svg>
                        </div>
                        <div style={estiloImagenFlotante} id="ContenedorImagenFlotante">
                            <Slider ref={sliderRef2} {...sliderSettings2}>
                                {imagenesHTML}
                            </Slider>
                        </div>
                    </div>
                    </div></div></div>
                :
                    <></>
                }
                <InfoPrincipalMUH ficha={ficha} cambiaPagina={props.cambiaPagina}  clickImagen = {clickImagen}></InfoPrincipalMUH>
                {
                    (ficha.MensajesEmergentes && ficha.MensajesEmergentes.length>0) ?
                        <MensajesEmergentes mensajes={ficha.MensajesEmergentes} id={ficha.Id}></MensajesEmergentes>
                    :
                        <div></div>
                }
                <section class="medicamento-infoGeneral">
                    <div class="container flex flex--justify-center">
                        <div class="contenido f-f-Flama-Book">
                            <MenuIzquierda apartados={ficha.Apartados} activo={ficha.Apartados[0].Ancla} anclas={anclas}></MenuIzquierda>

                            <ContenedorApartados cambiaPagina={props.cambiaPagina} ficha={ficha} anclas={anclas}
                                epigrafesActivos = {epigrafesActivos}
                                anclasEpis = {anclasEpis}
                            ></ContenedorApartados> 
                            {(ficha.CodTipoRegistro === "MUV") ?
                                <MargenDerchoMUV cambiaPagina={props.cambiaPagina} ficha={ficha} clickImagen = {clickImagen}></MargenDerchoMUV>
                            :
                                <MargenDerecho cambiaPagina={props.cambiaPagina} ficha={ficha} clickImagen = {clickImagen}></MargenDerecho>
                            }

                        </div>
                    </div>
                </section>
            </div>
        )
    }
    else 
        return (
            <div>
                <LoadingPopup></LoadingPopup>
            </div>
        )
}
export default FichaMUV;
