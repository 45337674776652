import ApartadoSeccionMenuBlanco from "./ApartadoSeccionMenuBlanco.js";

const ColumnaSeccionMenuBlanco = (props) => {
    
    const apartados =  props.item.map(item => <ApartadoSeccionMenuBlanco cambiaPagina={props.cambiaPagina} item={item} perfil = {props.perfil}></ApartadoSeccionMenuBlanco>)

    return (
        <div class="grid__column" >
            {apartados}
        </div>
    )
}
export default ColumnaSeccionMenuBlanco;
