import React, { useEffect, useState } from "react";
import BusqMedsService from "../../../services/user.service";
import SugerenciasService from "../../../services/sugerencias.service";
import Sugerencias from "../../Utilidades/Sugerencias/Sugerencias";
import { rootPath } from "../../../services/utils";

const FormCabeceraResultados = (props) => {
    const [texto, setTexto] = useState(props.infoPagina.texto);
    const [listaSugerencias, setListaSugerencias] = useState(null);
    if (props.infoPagina.fuerzaTexto)
    {
        if (props.infoPagina.texto != null)
        {
            if (props.infoPagina.texto != texto)
            {
                setTexto(props.infoPagina.texto);
            }
        }
    }

    const onChangeTexto = (e) => {
      //props.infoPagina.texto = e.target.value;
      props.infoPagina.fuerzaTexto = false;
      setTexto(e.target.value.replace('%',' '));
      SugerenciasService.getPalabras(encodeURIComponent(texto),"TOT").then((response) => {
        setListaSugerencias(response.data);
      })
    };

    const verResultados = (lista) =>{
        props.cambiaPagina({
            newpagina: "BInicio",
            newlista: lista,
            texto: texto,
        });
    };

    const compruebaSiEnter = (e) => {
        if (e.key === 'Enter')
            busqueda();
    }
  
    const busqueda = () => {
        var auxtexto = texto.replace('%',' ') //encodeURI(texto)
        if (auxtexto)
            if (auxtexto.trim().length > 0)
                props.cambiaPagina({
                    newpagina: "BInicio",
                    newlista: null,
                    texto: auxtexto,
                    opb: BusqMedsService.getOpcionBusqueda(),
                });
        
    };

    return (
        <div class="contenedor__buscador--buscador">
            <input type="text" 
                    placeholder="Buscar..."  
                    onChange={onChangeTexto}
                    list="sugerenciasFBC"
                    value={texto}
                    onKeyUp={(e) => compruebaSiEnter(e)} 
            />
            <img class="lupa" src={rootPath + "img/lupa_violeta.svg"} alt="" onClick={() => busqueda()} style={{cursor:'pointer'}}/>
            <Sugerencias missugerencias = {listaSugerencias} identificador="sugerenciasFBC"></Sugerencias>
        </div>
    );
};

export default FormCabeceraResultados;
