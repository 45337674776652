import CabeceraAyuda from "./CabeceraAyuda";
import BP2022Service from "../../services/user.service";
import DocumentosDeTipo from "../Fichas/FichaMUH/Apartados/InfoAdicional/DocumentosDeTipo";
import { useEffect, useState } from "react";
import LoadingPopup from "../Resultados/Loading.Popup";
import Acordeon from "../Fichas/FichaMUH/Apartados/Composicion/Acordeon";
import { rootPath } from "../../services/utils";


const Ayuda = (props) => {
    const [documentosAyuda, setDocumentosAyuda] = useState(null);
    const [documentosFiltrados, setDocumentosFiltrados] = useState(null);
    const [filtroAyuda, setFiltroAyuda] = useState("");

    useEffect(() => {
        if (!documentosAyuda)
        {
            DescargaDatos();
        }        
        else{
            filtraDocumentos();
        }
    },[documentosAyuda, filtroAyuda]);

    const DescargaDatos = () => {      
        BP2022Service.getDocumentosAyuda()
        .then((response) => {
            setDocumentosAyuda(response.data);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const onChangeTextoFiltro = (e) => {      
        setFiltroAyuda(e.target.value);
    };
    
    const filtraDocumentos = () => {
        if (documentosAyuda){
            var documentosAux = JSON.parse(JSON.stringify(documentosAyuda));
            if (filtroAyuda && filtroAyuda !== ""){
                for (var i=0; i<documentosAux.length; i++){
                    documentosAux[i].Documentos = documentosAux[i].Documentos.filter(doc => doc.Titulo.toLowerCase().includes(filtroAyuda.toLowerCase()));
                }
                documentosAux = documentosAux.filter(gd => gd.Documentos && gd.Documentos.length>0);
            }
            setDocumentosFiltrados(documentosAux);
        }
    }

    const htmlDocsAyuda = (documentosFiltrados ? documentosFiltrados.map(grupodocs => 
        <Acordeon 
            activo={true}
            titulo = {grupodocs.Tipo}
            contenido = 
                {(
                    <ul class="list fs fs14 greenColor4 FlamaBook" >
                        {grupodocs.Documentos.map(doc => 
                            <li>
                                <a href={doc.URL} title={doc.Titulo} target="_blank" >{doc.Titulo} <img src={rootPath + "img/blank.svg"} /></a>
                            </li>
                        )}
                    </ul>
                )}
                
            tipo = {3}
        />
    ) : <></>);


    return ( 
        <section class="section section__buscador section__buscador__busqueda" >
            <CabeceraAyuda cambiaPagina = {props.cambiaPagina}></CabeceraAyuda>
            <div class="container container__column" >
                <div class="content" >
                    { (!documentosAyuda) ?
                        <LoadingPopup titulo="Un momento" texto="Preparando documentos" esPorcentaje={true}></LoadingPopup>
                    :
                        <form class="content__results content__results__filters" id="main-filter"  onSubmit={(e) => e.preventDefault()} tabIndex="0">
                            <div class="module module__buttons module__buttons__buscar" style={{width:'100%'}}>
                                <div class="contenedor__buscador--buscador" style={{margin:'15px'}}>
                                    <input type="text" 
                                            placeholder="Filtrar documentos..."  
                                            value={filtroAyuda}
                                            onChange={(e) => onChangeTextoFiltro(e)}
                                    />
                                    <img class="lupa" src={rootPath + "img/lupa_violeta.svg"} alt=""  style={{cursor:'pointer'}}/>
                                </div>
                            </div>
                            <div class="column">
                                {htmlDocsAyuda}
                            </div>
                        </form>
                    }
                </div>
            </div>
        </section>
    );
};

export default Ayuda;
