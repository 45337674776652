import { numberWithCommas } from "../../../../../services/utils";
import Acordeon from "../../../FichaMUH/Apartados/Composicion/Acordeon";
import BloqueNombreValor from "../../../FichaMUH/Apartados/InfoGeneral/BloqueNombreValor";
import LinksFichaPA from "../../LinksFichaPA";
import DDD from "./DDD";

const InfoGeneralPA = (props) => {
    const ficha = props.ficha;
    const formula = ficha.FormulaEmpirica;
    var regex = new RegExp('([0-9]+)|([a-zA-Z]+)','g');

    const formulaPartida = (formula) ? formula.match(regex) : [];

    var formulaBonita = <span></span>;
    for (var i = 0; i<formulaPartida.length; i++){
        if (!isNaN(formulaPartida[i])){
            formulaBonita = <span>{formulaBonita}<sub>{formulaPartida[i]}</sub></span>
        }
        else{
            formulaBonita = <span>{formulaBonita}{formulaPartida[i]}</span>
        }
    } 
    
    const bloque1 = 
    ((ficha.CodTipoRegistro==="ING")?
        [
            {
                nombre : 'Código CAS',
                valor: ficha.CodCAS,
                link: null,
            },
            {
                nombre : 'Estado',
                valor: ficha.EstadoAuto.Descripcion,
                link: null,
            },
        ]
    :
        [
/*            {
                nombre : 'Código principio activo',
                valor: ficha.Codigo,
                link: null,
            },*/
            {
                nombre : 'Código CAS',
                valor: ficha.CodCAS,
                link: null,
            },
            {
                nombre : 'Fórmula empírica',
                valor: formulaBonita,
                link: null,
            },
            {
                nombre : 'Peso molecular',
                valor: (ficha.PesoMolecular>0) ? numberWithCommas(ficha.PesoMolecular) : '',
                link: null,
            },
            {
                nombre : 'Estado',
                valor: ficha.EstadoAuto.Descripcion,
                link: null,
            },
        ]
    );

    var estiloGrupos = {};

    if (ficha.CodTipoRegistro === "ASOH" || ficha.CodTipoRegistro === "ASOV" || ficha.CodTipoRegistro === "ASO" ){
        bloque1.splice(1,3);
        estiloGrupos = {
            borderTop : 'none',
        }
    }

    var bloqueATC = [];
    
    var link = (ficha.CodTipoRegistro==="ASOV" || ficha.CodTipoRegistro==="PAV") ? "BGTATCMUV" : "BGTATCMUH";
    for (var i=1; i<=5; i++)
    {
        var nombreCampo = "GATCNivel" + i.toString();
        if (ficha.DatosGrupos){
            if (ficha.DatosGrupos[nombreCampo]){
                var titulo = "Nivel " + i.toString();
                var id = ficha.DatosGrupos[nombreCampo].Codigo;
                var valor = ficha.DatosGrupos[nombreCampo].Codigo + " " + ficha.DatosGrupos[nombreCampo].Descripcion;
                bloqueATC.push({
                    nombre: titulo,
                    valor : valor,
                    link : link,
                    id : id
                })
            }
        }
    }
    var tituloGrupos = "Grupos terapéuticos ATC";
    const tituloEP = "Estupefaciente / Psicotropo";
    const tituloAF = "Actividades farmacológicas";
    const tituloCM = "Características metabólicas";
    const tituloSin = "Sinonimias";
    const tituloAING = "Actividades";
    const tituloDDD = "Dosis diarias definidas (DDD)";
    const contEP = (    
        <p class="fs fs16 FlamaBook blackColor">
            {(ficha.EstupefacientePsicotropo) ?
                ficha.EstupefacientePsicotropo.DescripcionPsicotropo
            :
                ''
            }
        </p>
    );


    var busquedaActividad = "BAFMUH";
    switch (ficha.CodTipoRegistro){
        case "ASOH":
        case "ASO":
        case "PAH":
            busquedaActividad = "BAFMUH";
            break;
        case "ASOV":
        case "PAV":
            busquedaActividad = "BAFMUV";
            break;
        case "ING":
            busquedaActividad = "BActPPF";
            break;
    }

    const HTMLAF = (ficha.ActividadesFarmacologicas ? ficha.ActividadesFarmacologicas.map(af => <li onClick={() => CambiaPaginaPorId(busquedaActividad,af.Id)} 
                                                                                                    style={{cursor:'pointer', color:'#5C9029'}}
                                                                                                    onMouseOver={(e) => e.target.style.fontWeight='bold'}
                                                                                                    onMouseOut={(e) => e.target.style.fontWeight='normal'}
                                                                                                >{af.Descripcion}</li>) : <div></div>);
    const HTMLCM = (ficha.CaracteristicasMetabolicas ? ficha.CaracteristicasMetabolicas.map(af => <li onClick={() => CambiaPaginaPorId(busquedaActividad,af.Id)} 
                                                                                                    style={{cursor:'pointer', color:'#5C9029'}}
                                                                                                    onMouseOver={(e) => e.target.style.fontWeight='bold'}
                                                                                                    onMouseOut={(e) => e.target.style.fontWeight='normal'}
                                                                                                >{af.Descripcion}</li>) : <div></div>);
    const HTMLSin = (ficha.Sinonimias ? ficha.Sinonimias.map(sino => <li >{sino}</li>) : <div></div>);
    const HTMLAING = (ficha.Actividades ? ficha.Actividades.map(af => <li onClick={() => CambiaPaginaPorId(busquedaActividad,af.Id)} 
                                                                                                    style={{cursor:'pointer', color:'#5C9029'}}
                                                                                                    onMouseOver={(e) => e.target.style.fontWeight='bold'}
                                                                                                    onMouseOut={(e) => e.target.style.fontWeight='normal'}
                                                                                                >{af.Descripcion}</li>) : <div></div>);
    const contAF = (    
        <div class="cover cover__text">
            <ul class="list list__normal fw fw400 FlamaBook" >
                {HTMLAF}
            </ul>
        </div>
    );
    const contCM = (    
        <div class="cover cover__text">
            <ul class="list list__normal fw fw400 FlamaBook" >
                {HTMLCM}
            </ul>
        </div>
    );
    const contSin = (    
        <div class="cover cover__text">
            <ul class="list list__normal fw fw400 FlamaBook" >
                {HTMLSin}
            </ul>
        </div>
    );
    const contAING = (    
        <div class="cover cover__text">
            <ul class="list list__normal fw fw400 FlamaBook" >
                {HTMLAING}
            </ul>
        </div>
    );
    const contDDD = (    
        <div class="infoContent">
            <DDD datosDDD = {ficha.Dosificacion}></DDD>
        </div>
    );
    const DenQuiHTML = ((ficha.DenominacionQuimica)?
        <div class="cover cover__text" style={{marginTop: '10px', marginBottom: '20px'}}>
            <h4 class="fs fs16 fw fw700 greyColor2 title" style={{marginBottom: '10px'}}>&nbsp;&nbsp;&nbsp;&nbsp;Denominación Química</h4>
            <p class="fs fs16 FlamaBook blackColor" style={{whiteSpace: 'pre-line'}} >{ficha.DenominacionQuimica}</p>
        </div>
    :
        <div></div>
    );

    const CambiaPaginaPorId = (pagina, id) => {
        props.cambiaPagina({
            newpagina: pagina,
            newid: id,
        });
    }



    return (
        <div class="infoContent">
            {(ficha.CodTipoRegistro === "ASOH" || ficha.CodTipoRegistro === "ASOV" || ficha.CodTipoRegistro === "ASO" ) ?
            <div></div>
            :
            <BloqueNombreValor bloque={bloque1} sonValoresNumericos={false} cambiaPagina={props.cambiaPagina}></BloqueNombreValor>
            }

            {(ficha.DatosGrupos) ?
                <div class="module module__infoColumns" style = {estiloGrupos}> 
                    <div class="text" >
                        <h3 class="fs fs16 fw fw600 greyColor2 title" >{tituloGrupos}</h3>
                    </div>
                    <BloqueNombreValor bloque={bloqueATC} sonValoresNumericos={false} cambiaPagina={props.cambiaPagina} claseBloque={'bloque2'}></BloqueNombreValor>
                </div>
            :
                <div style={{height:'25px'}}></div>
            }
            {(ficha.Psicotropo === "Sí") ?
                    <Acordeon titulo={tituloEP} contenido={contEP}></Acordeon>
                :
                    <div></div>
            }
            {DenQuiHTML}
            {(ficha.ActividadesFarmacologicas && ficha.ActividadesFarmacologicas.length>0) ?
                    <Acordeon titulo={tituloAF} contenido={contAF}></Acordeon>
                :
                    <div></div>
            }
            {(ficha.CaracteristicasMetabolicas && ficha.CaracteristicasMetabolicas.length>0) ?
                    <Acordeon titulo={tituloCM} contenido={contCM}></Acordeon>
                :
                    <div></div>
            }
            {(ficha.Actividades && ficha.Actividades.length>0) ?
                    <div>
                        <Acordeon titulo={tituloAING} contenido={contAING}></Acordeon>
                    </div>
                :
                    <div></div>
            }
            {(ficha.Sinonimias && ficha.Sinonimias.length>0) ?
                    <div>
                        <Acordeon titulo={tituloSin} contenido={contSin}></Acordeon>
                    </div>
                :
                    <div></div>
            }
            {(ficha.Dosificacion && ficha.Dosificacion.length>0) ?
                    <div>
                        <Acordeon titulo={tituloDDD} contenido={contDDD}></Acordeon>
                    </div>
                :
                    <div></div>
            }
        </div>
    )
}
export default InfoGeneralPA;
