const PreviewForma = (props) => {
    const idForma = props.idForma;
    const color1 = (props.color1 ? props.color1 : "transparent");
    const color2 = (props.color2 ? props.color2 : "transparent");

    const HTMLCirculo = (
            <div class="coverSvg" style={{width: '122px',margin: 'auto'}}>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.2 122.5">
                    <g>
                        <clipPath id="clip_1">
                            <path class="st2 pathLine" d="M117.8,40.5c-1.4-3.7-3.1-7.2-5.1-10.5c-0.5-0.8-1-1.6-1.6-2.4c-1.1-1.6-2.2-3.1-3.5-4.6                 c-0.6-0.7-1.2-1.5-1.9-2.2c-2.6-2.9-5.5-5.5-8.5-7.8c-1.5-1.2-3.1-2.2-4.8-3.2c-1.7-1-3.4-1.9-5.1-2.8C79.4,3.1,70.6,1,61.2,1                 C28,1,1,28,1,61.2c0,3.1,0.2,6.2,0.7,9.2c0.6,4,1.6,7.8,3,11.5c1.4,3.7,3.1,7.2,5.1,10.5c10.6,17.4,29.7,29,51.5,29                 s40.9-11.6,51.5-29c2-3.3,3.7-6.8,5.1-10.5s2.4-7.5,3-11.5c0.2-1.4,0.4-2.7,0.5-4.1V56.2C120.8,50.7,119.6,45.5,117.8,40.5z"></path>
                        </clipPath>
                        <image data-slide="1" class="img img__ext img__ext__1" width="990" height="696" x="2" y="-287" clip-path="url(#clip_1)" xlinkHref="./img/bgTrans.jpg" style={{width: '100%'}}></image>
                        <path class="st1 pathLeft" d="M1,61.2c0,3.1,0.2,6.2,0.7,9.2c0.6,4,1.6,7.8,3,11.5c1.4,3.7,3.1,7.2,5.1,10.5c10.6,17.4,29.7,29,51.5,29V1             C28,1,1,28,1,61.2z" 
                            style={{fill:color1, stroke:color1}}></path>
                        <path class="st0 pathRight" d="M112.7,92.5c2-3.3,3.7-6.8,5.1-10.5s2.4-7.5,3-11.5c0.2-1.4,0.4-2.7,0.5-4.1V56.2c-0.5-5.4-1.6-10.7-3.4-15.6             c-1.4-3.7-3.1-7.2-5.1-10.5c-0.5-0.8-1-1.6-1.6-2.4c-1.1-1.6-2.2-3.1-3.5-4.6c-0.6-0.7-1.2-1.5-1.9-2.2c-2.6-2.9-5.5-5.5-8.5-7.8             c-1.5-1.2-3.1-2.2-4.8-3.2c-1.7-1-3.4-1.9-5.1-2.8C79.4,3.1,70.6,1,61.2,1v120.5C83.1,121.5,102.2,109.8,112.7,92.5z" 
                            style={{fill:color2, stroke:color2}}></path>
                        <path class="st2 pathLine" d="M117.8,40.5c-1.4-3.7-3.1-7.2-5.1-10.5c-0.5-0.8-1-1.6-1.6-2.4c-1.1-1.6-2.2-3.1-3.5-4.6             c-0.6-0.7-1.2-1.5-1.9-2.2c-2.6-2.9-5.5-5.5-8.5-7.8c-1.5-1.2-3.1-2.2-4.8-3.2c-1.7-1-3.4-1.9-5.1-2.8C79.4,3.1,70.6,1,61.2,1             C28,1,1,28,1,61.2c0,3.1,0.2,6.2,0.7,9.2c0.6,4,1.6,7.8,3,11.5c1.4,3.7,3.1,7.2,5.1,10.5c10.6,17.4,29.7,29,51.5,29             s40.9-11.6,51.5-29c2-3.3,3.7-6.8,5.1-10.5s2.4-7.5,3-11.5c0.2-1.4,0.4-2.7,0.5-4.1V56.2C120.8,50.7,119.6,45.5,117.8,40.5z"></path>
                    </g>
                </svg>
            </div>
    );

    const HTMLCuadrado = (        
        <div class="coverSvg" style={{width: '122px',margin: 'auto'}}><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 118.6 118.6">
            <g>
                <clipPath id="clip_2">
                    <path class="st2 pathLine" d="M98,1H59.3H20.6C9.8,1,1,9.8,1,20.6V98c0,10.8,8.8,19.6,19.6,19.6h38.7v0H98c10.8,0,19.6-8.8,19.6-19.6V20.6                 C117.6,9.8,108.8,1,98,1z"></path>
                </clipPath>
                <image data-slide="1" class="img img__ext img__ext__1" width="990" height="696" x="2" y="-287" clip-path="url(#clip_2)" xlinkHref="./img/bgTrans.jpg" style={{width: '100%'}}></image>
                <path class="st0 pathLeft" d="M1,20.6V98c0,10.8,8.8,19.6,19.6,19.6h38.7V1H20.6C9.8,1,1,9.8,1,20.6z" 
                    style={{fill:color1, stroke:color1}}></path>
                <path class="st1 pathRight" d="M98,1H59.3v116.6H98c10.8,0,19.6-8.8,19.6-19.6V20.6C117.6,9.8,108.8,1,98,1z" 
                    style={{fill:color2, stroke:color2}}></path>
                <path class="st2 pathLine" d="M98,1H59.3H20.6C9.8,1,1,9.8,1,20.6V98c0,10.8,8.8,19.6,19.6,19.6h38.7v0H98c10.8,0,19.6-8.8,19.6-19.6V20.6             C117.6,9.8,108.8,1,98,1z"></path>
            </g>
        </svg></div>
    );

    const HTMLRectangulo = (
        <div class="coverSvg" style={{width: '122px',margin: 'auto'}}><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.5 56.4">
            <g>
                <clipPath id="clip_3">
                    <path class="st2 pathLine" d="M97.9,1H62.6h-38C11.5,1,1,11.5,1,24.5v7.3c0,13,10.5,23.5,23.5,23.5h38v0h35.4c13,0,23.5-10.5,23.5-23.5v-7.3                 C121.5,11.5,110.9,1,97.9,1z"></path>
                </clipPath>
                <image data-slide="1" class="img img__ext img__ext__1" width="990" height="696" x="2" y="-287" clip-path="url(#clip_3)" xlinkHref="./img/bgTrans.jpg" style={{width: '100%'}}></image>
                <path class="st0 pathLeft" d="M1,24.5v7.3c0,13,10.5,23.5,23.5,23.5h38V1h-38C11.5,1,1,11.5,1,24.5z" 
                    style={{fill:color1, stroke:color1}}></path>
                <path class="st1 pathRight" d="M97.9,55.4c13,0,23.5-10.5,23.5-23.5v-7.3c0-13-10.5-23.5-23.5-23.5H62.6v54.4H97.9z" 
                    style={{fill:color2, stroke:color2}}></path>
                <path class="st2 pathLine" d="M97.9,1H62.6h-38C11.5,1,1,11.5,1,24.5v7.3c0,13,10.5,23.5,23.5,23.5h38v0h35.4c13,0,23.5-10.5,23.5-23.5v-7.3             C121.5,11.5,110.9,1,97.9,1z"></path>
            </g>
        </svg></div>
    );

    const HTMLTriangulo = (
        <div class="coverSvg" style={{width: '122px',margin: 'auto'}}><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 133.9 118.9">
            <g>
                <clipPath id="clip_4">
                    <path class="st2 pathLine" d="M132.5,98.6c-0.2-1-0.6-2-1-3c-0.2-0.5-0.5-1-0.7-1.4L80.5,8.7c-0.7-1.1-1.5-2.2-2.4-3.1                 c-0.9-0.9-2-1.8-3.1-2.4C74,2.6,73,2.2,72,1.8c-1-0.3-2-0.6-3-0.7C68.5,1.1,68,1,67.5,1c-0.3,0-0.6,0-0.8,0v0                 c-1.2,0-2.5,0.2-3.6,0.5c-3.9,1-7.4,3.5-9.6,7.2L3.2,94.2c-0.7,1.2-1.3,2.5-1.6,3.8c-0.4,1.3-0.6,2.7-0.6,4.1                 c0,8.7,7,15.7,15.7,15.7h50v0h50.6c2.8,0,5.5-0.7,8-2.2c4.2-2.5,6.8-6.6,7.5-11.1c0.2-1,0.2-2,0.2-3S132.8,99.6,132.5,98.6z"></path>
                </clipPath>
                <image data-slide="1" class="img img__ext img__ext__1" width="990" height="696" x="2" y="-287" clip-path="url(#clip_4)" xlinkHref="./img/bgTrans.jpg" style={{width: '100%'}}></image>
                <path class="st0 pathLeft" d="M66.7,1c-1.2,0-2.5,0.2-3.6,0.5c-3.9,1-7.4,3.5-9.6,7.2L3.2,94.2c-0.7,1.2-1.3,2.5-1.6,3.8             c-0.4,1.3-0.6,2.7-0.6,4.1c0,8.7,7,15.7,15.7,15.7h50V1z" 
                    style={{fill:color1, stroke:color1}}></path>
                <path class="st1 pathRight" d="M131.5,95.6c-0.2-0.5-0.5-1-0.7-1.4L80.5,8.7c-0.7-1.1-1.5-2.2-2.4-3.1c-0.9-0.9-2-1.8-3.1-2.4             C74,2.6,73,2.2,72,1.8c-1-0.3-2-0.6-3-0.7C68.5,1.1,68,1,67.5,1c-0.3,0-0.6,0-0.8,0v116.8h50.6c2.8,0,5.5-0.7,8-2.2             c4.2-2.5,6.8-6.6,7.5-11.1c0.2-1,0.2-2,0.2-3s-0.2-2-0.4-3S132,96.6,131.5,95.6z" 
                    style={{fill:color2, stroke:color2}}></path>
                <path class="st2 pathLine" d="M132.5,98.6c-0.2-1-0.6-2-1-3c-0.2-0.5-0.5-1-0.7-1.4L80.5,8.7c-0.7-1.1-1.5-2.2-2.4-3.1             c-0.9-0.9-2-1.8-3.1-2.4C74,2.6,73,2.2,72,1.8c-1-0.3-2-0.6-3-0.7C68.5,1.1,68,1,67.5,1c-0.3,0-0.6,0-0.8,0v0             c-1.2,0-2.5,0.2-3.6,0.5c-3.9,1-7.4,3.5-9.6,7.2L3.2,94.2c-0.7,1.2-1.3,2.5-1.6,3.8c-0.4,1.3-0.6,2.7-0.6,4.1             c0,8.7,7,15.7,15.7,15.7h50v0h50.6c2.8,0,5.5-0.7,8-2.2c4.2-2.5,6.8-6.6,7.5-11.1c0.2-1,0.2-2,0.2-3S132.8,99.6,132.5,98.6z"></path>
            </g>
        </svg></div>
    );

    const HTMLPentagono = (
        <div class="coverSvg" style={{width: '122px',margin: 'auto'}}><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 135.4 128.7">
            <g>
                <clipPath id="clip_5">
                    <polygon class="st2 pathLine" points="67.7,1.2 1.2,49.5 26.6,127.7 67.7,127.7 67.7,127.7 108.8,127.7 134.2,49.6  "></polygon>
                </clipPath>
                <image data-slide="1" class="img img__ext img__ext__1" width="990" height="696" x="2" y="-287" clip-path="url(#clip_5)" xlinkHref="./img/bgTrans.jpg" style={{width: '100%'}}></image>
                <polygon class="st1 pathLeft" points="67.7,1.2 1.2,49.5 26.6,127.7 67.7,127.7    " 
                    style={{fill:color1, stroke:color1}}></polygon>
                <polygon class="st0 pathRight" points="67.7,1.2 67.7,127.7 108.8,127.7 134.2,49.5 67.7,1.2    " 
                    style={{fill:color2, stroke:color2}}></polygon>
                <polygon class="st2 pathLine" points="67.7,1.2 1.2,49.5 26.6,127.7 67.7,127.7 67.7,127.7 108.8,127.7 134.2,49.6  "></polygon>
            </g>
        </svg></div>
    );

    const HTMLHexagono = (
        <div class="coverSvg" style={{width: '122px',margin: 'auto'}}><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 130.5 112.4">
            <g>
                <clipPath id="clip_6">
                    <polygon class="st2 pathLine" points="97.3,1 65.4,1 65.4,1 33.2,1 1.2,56.2 33.2,111.4 65.4,111.4 97.3,111.4 129.4,56.2   "></polygon>
                </clipPath>
                <image data-slide="1" class="img img__ext img__ext__1" width="990" height="696" x="2" y="-287" clip-path="url(#clip_6)" xlinkHref="./img/bgTrans.jpg" style={{width: '100%'}}></image>
                <polygon class="st1 pathLeft" points="65.4,1 33.2,1 1.2,56.2 33.2,111.4 65.4,111.4   " 
                    style={{fill:color1, stroke:color1}}></polygon>
                <polygon class="st0 pathRight" points="65.4,1 65.4,111.4 97.3,111.4 129.4,56.2 97.3,1     " 
                    style={{fill:color2, stroke:color2}}></polygon>
                <polygon class="st2 pathLine" points="97.3,1 65.4,1 65.4,1 33.2,1 1.2,56.2 33.2,111.4 65.4,111.4 97.3,111.4 129.4,56.2   "></polygon>
            </g>
        </svg></div>
    );

    const HTMLOvalo = (
        <div class="coverSvg" style={{width: '122px',margin: 'auto'}}><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.5 68.1">
            <g>
                <clipPath id="clip_7">
                    <path class="st2 pathLine" d="M61.5,1c-0.1,0-0.2,0-0.2,0C28,1,1,15.8,1,34c0,18.2,27,33,60.2,33c0.1,0,0.2,0,0.2,0c33.2-0.1,60-14.8,60-33                 S94.6,1.1,61.5,1z"></path>
                </clipPath>
                <image data-slide="1" class="img img__ext img__ext__1" width="990" height="696" x="2" y="-287" clip-path="url(#clip_7)" xlinkHref="./img/bgTrans.jpg" style={{width: '100%'}}></image>
                <path class="st1 pathLeft" d="M61.5,1c-0.1,0-0.2,0-0.2,0C28,1,1,15.8,1,34c0,18.2,27,33,60.2,33c0.1,0,0.2,0,0.2,0V1z" 
                    style={{fill:color1, stroke:color1}}></path>
                <path class="st0 pathRight" d="M61.5,1v66.1c33.2-0.1,60-14.8,60-33S94.6,1.1,61.5,1z" 
                    style={{fill:color2, stroke:color2}}></path>
                <path class="st2 pathLine" d="M61.5,1c-0.1,0-0.2,0-0.2,0C28,1,1,15.8,1,34c0,18.2,27,33,60.2,33c0.1,0,0.2,0,0.2,0c33.2-0.1,60-14.8,60-33             S94.6,1.1,61.5,1z"></path>
            </g>
        </svg></div>
    );

    const HTMLHuevo = (
        <div class="coverSvg" style={{width: '122px',margin: 'auto'}}><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.5 83.6">
            <g>
                <clipPath id="clip_8">
                    <path class="st2 pathLine" d="M61.2,2.4C55.9,1.5,50.7,1,45.9,1C16.6,1,1,19.3,1,41.8c0,22.5,15.6,40.8,44.9,40.8c4.7,0,9.9-0.5,15.3-1.4                 c27.8-4.7,60.2-20.5,60.2-39.4S89,7,61.2,2.4z"></path>
                </clipPath>
                <image data-slide="1" class="img img__ext img__ext__1" width="990" height="696" x="2" y="-287" clip-path="url(#clip_8)" xlinkHref="./img/bgTrans.jpg" style={{width: '100%'}}></image>
                <path class="st1 pathLeft" d="M61.2,2.4C55.9,1.5,50.7,1,45.9,1C16.6,1,1,19.3,1,41.8c0,22.5,15.6,40.8,44.9,40.8c4.7,0,9.9-0.5,15.3-1.4             V2.4z" 
                    style={{fill:color1, stroke:color1}}></path>
                <path class="st0 pathRight" d="M61.2,2.4v78.8c27.8-4.7,60.2-20.5,60.2-39.4S89,7,61.2,2.4z" 
                    style={{fill:color2, stroke:color2}}></path>
                <path class="st2 pathLine" d="M61.2,2.4C55.9,1.5,50.7,1,45.9,1C16.6,1,1,19.3,1,41.8c0,22.5,15.6,40.8,44.9,40.8c4.7,0,9.9-0.5,15.3-1.4             c27.8-4.7,60.2-20.5,60.2-39.4S89,7,61.2,2.4z"></path>
            </g>
        </svg></div>
    );

    const HTMLCorazon = (
        <div class="coverSvg" style={{width: '122px',margin: 'auto'}}><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.4 99.1">
            <g>
                <clipPath id="clip_9">
                    <path class="st2 pathLine" d="M91.6,1C75.4,1,65.3,7,61.1,19.1c0-0.1-0.1-0.2-0.1-0.3v-0.2C56.3,6.9,46,1,30.3,1C6.4,1,1,26.8,1,38.4                 C1,71.3,27.9,98,61,98.1v0c0,0,0.1,0,0.1,0c33.3,0,60.2-26.8,60.2-59.8C121.4,26.1,115.8,1,91.6,1z"></path>
                </clipPath>
                <image data-slide="1" class="img img__ext img__ext__1" width="990" height="696" x="2" y="-287" clip-path="url(#clip_9)" xlinkHref="./img/bgTrans.jpg" style={{width: '100%'}}></image>
                <path class="st1 pathLeft" d="M61.2,18.7C56.4,6.9,46.2,1,30.4,1C6.5,1,1.2,26.8,1.2,38.4c0,32.9,26.9,59.6,60,59.7V18.7z" 
                    style={{fill:color1, stroke:color1}}></path>
                <path class="st0 pathRight" d="M91.7,1C75.6,1,65.5,7,61.3,19.1c0-0.1-0.1-0.2-0.1-0.3v79.3c0,0,0.1,0,0.1,0c33.3,0,60.2-26.8,60.2-59.8             C121.5,26.1,115.9,1,91.7,1z" 
                    style={{fill:color2, stroke:color2}}></path>
                <path class="st2 pathLine" d="M91.6,1C75.4,1,65.3,7,61.1,19.1c0-0.1-0.1-0.2-0.1-0.3v-0.2C56.3,6.9,46,1,30.3,1C6.4,1,1,26.8,1,38.4             C1,71.3,27.9,98,61,98.1v0c0,0,0.1,0,0.1,0c33.3,0,60.2-26.8,60.2-59.8C121.4,26.1,115.8,1,91.6,1z"></path>
            </g>
        </svg></div>
    );

    const HTMLDiamante = (
        <div class="coverSvg" style={{width: '122px',margin: 'auto'}}><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.7 100.9">
            <g>
                <clipPath id="clip_10">
                    <polygon class="st2 pathLine" points="121.4,31.9 94.8,1.1 63.9,1.1 63.9,1 31.5,1 1.4,31.8 63.9,99.4  "></polygon>
                </clipPath>
                <image data-slide="1" class="img img__ext img__ext__1" width="990" height="696" x="2" y="-287" clip-path="url(#clip_10)" xlinkHref="./img/bgTrans.jpg" style={{width: '100%'}}></image>
                <polygon class="st1 pathLeft" points="63.9,1 31.5,1 1.4,31.8 63.7,99.5 63.9,99.3     " 
                    style={{fill:color1, stroke:color1}}></polygon>
                <polygon class="st0 pathRight" points="63.9,99.4 121.4,31.9 94.8,1.1 63.9,1.1     " 
                    style={{fill:color2, stroke:color2}}></polygon>
                <polygon class="st2 pathLine" points="121.4,31.9 94.8,1.1 63.9,1.1 63.9,1 31.5,1 1.4,31.8 63.9,99.4  "></polygon>
            </g>
        </svg></div>
    );

    const HTMLDobleFlecha = (
        <div class="coverSvg" style={{width: '122px',margin: 'auto'}}><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 123.9 52.5">
            <g>
                <clipPath id="clip_11">
                    <path class="st2 pathLine" d="M121.9,23.7L103.6,2.4c-0.7-0.9-1.8-1.4-3-1.4H21.4L1.9,23.7c-1.3,1.5-1.3,3.6,0,5.1l18.3,21.3                 c0.7,0.9,1.8,1.4,3,1.4h77.4c1.1,0,2.2-0.5,3-1.4l18.3-21.3C123.2,27.3,123.2,25.2,121.9,23.7z"></path>
                </clipPath>
                <image data-slide="1" class="img img__ext img__ext__1" width="990" height="696" x="2" y="-287" clip-path="url(#clip_11)" xlinkHref="./img/bgTrans.jpg" style={{width: '100%'}}></image>
                <path class="st1 pathLeft" d="M62.4,1h-41L1.9,23.7c-1.3,1.5-1.3,3.6,0,5.1l18.3,21.3c0.7,0.9,1.8,1.4,3,1.4h39.2V1z" 
                    style={{fill:color1, stroke:color1}}></path>
                <path class="st0 pathRight" d="M103.6,2.4c-0.7-0.9-1.8-1.4-3-1.4H62.4v50.5h38.2c1.1,0,2.2-0.5,3-1.4l18.3-21.3c1.3-1.5,1.3-3.6,0-5.1             L103.6,2.4z" 
                    style={{fill:color2, stroke:color2}}></path>
                <path class="st2 pathLine" d="M121.9,23.7L103.6,2.4c-0.7-0.9-1.8-1.4-3-1.4H21.4L1.9,23.7c-1.3,1.5-1.3,3.6,0,5.1l18.3,21.3             c0.7,0.9,1.8,1.4,3,1.4h77.4c1.1,0,2.2-0.5,3-1.4l18.3-21.3C123.2,27.3,123.2,25.2,121.9,23.7z"></path>
            </g>
        </svg></div>
    );

    const HTMLMediaLuna = (
        <div class="coverSvg" style={{width: '122px',margin: 'auto'}}><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 71.9 122.5" width="70">
            <g>
                <clipPath id="clip_12">
                    <path class="st2 pathLine" d="M1,1v120.5c38.8-1,69.9-27.6,69.9-60.2S39.8,2,1,1z"></path>
                </clipPath>
                <image data-slide="1" class="img img__ext img__ext__1" width="990" height="696" x="2" y="-287" clip-path="url(#clip_12)" xlinkHref="./img/bgTrans.jpg" style={{width: '200%'}}></image>
                <path class="st0 pathLeft" d="M1,1v60.2h69.9C70.9,28.6,39.8,2,1,1z" 
                    style={{fill:color1, stroke:color1}}></path>
                <path class="st1 pathRight" d="M1,121.5c38.8-1,69.9-27.6,69.9-60.2H1V121.5z" 
                    style={{fill:color2, stroke:color2}}></path>
                <path class="st2 pathLine" d="M1,1v120.5c38.8-1,69.9-27.6,69.9-60.2S39.8,2,1,1z"></path>
            </g>
        </svg></div>
    );

    const HTMLManzana = (
        <div class="coverSvg" style={{width: '122px',margin: 'auto'}}><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 120.1 118.6">
            <g>
                <clipPath id="clip_13">
                    <path class="st2 pathLine" d="M118.2,43.4L103.9,8.3C102.1,3.9,97.8,1,93,1H27.9C23.2,1,19,3.8,17.1,8.1L2,43.1c-1.4,3.3-1.3,7,0.4,10.1                 l30.2,58.1c2,3.9,6.1,6.3,10.4,6.3h36.9c4.5,0,8.7-2.6,10.6-6.7L118,52.9C119.4,49.9,119.5,46.5,118.2,43.4z"></path>
                </clipPath>
                <image data-slide="1" class="img img__ext img__ext__1" width="990" height="696" x="2" y="-287" clip-path="url(#clip_13)" xlinkHref="./img/bgTrans.jpg" style={{width: '100%'}}></image>
                <path class="st1 pathLeft" d="M60.1,1H26.9C22.2,1,18,3.8,16.1,8.1L1,43.1c-1.4,3.3-1.3,7,0.4,10.1l30.2,58.1c2,3.9,6.1,6.3,10.4,6.3h18.1V1             z" 
                    style={{fill:color1, stroke:color1}}></path>
                <path class="st0 pathRight" d="M102.9,8.3C101.1,3.9,96.8,1,92,1H60.1v116.6h18.8c4.5,0,8.7-2.6,10.6-6.7L117,52.9c1.4-3,1.5-6.4,0.3-9.5             L102.9,8.3z" 
                    style={{fill:color2, stroke:color2}}></path>
                <path class="st2 pathLine" d="M118.2,43.4L103.9,8.3C102.1,3.9,97.8,1,93,1H27.9C23.2,1,19,3.8,17.1,8.1L2,43.1c-1.4,3.3-1.3,7,0.4,10.1             l30.2,58.1c2,3.9,6.1,6.3,10.4,6.3h36.9c4.5,0,8.7-2.6,10.6-6.7L118,52.9C119.4,49.9,119.5,46.5,118.2,43.4z"></path>
            </g>
        </svg></div>
    );

    const HTMLOctogono = (
        <div class="coverSvg" style={{width: '122px',margin: 'auto'}}><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 134.3 134.3">
            <g>
                <clipPath id="clip_14">
                    <polygon class="st2 pathLine" points="113.8,20.4 67.1,1.1 67.1,1.1 20.4,20.4 1.1,67.1 20.4,113.8 67.1,133.2 67.1,133.2 67.1,133.2                  113.8,113.8 133.2,67.1  "></polygon>
                </clipPath>
                <image data-slide="1" class="img img__ext img__ext__1" width="990" height="696" x="2" y="-287" clip-path="url(#clip_14)" xlinkHref="./img/bgTrans.jpg" style={{width: '100%'}}></image>
                <polygon class="st1 pathLeft" points="67.1,1.1 20.4,20.4 1.1,67.1 20.4,113.8 67.1,133.2  " 
                    style={{fill:color1, stroke:color1}}></polygon>
                <polygon class="st0 pathRight" points="67.1,1.1 67.1,1.1 67.1,133.2 67.1,133.2 113.8,113.8 133.2,67.1 113.8,20.4  " 
                    style={{fill:color2, stroke:color2}}></polygon>
                <polygon class="st2 pathLine" points="113.8,20.4 67.1,1.1 67.1,1.1 20.4,20.4 1.1,67.1 20.4,113.8 67.1,133.2 67.1,133.2 67.1,133.2              113.8,113.8 133.2,67.1  "></polygon>
            </g>
        </svg></div>
    );

    const HTMLRombo = (
        <div class="coverSvg" style={{width: '122px',margin: 'auto'}}><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 133.9 103.1">
            <g>
                <clipPath id="clip_15">
                    <path class="st2 pathLine" d="M131.4,47.3c-0.5-0.7-1.1-1.3-1.8-1.8L70,2.5c-1.4-1-2.9-1.5-4.5-1.5c-1.7,0-3.4,0.5-4.8,1.6l-56.6,43                 c-3.4,2.6-4.1,7.5-1.5,11c0.4,0.6,1,1.1,1.5,1.5l56.6,42.4c1.4,1.1,3.1,1.6,4.8,1.6c1.6,0,3.1-0.5,4.5-1.4l59.6-42.4                 C133.1,55.7,133.9,50.8,131.4,47.3z"></path>
                </clipPath>
                <image data-slide="1" class="img img__ext img__ext__1" width="990" height="696" x="2" y="-287" clip-path="url(#clip_15)" xlinkHref="./img/bgTrans.jpg" style={{width: '100%'}}></image>
                <path class="st1 pathLeft" d="M65.5,1c-1.7,0-3.4,0.5-4.8,1.6l-56.6,43c-3.4,2.6-4.1,7.5-1.5,11c0.4,0.6,1,1.1,1.5,1.5l56.6,42.4             c1.4,1.1,3.1,1.6,4.8,1.6V1z" 
                    style={{fill:color1, stroke:color1}}></path>
                <path class="st0 pathRight" d="M129.6,45.5L70,2.5c-1.4-1-2.9-1.5-4.5-1.5v101.1c1.6,0,3.1-0.5,4.5-1.4l59.6-42.4c3.5-2.5,4.4-7.4,1.8-10.9             C130.9,46.6,130.3,46,129.6,45.5z" 
                    style={{fill:color2, stroke:color2}}></path>
                <path class="st2 pathLine" d="M131.4,47.3c-0.5-0.7-1.1-1.3-1.8-1.8L70,2.5c-1.4-1-2.9-1.5-4.5-1.5c-1.7,0-3.4,0.5-4.8,1.6l-56.6,43             c-3.4,2.6-4.1,7.5-1.5,11c0.4,0.6,1,1.1,1.5,1.5l56.6,42.4c1.4,1.1,3.1,1.6,4.8,1.6c1.6,0,3.1-0.5,4.5-1.4l59.6-42.4             C133.1,55.7,133.9,50.8,131.4,47.3z"></path>
            </g>
        </svg></div>
    );

    const HTMLEsmeralda = (
        <div class="coverSvg" style={{width: '122px',margin: 'auto'}}><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 118.6 75.8">
            <g>
                <polygon class="st1 pathLeft" points="59.6,1 19.7,1 1,19.5 1,56.4 19.7,74.8 59.6,74.8    " 
                    style={{fill:color1, stroke:color1}}></polygon>
                <polygon class="st0 pathRight" points="59.6,1 59.6,74.8 98.9,74.8 117.6,56.4 117.6,19.5 98.9,1    " 
                    style={{fill:color2, stroke:color2}}></polygon>
                <polygon class="st2 pathLine" points="98.9,1 19.7,1 1,19.5 1,56.4 19.7,74.8 98.9,74.8 117.6,56.4 117.6,19.5  "></polygon>
            </g>
        </svg></div>
    );
    const HTMLEscudo = (
        <div class="coverSvg" style={{width: '122px',margin: 'auto'}}><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 476 476">
            <g stroke-width="5">
                <path class="st1 pathLeft" 
                    d="M238,0 c -226,0 -220,-10 -168,274 19,104 36,171 46,183 15,15 33,18 122,18 z" 
                    style={{fill:color1, stroke:color1}}>
                </path>
                <path class="st0 pathRight" 
                    d="M238,0  c 226,0 220,-10 168,274 -19,104 -36,171 -46,183 -15,15 -33,18 -122,18 z"  
                    style={{fill:color2, stroke:color2}}>
                </path>
                <path class="st2 pathLine" style={{strokeWidth: '8px'}}
                    d="M238,0 c -226,0 -220,-10 -168,274 19,104 36,171 46,183 15,15 33,18 122,18 
				       M238,0 c 226,0 220,-10 168,274 -19,104 -36,171 -46,183 -15,15 -33,18 -122,18">
                    </path>
            </g>
        </svg></div>
    );



    const ListaFormas = [
        HTMLCirculo,
        HTMLCuadrado,
        HTMLRectangulo,
        HTMLTriangulo,
        HTMLPentagono,
        HTMLHexagono,
        HTMLOvalo,
        HTMLHuevo,
        HTMLCorazon,
        HTMLDiamante,
        HTMLDobleFlecha,
        HTMLMediaLuna,
        HTMLManzana,
        HTMLOctogono,
        HTMLRombo,
        HTMLEsmeralda,
        HTMLEscudo,
    ]


    return(
        <div id="shape-preview">
            {ListaFormas[idForma-1]}
        </div>
    );
}

export default PreviewForma;


/*
        <div class="coverSvg" style={{width: '122px',margin: 'auto'}}><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 476 476">
            <g>
                <clipPath id="clip_17">
                    <path class="st2 pathLine" 
                        d="M238,0 c -226,0 -220,-10 -168,274 19,104 36,171 46,183 15,15 33,18 122,18 
                            M238,0 c 226,0 220,-10 168,274 -19,104 -36,171 -46,183 -15,15 -33,18 -122,18 
                            ">
                    </path>
                </clipPath>
                <image data-slide="1" class="img img__ext img__ext__1" width="990" height="696" x="2" y="-287" clip-path="url(#clip_17)" xlinkHref="./img/bgTrans.jpg" style={{width: '100%'}}></image>
                <path class="st1 pathLeft" 
                    d="M238,0 c -226,0 -220,-10 -168,274 19,104 36,171 46,183 15,15 33,18 122,18 z" 
                    style="fill: rgb(114, 187, 83); stroke: rgb(114, 187, 83);">
                </path>
                <path class="st0 pathRight" 
                    d="M238,0  c 226,0 220,-10 168,274 -19,104 -36,171 -46,183 -15,15 -33,18 -122,18 z" 
                    style="fill: rgb(166, 122, 34); stroke: rgb(166, 122, 34);">
                </path>
                <path class="st2 pathLine" 
                    d="M238,0 c -226,0 -220,-10 -168,274 19,104 36,171 46,183 15,15 33,18 122,18 
                        M238,0 c 226,0 220,-10 168,274 -19,104 -36,171 -46,183 -15,15 -33,18 -122,18 
                        ">
                </path>
            </g>
        </svg></div>

*/