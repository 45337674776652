import { getIdCCAA, numberWithCommas, numberWithCommas2, rootPath } from "../../../../services/utils";
import AvisoPreciosFuturos from "./AvisoPreciosFuturos";

const PreciosResumen = (props) => {
    const ficha = props.ficha;
    const tienePreciosFuturos = (ficha.PFuturos !== null)
    const tienePMenorCCAA = ((ficha.AgrupacionCCAA && ficha.AgrupacionCCAA.PVPMenor>0) || (ficha.PFuturos && ficha.PFuturos.AgrupacionCCAA && ficha.PFuturos.AgrupacionCCAA.PVPMenor>0))
    const tienePMasBajoCCAA = ((ficha.AgrupacionCCAA && ficha.AgrupacionCCAA.PVPMasBajo>0) || (ficha.PFuturos && ficha.PFuturos.AgrupacionCCAA && ficha.PFuturos.AgrupacionCCAA.PVPMasBajo>0))
    const tienePFacturacion = ((ficha.PFact>0)||(tienePreciosFuturos) && (ficha.PFuturos.PFact>0));
    const ambitoHospitalario = ((ficha.DatosF && ficha.DatosF.some(e => e.CodigoDato === 'H' || e.CodigoDato === 'EC') ? false : false));
    const literalPMenor = (ficha.AgrupacionCCAA ? ficha.AgrupacionCCAA.LiteralPMenor:
        tienePreciosFuturos ? 
            (ficha.PFuturos.AgrupacionCCAA ? ficha.PFuturos.AgrupacionCCAA.LiteralPMenor : 'Precio menor'):
            'Precio menor'
    )
    const LiteralPMasBajo = (ficha.AgrupacionCCAA ? ficha.AgrupacionCCAA.LiteralPMasBajo:
        tienePreciosFuturos ? 
            (ficha.PFuturos.AgrupacionCCAA ? ficha.PFuturos.AgrupacionCCAA.LiteralPMasBajo : 'Precio más bajo'):
            'Precio más bajo'
    )

    const estiloPreciosAltos = {fontSize:'25px'}
    const estiloVacio = {}
    const estiloPrecio = (ficha.PVPIVA>999999 ? estiloPreciosAltos : estiloVacio)
        
    var idCCAA = getIdCCAA();
    var literalPVPIVA = ((idCCAA === 5 || idCCAA === 18 || idCCAA === 19) ? 'PVP sin IVA': 'PVP con IVA');
    if (ambitoHospitalario) literalPVPIVA = "PVL";

    var precioAMostrar = ((idCCAA === 5 || idCCAA === 18 || idCCAA === 19) ? ficha.PVPsinIVA: ficha.PVPIVA);
    if (ambitoHospitalario) precioAMostrar = ficha.PVL

    var preciofuturoAMostrar = 0;
    if (tienePreciosFuturos){
        preciofuturoAMostrar = ((idCCAA === 5 || idCCAA === 18 || idCCAA === 19) ? ficha.PFuturos.PVPsinIVA: ficha.PFuturos.PVPIVA);
        if (ambitoHospitalario) preciofuturoAMostrar = ficha.PFuturos.PVL;
    }
    
    if (ficha.DatosF){
        if (ficha.DatosF.filter(e => e.CodigoDato === 'PN').length > 0){
            literalPVPIVA = literalPVPIVA + ' notificado';
        }
    }
    
    var idCCAA = getIdCCAA();

    const precioAComparar = (ficha.DatosF.filter(e => e.CodigoDato === 'PN').length > 0) ? 
        (idCCAA==5 && ficha.AgrupacionCCAA && ficha.AgrupacionCCAA.PVPMasBajo>0 && (ficha.DatosF.filter(e => e.CodigoDato === 'INSNS').length > 0)) ?
            ficha.AgrupacionCCAA.PVPMasBajo
            :
            ficha.PFact 
        : 
        (idCCAA === 5 || idCCAA === 18 || idCCAA === 19) ? 
            ficha.PVPsinIVA 
            : 
            ficha.PFact //ficha.PVPIVA
    const precioAComparar2 = (ficha.DatosF.filter(e => e.CodigoDato === 'PN').length > 0) ? ficha.PFact : ficha.PFact //ficha.PVPIVA
    const mostrarAvisoPMenorAuto = (ficha.AgrupacionCCAA && ficha.AgrupacionCCAA.PVPMenor>0 && precioAComparar>0 && ficha.AgrupacionCCAA.PVPMenor<precioAComparar);
    const mostrarAvisoPMasBajoAuto = (ficha.AgrupacionCCAA && ficha.AgrupacionCCAA.PVPMasBajo>0 && precioAComparar>0 && ficha.AgrupacionCCAA.PVPMasBajo<precioAComparar);
    const mostrarAvisoPMenorNac = (ficha.AgrupacionNacional && ficha.AgrupacionNacional.PVPMenor>0 && precioAComparar2>0 && ficha.AgrupacionNacional.PVPMenor<precioAComparar2);
    const mostrarAvisoPMasBajoNac = (ficha.AgrupacionNacional && ficha.AgrupacionNacional.PVPMasBajo>0 && precioAComparar2>0 && ficha.AgrupacionNacional.PVPMasBajo<precioAComparar2);

    var avisoprecioauto="";
    var avisoprecionac="";
    /*
    avisoprecio += (mostrarAvisoPMenorAuto ? " / Supera precio menor" : "");
    avisoprecio += (mostrarAvisoPMasBajoAuto ? " / Supera precio más bajo" : "");
    avisoprecio += (mostrarAvisoPMenorNac && !mostrarAvisoPMenorAuto ? " / Supera precio menor nacional" : "");
    avisoprecio += (mostrarAvisoPMasBajoNac && !mostrarAvisoPMasBajoAuto ? " / Supera precio más bajo nacional" : "");
    */
    
    avisoprecioauto += ((mostrarAvisoPMenorAuto && mostrarAvisoPMasBajoAuto) ? 
                            "Supera precio menor y más bajo autonómico"
                        : mostrarAvisoPMenorAuto ?
                            "Supera precio menor autonómico"
                        : mostrarAvisoPMasBajoAuto ?
                            "Supera precio más bajo autonómico"
                        : 
                            ""
                        );
    //avisoprecionac += ((mostrarAvisoPMenorNac || mostrarAvisoPMasBajoNac) ? "Supera precio menor/más bajo nacional" : "");
    avisoprecionac += ((mostrarAvisoPMenorNac && mostrarAvisoPMasBajoNac) ? 
                            "Supera precio menor y más bajo nacional"
                        : mostrarAvisoPMenorNac ?
                            "Supera precio menor nacional"
                        : mostrarAvisoPMasBajoNac ?
                            "Supera precio más bajo nacional"
                        : 
                            ""
                        );

    /*if (avisoprecio !== "")
        avisoprecio = avisoprecio.substring(3);*/

    const infoAvisoPrecio = ((mostrarAvisoPMasBajoAuto || mostrarAvisoPMasBajoNac || mostrarAvisoPMenorAuto || mostrarAvisoPMenorNac )?
        <div class="info">
            {((mostrarAvisoPMasBajoAuto || mostrarAvisoPMenorAuto) ?
                <p class="fs fs12 redColor misc">
                    {avisoprecioauto}
                </p>
             :
                <></>
            )}
            {((mostrarAvisoPMasBajoNac || mostrarAvisoPMenorNac) ?
                <p class="fs fs12 redColor misc">
                    {avisoprecionac}
                </p>
             :
                <></>
            )}
        </div>
    :
        <></>
    );


    return (
        <div>
        <div class="precios f-f-Flama-Basic">
        <div class="precios__iva">
            <p class="nombre literalprecio" style={{textTransform: 'none', display: 'flex', alignItems: 'center'}}>
                <span>{literalPVPIVA}</span>
                { 
                    (ficha.InfoPrecio && ficha.InfoPrecio !== '') ?
                        <span style={{cursor:'pointer', marginLeft:'10px'}}  float="right" onClick={(e) => props.abreEmergenteInfoPrecio(e)}>
                            <img src={rootPath + "img/infoGreen.svg"} alt=""/>
                        </span>
                    :
                        <></>
                }
            </p>
            {(precioAMostrar>0) ?
                <p class="precio" style={estiloPrecio}>{numberWithCommas(precioAMostrar)}</p>
            :
                <p class="precio">-</p>
            }
            {(tienePreciosFuturos && preciofuturoAMostrar !== precioAMostrar ?
                (preciofuturoAMostrar>0) ?
                    <p class="sin_iva">{numberWithCommas(preciofuturoAMostrar)}</p>
                    :
                    <p class="precio"></p>
                :
                <span></span>                            
            )}
        <p class="moneda">EUR</p>
        </div>
        <div class="otros__precios">
            {(tienePreciosFuturos && 
                (ficha.PFuturos.PVPIVA>0 || ficha.PFuturos.PVPsinIVA>0 || ficha.PFuturos.PFact>0 || 
                    (ficha.PFuturos.AgrupacionCCAA && 
                        (ficha.PFuturos.AgrupacionCCAA.PVPMenor > 0 || ficha.PFuturos.AgrupacionCCAA.PVPMasBajo > 0))) ?
                <AvisoPreciosFuturos fecha={ficha.PFuturos.Fecha}></AvisoPreciosFuturos>
            :
                <div></div>                            
            )}
            <div class="otros__precios--precios">
                <div class="precios__factura">
                    <p class="nombre literalprecio">Precio facturación</p>
                    {(tienePFacturacion && ficha.PFact>0) ?
                            <p class="precio">{numberWithCommas(ficha.PFact)}</p>
                    :
                            <p class="precio">-</p>
                    }
                    {((tienePreciosFuturos && ficha.PFuturos.PFact>0 && ficha.PFuturos.PFact !== ficha.PFact)  ?
                        <p class="sin_iva">{numberWithCommas(ficha.PFuturos.PFact)}</p>
                    :
                        <p class="sin_iva"></p>
                    )}
                    {tienePFacturacion ?
                        <p class="moneda">EUR</p>
                    : 
                        <span></span>
                    }   
                </div>
                {(ficha.CodTipoRegistro !== "MHO") ?
                    <div class="precios__comunidad">
                        <h3 class="titulo">{ficha.CCAA}</h3>
                        <div class="precios__comunidad--precios">
                            <div style={{maxWidth:'50%'}}>
                                <p class="nombre literalprecio">{literalPMenor}</p>                                        
                                {(ficha.AgrupacionCCAA ?
                                    (ficha.AgrupacionCCAA.PVPMenor>0 ?
                                        <p class="precio">{numberWithCommas(ficha.AgrupacionCCAA.PVPMenor)}</p>
                                    : 
                                        <p class="precio">-</p>
                                    )
                                :
                                    <p class="precio">-</p>
                                )}
                                {(tienePreciosFuturos ?
                                    (ficha.PFuturos.AgrupacionCCAA ?
                                        (ficha.PFuturos.AgrupacionCCAA.PVPMenor>0 && (!ficha.AgrupacionCCAA || ficha.PFuturos.AgrupacionCCAA.PVPMenor!==ficha.AgrupacionCCAA.PVPMenor) ?
                                            <p class="sin_iva">{numberWithCommas(ficha.PFuturos.AgrupacionCCAA.PVPMenor)}</p>
                                        : 
                                            <p class="sin_iva"></p>
                                        )
                                    :
                                        <p class="sin_iva"></p>
                                    )
                                :
                                    <span></span>                            
                                )}
                                {(tienePMenorCCAA ?
                                    <p class="moneda">EUR</p>
                                :
                                    <span></span>
                                )}
                            </div>
                            <div style={{maxWidth:'50%'}}>
                                <p class="nombre literalprecio">{LiteralPMasBajo}</p>
                                {(ficha.AgrupacionCCAA ?
                                    (ficha.AgrupacionCCAA.PVPMasBajo>0 ?
                                        <p class="precio">{numberWithCommas(ficha.AgrupacionCCAA.PVPMasBajo)}</p>
                                    : 
                                        <p class="precio">-</p>
                                    )
                                :
                                    <p class="precio">-</p>
                                )}
                                {(tienePreciosFuturos ?
                                    (ficha.PFuturos.AgrupacionCCAA ?
                                        (ficha.PFuturos.AgrupacionCCAA.PVPMasBajo>0 && (!ficha.AgrupacionCCAA || ficha.PFuturos.AgrupacionCCAA.PVPMasBajo !== ficha.AgrupacionCCAA.PVPMasBajo) ?
                                            <p class="sin_iva">{numberWithCommas(ficha.PFuturos.AgrupacionCCAA.PVPMasBajo)}</p>
                                        : 
                                            <p class="sin_iva"></p>
                                        )
                                    :
                                        <p class="sin_iva"></p>
                                    )
                                :
                                    <span></span>                            
                                )}
                                {(tienePMasBajoCCAA ?
                                    <p class="moneda">EUR</p>
                                :
                                    <span></span>
                                )}
                            </div>
                        </div>
                    </div>
                :
                    <></>
                }
            </div>
        </div>
    </div>
    {infoAvisoPrecio}
    </div>    
)
}
export default PreciosResumen;
