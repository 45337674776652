import { FormatoFechaBonito } from "../../../services/utils";

const ItemContenidoHistorial = (props) => {
    const CambiaAFicha = () => {
        props.cambiaPagina({
            newpagina: "Ficha"+props.item.CodTipoRegistro,
            newid: props.item.Id,
        });
    }

    return (
        <div class="item" >
            <span class="date" >{FormatoFechaBonito(props.item.FechaVisita)}</span>
            <h3 class="title" >
                <span title={props.item.NombreCompleto}  
                        onClick={() => CambiaAFicha()} 
                        style={{cursor:'pointer'}}>{props.item.NombreCompleto}</span>
            </h3>
            <div class="info" >
                <p class="fs fs15 purpleColor tag" >{props.item.TipoRegistro}</p>
            </div>        
        </div>
    )
}
export default ItemContenidoHistorial;
