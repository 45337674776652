import FormBusqueda from "./FormBusqueda/FormBusqueda";
import BusquedasPreferidas from "./BFavoritas/BusquedasPreferidasInicio";

const FormBusquedaYPreferidos = (props) => {
    const ancho = window.innerWidth;
    const isMobile = (ancho<768);   
    
    const estiloContainer = (isMobile) ? {maxWidth: '100%'} : {};

    return (
        <section className="section section__banner section__banner__buscador" > 
            <div className="container" style={estiloContainer} >
                <FormBusqueda cambiaPagina={props.cambiaPagina} ></FormBusqueda>
                <BusquedasPreferidas cambiaPagina={props.cambiaPagina} ></BusquedasPreferidas>
            </div>
        </section> 
    );
};

export default FormBusquedaYPreferidos;
