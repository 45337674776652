import { useEffect, useState } from "react";
import BP2022Service from "../../services/user.service";
import { getCurrentURL, getParam, getParamsURL, rootPath } from "../../services/utils";
import SelectorComboBox from "../Utilidades/Selector/SelectorComboBox";
import Emergente from "../Fichas/FichaMUH/Apartados/Cismed/Emergente";
import AddEmpresa from "./AddEmpresa";

const KeyEmpresa = (props) => {

    const [mensaje, setMensaje] = useState('');
    const [estado, setEstado] = useState(0);
    const [idEmpresa, setIdEmpresa] = useState(0);
    const [idCurrentEmpresa, setIdCurrentEmpresa] = useState(0);
    const [listaEmpresas, setListaEmpresas] = useState([]);
    const [listaColaboradoras, setListaColaboradoras] = useState([]);
    const [sKey, setSKey] = useState('');
    const [mostrarAddEmpresa, setMostrarAddEmpresa] = useState(false);
    const [alto, setAlto] = useState('450px');

    const colaboradoras = (listaColaboradoras) ? 
        listaColaboradoras.map(emp =>
            <div class="table__row table__row__normal fs fs14 fw fw300 blackColor uppercase" style={{display:'table'}}>
                <div style={{display:'table-row'}}>
                    <div class="column" style={{ width:'230px', display:'table-cell', paddingTop:'14px', paddingBottom:'0px' }}>
                        <p style={{verticalAlign:'middle'}}>{emp.Nombre}</p>
                    </div>
                    <div class="column" style={{ width:'230px', display:'table-cell', paddingTop:'14px', paddingBottom:'0px', textTransform:'none' }}>
                        <p style={{verticalAlign:'middle'}}>{emp.Key}</p>
                    </div>
                    <div class="column"style={{ width:'60px', display:'table-cell', paddingTop:'0px', paddingBottom:'0px' }}>
                        <p><img src="/img/checkKO2.svg" style={{cursor:'pointer', verticalAlign: 'middle'}} title="Quitar empresa" onClick={(e) =>quitaEmpresa(emp.IdEmpresa) }></img></p>
                    </div>
                </div>
            </div>
        )
    :
        <></>;

    const getEmpresa = () =>{
        setEstado(1);
        BP2022Service.getEmpresa()
        .then((response) => {
            if (response.data){
                setListaEmpresas(response.data.Empresas);
                setIdCurrentEmpresa(response.data.IdEmpresa);
                setIdEmpresa(response.data.IdEmpresa);
                setSKey(response.data.Key);
                setListaColaboradoras(response.data.Colaboradoras)
                setAlto((response.data.Colaboradoras && response.data.Colaboradoras.length>1 ) ?  (550 + ((response.data.Colaboradoras.length-1) * 50)).toString() + 'px' : (response.data.Colaboradoras && response.data.Colaboradoras.length===1 ) ? '550px' : '450px')
                setEstado(2);
                setMensaje('');
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
            setMensaje(error.message);
            setEstado(2);
        });
    }

    useEffect (() =>{
        if (estado===0){
            getEmpresa();
        }
    },[]);


    const setEmpresa = () =>{
        setEstado(3);
        BP2022Service.setEmpresa(idEmpresa)
        .then((response) => {
            if (response.data){
                setIdCurrentEmpresa(response.data.IdEmpresa);
                setSKey(response.data.Key);
                setEstado(2);
                setMensaje(response.data.Msg);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
            setMensaje(error.message);
            setEstado(2);
        });
    }

    const cambiaEmpresa = (nuevoId) =>{
        setIdEmpresa(nuevoId);
        setMensaje('');
    }

    const guardaEmpresa = () =>
    {
        setEmpresa();
    }

    const copiaClaveBack = () =>
    {
        //navigator.clipboard.writeText(sKey);
        navigator.clipboard
          .writeText(sKey)
          .then(() => {
          })
          .catch(() => {
          });
    
    }

    const copiaClave = () => {
        var textArea = document.createElement("textarea");
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;
      
        textArea.style.width = '2em';
        textArea.style.height = '2em';
      
        textArea.style.padding = 0;
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
      
        textArea.style.background = 'transparent';

        textArea.value = sKey;
      
        document.body.appendChild(textArea);
      
        textArea.select();
      
        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
        } catch (err) {
        }
      
        document.body.removeChild(textArea);
        
    }

    const cerrarFormAddEmpresa = () =>{
        setMostrarAddEmpresa(false);
    }
      
    const muestraFormAddEmpresa = () =>{
        setMostrarAddEmpresa(true);
    }
      
    const refrescaColaboradoras = (nuevalista) =>{
          setListaColaboradoras(nuevalista);
          setMostrarAddEmpresa(false);
          setAlto((nuevalista && nuevalista.length>1 ) ?  (550 + ((nuevalista.length-1) * 50)).toString() + 'px' : (nuevalista && nuevalista.length===1 ) ? '550px' : '450px')
    }

    const quitaEmpresa = (idEmp) =>{
        BP2022Service.delEmpresa(idEmp)
        .then((response) => {
            if (response.data){
                refrescaColaboradoras(response.data);
            }
        })
    }



    const listaEmpresasCodValor = listaEmpresas.map(e => ({codigo: e.Codigo, valor: e.Valor}));

    const contenido = (
        (estado === 0) ?
            <div style={{height: '100%', width: '100%', cursor:'wait', overflowY: 'hidden',}}>
                <p className="fs fs15 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '30%'}}>Iniciando...</p>
            </div>
        :(estado === 1) ?
            <div style={{height: '100%', width: '100%', cursor:'wait', overflowY: 'hidden',}}>
                <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '35px'}}>Buscando información...</p>
            </div>
        :(estado === 2) ?
            <form class="content__results content__results__filters" id="main-filter" onSubmit={(e) => e.preventDefault()}>
                {mostrarAddEmpresa ?
                    <AddEmpresa alCerrar={cerrarFormAddEmpresa} alAnadir={refrescaColaboradoras}></AddEmpresa>
                :
                    <></>
                }

                <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '6px'}}>{mensaje}</p>
                <p className="fw fw100 FlamaBook greyColor"
                    style={{marginBottom:'20px', marginTop:'20px'}}>
                    <span class="fs fs18">Empresa con permiso de actualización:</span>
                    <span class="fs fs16">
                        <SelectorComboBox 
                            lista = {listaEmpresasCodValor} 
                            idHTML="SelEmpConf" 
                            valueSelected={idEmpresa} 
                            alCambiar={cambiaEmpresa} 
                            ancho="100%"
                            maxLengthNormal={42}
                        ></SelectorComboBox>
                    </span>
                </p>
                <p className="fs fs18 fw fw100 FlamaBook greyColor"
                    style={{marginBottom:'30px'}}>
                        KEY asignada:
                        <span style={{fontWeight: 600, marginLeft:'20px'}}>
                            {(idEmpresa === idCurrentEmpresa) ? sKey : '------------'}
                            {(idEmpresa === idCurrentEmpresa) ? <img title="Copiar KEY" style={{paddingLeft:'20px', cursor:'pointer'}} src="/img/copy.svg" onClick={(e) => copiaClave()}/> : <></>}
                        </span>
                </p>
                <div className="cover cover__buttons" style={{textAlign: 'right', paddingTop: '10px'}} >
                    {(idEmpresa === idCurrentEmpresa) ? 
                        <button className="button button__grey"  style={{cursor:'default', height:'40px', paddingTop:'12px'}}> Asignar y generar Key</button>
                        :
                        <button className="button button__purple" onClick={(e) => guardaEmpresa()} style={{height:'40px', paddingTop:'12px'}}> Asignar y generar Key</button>
                    }
                </div>
                <p className="fw fw100 FlamaBook greyColor"
                    style={{marginBottom:'20px', marginTop:'20px'}}>
                    <span class="fs fs18">Otras empresas colaboradoras:</span>
                </p>
                {(listaColaboradoras && listaColaboradoras.length>0) ?
                <div class="table" style={{width:'520px'}}>
                    <div class="table__row table__row__header fs fs12 fw fw600 greenColor2 uppercase" style={{display:'table'}}>
                        <div style={{display:'table-row'}}>
                            <div class="column" style={{ width:'230px', display:'table-cell', paddingTop:'10px', paddingBottom:'5px' }} >
                                <p style={{marginBottom: '0px'}}>Empresa</p>
                            </div>
                            <div class="column" style={{width:'230px', display:'table-cell', paddingTop:'10px', paddingBottom:'5px' }} >
                                <p>KEY</p>
                            </div>
                            <div class="column" style={{width:'60px', display:'table-cell', paddingTop:'10px', paddingBottom:'5px' }} >
                                <p>&nbsp;</p>
                            </div>
                        </div>
                    </div>
                    {colaboradoras}
                </div>
                :
                <></>
                }
                <div className="cover cover__buttons" style={{textAlign: 'right', paddingTop: '10px'}} >
                    <button className="button button__purple" onClick={(e) => muestraFormAddEmpresa()} style={{height:'40px', paddingTop:'12px'}}> Añadir empresa</button>
                </div>
            </form>
        :(estado === 3) ?
            <div style={{height: '100%', width: '100%', cursor:'wait', overflowY: 'hidden',}}>
                <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '35px'}}>{mensaje}</p>
            </div>
        :
            <div style={{height: '100%', width: '100%', overflowY: 'hidden',}}>
                <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '30%'}}>Este paso no está implementado</p>
            </div>
    );


    return(
        <Emergente 
            contenido={contenido} 
            keyName={"generarKey"} 
            titulo={"Empresas informática actualizaciones"} 
            activo={true} 
            ocultaAspa = {false}
            alto = {alto}
            ancho = {'600px'}
            cerrar={props.alCerrar}
        ></Emergente>
    );
}

export default KeyEmpresa;
