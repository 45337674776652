import LoadingPopup from "../../Resultados/Loading.Popup";
import BP2022Service from "../../../services/user.service";
import { useEffect, useState } from "react";
import ResumenBusqueda from "../ResumenBusqueda";
import SelectorComposicion from "../../Utilidades/Selector/SelectorComposicion/SelectorComposicion";
import SelectorComboBox from "../../Utilidades/Selector/SelectorComboBox";
import { FormatoFechaDDMMYYYY, GetAntecesorWhithClassName, upperCaseFirstLetter } from "../../../services/utils";
import SelectorUno2 from "../../Utilidades/Selector/SelectorUno2";
import { OpcionesADM, OpcionesCONV, OpcionesDG, OpcionesDISP, OpcionesDISPV, OpcionesESP, OpcionesESPV, OpcionesOTR, OpcionesOTRV, OpcionesPAC } from "../../../services/objsAplicacion";
import SelectorCHK from "../../Utilidades/Selector/SelectorCHK";
import SelectorSNX from "../../Utilidades/Selector/SelectorSNX";
import Acordeon from "../../Fichas/FichaMUH/Apartados/Composicion/Acordeon";
import SelectorEdad from "../../Utilidades/Selector/SelectorEdad/SelectorEdad";
import SelectorEnfRel from "../../Utilidades/Selector/SelectorEnfRel/SelectorEnfRel";
import ItemResumenFiltros from "../../Resultados/FiltrosYBusqueda/ResumenFiltros/ItemResumenFiltros";
import SelectorMulti2 from "../../Utilidades/Selector/SelectorMulti2";
import SelectorTexto from "../../Utilidades/Selector/SelectorTexto";
import SelectorMulti2No from "../../Utilidades/Selector/SelectorMulti2No";
import SelectorNumero from "../../Utilidades/Selector/SelectorNumero";
import SelectorFechas from "../../Utilidades/Selector/SelectorFecha/SelectorFecha";

const FBAvanzadaMUV= (props) => {
    const [datosFormulario, setDatosFormulario] = useState({
        listaPAs: null,
        listaFFs: null,
        listaVias: null,
        listaEnfs: null,
        listaEsps: null,
        listaGTs: null,
        listaLabs: null,
        listaMens: null,
        listaEpis: null,
    });
    const [seleccionFormulario, setSeleccionFormulario] = useState({
        txt: props.txt,
        idsLab: props.idsLab,
        idsGT: props.idsGT,
        pas: props.pas,
        solo: props.solo,
        idVia: props.idVia,
        idFF: props.idFF,
        idEnf: props.idEnf,
        idEspecie: props.idEspecie,
        ops: (props.ops ? props.ops : []),
        uds: props.uds,
        fechaAutoIni: props.fechaAutoIni,
        fechaAutoFin: props.fechaAutoFin,
        fechaBajaIni: props.fechaBajaIni,
        fechaBajaFin: props.fechaBajaFin,
        idsMen: props.idsMen,
        texto: props.texto,
        idEpi: props.idEpi,
        noTexto: props.noTexto,
    })


    useEffect(() => {
        if (datosFormulario.listaPAs === null){
            DescargaDatos();
        }   
    },[]);

    const DescargaDatos = () => {      
        BP2022Service.datosFormularioAvanzadaMUV()
        .then((response) => {
            var df = {
                listaPAs : response.data.Componentes,
                listaLabs: response.data.Laboratorios,
                listaGTs: response.data.Grupos,
                listaVias : response.data.Vias,
                listaFFs : response.data.FormasFarma,
                listaEsps : response.data.Especies,
                listaEnfs : response.data.Enfermedades,
                listaMens: response.data.Mensajes,
                listaEpis: response.data.Epigrafes,
            };
            df.listaPAs.splice(0,0,{Codigo:0, Valor:'Indique principio activo'});
            df.listaLabs.splice(0,0,{Codigo:0, Valor:'Indique laboratorio'});
            df.listaGTs.splice(0,0,{Codigo:0, Valor:'Indique nombre o código de grupo'});
            df.listaEnfs.splice(0,0,{Codigo:0, Valor:'Indique indicación '});
            df.listaEsps.splice(0,0,{Codigo:0, Valor:'Indique especie destino '});
            df.listaVias.splice(0,0,{Codigo:-1, Valor:'Seleccione una vía'},{Codigo:0, Valor:'-'});
            df.listaFFs.splice(0,0,{Codigo:-1, Valor:'Seleccione una forma farmacéutica'},{Codigo:0, Valor:'-'});
            df.listaMens.splice(0,0,{Codigo:0, Valor:'Indique advertencia'});
            df.listaEpis.splice(0,0,{Codigo:-1, Valor:'Seleccione una epígrafe'},{Codigo:0, Valor:'-'});
            setDatosFormulario(df);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };


    const obtenDatoResumen = (opciones) =>{
        var strValores=[]
        for(var i=0; i< opciones.length; i++){
            var itemOpcion = opciones[i];
            if (itemOpcion.tip==="CHK"){
                var itemSel = seleccionFormulario.ops.find(item => item.cod===itemOpcion.cod);
                if (itemSel && itemSel.valores && itemSel.valores.length>0){
                    var strVal = "";
                    for(var j=0; j<itemSel.valores.length; j++){
                        if (j>0) strVal += " o ";
                        else strVal += itemOpcion.tit + ": ";
                        var valSel = itemOpcion.ops.find(val=> val.val === itemSel.valores[j]);
                        strVal += valSel.tit;
                    }
                    if (strVal !== "")
                        strValores.push(strVal);
                }
            }else if (itemOpcion.tip==="SNX"){
                var itemSel = seleccionFormulario.ops.find(item =>item.cod===itemOpcion.cod);
                if (itemSel && itemSel.valor && itemSel.valor !== "" && itemSel.valor !== "X"){
                    if (itemSel.valor === "S"){
                        strValores.push(itemOpcion.tit);
                    }else if (itemSel.valor === "N"){
                        strValores.push("No " + itemOpcion.tit.toLowerCase());
                    }
                }
            }
        }
        var res="";
        if (strValores.length>0){
            var res = strValores[0];
            for (var i=1; i<strValores.length; i++){
                res += "\n" + strValores[i];
            }
        }
        return res;
    }

    const construyeResumenBusqueda = () => {
        var res = [];

        if (seleccionFormulario.txt && seleccionFormulario.txt != ""){
            res.push ({titulo: 'Nombre contiene', valor: seleccionFormulario.txt});
        }

    
        if ((seleccionFormulario.idsLab) && (seleccionFormulario.idsLab.length>0)){
            if (datosFormulario.listaLabs){
                var valor = '';
                for (var i =0; i<seleccionFormulario.idsLab.length; i++){
                    var item = datosFormulario.listaLabs.find(x => x.Codigo === seleccionFormulario.idsLab[i]);
                    if (item){
                        if (valor !== '')
                            valor += ", ";
                        valor += item.Valor;
                    }
                }
                if (valor != '')
                    res.push ({titulo: "Laboratorio/s", valor: valor});
            }
        }    

        if ((seleccionFormulario.idsGT) && (seleccionFormulario.idsGT.length>0)){
            if (datosFormulario.listaGTs){
                var valor = '';
                for (var i =0; i<seleccionFormulario.idsGT.length; i++){
                    var item = datosFormulario.listaGTs.find(x => x.Codigo === seleccionFormulario.idsGT[i]);
                    if (item){
                        if (valor !== '')
                            valor += ", ";
                        valor += item.Valor;
                    }
                }
                if (valor != '')
                    res.push ({titulo: "Grupo/s terapéutico/s ATC", valor: valor});
            }
        }    

/*        if (seleccionFormulario.idLab>0){
            if (datosFormulario.listaLabs){
                var item = datosFormulario.listaLabs.find(x => x.Codigo === seleccionFormulario.idLab);
                if (item)
                    res.push ({titulo: "Laboratorio", valor: item.Valor});
            }
        }
    
        if (seleccionFormulario.idGT!==''){
            if (datosFormulario.listaGTs){
                var item = datosFormulario.listaGTs.find(x => x.Codigo === seleccionFormulario.idGT);
                if (item)
                    res.push ({titulo: "Grupo terapéutico ATC", valor: item.Valor});
            }
        }
*/    
        if (seleccionFormulario.idFF>0){
            if (datosFormulario.listaFFs){
                var item = datosFormulario.listaFFs.find(x => x.Codigo === seleccionFormulario.idFF);
                if (item)
                    res.push ({titulo: "Forma farmacéutica", valor: item.Valor});
            }
        }
    
        if (seleccionFormulario.idVia>0){
            if (datosFormulario.listaVias){
                var item = datosFormulario.listaVias.find(x => x.Codigo === seleccionFormulario.idVia);
                if (item)
                    res.push ({titulo: "Vía", valor: item.Valor});
            }
        }

        var idxEstado = seleccionFormulario.ops.findIndex(op => op.cod==="EST");
        if (idxEstado >= 0){
            var itemEstado=seleccionFormulario.ops[idxEstado];
            if (itemEstado.valores.length>0){
                var strValor = '';
                for (var i=0; i<itemEstado.valores.length; i++){
                    if (i>0) strValor += " o "
                    strValor += OpcionesDG[0].ops.find(op=> op.val === itemEstado.valores[i]).tit;
                }
                res.push({titulo: "Estado", valor: strValor});
            }
        }

        var idxCom = seleccionFormulario.ops.findIndex(op => op.cod==="COM");
        if (idxCom >= 0){
            var itemCom=seleccionFormulario.ops[idxCom];
            if (itemCom.valor==="S"){
                res.push({titulo: "Comercializado", valor: "Sí"});
            }else if (itemCom.valor==="N"){
                res.push({titulo: "Comercializado", valor: "No"});
            }
        }

        if (seleccionFormulario.uds>0){
            res.push ({titulo: "Unidades envase", valor: seleccionFormulario.uds.toString()});
        }
    
        if (seleccionFormulario.fechaAutoIni){
            if (seleccionFormulario.fechaAutoFin){
                res.push ({titulo: "Fecha de alta", valor: 'entre las fechas ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaAutoIni) + ' y ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaAutoFin)});
            }else{
                res.push ({titulo: "Fecha de alta", valor: 'posterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaAutoIni)});
            }
        }else if (seleccionFormulario.fechaAutoFin){
            res.push ({titulo: "Fecha de alta", valor: 'anterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaAutoFin)});
        }


        if (seleccionFormulario.fechaBajaIni){
            if (seleccionFormulario.fechaBajaFin){
                res.push ({titulo: "Fecha de baja", valor: 'entre las fechas ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaBajaIni) + ' y ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaBajaFin)});
            }else{
                res.push ({titulo: "Fecha de baja", valor: 'posterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaBajaIni)});
            }
        }else if (seleccionFormulario.fechaBajaFin){
            res.push ({titulo: "Fecha de baja", valor: 'anterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaBajaFin)});
        }

        if ((seleccionFormulario.idsMen) && (seleccionFormulario.idsMen.length>0)){
            if (datosFormulario.listaMens){
                var valor = '';
                for (var i =0; i<seleccionFormulario.idsMen.length; i++){
                    var item = datosFormulario.listaMens.find(x => x.Codigo === Math.abs(seleccionFormulario.idsMen[i]));
                    if (item){
                        if (valor !== '')
                            valor += ",\n";
                        if (seleccionFormulario.idsMen[i]<0)                            
                            valor += "NO "
                        valor += item.Valor;
                    }
                }
                if (valor != '')
                    res.push ({titulo: "Advertencias/s", valor: valor});
            }
        }    

        if ((seleccionFormulario.pas) && (seleccionFormulario.pas.length>0)){
            if (datosFormulario.listaPAs){
                var titulo = "Composición";
                for (var i =0; i<seleccionFormulario.pas.length; i++){
                    var valor = '';
                    var item = datosFormulario.listaPAs.find(x => x.Codigo === Math.abs(seleccionFormulario.pas[i].codigo));
                    if (item){
                        if (seleccionFormulario.pas[i].codigo<0)
                            valor += "No contiene ";
                        valor += item.Valor;
                        var sales = seleccionFormulario.pas[i].sales;
                        if (sales && sales.length>0){
                            for (var j=0; j<sales.length; j++){
                                if (j>0)
                                    valor += " /"
                                else
                                    valor += "        "
                                valor += " " + sales[j].valor;
                            }
                        }    
                        var cants = seleccionFormulario.pas[i].cants;
                        if (cants && cants.length>0){
                            for (var j=0; j<cants.length; j++){
                                if (j>0)
                                    valor += " /"
                                else
                                    valor += "        "
                                valor += " " + cants[j].des;
                            }
                        }    
                    }
                    res.push({titulo:titulo, valor: valor});
                    titulo = "";
                }
                if (seleccionFormulario.solo){                    
                    res.push({titulo: "", valor: "Únicamente los principios activos seleccionados"});
                }
                else{
                    res.push({titulo: "", valor: "Puede contener principios activos adicionales"});
                }
            }    
        }

        if (seleccionFormulario.texto && seleccionFormulario.texto.trim().length>2){
            if (datosFormulario.listaEpis){
                var aux = "Algún epígrafe contiene el texto ";
                if (seleccionFormulario.noTexto)
                    aux = "Ningún epígrafe contiene el texto";
                if (seleccionFormulario.idEpi && seleccionFormulario.idEpi>0){
                    var item = datosFormulario.listaEpis.find(x => x.Codigo === seleccionFormulario.idEpi);
                    if (item){
                        if (seleccionFormulario.noTexto)
                            aux = item.Valor.trim() + " no contiene el texto ";
                        else
                            aux = item.Valor.trim() + " contiene el texto ";
                    }
                }
                aux += "\"" + seleccionFormulario.texto + "\"";
                res.push({titulo: "Búsquedas por texto", valor: aux});
            }
        }

        var str = obtenDatoResumen(OpcionesOTRV);
        if (str !== "")
            res.push({titulo: "Tipo de medicamento", valor:str});

        var str = obtenDatoResumen(OpcionesDISPV);
        if (str !== "")
            res.push({titulo: "Dispensación", valor:str});

        var str = obtenDatoResumen(OpcionesESPV);
        if (str !== "")
            res.push({titulo: "Advertencias especiales", valor:str});

        var str = obtenDatoResumen(OpcionesCONV);
        if (str !== "")
            res.push({titulo: "Conservación", valor:str});

        if (seleccionFormulario.idEnf>0){
            if (datosFormulario.listaEnfs){
                var item = datosFormulario.listaEnfs.find(x => x.Codigo === seleccionFormulario.idEnf);
                if (item)
                    res.push ({titulo: "Indicación", valor: item.Valor});
            }
        }
        if (seleccionFormulario.idEspecie>0){
            if (datosFormulario.listaEsps){
                var item = datosFormulario.listaEsps.find(x => x.Codigo === seleccionFormulario.idEspecie);
                if (item)
                    res.push ({titulo: "Especie destino", valor: item.Valor});
            }
        }
    
        return res;
    }

    var resumen = construyeResumenBusqueda(seleccionFormulario);

    const hayBusqueda = (seleccionFormulario.pas && seleccionFormulario.pas.length>0)
        || (seleccionFormulario.txt && seleccionFormulario.txt.length>=2)
        || (seleccionFormulario.idsLab && seleccionFormulario.idsLab.length>0)
        || (seleccionFormulario.idsGT && seleccionFormulario.idsGT.length>0)
        || (seleccionFormulario.idEnf>0)
        || (seleccionFormulario.idEspecie>0)
        || (seleccionFormulario.idFF>0)
        || (seleccionFormulario.idVia>0)
        || (seleccionFormulario.ops && seleccionFormulario.ops.length>0)
        || (seleccionFormulario.uds > 0)
        || (seleccionFormulario.fechaAutoIni) || (seleccionFormulario.fechaAutoFin)
        || (seleccionFormulario.fechaBajaIni) || (seleccionFormulario.fechaBajaFin)
        || (seleccionFormulario.idsMen && seleccionFormulario.idsMen.length>0)
        || (seleccionFormulario.texto && seleccionFormulario.texto.length>2)
        
    const clickBuscar = (e) =>{
        if (hayBusqueda){
            var newpagina = "BAvanzadaMUV";

            props.cambiaPagina({
                newpagina: newpagina,
                txt: seleccionFormulario.txt,
                idsLab: seleccionFormulario.idsLab,
                idsGT: seleccionFormulario.idsGT,
                idEnf: seleccionFormulario.idEnf,
                idEspecie: seleccionFormulario.idEspecie,
                pas: seleccionFormulario.pas,
                solo: seleccionFormulario.solo,
                idVia: seleccionFormulario.idVia,
                idFF: seleccionFormulario.idFF,
                ops: seleccionFormulario.ops,
                uds: seleccionFormulario.uds,
                fechaAutoFin: seleccionFormulario.fechaAutoFin,
                fechaAutoIni: seleccionFormulario.fechaAutoIni,
                fechaBajaFin: seleccionFormulario.fechaBajaFin,
                fechaBajaIni: seleccionFormulario.fechaBajaIni,
                idsMen: seleccionFormulario.idsMen,
                texto: seleccionFormulario.texto,
                idEpi: seleccionFormulario.idEpi,
                noTexto: seleccionFormulario.noTexto,
            })
        }
    }

    const BorraBusqueda = (e) =>{
        const formularioVacio={
            pas: [],
            txt: '',
            idsLab: [],
            idsGT: [],
            idEnf: 0,
            idEspecie: 0,
            solo: "S",
            idVia: 0,
            idFF: 0,
            ops: [],
            uds: 0,
            fechaAutoFin: null,
            fechaAutoIni: null,
            fechaBajaFin: null,
            fechaBajaIni: null,
            idsMen: [],
            texto: '',
            idEpi: 0,
            noTexto: false,
        }
        setSeleccionFormulario(formularioVacio);
        var inputs = document.getElementsByClassName('inputSelector');
        if (inputs){
            for (var i=0; i<inputs.length; i++){
                inputs[i].value = "";
                const itemContainer = GetAntecesorWhithClassName(inputs[i], 'custom-select-container');
                if (itemContainer){
                    itemContainer.classList.remove('active');
                }
            }
        }
    }

    const copiaFechas = (origen,destino) =>{
        destino.fechaAutoFin = origen.fechaAutoFin;
        destino.fechaAutoIni = origen.fechaAutoIni;
        destino.fechaBajaFin = origen.fechaBajaFin;
        destino.fechaBajaIni = origen.fechaBajaIni;
    }

    const CambioFechaAuto= (inicio, fin) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.fechaAutoIni = inicio;
        nuevaSeleccion.fechaAutoFin = fin;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioFechaBaja= (inicio, fin) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.fechaBajaIni = inicio;
        nuevaSeleccion.fechaBajaFin = fin;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioEnfermedad = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idEnf = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioEspecie = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idEspecie = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const BorraEnfermedad = () =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idEnf = 0;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const BorraEspecie = () =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idEspecie = 0;
        setSeleccionFormulario(nuevaSeleccion);
    }

    /*
    const CambioLaboratorio = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idLab = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const BorraLaboratorio = () =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idLab = 0;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioGrupo = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idGT= nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const BorraGrupo = () =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idGT = '';
        setSeleccionFormulario(nuevaSeleccion);
    }
    */

    const AddLaboratorio = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        if (!nuevaSeleccion.idsLab) { nuevaSeleccion.idsLab = []};
        nuevaSeleccion.idsLab.push(nuevovalor);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveLaboratorio = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        var i = nuevaSeleccion.idsLab.indexOf(parseInt(valor));
        nuevaSeleccion.idsLab.splice(i,1);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddGrupo = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        if (!nuevaSeleccion.idsGT) { nuevaSeleccion.idsGT = []};
        nuevaSeleccion.idsGT.push(nuevovalor.trim());
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveGrupo = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        var i = nuevaSeleccion.idsGT.indexOf(valor.trim());
        nuevaSeleccion.idsGT.splice(i,1);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddAdvertencia = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        if (!nuevaSeleccion.idsMen) { nuevaSeleccion.idsMen = []};
        if (!nuevaSeleccion.idsMen.includes(nuevovalor)){
            nuevaSeleccion.idsMen.push(nuevovalor);
            setSeleccionFormulario(nuevaSeleccion);
        }
    }

    const NiegaAdvertencia = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        if (!nuevaSeleccion.idsMen) { nuevaSeleccion.idsMen = []};
        if (nuevaSeleccion.idsMen.includes(nuevovalor)){
            var idx = nuevaSeleccion.idsMen.indexOf(nuevovalor);
            nuevaSeleccion.idsMen.splice(idx,1,nuevovalor * -1);
            setSeleccionFormulario(nuevaSeleccion);
        }
    }

    const RemoveAdvertencia = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        var i = nuevaSeleccion.idsMen.indexOf(parseInt(valor));
        nuevaSeleccion.idsMen.splice(i,1);
        setSeleccionFormulario(nuevaSeleccion);
    }


    const CambiaTxt = (e) =>{
        const item = e.target;
        if (item){
            const valor = item.value;
            const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
            nuevaSeleccion.txt = valor;
            setSeleccionFormulario(nuevaSeleccion);
        }
    }

    const AddSal = (idPA, idSal, des) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (pa){
            if (!pa.sales){
                pa.sales = []
            }
            var sal = pa.sales.find(item => item.codigo === idSal);
            if (!sal){
                pa.sales.push({codigo:idSal, valor: des});
            }
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveSal = (idPA, idSal) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (pa){
            if (!pa.sales){
                pa.sales = []
            }
            pa.sales = pa.sales.filter(item => item.codigo !== idSal);
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddCant = (idPA, idUnd, cantidad, des) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (pa){
            if (!pa.cants){
                pa.cants = []
            }
            var cant = pa.cants.find(item => item.cod === idUnd && item.cant === cantidad);
            if (!cant){
                pa.cants.push({cod: idUnd, cant: cantidad, des: des});
            }
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveCant = (idPA, idUnd, cantidad) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (pa){
            if (!pa.cants){
                pa.cants = []
            }
            pa.cants = pa.cants.filter(item => item.cod !== idUnd || item.cant !== cantidad);
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddPA = (idPA) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (!pa){
            nuevaSeleccion.pas.push({
                codigo: idPA,
                sales: [],
                cants: [],
            })
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const UpdatePA = (idPA, negado) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => Math.abs(item.codigo)===Math.abs(idPA));
        if (pa){
            if (negado){
                pa.codigo = Math.abs(idPA) * (-1);
                pa.sales = [];
                pa.cants = [];
            }else{
                pa.codigo = Math.abs(idPA);
            }
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemovePA = (idPA) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.pas = nuevaSeleccion.pas.filter(item => Math.abs(item.codigo)!==Math.abs(idPA));
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambiaPASolo = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.solo = valor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioVia = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idVia = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioFF = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idFF = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioUds = (nuevonumero) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.uds = nuevonumero;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioBusquedaTexto = (nuevoepi, nuevotexto, negado) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.idEpi = nuevoepi;
        nuevaSeleccion.texto = nuevotexto;
        nuevaSeleccion.noTexto = negado;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambiaValorSNX = (valorSNX, idSNX) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        if (!nuevaSeleccion.ops)
            nuevaSeleccion.ops = [];
        var item = nuevaSeleccion.ops.find(it => it.cod===idSNX);
        if (item){
            if (valorSNX !== 'X'){
                item.valor = valorSNX;
            }else{
                var idx = nuevaSeleccion.ops.indexOf(item);
                nuevaSeleccion.ops.splice(idx,1);
            }
        }else{
            if (valorSNX !=='X'){
                nuevaSeleccion.ops.push({cod:idSNX, valor:valorSNX});
            }
        }
        setSeleccionFormulario(nuevaSeleccion);
    }


    const CambiaValorCHK = (valoresCHK,idCHK) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        if (!nuevaSeleccion.ops)
            nuevaSeleccion.ops = [];
        var item = nuevaSeleccion.ops.find(it => it.cod===idCHK);
        if (item){
            if (valoresCHK.length>0){
                item.valores = valoresCHK;
            }else{
                var idx = nuevaSeleccion.ops.indexOf(item);
                nuevaSeleccion.ops.splice(idx,1);
            }
        }else{
            if (valoresCHK.length>0){
                var newitem = {
                    cod: idCHK,
                    valores: valoresCHK,
                }
                nuevaSeleccion.ops.push(newitem);
            }            
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const KeyDownForm = (e) =>{
        var keyCode = e.keyCode;
        if (keyCode === 13){
            clickBuscar(e);
        }
    }


    const listaCodValorLabs   = (datosFormulario.listaLabs) ? datosFormulario.listaLabs.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorGTs    = (datosFormulario.listaGTs)  ? datosFormulario.listaGTs.map(it =>  ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorPAs    = (datosFormulario.listaPAs) ? datosFormulario.listaPAs.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorFFs    = (datosFormulario.listaFFs) ? datosFormulario.listaFFs.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorVias   = (datosFormulario.listaVias) ? datosFormulario.listaVias.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorEnfs   = (datosFormulario.listaEnfs) ? datosFormulario.listaEnfs.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorEsps   = (datosFormulario.listaEsps) ? datosFormulario.listaEsps.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorMens   = (datosFormulario.listaMens) ? datosFormulario.listaMens.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorEpis   = (datosFormulario.listaEpis) ? datosFormulario.listaEpis.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];

    const clasedivmodulefilters = "cover cover__filters cover__filters__2 cover__filters__2--1 cover__filters__2--1__50";

    const HTMLOpcionesDG = OpcionesDG.map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );


    const HTMLOpcionesOTR1 = OpcionesOTRV.slice(0,4).map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );
/*
    const HTMLOpcionesOTR2 = OpcionesOTRV.slice(4).map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
                estiloDiv = {{marginBottom: '20px'}}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
                estiloDiv = {{marginBottom: '20px'}}
            />
        : <div></div>
    );
*/

    const HTMLOpcionesDISP = OpcionesDISPV.slice(0,3).map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );

    const HTMLOpcionesDISP2 = OpcionesDISPV.slice(3).map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );

    const HTMLOpcionesESPV = OpcionesESPV.map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );

    const HTMLOpcionesCONV = OpcionesCONV.map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );

    const ancho = window.innerWidth;
    const isMobile = (ancho<768);

    const estiloLab = (isMobile) ? {} : {width: '50%'} ;
    const estiloFF = (isMobile) ? {} : {width: '40%'} ;
    const estiloVia = (isMobile) ? {} : {width: '25%'} ;



    const HTMLDatosGenContenido = (
        <div>
            <div class="cover cover__filters cover__filters__code" >
                <div class="coverModule">
                    <h3 class="title fs fs16 greyColor2">Nombre</h3>
                    <div class="module module__filters">
                        <div class="box box__inputs">
                            <div class="cover">
                                <input type="text" name="" value={seleccionFormulario.txt} placeholder="Nombre del medicamento"   onChange={(e) => CambiaTxt(e)}  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class={clasedivmodulefilters} >
                <div class="module module__filters" style={estiloLab}>
                    <h3 class="title fs fs16 greyColor2" >Laboratorio</h3>
                    <SelectorMulti2 lista = {listaCodValorLabs} 
                                idHTML="FBAvanzadaMUHLLabs" 
                                valuesSelected={seleccionFormulario.idsLab}
                                alAnadir={AddLaboratorio} 
                                alBorrar={RemoveLaboratorio} 
                                ancho="100%"
                    ></SelectorMulti2>
                </div>
                <div class="module module__filters"  style={{width: '50%'}}>
                    <h3 class="title fs fs16 greyColor2" >Grupo terapéutico ATC</h3>
                    <SelectorMulti2 lista = {listaCodValorGTs} 
                                idHTML="FBAvanzadaMUHLGTs" 
                                valuesSelected={seleccionFormulario.idsGT} 
                                alAnadir={AddGrupo} 
                                alBorrar={RemoveGrupo} 
                                ancho="100%"
                                idEsStr={true}
                    ></SelectorMulti2>
                </div>
            </div>
            <div class="cover cover__filters cover__filters__4" >
                <div class="module module__selects" style={estiloFF}>
                    <h3 class="title fs fs16 greyColor2" style={{marginBottom:'12px'}}>Forma farmacéutica</h3>
                    <SelectorComboBox lista = {listaCodValorFFs} 
                            idHTML="FBAvanzadaFF" 
                            valueSelected={seleccionFormulario.idFF} 
                            alCambiar={CambioFF} 
                            ancho="100%"
                            maxLengthNormal={32}
                    ></SelectorComboBox>
                </div>
                <div class="module module__filters" style={estiloVia}>
                    <h3 class="title fs fs16 greyColor2"  style={{marginBottom:'12px'}}>Vía de administración</h3>
                    <SelectorComboBox lista = {listaCodValorVias} 
                            idHTML="FBAvanzadaVia" 
                            valueSelected={seleccionFormulario.idVia} 
                            alCambiar={CambioVia} 
                            ancho="100%"
                            maxLengthNormal={16}
                    ></SelectorComboBox>
                </div>
                <SelectorNumero 
                            alCambiar={CambioUds} 
                            num={seleccionFormulario.uds} 
                            valorVacio={0}
                            titulo = "Unidades por envase"
                ></SelectorNumero>
            </div>
            <div class="cover cover__filters cover__filters__4" >
                {HTMLOpcionesDG}
            </div>
            <div class={clasedivmodulefilters} >
                <SelectorFechas 
                            titulo="Fecha de alta" 
                            alCambiar={CambioFechaAuto} 
                            inicio={seleccionFormulario.fechaAutoIni} 
                            fin={seleccionFormulario.fechaAutoFin}
                            idHTML = "FBAH_FAuto"
                            subtitulodesde = "Posterior a"
                            subtitulohasta = "Anterior a"
                ></SelectorFechas>
                <SelectorFechas 
                            titulo="Fecha de baja" 
                            alCambiar={CambioFechaBaja} 
                            inicio={seleccionFormulario.fechaBajaIni} 
                            fin={seleccionFormulario.fechaBajaFin}
                            idHTML = "FBAH_FBaja"
                            subtitulodesde = "Posterior a"
                            subtitulohasta = "Anterior a"
                ></SelectorFechas>
            </div>
        </div>
    );

    const HTMLDatosCompos = (
            <SelectorComposicion lista = {listaCodValorPAs} 
                idHTML="FBAvanzadaCompos" 
                valuesSelected={seleccionFormulario.pas} 
                pasolo={seleccionFormulario.solo}
                alAnadirPA={AddPA} 
                alBorrarPA={RemovePA} 
                alUpdatePA={UpdatePA}
                alAnadirSal={AddSal} 
                alBorrarSal={RemoveSal} 
                alAnadirCant={AddCant} 
                alBorrarCant={RemoveCant} 
                alCambiarPASolo={CambiaPASolo}
                ancho="100%"
                titulo={""}
                muestraChecks={true}
                nombreComponentes = {"principios activos"}
                tipo={"H"}
            ></SelectorComposicion>
    );

    const HTMLDatosOtr = (
        <div>
            <div class="cover cover__filters cover__filters__4" style={{marginBottom:'20px'}}>
                {HTMLOpcionesOTR1}
            </div>
        </div>
    );

    const HTMLDatosDisp = (
        <div>
            <div class="cover cover__filters cover__filters__2" style={{marginBottom:'20px'}}>
                {HTMLOpcionesDISP}
            </div>
            <div class="cover cover__filters cover__filters__2" style={{marginBottom:'20px'}}>
                {HTMLOpcionesDISP2}
                <div></div>
            </div>
        </div>
    );

    const HTMLDatosEsp = (
        <div class="cover cover__filters cover__filters__4" >
            {HTMLOpcionesESPV}
        </div>
    );

    const HTMLDatosCon = (
        <div class="cover cover__filters cover__filters__4" >
            {HTMLOpcionesCONV}
        </div>
    );

    const HTMLAdvertenciasContenido = (
        <div>
            <div className="cover cover__filters" >
                <SelectorMulti2No lista = {listaCodValorMens} 
                            idHTML="FBAvanzadaMUHMAs" 
                            valuesSelected={seleccionFormulario.idsMen}
                            alAnadir={AddAdvertencia} 
                            alBorrar={RemoveAdvertencia}
                            alNegar={NiegaAdvertencia}
                            titulo={"Advertencias especiales"} 
                            ancho="100%"
                            maxLengthNormal = {85}
                ></SelectorMulti2No>
            </div>
        </div>
    );

    const HTMLDatosBusquedaTexto = (
        <SelectorTexto listaEpigrafes = {listaCodValorEpis} 
            idHTML="FBAvanzadaMUH_BTexto" 
            texto = {seleccionFormulario.texto}
            idEpi = {seleccionFormulario.idEpi}
            negado = {seleccionFormulario.noTexto}
            placeholder = "Introduzca el texto a buscar"
            tituloTexto = "Términos a buscar"
            tituloEpigrafe = "Buscar en"
            alCambiar={CambioBusquedaTexto} 
            ancho="100%"
        ></SelectorTexto>
    );

    const HTMLDatosEspInd = (
        <div class="cover cover__filters cover__filters__2 cover__filters__2--1 cover__filters__2--1__50" >
            <div class="module module__filters"  style={estiloLab}>
                <h3 class="title fs fs16 greyColor2" >Especie destino</h3>
                <SelectorUno2 lista = {listaCodValorEsps} 
                            idHTML="FBEspecieIndicacionLEspecie" 
                            valueSelected={seleccionFormulario.idEspecie} 
                            alCambiar={CambioEspecie} 
                            alBorrar={BorraEspecie} 
                            ancho="100%"
                ></SelectorUno2>
            </div>
            <div class="module module__filters"  style={{width: '50%'}}>
                <h3 class="title fs fs16 greyColor2" >Indicación</h3>
                <SelectorUno2 lista = {listaCodValorEnfs} 
                            idHTML="FBEspecieIndicacionLEnfermedad" 
                            valueSelected={seleccionFormulario.idEnf} 
                            alCambiar={CambioEnfermedad} 
                            alBorrar={BorraEnfermedad} 
                            ancho="100%"
                ></SelectorUno2>
            </div>
        </div>
    );

    const estiloBotonesSup = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'space-between'} : {height:'76px', width:'100%', justifyContent: 'space-between', visibility:'hidden'})
    const estiloBotonesInf = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'center'} : {height:'76px', width:'100%', justifyContent: 'center', display:'none'})

    return(
        <div class="content">
            { (datosFormulario.listaPAs === null) ?
                <LoadingPopup titulo="Un momento" texto="Preparando formulario" esPorcentaje={true}></LoadingPopup>
            :
                <form class="content__results content__results__filters" id="main-filter"  onSubmit={(e) => e.preventDefault()}  onKeyDown={(e) => KeyDownForm(e)} tabIndex="0">
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesSup}>
                        <span class="button button__arrow button__green noUppercase reset-form" data-form="#main-filter" title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} style={{visibility:'hidden'}} >
                            Vaciar búsqueda
                        </span>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                        <span class="button button__arrow button__green reset-form" data-form="#main-filter" style={{textTransform:'none', fontSize: '14px', width:'146px'}} title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} >
                            Vaciar búsqueda
                        </span>
                    </div>
                    <div class="column">
                        <Acordeon 
                            activo={true}
                            titulo = "DATOS GENERALES"
                            contenido = {HTMLDatosGenContenido}
                            tipo = {3}
                        />

                        <Acordeon 
                            activo={false}
                            titulo = "COMPOSICIÓN"
                            contenido = {HTMLDatosCompos}
                            tipo = {3}
                        />

                        <Acordeon 
                            activo={false}
                            titulo = "TIPO DE MEDICAMENTO"
                            contenido = {HTMLDatosOtr}
                            tipo = {3}
                        />

                        <Acordeon 
                            activo={false}
                            titulo = "CONDICIONES DE DISPENSACIÓN"
                            contenido = {HTMLDatosDisp}
                            tipo = {3}
                        />

                        <Acordeon 
                            activo={false}
                            titulo = "ADVERTENCIAS ESPECIALES"
                            contenido = {HTMLAdvertenciasContenido}
                            tipo = {3}
                        />


                        <Acordeon 
                            activo={false}
                            titulo = "CONDICIONES DE CONSERVACIÓN"
                            contenido = {HTMLDatosCon}
                            tipo = {3}
                        />

                        <Acordeon 
                            activo={false}
                            titulo = "ESPECIE DESTINO / INDICACIÓN"
                            contenido = {HTMLDatosEspInd}
                            tipo = {3}
                        />
                        <Acordeon 
                            activo={false}
                            titulo = "TEXTO"
                            contenido = {HTMLDatosBusquedaTexto}
                            tipo = {3}
                        />

                    </div>
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesInf}>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                    </div>
                    <ResumenBusqueda condiciones={resumen}></ResumenBusqueda>
                </form>
            }
        </div>
    );
}
    
export default FBAvanzadaMUV;
    