import BP2022Service from "../../../../../services/user.service";
import { useEffect, useState } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import GrupoCamposExportables from "./GrupoCamposExportable";
import LoadingPopup from "../../../Loading.Popup";
import { AddClassNameToClassItems, numberWithCommas, PerfilUsuario } from "../../../../../services/utils";
import Emergente from "../../../../Fichas/FichaMUH/Apartados/Cismed/Emergente";


const CamposExportacion= (props) => {
    const [listasPosibles, setListasPosibles] = useState([]);
    const [listasResultados, setListasResultados] = useState(props.listasResultados);
    const [codTipoExporta, setCodTipoExporta] = useState(props.codTipoExporta);
    const [codsSeleccionados, setCodsSeleccionados] = useState(null);
    const [datosAExportar, setDatosAExportar] = useState({
        headers: [],
        data: [],
    });
    const [loading, setLoading] = useState(false);
    const [txtExportar, setTxtExportar] = useState("Exportar");
    const [txtCancelar, setTxtCancelar] = useState("Cancelar");
//    const [setMuestraEmergenteMaxRegistros, setMuestraEmergenteMaxRegistros] = useState(false);
    const maxRegs = 5000;



    var listaPosibles  = ((listasPosibles.find(item => item.codTipoExporta === codTipoExporta)) ? listasPosibles.find(item => item.codTipoExporta === codTipoExporta).lista : null);
    var listaResultados = ((listasResultados.find(item => item.codTipoExporta === codTipoExporta)) ? listasResultados.find(item => item.codTipoExporta === codTipoExporta).lista : null);


    var numTotalCampos = 0;
    if (listaPosibles){
        for (var i=0; i<listaPosibles.length; i++){
            numTotalCampos += listaPosibles[i].Campos.length;
        }
    }

    var literalTitulo = "Selecciona los campos a exportar"
    if (numTotalCampos===0)
        literalTitulo = "No existen campos exportables para este tipo de registros";

    if (numTotalCampos>0)

    //var maxCampos = 100;
    var maxCampos = (codTipoExporta==="MUH") ? 7 : 5;

    


    useEffect(() => {
        if (listaPosibles === null)
        {
            DescargaDatos();
        }        
    },[codTipoExporta]);


    const DescargaDatos = () => {      
        BP2022Service.obtenCamposExportables(codTipoExporta, props.codBusqueda)
        .then((response) => {
            var lp = {
                codTipoExporta : codTipoExporta,
                lista : response.data,
            };
            const nuevasListasPosibles = JSON.parse(JSON.stringify(listasPosibles));
            nuevasListasPosibles.push(lp);
            /*var nuevaSeleccion=[];
            for (var i=0; i<2; i++){
                if(lp.lista[0].Campos[i])
                    nuevaSeleccion.push(lp.lista[0].Campos[i].CodCampo);
            }
            setCodsSeleccionados(nuevaSeleccion);*/
            setListasPosibles(nuevasListasPosibles);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
    
    const clickExportar = (e) =>{
        if (codTipoExporta && codTipoExporta != "" && listaResultados && listaResultados.length>0 && codsSeleccionados && codsSeleccionados.length>0){
            if (listaResultados.length<=maxRegs || PerfilUsuario() === "CON" ){
                setLoading(true)
                BP2022Service.obtenRegistrosAExportar(codTipoExporta, listaResultados, codsSeleccionados, '', props.codBusqueda, props.aux1, props.aux2)
                .then((response) => {
                    const nuevosDatosAExportar = {
                        headers: response.data.cabeceras,
                        data: response.data.datos,
                    }
                    setLoading(false);
                    setDatosAExportar(nuevosDatosAExportar);
                    setTxtExportar("Volver a generar");
                    setTxtCancelar("Volver al listado")
                })
                .catch(function (error) {
                    console.log('Error', error.message);
                    setLoading(false);
                });
            }
            else{
                abreEmergenteMaxRegs();
            }
        }
    }

    const checkCampo= (e, valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(codsSeleccionados));
        var activo = e.target.checked;
        if (activo){
            if (!nuevaSeleccion.includes(valor)){
                nuevaSeleccion.push(valor);
            }
        }
        if (!activo){
            if (nuevaSeleccion.includes(valor)){
                var idx = nuevaSeleccion.indexOf(valor);
                nuevaSeleccion.splice(idx,1);
            }
        }
        setCodsSeleccionados(nuevaSeleccion);
    }

    const disabledAdd = (codsSeleccionados && codsSeleccionados.length>=maxCampos && PerfilUsuario() !== "CON");

    if (!codsSeleccionados && listaPosibles){
        if (codTipoExporta === "MUH"){
            var nuevaSeleccion=[];
            nuevaSeleccion.push("CNMUH");
            nuevaSeleccion.push("NOMMUH");
            setCodsSeleccionados(nuevaSeleccion);
        }else if (codTipoExporta === "MUV"){
            var nuevaSeleccion=[];
            nuevaSeleccion.push("CNMUV");
            nuevaSeleccion.push("NOMMUV");
            setCodsSeleccionados(nuevaSeleccion);
        }else if (codTipoExporta === "PPF"){
            var nuevaSeleccion=[];
            nuevaSeleccion.push("CNPPF");
            nuevaSeleccion.push("NOMPPF");
            setCodsSeleccionados(nuevaSeleccion);
        }else if (codTipoExporta === "FM"){
            var nuevaSeleccion=[];
            nuevaSeleccion.push("CNFM");
            nuevaSeleccion.push("NOMFM");
            setCodsSeleccionados(nuevaSeleccion);
        }else if (codTipoExporta === "PA"){
            var nuevaSeleccion=[];
            nuevaSeleccion.push("NOMPA");
            setCodsSeleccionados(nuevaSeleccion);
        }else if (codTipoExporta === "EXT"){
            var nuevaSeleccion=[];
            nuevaSeleccion.push("NOMEXT");
            setCodsSeleccionados(nuevaSeleccion);
        }else{
            setCodsSeleccionados([]);
        }
    }

    const HTMLGruposExportables = (
        (listaPosibles) ?
            listaPosibles.map(grupo => 
                ((grupo.Campos && grupo.Campos.length>0) ?
                    <div class="module module__checks module__checks__limit" >
                        <GrupoCamposExportables 
                            codGrupo={grupo.CodGrupo}
                            titulo = {grupo.Titulo}
                            campos = {grupo.Campos}
                            onCheck = {checkCampo}
                            seleccionados = {codsSeleccionados}
                            activo = {listaPosibles.indexOf(grupo) === 0}
                            disabledAdd = {disabledAdd}
                        />
                    </div>
                :
                    <div></div>
                )
            )
        :
            <div></div>
    );

    function getTipoFromCodTipo(codTipo){
        var res = "";
        switch (codTipo){
            case "MUH":
                res = "Medicamentos uso humano";
            break;
            case "MUV":
                res = "Medicamentos veterinarios";
            break;
            case "PPF":
                res = "Productos de parafarmacia";
            break;
            case "PA":
                res = "Principios activos";
            break;
            case "FM":
                res = "Formulación magistral";
            break;
            case "ING":
                res = "Ingredientes";
            break;
            case "EXT":
                res = "Medicamentos extranjeros";
            break;
            case "PAT":
                res = "Enfermedades";
            break;
        }
        return res;
    }

    const cambiaTab = (codTab) =>{
        if (codTab !== codTipoExporta)
            setCodsSeleccionados(null);
        setCodTipoExporta(codTab);
    }

    const abreEmergenteMaxRegs = () => {
        //e.preventDefault();
        AddClassNameToClassItems(modalKeyName, null, 'active');
    }

    const tiposRegs = [];

    for (var j=0; j<listasResultados.length; j++){
        tiposRegs.push({
            cod: listasResultados[j].codTipoExporta,
            texto: getTipoFromCodTipo(listasResultados[j].codTipoExporta),
            clase: (codTipoExporta === listasResultados[j].codTipoExporta) ? "active" : "",
        })
    }



    const HTMLTabs = (
        <div class="section section__tabs" style={{marginTop:'0px'}}>
            <div class="container" >
                <ul class="tabs" >
                    {tiposRegs.map(t => <li class={t.clase} onClick={(e) => cambiaTab(t.cod)}>{t.texto}</li>)}
                </ul>
            </div>
        </div>
    );
    /* style={{marginTop:'0px'}}*/

    const modalTitulo = 'Número máximo de registros excedido';
    const modalContent = (
        <div>
            <p>
                El listado generado supera el límite máximo de registros que pueden exportarse
                (máximo {numberWithCommas(maxRegs)}). Puede volver a generar el listado limitando los criterios de búsqueda 
                de forma que obtenga un listado con un menor número de registros.
            </p>
            <p>
                En caso de que necesite exportar una cantidad superior de información, le rogamos se ponga 
                en contacto con el Consejo General en el correo electrónico <a href="congral@redfarma.org">congral@redfarma.org</a>.
            </p>
        </div>
    );

    const modalKeyName="ModalMaxRegsExporta"

    return (
        <div>
            {HTMLTabs}
            <section class="section section__buscador section__buscador__busqueda" style={{marginTop:'30px'}}>
                {(loading) ?
                    <LoadingPopup></LoadingPopup>      
                :
                    <Emergente contenido={modalContent} keyName={modalKeyName} titulo={modalTitulo} alto='280px'></Emergente>
                }
                <div class="container container__column container__column__favoritos" > 
                    <div class="content" style={{borderTopWidth: '1px'}} >
                        <div class="content__results content__results__filters" id="main-filter"  onSubmit={(e) => e.preventDefault()}>
                            <div class="module module__buttons" style={{paddingTop:'10px', paddingBottom:'0px'}}>
                                <h3 class="fs fs14 fw fw400 purpleColor">{listaResultados.length} registros</h3>
                            </div>
                            <div class="module module__buttons" style={{paddingTop:'10px'}}>
                                <h2 class="fs fs20 fw fw400 blackColor">{literalTitulo}</h2>
                            </div>
                            <div class="column module__checks__limit__wrap" >                       
                                <div class="cover cover__filters cover__filters__info" >
                                    <div class="module" >
                                        {(numTotalCampos>maxCampos)?
                                        <div class="box" >
                                            <svg width="20px" height="20px" viewBox="0 0 20 20" >
                                                <g id="desarollo_v3" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <g transform="translate(-247.000000, -863.000000)">
                                                        <g id="Group-61-Copy" transform="translate(247.000000, 863.000000)">
                                                            <circle id="Oval-Copy-7" stroke="#7EB04B" stroke-width="2" cx="10" cy="10" r="9"></circle>
                                                            <g id="Group-60" transform="translate(9.000000, 5.000000)" fill="#7EB04B" class="info" >
                                                                <rect id="Rectangle" x="0" y="0" width="2" height="5"></rect>
                                                                <rect id="Rectangle" x="0" y="7" width="2" height="2"></rect>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg> 
                                            Puedes seleccionar hasta {maxCampos.toString()} campos. En caso de querer cambiar un campo por otro, desmarca previamente el que desees eliminar
                                        </div>
                                        :
                                        <div></div>
                                        }
                                    </div>
                                </div>
                                {HTMLGruposExportables}
                                <div class="mySearch mySearch__columns" >
                                    <div class="module module__buttons module__buttons__buscar">
                                        <button class="button button__arrow button__purple noUppercase" title="Guardar" onClick={e => clickExportar(e)} style={{marginRight:'10px'}}>{txtExportar}</button>
                                        <button class="button button__arrow button__grey noUppercase" title="Salir" onClick={e => props.alSalir(false)} style={{marginLeft:'10px'}}>{txtCancelar}</button>
                                    </div>
                                    {(datosAExportar.data && datosAExportar.data.length>0 && datosAExportar.headers && datosAExportar.headers.length>0) ?
                                        <div class="cover__filters__info">
                                        <div class="module" style={{justifyContent: 'center'}}>
                                            <CSVDownload data={datosAExportar.data} headers={datosAExportar.headers} separator=";" filename="BotPlusExport.csv" >
                                            </CSVDownload>
                                            <div class="box"style={{justifyContent: 'center'}}>
                                            <svg width="20px" height="20px" viewBox="0 0 20 20" >
                                                <g id="desarollo_v3" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <g id="NUEVA-NECESIDAD!-Pintar-pantalla-buscadores-favoritos" transform="translate(-247.000000, -863.000000)">
                                                        <g id="Group-61-Copy" transform="translate(247.000000, 863.000000)">
                                                            <circle id="Oval-Copy-7" stroke="#7EB04B" stroke-width="2" cx="10" cy="10" r="9"></circle>
                                                            <g id="Group-60" transform="translate(9.000000, 5.000000)" fill="#7EB04B" class="info" >
                                                                <rect id="Rectangle" x="0" y="0" width="2" height="5"></rect>
                                                                <rect id="Rectangle" x="0" y="7" width="2" height="2"></rect>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg> 
                                            Si el archivo no se descarga automáticamente pulse sobre el siguiente botón
                                            </div>
                                            <div class="module module__buttons module__buttons__buscar" style={{paddingTop: '10px'}}>
                                            <CSVLink data={datosAExportar.data} headers={datosAExportar.headers} separator=";" decimalSeparator="," filename="BotPlusExport.csv" >
                                                <button class="button button__arrow button__purple noUppercase" title="Descargar">Descargar archivo</button>
                                            </CSVLink>
                                            </div>
                                        </div>
                                        </div>
                                    :
                                        <div></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
};

export default CamposExportacion;
/*
                                            <CSVDownload data={datosAExportar.data} headers={datosAExportar.headers} separator=";" filename="BotPlusExport.csv" >
                                            </CSVDownload>
*/