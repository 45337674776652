import React, { useState, useEffect } from "react";
import LogoCabecera from "./LogoCabecera.js";
import MenuHamburguesa from "./MenuHamburquesa/MenuHamburguesa.js";
import ZonaMenus from "./ZonaMenus.js";
import AuthService from "../../services/auth.service";
import { PerfilUsuario, rootPath } from "../../services/utils.js";
import BP2022Service from "./../../services/user.service";

const Cabecera = (props) => {

    const logOut = () => {
        console.log("--LogOut--");
        CambiaPaginaSimple('Inicio');
        AuthService.logout();
    };

    const CambiaPaginaSimple = (codigoPagina) => {
        props.cambiaPagina({
            newpagina: codigoPagina
        });
    }

    const OpenNewWindow = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }


    const [opcionesMenu, setOpcionesMenu] = useState([
        {
            numColumnas : 4,
            filasPorColumna : [4,3,3,5],
            titulo : 'Uso humano',
            apartados : [
                {
                    titulo : 'Búsqueda avanzada',
                    pagina : null,
                    subapartados : [
                        {
                            titulo : 'Medicamentos',
                            pagina : 'FBAvanzadaMUH'
                        },
                        {
                            titulo : 'Principios activos',
                            pagina : 'FBAvanzadaPAH'
                        },
                    ]
                },
                {
                    titulo : 'Medicamentos por composición',
                    pagina : 'FBComposicionMUH',
                    subapartados : null
                },
                {
                    titulo : 'Interacciones',
                    pagina : 'BInteraccionesMUH',
                    subapartados : null
                },
                {
                    titulo : 'Medicamentos por aspecto físico',
                    pagina : 'FBFormaColorMUH',
                    subapartados : null
                },
                {
                    titulo : 'Medicamentos con igual composición',
                    pagina : null,
                    subapartados : [
                        {
                            titulo : 'Por agrupación homogénea nacional',
                            pagina : 'FBAgrupHomoMUH'
                        },
                        {
                            titulo : 'Por agrupación homogénea autonómica',
                            pagina : 'FBAgrupHomoAutoMUH'
                        },
                        {
                            titulo : 'Por grupo terapéutico ATC',
                            pagina : 'FBGTATCMUH'
                        },
                        {
                            titulo : 'Por descripción clínica AEMPS',
                            pagina : 'FBGTVMPPMUH'
                        },
                    ]
                },
                {
                    titulo : 'Medicamentos por forma farmacéutica',
                    pagina : 'FBFormaFarmaMUH',
                    subapartados : null
                },
                {
                    titulo : 'Medicamentos por laboratorio',
                    pagina : 'FBLaboratorioMUH',
                    subapartados : null
                },
                {
                    titulo : 'Principios activos por actividad',
                    pagina : null,
                    subapartados : [
                        {
                            titulo : 'Por actividad farmacológica',
                            pagina : 'FBAFMUH'
                        },
                        {
                            titulo : 'Por capacidad inductora/inhibidora enzimática',
                            pagina : 'FBAMMUH'
                        },
                    ]
                },
                {
                    titulo : 'Relacionados con enfermedades',
                    pagina : null,
                    subapartados : [
                        {
                            titulo : 'Medicamentos para una indicación',
                            pagina : 'FBPacienteIndicacionMUH'
                        },
                        {
                            titulo : 'Búsqueda de reacciones adversas',
                            pagina : 'FBReaccionesAdversasMUH'
                        },
                        {
                            titulo : 'Interferencias con análisis clínicos',
                            pagina : 'FBAnalisisMUH'
                        },
                    ]
                },
                {
                    titulo : 'Por farmacogenética',
                    pagina : 'FBFarmacogeneticaMUH',
                    subapartados : null
                },
                {
                    titulo : 'Problemas de suministro AEMPS',
                    pagina : 'FBProblemasSumMUH',
                    subapartados : null
                },
                {
                    titulo : 'Lotes retirados por alertas de calidad',
                    pagina : 'FBAlertasCMUH',
                    subapartados : null
                },
                {
                    titulo : 'Formulación magistral',
                    pagina : 'BFormMagistralMUH',
                    subapartados : null
                },                
                {
                    titulo : 'Medicamentos homeopáticos',
                    pagina : 'BMedsHomeoMUH',
                    subapartados : null
                },                
                {
                    titulo : 'Medicamentos extranjeros',
                    pagina : 'FBExtranjerosEXT',
                    subapartados : null
                },                
            ]
        },
        {
            numColumnas : 4,
            filasPorColumna : [2,2,2,2],
            titulo : 'Veterinaria',
            apartados : [
                {
                    titulo : 'Búsqueda avanzada',
                    pagina : 'FBAvanzadaMUV',
                    subapartados : null
                },
                {
                    titulo : 'Medicamentos por composición',
                    pagina : 'FBComposicionMUV',
                    subapartados : null
                },
                {
                    titulo : 'Medicamentos por forma farmacéutica',
                    pagina : 'FBFormaFarmaMUV',
                    subapartados : null
                },
                {
                    titulo : 'Medicamentos por laboratorio',
                    pagina : 'FBLaboratorioMUV',
                    subapartados : null
                },
                {
                    titulo : 'Principios activos por actividad',
                    pagina : 'FBAFMUV',
                    subapartados : null
                },
                {
                    titulo : 'Medicamentos por grupo terapéutico ATC',
                    pagina : 'FBGTATCMUV',
                    subapartados : null
                },
                {
                    titulo : 'Medicamentos indicados por especie',
                    pagina : 'FBEspecieIndicacionMUV',
                    subapartados : null
                },
                {
                    titulo : 'Lotes retirados por alertas de calidad',
                    pagina : 'FBAlertasCMUV',
                    subapartados : null
                },
            ]
        },
        {
            numColumnas : 4,
            filasPorColumna : [3,2,3,1],
            titulo : 'Parafarmacia',
            apartados : [
                {
                    titulo : 'Búsqueda avanzada',
                    pagina : 'FBAvanzadaPPF',
                    subapartados : null
                },
                {
                    titulo : 'Productos por composición',
                    pagina : 'FBComposicionPPF',
                    subapartados : null
                },
                {
                    titulo : 'Interacciones',
                    pagina : 'BInteraccionesPPF',
                    subapartados : null
                },
                {
                    titulo : 'Productos por forma farmacéutica',
                    pagina : 'FBFormaFarmaPPF',
                    subapartados : null
                },
                {
                    titulo : 'Productos por laboratorio',
                    pagina : 'FBLaboratorioPPF',
                    subapartados : null
                },
                {
                    titulo : 'Ingredientes cosméticos por actividad',
                    pagina : 'FBActPPF',
                    subapartados : null
                },
                {
                    titulo : 'Dietoterápicos',
                    pagina : 'FBSaborEdadPPF',
                    subapartados : null
                },
                {
                    titulo : 'Lotes retirados por alertas de calidad',
                    pagina : 'FBAlertasCPPF',
                    subapartados : null
                },
                {
                    titulo : 'Productos similares',
                    pagina : null,
                    subapartados : [
                        {
                            titulo : 'Por agrupación homogénea mutualidades',
                            pagina : 'FBAgrupHomoPPF'
                        },
                        {
                            titulo : 'Por agrupación homogénea autonómica',
                            pagina : 'FBAgrupHomoAutoPPF'
                        },
                        {
                            titulo : 'Por categorización del Consejo General',
                            pagina : 'FBGTConPPF'
                        },
                        {
                            titulo : 'Por clasificación Nomenclátor',
                            pagina : 'FBGTSanPPF'
                        },
                    ]
                },
            ]
        },
        {
            numColumnas : 1,
            filasPorColumna : [2],
            titulo : 'Enfermedades',
            apartados : [
                {
                    titulo : 'Enfermedades humanas',
                    pagina : 'BEnfermedadesPATH',
                    subapartados : null
                },
                {
                    titulo : 'Enfermedades veterinarias',
                    pagina : 'BEnfermedadesPATV',
                    subapartados : null
                },
            ]
        },
        {
            numColumnas : 3,
            filasPorColumna : [2,1,1],
            titulo : 'Listados',
            apartados : [
                {
                    titulo : 'Generales',
                    pagina : null,
                    subapartados : [
                        {
                            titulo : 'Productos con cambios de nombre',
                            pagina : 'FBCambioNombre',
                        },
                        {
                            titulo : 'Productos con cambios de laboratorio',
                            pagina : 'FBCambioLaboratorio'
                        },
                    ]
                },
                {
                    titulo : 'Advertencias',
                    pagina : null,
                    subapartados : [
                        {
                            titulo : 'Productos sin gluten',
                            pagina : 'BSinGluten'
                        },
                        {
                            titulo : 'Productos sin lactosa',
                            pagina : 'BSinLactosa'
                        },
                        {
                            titulo : 'Medicamentos que pueden dar positivo en dopaje',
                            pagina : 'BDopantesMUH'
                        },
                        {
                            titulo : 'Productos que no deben administrarse con lentes de contacto',
                            pagina : 'BNoLentes'
                        },
                    ]
                },
                {
                    titulo : 'Financiación y precios',
                    pagina : null,
                    subapartados : [
                        {
                            titulo : 'Productos con cambios de precio',
                            pagina : 'FBCambioPrecioMUH'
                        },
                        {
                            titulo : 'Productos con futuros cambios de precio',
                            pagina : 'BCambioPrecioPrevistoMUH'
                        },
                        {
                            titulo : 'Medicamentos que superan el precio menor',
                            pagina : 'BComparaPrecioMenorMUH'
                        },
                        {
                            titulo : 'Medicamentos que superan el precio más bajo',
                            pagina : 'BComparaPrecioMasBajoMUH'
                        },
                        {
                            titulo : 'Medicamentos desfinanciados',
                            pagina : 'FBDesfinanciadosMUH'
                        },
                        {
                            titulo : 'Medicamentos con precio notificado',
                            pagina : 'BPrecioNotificadoMUH'
                        },
                        {
                            titulo : 'Medicamentos afectados por las deducciones del RDL 8/2010',
                            pagina : 'BDeduccionesRDLMUH'
                        },
                    ]
                },
                {
                    titulo : 'Dispensación',
                    pagina : null,
                    subapartados : [
                        {
                            titulo : 'Medicamentos no sustituibles',
                            pagina : 'BNoSustituiblesMUH'
                        },
                        {
                            titulo : 'Medicamentos sujetos a seguimiento adicional',
                            pagina : 'BSeguimientoAdicionalMUH'
                        },
                        {
                            titulo : 'Medicamentos obligados a llevar dispositivo de seguridad',
                            pagina : 'BDispositivoSeguridadMUH'
                        },
                        {
                            titulo : 'Medicamentos de anotación en libro recetario',
                            pagina : 'BLibroRecetarioM'
                        },
                        {
                            titulo : 'Medicamentos de anotación en libro estupefacientes',
                            pagina : 'BLibroEstupefacientesM'
                        },
                        {
                            titulo : 'Medicamentos de notificación anual por ESVAC',
                            pagina : 'BNotificacionESVACMUV'
                        },
                    ]
                },
            ]
        },
    ]);

    if (!window.mostrarHomeopatia){
        opcionesMenu[0].filasPorColumna = [4,3,3,4];
        opcionesMenu[0].apartados.splice(opcionesMenu[0].apartados.length-2,1);
    }


    const elementosMenuIconos = [
        { 
            titulo : 'Inicio',
            alHacerClick: CambiaPaginaSimple,
            imagen : rootPath + 'img/homeWhite.svg',
            imagenGris : rootPath + 'img/homeGrey.svg',
            parametro: 'Inicio'            
        },
        { 
            titulo : 'Historial',
            alHacerClick: CambiaPaginaSimple,
            imagen : rootPath + 'img/clockWhite.svg',
            imagenGris : rootPath + 'img/clockGrey.svg',
            parametro: 'BHistorialUsuario'            
        },
        { 
            titulo : 'Configuración',
            alHacerClick: CambiaPaginaSimple,
            imagen : rootPath + 'img/settingsWhite.svg',            
            imagenGris : rootPath + 'img/settingsGrey.svg',            
            parametro: 'Configuracion'            
        },
        { 
            titulo : 'Ayuda',
            //alHacerClick: OpenNewWindow,
            alHacerClick: CambiaPaginaSimple,
            imagen : rootPath + 'img/helpWhite.svg',            
            imagenGris : rootPath + 'img/helpGrey.svg',            
            //parametro: '/Ayuda/ManualUsoBOTPLUS2022.pdf',
            parametro: 'Help'            
        },
        { 
            titulo : 'Desconectar',
            alHacerClick: logOut,
            imagen : rootPath + 'img/logOutWhite.svg',            
            imagenGris : rootPath + 'img/logOutGrey.svg',            
            parametro: ''            
        },       
    ]

    const perfil = PerfilUsuario();

    /*if (perfil === "EST"){
        elementosMenuIconos.splice(1,1);
    }*/

    useEffect(() => {
        BP2022Service.obtenListadosExportables()
        .then((response) => {
            var haCambiadoMenu = false;
            const nuevoMenu = JSON.parse(JSON.stringify(opcionesMenu));
            if (response.data && response.data.length>0){
                nuevoMenu[4].filasPorColumna = [2,1,2]
                nuevoMenu[4].apartados.push({
                    titulo : 'Exportar',
                    pagina : 'ExportaWS',
                    subapartados : null,
                });
                haCambiadoMenu = true;
            }
            BP2022Service.moduloIndicacionesPF()
            .then((response) => {
                if (response.data){
                    nuevoMenu[2].filasPorColumna = [3,3,3,1]
                    nuevoMenu[2].apartados.splice(6,0,
                        {
                            titulo : 'Productos y complementos alimenticios',
                            pagina : 'FBProductosIndicacionPPF',
                            subapartados : null,
                        });
                    haCambiadoMenu = true;
                }
                BP2022Service.hayBuscadorCisMED()
                .then((response) => {
                    if (response.data){
                        nuevoMenu[4].apartados[3].subapartados.push(
                            {
                                titulo : 'Medicamentos con alerta CisMED',
                                pagina : 'BAlertadosCismedMUH',
                            });
                        haCambiadoMenu = true;
                    }
                    if (haCambiadoMenu){
                        setOpcionesMenu(nuevoMenu);                
                    }
                })
                .catch(function (error) {
                    console.log('Error', error.message);
                });
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    },  []);


    return (
        <header class="header" > 
		    <div class="container" >
                <LogoCabecera cambiaPagina = {props.cambiaPagina} />
                <ZonaMenus cambiaPagina = {props.cambiaPagina} opcionesMenu = {opcionesMenu} elementosMenuIconos={elementosMenuIconos} cierraMenuBurguer={props.cierraMenuBurguer}  provincia = {props.provincia} perfil = {perfil}/>   
                <MenuHamburguesa cambiaPagina = {props.cambiaPagina} opcionesMenu = {opcionesMenu} opcionesMenuGris={elementosMenuIconos} historial = {props.historial} perfil = {perfil}/>   
		    </div>
	    </header>
    )      
};

export default Cabecera

