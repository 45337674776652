import { useState } from "react";
import { RemoveClassNameToClassItems } from "../../services/utils";
import Emergente from "../Fichas/FichaMUH/Apartados/Cismed/Emergente";
import ModalRecupera from "./ModalRecupera";

const Recupera = (props) => {

    const [muestraEmergente, setMuestraEmergente] = useState(false);
    const [dni, setDni] = useState('');
    const [nfact, setNfact] = useState('');
    const [altoEmergente, setAltoEmergente] = useState(null);

    const cancelaProceso = () =>{
        setMuestraEmergente(false);
    }

    const iniciaProceso = () =>{
        setMuestraEmergente(true);
    }

    const cambiaDNI = (txt) =>{
        setDni(txt);
    }

    const cambiaNFact = (txt) =>{
        setNfact(txt);
    }

    const cambiAlto = (alto) =>{
        setAltoEmergente(alto);
    }

    const modalTitulo = "Claves BOTPLUS";
    const modalKeyName = "AccessNotAllowed"
    const modalContent = (
        <ModalRecupera dni={dni} nfact={nfact} cambiaAlto = {cambiAlto} cancela = {cancelaProceso}
        >
        </ModalRecupera>
    );


    return(
        <div className="box box__claves">
            {muestraEmergente ?
                <Emergente 
                    contenido={modalContent} 
                    keyName={modalKeyName} 
                    titulo={modalTitulo} 
                    activo={true} 
                    alto={altoEmergente}
                    cerrar={cancelaProceso}></Emergente>
            :
                <></>
            }
            <form  onSubmit={(e) => e.preventDefault()}>
                <div className="cover cover__input" >
                    <label className="fs fs15 fw fw400 greyColor2" >DNI / CIF</label>
                    <input type="text" name="usuario" placeholder="DNI o CIF" value = {dni} onChange = {(e) => cambiaDNI(e.target.value)}/>
                </div>
                <div className="cover cover__input" >
                    <label className="fs fs15 fw fw400 greyColor2" >Número de factura o pedido</label>
                    <input type="text" name="usuario" placeholder="Número de factura o pedido" value={nfact} onChange = {(e) => cambiaNFact(e.target.value)}/>
                </div>
                <div className="cover cover__buttons" >
                    <button className="button button__purple"  onClick={(e) => iniciaProceso()}> Obtener Claves</button>
                </div>
            </form>
        </div>
    );
}

export default Recupera;