import Composicion from "./Composicion";
const Composiciones = (props) => {
    const datosCompos = props.datosCompos;
    const por = datosCompos.ComposicionPor;
    const composiciones = datosCompos.Composiciones.map(compo => <Composicion cambiaPagina={props.cambiaPagina} composicion={compo} por={por} tipo={props.tipo}></Composicion>);
    return (
        <div class="infoContent" >
            {composiciones}
        </div>
    )
}
export default Composiciones;
