import { getIdCCAA, numberWithCommas, AddClassNameToClassItems, rootPath } from "../../../../../services/utils";
import AvisoPriciosFuturos from "../../InfoPrincipal/AvisoPreciosFuturos";
import FilaBloquePrecios from "./FilaBloquePrecios";
/*
            {(tienePreciosFuturos && 
                (ficha.PFuturos.PVPIVA>0 || ficha.PFuturos.PVPsinIVA>0 || ficha.PFuturos.PFact>0 || 
                    (ficha.PFuturos.AgrupacionCCAA && 
                        (ficha.PFuturos.AgrupacionCCAA.PVPMenor > 0 || ficha.PFuturos.AgrupacionCCAA.PVPMasBajo > 0))) ?
                <AvisoPreciosFuturos fecha={ficha.PFuturos.Fecha}></AvisoPreciosFuturos>
            :
                <div></div>                            
            )}
*/

const BloquePrecios = (props) => {
    const ficha=props.ficha;
    const tienePreciosFuturos = (ficha.PFuturos);
    const pFuturosHTML = (tienePreciosFuturos &&
            (ficha.PFuturos.PVPIVA>0 || ficha.PFuturos.PVPsinIVA>0 || ficha.PFuturos.PFact>0 || ficha.PFuturos.PVL>0 || ficha.PFuturos.PRef>0 || ficha.PFuturos.PVLRef>0 )? 
            <AvisoPriciosFuturos fecha={ficha.PFuturos.Fecha}></AvisoPriciosFuturos> : 
            <span> </span>
    );
    const claseDivTabla = (tienePreciosFuturos ? "tablaConPF" : "tablaSinPF");
    var literalPVPIVA = 'PVP con IVA';
    if (ficha.CodTipoRegistro === 'PPF')
        literalPVPIVA = 'PVP con IVA de facturación';
    var literalPVPsinIVA = 'PVP sin IVA';
    var idCCAA = getIdCCAA();
    if ((idCCAA===18) && (ficha.CodTipoRegistro === 'PPF') && (ficha.IdTipoPF===6))
        literalPVPIVA = "Precio Ceuta";
    if ((idCCAA===19) && (ficha.CodTipoRegistro === 'PPF') && (ficha.IdTipoPF===6))
        literalPVPIVA = "Precio Melilla";
    var literalPVL = 'PVL';
    if (ficha.DatosF){
        if (ficha.DatosF.filter(e => e.CodigoDato === 'PN').length > 0){
            literalPVPIVA = 'PVP con IVA notificado';
            literalPVPsinIVA = 'PVP sin IVA notificado';
            literalPVL = 'PVL notificado';
        }
    }

    var precioReferencia = (ficha.PVPIVA ? ficha.PVPIVA : ficha.PVPsinIVA ? ficha.PVPsinIVA : ficha.PVL ? ficha.PVL : ficha.PFact);

    var gridTemplateColumns = "2.5fr 0.5fr 1.7fr";
    if (tienePreciosFuturos)
        gridTemplateColumns = "2.5fr 0.6fr 0.6fr 1.8fr";


    if (precioReferencia){
        if (precioReferencia>=100){
            gridTemplateColumns = "2.5fr 0.6fr 1.6fr";
            if (tienePreciosFuturos){
                gridTemplateColumns = "2.5fr 0.7fr 0.7fr 1.6fr";
            }
        }
        if (precioReferencia>=1000){
            gridTemplateColumns = "2.5fr 0.7fr 1.5fr";
            if (tienePreciosFuturos){
                gridTemplateColumns = "2.5fr 0.8fr 0.8fr 1.4fr";
            }
        }
        if (precioReferencia>=10000){
            gridTemplateColumns = "2.5fr 0.9fr 1.3fr";
            if (tienePreciosFuturos){
                gridTemplateColumns = "2.5fr 1.0fr 1.0fr 1.0fr";
            }
        }
        if (precioReferencia>=100000){
            gridTemplateColumns = "2.5fr 1.0fr 1.2fr";
            if (tienePreciosFuturos){
                gridTemplateColumns = "2.5fr 1.1fr 1.1fr 0.8fr";
            }
        }
    }

    

    const Filas = (tienePreciosFuturos ?
        [
            {
                titulo: 'IVA aplicable',
                valor1: ficha.IVA >0 ? ficha.IVA + '%': '-',
                valor2: '',
                valor3: '',
                colSpan:false,
            },
            {
                titulo: literalPVPIVA,
                valor1: ficha.PVPIVA >0 ? numberWithCommas(ficha.PVPIVA): '-',
                valor2: ficha.PFuturos.PVPIVA >0 && ficha.PFuturos.PVPIVA !== ficha.PVPIVA ? numberWithCommas(ficha.PFuturos.PVPIVA) : ' ',
                valor3: ficha.PVPIVA >0 || ficha.PFuturos.PVPIVA >0 ?'EUR':'',
                colSpan:false,
            },
            {
                titulo: literalPVPsinIVA,
                valor1: ficha.PVPsinIVA>0 ? numberWithCommas(ficha.PVPsinIVA) : '-',
                valor2: ficha.PFuturos.PVPsinIVA > 0 && ficha.PFuturos.PVPsinIVA !== ficha.PVPsinIVA ? numberWithCommas(ficha.PFuturos.PVPsinIVA) : ' ',
                valor3: ficha.PVPsinIVA>0 || ficha.PFuturos.PVPsinIVA > 0 ? 'EUR': '',
                colSpan:false,
            },
            {
                titulo: literalPVL,
                valor1: ficha.PVL > 0 ? numberWithCommas(ficha.PVL) : '-',
                valor2: ficha.PFuturos.PVL > 0 && ficha.PFuturos.PVL !== ficha.PVL ? numberWithCommas(ficha.PFuturos.PVL) : ' ',
                valor3: ficha.PFuturos.PVL > 0 || ficha.PVL > 0 ? 'EUR': '',
                colSpan:false,
            },
            {
                titulo: 'Precio facturación',
                valor1: ficha.PFact > 0 ? numberWithCommas(ficha.PFact) : '-',
                valor2: ficha.PFuturos.PFact > 0 && ficha.PFuturos.PFact !== ficha.PFact? numberWithCommas(ficha.PFuturos.PFact) : ' ',
                valor3: ficha.PFact > 0 || ficha.PFuturos.PFact > 0 ? 'EUR' : '',
                colSpan:false,
            },
            {
                titulo: 'Conjunto de referencia',
                valor1: (ficha.CjtoRef) ? ficha.CjtoRef.Codigo + ' ' + ficha.CjtoRef.Nombre : '',
                colSpan:true,
            },
            {
                titulo: 'PVP IVA referencia',
                valor1: ficha.PRef > 0 ? numberWithCommas(ficha.PRef) : '-',
                valor2: ficha.PFuturos.PRef > 0 && ficha.PFuturos.PRef != ficha.PRef ? numberWithCommas(ficha.PFuturos.PRef) : ' ',
                valor3: ficha.PFuturos.PRef > 0 || ficha.PRef > 0 ? 'EUR' : '',
                colSpan:false,
            },
            {
                titulo: 'PVL referencia',
                valor1: ficha.PVLRef > 0 ? numberWithCommas(ficha.PVLRef) : '-',
                valor2: ficha.PFuturos.PVLRef > 0 && ficha.PFuturos.PVLRef !== ficha.PVLRef? numberWithCommas(ficha.PFuturos.PVLRef) : ' ',
                valor3: ficha.PFuturos.PVLRef > 0 || ficha.PVLRef > 0 ? 'EUR' : '',
                colSpan:false,
            },
        ]
    :
        [
            {
                titulo: 'IVA aplicable',
                valor1: ficha.IVA >0 ? ficha.IVA + '%': '-',
                valor2: '',
                colSpan:false,
            },
            {
                titulo: literalPVPIVA,
                valor1: ficha.PVPIVA >0 ? numberWithCommas(ficha.PVPIVA): '-',
                valor2: ficha.PVPIVA >0 ?'EUR':'',
                colSpan:false,
            },
            {
                titulo: literalPVPsinIVA,
                valor1: ficha.PVPsinIVA>0 ? numberWithCommas(ficha.PVPsinIVA) : '-',
                valor2: ficha.PVPsinIVA>0 ? 'EUR': '',
                colSpan:false,
            },
            {
                titulo: literalPVL,
                valor1: ficha.PVL >0 ? numberWithCommas(ficha.PVL) : '-',
                valor2: ficha.PVL > 0 ? 'EUR' : '',
                colSpan:false,
            },
            {
                titulo: 'Precio facturación',
                valor1: ficha.PFact > 0 ? numberWithCommas(ficha.PFact) : '-',
                valor2: ficha.PFact > 0 ? 'EUR' : '',
                colSpan:false,
            },
            {
                titulo: 'Conjunto de referencia',
                valor1: (ficha.CjtoRef) ? ficha.CjtoRef.Codigo + ' ' + ficha.CjtoRef.Nombre : '-',
                colSpan:true,
            },
            {
                titulo: 'PVP IVA referencia',
                valor1: ficha.PRef > 0 ? numberWithCommas(ficha.PRef) : '-',
                valor2: ficha.PRef > 0 ? 'EUR' : '',
                colSpan:false,
            },
            {
                titulo: 'PVL referencia',
                valor1: ficha.PVLRef > 0 ? numberWithCommas(ficha.PVLRef) : '-',
                valor2: ficha.PVLRef > 0 ? 'EUR' : '',
                colSpan:false,
            },
        ]
    );

    if (ficha.CodTipoRegistro === "PPF")
    {
        Filas.splice(4,4);
        Filas.splice(2,1);
        if (ficha.LiteralPrecio)
            Filas[1].titulo = ficha.LiteralPrecio;
        var idCCAA = getIdCCAA();
        if (idCCAA === 5 || idCCAA === 18 || idCCAA === 19){
            if (tienePreciosFuturos){
                Filas[1].valor1= ficha.PVPsinIVA>0 ? numberWithCommas(ficha.PVPsinIVA) : '-';
                Filas[1].valor2= ficha.PFuturos.PVPsinIVA > 0 && ficha.PFuturos.PVPsinIVA !== ficha.PVPsinIVA ? numberWithCommas(ficha.PFuturos.PVPsinIVA) : ' ';
                Filas[1].valor3= ficha.PVPsinIVA>0 || ficha.PFuturos.PVPsinIVA > 0 ? 'EUR': '';
            }
            else{
                Filas[1].valor1 = ficha.PVPsinIVA>0 ? numberWithCommas(ficha.PVPsinIVA) : '-';
                Filas[1].valor2 = ficha.PVPsinIVA>0 ? 'EUR': '';
            }
        }

    }

    if (ficha.CodTipoRegistro === "MHO")
    {
        Filas.splice(5,3);
    }

    const TablaHTML = Filas.map(fila => <FilaBloquePrecios fila = {fila} tienePFuturos={tienePreciosFuturos} gridTemplateColumns = {gridTemplateColumns}></FilaBloquePrecios>);
    
    return (
        <div className="infoGeneral__precios">
            <div className="titulo-alerta">
                <div className="titulo" style={{display: 'flex', alignItems: 'center'}}>
                    <span>Precios</span>
                    { 
                        (ficha.InfoPrecio && ficha.InfoPrecio !== '') ?
                            <span style={{cursor:'pointer', marginLeft:'10px'}}  float="right" onClick={(e) => props.abreEmergenteInfoPrecio(e)}>
                                <img src={rootPath + "img/infoGreen.svg"} alt=""/>
                            </span>
                        :
                            <></>
                    }
                </div>
                {pFuturosHTML}
            </div>
            <div className={claseDivTabla}>
                {TablaHTML}
            </div>
        </div>
    )
}
export default BloquePrecios;
