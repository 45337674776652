import { useEffect, useState } from "react";
import { filtrosInteracciones } from "../../../services/objsAplicacion";
import BP2022Service from "../../../services/user.service";
import { GetFiltrosStr, handleClasesCheckbox, rootPath } from "../../../services/utils";
import LoadingPopup from "../Loading.Popup";
import Interaccion from "./Interaccion";
import MargenIzquierdoInter from "./MargenIzquierdoInter";
import MedsSeleccionados from "./MedsSeleccionados";
import { isNumeric } from "validator";

const BInteracciones = (props) => {
    const [listaPosibles, setListaPosibles] = useState(null);
    const [idsSel, setIdsSel] = useState((props.infoPagina.idsSel) ? props.infoPagina.idsSel : []);
    const [listaResultados, setListaResultados] = useState(null);
    const [listaFiltrada, setListaFiltrada] = useState(null);
    const [configuracionVista, setConfiguracionVista] = useState({
        filtros: (props.infoPagina.filtrosActivos) ? props.infoPagina.filtrosActivos : [],
        numMostrar: (props.infoPagina.numMostrar) ? props.infoPagina.numMostrar: 10,
    })
    const [itemsSelected, setItemsSelected] = useState(null);
    
   
    const ActulizaItemsSelected = () =>{
        var nuevosItemsSelected = [];

        for (var i = 0; i<idsSel.length; i++){
            var id = idsSel[i];
            var item = listaPosibles.find(x=>parseInt(x.Codigo)===parseInt(id));
            if (item)
                nuevosItemsSelected.push(item);
        }
        setItemsSelected(nuevosItemsSelected);
    }


    const filtrosInt = filtrosInteracciones;

    for (var i=0; i<filtrosInt.length; i++){
        var idGrupo = filtrosInt[i].idGrupo;
        if (filtrosInt[i].valores && filtrosInt[i].valores.length>0){
            for (var j=0; j<filtrosInt[i].valores.length; j++){
                var valor = filtrosInt[i].valores[j].value;
                filtrosInt[i].valores[j].activo = (
                    configuracionVista.filtros && configuracionVista.filtros.length>0 ? 
                        (configuracionVista.filtros.find(x => x.nombre === idGrupo && parseInt(x.valor) === parseInt(valor)) ? true : false)
                    : 
                        false
                    );
            }
        }
    }

    useEffect(() => {
        if (listaPosibles === null){
            DescargaDatos();
        }else if (idsSel && idsSel.length>0 && !listaResultados){
            ActulizaItemsSelected();
            BuscaInteracciones();
        }else if((!idsSel || idsSel.length===0) && !listaResultados){
            setItemsSelected([]);
            setListaResultados([]);
        }else if(listaResultados && (!listaFiltrada) )  {
            var nuevaListaFiltrada = [];
            if (configuracionVista.filtros && configuracionVista.filtros.length>0){
                AplicaFiltrosInteracciones(listaResultados,configuracionVista.filtros)
            }
            else{
                nuevaListaFiltrada = listaResultados;
                setListaFiltrada(nuevaListaFiltrada);
            }
        }else{
        }

        if ((idsSel) && idsSel.length>0){
            var newpath = rootPath + "BInteraccionesMUH?idsSel=" + idsSel.toString();
            if (configuracionVista.filtros && configuracionVista.filtros.length>0){
                newpath += "&" + GetFiltrosStr(filtrosInteracciones);
            }
            window.history.replaceState(null,'',newpath);
        }

    },[listaPosibles, idsSel, listaResultados, listaFiltrada, configuracionVista]);

    const DescargaDatos = () => {     
        BP2022Service.listaPosiblesInteractores(idsSel)
        .then((response) => {
            var nuevaListaPosibles = response.data.Medicamentos;
            nuevaListaPosibles.splice(0,0,{Codigo:0, Valor:'Medicamento o complemento'});
            setListaPosibles(nuevaListaPosibles);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const BuscaInteracciones = () => {      
        BP2022Service.buscaInteracciones(idsSel)
        .then((response) => {
            var nuevaListaResultados = response.data;
            setListaResultados(nuevaListaResultados);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const AnadeSiCodigo = (sCodigo) => {
        if (sCodigo){
            if (sCodigo.length >= 5){
                if (isNumeric(sCodigo)){
                    BP2022Service.getItemsFromEspecod(sCodigo)
                    .then((response) => {
                        if (response.data){
                            let tempList = JSON.parse(JSON.stringify(listaPosibles));
                            var added = false;
                            for (i=0; i<response.data.length; i++){
                                if (!tempList.find(x => x.Codigo2 === response.data[i].CodigoNacional)){
                                    var item = {Codigo: response.data[i].Id, Valor: response.data[i].Nombre, Codigo2: response.data[i].CodigoNacional}
                                    tempList.push(item);
                                    added = true;
                                }
                                else{
                                }
                            }
                            if (added) setListaPosibles(tempList);
                        }
                    })
                    .catch(function (error) {
                        console.log('Error', error.message);
                    });
                }
            }
        }
    }

    const cambioFiltroCheck = (idFiltro, idValor, marcado, ) => {
        let tempVar = JSON.parse(JSON.stringify(configuracionVista));
        var item = tempVar.filtros.findIndex(x => x.nombre === idFiltro && parseInt(x.valor) === parseInt(idValor));
        if (item<0 && marcado){
            tempVar.filtros.push({nombre: idFiltro, valor:idValor})
        }
        if (item>=0 && !marcado){
            tempVar.filtros.splice(item,1);
        }
        setConfiguracionVista(tempVar);
        //setListaFiltrada(null);
        AplicaFiltrosInteracciones(listaResultados,tempVar.filtros);
    };

    function AplicaFiltro(lista, filtroInter, filtroSel)
    {
        if (lista){
            if (filtroInter.valores){
                var seleccionados = [];
                for (var i=0; i<filtroInter.valores.length; i++){
                    var aux = filtroSel.find(y => y.nombre === filtroInter.idGrupo && parseInt(y.valor) === parseInt(filtroInter.valores[i].value))
                    if (aux){
                        seleccionados.push(filtroInter.valores[i].value);
                        if (filtroInter.valores[i].otrosValores)
                            for (var j=0; j<filtroInter.valores[i].otrosValores.length; j++)
                            {
                                seleccionados.push(filtroInter.valores[i].otrosValores[j]);
                            }
                    }
                }
                if (seleccionados.length>0){
                    lista = lista.filter(item => seleccionados.includes(item[filtroInter.campolista]));
                }
            }
        }
        return lista;
    };

    const AddMedicamento = (nuevovalor) =>{
        const nuevosIdsSel = JSON.parse(JSON.stringify(idsSel));
        if (!nuevosIdsSel) { nuevosIdsSel = []};
        if (!nuevosIdsSel.includes(nuevovalor))
            nuevosIdsSel.push(nuevovalor);
        setIdsSel(nuevosIdsSel);
        setListaResultados(null);
        setListaFiltrada(null);
    }

    const RemoveMedicamento = (valor) =>{
        const nuevosIdsSel = JSON.parse(JSON.stringify(idsSel));
        if (!nuevosIdsSel) { nuevosIdsSel = []};
        var i = nuevosIdsSel.indexOf(parseInt(valor));
        nuevosIdsSel.splice(i,1);
        setIdsSel(nuevosIdsSel);
        setListaResultados(null);
        setListaFiltrada(null);
    }

    const ClearMedicamentos = () =>{
        const nuevosIdsSel = [];
        setIdsSel(nuevosIdsSel);
        setItemsSelected([]);
        setListaResultados(null);
        setListaFiltrada(null);
    }

    const AplicaFiltrosInteracciones = (listaCompleta,filtros) => {
        var lista = listaCompleta;
        if (filtrosInteracciones)
        {
            for (var i=0; i<filtrosInteracciones.length; i++)
            {
                lista = AplicaFiltro(lista,filtrosInteracciones[i], filtros);
            }
        }
        setListaFiltrada(lista);
    }

    const muestraMas= () =>{
        let tempVar = JSON.parse(JSON.stringify(configuracionVista));
        tempVar.numMostrar += 10;
        setConfiguracionVista(tempVar);
    }

    var bMostrarMas = (listaFiltrada ? (configuracionVista.numMostrar<listaFiltrada.length) : false);

    const HTMLListaInteracciones = 
        (listaFiltrada) ? 
        listaFiltrada.length>0 ? 
        listaFiltrada.slice(0,configuracionVista.numMostrar).map(inter => <Interaccion item={inter}></Interaccion>) : 
        <div style={{paddingTop:'30px', textAlign:'center'}}>No se han encontrado interacciones farmacológicas</div> : 
        <div></div>;

    if ((listaPosibles === null) || (listaResultados === null) || (listaFiltrada === null))
        if (listaPosibles === null)
            return(            
                <LoadingPopup titulo="Un momento" texto="Preparando formulario" esPorcentaje={true}></LoadingPopup>
            );
        else
            return(            
                <LoadingPopup></LoadingPopup>
            );
    else
        return(
                <div class="container" >
                    <MargenIzquierdoInter listaPosibles = {listaPosibles}
                        seleccionados = {idsSel}
                        alAnadir = {AddMedicamento}
                        alBorrar = {RemoveMedicamento}
                        filtros = {filtrosInt}
                        cambiaCheck = {cambioFiltroCheck}
                        alCambiarTexto = {AnadeSiCodigo}
                    ></MargenIzquierdoInter>
                    <div class="content content__purple" >
                        <div class="content__results__interacciones content__results" >
                            <form class="content__results content__results__filters" >
                                <MedsSeleccionados itemsSelected={itemsSelected} alBorrar={RemoveMedicamento} alLimpiar={ClearMedicamentos} nTotal={listaResultados.length} nFiltrados={listaFiltrada.length}></MedsSeleccionados>
                                {HTMLListaInteracciones}
                            </form>
                            {(listaFiltrada && listaFiltrada.length>0) ?
                                <div class="showing" >
                                    <p class="text" >
                                        <span class="number number__show" >{Math.min(configuracionVista.numMostrar,listaFiltrada.length)}</span> interacciones de <span class="number number__total" >{listaFiltrada.length}</span>
                                    </p>
                                    <div class="module module__buttons">
                                        {bMostrarMas ?(
                                            <span class="button" title="Ver más"  onClick={() => muestraMas()} style={{cursor:'pointer'}}>
                                                Ver más <img src={rootPath + "img/arrowPurpleDown2.svg"} alt="arrowPurpleDown2" />
                                            </span>
                                        ):(
                                            <div></div>
                                        )}                            
                                    </div>
                                </div>
                            :
                                <div></div>
                            }
                        </div>
                    </div>
                </div>
        );
}

export default BInteracciones;