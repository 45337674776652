
const TablaInfoPrincipalExt = (props) => {
    const ficha = props.ficha;

    const estiloValor = {width : '90%'};

    return (
        <div class="dispensacion-facturacion">
            <h3 class="titulo"></h3>
            <div class="facturacion" style={{borderBottom:'none'}}>
                <div class="fila">
                    <div class="col-i" >País</div>
                    <div class= "col-d" style={estiloValor}>{ficha.Pais}</div>
                </div>
            </div>
        </div>
    )
}
export default TablaInfoPrincipalExt;
