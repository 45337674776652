import FormBusquedaYPreferidos from "./Principal/FormBusquedaYPreferidos";
import NovedadesYProblemasSuministro from "./Apartados/NovedadesYProblemasSuministro";
import NotasSeguridadYAlertasCalidad from "./Apartados/NotasYAlertas/NotasSeguridadYAlertasCalidad";
import { PerfilUsuario } from "../../services/utils";

const Inicio = (props) => {
    return (
        <div>
            <FormBusquedaYPreferidos cambiaPagina={props.cambiaPagina} ></FormBusquedaYPreferidos>
            <NovedadesYProblemasSuministro cambiaPagina={props.cambiaPagina} ></NovedadesYProblemasSuministro>
            {(PerfilUsuario()!="EST")?
                <NotasSeguridadYAlertasCalidad cambiaPagina={props.cambiaPagina}></NotasSeguridadYAlertasCalidad>
            :
                <></>
            }
        </div>
    );
};

export default Inicio;
