import React, { useState, useEffect, createRef } from "react";
import CabeceraResultados from "../../Resultados/CabeceraResultados/CabeceraResultados";
import BP2022Service from "../../../services/user.service";
import InfoPrincipalMUH from "../FichaMUH/InfoPrincipal/InfoPrincipalMUH.js";
import MenuIzquierda from "../FichaMUH/MenuIzquierda/MenuIzquierda";
import ContenedorApartados from "../FichaMUH/Apartados/ContenedorApartados";
import MensajesEmergentes from "../FichaMUH/MensajesEmergentes";
import { AnadeApartado, baseURLPictogramas, compruebaAnclas, RemoveClassNameToClassItems } from "../../../services/utils";
import LoadingPopup from "../../Resultados/Loading.Popup";
import MargenDerechoPA from "../FichaPA/MargenDerechoPA";

const FichaING = (props) => {
    const id = props.infoPagina.id;
    const [ficha, setFicha] = useState(null);
    const [anclas, setAnclas] = useState([]);
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        if ((ficha==null) || (ficha.Id !== id))
        {
            descargaFicha();
        }        
        else if (refresh==1){
            descargaTextos(ficha.Id);
        }
        else if (refresh==2){
            //descargaSinonimias(ficha.Id)
            setRefresh(prev => prev +1);
        }
        else if (refresh==3){
            descargaAdvertencias(ficha.Id);
        }
        else if (refresh==4){
            descargaHistorico(ficha.Id)
        }
        else if (refresh==5){
            descargaInformacionAdicional(ficha.Id)
        }
        else if (refresh==6){
            descargaVideos(ficha.Id)
        }
        else if (refresh == 7)
        {
        }
    })
    const descargaFicha = () => {
        BP2022Service.getFichaING(id, props.tipo)
        .then((response) => {
            RemoveClassNameToClassItems('lightbox__popup',null,'active');
            //checkAnclas(response.data.Apartados);
            var auxFicha = response.data;
            compruebaAnclas(auxFicha.Apartados,anclas);
            auxFicha.CodTipoRegistro="ING";
            auxFicha.TipoRegistro="Ingrediente parafarmacia"; // + auxFicha.TipoIngrediente.toLowerCase();
            setFicha(auxFicha);
            setRefresh(1);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
    
    const descargaTextos = (miId) => {
        BP2022Service.getTextosING (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Epigrafes){
                    if (response.data.Epigrafes.length>0){
                        AnadeApartado({Titulo: "Ficha de ingrediente",  Ancla:"TEXT"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosTextos = response.data;
                    }
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
    
    const descargaAdvertencias = (miId) => {
        BP2022Service.getAdvertenciasING (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Advertencias){
                    if (response.data.Advertencias.length>0){
                        AnadeApartado({Titulo: "Advertencias",  Ancla:"ADV"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosAdvertencias = response.data;
                    }
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
   
    const descargaHistorico = (miId) => {
        BP2022Service.getHistoricoING (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Histórico",  Ancla:"HIST"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.DatosHistorico = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };


    const descargaInformacionAdicional = (miId) => {
        BP2022Service.getInformacionAdicionalING (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Información adicional",  Ancla:"DOCS"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.DatosInfoAdicional = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaVideos = (miId) => {
        BP2022Service.getVideosING (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Documentos){
                    if (response.data.Documentos.length>0){
                        AnadeApartado({Titulo: "Vídeos",  Ancla:"VID"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosVideos = response.data;
                    }
                }
                props.descargaHistorial();
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaSinonimias = (miId) => {
        BP2022Service.getSinonimiasING (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Sinonimias",  Ancla:"SINO"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.Sinonimias = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    /*const checkAnclas = (aptdos) => {
        for (var i=0; i<aptdos.length; i++){
            const idAncla = aptdos[i].Ancla;
            if (!anclas.find(ancla => ancla.idAncla===idAncla))
            {
                const anclaNueva = createRef();
                anclas.push({idAncla: idAncla, refAncla : anclaNueva});
            }
        }
    }*/

     
    if (ficha !== null) 
    {
        return (
            <div>
                <CabeceraResultados infoPagina={props.infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras}></CabeceraResultados>
                <InfoPrincipalMUH ficha={ficha} cambiaPagina={props.cambiaPagina} ></InfoPrincipalMUH>
                {
                    (ficha.MensajesEmergentes && ficha.MensajesEmergentes.length>0) ?
                        <MensajesEmergentes mensajes={ficha.MensajesEmergentes} id={ficha.Id}></MensajesEmergentes>
                    :
                        <div></div>
                }
                <section class="medicamento-infoGeneral">
                    <div class="container flex flex--justify-center">
                        <div class="contenido f-f-Flama-Book">
                            <MenuIzquierda apartados={ficha.Apartados} activo={ficha.Apartados[0].Ancla} anclas={anclas}></MenuIzquierda>

                            <ContenedorApartados cambiaPagina={props.cambiaPagina} ficha={ficha} anclas={anclas}></ContenedorApartados> 
                            <MargenDerechoPA cambiaPagina={props.cambiaPagina} ficha={ficha} ></MargenDerechoPA>

                        </div>
                    </div>
                </section>
            </div>
        )
    }
    else 
        return (
            <div>
                <LoadingPopup></LoadingPopup>
            </div>
        )
}
export default FichaING;
