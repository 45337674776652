import SelectorMulti2 from "../../Utilidades/Selector/SelectorMulti2";
import FiltroBusqueda from "../FiltrosYBusqueda/Filtros/FiltroBusqueda";

function MargenIzquierdoInter(props){
    const filtros = props.filtros;
    const htmlFiltros = (filtros ?
            <div>
                {filtros.map(filtro =>  <FiltroBusqueda filtro={filtro} 
                                                cambiaCheck= {props.cambiaCheck} 
                                        >
                                        </FiltroBusqueda>)}
            </div>
        :
            <div></div>
    );
/*
    const handleClickFiltrosHeader = (e) =>{
        ToggleClassNameToClassItems('cover__aside', null, 'active');
    }
*/
    const listaCodValorMeds   = (props.listaPosibles) ? props.listaPosibles.map(it => ({codigo: it.Codigo, valor: it.Valor, codigo2: it.Codigo2})) : [];

    return (
        <form class="aside aside__purple noBottom" id="main-filter" >
            <div class="content__results__filters" >
                <div class="cover cover__filters cover__filters__lab" >
                    <div class="module module__filters" >
                        <h3 class="title fs fs16 greyColor2" >Medicamentos o complementos</h3>
                        <SelectorMulti2 
                                lista = {listaCodValorMeds} 
                                idHTML="FBInterLMeds" 
                                valuesSelected={props.seleccionados}
                                alAnadir={props.alAnadir }
                                alBorrar={props.alBorrar} 
                                ancho="100%"
                                tipoFiltradoLista={5}
                                ocultaTags={true}
                                small = {true}
                                estrecho = {true}
                                alCambiarTexto = {props.alCambiarTexto}
                        ></SelectorMulti2>
                    </div>
                </div>
            </div>
            <div class="title" >
                <h2 class="fs fs15 FlamaBook" >Filtrar</h2>
            </div>
                {htmlFiltros}
        </form>
    )
}    
export default MargenIzquierdoInter;
