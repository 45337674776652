import { useEffect, useState } from "react";
import LoadingPopup from "../../Resultados/Loading.Popup";
import BP2022Service from "../../../services/user.service";
import SelectorUno from "../../Utilidades/Selector/SelectorUno";
import ResumenBusqueda from "../ResumenBusqueda";
import SelectorFechas from "../../Utilidades/Selector/SelectorFecha/SelectorFecha";
import { FormatoFechaDDMMYYYY, GetAntecesorWhithClassName } from "../../../services/utils";
import SelectorUno2 from "../../Utilidades/Selector/SelectorUno2";

const FBAlertasCalidad= (props) => {
    const [datosFormulario, setDatosFormulario] = useState({
        listaLab: null,
        listaPA: null,
    });
    const [seleccionFormulario, setSeleccionFormulario] = useState({
        txt: props.txt,
        txtLote: props.txtLote,
        idLab: props.idLab,
        idPA: props.idPA,
        inicio: props.inicio,
        fin: props.fin,
    })

    const tituloPA = (props.tipo === "F" ? "Ingrediente" : "Principio activo")
    const placeholderNombre = (props.tipo === "F" ? "Escribe nombre producto" : "Escribe nombre medicamento")


    useEffect(() => {
        if (datosFormulario.listaPA === null)
        {
            DescargaDatos();
        }        
    },[]);

    const DescargaDatos = () => {      
        BP2022Service.datosFormularioAlertasCalidad(props.tipo)
        .then((response) => {
            var df = {
                listaPA : response.data.PrincipiosActivos,
                listaLab : response.data.Laboratorios,  
            };
            df.listaLab.splice(0,0,{Codigo:0, Valor:'Indique un laboratorio'});
            df.listaPA.splice(0,0,{Codigo:0, Valor:'Indique un ' + tituloPA.toLowerCase()});
            setDatosFormulario(df);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };


    const construyeResumenBusqueda = () => {
        var res = [];
        if (seleccionFormulario.txt && seleccionFormulario.txt != ""){
            res.push ({titulo: 'Nombre contiene', valor: seleccionFormulario.txt});
        }

        if (seleccionFormulario.txtLote && seleccionFormulario.txtLote != ""){
            res.push ({titulo: 'Número de lote contiene', valor: seleccionFormulario.txtLote});
        }

        if (seleccionFormulario.idLab>0){
            if (datosFormulario.listaLab){
                var item = datosFormulario.listaLab.find(x => x.Codigo === seleccionFormulario.idLab);
                if (item)
                    res.push ({titulo: "Laboratorio", valor: item.Valor});
            }
        }

        if (seleccionFormulario.idPA>0){
            if (datosFormulario.listaPA){
                var item = datosFormulario.listaPA.find(x => x.Codigo === seleccionFormulario.idPA);
                if (item)
                    res.push ({titulo: "Principio activo", valor: item.Valor});
            }
        }

        if (seleccionFormulario.inicio){
            if (seleccionFormulario.fin){
                res.push ({titulo: "Fecha de la alerta", valor: 'entre las fechas ' + FormatoFechaDDMMYYYY(seleccionFormulario.inicio) + ' y ' + FormatoFechaDDMMYYYY(seleccionFormulario.fin)});
            }else{
                res.push ({titulo: "Fecha de la alerta", valor: 'posterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.inicio)});
            }
        }else if (seleccionFormulario.fin){
            res.push ({titulo: "Fecha de la alerta", valor: 'anterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.fin)});
        }

        
        return res;
    }

    const resumen = construyeResumenBusqueda(seleccionFormulario);

    const busqueda = (props.tipo === "F" ? "BAlertasCPPF" : props.tipo === "V" ? "BAlertasCMUV" : "BAlertasCMUH")

    const hayBusqueda = true;

    const clickBuscar = (e) =>{
        props.cambiaPagina({
            newpagina: busqueda,
            txt: seleccionFormulario.txt,
            txtLote: seleccionFormulario.txtLote,
            idLab: seleccionFormulario.idLab,
            idPA: seleccionFormulario.idPA,
            inicio: seleccionFormulario.inicio,
            fin: seleccionFormulario.fin,
        })
    }

    const BorraBusqueda = (e) =>{
        const formularioVacio={
            txt: '',
            txtLote: '',
            idLab: 0,
            idPA: 0,
            inicio: null,
            fin: null,
        }
        setSeleccionFormulario(formularioVacio);
        var inputs = document.getElementsByClassName('inputSelector');
        if (inputs){
            for (var i=0; i<inputs.length; i++){
                inputs[i].value = "";
                const itemContainer = GetAntecesorWhithClassName(inputs[i], 'custom-select-container');
                if (itemContainer){
                    itemContainer.classList.remove('active');
                }
            }
        }
    };

    const copiaFechas = (origen,destino) =>{
        destino.fin = origen.fin;
        destino.inicio = origen.inicio;
    }

    const CambioFecha= (inicio, fin) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.inicio = inicio;
        nuevaSeleccion.fin = fin;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioLaboratorio = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.idLab = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const BorraLaboratorio = () =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.idLab = 0;
        setSeleccionFormulario(nuevaSeleccion);
    }
    const CambioPA = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.idPA = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const BorraPA = () =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.idPA = 0;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambiaTxt = (e) =>{
        const item = e.target;
        if (item){
            const valor = item.value;
            const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
            copiaFechas(seleccionFormulario, nuevaSeleccion);
            nuevaSeleccion.txt = valor;
            setSeleccionFormulario(nuevaSeleccion);
        }
    }

    const CambiaTxtLote = (e) =>{
        const item = e.target;
        if (item){
            const valor = item.value;
            const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
            copiaFechas(seleccionFormulario, nuevaSeleccion);
            nuevaSeleccion.txtLote = valor;
            setSeleccionFormulario(nuevaSeleccion);
        }
    }

    const KeyDownForm = (e) =>{
        var keyCode = e.keyCode;
        if (keyCode === 13){
            clickBuscar(e);
        }
    }

    const estiloBotonesSup = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'space-between'} : {height:'76px', width:'100%', justifyContent: 'space-between', visibility:'hidden'})
    const estiloBotonesInf = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'center'} : {height:'76px', width:'100%', justifyContent: 'center', display:'none'})


    const listaCodValorLab = (datosFormulario.listaLab) ? datosFormulario.listaLab.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorPA = (datosFormulario.listaPA) ? datosFormulario.listaPA.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    
    const clasedivmodulefilters = "cover cover__filters cover__filters__2 cover__filters__2--1 cover__filters__2--1__50 " ;

    return (
        <div class="content" >

            { (datosFormulario.arbol === null) ?
                <LoadingPopup titulo="Un momento" texto="Preparando formulario" esPorcentaje={true}></LoadingPopup>
            :
                <form class="content__results content__results__filters " id="main-filter"  onSubmit={(e) => e.preventDefault()}  onKeyDown={(e) => KeyDownForm(e)} tabIndex="0">
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesSup}>
                        <span class="button button__arrow button__green noUppercase reset-form" data-form="#main-filter" title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} style={{visibility:'hidden'}} >
                            Vaciar búsqueda
                        </span>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                        <span class="button button__arrow button__green reset-form" data-form="#main-filter" style={{textTransform:'none', fontSize: '14px', width:'146px'}} title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} >
                            Vaciar búsqueda
                        </span>
                    </div>

                    <div class="cover cover__filters cover__filters__2 cover__filters__2--1"  >
                        <div class="coverModule">
                            <h3 class="title fs fs16 greyColor2">Nombre</h3>
                            <div class="module module__filters">
                                <div class="box box__inputs">
                                    <div class="cover cover__pad10">
                                        <input type="text" name="" value={seleccionFormulario.txt} placeholder={placeholderNombre}   onChange={(e) => CambiaTxt(e)}/ >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="coverModule">
                            <h3 class="title fs fs16 greyColor2">Número de lote</h3>
                            <div class="module module__filters">
                                <div class="box box__inputs">
                                    <div class="cover cover__pad10">
                                        <input type="text" name="" value={seleccionFormulario.txtLote} placeholder="Escribe número de lote"   onChange={(e) => CambiaTxtLote(e)}/ >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class={clasedivmodulefilters} >
                    <div class="module module__filters">
                            <h3 class="title fs fs16 greyColor2" >Laboratorio</h3>
                            <SelectorUno2 lista = {listaCodValorLab} 
                                        idHTML="FBPSumLab" 
                                        valueSelected={seleccionFormulario.idLab}
                                        alCambiar={CambioLaboratorio} 
                                        alBorrar={BorraLaboratorio} 
                                        ancho="100%"
                            ></SelectorUno2>
                        </div>
                        <div class="module module__filters">
                            <h3 class="title fs fs16 greyColor2" >{tituloPA}</h3>
                            <SelectorUno2 lista = {listaCodValorPA} 
                                        idHTML="FBPSumPA" 
                                        valueSelected={seleccionFormulario.idPA}
                                        alCambiar={CambioPA} 
                                        alBorrar={BorraPA} 
                                        ancho="100%"
                            ></SelectorUno2>
                        </div>
                    </div>
                    <div class="cover cover__filters cover__filters__2 cover__filters__2--1 cover__filters__2--1__50 calendars" >
                        <SelectorFechas 
                                    titulo="Fecha de la alerta" 
                                    alCambiar={CambioFecha} 
                                    inicio={seleccionFormulario.inicio} 
                                    fin={seleccionFormulario.fin}
                                    idHTML = "FBAC_Inicio"
                        ></SelectorFechas>
                    </div>

                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesInf}>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                    </div>
                    <ResumenBusqueda condiciones={resumen}></ResumenBusqueda>
                </form>
            }
        </div>
    );
};

export default FBAlertasCalidad;
