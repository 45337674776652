import LoadingPopup from "../../Resultados/Loading.Popup";
import BP2022Service from "../../../services/user.service";
import { useEffect, useState } from "react";
import ResumenBusqueda from "../ResumenBusqueda";
import SelectorComposicion from "../../Utilidades/Selector/SelectorComposicion/SelectorComposicion";
import SelectorComboBox from "../../Utilidades/Selector/SelectorComboBox";
import { GetAntecesorWhithClassName, upperCaseFirstLetter } from "../../../services/utils";
import SelectorUno2 from "../../Utilidades/Selector/SelectorUno2";
import { OpcionesADM, OpcionesDG, OpcionesDISP, OpcionesESP, OpcionesOTR, OpcionesPAC, OpcionesPacienteProductosIndi, OpcionesPatologiasPI } from "../../../services/objsAplicacion";
import SelectorCHK from "../../Utilidades/Selector/SelectorCHK";
import SelectorSNX from "../../Utilidades/Selector/SelectorSNX";
import Acordeon from "../../Fichas/FichaMUH/Apartados/Composicion/Acordeon";
import SelectorEdad from "../../Utilidades/Selector/SelectorEdad/SelectorEdad";
import SelectorEnfRel from "../../Utilidades/Selector/SelectorEnfRel/SelectorEnfRel";
import ItemResumenFiltros from "../../Resultados/FiltrosYBusqueda/ResumenFiltros/ItemResumenFiltros";
import SelectorMulti2 from "../../Utilidades/Selector/SelectorMulti2";

const FBProductosIndicacion= (props) => {
    const [datosFormulario, setDatosFormulario] = useState({
        listaEnfs: null,
        listaFFs: null,
        listaPAs: null,
        listaIntsV: null,
        listaIntsNV: null,
    });
    const [seleccionFormulario, setSeleccionFormulario] = useState({
        idEnf: props.idEnf,
        idFF: props.idFF,
        pas: props.pas,
        ints: props.ints,
        ops: (props.ops ? props.ops : []),
    })

    const [ocultaIntolerancias, setOcultaIntolerancias] = useState(true);

    const claseIntoleranciasOcultas = (ocultaIntolerancias) ? "cover hide" : "cover hide active";
    const claseBotonIntolerancias = (ocultaIntolerancias) ? "button button__arrow button__purple__line noUppercase verMas" : "button button__arrow button__purple__line noUppercase verMas active";
    const txtbotonIntolerancias = (ocultaIntolerancias) ? "Ver más" : "Ver menos";

    useEffect(() => {
        if (datosFormulario.listaEnfs === null){
            DescargaDatos();
        }   
    },[]);

    const DescargaDatos = () => {      
        BP2022Service.datosFormularioProductosIndicacion()
        .then((response) => {
            var df = {
                listaEnfs : response.data.Enfermedades,
                listaFFs : response.data.FormasFarma,
                listaPAs : response.data.Componentes,
                listaIntsV : response.data.IntoleranciasV,
                listaIntsNV : response.data.IntoleranciasNoV,
            };
            df.listaEnfs.splice(0,0,{Codigo:0, Valor:'Indique una enfermedad '});
            df.listaFFs.splice(0,0,{Codigo:-1, Valor:'Seleccione una forma farmacéutica'},{Codigo:0, Valor:'-'});
            df.listaPAs.splice(0,0,{Codigo:0, Valor:'Indique un ingrediente '});
            setDatosFormulario(df);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const clickVerIntolerancias=(e) =>{
        setOcultaIntolerancias(!ocultaIntolerancias);
    }

    const obtenDatoResumenPaciente = (itemOpcion) =>{
        var strValores=[]
        var itemSel = seleccionFormulario.ops.find(item => item.cod===itemOpcion.cod);
        if (itemSel && itemSel.valores && itemSel.valores.length>0){
            var strVal = "";
            for(var j=0; j<itemSel.valores.length; j++){
                if (j>0) strVal += ", ";
                else strVal += "";
                var valSel = itemOpcion.ops.find(val=> val.val === itemSel.valores[j]);
                strVal += valSel.tit;
            }
            if (strVal !== "")
                strValores.push(strVal);
        }

        var res="";
        if (strValores.length>0){
            var res = strValores[0];
            for (var i=1; i<strValores.length; i++){
                res += "\n" + strValores[i];
            }
        }
        return res;
    }

    const hayBusqueda = (seleccionFormulario.idEnf>0) || (seleccionFormulario.idFF>0) 
                        || (seleccionFormulario.pas && seleccionFormulario.pas.length>0) 

    const construyeResumenBusqueda = () => {
        var res = [];

        if (hayBusqueda){
            if (datosFormulario.listaEnfs && seleccionFormulario.idEnf>0){
                var item = datosFormulario.listaEnfs.find(x => x.Codigo === seleccionFormulario.idEnf);
                if (item)
                    res.push ({titulo: "Productos indicados para ", valor: item.Valor});
            }

            if (seleccionFormulario.idFF>0){
                if (datosFormulario.listaFFs){
                    var item = datosFormulario.listaFFs.find(x => x.Codigo === seleccionFormulario.idFF);
                    if (item)
                        res.push ({titulo: "Forma farmacéutica", valor: item.Valor});
                }
            }
        
            if ((seleccionFormulario.pas) && (seleccionFormulario.pas.length>0)){
                if (datosFormulario.listaPAs){
                    var titulo = "Composición";
                    for (var i =0; i<seleccionFormulario.pas.length; i++){
                        var valor = '';
                        var item = datosFormulario.listaPAs.find(x => x.Codigo === Math.abs(seleccionFormulario.pas[i].codigo));
                        if (item){
                            if (seleccionFormulario.pas[i].codigo<0)
                                valor += "No contiene ";
                            valor += item.Valor;
                            var sales = seleccionFormulario.pas[i].sales;
                            if (sales && sales.length>0){
                                for (var j=0; j<sales.length; j++){
                                    if (j>0)
                                        valor += " /"
                                    else
                                        valor += "        "
                                    valor += " " + sales[j].valor;
                                }
                            }    
                            var cants = seleccionFormulario.pas[i].cants;
                            if (cants && cants.length>0){
                                for (var j=0; j<cants.length; j++){
                                    if (j>0)
                                        valor += " /"
                                    else
                                        valor += "        "
                                    valor += " " + cants[j].des;
                                }
                            }    
                        }
                        res.push({titulo:titulo, valor: valor});
                        titulo = "";
                    }
                }
            }

            var valor = obtenDatoResumenPaciente(OpcionesPacienteProductosIndi[0]);
            if (valor != "")
                res.push ({titulo: "Características del paciente", valor: valor});
    
            if ((seleccionFormulario.ints) && (seleccionFormulario.ints.length>0)){
                if (datosFormulario.listaIntsV){
                    var valor = '';
                    for (var i =0; i<seleccionFormulario.ints.length; i++){
                        var item = datosFormulario.listaIntsV.find(x => x.Codigo === seleccionFormulario.ints[i]);
                        if (!item) item = datosFormulario.listaIntsNV.find(x => x.Codigo === seleccionFormulario.ints[i]);
                        if (item){
                            if (valor !== '')
                                valor += ", ";
                            valor += item.Valor;
                        }
                    }
                    if (valor != '')
                        res.push ({titulo: "Intolerancias del paciente", valor: valor});
                }
            }
    
        }
        return res;
    }

    var resumen = construyeResumenBusqueda(seleccionFormulario);

    const clickBuscar = (e) =>{
        if  ( hayBusqueda){
            var newpagina = "BProductosIndicacionPPF";

            props.cambiaPagina({
                newpagina: newpagina,
                idEnf: seleccionFormulario.idEnf,
                idFF: seleccionFormulario.idFF,
                pas: seleccionFormulario.pas,
                ints: seleccionFormulario.ints,
                ops: seleccionFormulario.ops,
            })
        }
    }

    const BorraBusqueda = (e) =>{
        const formularioVacio={
            idEnf: 0,
            idFF: 0,
            pas: [],
            ints: [],
            ops: [],
        }
        setSeleccionFormulario(formularioVacio);
        var inputs = document.getElementsByClassName('inputSelector');
        if (inputs){
            for (var i=0; i<inputs.length; i++){
                inputs[i].value = "";
                const itemContainer = GetAntecesorWhithClassName(inputs[i], 'custom-select-container');
                if (itemContainer){
                    itemContainer.classList.remove('active');
                }
            }
        }
    }

    const CambioEnfermedad = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idEnf = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const BorraEnfermedad = () =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idEnf = 0;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddIngrediente = (idPA) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (!pa){
            nuevaSeleccion.pas.push({
                codigo: idPA,
                sales: [],
                cants: [],
            })
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveIngrediente = (idPA) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.pas = nuevaSeleccion.pas.filter(item => Math.abs(item.codigo)!==Math.abs(idPA));
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioFF = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idFF = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioIntolerancia = (e, idInt) =>{
        var item = e.target;
        var idx = (seleccionFormulario.ints) ? seleccionFormulario.ints.indexOf(idInt) : -1;
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (item.checked){
            if (!nuevaSeleccion.ints) nuevaSeleccion.ints = [];
            if (idx<0) nuevaSeleccion.ints.push(idInt);
        }else{
            if (idx>=0) nuevaSeleccion.ints.splice(idx,1);
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambiaValorSNX = (valorSNX, idSNX) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (!nuevaSeleccion.ops)
            nuevaSeleccion.ops = [];
        var item = nuevaSeleccion.ops.find(it => it.cod===idSNX);
        if (item){
            if (valorSNX !== 'X'){
                item.valor = valorSNX;
            }else{
                var idx = nuevaSeleccion.ops.indexOf(item);
                nuevaSeleccion.ops.splice(idx,1);
            }
        }else{
            if (valorSNX !=='X'){
                nuevaSeleccion.ops.push({cod:idSNX, valor:valorSNX});
            }
        }
        setSeleccionFormulario(nuevaSeleccion);
    }


    const CambiaValorCHK = (valoresCHK,idCHK) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (!nuevaSeleccion.ops)
            nuevaSeleccion.ops = [];
        var item = nuevaSeleccion.ops.find(it => it.cod===idCHK);
        if (item){
            if (valoresCHK.length>0){
                item.valores = valoresCHK;
            }else{
                var idx = nuevaSeleccion.ops.indexOf(item);
                nuevaSeleccion.ops.splice(idx,1);
            }
        }else{
            if (valoresCHK.length>0){
                var newitem = {
                    cod: idCHK,
                    valores: valoresCHK,
                }
                nuevaSeleccion.ops.push(newitem);
            }            
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const KeyDownForm = (e) =>{
        var keyCode = e.keyCode;
        if (keyCode === 13){
            clickBuscar(e);
        }
    }



    const listaCodValorEnfs   = (datosFormulario.listaEnfs) ? datosFormulario.listaEnfs.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorFFs    = (datosFormulario.listaFFs)  ? datosFormulario.listaFFs.map(it =>  ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaIngredientes   = (datosFormulario.listaPAs)  ? datosFormulario.listaPAs.map(it =>  ({codigo: it.Codigo, valor: it.Valor})) : [];

    const HTMLIntoleranciasV = (((datosFormulario.listaIntsV) && (datosFormulario.listaIntsV.length>0)) ?
        datosFormulario.listaIntsV.map(int => 
            <label>
                <input type="checkbox" value={int.Codigo} checked = {(seleccionFormulario.ints && seleccionFormulario.ints.indexOf(int.Codigo)>=0)} onClick={(e) => CambioIntolerancia(e, int.Codigo)} />
                {upperCaseFirstLetter(int.Valor)}
            </label>
        )
    :
        <div></div>
    );

    const HTMLIntoleranciasNV = (((datosFormulario.listaIntsNV) && (datosFormulario.listaIntsNV.length>0)) ?
        datosFormulario.listaIntsNV.map(int => 
            <label>
                <input type="checkbox" value={int.Codigo} checked = {(seleccionFormulario.ints && seleccionFormulario.ints.indexOf(int.Codigo)>=0)} onClick={(e) => CambioIntolerancia(e, int.Codigo)} />
                {upperCaseFirstLetter(int.Valor)}
            </label>
        )
    :
        <div></div>
    );

    const clasedivmodulefilters = "cover cover__filters cover__filters__2 cover__filters__2--1 cover__filters__2--1__50";


    const HTMLOpcionesPac = (
        <div class="cover cover__filters cover__filters__2" style={{marginBottom:'0px'}}>
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===OpcionesPacienteProductosIndi[0].cod) ? seleccionFormulario.ops.find(it => it.cod===OpcionesPacienteProductosIndi[0].cod).valores : [])}
                opcionesCHK = {OpcionesPacienteProductosIndi[0].ops}
                titulo = {OpcionesPacienteProductosIndi[0].tit}
                idOrigen = {OpcionesPacienteProductosIndi[0].cod}
                alCambiar = {CambiaValorCHK}
            />
        </div>
    );

    const HTMLDatosGeneral = (
        <div>
            <div class="cover cover__filters cover__filters__2 cover__filters__2--1" >
                <div class="module module__filters" >
                    <h2 class="superTitle fs fs20 purpleColor" >Situación del paciente</h2>
                    
                    <SelectorUno2 lista = {listaCodValorEnfs} 
                                idHTML="FBPacIndMUHLInd" 
                                valueSelected={seleccionFormulario.idEnf}
                                alCambiar={CambioEnfermedad} 
                                alBorrar={BorraEnfermedad} 
                                ancho="100%"
                    ></SelectorUno2>
                </div>
            </div>
            <div class="cover cover__title" style={{marginBottom: '22px'}} >
                <h2 class="title fs fs20 purpleColor" >Características del producto</h2>
            </div>
            <div class="cover cover__filters cover__filters__2 cover__filters__2--1" >
                <div class="module module__filters" >
                    <h3 class="title fs fs16 greyColor2" style={{marginBottom:'12px'}}>Ingredientes</h3>
                    <SelectorMulti2 
                                lista = {listaIngredientes} 
                                idHTML="FBProdIndLIng" 
                                valuesSelected={seleccionFormulario.pas.map(pa=>pa.codigo)}
                                alAnadir={AddIngrediente }
                                alBorrar={RemoveIngrediente} 
                                ancho="100%"
                                tipoFiltradoLista={1}
                    ></SelectorMulti2>
                </div>
            </div>
            <div class="cover cover__filters cover__filters__2 cover__filters__2--1" >
                <div class="module module__filters">
                    <h3 class="title fs fs16 greyColor2" style={{marginBottom:'12px'}}>Forma farmacéutica</h3>
                    <SelectorComboBox lista = {listaCodValorFFs} 
                            idHTML="FBComposFF" 
                            valueSelected={seleccionFormulario.idFF} 
                            alCambiar={CambioFF} 
                            ancho="100%"
                    ></SelectorComboBox>
                </div>
            </div>
            <div class="cover cover__title" style={{marginBottom: '22px'}} >
                <h2 class="title fs fs20 purpleColor" >Características del paciente</h2>
            </div>
            <div>
                {HTMLOpcionesPac}
            </div>
            <div>
                <div class="cover cover__filters cover__filters__checks" >
                    <div class="module module__checks"style={{width:'100%'}}>
                        <h3 class="fs fs16 greyColor2 title" >Intolerancias</h3>
                        <div class="checks" >
                            <div class="cover" >
                                {HTMLIntoleranciasV}
                            </div>
                            <div class={claseIntoleranciasOcultas} id="IntoleranciasOcultas">
                                {HTMLIntoleranciasNV}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="module module__buttons module__buttons__buscar">
                    <span class={claseBotonIntolerancias}   onClick={(e) => clickVerIntolerancias(e)}>{txtbotonIntolerancias} <img src="./img/arrowPurpleDown2.svg" alt="arrowPurpleDown2" /></span>
                </div>
            </div>
        </div>
    );

    const estiloBotonesSup = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'space-between'} : {height:'76px', width:'100%', justifyContent: 'space-between', visibility:'hidden'})
    const estiloBotonesInf = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'center'} : {height:'76px', width:'100%', justifyContent: 'center', display:'none'})

    return(
        <div class="content" >
            { (datosFormulario.listaEnfs === null) ?
                <LoadingPopup titulo="Un momento" texto="Preparando formulario" esPorcentaje={true}></LoadingPopup>
            :
                <form class="content__results content__results__filters" id="main-filter"  onSubmit={(e) => e.preventDefault()}  onKeyDown={(e) => KeyDownForm(e)} tabIndex="0">
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesSup}>
                        <span class="button button__arrow button__green noUppercase reset-form" data-form="#main-filter" title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} style={{visibility:'hidden'}} >
                            Vaciar búsqueda
                        </span>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                        <span class="button button__arrow button__green reset-form" data-form="#main-filter" style={{textTransform:'none', fontSize: '14px', width:'146px'}} title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} >
                            Vaciar búsqueda
                        </span>
                    </div>
                    {HTMLDatosGeneral}
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesInf}>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                    </div>
                    <ResumenBusqueda condiciones={resumen}></ResumenBusqueda>
                </form>
            }
        </div>
    );
}
    
export default FBProductosIndicacion;
    