import { apiProdPath, FormatoFechaDDMMYYYY, numberWithCommas } from "../../../../../services/utils";
import FilaBloquePrecios from "../InfoGeneral/FilaBloquePrecios";

const AgrupacionHomogenea = (props) => {
    const tienePreciosFuturos = (props.agrupFutura != null);
    const claseDivTabla = (tienePreciosFuturos ? "tablaConPF" : "tablaSinPF");
    var Filas = [];
    const busqueda = ((props.agrup && props.agrup.IdCCAA > 0 ) || (props.agrupFutura && props.agrupFutura.IdCCAA > 0 )) ? "BAgrupHomoAuto" + props.tipoReg : "BAgrupHomo" + props.tipoReg;

    const literalAgrupacion = (props.tipoReg === "FM")||(props.tipoReg === "PO") ? "Fórmula codificada receta electrónica" : "Agrupación homogénea";
    var literalFecha = ""
    if (!props.agrup && props.agrupFutura && props.fecha)
        literalFecha += " a partir de " + FormatoFechaDDMMYYYY(props.fecha);


    if (tienePreciosFuturos){
        if ((props.agrup && props.agrup.PVPMenor>0)||(props.agrupFutura.PVPMenor>0)){
            Filas.push(
                {
                    titulo: (props.agrup) ? props.agrup.LiteralPMenor : props.agrupFutura.LiteralPMenor,
                    valor1: (props.agrup && props.agrup.PVPMenor >0) ? numberWithCommas(props.agrup.PVPMenor): '-',
                    valor2: props.agrupFutura.PVPMenor > 0 && (!props.agrup || props.agrupFutura.PVPMenor !== props.agrup.PVPMenor) ? 
                            numberWithCommas(props.agrupFutura.PVPMenor) : 
                            props.agrupFutura.PVPMenor === -1 ?
                            'Desaparece' :
                            ' ',
                    valor3: 'EUR',
                    colSpan:false,
                }
            )
        }
        if ((props.agrup && props.agrup.PVPMasBajo>0)||(props.agrupFutura.PVPMasBajo>0)){
            Filas.push(
                {
                    titulo: (props.agrup) ? props.agrup.LiteralPMasBajo : props.agrupFutura.LiteralPMasBajo,
                    valor1: (props.agrup && props.agrup.PVPMasBajo >0) ? numberWithCommas(props.agrup.PVPMasBajo): '-',
                    valor2: props.agrupFutura.PVPMasBajo > 0 && (!props.agrup || props.agrupFutura.PVPMasBajo !== props.agrup.PVPMasBajo) ? 
                            numberWithCommas(props.agrupFutura.PVPMasBajo) : 
                            props.agrupFutura.PVPMasBajo === -1 ?
                            'Desaparece' :
                            ' ',
                    valor3: 'EUR',
                    colSpan:false,
                }
            )
        }
        if (props.mostrarPExtra && props.agrup && props.agrup.PVPExtra>0){
            Filas.push(
                {
                    titulo: props.agrup ? props.agrup.LiteralPExtra : props.agrupFutura.LiteralPExtra,
                    valor1: (props.agrup && props.agrup.PVPExtra >0) ? numberWithCommas(props.agrup.PVPExtra): '-',
                    valor2: props.agrupFutura.PVPExtra > 0 && (!props.agrup || props.agrupFutura.PVPExtra !== props.agrup.PVPExtra) ? 
                            numberWithCommas(props.agrupFutura.PVPExtra) : 
                            props.agrupFutura.PVPExtra === -1 ?
                            'Desaparece' :
                            ' ',
                    valor3: 'EUR',
                    colSpan:false,
                }
            )
        }
    }
    else{
        if (props.agrup.PVPMenor>0){
            Filas.push(
                {
                    titulo: props.agrup.LiteralPMenor,
                    valor1: props.agrup.PVPMenor >0 ? numberWithCommas(props.agrup.PVPMenor): '-',
                    valor2: 'EUR',
                    colSpan:false,
                }
            )
        }
        if (props.agrup.PVPMasBajo>0){
            Filas.push(
                {
                    titulo: props.agrup.LiteralPMasBajo,
                    valor1: props.agrup.PVPMasBajo >0 ? numberWithCommas(props.agrup.PVPMasBajo): '-',
                    valor2: 'EUR',
                    colSpan:false,
                }
            )
        }
        if (props.mostrarPExtra && props.agrup.PVPExtra>0){
            Filas.push(
                {
                    titulo: props.agrup.LiteralPExtra,
                    valor1: props.agrup.PVPExtra >0 ? numberWithCommas(props.agrup.PVPExtra): '-',
                    valor2: 'EUR',
                    colSpan:false,
                }
            )
        }
    }

    const TablaHTML = Filas.map(fila => <FilaBloquePrecios fila = {fila} tienePFuturos={tienePreciosFuturos}></FilaBloquePrecios>);

    const CambiaPaginaBusquedaGrupo = (id) => {
        props.cambiaPagina({
            newpagina: busqueda,
            newid: id,
        });
    }

    const estilobordes = (props.noBordeSup) ? {borderTop:'none'} : {};

    return (
        <div class="module module__infoColumns" style={estilobordes}> 
            <div class="text" >
                <h3 class="fs fs16 fw fw600 greyColor2 title" >{literalAgrupacion} {props.ccaa} {literalFecha}</h3>
                <p class="fs fs14 fw greenColor4 uppercase" style={{cursor:'pointer', color:'#5C9029'}} 
                    onClick={(e) => CambiaPaginaBusquedaGrupo(props.agrup.Codigo)}
                    onMouseOver={(e) => e.target.style.fontWeight='bold'}
                    onMouseOut={(e) => e.target.style.fontWeight='normal'}
                >
                    {(props.agrup)?
                    props.agrup.Codigo + ' ' +props.agrup.Nombre
                    :
                    props.agrupFutura.Codigo + ' ' +props.agrupFutura.Nombre
                    }
                </p>
            </div>
            <div class={claseDivTabla}>
                {TablaHTML}
            </div>
        </div> 
    )
}
export default AgrupacionHomogenea;
