import FilaDDD from "./FilaDDD";


const DDD = (props) => {
    const datosDDD = props.datosDDD;
    const HTMLDDD = datosDDD.map(ddd => <FilaDDD dosificacion={ddd} ></FilaDDD>);
    return (
        <div class="infoContent" >
            <div class="table" style={{width:'100%'}}>
                <div class="table__row table__row__header table__row__header__noBg fs fs12 fw fw600 greenColor uppercase">
                    <div class="column" >
                        <p>Dosis</p>
                    </div>
                    <div class="column" >
                        <p>Unidades</p>
                    </div>
                    <div class="column" >
                        <p>Via</p>
                    </div>
                </div>
                {HTMLDDD}
            </div>
        </div>
    )
}
export default DDD;
