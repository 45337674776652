import axios from "axios";
import authHeader, { noAuthHeader } from "./auth-header";
import { getApiPath } from "./utils";

const API_URL = getApiPath() + "login/";


const login = (username, password, fb, ma) => {
    return axios
        .post(API_URL + "BotplusAuthenticate", {
            Username : username,
            Password : password,
            FuerzaBruta : fb,
            MantenerAbierta : ma
        }, { headers: noAuthHeader() })
        .then((response) => {
            if (response.data.Token && (response.data.Status === 0 || response.data.Status === '0')) {
                localStorage.setItem("login", JSON.stringify(response.data));
            }
            return response.data;
        });
};

const logout = () => {
    try{
        axios.get(API_URL + "CloseBotPlusSession", { headers: authHeader() })
            .then((response) => {
                window.location.reload();
            })
    }
    catch (error) {}
    localStorage.removeItem("login");
};
  
const getLocalUser = () => {
    var user = JSON.parse(localStorage.getItem("login"));
    if (user)
    {
        return user;
    }
};


const getNetworkUser = () => {
    return axios.get(API_URL +  'GetBotPlusUser', { headers: authHeader() })
        .then((response) => {
            if (response.data.Token && (response.data.Status === 0 || response.data.Status === '0')) {
                //localStorage.setItem("login", JSON.stringify(response.data));
            }
            return response.data;
        });
}

const openDeferredSesion = (token) => {
    return axios.get(API_URL +  'OpenDeferredSession', { headers: {'Authorization': 'Bearer ' + token} })
        .then((response) => {
            if (response.data.Token && (response.data.Status === 0 || response.data.Status === '0')) {
                localStorage.setItem("login", JSON.stringify(response.data));
            }
            return response.data;
        });
}

const getSpecialAuthentication = (str) => {
    return axios.get(API_URL +  'SpecialAuthentication?keypass=' + str)
        .then((response) => {
            if (response.data.Token && (response.data.Status === 0 || response.data.Status === '0')) {
                //localStorage.setItem("login", JSON.stringify(response.data));
            }
            return response.data;
        });
}

const getIPAuthentication = () => {
    return axios.get(API_URL +  'IPAuthentication')
        .then((response) => {
            if (response.data.Token && (response.data.Status === 0 || response.data.Status === '0')) {
            }
            return response.data;
        });
}

const buscaClaves = (dni, nfact) => {
    return axios.get(API_URL +  'BuscaClaves?dni='+dni+'&nfac='+nfact)
        .then((response) => {
            return response.data;
        });
}

const infoCredenciales = (str) => {
    return axios.get(API_URL +  'CheckCredenciales?a='+str)
        .then((response) => {
            return response.data;
        });
}

const guardaDatosCorreo = (cliente, correo, desea) => {
    return axios.post(API_URL +  'GuardaDatosCorreo',{Cliente: cliente, Correo: correo, Desea: desea});
}

const actualizaKey = (idPedido) => {
    return axios.post(API_URL +  'ActualizaKey',{IdPedido: idPedido});
}


const isSesionOk = () => {
    return axios.get(API_URL +  'GetSesionStatus', { headers: authHeader() })
        .then((response) => {
            if (response.data === 0 || response.data === '0') {
                //localStorage.setItem("login", JSON.stringify(response.data));
                return true;
            }
            return false;
        });
}

const tieneLicenciaLocal = () => {
    return axios.get(API_URL +  'TieneLicenciaLocal', { headers: authHeader() })
        .then((response) => {
            if (response.data) {
                //localStorage.setItem("login", JSON.stringify(response.data));
                return true;
            }
            return false;
        });
}

const getUserRol = () =>{
    var user = getLocalUser();
    var rol = '';
    if (user)
        if (user.botPlusUser)
            if (user.botPlusUser.Rol)
                rol = user.botPlusUser.Rol;
    return rol;
}

const aceptaLicencia = () => {
    return axios.get(API_URL +  'AceptaLicencia', { headers: authHeader() })
}

const moduloCambioPassAnonimo = () => {
    return axios.get(getApiPath() + "BotPlus2022/HayModuloCambioPass");
 };
  


const loginService = {
    login,
    logout,
    getLocalUser,
    getNetworkUser,
    getSpecialAuthentication,
    getIPAuthentication,
    buscaClaves,
    guardaDatosCorreo,
    actualizaKey,
    isSesionOk,
    getUserRol,
    infoCredenciales,
    tieneLicenciaLocal,
    aceptaLicencia,
    openDeferredSesion,
    moduloCambioPassAnonimo,

  };
  
export default loginService;