const GrupoPolimorfismos = (props) => {
    const grupo = props.grupo;
    const polimorfismos = grupo.Polimorfismos.map(item => <span>{item}<br /></span>);
    const recos = grupo.RecomendacionesTerapeuticas.map(item => <span>{item}<br /></span>);
    const estiloUltimaFila = {borderBottom: '1px solid #e8e8e8'};
    //const estiloUltimaFila = {};
    var estiloObservaciones = {};
    var estiloRecomendaciones = {};
    var estiloPrimeraFila = {};
    if (!props.ultimaFila){
        if (grupo.Observaciones && grupo.Observaciones !== '')
            estiloObservaciones = estiloUltimaFila;
        else if (grupo.RecomendacionesTerapeuticas.length>0)
            estiloRecomendaciones = {};//estiloUltimaFila;
        else
            estiloPrimeraFila = estiloUltimaFila;
    }

    return (
        <div>
            <div class="table__row table__row__normal fw fw300 uppercase" style={estiloPrimeraFila}>
                <div class="column fs fs12 fw fw300 FlamaBook" >
                    <p>
                        {polimorfismos}
                    </p>
                </div>
                <div class="column fs fs12 fw fw300 FlamaBook" >
                    <p>{grupo.Fenotipo}</p>
                </div>
                <div class="column fs fs12 fw fw300 FlamaBook" >
                    <p>{grupo.Efecto}</p>
                </div>
            </div>
            {(grupo.RecomendacionesTerapeuticas.length>0) ?
                <div class="table__row table__row__normal" title="Recomendaciones terapéuticas"  style={estiloRecomendaciones}>
                    <div class="column fs fs12 fw fw300 FlamaBook" >
                        <p style={{textAlign:'left'}}>
                            {recos}
                        </p>
                    </div>
                </div>
            :
                <div></div>
            }
            {(grupo.Observaciones && grupo.Observaciones !== '') ?
                <div class="table__row table__row__normal" title="Observaciones" style={estiloObservaciones}>
                    <div class="column fs fs14 fw fw300 FlamaBook blackColor" >
                        <p class="alignLeft" >{grupo.Observaciones}</p>
                    </div>
                </div>
            :
                <div></div>
            }
            
        </div>
    )
}
export default GrupoPolimorfismos;
