import Slider from 'react-slick'
import React, { useState, useRef, useEffect } from "react";
import { PerfilUsuario, divideArrayEnPartes, getIdCCAA, numberWithCommas, rootPath } from "../../../../services/utils";
import FilaPictogramas from "../InfoPrincipal/FilaPictogramas";

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import LinksFichaMUH from '../InfoPrincipal/LinksFichaMUH';
import Ficha from '../../Ficha';

const MargenDerecho = (props) => {
    //const [sliderRef, setSliderRef] = useState(null)
    //const [imgIndex,setImgIndex] = useState(0);
    const sliderRef = useRef();
    const ficha = props.ficha;
    const tienePFuturos = (ficha.PFuturos !== null) && (ficha.PFuturos.PVPIVA>0);
    const imgDefecto = rootPath + "img/person.svg" ;
    const srcImagen =   (props.ficha.ImagenEnvase != null ?
                            (props.ficha.ImagenEnvase.URLFull != null ?
                                props.ficha.ImagenEnvase.URLFull
                            : 
                                props.ficha.ImagenEnvase.URL
                            )
                        :
                            ''
                        );

    const srcImagenFF = (props.ficha.ImagenFormaF != null ?
                            (props.ficha.ImagenFormaF.URLFull != null ?
                                props.ficha.ImagenFormaF.URLFull
                            : 
                                props.ficha.ImagenFormaF.URL
                            )
                        :
                            ''
                        );
    const tieneImagenes = srcImagen !== '' || srcImagenFF !== '';    
    const arrayImgs = [];
    if (props.imgIndex === 1){
        if (srcImagenFF != '')
            arrayImgs.push(srcImagenFF);
        if (srcImagen != '')
            arrayImgs.push(srcImagen);
    }else{
        if (srcImagen != '')
        arrayImgs.push(srcImagen);
        if (srcImagenFF != '')
            arrayImgs.push(srcImagenFF);
    }



    useEffect(() => {
        //setImgIndex(props.imgIndex);
    },  [props.imgIndex]);
    

/*    const posicionaImagen = () =>  {
        if (sliderRef)
            if(sliderRef.current)
                if (props.imgIndex<arrayImgs.length)
                    sliderRef.current.slickGoTo(props.imgIndex);
    }               
  */  

    const imagenesHTML = tieneImagenes ? arrayImgs.map(srcImg => <div><img class="imagen__medicamento" src={srcImg} alt={srcImg} 
                                                                            style={{maxWidth:'200px'}}
                                                                            onClick={(e) => props.clickImagen(e, (props.imgIndex===1) ? (arrayImgs.indexOf(srcImg) + 1) % 2 : arrayImgs.indexOf(srcImg))}
                                                                 /></div>)
                                        : <div><p>&nbsp;</p><img class="imagen__medicamento" src={imgDefecto} style={{height:'180px', cursor:'default'}} /></div>                                        

    const sliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        speed: 300,
        arrows: true,
        touchMove: true,
        accessibility: true,
        adaptativeHeight: true,
        dots: false,
        focusOnSelect: true,
        className: 'single-item slide__1',
    }
                                                                                
    const estiloPreciosAltos = {fontSize:'14px'}
    const estiloVacio = {}
    const estiloPrecio = (ficha.PVPIVA>999999 ? estiloPreciosAltos : estiloVacio)

    const pictogramasEnFilas = divideArrayEnPartes(ficha.Pictogramas,5);    
    const pictogramas = pictogramasEnFilas.map( fila => <FilaPictogramas fila={fila}></FilaPictogramas>);

    const ambitoHospitalario = ((ficha.DatosF && ficha.DatosF.some(e => e.CodigoDato === 'H' || e.CodigoDato === 'EC') ? false : false));
    const notificado = ((ficha.DatosF && ficha.DatosF.some(e => e.CodigoDato === 'PN') ? true : false));

    var idCCAA = getIdCCAA();
    var literalPVPIVA = ((idCCAA === 5 || idCCAA === 18 || idCCAA === 19) ? 'PVP sin IVA': 'PVP con IVA');
    if (ambitoHospitalario) literalPVPIVA = (notificado) ? "PVL notificado":"PVL";

    var precioAMostrar = ((idCCAA === 5 || idCCAA === 18 || idCCAA === 19) ? ficha.PVPsinIVA: ficha.PVPIVA);
    if (ambitoHospitalario) precioAMostrar = ficha.PVL

    var precioFuturoAMostrar = 0;
    if (tienePFuturos){
        precioFuturoAMostrar = ((idCCAA === 5 || idCCAA === 18 || idCCAA === 19) ? ficha.PFuturos.PVPsinIVA: ficha.PFuturos.PVPIVA);
        if (ambitoHospitalario) precioFuturoAMostrar = ficha.PFuturos.PVL;
    }

    var textoDispensable = ficha.Dispensable.replace("Mutualidades","Mut").replace("SACYL no. ", "No/");
    textoDispensable = (textoDispensable && textoDispensable.length>2 && textoDispensable.substring(0,2)==="No" ? "No" : textoDispensable);

    return (
        <div class="medicamento">
            <div class="nombre f-f-Flama-Medium">{ficha.NombreCompleto}</div>
                <div class="contenido__imagen">
                    <Slider ref={sliderRef} {...sliderSettings}>
                        {imagenesHTML}
                    </Slider>
                    {pictogramas}
                </div>
            {((ficha.CodTipoRegistro === "xMHO" || PerfilUsuario()==="EST") ?
                <></>
            :
                <div class="detalles">
                    {((ficha.CodTipoRegistro === "MHO") && (precioAMostrar === 0) &&  (!ficha.PreciosFuturos)) ?
                        <></>
                    :
                        <div class="precios__iva f-f-Flama-Basic">
                            <p class="nombre">{literalPVPIVA}</p>
                            {precioAMostrar>0 ?
                            <p class="precio" style={estiloPrecio}>{numberWithCommas(precioAMostrar)}</p>
                            :
                            <p class="precio" >&nbsp;</p>
                            }
                            {(
                                tienePFuturos && precioFuturoAMostrar>0 ? 
                                    <p class="sin_iva">{numberWithCommas(precioFuturoAMostrar)}</p>
                                :
                                    <span></span>
                            )}
                            <p class="moneda">EUR</p>
                        </div>
                    }
                    <div class="condiciones">
                        <div class="fila">
                            <div class="col-i">Receta</div>
                            <div class="col-d">{ficha.Receta}</div>
                        </div>
                        <div class="fila">
                            <div class="col-i">Dispensable</div>
                            <div class="col-d" title={ficha.Dispensable}>{textoDispensable}</div>
                        </div>
                        <div class="fila">
                            <div class="col-i">Financiado</div>
                            <div class="col-d" title={ficha.Financiado}>{ficha.ShortFinanciado}</div>
                        </div>
                    </div>
                </div>
            )}
            <LinksFichaMUH ficha={ficha} cambiaPagina={props.cambiaPagina} ></LinksFichaMUH>
        </div>
    )
}
export default MargenDerecho;

