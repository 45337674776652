import { useState } from "react";

const Licencia = (props) => {
    const [checkedAceptar, setCheckedAcpetar] = useState(false);

    const clickAcepta = () =>{
        if (checkedAceptar)
            props.alAceptar();
    }

    const clickCheckAceptar = (valor) =>{
        setCheckedAcpetar(valor);
    }

    const estiloCheckBox = {
        height: '16px',
    }


    return(
        <div>
            <p style={{marginBottom:'20px'}}>
                Debe de aceptar la licencia de uso de BOT PLUS para poder continuar
            </p>
            <p>
                <small>Si no puede leer el acuerdo correctamente pulse <a href={"/Licencias/" +props.licencia} target="_blank">aquí</a></small>
            </p>
            <embed style={{width:'90%', height: '60%', minHeight:'450px'}} border="1" src={"/Licencias/" +props.licencia} type="application/pdf" />    
            <p className="fs fs16 fw fw100 FlamaBook greyColor" 
                    style={{width:'100%', textAlign:'left',  paddingRight: '10px', fontWeight:'normal', textAlign: 'justify'
                            ,marginTop: '20px'}}>
                <label>
                    <input type="checkbox" checked={checkedAceptar} style={estiloCheckBox} onChange={(e) => clickCheckAceptar(e.target.checked)} /> 
                    <span style={{paddingLeft:'10px'}}> 
                        Acepto el contrato de licencia
                    </span>
                </label>                        
            </p>
            <div className="module module__buttons" style={{marginTop: '20px'}}>
            {(checkedAceptar) ?
                <span>
                <button className="button" style={{backgroundColor:'#93C462', color:'#ffffff', height:'32px', paddingTop:'0px'}} 
                        onClick={(e) => clickAcepta()}>
                    <img src="/img/checkGreen.svg" style={{verticalAlign:'middle'}}  />
                    &nbsp;&nbsp;
                    <span  style={{verticalAlign:'middle'}}>Aceptar</span>
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                :
            <></>
            }
                <button className="button" style={{backgroundColor:'#B7b7b7', color:'#ffffff', height:'32px', paddingTop:'0px'}} 
                        onClick={(e) => props.alLogout()}>
                    <img src="/img/checkKO.svg" style={{verticalAlign:'middle'}}  />
                    &nbsp;&nbsp;
                    <span  style={{verticalAlign:'middle'}}>Salir</span>
                </button>
            </div>
        </div>
    );
}

export default Licencia;
