import CabeceraExporta from "./CabeceraExporta";
import BP2022Service from "../../services/user.service";
import { useEffect, useState } from "react";
import LoadingPopup from "../Resultados/Loading.Popup";
import { rootPath } from "../../services/utils";
import { CSVLink, CSVDownload } from "react-csv";


const Exporta = (props) => {
    const [documentosExporta, setDocumentosExporta] = useState(null);
    const [docSel, setDocSel] = useState(0)
    const [datosAExportar, setDatosAExportar] = useState({
        headers: [],
        data: [],
    });
    const [txtExportar, setTxtExportar] = useState("Exportar");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!documentosExporta)
        {
            DescargaExporta();
        }        
    },[]);

    const DescargaExporta = () => {      
        BP2022Service.obtenListadosExportables()
        .then((response) => {
            if (response.data && response.data.length>0){
                setDocSel(response.data[0].Codigo);
            }
            setDocumentosExporta(response.data);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const ClickDoc = (cod) => {
        setDocSel(cod);
    }

    const ClickExportar = (e) =>{
        setLoading(true);
        setDatosAExportar({
            headers: [],
            data: [],
        });
        BP2022Service.obtenExportacionExcel(docSel)
        .then((response) => {
            const nuevosDatosAExportar = {
                headers: response.data.cabeceras,
                data: response.data.datos,
            }
            setLoading(false);
            setDatosAExportar(nuevosDatosAExportar);
            setTxtExportar("Volver a generar");
        })
        .catch(function (error) {
            console.log('Error', error.message);
            setLoading(false);
        });
    }



    const htmlDocsExporta = (documentosExporta ? documentosExporta.map(doc => 
        <div class="column">
            <label style={{fontSize : '16px'}}>
                <input type="radio" name={"exporta" + doc.Codigo} value={doc.Codigo} checked={docSel===doc.Codigo}  onClick={(e) => ClickDoc(doc.Codigo)}/>
                {doc.Valor}
            </label>
        </div>
    ) : <></>);


    return ( 
        <section class="section section__buscador section__buscador__busqueda" >
            <CabeceraExporta cambiaPagina = {props.cambiaPagina}></CabeceraExporta>
            <div class="container container__column" >
                <div class="content" >
                    { (!documentosExporta) || (loading) ?
                        <LoadingPopup titulo="Un momento" texto="Preparando documentos" esPorcentaje={true}></LoadingPopup>
                    :
                        <div class="content__results content__results__filters" id="main-filter"  onSubmit={(e) => e.preventDefault()} tabIndex="0">
                            <div class="module module__filters" style={{minWidth:'140px'}}>
                                <div class="column" style={{marginTop: '30px'}}>                        
                                    <div class="cover cover__filters cover__filters__warning" style={{marginTop:'10px', marginBottom:'10px', fontSize: '12px'}} >
                                        <div class="module" >
                                            <div class="box" style={{fontSize: '18px', textAlign:'center', fontWeight:'600'}}>
                                            Seleccione el listado que desea exportar y pulse sobre el botón "Exportar".
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="radios radios__full radio_subfield">
                                    {htmlDocsExporta}
                                </div>
                            </div>
                            <div class="mySearch mySearch__columns" >
                                <div class="module module__buttons module__buttons__buscar">
                                    <button class="button button__arrow button__purple noUppercase" title="Guardar" onClick={e => ClickExportar(e)} style={{marginRight:'10px'}}>{txtExportar}</button>
                                </div>
                                {(datosAExportar.data && datosAExportar.data.length>0 && datosAExportar.headers && datosAExportar.headers.length>0) ?
                                    <div class="cover__filters__info">
                                        <div class="module" style={{justifyContent: 'center'}}>
                                            <CSVDownload data={datosAExportar.data} headers={datosAExportar.headers} separator=";" filename="BotPlusExport.csv" >
                                            </CSVDownload>
                                            <div class="box"style={{justifyContent: 'center'}}>
                                            <svg width="20px" height="20px" viewBox="0 0 20 20" >
                                                <g id="desarollo_v3" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <g id="NUEVA-NECESIDAD!-Pintar-pantalla-buscadores-favoritos" transform="translate(-247.000000, -863.000000)">
                                                        <g id="Group-61-Copy" transform="translate(247.000000, 863.000000)">
                                                            <circle id="Oval-Copy-7" stroke="#7EB04B" stroke-width="2" cx="10" cy="10" r="9"></circle>
                                                            <g id="Group-60" transform="translate(9.000000, 5.000000)" fill="#7EB04B" class="info" >
                                                                <rect id="Rectangle" x="0" y="0" width="2" height="5"></rect>
                                                                <rect id="Rectangle" x="0" y="7" width="2" height="2"></rect>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg> 
                                            &nbsp; &nbsp; Si el archivo no se descarga automáticamente pulse sobre el siguiente botón 
                                            </div>
                                            <div class="module module__buttons module__buttons__buscar" style={{paddingTop: '20px'}}>
                                            <CSVLink data={datosAExportar.data} headers={datosAExportar.headers} separator=";" decimalSeparator="," filename="BotPlusExport.csv" >
                                                <button class="button button__arrow button__purple noUppercase" title="Descargar">Descargar archivo</button>
                                            </CSVLink>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    <div></div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </section>
    );
};

export default Exporta;
