import ItemRamaArbol3 from "./ItemRamaArbol3";

function RamaArbol3(props){
    const arbol = props.arbol;

    const arbolHTML = (((arbol) && (arbol.length>0)) ?
        arbol.map(item => 
            <ItemRamaArbol3 item = {item}  abreRama = {props.abreRama} cierraRama = {props.cierraRama} cambiaSeleccion = {props.cambiaSeleccion} muestraCodigo = {props.muestraCodigo}></ItemRamaArbol3>
        )
    : 
        <></>
    );

    return (
        <div> 
            {arbolHTML}
        </div>
    )
}
export default RamaArbol3;
