import { useEffect, useState } from "react";
import { AddClassNameToChildClassItems, buscaOptionEnSelectByValue, deepEqual
    , elementIsOfClass, GetAntecesorWhithClassName, GetLastSucesorWhithClassName
    , GetSucesorWhithClassName, primerHijoConClase, RemoveClassNameToChildClassItems
    , scrollSiNoVisible } from "../../../services/utils";

function SelectorComboBox(props){
    const [propiedades, setPropiedades] = useState({
        lista: props.lista,
        valueSelected: props.valueSelected,
    })
    const lista = propiedades.lista;
    const valueSelected = propiedades.valueSelected;
    const idHTML = props.idHTML; 
    const optionsHTML = propiedades.lista.map(item => (item.codigo === propiedades.valueSelected ? 
        <option selected value={item.codigo}>{item.valor}</option>
        :<option value={item.codigo}>{item.valor}</option> )
    );
    const classInputSelector = (props.disabled ? "inputSelector disabled" : "inputSelector");

    const maxLengthNormal = (props.maxLengthNormal ? props.maxLengthNormal : 38);
    const estiloPetit = {fontSize:'12px', textTransform: 'none', whiteSpace: 'normal'};
    const estiloNormal = {textTransform: 'none'};

    const estiloModuleSelect = (props.ancho ? {width: props.ancho} : {});

    const optionsDisplayed = lista.slice(1).map(item => (
            <label class={"labeloption " + (item.codigo===valueSelected? "active" : "") } 
                    style={(item.valor.length>maxLengthNormal)?estiloPetit:estiloNormal}
                    data-value={item.codigo} 
                    onClick={(e) => ClickEnOpcion(e)} 
                    title = {item.valor} 
            >{item.valor}</label>
        ));

    const clasesCoverSelect = (props.disabled ? "cover cover__select cover__select cover__select__border disabled" : "cover cover__select cover__select cover__select__border");

    const tipoFiltradoLista = 1;

    
    var textSelected = '';

    if (lista)
        if (lista.length>0)
            textSelected = lista[0].valor;
    
    for (var item of lista){
        if (item.codigo===valueSelected)
            textSelected = item.valor;
    }
    
 
    useEffect(() => {
        //if (!deepEqual(props.lista, propiedades.lista) || (props.valueSelected !== valueSelected)){
            setPropiedades({
                lista: props.lista,
                valueSelected: props.valueSelected,
            })
        //}
        
    },  [props.lista, props.valueSelected]);


    const ClickEnCoverSelect  = (e) =>{
        if (!props.disabled){
            const item = e.target;        
            const container = GetAntecesorWhithClassName(item, 'custom-select-container');
            container.classList.toggle('active');
            const input = primerHijoConClase(container, 'inputSelector');
            input.value = "";
        }
    }

    const ClickEnOpcion  = (e) =>{
    	var option = e.target;
        SeleccionaOpcion(option);
    }

    const SeleccionaOpcion  = (option) =>{
        var value = option.getAttribute("data-value");
        var html = option.innerHTML;

        var container = GetAntecesorWhithClassName(option, "custom-select-container");
        var select = primerHijoConClase(container,'custom-select');
        var input = primerHijoConClase(container, 'inputSelector');

        //var optionWithValue = buscaOptionEnSelectByValue(select,value);
        //optionWithValue.setAttribute('selected', '');
        select.value=value;
        container.classList.remove("active");

        RemoveClassNameToChildClassItems(container, 'dropdown__radios', 'labeloption', 'active');
        RemoveClassNameToChildClassItems(container, 'dropdown__radios', 'labeloption', 'preselected');
        RemoveClassNameToChildClassItems(container, 'dropdown__radios', 'labeloption', 'hide');
        option.classList.add("active");

        if (input){
            input.value='';
            input.placeholder=html;
            textSelected = html;
        }
        
        if (!props.idEsStr)
            value = parseInt(value);
        if (props.alCambiar){
            props.alCambiar(value);
        }

    }

    const remove_accents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } 

    const KeyUpInput = (e) =>{
		var input = e.target;
        const container = GetAntecesorWhithClassName(input, 'custom-select-container');
        var texto = input.value;
        FiltraOpciones(container,texto);
    }

    const FiltraOpciones = (container, texto) =>{
        var search = remove_accents(texto.toLowerCase());
        var search2 = " " + search;

    	var found = false;

    	if(search != ""){
            var labeloptions = container.getElementsByClassName('labeloption');
            for (var elemento of labeloptions) {
	    		var label = elemento;
	    		var html = remove_accents(label.innerHTML.toLowerCase()).replace('-',' ').replace('(',' ');
                var strlabel = label.getAttribute('data-value').toString();
                var bMostrar = false;
                switch (tipoFiltradoLista){
                    case 0:
                        bMostrar = ((html.indexOf(search) === 0));
                        break;
                    case 1: 
                        bMostrar = ((html.indexOf(search) === 0) || (html.indexOf(search2)>0));
                        break;
                    case 2:
                        bMostrar = ((html.indexOf(search) === 0) || (strlabel.indexOf(search) === 0));
                        break;
                    case 3:
                        bMostrar = ((html.indexOf(search) === 0) || (strlabel.indexOf(search) === 0) || (html.indexOf(search2)>0));
                        break;
                }
	    		if(bMostrar){
	    			found = true;
					label.classList.remove("hide");
				}else{
					label.classList.add("hide");
				}
            }    
    	}
        else{
            found = true;
            RemoveClassNameToChildClassItems(container,'labeloption',null,'hide');
        }
    	

    	if(found){
            AddClassNameToChildClassItems(container,'dropdown',null,'show');
            var ddowns = container.getElementsByClassName('ddown');
            for (var elemento of ddowns) {
                var hijos = elemento.children;
                var hijosNiOcultosNiActivos = false;
                for (var hijo of hijos){
                    if (elementIsOfClass(hijo, 'labeloption')){
                        if (!elementIsOfClass('hide') && !elementIsOfClass('active')){
                            hijosNiOcultosNiActivos = true;
                        }
                    }
                }
                if (!hijosNiOcultosNiActivos){
                    elemento.classList.remove('show');
                    const input = primerHijoConClase(container, 'inputSelector');
                    if (input.placeholder === '')
                        input.placeholder = textSelected;    
                }
            }
    	}else{
            container.classList.remove('active');
            //RemoveClassNameToChildClassItems(container,'dropdown',null,'show');
    	}
    }



    const KeyDownSelector = (e) =>{
        if (!props.disabled){
            const container = GetAntecesorWhithClassName(e.target, 'custom-select-container');
            const ddown = GetSucesorWhithClassName(container, 'dropdown__radios');
            const input = primerHijoConClase(container, 'inputSelector');
            var keyCode = e.keyCode;

            if ((keyCode >= 48 && keyCode<=57)||(keyCode >= 65 && keyCode<=90) || (keyCode===46) || (keyCode===8)|| (keyCode===40) || (keyCode===38) ){
                container.classList.add('active');
            }

            if ((keyCode===46) || (keyCode===8)){
                input.placeholder='';
            }
            
            if (keyCode === 27){
                container.classList.remove('active');
                if (input.placeholder === '')
                    input.placeholder = textSelected;
            }


            if (ddown.classList.contains('show')){
                var miItem;
                var offset = 160;
                miItem = GetSucesorWhithClassName(ddown,'preselected');
                if (!miItem){
                    miItem = GetSucesorWhithClassName(ddown,'active');
                }
                if (keyCode ===38){
                    //UP
                    if (miItem)
                        miItem = miItem.previousSibling;
                    if (!miItem)
                        miItem = GetLastSucesorWhithClassName(ddown,'labeloption')
                    while (miItem && miItem.classList.contains('hide')){
                        miItem = miItem.previousSibling;
                        if (!miItem){
                            miItem = GetLastSucesorWhithClassName(ddown,'labeloption')
                        }
                    }
                    if (miItem){
                        scrollSiNoVisible(ddown,miItem,offset);
                        RemoveClassNameToChildClassItems(ddown,'labeloption',null,'preselected');
                        miItem.classList.add('preselected');
                    }
                }
                if (keyCode === 40){
                    //DOWN
                    if (miItem)
                        miItem = miItem.nextSibling;
                    if (!miItem)
                        miItem = GetSucesorWhithClassName(ddown,'labeloption')
                    while (miItem && miItem.classList.contains('hide')){
                        miItem = miItem.nextSibling;
                        if (!miItem){
                            miItem = GetSucesorWhithClassName(ddown,'labeloption')
                        }
                    }
                    if (miItem){
                        scrollSiNoVisible(ddown,miItem,offset);
                        RemoveClassNameToChildClassItems(ddown,'labeloption',null,'preselected');
                        miItem.classList.add('preselected');
                    }
                }
                if (keyCode === 13){
                    e.preventDefault();
                    e.stopPropagation();
                    miItem =  GetSucesorWhithClassName(ddown,'preselected')
                    if (miItem){
                        SeleccionaOpcion(miItem);
                    }
                }
            }
        }
    }

  
    return (
        <div class="module module__selects" style={estiloModuleSelect}>
            <div class="selects" >
                <div class="custom-select-container " id="custom-select"   onKeyDown={(e) => KeyDownSelector(e)} >
                    <select class="custom-select" id={idHTML} style={{display:"none"}}  >
                        {optionsHTML}
                    </select>
                    <div tabIndex="0" class={clasesCoverSelect} onClick={(e) => ClickEnCoverSelect(e)}>
                        <input type="text" placeholder={textSelected} className={classInputSelector} onKeyUp={(e) => KeyUpInput(e)} disabled = {props.disabled}/>
                        <div class="select-label" style={{display:'none'}} >
                            {textSelected}
                        </div>
                    </div>
                    <div class="dropdown dropdown__radios">
                        {optionsDisplayed}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SelectorComboBox;

