import ItemMenuPrincipalMorado from './ItemMenuPrincipalMorado.js';


const MenuPrincipalMorado = (props) => {

    const seccionesMenu =  props.opcionesMenu.map(item => <ItemMenuPrincipalMorado 
                                                                item={item.titulo} 
                                                                idx={props.opcionesMenu.indexOf(item)+1}
                                                                perfil = {props.perfil}></ItemMenuPrincipalMorado>)
    return (
        <ul class="list list__purple" >
            {seccionesMenu}
        </ul>
    )
}
export default MenuPrincipalMorado;
