import Acordeon from "../../../FichaMUH/Apartados/Composicion/Acordeon";
import FilaTE from "./FilaTE";

const TiemposEsperaEspecie = (props) => {
    const tiemposEspera = props.tiemposEspera;
    const titulo = tiemposEspera.Especie;
    const HTMLTiemposEsperaTejidos = tiemposEspera.TiemposEsperaTejido.map(tet => <FilaTE tiempoEspera={tet} ></FilaTE>);

    var contenidoAcordeon = (
        <div class="table" style={{width:'100%'}}>
            <div class="table__row table__row__header table__row__header__noBg fs fs12 fw fw600 greenColor uppercase">
                <div class="column" >
                    <p>Tejido</p>
                </div>
                <div class="column" >
                    <p>Tiempo</p>
                </div>
            </div>
            {HTMLTiemposEsperaTejidos}
        </div>
    );
    return (
        <Acordeon titulo = {titulo} contenido={contenidoAcordeon} activo={false}></Acordeon>
    )
}
export default TiemposEsperaEspecie;
 
