import MisBuscadoresCabeceraResultados from "../Resultados/CabeceraResultados/MisBuscadoresCabeceraResultados";
import VolverCabeceraResultados from "../Resultados/CabeceraResultados/VolverCabeceraResultados";

const CabeceraAyuda = (props) => {
    return (
        <div class="contenedor__buscador f-f-Flama-Book">
            <div class="container flex flex--justify-center">
                <VolverCabeceraResultados atras={props.atras} textoVolver=""></VolverCabeceraResultados>
                <div class="contenedor__buscador--buscador" style={{display:'flex', justifyContent: 'center'}}>
                    <div class="box box__busqueda"  >
                        <div class="mask" >
                            <img src="/img/ficha/help.svg" alt="" />
                        </div>
                        <div class="text" >
                            <p class="fs fs20 fw fw400 blackColor" ><strong>Ayuda</strong></p>
                        </div>
                    </div>
                </div>
                <MisBuscadoresCabeceraResultados  cambiaPagina={props.cambiaPagina}></MisBuscadoresCabeceraResultados>
            </div>
        </div>
    );
};

export default CabeceraAyuda;
  