import {Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,} from 'chart.js';
//import faker from 'faker';
import { Bar } from 'react-chartjs-2';
import { Redondea } from '../../../../../services/utils';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const GraficaCismed = (props) => {
    const inactivo = props.inactivo;
    const datosCismed = props.datosCismed;
    const estilo = (inactivo ?
        {color:'transparent', textShadow: '0 0 10px rgba(0,0,0,0.5)'}
        :
        {}
    );
    const estiloImg = (inactivo ?
        {filter: 'blur(15px)' , WebkitFilter: 'blur(15px)', width: '100%', height: '300px'}
        :
        {width: '100%', height: '300px'}
    );
    const datosGraph = {
        labels : ['España', datosCismed.CCAA, datosCismed.Provincia],
        datasets:[
                { 
                    data: [Redondea(datosCismed.PorcentajeNacional,2), Redondea(datosCismed.PorcentajeCCAA,2), Redondea(datosCismed.PorcentajeProvincia,2)],
                    fill: false,
                    backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(255, 159, 64, 0.2)', 'rgba(255, 205, 86, 0.2)'],
                    borderColor: ['rgb(255, 99, 132)', 'rgb(255, 159, 64)', 'rgb(255, 205, 86)'],
                    borderWidth: 1
                }
        ]
    };

    var max  = 60;
    if ((datosCismed.PorcentajeNacional >= max) || (datosCismed.PorcentajeCCAA >= max) || (datosCismed.PorcentajeProvincia >= max))
        max = 100;

    const optionsGraph = {
        responsive: true,
        maintainAspectRatio: false,
        legend: { 
            display: false 
        },
        title: {
            display: true,
            text: 'PORCENTAJE DE FARMACIAS CON FALTA DE SUMINISTRO'
        },
        scales: { 
            y: { 
                display: true,
                stacked: true,
                min: 0 ,
                max: max ,
                suggestedMin:0 ,
                suggestedMax: max
            }       

        },
        plugins: {
            legend: { 
                display: false 
            },
        }
    }

    return (
        <div  class="cover cover__text"  style={estiloImg}>
            <h4 class="fs fs16 fw fw700 greyColor2 title" >Porcentaje de farmacias con falta de suministro</h4>
            <Bar options={optionsGraph} data={datosGraph} />
        </div>
    )
}
export default GraficaCismed;

/*
        <div class="cover cover__text" >
        <h4 class="fs fs16 fw fw700 greyColor2 title">Porcentaje de farmacias con falta de suministro</h4>
        <div class="mask" >
            <Bar options={optionsGraph} data={datosGraph} />
        </div>
        </div>

*/