import { FormatoFechaDDMMYYYY } from "../../../../../services/utils";
import CambioHistorico from "./CambioHistorico";

const FechaHistorico = (props) => {
    const fecha = props.fecha;
    const htmlCambios = fecha.Cambios.map(cambio => <CambioHistorico cambio={cambio} abierto={false} esECoH={props.esECoH}></CambioHistorico>)
    return (
        <div>
            <div class="cover__subTitle" >
                <h2 class="fs fs16 fw fw600 greenColor" >{FormatoFechaDDMMYYYY(fecha.Fecha)}</h2>
            </div>
            {htmlCambios}
        </div>
    )
}
export default FechaHistorico;
