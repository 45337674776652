import { useEffect, useState } from "react";
import BP2022Service from "../../services/user.service";
import { getCurrentURL, getParam, getParamsURL, rootPath } from "../../services/utils";
import SelectorComboBox from "../Utilidades/Selector/SelectorComboBox";
import Emergente from "../Fichas/FichaMUH/Apartados/Cismed/Emergente";
import AddEmpresa from "./AddEmpresa";

const CambioPassword = (props) => {

    const [mensaje, setMensaje] = useState('');
    const [dirCorreo, setDirCorreo] = useState('');
    const [user, setUser] = useState('');
    const [newCorreo, setNewCorreo] = useState('');
    const [confirmaCorreo, setConfirmaCorreo] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [alto, setAlto] = useState('230px');
    const [estado, setEstado] = useState(0);
    const [opt1, setOpt1] = useState(false);
    const [opt2, setOpt2] = useState(false);

    const estiloCheckBox = {
        height: '16px',
    }

    const getDatosCambioPassword = () =>{
        setEstado(3);
        BP2022Service.getDirCorreo()
        .then((response) => {
            if (response.data && response.data.Correo != ""){
                setDirCorreo(response.data.Correo);
                setUser(response.data.Usuario);
                setOpt1(response.data.DeseaRecibirInfo);
                setOldPassword('');
                setEstado(2);
                setMensaje('');
            }
            else{
                setEstado(1);
                setMensaje('Para cambiar la contraseña es necesario que facilite una dirección de correo eletrónico.')
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
            setMensaje(error.message);
        });
    }

    useEffect (() =>{
        if (estado===0)
            getDatosCambioPassword();
    },[]);


    const guradaPassword = () =>{
        if (estado === 1){
            if (newCorreo != ""){
                if (validateEmail(newCorreo)){
                    if (newCorreo === confirmaCorreo){
                        if (oldPassword != ""){
                            if (opt1){
                                setEstado(3);
                                setMensaje('Enviando datos...')
                                BP2022Service.setDirCorreo(oldPassword, newCorreo, opt2)
                                .then((response) => {
                                    if (response.data){
                                        if (response.data.Exito){
                                            setDirCorreo(newCorreo);
                                            setEstado(6);
                                            setMensaje('Para completar el proceso debe validar su dirección de correo. Consulte su buzón y siga las instrucciones');
                                        }else{
                                            setEstado(1);
                                            setMensaje(response.data.Mensaje);
                                        }
                                    }else{
                                        setEstado(1);
                                        setMensaje('No se ha podido cambiar la dirección de correo electrónico.')
                                    }
                                })
                                .catch(function (error) {
                                    console.log('Error', error.message);
                                    setMensaje(error.message);
                                });
                            }
                            else{
                                setMensaje('Debe aceptar el uso de su correo electónico para la gestión de Bot Plus')
                            }
                        }
                        else{
                            setMensaje('Para cambiar la dirección de correo asociada a la cuenta de ususario debe proporcionar la contraseña actual')
                        }
                    }
                    else{
                        setMensaje('La dirección de correo electrónico y su confirmación no coinciden')
                    }
                }
                else{
                    setMensaje('La dirección de correo electrónico proporcionada no es correcta')
                }
            }
            else{
                setMensaje('Debe proporcionar una dirección de correo electrónico')
            }
        }else if (estado === 222){
            if (newPassword != ""){
                if (validatePassword(newPassword)){
                    if (newPassword === confPassword){
                        if (oldPassword != ""){
                            setEstado(3);
                            setMensaje('Enviando datos...')
                            BP2022Service.setPassword(oldPassword, newPassword)
                            .then((response) => {
                                if (response.data){
                                    if (response.data.Exito){
                                        setEstado(4);
                                        setMensaje('Contraseña cambiada correctamente');
                                    }else{
                                        setEstado(222);
                                        setMensaje(response.data.Mensaje);
                                    }
                                }else{
                                    setEstado(222);
                                    setMensaje('No se ha podido cambiar la contraseña.')
                                }
                            })
                            .catch(function (error) {
                                console.log('Error', error.message);
                                setMensaje(error.message);
                            });
                        }
                        else{
                            setMensaje('Para realizar el cambio de contraseña debe proporcionar la contraseña actual')
                        }
                    }
                    else{
                        setMensaje('La nueva contraseña y su confirmación no coinciden')
                    }
                }
                else{
                    setMensaje('La nueva contraseña no cumple las especificaciones')
                }
            }
            else{
                setMensaje('La nueva contraseña no puede ser vacia')
            }
        }else if (estado===2){
            enviaCorreoUsuario();
        }else if (estado===5){
            enviaCorreoUsuarioCambioCorreo();
        }else if (estado===6){
            props.alCerrar();
        }
}

    const enviaCorreoUsuario = () =>{
        /*if (user != ""){*/
            setEstado(3);
            setMensaje('Enviando correo...');
            BP2022Service.enviaCorreoCambioPass()
            .then((response) => {
                if (response.data && response.data.Ok){
                    setEstado(4);
                    setMensaje('Se ha enviado un correo electrónico para realizar el cambio de contraseña. Consulte su buzón y siga las instrucciones');
                }
                else{
                    setEstado(2);
                    setMensaje(response.data.Mensaje)
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
                setEstado(2);
                setMensaje(error.message);
            });
        /*}
        else{
            setMensaje('Debe indicar un nombre de usuario');
        }*/
    }

    const enviaCorreoUsuarioCambioCorreo = () =>{
            setEstado(3);
            setMensaje('Enviando correo...');
            BP2022Service.enviaCorreoCambioCorreo()
            .then((response) => {
                if (response.data && response.data.Ok){
                    setEstado(4);
                    setMensaje('Se ha enviado un correo electrónico para realizar el cambio solicitado. Consulte su buzón y siga las instrucciones');
                }
                else{
                    setEstado(5);
                    setMensaje(response.data.Mensaje)
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
                setEstado(5);
                setMensaje(error.message);
            });
    }



    const CambiaCorreo = () => {
        setMensaje('');
        setOpt1(false);
        setEstado(5);
    };


    const CambiaNewPassword = (s) => {
        setMensaje("");
        setNewPassword(s);
    };

    const CambiaOldPassword = (s) => {
        setMensaje("");
        setOldPassword(s);
    };

    const CambiaConfirmacion = (s) => {
        setMensaje("");
        setConfPassword(s);
    };

    const CambiaNewCorreo = (s) => {
        setMensaje("");
        setNewCorreo(s);
    };

    const CambiaConfirmaCorreo = (s) => {
        setMensaje("");
        setConfirmaCorreo(s);
    };

    const cambiaOpt1 = (checked) =>{
        setOpt1(checked);
    }

    const cambiaOpt2 = (checked) =>{
        setOpt2(checked);
    }

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const validatePassword = (str) => {
        //var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        return re.test(str);    
    };


    var newalto;
    var txtBoton = "Cambiar contraseña";
    var titulo = "Cambio de contraseña";
    switch (estado){
        case 0:
            newalto = '230px';
            break;
        case 1:
            newalto = '740px';
            txtBoton = "Guardar correo electrónico";
            titulo = "Cambio de correo electrónico"
            break;
        case 2:
            txtBoton = "Enviar correo electrónico";
            newalto = '570px';            
            break;
        case 3:
            newalto = '230px';
            break;
        case 4:
            newalto = '230px';
            break;                                
        case 5:
            titulo = "Cambio de correo electrónico"
            txtBoton = "Enviar correo electrónico";
            newalto = '515px';            
            break;
        case 6:
            titulo = "Cambio de correo electrónico"
            txtBoton = "Cerrar";
            newalto = '230px';
            break;                                
        }
    if (alto != newalto)
        setAlto(newalto);
            


    const contenido = (
        (estado === 0) ?
            <div style={{height: '100%', width: '100%', cursor:'wait', overflowY: 'hidden',}}>
                <p className="fs fs15 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '30%'}}>Iniciando...</p>
            </div>
        :((estado >= 1 && estado <= 2) || estado === 5 || estado === 6) ?
            <form class="content__results content__results__filters" id="main-filter" onSubmit={(e) => e.preventDefault()} autocomplete="off" >
                {(mensaje) && (mensaje !== "") ? 
                    <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '6px', color:'#F05341'}}>{mensaje}</p>
                :
                    <></>                
                }
                {(estado === 1) ?
                    <div className="text" style={{width:'100%', paddingBottom:'6px', fontSize:'16px', lineHeight:'20px', verticalAlign:'baseline'}}  >
                        <p className="fs fs18 fw fw100 FlamaBook greyColor"
                            style={{marginBottom:'30px'}}>
                                Contraseña actual:
                                <div class="coverModule">
                                    <div class="module module__filters">
                                        <div class="box box__inputs">
                                            <div class="cover cover__input">
                                            <input type="password" name="CPOldPass" value={oldPassword} disabled = {estado !== 1} 
                                                    onChange={ e => CambiaOldPassword(e.target.value)} 
                                                    placeholder="Introduzca la contraseña actual" autocomplete="new-password"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </p>
                        <p className="fs fs18 fw fw100 FlamaBook greyColor"
                            style={{marginBottom:'30px'}}>
                                Correo eletrónico:
                                <div class="coverModule">
                                    <div class="module module__filters">
                                        <div class="box box__inputs">
                                            <div class="cover cover__input">
                                            <input type="text" name="CPOldPass" value={newCorreo} disabled = {estado !== 1} 
                                                    onChange={ e => CambiaNewCorreo(e.target.value)} 
                                                    placeholder="Introduzca la nueva dirección de correo electrónico" autocomplete="new-password"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </p>
                        <p className="fs fs18 fw fw100 FlamaBook greyColor"
                            style={{marginBottom:'30px'}}>
                                Confirmación correo eletrónico:
                                <div class="coverModule">
                                    <div class="module module__filters">
                                        <div class="box box__inputs">
                                            <div class="cover cover__input">
                                            <input type="text" name="CPOldPass" value={confirmaCorreo} disabled = {estado !== 1} 
                                                    onChange={ e => CambiaConfirmaCorreo(e.target.value)} 
                                                    placeholder="Confirme la nueva dirección de correo electrónico" autocomplete="new-password"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </p>
                        <p className="fs fs16 fw fw100 FlamaBook greyColor" 
                                style={{width:'100%', textAlign:'left',  paddingRight: '10px', fontWeight:600, textAlign: 'justify',
                                    borderBottomStyle: 'solid', borderBottomWidth: '1px', borderBottomColor: '#e8e8e8'
                                    , paddingBottom: '10px'}}>
                            <label>
                                <input type="checkbox" checked={opt1} style={estiloCheckBox} onChange={(e) => cambiaOpt1(e.target.checked)} /> 
                                <span style={{paddingLeft:'10px', fontSize:'14px'}}> 
                                    Mediante el marcado de la casilla, el usuario acepta el uso 
                                    de su correo electrónico para la gestión de BOT PLUS y para 
                                    todas las comunicaciones accesorias derivadas del uso de la 
                                    aplicación.-
                                </span>
                            </label>                        
                        </p>
                        <p className="fs fs15 fw fw100 FlamaBook greyColor" 
                                style={{width:'100%', textAlign:'left',  paddingRight: '10px', fontWeight:'normal', textAlign: 'justify'
                                        ,paddingTop: '10px', fontSize:'14px'}}>
                                    Asimismo, solicitamos su consentimiento expreso para el envío de comunicaciones 
                                    electrónicas relativas a los servicios y productos que ofrecemos e información 
                                    que pueda resultar de su interés rellenando previamente su correo electrónico y 
                                    marcando la siguiente casilla                                        
                        </p>
                        <p className="fs fs16 fw fw100 FlamaBook greyColor" 
                                style={{width:'100%', textAlign:'left',  paddingRight: '10px', fontWeight:'normal', textAlign: 'justify'
                                        ,paddingTop: '10px'}}>
                            <label>
                                <input type="checkbox" checked={opt2} style={estiloCheckBox} onChange={(e) => cambiaOpt2(e.target.checked)} /> 
                                <span style={{paddingLeft:'10px', fontSize:'14px'}}> 
                                    Deseo recibir comunicaciones comerciales del CGCOF en los términos descritos en la&nbsp; 
                                    <a href='https://www.farmaceuticos.com/politica-de-privacidad' target='_blank' style={{color:'#93c462'}}>
                                        Política de Privacidad
                                    </a>.
                                </span>
                            </label>                        
                        </p>
                    </div>
                :<></>                
                }
                {(estado === 222) ?
                    <div>
                        <p className="fs fs18 fw fw100 FlamaBook greyColor"
                            style={{marginBottom:'30px'}}>
                                    Contraseña actual:
                                <div class="coverModule">
                                    <div class="module module__filters">
                                        <div class="box box__inputs">
                                            <div class="cover cover__input">
                                            <input type="password" name="CPOldPass" value={oldPassword} disabled = {estado !== 2} 
                                                    onChange={ e => CambiaOldPassword(e.target.value)} 
                                                    placeholder="Introduzca la contraseña actual" autocomplete="new-password"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </p>
                        <p className="fs fs18 fw fw100 FlamaBook greyColor"
                            style={{marginBottom:'10px'}}>
                                <span style={{paddingLeft:'10px', fontSize:'14px'}}> 
                                   La nueva contraseña debe tener una longitud mínima de 8 caracteres y debe contener al menos una mayúscula, una minúscula y un número
                                </span>
                        </p>
                        <p className="fs fs18 fw fw100 FlamaBook greyColor"
                            style={{marginBottom:'30px'}}>
                                Nueva contraseña:
                                <div class="coverModule">
                                    <div class="module module__filters">
                                        <div class="box box__inputs">
                                            <div class="cover cover__input">
                                                <input type="password" name="CPNewPass" value={newPassword} disabled = {estado !== 2} 
                                                    onChange={ e => CambiaNewPassword(e.target.value)} 
                                                    placeholder="Introduzca la nueva contraseña" autocomplete="new-password"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </p>
                        <p className="fs fs18 fw fw100 FlamaBook greyColor"
                            style={{marginBottom:'30px'}}>
                                Confirmación nueva contraseña:
                                <div class="coverModule">
                                    <div class="module module__filters">
                                        <div class="box box__inputs">
                                            <div class="cover cover__input">
                                                <input type="password" name="CPConf" value={confPassword} disabled = {estado !== 2}  
                                                    onPaste ={ e =>{e.preventDefault(); return false;}} 
                                                    onChange={ e => CambiaConfirmacion(e.target.value)} 
                                                    placeholder="Confirme la nueva contraseña" autocomplete="new-password"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </p>
                        <p className="fs fs18 fw fw100 FlamaBook greyColor"
                            style={{marginBottom:'30px'}}>
                                Dirección de correo electrónico:
                                <div class="coverModule">
                                    <div class="module module__filters">
                                        <div class="box box__inputs">
                                            <div class="cover cover__input" style={{display:'flex', justifyContent:'space-between'}}>
                                                <input type="text" name="CPMail" value={dirCorreo} disabled = {true} style={{width: '70%', color:'#C0C0C0'}} 
                                                    placeholder="Dirección de correo electrónico" 
                                                />
                                                <button className="button button__green" onClick={(e) => CambiaCorreo()} style={{height:'40px', paddingTop:'12px'}}> Cambiar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </p>
                    </div>
                :<></>                
                }
                {(estado === 2) ?
                    <div className="text" style={{width:'100%', paddingBottom:'6px', fontSize:'16px', lineHeight:'20px', verticalAlign:'baseline'}}  >
                        <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'justify', marginTop: '6px'}}>
                            Si desea cambiar su actual contraseña, pulse en el botón morado que aparece a continuación. 
                            Le enviaremos un correo electrónico a la dirección de correo abajo indicada, en el que le indicaremos las instrucciones a seguir para cambiar la contraseña.
                        </p>
                        <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'justify', marginTop: '10px'}}>
                            En el caso de que no llegase dicho correo, compruebe que no estuviera en la carpeta de correo no deseado.
                        </p>
                        <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'justify', marginTop: '10px'}}>
                            Si desease cambiar la dirección de correo electrónico, pulse en el botón verde que aparece a la derecha de su dirección actual.
                        </p>
                        <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'justify', marginTop: '0px'}}>
                            Si tuviera cualquier incidencia, póngase en <a target="_blank"  className="greenColor" href="http://farmaceuticos.com/contacto/">contacto con el Consejo General</a>.
                        </p>
                        <div className="cover cover__buttons" style={{textAlign: 'right', paddingTop: '10px'}} >
                            <button className="button button__purple" onClick={(e) => guradaPassword()} style={{height:'40px', paddingTop:'12px'}}> {txtBoton}</button>
                        </div>
                        <p className="fs fs18 fw fw100 FlamaBook greyColor"
                            style={{marginTop:'30px'}}>
                            Dirección de correo electrónico:
                            <div class="coverModule">
                                <div class="module module__filters">
                                    <div class="box box__inputs">
                                        <div class="cover cover__input" style={{display:'flex', justifyContent:'space-between'}}>
                                            <input type="text" name="CPMail" value={dirCorreo} disabled = {true} style={{width: '60%', color:'#C0C0C0'}} 
                                                placeholder="Dirección de correo electrónico" 
                                            />
                                            <button className="button button__green" onClick={(e) => CambiaCorreo()} style={{height:'40px', paddingTop:'12px'}}> Cambiar correo</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </p>
                    </div>
                :<></>                
                }
                {(estado === 5) ?
                    <div className="text" style={{width:'100%', paddingBottom:'6px', fontSize:'16px', lineHeight:'20px', verticalAlign:'baseline'}}  >
                        <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'justify', marginTop: '6px'}}>
                            Le enviaremos un correo electrónico a la dirección de correo abajo indicada, en el que le indicaremos las instrucciones
                            a seguir para cambiar la dirección de correo asociada.
                        </p>
                        <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'justify', marginTop: '10px'}}>
                            En el caso de que no llegase dicho correo, compruebe que no estuviera en la carpeta de correo no deseado.
                        </p>
                        <p className="fs fs18 fw fw100 FlamaBook greyColor"
                            style={{marginBottom:'30px'}}>
                                Dirección de correo electrónico:
                                <div class="coverModule">
                                    <div class="module module__filters">
                                        <div class="box box__inputs">
                                            <div class="cover cover__input" style={{display:'flex', justifyContent:'space-between'}}>
                                                <input type="text" name="CPMail" value={dirCorreo} disabled = {true} style={{width: '70%', color:'#C0C0C0'}} 
                                                    placeholder="Dirección de correo electrónico" 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </p>
                        <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'justify', marginTop: '0px'}}>
                            Si la dirección de correo electrónico arriba indicada no fuera accsible o tuviera cualquier otra incidencia, póngase en 
                            <a target="_blank"  className="greenColor" href="http://farmaceuticos.com/contacto/">contacto con el Consejo General</a>.
                        </p>
                    </div>
                :<></>                
                }
                {(estado !== 2) ?
                    <div className="cover cover__buttons" style={{textAlign: 'right', paddingTop: '10px'}} >
                        <button className="button button__purple" onClick={(e) => guradaPassword()} style={{height:'40px', paddingTop:'12px'}}> {txtBoton}</button>
                    </div>
                    :<></>                
                }
            </form>
        :(estado === 0 || estado === 3 || estado === 4 ) ?
            <div style={{height: '100%', width: '100%', cursor:'wait', overflowY: 'hidden',}}>
                <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '35px'}}>{mensaje}</p>
            </div>
        :
            <div style={{height: '100%', width: '100%', overflowY: 'hidden',}}>
                <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '30%'}}>Este paso no está implementado</p>
            </div>
    );


    return(
        <Emergente 
            contenido={contenido} 
            keyName={"cambioPass"} 
            titulo={titulo} 
            activo={true} 
            ocultaAspa = {false}
            alto = {alto}
            ancho = {'680px'}
            cerrar={props.alCerrar}
        ></Emergente>
    );
}

export default CambioPassword;
