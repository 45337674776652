import { useEffect, useState } from "react";
import { numberWithCommas } from "../../services/utils";

const LoadingPopup = (props) => {
    const claseLightbox = "lightbox lightbox__popUp active";

    const estiloSection = {
        opacity: 0,
        visibility: 'hidden', 
        pointerEvents: 'none',
    }

    const maxItems = (props.esPorcentaje ? 100: 222935);
    const [miEstado, setMiEstado] = useState({
        numItems : 0,
        lastPaso : 0,
        paso : Math.round((maxItems)/3),
        fraccionRestoDelPaso: 3,
    })
    /*/const [numItems, setNumItems] = useState(0);
    const [lastPaso, setLastPaso] = useState(0);
    const [paso, setPaso] = useState(Math.round((maxItems-numItems)/3));*/

    var numItems = miEstado.numItems;
    var lastPaso = miEstado.lastPaso;
    var paso = miEstado.paso;
    var pasito = paso/20;
    /*if (pasito === 0)
        pasito = 1;*/
    var porcentaje = Math.round((miEstado.numItems/maxItems) * 100);
    

    useEffect(() => {
        var timer1s = setTimeout(() => {
            DaUnPaso();
          }, 50);
    });

    const DaUnPaso  = (e) =>{
        numItems = numItems + pasito;
        var fraccionRestoDelPaso = miEstado.fraccionRestoDelPaso;
        if (numItems>=(lastPaso+paso)){
            porcentaje = Math.round((miEstado.numItems/maxItems) * 100);
            if ((porcentaje>70) && (fraccionRestoDelPaso<30))
                fraccionRestoDelPaso += 1;
            lastPaso = numItems;
            paso = Math.round((maxItems-numItems)/fraccionRestoDelPaso);
        }
        setMiEstado({
            numItems : numItems,
            lastPaso : lastPaso,
            paso : paso,
            fraccionRestoDelPaso : fraccionRestoDelPaso,
        })
    }

    var texto = (props.texto) ? props.texto : "Analizando";
    texto += " " + numberWithCommas(Math.round(miEstado.numItems))
    if (props.esPorcentaje){
        texto += " %";
    }else{
        texto += " registros";
    }

    var titulo = (props.titulo) ? props.titulo : "Buscando";



    return (
        <section class={claseLightbox} style={estiloSection} > 
            <div class="lightbox__content whiteColorBg">
                <div className="box">
                    <h3 class="fs fs29 blackColor title" >{titulo}…</h3>
                    <p class="fs fs14 greyColor2">{texto}</p>
                    <div class="bar" >
                        <span className="bg" style={{width: porcentaje.toString() + '%'}} ></span>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default LoadingPopup;
/*
        <section class={claseLightbox} style={estiloSection} > 
            <div class="lightbox__content whiteColorBg">
                <div className="box">
                    <h3 class="fs fs29 blackColor title" >Buscando…</h3>
                    <p class="fs fs14 greyColor2">Analizando <span>219.325</span> archivos</p>
                    <div class="bar" >
                        <span class="bg" style="width: 60%;" ></span>
                    </div>
                </div>
            </div>
        </section>

*/