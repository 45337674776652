import FilaTitulosIdent from "./FilaTitulosIdent";

const FilaIdent = (props) => {
    const fila = props.fila;
    //const esImagen = props.esImagen;

    const htmlFila = fila.map(item=>
        <div class="column fs fs14 blackColor FlamaBook" >
            {
            (fila.indexOf(item) + (props.idxBloque * props.colsperrow)===props.idxForma) ?
                <img src={props.URLForma} alt={item} />
            :
            (item && item !== '') ? 
                <span>{item.split("\n").map(line=><p>{line}</p>)}</span>
            :
                <p>-</p>
            }
        </div>
    );

    return (
        <div>
        <FilaTitulosIdent fila = {props.titulos} idxBloque = {props.idxBloque}></FilaTitulosIdent>            
        <div class="table__row table__row__normal fw fw300 ">
            {htmlFila}
        </div>
        </div>
    )
}
export default FilaIdent;
