
import LoadingPopup from "../../Resultados/Loading.Popup";
import BP2022Service from "../../../services/user.service";
import { useEffect, useState } from "react";
import ResumenBusqueda from "../ResumenBusqueda";
import SelectorComposicion from "../../Utilidades/Selector/SelectorComposicion/SelectorComposicion";
import SelectorComboBox from "../../Utilidades/Selector/SelectorComboBox";
import { GetAntecesorWhithClassName, rootPath, upperCaseFirstLetter } from "../../../services/utils";
import SelectorUno2 from "../../Utilidades/Selector/SelectorUno2";
import { OpcionesADM, OpcionesDG, OpcionesDISP, OpcionesESP, OpcionesOTR, OpcionesPAC, OpcionesPacientePI, OpcionesPatologiasPI } from "../../../services/objsAplicacion";
import SelectorCHK from "../../Utilidades/Selector/SelectorCHK";
import SelectorSNX from "../../Utilidades/Selector/SelectorSNX";
import Acordeon from "../../Fichas/FichaMUH/Apartados/Composicion/Acordeon";
import SelectorEdad from "../../Utilidades/Selector/SelectorEdad/SelectorEdad";
import SelectorEnfRel from "../../Utilidades/Selector/SelectorEnfRel/SelectorEnfRel";
import ItemResumenFiltros from "../../Resultados/FiltrosYBusqueda/ResumenFiltros/ItemResumenFiltros";
import SelectorMulti2 from "../../Utilidades/Selector/SelectorMulti2";
import PreviewForma from "./PreviewForma";

const FBFormaColor= (props) => {
    const [datosFormulario, setDatosFormulario] = useState({
        listaFormas: null,
        listaColores: null,
        listaSabores: null,
        listaEnfs: null,
        listaPAs: null,
    });
    const [seleccionFormulario, setSeleccionFormulario] = useState({
        idForma: props.idForma,
        idColor1: props.idColor1,
        idColor2: props.idColor2,
        idSabor: props.idSabor,
        idEnf: props.idEnf,
        tamanos: (props.tamanos ? props.tamanos : []),
        CO1: props.CO1,
        CO2: props.CO2,
        col2: props.col2,
        anycol: props.anycol,
        ranura: props.ranura,
        logo: props.logo,
        difCol: props.difCol,
        solo: (props.solo ? props.solo : true),
        ins1: props.ins1,
        ins2: props.ins2,
        pas: (props.pas ? props.pas : []),
    })
    const [tipoFF, setTipoFF] = useState(props.tipoFF);

    useEffect(() => {
        if (datosFormulario.listaEnfs === null){
            DescargaDatos();
        }   
        if (tipoFF !== props.tipoFF)
            setTipoFF(props.tipoFF);
        var col1 = document.getElementById('Seccion_Color1');
        var col1c = document.getElementById('Seccion_Color1_Copia');
        var col2 = document.getElementById('Seccion_Color2');
        var sab = document.getElementById('Seccion_Sabor');
        var ftr = document.getElementById('Seccion_Forma_Tamano_Ranura');
        var ins = document.getElementById('Seccion_Inscripciones');
        if (col1){
            if (props.tipoFF==="CO"){
                col1.style.display='block';
                col1c.style.display='none';
                col2.style.display='block';
                sab.style.display='block';
                ftr.style.display='block';
                ins.style.display='block';
            }
            if (props.tipoFF==='CA'){
                col1.style.display='none';
                col1c.style.display='block';
                col2.style.display='block';
                sab.style.display='none';
                ftr.style.display='none';
                ins.style.display='block';
            }
            if (props.tipoFF==='JA'){
                col1.style.display='none';
                col1c.style.display='block';
                col2.style.display='none';
                sab.style.display='block';
                ftr.style.display='none';
                ins.style.display='none';
            }
        }
        if (props.tipoFF != tipoFF)
            BorraBusqueda();
    },[props.tipoFF, datosFormulario, seleccionFormulario]);

    const DescargaDatos = () => {      
        BP2022Service.datosFormularioFormaColor()
        .then((response) => {
            var df = {
                listaFormas : response.data.Formas,
                listaColores : response.data.Colores,
                listaSabores : response.data.Sabores,
                listaEnfs : response.data.Enfermedades,
                listaPAs : response.data.PrincipiosActivos,
            };
            df.listaSabores.splice(0,0,{Codigo:0, Valor:'Indique un sabor'});
            df.listaEnfs.splice(0,0,{Codigo:0, Valor:'Indique una enfermedad '});
            df.listaPAs.splice(0,0,{Codigo:0, Valor:'Indique un principio activo '});
            setDatosFormulario(df);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const KeyDownForm = (e) =>{
        var keyCode = e.keyCode;
        if (keyCode === 13){
            clickBuscar(e);
        }
    }



    const getStrColor=(idColor) =>{
        if (idColor){
            var color = datosFormulario.listaColores.find(col => col.Codigo===idColor)
            if (color)
                if (color.AuxStr)
                    return color.AuxStr;
                else
                    return "transparent";
            else
                return "#FFFFFF";
        }else{
            return "#FFFFFF";
        }
    }

    const getStrTamanos=() =>{
        var res = "";
        if (seleccionFormulario.tamanos){
            for (var i=0; i<seleccionFormulario.tamanos.length; i++){
                var aux = "";
                if (seleccionFormulario.tamanos[i]==="P"){
                    aux = "pequeña"
                }
                if (seleccionFormulario.tamanos[i]==="M"){
                    aux = "mediana"
                }
                if (seleccionFormulario.tamanos[i]==="G"){
                    aux = "grande"
                }
                if (res !== "")
                    if (i===seleccionFormulario.tamanos.length-1)
                        res += " o " + aux;
                    else
                        res += ", " + aux;
                else
                    res += upperCaseFirstLetter(aux);
            }
        }
        return res;
    }

    const getStrInscripciones=() =>{
        var res = "";
        if (seleccionFormulario.ins1 && seleccionFormulario.ins1 !== ""){
            if (res != "")
                res += "\n";
            res += seleccionFormulario.ins1;
        }
        if (seleccionFormulario.ins2 && seleccionFormulario.ins2 !== ""){
            if (res != "")
                res += "\n";
            res += seleccionFormulario.ins2;
        }
        if (seleccionFormulario.logo){
            if (res != "")
                res += "\n";
            res += "Con dibujo"
        }
        return res;
    }

    const getStrColorDesc=(idColor, co) =>{
        var res = "";
        if (idColor !== "" ){
            var color = datosFormulario.listaColores.find(col => col.Codigo===idColor)
            if (color)
                res = upperCaseFirstLetter(color.Valor);
            if (co==="C")                
                res += " claro";
            else if (co==="O")
                res += " oscuro";
        }
        return res;
    }

    const getStrSabor=() =>{
        var res = "";
        if (seleccionFormulario.idSabor > 0 ){
            var item = datosFormulario.listaSabores.find(col => col.Codigo===seleccionFormulario.idSabor)
            if (item)
                res = upperCaseFirstLetter(item.Valor);
        }
        return res;
    }

    const getStrIndicacion=() =>{
        var res = "";
        if (seleccionFormulario.idEnf > 0 ){
            var item = datosFormulario.listaEnfs.find(col => col.Codigo===seleccionFormulario.idEnf)
            if (item)
                res = upperCaseFirstLetter(item.Valor);
        }
        return res;
    }

    const getStrPrincipiosActivos=() =>{
        var res = "";
        if (seleccionFormulario.pas && seleccionFormulario.pas.length > 0 ){
            /*for (var i=0; i<seleccionFormulario.pas.length; i++){
                var aux = "";
                var item = datosFormulario.listaPAs.find(col => col.Codigo===seleccionFormulario.pas[i])
                if (item)
                    aux = item.Valor;
                if (res === "")
                    res = aux;
                else
                    res += "\n" + aux;
            }*/
            for (var i =0; i<seleccionFormulario.pas.length; i++){
                var valor = '';
                var item = datosFormulario.listaPAs.find(x => x.Codigo === Math.abs(seleccionFormulario.pas[i].codigo));
                if (item){
                    if (seleccionFormulario.pas[i].codigo<0)
                        valor += "No contiene ";
                    valor += item.Valor;
                    var sales = seleccionFormulario.pas[i].sales;
                    if (sales && sales.length>0){
                        for (var j=0; j<sales.length; j++){
                            if (j>0)
                                valor += " /"
                            else
                                valor += "        "
                            valor += " " + sales[j].valor;
                        }
                    }    
                    var cants = seleccionFormulario.pas[i].cants;
                    if (cants && cants.length>0){
                        for (var j=0; j<cants.length; j++){
                            if (j>0)
                                valor += " /"
                            else
                                valor += "        "
                            valor += " " + cants[j].des;
                        }
                    }    
                }
                res = valor;
            }
            if (seleccionFormulario.solo)                    
                res += "\n Únicamente los principios activos indicados";
            else 
                res += "\n Puede contener otros principios activos ";
        }
        return res;
    }

    const clickCambiaCol2 = (e) =>{
        var check = e.target;
        var item = GetAntecesorWhithClassName(check, 'checks__full');
        item = item.nextSibling;
        var content = item.nextSibling;
        if (check.checked){
            item.classList.add('active');
            content.classList.add('active');
        }else{
            item.classList.remove('active');
            content.classList.remove('active');
        }
        CambiaCol2(check.checked);
    }

    const despliegaColor2= (e) =>{
        var item = e.target;
        item = GetAntecesorWhithClassName(item,'showButton__colors');
        var content = item.nextSibling;
        item.classList.toggle("active");
        content.classList.toggle("active");
    }

    const clasesHeaderColor2 = (seleccionFormulario.col2) ? "showButton showButton__colors active" : "showButton showButton__colors";
    const clasesContentColor2 = (seleccionFormulario.col2) ? "module module__filters showContent active" : "module module__filters showContent";
    
    const hayBusqueda = seleccionFormulario.idEnf>0 || seleccionFormulario.idForma>0 || seleccionFormulario.idColor1!=='' || seleccionFormulario.idSabor>0 || seleccionFormulario.ins1 !== '' || (seleccionFormulario.pas && seleccionFormulario.pas.length>0);

    const clickBuscar = (e) =>{
        if  (hayBusqueda){
            var newpagina = "BFormaColorMUH";

            props.cambiaPagina({
                newpagina: newpagina,
                tipoFF: tipoFF,
                idForma: seleccionFormulario.idForma,
                idColor1: seleccionFormulario.idColor1,
                idColor2: seleccionFormulario.idColor2,
                idSabor: seleccionFormulario.idSabor,
                idEnf: seleccionFormulario.idEnf,
                tamanos: seleccionFormulario.tamanos,
                CO1: seleccionFormulario.CO1,
                CO2: seleccionFormulario.CO2,
                ranura: seleccionFormulario.ranura,
                logo: seleccionFormulario.logo,
                difCol: seleccionFormulario.difCol,
                anycol: seleccionFormulario.anycol,
                col2: seleccionFormulario.col2,
                ins1: seleccionFormulario.ins1,
                ins2: seleccionFormulario.ins2,
                pas: seleccionFormulario.pas,
                solo: seleccionFormulario.solo,
            })
        }
    }

    const BorraBusqueda = (e) =>{
        const formularioVacio={
            idForma: 0,
            idColor1: '',
            idColor2: '',
            idSabor: 0,
            idEnf: 0,
            tamanos: [],
            CO1: '',
            CO2: '',
            ranura: false,
            logo: false,
            difCol: false,
            anycol: false,
            col2: false,
            ins1: '',
            ins2: '',
            pas: [],
            solo: true,
        }
        setSeleccionFormulario(formularioVacio);
        var inputs = document.getElementsByClassName('inputSelector');
        if (inputs){
            for (var i=0; i<inputs.length; i++){
                inputs[i].value = "";
                const itemContainer = GetAntecesorWhithClassName(inputs[i], 'custom-select-container');
                if (itemContainer){
                    itemContainer.classList.remove('active');
                }
            }
        }
    }

    const DoNothing = () =>{

    }

    const CambioEnfermedad = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idEnf = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const BorraEnfermedad = () =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idEnf = 0;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const ClickForma = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (nuevaSeleccion.idForma === nuevovalor)
            nuevaSeleccion.idForma = 0;
        else
            nuevaSeleccion.idForma = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioForma = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idForma = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const ClickColor1 = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (nuevaSeleccion.idColor1 === nuevovalor)
            nuevaSeleccion.idColor1 = '';
        else
            nuevaSeleccion.idColor1 = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const ClickColor2 = (nuevovalor) =>{
        if (seleccionFormulario.idColor2 === nuevovalor)
            CambioColor2('');
        else
            CambioColor2(nuevovalor);
    }

    const CambioColor1 = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idColor1 = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioColor2 = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idColor2 = nuevovalor;
        nuevaSeleccion.anycol = false;
        nuevaSeleccion.col2 = true;
        setSeleccionFormulario(nuevaSeleccion);
    }
    
    const CambioSabor = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idSabor = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const BorraSabor = () =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idSabor = 0;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddTamano= (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (!nuevaSeleccion.tamanos) { nuevaSeleccion.tamanos = []};
        nuevaSeleccion.tamanos.push(nuevovalor);
        setSeleccionFormulario(nuevaSeleccion);
    }
    
    const RemoveTamano = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var i = nuevaSeleccion.tamanos.indexOf(valor);
        nuevaSeleccion.tamanos.splice(i,1);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const ToggleTamano = (valor) =>{
        if (seleccionFormulario.tamanos.includes(valor))
            RemoveTamano(valor);
        else
            AddTamano(valor);
    }

    const CambiaCO1= (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.CO1 = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambiaCO2= (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.CO2 = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }    

    const CambiaRanura= (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.ranura = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambiaLogo= (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.logo = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }
    
    const CambiaDifCol= (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.difCol = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }
    
    const CambiaAnycol= (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.anycol = nuevovalor;
        if (nuevovalor)
            nuevaSeleccion.idColor2 = "";
        else if (!nuevaSeleccion.idColor2 || nuevaSeleccion.idColor2==="")
            nuevaSeleccion.col2 = false;
        setSeleccionFormulario(nuevaSeleccion);
    }
        
    const CambiaCol2= (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.col2 = nuevovalor;
        if ((nuevovalor) && (!nuevaSeleccion.idColor2 || nuevaSeleccion.idColor2 == ""))
            nuevaSeleccion.anycol = true;
        if (!nuevovalor){
            nuevaSeleccion.anycol = false;
            nuevaSeleccion.idColor2 = "";
        }

        setSeleccionFormulario(nuevaSeleccion);
    }
    const CambiaSolo= (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.solo = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }
    
    const CambiaIns1= (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.ins1 = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }
    
    const CambiaIns2= (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.ins2 = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

/*
    const AddPA = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (!nuevaSeleccion.pas) { nuevaSeleccion.pas = []};
        nuevaSeleccion.pas.push(nuevovalor);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemovePA = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var i = nuevaSeleccion.pas.indexOf(parseInt(valor));
        nuevaSeleccion.pas.splice(i,1);
        setSeleccionFormulario(nuevaSeleccion);
    }
*/

    const AddSal = (idPA, idSal, des) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (pa){
            if (!pa.sales){
                pa.sales = []
            }
            var sal = pa.sales.find(item => item.codigo === idSal);
            if (!sal){
                pa.sales.push({codigo:idSal, valor: des});
            }
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveSal = (idPA, idSal) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (pa){
            if (!pa.sales){
                pa.sales = []
            }
            pa.sales = pa.sales.filter(item => item.codigo !== idSal);
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddCant = (idPA, idUnd, cantidad, des) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (pa){
            if (!pa.cants){
                pa.cants = []
            }
            var cant = pa.cants.find(item => item.cod === idUnd && item.cant === cantidad);
            if (!cant){
                pa.cants.push({cod: idUnd, cant: cantidad, des: des});
            }
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveCant = (idPA, idUnd, cantidad) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (pa){
            if (!pa.cants){
                pa.cants = []
            }
            pa.cants = pa.cants.filter(item => item.cod !== idUnd || item.cant !== cantidad);
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddPA = (idPA) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (!pa){
            nuevaSeleccion.pas.push({
                codigo: idPA,
                sales: [],
                cants: [],
            })
        }
        setSeleccionFormulario(nuevaSeleccion);
        //ActualizaFormasYVias(nuevaSeleccion.pas);
    }

    const UpdatePA = (idPA, negado) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => Math.abs(item.codigo)===Math.abs(idPA));
        if (pa){
            if (negado){
                pa.codigo = Math.abs(idPA) * (-1);
                pa.sales = [];
                pa.cants = [];
            }else{
                pa.codigo = Math.abs(idPA);
            }
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemovePA = (idPA) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.pas = nuevaSeleccion.pas.filter(item => Math.abs(item.codigo)!==Math.abs(idPA));
        setSeleccionFormulario(nuevaSeleccion);
        //ActualizaFormasYVias(nuevaSeleccion.pas);
    }

    const CambiaPASolo = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.solo = valor;
        setSeleccionFormulario(nuevaSeleccion);
    }



    const listaTamanos = [
        {
            cod: "P",
            txt: "<8 mm",
            img: "forma10size1.svg",
        },
        {
            cod: "M",
            txt: "8-15 mm",
            img: "forma10size2.svg",
        },
        {
            cod: "G",
            txt: ">15 mm",
            img: "forma10size3.svg",
        },
    ]


    const listaCodValorSabores   = (datosFormulario.listaSabores) ? datosFormulario.listaSabores.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorColores   = (datosFormulario.listaColores) ? datosFormulario.listaColores.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorPAs       = (datosFormulario.listaPAs)     ? datosFormulario.listaPAs.map    (it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorEnfs      = (datosFormulario.listaEnfs)    ? datosFormulario.listaEnfs.map   (it => ({codigo: it.Codigo, valor: it.Valor})) : [];

    listaCodValorColores.splice(0,0,{codigo:"", valor:''});
    listaCodValorColores.splice(0,0,{codigo:"", valor:'Selecciones un color'});

    const estiloSeleccionado = {
        backgroundColor: "#f8f0ff",
        borderColor: '#c8a6e5',
    }
    const estiloTamanoSeleccionado = {
        backgroundColor: "#f8f0ff",
        borderColor: '#c8a6e5',
        width: '63px',
        height: '63px',
    }
    const textoTamanoSeleccionado = {
        color: '#81509A',
        fontWeight: '500',
    }
    const estiloTamanoVacio = {
        width: '63px',
        height: '63px',
    }
    const textoTamanoVacio = {
    }
    const estiloVacio = { }

    const HTMLFormas = ((datosFormulario.listaFormas) ? 
        datosFormulario.listaFormas.map(forma =>
            <div  style={(forma.Codigo === seleccionFormulario.idForma ? estiloSeleccionado : estiloVacio)} onClick={(e) => ClickForma(forma.Codigo)}>
                <label>
                    <img title={forma.Valor} src={rootPath + "img/ficha/forma" + forma.Codigo + ".svg"} />
                </label>
            </div>
        )
    :
        <div></div>
    );

    const nomForma = (seleccionFormulario.idForma && seleccionFormulario.idForma>0 && datosFormulario.listaFormas ? datosFormulario.listaFormas.find(forma => forma.Codigo === seleccionFormulario.idForma).Valor : '');

    const HTMLTamanos = ((listaTamanos) ? 
        listaTamanos.map(tam =>
            <div onClick={(e) => ToggleTamano(tam.cod)}>
                <label>
                    <div class="mask" style={(seleccionFormulario.tamanos.includes(tam.cod) ? estiloTamanoSeleccionado : estiloTamanoVacio)} >
                        <img title={tam.img} src={rootPath + "img/ficha/" + tam.img} />
                    </div>
                    <div class="text" style={(seleccionFormulario.tamanos.includes(tam.cod) ? textoTamanoSeleccionado : textoTamanoVacio)} >
                        <p class="fs fs12" style={{textAlign:'center'}}>{tam.txt}</p>
                    </div>
                </label>
            </div>
        )
    :
        <div></div>
    );

    const HTMLColores = ((datosFormulario.listaColores) ?
        datosFormulario.listaColores.map(color =>
            (color.Codigo !== 'ZY' || tipoFF !== 'CO') ? 
            <label style={(color.Codigo==='ZY') ? {} : {backgroundColor: color.AuxStr}} 
                    className={(color.Codigo==='ZY') ? "color14" : "color"} 
                    onClick={(e) => ClickColor1(color.Codigo)}
                    title = {color.Valor}>
                {(seleccionFormulario.idColor1 === color.Codigo) ?
                    <img title={color.Valor} src="/img/ficha/checkSelected.svg" style={{opacity:'1'}}/>
                :
                    <img title={color.Valor} src="/img/ficha/checkSelected.svg" style={{opacity:'0'}}/>
                }
            </label>
            :
            <></>
        )
    :
        <div></div>
    );

    
    const HTMLColores2 = ((datosFormulario.listaColores) ?
        datosFormulario.listaColores.map(color =>
            (color.Codigo !== 'ZY' || tipoFF !== 'CO') ? 
                <label style={(color.Codigo==='ZY') ? {} : {backgroundColor: color.AuxStr}} 
                    className={(color.Codigo==='ZY') ? "color14" : "color"} 
                    onClick={(e) => CambioColor2(color.Codigo)}
                    title = {color.Valor}>
                {(seleccionFormulario.idColor2 === color.Codigo) ?
                    <img title={color.Valor} src="/img/ficha/checkSelected.svg" style={{opacity:'1'}}/>
                :
                    <img title={color.Valor} src="/img/ficha/checkSelected.svg" style={{opacity:'0'}}/>
                }
            </label>
            :
            <></>
        )
    :
        <div></div>
    );

    const estiloBotonesSup = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'space-between'} : {height:'76px', width:'100%', justifyContent: 'space-between', visibility:'hidden'})
    const estiloBotonesInf = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'center'} : {height:'76px', width:'100%', justifyContent: 'center', display:'none'})
    const estiloFormaMin = (seleccionFormulario.idForma === 12 ? {width: '9px', marginRight: '14px', marginLeft: '4px'}: {width: '17px', marginRight: '10px', marginLeft: '0px'});

    return(
        <div class="content" >
            { (datosFormulario.listaEnfs === null) ?
                <LoadingPopup titulo="Un momento" texto="Preparando formulario" esPorcentaje={true}></LoadingPopup>
            :
                <form class="content__results content__results__filters" id="main-filter" onSubmit={(e) => e.preventDefault()}  onKeyDown={(e) => KeyDownForm(e)} tabIndex="0">
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesSup}>
                        <span class="button button__arrow button__green noUppercase reset-form" data-form="#main-filter" title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} style={{visibility:'hidden'}} >
                            Vaciar búsqueda
                        </span>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                        <span class="button button__arrow button__green reset-form" data-form="#main-filter" style={{textTransform:'none', fontSize: '14px', width:'146px'}} title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} >
                            Vaciar búsqueda
                        </span>
                    </div>
                    <div class="cover cover__filters cover__filters__asideRight" >
                        <div class="coverContainerAside" >                    
                            <div class="wrapColumns">
                                <div class="moduleColumns"  style={{borderBottom:'none'}} >
                                    <div class="column column__1" id="columna_FBFC1">
                                        <div id="Seccion_Forma_Tamano_Ranura">
                                        <h3 class="title fs fs16 greyColor2">Forma:</h3>
                                        <div class="coverModule">
                                            <div class="module module__filters" >
                                                <div class="grid grid__pills grid__pills__form" id="shape-selector" >
                                                    {HTMLFormas}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="coverModule">
                                            <h3 class="title fs fs16 greyColor2">Tamaño:</h3>
                                            <div class="module module__filters" >
                                                <div class="grid grid__pills grid__pills__form grid__pills__form__size" >
                                                    {HTMLTamanos}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="coverModule">
                                            <h3 class="title fs fs16 greyColor2">Ranura:</h3>
                                            <div class="module module__filters" >
                                                <div class="box box__ranura" >
                                                    {(seleccionFormulario.ranura) ?
                                                        <label class="fs fs12 greyColor17" 
                                                            style={{backgroundColor:'#f8f0ff', borderColor:'#c8a6e5', color: '#81509A'}} 
                                                            onClick={(e) => CambiaRanura(false)}>Sí</label>
                                                    :
                                                        <label class="fs fs12 greyColor17"
                                                            onClick={(e) => CambiaRanura(true)}>Sí</label>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div id="Seccion_Color1_Copia" style={{display:'none'}}>
                                        <div class="coverModule" style={{paddingBottom:'16px'}}>
                                            <h3 class="title fs fs16 greyColor2">Color 1:</h3>
                                            <div class="module module__filters borderBottom" >
                                                <div class="grid grid__pills grid__pills__color" id="color-selector" >
                                                    {HTMLColores}                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="coverModule noPadding">
                                            <SelectorComboBox lista = {listaCodValorColores} 
                                                    idHTML="FBFormaColorColor1" 
                                                    valueSelected={seleccionFormulario.idColor1} 
                                                    alCambiar={CambioColor1} 
                                                    ancho="100%"
                                                    idEsStr={true}
                                            ></SelectorComboBox>
                                            <div class="module module__filters">
                                                <div class="grid grid__pills grid__pills__form grid__pills__form__tone" >
                                                    {(seleccionFormulario.CO1 === 'C') ?
                                                        <label style={estiloTamanoSeleccionado} onClick={(e) => CambiaCO1("")}>
                                                            <span >
                                                                <img title="Claro" src={rootPath + "img/ficha/sun.svg"} />
                                                                <p style={{color: '#81509A'}} class="fs fs12 fw fw400" >Claro</p>
                                                            </span>
                                                        </label>
                                                    :
                                                        <label style={estiloTamanoVacio} onClick={(e) => CambiaCO1("C")}>
                                                            <span>
                                                                <img title="Claro" src={rootPath + "img/ficha/sun.svg"} />
                                                                <p class="fs fs12 fw fw400" >Claro</p>
                                                            </span>
                                                        </label>
                                                    }
                                                    {(seleccionFormulario.CO1 === 'O') ?
                                                        <label style={estiloTamanoSeleccionado} onClick={(e) => CambiaCO1("")}>
                                                            <span>
                                                                <img title="Oscuro" src={rootPath + "img/ficha/moon.svg"} />
                                                                <p style={{color: '#81509A'}} class="fs fs12 fw fw400" >Oscuro</p>
                                                            </span>
                                                        </label>
                                                    :
                                                        <label style={estiloTamanoVacio} onClick={(e) => CambiaCO1("O")}>
                                                            <span>
                                                                <img title="Oscuro" src={rootPath + "img/ficha/moon.svg"} />
                                                                <p class="fs fs12 fw fw400" >Oscuro</p>
                                                            </span>
                                                        </label>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="coverModule">
                                        <div id="Seccion_Inscripciones" style={{paddingTop:'20px'}}>
                                            <h3 class="title fs fs16 greyColor2">Inscripciones:</h3>
                                            <div class="module module__filters" >
                                                <div class="box box__inputs" >
                                                    <div class="cover" >
                                                        <input type="text" name="" value="" 
                                                                placeholder="Escribe inscripción" 
                                                                class="related-text" 
                                                                value = {seleccionFormulario.ins1}
                                                                onChange={(e) => CambiaIns1(e.target.value)} />
                                                    </div>
                                                    <div class="cover" >
                                                        <input type="text" name="" 
                                                                class="related-text" 
                                                                placeholder="Escribe inscripción 2" 
                                                                value = {seleccionFormulario.ins2}
                                                                onChange={(e) => CambiaIns2(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div class="box box__ranura" >
                                                    {(seleccionFormulario.logo) ?
                                                        <label class="fs fs12 greyColor17" 
                                                            style={{backgroundColor:'#f8f0ff', borderColor:'#c8a6e5', color: '#81509A'}} 
                                                            onClick={(e) => CambiaLogo(false)}>Presencia de dibujo</label>
                                                    :
                                                        <label class="fs fs12 greyColor17"
                                                            onClick={(e) => CambiaLogo(true)}>Presencia de dibujo</label>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>

                                    <div class="column column__2" id="columna_FBFC2">
                                        <div id="Seccion_Color1">
                                        <div class="coverModule">
                                            <h3 class="title fs fs16 greyColor2">Color 1:</h3>
                                            <div class="module module__filters borderBottom" >
                                                <div class="grid grid__pills grid__pills__color" id="color-selector" >
                                                    {HTMLColores}                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="coverModule noPadding">
                                            <SelectorComboBox lista = {listaCodValorColores} 
                                                    idHTML="FBFormaColorColor1" 
                                                    valueSelected={seleccionFormulario.idColor1} 
                                                    alCambiar={CambioColor1} 
                                                    ancho="100%"
                                                    idEsStr={true}
                                            ></SelectorComboBox>
                                        </div>
                                        <div class="coverModule">
                                            <div class="module module__filters">
                                                <div class="grid grid__pills grid__pills__form grid__pills__form__tone" >
                                                    {(seleccionFormulario.CO1 === 'C') ?
                                                        <label style={estiloTamanoSeleccionado} onClick={(e) => CambiaCO1("")}>
                                                            <span >
                                                                <img title="Claro" src={rootPath + "img/ficha/sun.svg"} />
                                                                <p style={{color: '#81509A'}} class="fs fs12 fw fw400" >Claro</p>
                                                            </span>
                                                        </label>
                                                    :
                                                        <label style={estiloTamanoVacio} onClick={(e) => CambiaCO1("C")}>
                                                            <span>
                                                                <img title="Claro" src={rootPath + "img/ficha/sun.svg"} />
                                                                <p class="fs fs12 fw fw400" >Claro</p>
                                                            </span>
                                                        </label>
                                                    }
                                                    {(seleccionFormulario.CO1 === 'O') ?
                                                        <label style={estiloTamanoSeleccionado} onClick={(e) => CambiaCO1("")}>
                                                            <span>
                                                                <img title="Oscuro" src={rootPath + "img/ficha/moon.svg"} />
                                                                <p style={{color: '#81509A'}} class="fs fs12 fw fw400" >Oscuro</p>
                                                            </span>
                                                        </label>
                                                    :
                                                        <label style={estiloTamanoVacio} onClick={(e) => CambiaCO1("O")}>
                                                            <span>
                                                                <img title="Oscuro" src={rootPath + "img/ficha/moon.svg"} />
                                                                <p class="fs fs12 fw fw400" >Oscuro</p>
                                                            </span>
                                                        </label>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="coverModule showElement" id="Seccion_Color2">
                                            <div class="checks checks__full" style={{paddingTop:'10px'}}>
                                                <label>
                                                    <input type="checkbox" id="check2colores" checked={seleccionFormulario.col2} onClick={(e) => clickCambiaCol2 (e)}/>
                                                    Tiene 2 colores
                                                </label>
                                            </div>
                                            <div class={clasesHeaderColor2} id="cabecera__boton2" onClick = {(e) => despliegaColor2(e)}>
                                                <h3 class="title fs fs16 greyColor2">Color 2:</h3><span class="plus" id="remove-color2"></span>
                                            </div>
                                            <div class={clasesContentColor2} >
                                                <div class="checks checks__full" style={{paddingTop:'10px'}}>
                                                    <label>
                                                        <input type="checkbox" id="checkCualquierColor" checked={seleccionFormulario.anycol} onClick={(e) => CambiaAnycol (e.target.checked)}/>
                                                        Cualquier color
                                                    </label>
                                                </div>
                                                <div class="grid grid__pills grid__pills__color borderBottom" id="color-selector2" >
                                                    {HTMLColores2}  
                                                </div>
                                                <div class="coverModule noPadding" style={{paddingLeft:'0'}}>
                                                    <SelectorComboBox lista = {listaCodValorColores} 
                                                            idHTML="FBFormaColorColor2" 
                                                            valueSelected={seleccionFormulario.idColor2} 
                                                            alCambiar={CambioColor2} 
                                                            ancho="100%"
                                                            idEsStr={true}
                                                    ></SelectorComboBox>
                                                </div>
                                                <div class="coverModule" style={{paddingLeft:'0', paddingBottom:'20px'}}>
                                                    <div class="module module__filters">
                                                        <div class="grid grid__pills grid__pills__form grid__pills__form__tone" >
                                                            {(seleccionFormulario.CO2 === 'C') ?
                                                                <label style={estiloTamanoSeleccionado} onClick={(e) => CambiaCO2("")}>
                                                                    <span >
                                                                        <img title="Claro" src={rootPath + "img/ficha/sun.svg"} />
                                                                        <p style={{color: '#81509A'}} class="fs fs12 fw fw400" >Claro</p>
                                                                    </span>
                                                                </label>
                                                            :
                                                                <label style={estiloTamanoVacio} onClick={(e) => CambiaCO2("C")}>
                                                                    <span>
                                                                        <img title="Claro" src={rootPath + "img/ficha/sun.svg"} />
                                                                        <p class="fs fs12 fw fw400" >Claro</p>
                                                                    </span>
                                                                </label>
                                                            }
                                                            {(seleccionFormulario.CO2 === 'O') ?
                                                                <label style={estiloTamanoSeleccionado} onClick={(e) => CambiaCO2("")}>
                                                                    <span>
                                                                        <img title="Oscuro" src={rootPath + "img/ficha/moon.svg"} />
                                                                        <p style={{color: '#81509A'}} class="fs fs12 fw fw400" >Oscuro</p>
                                                                    </span>
                                                                </label>
                                                            :
                                                                <label style={estiloTamanoVacio} onClick={(e) => CambiaCO2("O")}>
                                                                    <span>
                                                                        <img title="Oscuro" src={rootPath + "img/ficha/moon.svg"} />
                                                                        <p class="fs fs12 fw fw400" >Oscuro</p>
                                                                    </span>
                                                                </label>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="checks checks__full" style={{paddingTop:'10px'}}>
                                                <label>
                                                    <input type="checkbox" name="relacion" value="relacion1" checked={seleccionFormulario.difCol} onClick={(e) => CambiaDifCol (e.target.checked)}/>
                                                    Tiene comprimidos de diferentes colores y/o formas en el mismo envase
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cover cover__filters cover__filters__2 cover__filters__2--1" id="Seccion_Sabor">
                                    <div class="module module__filters" >
                                        <h3 class="title fs fs16 greyColor2">Sabor:</h3>
                                        
                                        <SelectorUno2 lista = {listaCodValorSabores} 
                                                    idHTML="FBFormaColorMUHLSabor" 
                                                    valueSelected={seleccionFormulario.idSabor}
                                                    alCambiar={CambioSabor} 
                                                    alBorrar={BorraSabor} 
                                                    ancho="100%"
                                        ></SelectorUno2>
                                    </div>
                                </div>

                                <h3 class="title fs fs16 greyColor2">Indicacion:</h3>
                                
                                <SelectorUno2 lista = {listaCodValorEnfs} 
                                            idHTML="FBFormaColorMUHLEnf" 
                                            valueSelected={seleccionFormulario.idEnf}
                                            alCambiar={CambioEnfermedad} 
                                            alBorrar={BorraEnfermedad} 
                                            ancho="100%"
                                ></SelectorUno2>
                            </div>
                            <div class="asideRight" >
                                <h3 class="title fs fs16 greyColor2">Composición de búsqueda:</h3>
                                <div class="module" >
                                    {(seleccionFormulario.idForma>0  && tipoFF==="CO") ?
                                    <div class="mask coverSvg" id="module-shape" >
                                        <PreviewForma 
                                            idForma = {seleccionFormulario.idForma}
                                            color1 = {getStrColor(seleccionFormulario.idColor1)}
                                            color2 = {(seleccionFormulario.idColor2) ? getStrColor(seleccionFormulario.idColor2): getStrColor(seleccionFormulario.idColor1)}
                                        />
                                    </div>
                                    :
                                    <div></div>
                                    }
                                    <div class="box box__text" >
                                        {(seleccionFormulario.idForma>0  && tipoFF==="CO") ?
                                            <div class="row" >
                                                <div class="column fs fs14 greyColor FlamaBook" >
                                                    <p>Forma:</p>
                                                </div>
                                                <div class="column fs fs14 greenColor2" >
                                                    <p id="label-sabor" style={{verticalAlign:'middle'}}>
                                                        <img title={nomForma} style={estiloFormaMin} src={rootPath + "img/ficha/forma" + seleccionFormulario.idForma + ".svg"} />
                                                        {nomForma}
                                                    </p>
                                                </div>
                                            </div>
                                        :
                                            <div></div>
                                        }
                                        {(seleccionFormulario.tamanos && seleccionFormulario.tamanos.length>0 && tipoFF==="CO") ?
                                            <div class="row" >
                                                <div class="column fs fs14 greyColor FlamaBook" >
                                                    <p>Tamaño:</p>
                                                </div>
                                                <div class="column fs fs14 greenColor2" >
                                                    <p id="label-sabor" >{getStrTamanos()}</p>
                                                </div>
                                            </div>
                                        :
                                            <div></div>
                                        }
                                        {(seleccionFormulario.ranura && tipoFF==="CO") ?
                                            <div class="row" >
                                                <div class="column fs fs14 greyColor FlamaBook" >
                                                    <p>Ranura:</p>
                                                </div>
                                                <div class="column fs fs14 greenColor2" >
                                                    <p id="label-sabor" >Sí</p>
                                                </div>
                                            </div>
                                        :
                                            <div></div>
                                        }
                                        {(((seleccionFormulario.ins1 && seleccionFormulario.ins1 !== "") 
                                            || (seleccionFormulario.ins2 && seleccionFormulario.ins2 !== "")
                                            || (seleccionFormulario.logo)) 
                                        && (tipoFF==="CO" || tipoFF==="CA")) ?
                                            <div class="row" >
                                                <div class="column fs fs14 greyColor FlamaBook" >
                                                    <p>Inscripciones:</p>
                                                </div>
                                                <div class="column fs fs14 greenColor2"  >
                                                    <p id="label-sabor" style={{whiteSpace: 'pre-line'}}>{getStrInscripciones()}</p>
                                                </div>
                                            </div>
                                        :
                                            <div></div>
                                        }
                                        {(seleccionFormulario.idColor1 && seleccionFormulario.idColor1 !== "")                                              
                                        ?
                                            <div class="row" >
                                                <div class="column fs fs14 greyColor FlamaBook" >
                                                    <p>Color:</p>
                                                </div>
                                                <div class="column fs fs14 greenColor2"  >
                                                    <p id="label-color" >
                                                        {(seleccionFormulario.idColor1 === "ZY") ?
                                                            <span class="tile" style={{boxShadow: 'none'}}></span>
                                                        :
                                                            <span class="tile" style={{backgroundColor: getStrColor(seleccionFormulario.idColor1), backgroundImage: 'none', boxShadow: 'none'}}></span>
                                                        }
                                                        <span class="name">
                                                            {upperCaseFirstLetter(getStrColorDesc(seleccionFormulario.idColor1, seleccionFormulario.CO1))}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        :
                                            <div></div>
                                        }
                                        {(((seleccionFormulario.idColor2 && seleccionFormulario.idColor2 !== "") || seleccionFormulario.col2)
                                        && (tipoFF === "CO" || tipoFF === "CA"))
                                        ?
                                            <div class="row" >
                                                <div class="column fs fs14 greyColor FlamaBook" >
                                                    <p>Color 2:</p>
                                                </div>
                                                <div class="column fs fs14 greenColor2"  >
                                                    <p id="label-color" >
                                                        {(seleccionFormulario.anycol) ?
                                                            <span class="tile" style={{boxShadow: 'none',backgroundImage: 'none'}}></span>
                                                        : (seleccionFormulario.idColor2 === "ZY") ?
                                                            <span class="tile" style={{boxShadow: 'none'}}></span>
                                                        :
                                                            <span class="tile" style={{backgroundColor:getStrColor(seleccionFormulario.idColor2), backgroundImage: 'none', boxShadow: 'none'}}></span>
                                                        }
                                                        <span class="name">
                                                            {(seleccionFormulario.anycol) ?
                                                                "Cualquiera"
                                                            :
                                                                upperCaseFirstLetter(getStrColorDesc(seleccionFormulario.idColor2, seleccionFormulario.CO2))
                                                            }
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        :
                                            <div></div>
                                        }
                                        {(seleccionFormulario.idSabor > 0 && (tipoFF==="CO" || tipoFF==="JA")) ?
                                            <div class="row" >
                                                <div class="column fs fs14 greyColor FlamaBook" >
                                                    <p>Sabor:</p>
                                                </div>
                                                <div class="column fs fs14 greenColor2" >
                                                    <p id="label-sabor" >{getStrSabor()}</p>
                                                </div>
                                            </div>
                                        :
                                            <div></div>
                                        }

                                        {(seleccionFormulario.idEnf > 0 ) ?
                                            <div class="row" >
                                                <div class="column fs fs14 greyColor FlamaBook" >
                                                    <p>Indicación:</p>
                                                </div>
                                                <div class="column fs fs14 greenColor2" >
                                                    <p id="label-sabor" >{getStrIndicacion()}</p>
                                                </div>
                                            </div>
                                        :
                                            <div></div>
                                        }

                                        {(seleccionFormulario.pas && seleccionFormulario.pas.length > 0 ) ?
                                            <div class="row" >
                                                <div class="column fs fs14 greyColor FlamaBook" >
                                                    <p>Principios activos:</p>
                                                </div>
                                                <div class="column fs fs14 greenColor2" >
                                                    <p id="label-sabor"style={{whiteSpace: 'pre-line'}} >{getStrPrincipiosActivos()}</p>
                                                </div>
                                            </div>
                                        :
                                            <div></div>
                                        }
                                        {(seleccionFormulario.difCol && (tipoFF==="CO" || tipoFF==="CA")) ?
                                            <div class="row" >
                                                <div class="fs fs14 greyColor FlamaBook" style={{paddingLeft:'10px'}} >
                                                    <p>Tiene comprimidos diferentes</p>
                                                </div>
                                            </div>
                                        :
                                            <div></div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SelectorComposicion lista = {listaCodValorPAs} 
                                idHTML="FBFCCompos" 
                                valuesSelected={seleccionFormulario.pas} 
                                pasolo={seleccionFormulario.solo}
                                alAnadirPA={AddPA} 
                                alBorrarPA={RemovePA} 
                                alUpdatePA={UpdatePA}
                                alAnadirSal={AddSal} 
                                alBorrarSal={RemoveSal} 
                                alAnadirCant={AddCant} 
                                alBorrarCant={RemoveCant} 
                                alCambiarPASolo={CambiaPASolo}
                                ancho="100%"
                                titulo="Principios activos:"
                                muestraChecks={true}
                                nombreComponentes = "Principios activos"
                                tipo="H"
                                tipoFiltradoLista={1}
                                ocultaNegado = {true}
                    ></SelectorComposicion>
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesInf}>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                    </div>

                </form>
            }
        </div>
    );
}
    
export default FBFormaColor;
    /*, backgroundImage:'/img/ficha/transparent.svg', backgroundRepeat:'repeat', backgroundSize:'50%'*/