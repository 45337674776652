import AdvertenciasTipo from "../Advertencias/AdvertenciasTipo";
import DatosConservacion from "./DatosConservacion";

const Conservacion = (props) => {
    const infoConservacion = props.infoConservacion;
    var datosConservacion = JSON.parse(JSON.stringify(infoConservacion.DatosConservacion));
    const mensajesConservacion = infoConservacion.MensajesConservacion;
    var datosValidez = [];

    if (mensajesConservacion){
        for (var i=0; i<mensajesConservacion.length; i++){
            /*if (mensajesConservacion[i].IdTipo === 119 || mensajesConservacion[i].IdTipo === 100144){
                if (mensajesConservacion[i].Mensajes){
                    for (var j=0; j<mensajesConservacion[i].Mensajes.length; j++){
                        const mensajecomodato = {
                            Dato : mensajesConservacion[i].Mensajes[j].Mensaje,
                        }        
                        datosConservacion.push(mensajecomodato);
                    }
                }
            }
            else{*/
                if (mensajesConservacion[i].Mensajes){
                    for (var j=0; j<mensajesConservacion[i].Mensajes.length; j++){
                        const mensajecomodato = {
                            Dato : mensajesConservacion[i].Mensajes[j].Mensaje,
                        }        
                        if ((j===0) && (mensajesConservacion[i].URLPictograma) && (mensajesConservacion[i].URLPictograma !== '')){
                            mensajecomodato.URLPictograma = mensajesConservacion[i].URLPictograma;
                        }
                        datosValidez.push(mensajecomodato);
                    }
                }
            //}
        }
    }

    const advertenciasPorTipo = mensajesConservacion.map(tipoAdver => <AdvertenciasTipo advertenciasTipo={tipoAdver}></AdvertenciasTipo>);

    return (
        <div class="infoContent" >
            {(datosConservacion && datosConservacion.length>0) ?
            <DatosConservacion datosConservacion={datosConservacion} titulo = "Conservación antes de apertura"></DatosConservacion>
            :
            <div></div>
            }
            {(datosValidez && datosValidez.length>0) ?
            <DatosConservacion datosConservacion={datosValidez} titulo = "Periodos de validez"></DatosConservacion>
            :
            <div></div>
            }
        </div>
    )
}
export default Conservacion;

