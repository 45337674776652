import Selector from "../Utilidades/Selector/Selector";
import BP2022Service from "../../services/user.service";
import AuthService from "../../services/auth.service"
import { useState } from "react";
import { useEffect } from "react";
import Emergente from "../Fichas/FichaMUH/Apartados/Cismed/Emergente";
import { AddClassNameToClassItems, addDays, rootPath } from "../../services/utils";
import SelectorComboBox from "../Utilidades/Selector/SelectorComboBox";
//import BP2022AuthService from "../../services/auth.service";
import BP2022AuthService from "../../services/auth.service";
import KeyEmpresa from "./KeyEmpresa";
import {FormatoFechaDDMMYYYY} from '../../services/utils.js'
import UsuAdicional from "./UsuAdicional";
import packageJson from '../../../package.json';
import CambioPassword from "./CambioPassword.js";
import RecuperaPassword2 from "./RecuperaPassword2.js";
import CambiaCorreo2 from "./CambiaCorreo2.js";
import ConfirmaCorreo2 from "./ConfirmaCorreo2.js";

const PaginaConfiguracion = (props) => {
    const [datosConf,setDatosConf] = useState(null);
    const [tieneLicenciaBot, setTieneLicenciaBot] = useState(null);
    const [mostrarEmpresa, setMostrarEmpresa] = useState(false);
    const [mostrarUsuAdicional, setMostrarUsuAdicional] = useState(false);
    const [mostrarBotonCambioPass, setMostrarBotonCambioPass] = useState(false);
    const [mostrarCambioPass, setMostrarCambioPass] = useState(false);
    const [verCambioPass2, setVerCambioPass2] = useState(props.cambiopass);
    const [verCambioMail2, setVerCambioMail2] = useState(props.cambiomail);
    const [verConfirmaMail2, setVerConfirmaMail2] = useState(props.confirmamail);
    const [link, setLink] = useState(props.link);
    const [dirCorreo, setDirCorreo] = useState('')

    const provincias =(datosConf ? datosConf.Provincias : null);
    const ccaas = (datosConf? datosConf.CCAAs : null);
    var guardando = false;
    const user = AuthService.getLocalUser();

    const refdate = addDays(new Date(), 367).toJSON();
    const username = (user && user.botPlusUser && user.botPlusUser.Username) ? user.botPlusUser.Username : '';
    const fechaLicencia = (user && user.botPlusUser && user.botPlusUser.LicenciaHasta && user.botPlusUser.LicenciaHasta<refdate) ? user.botPlusUser.LicenciaHasta : null;
    const esUsuarioPrincipal = (user && user.botPlusUser && user.botPlusUser.EsUsuarioPrincipal ? user.botPlusUser.EsUsuarioPrincipal : false);

    useEffect(() => {
        if (datosConf==null)
        {
            DescargaDatosConfiguracion();
        }else{            
            if ((datosConf.Mensaje)&&(datosConf.Mensaje !== '')){
                //props.atras();
                if ((props.returnTo) && (props.returnTo !== '') && (!props.returnTo.includes('BAgrupHomoAuto'))){
                    window.location.href = props.returnTo;                
                }else{
                    window.location.href = rootPath;
                }
            }
        }
        if (tieneLicenciaBot===null){
            getTieneLicenciaBot();
         }
         BP2022Service.moduloCambioPass()
         .then((response) => {
             if (response.data){
                setMostrarBotonCambioPass(true);
                BP2022Service.getDirCorreo()
                .then((response) => {
                    if (response.data && response.data.Correo != ""){
                        setDirCorreo(response.data.Correo);
                    }
                })
                .catch(function (error) {
                    console.log('Error', error.message);
                });
             }
         })
         .catch(function (error) {
             console.log('Error', error.message);
         });

    },[datosConf]);

    const ocultaCambioPass2 = () =>{
        setVerCambioPass2(false);
        props.alTerminarCambio();
    }
  
    const ocultaCambioMail2 = () =>{
        setVerCambioMail2(false);
        props.alTerminarCambio();
    }
  
    const ocultaConfirmaMail2 = () =>{
        setVerConfirmaMail2(false);
        props.alTerminarCambio();
    }
  
    const getTieneLicenciaBot = () =>{
        BP2022AuthService.tieneLicenciaLocal()
        .then((response) => {
            setTieneLicenciaBot(response)
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    }

    const muestraEmpresa = (valor) =>{
        setMostrarEmpresa(valor);
    }

    const muestraUsuAdicional = (valor) =>{
        setMostrarUsuAdicional(valor);
    }

    const muestraCambioPassword = (valor) =>{
        setMostrarCambioPass(valor);
    }

    const DescargaDatosConfiguracion = () => {
        if (!guardando){
            guardando=true;
            BP2022Service.getDatosConfiguracion()
            .then((response) => {
                response.data.Provincias.splice(0,0,{IdProvincia:0, NombreProvincia:'Indique una provincia', IdCCAA: 0, NombreComunidad: ''});
                setDatosConf(response.data)
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
        }
    };


    const GuardaDatosConfiguracion = () => {
        BP2022Service.setDatosConfiguracion(datosConf.IdProvincia, datosConf.NumFarmacia)
        .then((response) => {
            var lu = JSON.parse(localStorage.getItem("login"));
            lu.botPlusUser.idCCAA = response.data.IdCCAA;
            lu.botPlusUser.idProvincia = response.data.IdProvincia;
            lu.botPlusUser.Provincia = response.data.Provincia;
            localStorage.setItem("login", JSON.stringify(lu));
            setDatosConf(response.data)
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    
    const CambiaCCAA = (idProvincia) => {
        let tempDatosConf = JSON.parse(JSON.stringify(datosConf));
        var aux = provincias.filter(prov => {return prov.IdProvincia.toString()===idProvincia.toString()});
        if (aux){
            if (aux.length>0){
                var idCCAA = aux[0].IdCCAA;
                tempDatosConf.IdProvincia = parseInt(idProvincia);
                tempDatosConf.IdCCAA = idCCAA
            }
        }
        tempDatosConf.Mensaje = null;
        setDatosConf(tempDatosConf);
    };

    const DoNothing=() =>{

    }

    const CambiaNumFarmacia = (e) => {
        let tempDatosConf = JSON.parse(JSON.stringify(datosConf));
        tempDatosConf.NumFarmacia = e.target.value;
        tempDatosConf.Mensaje = null;
        setDatosConf(tempDatosConf);
    };

    const modalTitulo = "";
    const modalContent = (
        <div>
                <p>La Organización Farmacéutica Colegial - Colegios, Consejos Autonómicos y Consejo General - ha desarrollado el &nbsp;
                    <strong>Centro de Información sobre el Suministro de Medicamentos (CisMED)</strong>.
                </p>
                <p>CisMED es un sistema de información con un doble objetivo: detectar en tiempo real incidencias en el suministro regular de medicamentos, 
                    y dotar a la Organización de información sobre su disponibilidad para facilitar la toma de decisiones de los farmacéuticos, y contribuir 
                    a la continuidad de los tratamientos de los pacientes.
                </p>
                <p>Las farmacias adheridas a CisMED generan una información que, una vez tratada, permite disponer de datos sobre el suministro de medicamentos 
                    en su provincia y, una vez remitida al Consejo General, consolidar los datos a nivel nacional.
                </p>
                <p>Si es usted un usuario perteneciente a una farmacia adherida a CisMED, podrá consultar la información sobre faltas en el suministro del medicamento 
                    de forma completa.
                </p>
        </div>
    );

    const modalTituloAvisoInicial = "";
    const modalContentAvisoInicial = (
        <div>
                <p>Gracias por acceder a BOT PLUS.
                </p>
                <p>Antes de comenzar a utilizar la base de datos, es necesario que usted configure la provincia de la sesión, de forma que BOT PLUS le pueda 
                    mostrar la información de ámbito autonómico asociada a medicamentos y productos de parafarmacia. Para ello incluya dicha provincia en la 
                    siguiente pantalla. Esta provincia se podrá modificar posteriormente en cualquier momento.
                </p>
                <p>En esta pantalla podrá también incluir el número de farmacia adherida a CisMED, para que BOT PLUS le muestre la información sobre incidencias
                    de suministro de medicamentos. En caso de no ser una farmacia adherida a CisMED, podrá utilizar BOT PLUS con total normalidad, si bien no podrá 
                    acceder a la información de CisMED.
                </p>
        </div>
    );

    const modalKeyName="ModalCismedInfo"
    const modalKeyNameAvisoInicial="ModalAvisoInicial"

    const AbreEmergenteCismed = () => {
        AddClassNameToClassItems(modalKeyName, null, 'active');
    }

    const modalAvisoInicialActivo = props.muestraAviso;


    const modalTitulo2 = "";
    const modalContent2 = (
        <div>
                <p>
                    {(datosConf) ? datosConf.Mensaje : ''}
                </p>
        </div>
    );

    const modalKeyName2="ModalSavedInfo"

    const AbreEmergenteSaved = () => {
        AddClassNameToClassItems(modalKeyName2, null, 'active');
        //props.refresca();
    }

    const ancho = window.innerWidth;
    const isMobile = (ancho<768);    

    if (!datosConf){
        return (
            <div></div>
        );
    }
    else{
        if (!datosConf.IdProvincia>0){
            if (provincias[0].IdProvincia!==0){
                provincias.splice(0,0,{IdProvincia: 0, NombreProvincia: 'Seleccione provincia', IdCCAA : 0});
                ccaas.splice(0,0,{Codigo:0, Valor:'-'});
            }
        }
        const listaProvinciasCodValor = provincias.map(prov => ({codigo: prov.IdProvincia, valor: prov.NombreProvincia}));
        const listaCCAACodValor = ccaas.map(ca => ({codigo: ca.Codigo, valor: ca.Valor}));
        return (
            <div class="container container__column container__column__configuracion" >
                {(verCambioPass2) ?
                        <RecuperaPassword2 alCerrar={ocultaCambioPass2} link={link} esRecupera={false} esCambioPass = {verCambioPass2}></RecuperaPassword2>
                    :(verCambioMail2) ?
                        <CambiaCorreo2 alCerrar={ocultaCambioMail2} link={link} esCambioMail = {verCambioMail2}></CambiaCorreo2>
                    :(verConfirmaMail2) ?
                        <ConfirmaCorreo2 alCerrar={ocultaConfirmaMail2} link={link} esConfrimaMail = {verConfirmaMail2}></ConfirmaCorreo2>
                    :
                        <></>
                }
                <Emergente contenido={modalContent} keyName={modalKeyName} titulo={modalTitulo} ></Emergente>
                <Emergente contenido={modalContent2} keyName={modalKeyName2} titulo={modalTitulo2} alto='150px'></Emergente>
                <Emergente contenido={modalContentAvisoInicial} keyName={modalKeyNameAvisoInicial} titulo={modalTituloAvisoInicial} activo = {modalAvisoInicialActivo}></Emergente>
                {mostrarEmpresa ?
                    <KeyEmpresa alCerrar={muestraEmpresa}></KeyEmpresa>
                :
                    mostrarUsuAdicional ?
                        <UsuAdicional alCerrar={muestraUsuAdicional}></UsuAdicional>
                    :
                        mostrarCambioPass ?
                            <CambioPassword alCerrar={muestraCambioPassword}></CambioPassword>
                        :
                            <></>
                }

                <div class="content" >
                    <form class="content__results content__results__filters" id="main-filter" onSubmit={(e) => e.preventDefault()}>
                        
                        <div class="module module__buttons" style={{justifyContent: 'space-between'}}>
                            <a href="#" title="Farmaceuticos" >
                                <img src="./img/logoFarmaceuticos.svg" alt="logoFarmaceuticos" />
                            </a>
                            <div>
                                {username !== '' ? 
                                    <div class="box" style={{fontSize: '13px', marginTop: '10px', fontFamily: 'FlamaBook, sans-serif', color: '#1D1D1B'}}>
                                        <span>Usuario BOT PLUS: </span>
                                        <span style={{fontWeight:'700'}}>&nbsp;{username}</span> 
                                    </div>
                                : <></>}
                                {dirCorreo && dirCorreo !== '' ? 
                                    <div class="box" style={{fontSize: '13px', marginTop: '5px', fontFamily: 'FlamaBook, sans-serif', color: '#1D1D1B', textAlign: 'center'}}>
                                        <span>{dirCorreo} </span>
                                    </div>
                                : <></>}
                                {fechaLicencia ? 
                                    <div class="box" style={{fontSize: '13px', marginTop: '10px', fontFamily: 'FlamaBook, sans-serif', color: '#1D1D1B'}}>
                                        <span>Licencia válida hasta </span>
                                        {FormatoFechaDDMMYYYY(fechaLicencia)
                                    }</div> 
                                : <></>}
                            </div>
                            {((tieneLicenciaBot || mostrarBotonCambioPass) && !isMobile && esUsuarioPrincipal) ?
                            <div>
                            {(tieneLicenciaBot && !isMobile && esUsuarioPrincipal) ?
                                <div>
                                    <button class="button button__arrow button__purple noUppercase" 
                                            title="Generación de KEY para empresa de informática" 
                                            style={{height:'30px',width:'100%',justifyContent:'center'}}
                                            onClick={ e => muestraEmpresa(true)}>Generar KEY</button>
                                    <button class="button button__arrow button__purple noUppercase" 
                                            title="Configuración de usuario adicional" 
                                            style={{height:'30px',width:'100%',justifyContent:'center', marginTop:'6px'}}
                                            onClick={ e => muestraUsuAdicional(true)}>Usuario adicional</button>                                        
                                </div>                                        
                            :
                                <></>
                            }
                            {(mostrarBotonCambioPass && !isMobile && esUsuarioPrincipal && datosConf.PuedeCambiarPass) ?
                                    <button class="button button__arrow button__purple noUppercase" 
                                            title="Cambiar contraseña de acceso a BotPlus" 
                                            style={{height:'30px',width:'100%',justifyContent:'center', marginTop:'6px'}}
                                            onClick={ e => muestraCambioPassword(true)}>Cambiar contraseña</button>
                            :
                                <></>
                            }
                            </div>
                            :
                            <></>
                        }
                        </div>
                        {(tieneLicenciaBot && isMobile && esUsuarioPrincipal) ?
                            <div class="module module__buttons" style={{justifyContent: 'space-between'}}>
                                <button class="button button__arrow button__purple noUppercase" 
                                        title="Generación de KEY para empresa de informática" 
                                        style={{height:'30px'}}
                                        onClick={ e => muestraEmpresa(true)}>Generar KEY</button>
                                <button class="button button__arrow button__purple noUppercase" 
                                        title="Configuración de usuario adicional" 
                                        style={{height:'30px'}}
                                        onClick={ e => muestraUsuAdicional(true)}>Usuario adicional</button>
                            </div>            
                            :
                                <></>
                            }
                        
                        <div class="column" style={{marginTop: '30px'}}>                        
                            <div class="cover cover__filters cover__filters__warning" style={{marginTop:'10px', marginBottom:'10px', fontSize: '12px'}} >
                                <div class="module" >
                                    <div class="box" style={{fontSize: '16px', textAlign:'center'}}>
                                    Indique la provincia en la que quiere configurar BOT PLUS para conocer la información de ámbito autonómico. 
                                    Esta provincia puede modificarse posteriormente en cualquier momento.
                                    </div>
                                </div>
                            </div>
                            
                            <div class="cover cover__filters cover__filters__2--50" >
                            <SelectorComboBox 
                                    lista = {listaProvinciasCodValor} 
                                    idHTML="SelProvConf" 
                                    valueSelected={datosConf.IdProvincia} 
                                    alCambiar={CambiaCCAA} 
                                    ancho="100%"
                                ></SelectorComboBox>

                                <SelectorComboBox 
                                    lista = {listaCCAACodValor} 
                                    idHTML="SelCCAAConf" 
                                    valueSelected={datosConf.IdCCAA} 
                                    alCambiar={DoNothing} 
                                    ancho="100%"
                                    disabled={true}
                                ></SelectorComboBox>

                            </div>
                            
                            <div class="cover cover__filters cover__filters__code cover__filters__center" style={{marginTop:'-15px'}}>
                                <div class="coverModule">
                                    <div class="mask" >
                                        <img src="./img/logoFarmacia.png" />
                                    </div>
                                </div>
                            </div>
                            <div class="cover cover__filters cover__filters__warning" style={{marginTop:'-75px', marginBottom:'20px', fontSize: '12px'}} >
                                <div class="module" >
                                    <div class="box" style={{fontSize: '16px', textAlign:'center'}}>
                                        En caso de que el usuario se trate de una farmacia adherida a CisMED, puede incluir a continuación el número de farmacia para activar 
                                        la información de CisMED en BOT PLUS.<br />
                                        En caso contrario, podrá utilizar BOT PLUS con total normalidad, si bien no podrá acceder a la información de CisMED.
                                    </div>
                                </div>
                            </div>
                            <div class="cover cover__filters cover__filters__code cover__filters__center" >
                                <div class="coverModule">
                                    <div class="text hasTooltip popUp__Info">
                                        <h3 class="fs fs15 fw fw600 greyColor2 title">Información CisMED 
                                            <span onClick={e => AbreEmergenteCismed()}>
                                                <img src="./img/infoGreen.svg" alt="" />
                                            </span>
                                        </h3>
                                    </div>
                                </div>
                                <div class="coverModule">
                                    <div class="module module__filters">
                                        <div class="box box__inputs">
                                            <div class="cover">
                                                <input type="text" name="" value={datosConf.NumFarmacia} onChange={ e => CambiaNumFarmacia(e)} placeholder="Número de farmacia" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mySearch mySearch__columns" style={{marginTop:'-45px'}}>
                                <div class="module module__buttons module__buttons__buscar">
                                    <button class="button button__arrow button__purple noUppercase" title="Guardar" onClick={ e => GuardaDatosConfiguracion()}>Guardar</button>
                                </div>
                            </div>
                        
                            <div class="cover cover__filters cover__filters__warning" style={{marginTop:'20px'}}>
                                <div class="module" >
                                    {(datosConf.AccesoCisMED) ?
                                        ((datosConf.IdProvincia===datosConf.IdProvinciaCisMED) ?
                                            <div class="box" >
                                                <img src="./img/warningGreen.svg" alt="warningGreen" /> 
                                                Dispone de acceso a la información en BOT PLUS de CisMED.
                                            </div>
                                        :
                                            <div class="box" >
                                                <img src="./img/warningYellow.svg" alt="warningYellow" />
                                                Dispone de acceso a la información en BOT PLUS de CisMED, pero la provincia seleccionada no coincide con la registrada en su adhesión a CisMED.
                                            </div>
                                        )
                                    :
                                        <div class="box" >
                                            <img src="./img/warningRed.svg" alt="warningRed" /> 
                                            No dispone de acceso a la información en BOT PLUS de CisMED.
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>

                        <div>
                            <div style={{textAlign:'right'}}>
                            <div class="box" style={{fontSize: '10px', marginTop: '4px', fontFamily: 'FlamaBook, sans-serif', color: '#1D1D1B'}}>
                                    <span style={{textAlign:'right'}}>Versión del front end: {packageJson.version}.{packageJson.dateversion}.{packageJson.serial}</span>
                                </div>
                                <div class="box" style={{fontSize: '10px', marginTop: '4px', fontFamily: 'FlamaBook, sans-serif', color: '#1D1D1B'}}>
                                    <span style={{textAlign:'right'}}>Versión del back end: {datosConf.Version.Version}.{datosConf.Version.Dateversion}.{datosConf.Version.Serial}</span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
};

export default PaginaConfiguracion;