import { rootPath } from "../../../services/utils";

const LinksFichaPPF = (props) => {
    const ficha = props.ficha;

    const CambiaPaginaPorId = (pagina, id) => {
        props.cambiaPagina({
            newpagina: pagina,
            newid: id,
        });
    }

    const CambiaPaginaInteracciones = (pagina, id) => {
        var idInter = id + 2000000;
        props.cambiaPagina({
            newpagina: pagina,
            idsSel: [idInter,],
        });
    }

    const mouseOverLink = (e) => {
        e.target.style.fontWeight='bold';
        e.target.style.textDecoration='none';
    }
    const mouseOutLink = (e) => {
        e.target.style.fontWeight='normal';
    }




    const tieneCompos = ((ficha.DatosComposicion) && (ficha.DatosComposicion.Composiciones) && (ficha.DatosComposicion.Composiciones.length>0))
    //console.log(ficha);
    return (
        <div class="dispensacion-facturacion">
            <div class="extras">
                {(ficha.Interactua) ?
                    <div class="elemento" onClick={() => CambiaPaginaInteracciones('BInteraccionesMUH',ficha.Id)} style={{cursor:'pointer'}}>
                        <div class="mask" >
                            <img src={rootPath + "img/ficha/arrowsGreen.svg"} />
                        </div>
                        <span style={{textDecoration:'none'}}
                                onMouseOver={(e) => mouseOverLink(e)}
                                onMouseOut={(e) => mouseOutLink(e)}
                        >Interacciones</span>
                    </div>
                :
                    <div></div>
                }
                {(
                    ficha.Prospecto ?
                        <div class="elemento">
                            <div class="mask" >
                                <a href={ficha.Prospecto.URL} target="_blank" title={ficha.Prospecto.Titulo} >
                                    <img src={ rootPath + "img/ficha/fileGreen.svg"} />
                                </a>
                            </div>
                            <a href={ficha.Prospecto.URL} target="_blank" title={ficha.Prospecto.Titulo} >
                                <span style={{textDecoration:'none'}}
                                        onMouseOver={(e) => mouseOverLink(e)}
                                        onMouseOut={(e) => mouseOutLink(e)}
                                >Prospecto</span>
                            </a>
                        </div>
                    :
                        <span></span>
                )}
                {(
                    ficha.FichaTecnica ?
                        <div class="elemento">
                            <div class="mask" >
                                <a href={ficha.FichaTecnica.URL} target="_blank" title={ficha.FichaTecnica.Titulo} >
                                    <img src={rootPath + "img/ficha/fileGreen2.svg"} />
                                </a>
                            </div>
                            <a href={ficha.FichaTecnica.URL} target="_blank" title={ficha.FichaTecnica.Titulo} >
                                <span style={{textDecoration:'none'}}
                                        onMouseOver={(e) => mouseOverLink(e)}
                                        onMouseOut={(e) => mouseOutLink(e)}
                                >Ficha técnica</span>
                            </a>
                        </div>
                    :
                        <span></span>
                )}
                {(
                    ficha.IFU ?
                        <div class="elemento">
                            <div class="mask" >
                                <a href={ficha.IFU.URL} target="_blank" title={ficha.IFU.Titulo} >
                                    <img src={rootPath + "img/ficha/fileGreen2.svg"} />
                                </a>
                            </div>
                            <a href={ficha.IFU.URL} target="_blank" title={ficha.IFU.Titulo} >
                                <span style={{textDecoration:'none'}}
                                        onMouseOver={(e) => mouseOverLink(e)}
                                        onMouseOut={(e) => mouseOutLink(e)}
                                >Instrucciones de uso (IFU)</span>
                            </a>
                        </div>
                    :
                        <span></span>
                )}
                {(
                    ficha.FolletoInformativo ?
                        <div class="elemento">
                            <div class="mask" >
                                <a href={ficha.FolletoInformativo.URL} target="_blank" title={ficha.FolletoInformativo.Titulo} >
                                    <img src={rootPath + "img/ficha/fileGreen2.svg"} />
                                </a>
                            </div>
                            <a href={ficha.FolletoInformativo.URL} target="_blank" title={ficha.FolletoInformativo.Titulo} >
                                <span style={{textDecoration:'none'}}
                                        onMouseOver={(e) => mouseOverLink(e)}
                                        onMouseOut={(e) => mouseOutLink(e)}
                                >Ficha laboratorio</span>
                            </a>
                        </div>
                    :
                        <span></span>
                )}
            </div>
        </div>
    )
}
export default LinksFichaPPF;
