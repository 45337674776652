
import { useEffect, useState } from "react";
import { rootPath } from "../../../../services/utils";
import RamaArbol3 from "./RamaArbol3";

function SelectorArbol3(props){
    const [datosSeleccion, setDatosSeleccion] = useState({
        valuesSelected: props.valuesSelected,
        texto: props.texto
    });
    const [listaCompleta, setListaCompleta] = useState(props.listaCompleta);
    const [listaFiltrada, setListaFiltrada] = useState([]);
    const [numMostrar, setNumMostrar] = useState(20);
    var bMostrarMas = (listaFiltrada.length>=numMostrar);

    const codigoJerarquico = props.codigoJerarquico;

    useEffect(() => {
        /*if ((props.valorSeleccionado && props.valorSeleccionado !== datosSeleccion.valorSeleccionado) 
        || (props.texto && props.texto !== datosSeleccion.texto)
        || (props.listaCompleta && props.listaCompleta != listaCompleta)){*/
            const nuevaSeleccion = JSON.parse(JSON.stringify(datosSeleccion));
            nuevaSeleccion.valuesSelected = props.valuesSelected ? props.valuesSelected : [];
            nuevaSeleccion.texto = props.texto;
            var nuevalistafiltrada = filtraLista(props.listaCompleta,props.valuesSelected, props.texto);
            setListaCompleta(props.listaCompleta);
            setListaFiltrada(nuevalistafiltrada);
            setDatosSeleccion(nuevaSeleccion);
        /*}*/
    },  [props.valuesSelected, props.texto, props.listaCompleta,numMostrar]);


    const filtraLista = (lista, valores, texto) =>{
        /*if (valor && valor != ''){
            return filtraValorSeleccionado(lista, valor);
        }else{*/
            return filtraListaConTexto(lista, texto, valores);
        /*}*/
    }

    const filtraListaConTexto = (lista, texto, valores) =>{

        const nuevalista = [];
        const pendientes = [];
        pendientes.push(lista);
        var currentlista = pendientes.pop();
        var listaLlena = false;
        while (((currentlista && currentlista.length>0) || (pendientes && pendientes.length>0)) && !listaLlena){
            if (currentlista && currentlista.length>0){
                for (var i=0; i<currentlista.length && !listaLlena; i++){
                    if (itemContieneTxt(currentlista[i],texto) || (valores && (valores.includes(currentlista[i].Codigo)) || EsAlgunCodigoPadre(currentlista[i].Codigo,valores))){
                        var newitem = {
                            codigo: currentlista[i].Codigo,
                            valor: currentlista[i].Valor,
                            abierto: valores && (!valores.includes(currentlista[i].Codigo) &&  EsAlgunCodigoPadre(currentlista[i].Codigo,valores)) ,
                            seleccionado: (valores && valores.includes(currentlista[i].Codigo)),
                            disabled: false,
                            tieneHijos: (currentlista[i].hijos && currentlista[i].hijos.length>0),
                        }
                        if (newitem.abierto){
                            newitem.hijos = [];
                            if (currentlista[i].hijos && currentlista[i].hijos.length>0){
                                for (var j=0; j<currentlista[i].hijos.length; j++){
                                    var newitem2 = {
                                        codigo: currentlista[i].hijos[j].Codigo,
                                        valor: currentlista[i].hijos[j].Valor,
                                        abierto: valores && (!valores.includes(currentlista[i].hijos[j].Codigo) &&  EsAlgunCodigoPadre(currentlista[i].hijos[j].Codigo,valores)) ,
                                        seleccionado: newitem.seleccionado || (datosSeleccion.valuesSelected && datosSeleccion.valuesSelected.includes(currentlista[i].hijos[j].Codigo)),
                                        disabled: newitem.seleccionado,
                                        tieneHijos: (currentlista[i].hijos[j].hijos && currentlista[i].hijos[j].hijos.length>0),
                                    }
                                    if (newitem2.abierto){
                                        newitem2.hijos = [];
                                        if (currentlista[i].hijos[j].hijos && currentlista[i].hijos[j].hijos.length>0){
                                            for (var k=0; k<currentlista[i].hijos[j].hijos.length; k++){
                                                var newitem3 = {
                                                    codigo: currentlista[i].hijos[j].hijos[k].Codigo,
                                                    valor: currentlista[i].hijos[j].hijos[k].Valor,
                                                    abierto: valores && (!valores.includes(currentlista[i].hijos[j].hijos[k].Codigo) &&  EsAlgunCodigoPadre(currentlista[i].hijos[j].hijos[k].Codigo,valores)) ,
                                                    seleccionado: newitem2.seleccionado || (datosSeleccion.valuesSelected && datosSeleccion.valuesSelected.includes(currentlista[i].hijos[j].hijos[k].Codigo)),
                                                    disabled: newitem2.seleccionado,
                                                    tieneHijos: (currentlista[i].hijos[j].hijos[k].hijos && currentlista[i].hijos[j].hijos[k].hijos.length>0),
                                                }
                                                if (newitem3.abierto){
                                                    newitem3.hijos = [];
                                                    if (currentlista[i].hijos[j].hijos[k].hijos && currentlista[i].hijos[j].hijos[k].hijos.length>0){
                                                        for (var l=0; l<currentlista[i].hijos[j].hijos[k].hijos.length; l++){
                                                            var newitem4 = {
                                                                codigo: currentlista[i].hijos[j].hijos[k].hijos[l].Codigo,
                                                                valor: currentlista[i].hijos[j].hijos[k].hijos[l].Valor,
                                                                abierto: valores && (!valores.includes(currentlista[i].hijos[j].hijos[k].hijos[l].Codigo) &&  EsAlgunCodigoPadre(currentlista[i].hijos[j].hijos[k].hijos[l].Codigo,valores)) ,
                                                                seleccionado: newitem3.seleccionado || (datosSeleccion.valuesSelected && datosSeleccion.valuesSelected.includes(currentlista[i].hijos[j].hijos[k].hijos[l].Codigo)),
                                                                disabled: newitem3.seleccionado,
                                                                tieneHijos: (currentlista[i].hijos[j].hijos[k].hijos[l].hijos && currentlista[i].hijos[j].hijos[k].hijos[l].hijos.length>0),
                                                            }
                                                            if (newitem4.abierto){
                                                                newitem4.hijos = [];
                                                                if (currentlista[i].hijos[j].hijos[k].hijos[l].hijos && currentlista[i].hijos[j].hijos[k].hijos[l].hijos.length>0){
                                                                    for (var m=0; m<currentlista[i].hijos[j].hijos[k].hijos[l].hijos.length; m++){
                                                                        var newitem5 = {
                                                                            codigo: currentlista[i].hijos[j].hijos[k].hijos[l].hijos[m].Codigo,
                                                                            valor: currentlista[i].hijos[j].hijos[k].hijos[l].hijos[m].Valor,
                                                                            abierto: valores && (!valores.includes(currentlista[i].hijos[j].hijos[k].hijos[l].hijos[m].Codigo) &&  EsAlgunCodigoPadre(currentlista[i].hijos[j].hijos[k].hijos[l].hijos[m].Codigo,valores)) ,
                                                                            seleccionado: newitem4.seleccionado || (datosSeleccion.valuesSelected && datosSeleccion.valuesSelected.includes(currentlista[i].hijos[j].hijos[k].hijos[l].hijos[m].Codigo)),
                                                                            disabled: newitem4.seleccionado,
                                                                            tieneHijos: (currentlista[i].hijos[j].hijos[k].hijos[l].hijos[m].hijos && currentlista[i].hijos[j].hijos[k].hijos[l].hijos[m].hijos.length>0),
                                                                        }
                                                                        newitem4.hijos.push(newitem5);
                                                                    }
                                                                }
                                                            }
                                                            newitem3.hijos.push(newitem4);
                                                        }
                                                    }
                                                }
                                                newitem2.hijos.push(newitem3);
                                            }
                                        }
                                    }
                                    newitem.hijos.push(newitem2);
                                }
                            }
                        }
                        nuevalista.push(newitem);
                        listaLlena = nuevalista.length>=numMostrar;
                    }
                    else{
                        pendientes.push(currentlista[i].hijos);
                    }
                }
            }
            if (!listaLlena)
                currentlista = pendientes.pop();
        }
        bMostrarMas = listaLlena;
        return nuevalista;
    }

    const filtraValorSeleccionado = (lista, seleccion) =>{
        const nuevalista = [];
        const pendientes = [];
        pendientes.push(lista);
        var currentlista = pendientes.pop();
        var encontrado = false;
        while (((currentlista && currentlista.length>0) || (pendientes && pendientes.length>0)) && !encontrado){
            if (currentlista && currentlista.length>0){
                for (var i=0; i<currentlista.length && !encontrado; i++){
                    if (currentlista[i].Codigo === seleccion){
                        var newitem = {
                            codigo: currentlista[i].Codigo,
                            valor: currentlista[i].Valor,
                            abierto: false,
                            seleccionado: true,
                            tieneHijos: (currentlista[i].hijos && currentlista[i].hijos.length>0),
                        }
                        nuevalista.push(newitem);
                        encontrado=true;
                    }
                    else{
                        pendientes.push(currentlista[i].hijos);
                    }
                }
            }
            if (!encontrado)
                currentlista = pendientes.pop();
        }
        bMostrarMas = false;
        return nuevalista;
    }

    const abreRama = (codigoRama) =>{
        const nuevaListaFiltrada = JSON.parse(JSON.stringify(listaFiltrada));
        var pendientes = [];
        pendientes.push(nuevaListaFiltrada);
        var currentlista = pendientes.pop();
        var encontrado = false;
        var itemListaFiltrada = null;
        while (currentlista && currentlista.length>0 && !encontrado){
            for (var i=0; i<currentlista.length && !encontrado; i++){
                if (currentlista[i].codigo === codigoRama){
                    itemListaFiltrada = currentlista[i];
                    encontrado=true;
                }else{
                    if (currentlista[i].hijos && currentlista[i].hijos.length>0){
                        pendientes.push(currentlista[i].hijos);
                    }
                }
            }
            if (!encontrado)
                currentlista = pendientes.pop();
        }
        pendientes = [];
        pendientes.push(listaCompleta);
        var currentlista = pendientes.pop();
        var encontrado = false;
        var itemListaCompleta = null;
        while (currentlista && currentlista.length>0 && !encontrado){
            for (var i=0; i<currentlista.length && !encontrado; i++){
                if (currentlista[i].Codigo === codigoRama){
                    itemListaCompleta = currentlista[i];
                    encontrado=true;
                }else{
                    if (currentlista[i].hijos && currentlista[i].hijos.length>0){
                        if (codigoJerarquico){
                            if (EsCodigoPadre(currentlista[i].Codigo,codigoRama)){
                                pendientes.push(currentlista[i].hijos);        
                            }
                        }else{
                            pendientes.push(currentlista[i].hijos);
                        }                        
                    }
                }
            }
            if (!encontrado)
                currentlista = pendientes.pop();
        }
        if (itemListaFiltrada && itemListaCompleta){
            itemListaFiltrada.hijos = [];
            if (itemListaCompleta.hijos && itemListaCompleta.hijos.length>0){
                for (var i=0; i<itemListaCompleta.hijos.length; i++){
                    var newitem = {
                        codigo: itemListaCompleta.hijos[i].Codigo,
                        valor: itemListaCompleta.hijos[i].Valor,
                        abierto: false ,
                        seleccionado: itemListaFiltrada.seleccionado || (datosSeleccion.valuesSelected && datosSeleccion.valuesSelected.includes(itemListaCompleta.hijos[i].Codigo)),
                        disabled: itemListaFiltrada.seleccionado,
                        tieneHijos: (itemListaCompleta.hijos[i].hijos && itemListaCompleta.hijos[i].hijos.length>0),
                    }
                    itemListaFiltrada.hijos.push(newitem);
                }
                itemListaFiltrada.abierto = true;
            }
        }
        setListaFiltrada(nuevaListaFiltrada);
    }

    const cierraRama = (codigoRama) =>{
        const nuevaListaFiltrada = JSON.parse(JSON.stringify(listaFiltrada));
        const pendientes = [];
        pendientes.push(nuevaListaFiltrada);
        var currentlista = pendientes.pop();
        var encontrado = false;
        var itemListaFiltrada = null;
        while (currentlista && currentlista.length>0 && !encontrado){
            for (var i=0; i<currentlista.length && !encontrado; i++){
                if (currentlista[i].codigo === codigoRama){
                    itemListaFiltrada = currentlista[i];
                    encontrado=true;
                }else{
                    if (currentlista[i].hijos && currentlista[i].hijos.length>0){
                        pendientes.push(currentlista[i].hijos);
                    }
                }
            }
            if (!encontrado)
                currentlista = pendientes.pop();
        }
        if (itemListaFiltrada){
            itemListaFiltrada.hijos = [];
            itemListaFiltrada.abierto = false;
        }
        setListaFiltrada(nuevaListaFiltrada);
    }

    const itemContieneTxt = (item, txt) =>{
        txt = txt.replace('/',' ').replace(',', ' ').replace('\\',' ').replace('-',' ');
        var textoSeparado = txt.split(' ');
        var res = true;
        for (var i=0; i<textoSeparado.length; i++){
            res = res && itemContienePalabra(item, textoSeparado[i]);
        }
        return res;
    }

    const itemContienePalabra = (item, txt) =>{
        if (txt){
            txt = txt.trim().toLowerCase();
            var txt2 = ' ' + txt;
            return ((item.Codigo.toLowerCase().indexOf(txt)===0) || (item.Valor.toLowerCase().indexOf(txt)===0) || (item.Valor.toLowerCase().indexOf(txt2)>=0))
        }else return true;
    }

    const EsCodigoPadre = (padre, hijo) =>{
        if ((hijo) && (hijo !== "")){
            if (padre && padre !== ""){
                var res = (hijo.indexOf(padre)===0);
                if (!res){
                    res = (padre === "0" 
                        &&(hijo.indexOf("1")===0
                            || hijo.indexOf("2")===0
                            || hijo.indexOf("3")===0
                            || hijo.indexOf("4")===0
                        )
                    );
                }
                return res;
            }else return false;
        }else return false;
    }

    const EsAlgunCodigoPadre = (padre, hijos) =>{
        if (hijos && hijos.length>0){
            var res = false;
            for(var i=0; i<hijos.length && !res; i++){
                var hijo = hijos[i];
                res = res || EsCodigoPadre(padre,hijo);
            }
            return res;
        }else{
            return false;
        }

    }

    const cambiaTexto = (newtxt) =>{
        const nuevosDatosSeleccion = JSON.parse(JSON.stringify(datosSeleccion));
        nuevosDatosSeleccion.texto = newtxt;
        var nuevalistafiltrada = filtraListaConTexto(listaCompleta,newtxt);
        setListaFiltrada(nuevalistafiltrada);
        setDatosSeleccion(nuevosDatosSeleccion);    
        props.cambiaTxt(newtxt);    
    }

    const cambiaSeleccion = (checked, codigo, valor) =>{
        const nuevosDatosSeleccion = JSON.parse(JSON.stringify(datosSeleccion));
        if (checked)
            nuevosDatosSeleccion.valuesSelected.push(codigo);
        else{
            var idx = nuevosDatosSeleccion.valuesSelected.indexOf(codigo);
            if (idx>0)
                nuevosDatosSeleccion.valuesSelected.splice(idx,1);
        }
        setDatosSeleccion(nuevosDatosSeleccion);
        if (checked)
            props.alAnadir(codigo,valor);
        else
            props.alBorrar(codigo,valor);
    }

    const muestraMas = () =>{
        setNumMostrar(numMostrar + 20);
    }

    const arbolHTML = <RamaArbol3 arbol = {listaFiltrada} abreRama = {abreRama} cierraRama = {cierraRama} cambiaSeleccion = {cambiaSeleccion} muestraCodigo = {props.muestraCodigo}></RamaArbol3>

    return (
        <div> 
            <div class="custom-select-container search style-radios target" >
                <img class="arrow imagenTagEliminar" src="./img/close-tag.svg" id="imgBorrar" alt="eliminar" style={{display:'none'}} />
                <div class="cover cover__search">
                    <img class="lupa" src="./img/ficha/lupaPurple.svg" />
                    <input type="text" placeholder={props.placeholder} value = {datosSeleccion.texto} className="inputSelector" onChange={(e) => cambiaTexto(e.target.value)} style={{maxWidth:'600px'}}/>                        
                </div>
            </div>
            {arbolHTML}
            <div class="content__results__filters">
            <div class="module module__buttons module__buttons__buscar">
                {bMostrarMas ?(
                    <span class="button button__arrow button__purple__line noUppercase verMas" title="Ver más"  onClick={() => muestraMas()} style={{cursor:'pointer'}}>
                        Ver más <img src={rootPath + "img/arrowPurpleDown2.svg"} alt="arrowPurpleDown2" />
                    </span>
                ):(
                    <div></div>
                )}                            
            </div>
            </div>
        </div>
    )
}
export default SelectorArbol3;
