import MisBuscadoresCabeceraResultados from "../../Resultados/CabeceraResultados/MisBuscadoresCabeceraResultados";
import VolverCabeceraResultados from "../../Resultados/CabeceraResultados/VolverCabeceraResultados";
import TituloFormularioBusqueda from "./TituloFormularioBusqueda";

const CabeceraFormulario = (props) => {
    const infoPagina = props.infoPagina;
    var tipo = props.tipo;
    var titulo = props.titulo;
    return (
      <div class="contenedor__buscador f-f-Flama-Book">
        <div class="container flex flex--justify-center">
          <VolverCabeceraResultados atras={props.atras} textoVolver=""></VolverCabeceraResultados>
          <TituloFormularioBusqueda tipo={tipo} titulo={titulo} codPagina={infoPagina.codigoPagina}></TituloFormularioBusqueda>
          <MisBuscadoresCabeceraResultados  infoPagina={props.infoPagina} cambiaPagina={props.cambiaPagina}></MisBuscadoresCabeceraResultados>
        </div>
      </div>
    );
  };
  
  export default CabeceraFormulario;
  