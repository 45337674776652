import Slider from 'react-slick'
import React, { useState } from "react";
import { divideArrayEnPartes, numberWithCommas, rootPath } from "../../../services/utils";
import FilaPictogramas from "../FichaMUH/InfoPrincipal/FilaPictogramas";

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import LinksFichaMUH from './LinksFichaFM';
import LinksFichaFM from './LinksFichaFM';

const MargenDerechoFM = (props) => {
    const [sliderRef, setSliderRef] = useState(null)
    const ficha = props.ficha;
    const tienePFuturos = (ficha.PFuturos !== null) && (ficha.PFuturos.AgrupacionCCAA !== null) && (ficha.PFuturos.AgrupacionCCAA.PVPMenor>0);
    const imgDefecto = rootPath + "img/person.svg" ;
    const literalPrecio = (ficha.AgrupacionCCAA ? (!ficha.AgrupacionCCAA.LiteralPMenor || ficha.AgrupacionCCAA.LiteralPMenor === '') ? (ficha.IdCCAA===5 || ficha.IdCCAA===18 || ficha.IdCCAA===19 ? 'PVP': 'PVP con IVA') : ficha.AgrupacionCCAA.LiteralPMenor : '');

    const imagenesHTML = <div style={{textAlign : 'center'}}><p>&nbsp;</p><img class="imagen__medicamento" src={imgDefecto} style={{height:'180px'}} /></div>

    const pictogramasEnFilas = divideArrayEnPartes(ficha.Pictogramas,5);    
    const pictogramas = pictogramasEnFilas.map( fila => <FilaPictogramas fila={fila}></FilaPictogramas>);
    return (
        <div class="medicamento">
            <div class="nombre f-f-Flama-Medium">{ficha.NombreCompleto}</div>
                <div class="contenido__imagen">
                    {imagenesHTML}
                    {/*pictogramas*/}
                </div>
            <div class="detalles">
                {(!ficha.LiteralPrecioFM || ficha.LiteralPrecioFM === "") ?
                    <div class="precios__iva f-f-Flama-Basic">
                        <p class="nombre">{literalPrecio}</p>
                        {(ficha.AgrupacionCCAA) && (ficha.AgrupacionCCAA.PVPMenor>0) ?
                        <p class="precio" >{numberWithCommas(ficha.AgrupacionCCAA.PVPMenor)}</p>
                        :
                        <p class="precio" >-</p>
                        }
                        {(
                            tienePFuturos ? 
                                <p class="sin_iva">{numberWithCommas(ficha.PFuturos.AgrupacionCCAA.PVPMenor)}</p>
                            :
                                <span></span>
                        )}
                        <p class="moneda">EUR</p>
                    </div>
                :
                    <div class="precios__iva f-f-Flama-Basic">
                        <p class="nombre">{ficha.LiteralPrecioFM}</p>
                        {(ficha.PVPIVA>0) ?
                        <p class="precio" >{numberWithCommas(ficha.PVPIVA)}</p>
                        :
                        <p class="precio" >-</p>
                        }
                        {(
                            tienePFuturos ? 
                                <p class="sin_iva">{numberWithCommas(ficha.PFuturos.PVPIVA)}</p>
                            :
                                <span></span>
                        )}
                        <p class="moneda">EUR</p>
                    </div>
                }
                <div class="condiciones">
                    {/*<div class="fila">
                        <div class="col-i">Interoperable</div>
                        <div class="col-d">Sí</div>
                    </div>*/}
                     {(ficha.Interoperable) ?
                        <div class="fila">
                            <div class="col-i">Interoperable</div>
                            <div class="col-d">{ficha.Interoperable}</div>
                        </div>
                    :
                        <></>
                     }
                    <div class="fila">
                        <div class="col-i">Receta</div>
                        <div class="col-d">{ficha.Receta}</div>
                    </div>
                    <div class="fila">
                        <div class="col-i">Financiado</div>
                        <div class="col-d" title={ficha.Financiado}>{ficha.ShortFinanciado}</div>
                    </div>
                </div>
            </div>
            <LinksFichaFM ficha={ficha} cambiaPagina={props.cambiaPagina} ></LinksFichaFM>
        </div>
    )
}
export default MargenDerechoFM;