import { useEffect, useState } from "react";
import BP2022Service from "../../services/user.service";
import AuthService from "../../services/auth.service";
import { getCurrentURL, getParam, getParamsURL, rootPath } from "../../services/utils";
import SelectorComboBox from "../Utilidades/Selector/SelectorComboBox";
import Emergente from "../Fichas/FichaMUH/Apartados/Cismed/Emergente";
import AddEmpresa from "./AddEmpresa";
import {createBrowserHistory} from 'history';

const RecuperaCorreo2 = (props) => {

    const [mensaje, setMensaje] = useState('Leyendo datos...');
    const [alto, setAlto] = useState('230px');
    const [estado, setEstado] = useState(0);
    const [user, setUser] = useState('');
    const [newCorreo, setNewCorreo] = useState('');
    const [confirmaCorreo, setConfirmaCorreo] = useState('');
    const [link, setLink] = useState(props.link)
    const [opt1, setOpt1] = useState(false);
    const [opt2, setOpt2] = useState(false);

    const estiloCheckBox = {
        height: '16px',
    }

    const siguiente = () =>{
        if (estado === 2)
            cambiaCorreo();
        else if (estado === 4)
            props.alCerrar();
        else if (estado === 5){
            props.alCerrar();
            /*const history = createBrowserHistory();
            history.push(rootPath);*/
        }
    }

    const compruebaLink = () =>{
        if (link != ""){
            BP2022Service.checkLinkCC(link)
            .then((response) => {
                if (response.data && response.data.Ok){
                    var localUser = AuthService.getLocalUser()
                    if (props.esCambioMail && localUser && localUser.botPlusUser && localUser.botPlusUser.Username && localUser.botPlusUser.Username.toLowerCase() !== response.data.DirCorreo.toLowerCase()){
                        setEstado(1);
                        setMensaje('El usuario con el que ha iniciado sesión no se corresponde con el usuario que solicitó el cambio de correo. Inicie sesión con el usuario adecuado.')
                    }else{
                        setUser(response.data.DirCorreo);
                        setEstado(2);
                        setMensaje('');
                    }
                }
                else{
                    setEstado(1);
                    setMensaje(response.data.Mensaje)
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
                setEstado(1);
                setMensaje(error.message);
            });
        }
        else{
            setMensaje('La URL no está completa');
        }
    }

    const cambiaCorreo = () =>{
        if (link != ""){
            if (newCorreo != ""){
                if (validateEmail(newCorreo)){
                    if (newCorreo === confirmaCorreo){
                        if (opt1){
                            setEstado(3);
                            setMensaje('Enviando datos...')
                            BP2022Service.setCorreoLink(link, newCorreo, opt2)
                            .then((response) => {
                                if (response.data){
                                    if (response.data.Exito){
                                        setEstado(5);
                                        setMensaje('Dirección de correo cambiada correctamente');
                                    }else{
                                        setEstado(2);
                                        setMensaje(response.data.Mensaje);
                                    }
                                }else{
                                    setEstado(2);
                                    setMensaje('No se ha podido cambiar la dirección de correo.')
                                }
                            })
                            .catch(function (error) {
                                console.log('Error', error.message);
                                setMensaje(error.message);
                            });
                        }
                        else{
                            setMensaje('Debe aceptar el uso de su correo electónico para la gestión de Bot Plus')
                        }
                    }
                    else{
                        setMensaje('La nueva dirección de correo y su confirmación no coinciden')
                    }
                }
                else{
                    setMensaje('La nueva dirección de correo no es correcta')
                }
            }
            else{
                setMensaje('La dirección de correo no puede ser vacia')
            }
        }
        else{
            setMensaje('La URL no está completa');
        }
    }

    useEffect (() =>{
        if (estado===0)
            compruebaLink();
    },[]);


    const CambiaNewCorreo = (s) => {
        setMensaje("");
        setNewCorreo(s);
    };

    const CambiaConfirmaCorreo = (s) => {
        setMensaje("");
        setConfirmaCorreo(s);
    };

    const cambiaOpt1 = (checked) =>{
        setOpt1(checked);
    }

    const cambiaOpt2 = (checked) =>{
        setOpt2(checked);
    }


    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };



    var newalto;
    var txtBoton = "Siguiente";
    var titulo = "Cambio de dirección de correo";

    switch (estado){
        case 0:
            newalto = '230px';
            break;
        case 1:
            newalto = '230px';
            break;
        case 2:
            newalto = '720px';
            break;
        case 3:
            newalto = '230px';
            break;
        case 4:
            newalto = '230px';
            txtBoton= "Cerrar";
            break;
        case 5:
            newalto = '230px';
            txtBoton= "Cerrar";
            break;
    }
    if (alto != newalto)
        setAlto(newalto);
            
    const contenido = 
        <form class="content__results content__results__filters" id="main-filter" onSubmit={(e) => e.preventDefault()} autocomplete="off" >
            {(estado === 0)||(estado === 3)||(estado === 4)||(estado === 5) ?            
                <div className="text" style={{width:'100%', paddingBottom:'6px', fontSize:'16px', lineHeight:'20px', verticalAlign:'baseline'}}  >
                    {(mensaje) && (mensaje !== "") ? 
                        <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '6px'}}>{mensaje}</p>
                    :
                        <></>                
                    }
                </div>
            :(estado == 1) ?
                <div className="text" style={{width:'100%', paddingBottom:'6px', fontSize:'16px', lineHeight:'20px', verticalAlign:'baseline'}}  >
                    {(mensaje) && (mensaje !== "") ? 
                        <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '6px', color:'#F05341'}}>{mensaje}</p>
                    :
                        <></>                
                    }
                </div>
            :(estado == 2) ?
                <div className="text" style={{width:'100%', paddingBottom:'6px', fontSize:'16px', lineHeight:'20px', verticalAlign:'baseline'}}  >
                    {(mensaje) && (mensaje !== "") ? 
                        <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '6px', color:'#F05341'}}>{mensaje}</p>
                    :
                        <></>                
                    }
                    <p className="fs fs18 fw fw100 FlamaBook greyColor"
                        style={{marginBottom:'30px'}}>
                            Usuario:
                            <div class="coverModule">
                                <div class="module module__filters">
                                    <div class="box box__inputs">
                                        <div class="cover cover__input">
                                            <input type="text" name="CPUser" value={user} disabled = {true} style={{color:"#C0C0C0"}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </p>
                    <p className="fs fs18 fw fw100 FlamaBook greyColor"
                        style={{marginBottom:'30px'}}>
                            Correo eletrónico:
                            <div class="coverModule">
                                <div class="module module__filters">
                                    <div class="box box__inputs">
                                        <div class="cover cover__input">
                                        <input type="text" name="CPOldPass" value={newCorreo}  
                                                onChange={ e => CambiaNewCorreo(e.target.value)} 
                                                placeholder="Introduzca la nueva dirección de correo electrónico" autocomplete="new-password"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </p>
                    <p className="fs fs18 fw fw100 FlamaBook greyColor"
                        style={{marginBottom:'30px'}}>
                            Confirmación correo eletrónico:
                            <div class="coverModule">
                                <div class="module module__filters">
                                    <div class="box box__inputs">
                                        <div class="cover cover__input">
                                        <input type="text" name="CPOldPass" value={confirmaCorreo} 
                                                onChange={ e => CambiaConfirmaCorreo(e.target.value)} 
                                                placeholder="Confirme la nueva dirección de correo electrónico" autocomplete="new-password"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </p>
                        <p className="fs fs16 fw fw100 FlamaBook greyColor" 
                                style={{width:'100%', textAlign:'left',  paddingRight: '10px', fontWeight:600, textAlign: 'justify',
                                    borderBottomStyle: 'solid', borderBottomWidth: '1px', borderBottomColor: '#e8e8e8'
                                    , paddingBottom: '10px'}}>
                            <label>
                                <input type="checkbox" checked={opt1} style={estiloCheckBox} onChange={(e) => cambiaOpt1(e.target.checked)} /> 
                                <span style={{paddingLeft:'10px', fontSize:'14px'}}> 
                                    Mediante el marcado de la casilla, el usuario acepta el uso 
                                    de su correo electrónico para la gestión de BOT PLUS y para 
                                    todas las comunicaciones accesorias derivadas del uso de la 
                                    aplicación.
                                </span>
                            </label>                        
                        </p>
                        <p className="fs fs15 fw fw100 FlamaBook greyColor" 
                                style={{width:'100%', textAlign:'left',  paddingRight: '10px', fontWeight:'normal', textAlign: 'justify'
                                        ,paddingTop: '10px', fontSize:'14px'}}>
                                    Asimismo, solicitamos su consentimiento expreso para el envío de comunicaciones 
                                    electrónicas relativas a los servicios y productos que ofrecemos e información 
                                    que pueda resultar de su interés rellenando previamente su correo electrónico y 
                                    marcando la siguiente casilla                                        
                        </p>
                        <p className="fs fs16 fw fw100 FlamaBook greyColor" 
                                style={{width:'100%', textAlign:'left',  paddingRight: '10px', fontWeight:'normal', textAlign: 'justify'
                                        ,paddingTop: '10px'}}>
                            <label>
                                <input type="checkbox" checked={opt2} style={estiloCheckBox} onChange={(e) => cambiaOpt2(e.target.checked)} /> 
                                <span style={{paddingLeft:'10px', fontSize:'14px'}}> 
                                    Deseo recibir comunicaciones comerciales del CGCOF en los términos descritos en la&nbsp; 
                                    <a href='https://www.farmaceuticos.com/politica-de-privacidad' target='_blank' style={{color:'#93c462'}}>
                                        Política de Privacidad
                                    </a>.
                                </span>
                            </label>                        
                        </p>
                    </div>
                :
                    <></>
            }
            {((estado === 2) || (estado === 4) || (estado === 5))?
                <div className="cover cover__buttons" style={{textAlign: 'right', paddingTop: '10px'}} >
                        <button className="button button__purple" onClick={(e) => siguiente()} style={{height:'40px', paddingTop:'12px'}}> {txtBoton}</button>
                </div>
            :
                <></>
            }
        </form>


    return(
        <Emergente 
            contenido={contenido} 
            keyName={"cambioCorreo"} 
            titulo={titulo} 
            activo={true} 
            ocultaAspa = {false}
            alto = {alto}
            ancho = {'680px'}
            cerrar={props.alCerrar}
        ></Emergente>
    );
}

export default RecuperaCorreo2;
