import { rootPath } from "../../services/utils";
import BP2022Service from "../../services/user.service";
import packageJson from '../../../package.json';
import { useEffect, useState } from "react";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/botplustt.css';

const LogoCabecera = (props) => {

    const frontVersion = {Version : packageJson.version, Dateversion : packageJson.dateversion, Serial : packageJson.serial};
    const [backVersion, setBackVersion] = useState(null);

    useEffect(() => {
        if (!backVersion)
        {
            ConsultaBackVersion();
        }
    },[]);

    const ConsultaBackVersion = () => {
        BP2022Service.getBackVersion()
        .then((response) => {
            setBackVersion(response.data)
        })
        .catch(function (error) {
            console.log('Error', error.message);
        })
    };


    const CambiaPaginaSimple = (codigoPagina) => {
        props.cambiaPagina({
            newpagina: codigoPagina
        });
    }


    return (
        <div class="module module__logo" >
            <div class="mask" >
            <Tippy trigger='mouseenter' arrow={true} theme = 'botplustt' delay={[2000,0]} 
                            content={   
                                <>
                                    <div>
                                        Versión del front end: {frontVersion.Version}.{frontVersion.Dateversion}.{frontVersion.Serial}
                                    </div>
                                    {
                                    (backVersion)?
                                        <div>
                                            Versión del back end: {backVersion.Version}.{backVersion.Dateversion}.{backVersion.Serial}
                                        </div>

                                    :<></>
                                    }
                                </>
                            }
                        >
                    <img src={ rootPath + "img/logoHeader2.svg"} alt="logoHeader2" onClick={() => CambiaPaginaSimple("Inicio")} style={{cursor:'pointer'}}/>
                </Tippy>
            </div>
        </div>
    )
}

export default LogoCabecera;
