import { useEffect, useState } from "react";
import BP2022Service from "../../services/user.service";
import AuthService from "../../services/auth.service";
import { getCurrentURL, getParam, getParamsURL, rootPath } from "../../services/utils";
import SelectorComboBox from "../Utilidades/Selector/SelectorComboBox";
import Emergente from "../Fichas/FichaMUH/Apartados/Cismed/Emergente";
import AddEmpresa from "./AddEmpresa";
import {createBrowserHistory} from 'history';

const ConfirmaCorreo2 = (props) => {

    const [mensaje, setMensaje] = useState('');
    const [alto, setAlto] = useState('230px');
    const [estado, setEstado] = useState(-1);
    const [user, setUser] = useState('');
    const [link, setLink] = useState(props.link)

    const estiloCheckBox = {
        height: '16px',
    }

    const siguiente = () =>{
       if (estado === 1)
            props.alCerrar();
        else if (estado === 2){
            props.alCerrar();
            /*const history = createBrowserHistory();
            history.push(rootPath);*/
        }
    }

    const compruebaLink = () =>{
        if (link != ""){
            setEstado(0);
            setMensaje('Leyendo datos...');
            BP2022Service.checkLinkCheckC(link)
            .then((response) => {
                if (response.data && response.data.Ok){
                    var localUser = AuthService.getLocalUser()
                    if (props.esConfirmaMail && localUser && localUser.botPlusUser && localUser.botPlusUser.Username && localUser.botPlusUser.Username.toLowerCase() !== response.data.DirCorreo.toLowerCase()){
                        setEstado(1);
                        setMensaje('El usuario con el que ha iniciado sesión no se corresponde con el usuario que solicitó el cambio de correo. Inicie sesión con el usuario adecuado.')
                    }else{
                        setUser(response.data.DirCorreo);
                        setEstado(2);
                        setMensaje('La dirección de correo se ha validado correctamente');
                    }
                }
                else{
                    setEstado(1);
                    setMensaje(response.data.Mensaje)
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
                setEstado(1);
                setMensaje(error.message);
            });
        }
        else{
            setMensaje('La URL no está completa');
        }
    }


    useEffect (() =>{
        if (estado===-1)
            compruebaLink();
    },[]);


    var newalto;
    var txtBoton = "Cerrar";
    var titulo = "Confirmación correo electrónico";

    switch (estado){
        case 0:
            newalto = '230px';
            break;
        case 1:
            newalto = '230px';
            break;
        case 2:
            newalto = '230px';
            break;
    }
    if (alto != newalto)
        setAlto(newalto);
            
    const contenido = 
        <form class="content__results content__results__filters" id="main-filter" onSubmit={(e) => e.preventDefault()} autocomplete="off" >
            {(estado === 0)||(estado === 1)||(estado === 2) ?            
                <div className="text" style={{width:'100%', paddingBottom:'6px', fontSize:'16px', lineHeight:'20px', verticalAlign:'baseline'}}  >
                    {(mensaje) && (mensaje !== "") ? 
                        <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '6px'}}>{mensaje}</p>
                    :
                        <></>                
                    }
                </div>
            :
                <></>
            }
            {((estado === 1) || (estado === 2))?
                <div className="cover cover__buttons" style={{textAlign: 'right', paddingTop: '10px'}} >
                        <button className="button button__purple" onClick={(e) => siguiente()} style={{height:'40px', paddingTop:'12px'}}> {txtBoton}</button>
                </div>
            :
                <></>
            }
        </form>


    return(
        <Emergente 
            contenido={contenido} 
            keyName={"confirmaCorreo"} 
            titulo={titulo} 
            activo={true} 
            ocultaAspa = {false}
            alto = {alto}
            ancho = {'680px'}
            cerrar={props.alCerrar}
        ></Emergente>
    );
}

export default ConfirmaCorreo2;
