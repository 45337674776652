import React, { useEffect, useState } from "react";
import NovedadesInicioCabecera from "./NovedadesInicioCabecera";
import NovedadesInicioCuerpo from "./NovedadesInicioCuerpo";
import NovedadesInicioPie from "./NovedadesInicioPie";
import BP2022Service, {getNovedades} from "../../../../services/user.service";

const NovedadesInicio = (props) => {
    const [listaNovedades, setListaNovedades] = useState(null);

    useEffect(() => {
      descargaNovedades();
    }, [])
  
    const descargaNovedades = () => {
      BP2022Service.getNovedades()
      .then((response) => {
        setListaNovedades(response.data.Resultados);
      })
      .catch(function (error) {
        console.log('Error', error.message);
      });
    };
  
    const primerasNovedades = listaNovedades ? (
        listaNovedades.slice(0,2)
    ):(
        null
    );
  
    const restoNovedades = listaNovedades ? (
        listaNovedades.slice(2)
    ):(
        null
    );

    var estiloDivContent = (props.ancho && props.ancho !== '') ? {width : props.ancho} : {}
    
    return (
        <div class="content" style={estiloDivContent}>
            <NovedadesInicioCabecera cambiaPagina={props.cambiaPagina} lista={primerasNovedades}></NovedadesInicioCabecera>
            <NovedadesInicioCuerpo cambiaPagina={props.cambiaPagina} lista={restoNovedades}></NovedadesInicioCuerpo>
            <NovedadesInicioPie cambiaPagina={props.cambiaPagina}></NovedadesInicioPie>
        </div>
    );
};

export default NovedadesInicio;
  