import React, { useState, useEffect } from "react";
import Inicio from "../Inicio/Inicio";
import LoadingPopup from "../Resultados/Loading.Popup";
import FichaASO from "./FichaASO/FichaASO";
import FichaEXT from "./FichaEXT/FichaExt";
import FichaFM from "./FichaFM/FichaFM";
import FichaING from "./FichaING/FichaING";
import FichaMUH from './FichaMUH/FichaMUH';
import FichaMUV from "./FichaMUV/FichaMUV";
import FichaPAH from "./FichaPA/FichaPAH";
import FichaPAV from "./FichaPA/FichaPAV";
import FichaPAT from "./FichaPAT/FichaPAT";
import FichaPPF from "./FichaPPF/FichaPPF";

const Ficha = (props) => {
    const infoPagina = props.infoPagina;
    var ficha;
    var visitados = JSON.parse(localStorage.getItem("visitados"));
    if (!visitados)
        visitados = [];
    
    switch(infoPagina.codigoPagina){
        case "FichaMUH":
        case "FichaMHO":            
            ficha = (
                <FichaMUH infoPagina={infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras} descargaHistorial={props.descargaHistorial} ></FichaMUH>
            );
            break;
        case "FichaMUV":
            ficha = (
                <FichaMUV infoPagina={infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras} descargaHistorial={props.descargaHistorial}></FichaMUV>
            );
            break;
        case "FichaFM":
            ficha = (
                <FichaFM infoPagina={infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras} descargaHistorial={props.descargaHistorial}></FichaFM>
            );
            break;
        case "FichaPPF":
            ficha = (
                <FichaPPF infoPagina={infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras} descargaHistorial={props.descargaHistorial} ></FichaPPF>
            );
            break;
        case "FichaPAH":
        case "FichaPHO":            
            ficha = (
                <FichaPAH infoPagina={infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras} descargaHistorial={props.descargaHistorial}></FichaPAH>
            );
            break;
        case "FichaPAV":
            ficha = (
                <FichaPAV infoPagina={infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras} descargaHistorial={props.descargaHistorial}></FichaPAV>
            );
            break;
        case "FichaASO":
        case "FichaASOH":
        case "FichaASOV":
            ficha = (
                <FichaASO infoPagina={infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras} descargaHistorial={props.descargaHistorial} tipo="H"></FichaASO>
            );
            break;
        case "FichaING":
            ficha = (
                <FichaING infoPagina={infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras} descargaHistorial={props.descargaHistorial}></FichaING>
            );
            break;
        case "FichaPAT":
        case "FichaPATH":
        case "FichaPATV":
            ficha = (
                <FichaPAT infoPagina={infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras} descargaHistorial={props.descargaHistorial}></FichaPAT>
            );
            break;
        case "FichaEXT":
            ficha = (
                <FichaEXT infoPagina={infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras} descargaHistorial={props.descargaHistorial}></FichaEXT>
            );
            break;
    }
    var tipo = infoPagina.codigoPagina.substring(5);
    var id = infoPagina.id;
    if (visitados.findIndex(item=> item.tipo===tipo && item.id===id)<0){
        visitados.push({tipo: tipo, id: id})
        localStorage.setItem("visitados", JSON.stringify(visitados));
    }

    return (
        <div>
            {ficha}
        </div>
    );

};

export default Ficha;
