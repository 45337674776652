import BloqueTablaIdent from "./BloqueTablaIdent";
import FilaIdent from "./FilaIdent";

const Identificacion = (props) => {
    const datosIdentificacion = props.datosIdentificacion;
    const colsperrow = 3;
    const tablas = [];
    var iaux=datosIdentificacion.Titulos.indexOf('Forma');
    /*for(let i=0; i < datosIdentificacion.Titulos.length; i+= colsperrow){
        const chunk = datosIdentificacion.Titulos.slice(i, i + colsperrow);
        const valores = [];
        for (let j=0; j<datosIdentificacion.Identificaciones.length; j++){
            const chunkV = datosIdentificacion.Identificaciones[j].Valores.slice(i, i + colsperrow);
            const aux = {
                valores : chunkV,
                URLForma : datosIdentificacion.Identificaciones[j].URLForma,
                idxForma : iaux
            }
            valores.push(aux);
        }
        const cjtofilas = {
            titulos : chunk,
            valores : valores,
        }
        tabla.push(cjtofilas);
        iaux -= colsperrow;
    }*/

    for (let j=0; j<datosIdentificacion.Identificaciones.length; j++){
        const tabla = [];
        for(let i=0; i < datosIdentificacion.Titulos.length; i+= colsperrow){
            const chunk = datosIdentificacion.Titulos.slice(i, i + colsperrow);
            const chunkV = datosIdentificacion.Identificaciones[j].Valores.slice(i, i + colsperrow);
            const fila = {
                titulos : chunk,
                valores : chunkV,
                URLForma : datosIdentificacion.Identificaciones[j].URLForma,
            }
            tabla.push(fila);
        }
        tablas.push(tabla);
    }

    
    //const htmlTabla = tabla.map(bloque => <BloqueTablaIdent bloque={bloque} idxBloque={tabla.indexOf(bloque)}></BloqueTablaIdent>);
    //const htmlTabla = tabla.map(fila => <FilaIdent fila={fila.valores} titulos = {fila.titulos} idxBloque={tabla.indexOf(fila)}></FilaIdent>);

    const htmlTablas = tablas.map(tabla => 
        <div class="table" style={{width:'100%', marginBottom:'20px'}}>
            {tabla.map(fila => <FilaIdent fila={fila.valores} titulos = {fila.titulos} idxBloque={tabla.indexOf(fila)} idxForma = {iaux} URLForma = {fila.URLForma} colsperrow = {colsperrow} ></FilaIdent>)}
        </div>
    )
    

    return (
        <div class="infoContent" >
            {htmlTablas}
        </div>
    )
}
export default Identificacion;

