import { rootPath } from "../../../services/utils";

const LinksFichaPAT = (props) => {
    const ficha = props.ficha;

    const CambiaPaginaPorId = (pagina, id) => {
        props.cambiaPagina({
            newpagina: pagina,
            newid: id,
        });
    }

    const linkIndic = (ficha.TipoPat === "V")? "BIndicacionMUV" : "BIndicacionMUH";
    const linkIndicPF = "BIndicacionPPF";

    const mouseOverLink = (e) => {
        e.target.style.fontWeight='bold';
        e.target.style.textDecoration='none';
    }
    const mouseOutLink = (e) => {
        e.target.style.fontWeight='normal';
    }

    return (
        <div class="dispensacion-facturacion">
            {ficha.TipoPat==="H" ?
                <>
                    <div class="extras" style={{paddingBottom : '8px'}}>
                        <div class="elemento" onClick={() => CambiaPaginaPorId(linkIndic,ficha.Id)} style={{cursor:'pointer'}}>
                            <div class="mask" >
                                <img src={rootPath + "img/ficha/lupaVerde.svg"} />
                            </div>
                            <span style={{textDecoration:'none'}}
                                        onMouseOver={(e) => mouseOverLink(e)}
                                        onMouseOut={(e) => mouseOutLink(e)}
                            >Medicamentos indicados</span>                    
                        </div>
                        <div class="elemento" onClick={() => CambiaPaginaPorId('BContraindicacionMUH',ficha.Id)} style={{cursor:'pointer'}}>
                            <div class="mask" >
                                <img src={rootPath + "img/ficha/lupaVerde.svg"} />
                            </div>
                            <span style={{textDecoration:'none'}}
                                    onMouseOver={(e) => mouseOverLink(e)}
                                    onMouseOut={(e) => mouseOutLink(e)}
                            >Contraindicados</span>                    
                        </div>
                        <div class="elemento" onClick={() => CambiaPaginaPorId('BPrecaucionMUH',ficha.Id)} style={{cursor:'pointer'}}>
                            <div class="mask" >
                                <img src={rootPath + "img/ficha/lupaVerde.svg"} />
                            </div>
                            <span style={{textDecoration:'none'}}
                                    onMouseOver={(e) => mouseOverLink(e)}
                                    onMouseOut={(e) => mouseOutLink(e)}
                            >Con uso precautorio</span>                    
                        </div>
                        <div class="elemento" onClick={() => CambiaPaginaPorId('BReaccionAdversaMUH',ficha.Id)} style={{cursor:'pointer'}}>
                            <div class="mask" >
                                <img src={rootPath + "img/ficha/lupaVerde.svg"} />
                            </div>
                            <span style={{textDecoration:'none'}}
                                    onMouseOver={(e) => mouseOverLink(e)}
                                    onMouseOut={(e) => mouseOutLink(e)}
                            >Que la incluyen como RAM</span>                    
                        </div>
                    </div>
                    {ficha.VisibleEnPF && ficha.TieneProductos ?
                        <div class="extras" style={{borderTop:'1px solid #e8e8e8'}}>
                            <div class="elemento" onClick={() => CambiaPaginaPorId(linkIndicPF,ficha.Id)} style={{cursor:'pointer'}}>
                                <div class="mask" >
                                    <img src={rootPath + "img/ficha/lupaVerde.svg"} />
                                </div>
                                <span style={{textDecoration:'none'}}
                                            onMouseOver={(e) => mouseOverLink(e)}
                                            onMouseOut={(e) => mouseOutLink(e)}
                                >Productos</span>                    
                            </div>
                        </div>
                    :
                        <></>
                    }
                </>
            :
                <div class="extras">
                    <div class="elemento" onClick={() => CambiaPaginaPorId(linkIndic,ficha.Id)} style={{cursor:'pointer'}}>
                        <div class="mask" >
                            <img src={rootPath + "img/ficha/lupaVerde.svg"} />
                        </div>
                        <span style={{textDecoration:'none'}}
                                    onMouseOver={(e) => mouseOverLink(e)}
                                    onMouseOut={(e) => mouseOutLink(e)}
                        >Medicamentos indicados</span>                    
                    </div>
                </div>
            }
        </div>
    )
}
export default LinksFichaPAT;
