import { useEffect, useState } from "react";
import { AddClassNameToChildClassItems, buscaOptionEnSelectByValue, createElementFromHTML
    , deepEqual, elementIsOfClass, ExistsClassNameChildClassItems, GetAntecesorWhithClassName
    , GetLastSucesorWhithClassName, getSelectValues, GetSucesorWhithClassName
    , primerHijoConClase, RemoveClassNameToChildClassItems, scrollSiNoVisible } from "../../../../services/utils";
import BP2022Service from "../../../../services/user.service";    
import ItemMenuIconos from "../../../Cabecera/MenuIconos/ItemMenuIconos";

function SelectorComposicion(props){
    const [propiedades, setPropiedades] = useState({
        lista: props.lista,
        valuesSelected: props.valuesSelected,
    })
    const lista = propiedades.lista;
    const [listavisible, setListaVisible] = useState([]) ;
    const valuesSelected = (propiedades.valuesSelected) ? propiedades.valuesSelected : [] ;
    const [infoComponentes, setInfoComponentes] = useState([]) ;
    const idHTML = props.idHTML; 
    const optionsHTML = listavisible.map(item => (
        valuesSelected.includes(item.codiog) ? 
        <option selected value={item.codigo}>{item.valor}</option>
        :<option value={item.codigo}>{item.valor}</option> )
    );
    const [currentComponente, setCurrentComponente] = useState(0);
    const [negado, setNegado] = useState(false);
    const estiloModuleSelect = (props.ancho ? {width: props.ancho} : {});

    const optionsDisplayed = listavisible.map(item => (
        valuesSelected.includes(item.codiog) ?
            <label class="labeloption active " data-value={item.codigo} onClick={(e) => ClickEnOpcion(e)}>{item.valor}</label>
            :<label class="labeloption " data-value={item.codigo} onClick={(e) => ClickEnOpcion(e)}>{item.valor}</label>
        ));

    const tipoFiltradoLista = (props.tipoFiltradoLista>=0) ? props.tipoFiltradoLista : 1;
    const clasesCoverSelect = (props.disabled ? "cover cover__select cover__select__border disabled" : "cover cover__select cover__select cover__select__border");

    const textPlaceholder = (lista && lista.length>0) ? lista[0].valor : '';

    var itemsSelected = [];

    for (var vs of valuesSelected){
        var item = lista.find(vsitem=> vsitem.codigo === Math.abs(vs.codigo));
        var infoComponente = infoComponentes.find(info=> info.Codigo === Math.abs(vs.codigo));
        if (item){
            if (infoComponente){
                itemsSelected.push({codigo: item.codigo, valor: item.valor, sales: vs.sales, cants: vs.cants, salesPosibles : infoComponente.Sales, cantsPosibles : infoComponente.Cantidades, negado : (vs.codigo<0)});
            }else{
                itemsSelected.push({codigo: item.codigo, valor: item.valor, sales: vs.sales, cants: vs.cants, salesPosibles : [], cantsPosibles : [], negado : (vs.codigo<0)});
            }
            
        }
    }

   
    const HTMLImagen = <img class="arrow imagenTagEliminar" src="./img/close-tag.svg" id="imgBorrar" alt="eliminar" onClick={(e) => BorraItem(e) } />
    const HTMLTagsSelected = itemsSelected.map(item => 
                                <span className={(item.codigo===currentComponente) ? "tag active": "tag"} data-value={item.codigo} data-negado={(item.negado)?"S":"N"} onClick={(e)=> SeleccionaItem(e) } >
                                    {item.negado ? "NO " : ""}
                                    {item.valor}
                                    {HTMLImagen}
                                    {(item.sales) && (item.sales.length>1) ? 
                                        <span class="extra-field">
                                            <span class="row-subfield">varias sales
                                            </span>
                                        </span>
                                    :(item.sales) && (item.sales.length===1) ?
                                        <span class="extra-field">
                                            <span class="row-subfield">{item.sales[0].valor}
                                            </span>
                                        </span>
                                    :
                                        <span></span>
                                    }
                                    {(item.cants) && (item.cants.length>1) ? 
                                        <span class="extra-field">
                                            <span class="row-subfield">varias cantidades
                                            </span>
                                        </span>
                                    :(item.cants) && (item.cants.length===1) ?
                                        <span class="extra-field">
                                            <span class="row-subfield">{item.cants[0].des}
                                            </span>
                                        </span>
                                    :
                                        <span></span>
                                    }
                                </span>
                            );
    const HTMLTags = ((itemsSelected && itemsSelected.length>0) ?
        <div class="module module__filters" id="extra-filter" style={{borderLeft: '1px', borderLeftColor: '#d9d9d9', borderLeftStyle: 'solid', paddingLeft: '20px', paddingRight: '20px'}}>
            <div class="tags" id="tags-1">
                {HTMLTagsSelected}
            </div>
        </div>
        :
        <div class="module module__filters" id="extra-filter">
            <div class="tags" id="tags-1">
            </div>
        </div>
    );

    const currentItemSelected = itemsSelected.find(item=> (item.codigo === currentComponente));

    const ClickNegado = (e) =>{
        if (currentComponente){
            props.alUpdatePA(currentComponente,e.target.checked);
        }
        setNegado(e.target.checked);
    }


    const HTMLSalesYCantidades = (((currentComponente>0) && (currentItemSelected)  
                                    /*&& (((currentItemSelected.salesPosibles) && (currentItemSelected.salesPosibles.length>0)) 
                                        || ((currentItemSelected.cantsPosibles) && (currentItemSelected.cantsPosibles.length>0)))*/) ? 
        <div class="module module__filters" id="extra-filter" style={{borderLeft: '1px', borderLeftColor: '#d9d9d9', borderLeftStyle: 'solid', paddingLeft: '20px'}}>
            <h3 class="fs fs14 FlamaBook greyColor2 name">{currentItemSelected.valor}</h3>
            {(!props.ocultaNegado) ?
            <div className="radios">
                    <label style={{fontSize:'14px'}}>
                        <input type="checkbox" name="negado" checked={negado}  onClick={(e) => ClickNegado(e)}/>
                        No debe contener {currentItemSelected.valor}
                    </label>
            </div>
            :
            <></>
            }
            { ((currentItemSelected.cantsPosibles) && (currentItemSelected.cantsPosibles.length>0) && !negado) ?
                <div class="custom-select-container  active   has-checks style-green " id="custom-select-785" data-placeholder="Cantidad">
                    <div class="cover cover__select cover__select cover__select__border" onClick={(e) => clickEnCabeceraSC(e)}>
                        <div class="select-label">Cantidad</div>
                    </div>
                    <div class="dropdown dropdown__radios">
                        { currentItemSelected.cantsPosibles.map(cp =>
                            (currentItemSelected.cants.find(cs => (cs.cant === cp.Cant && cs.cod === cp.Cod)) ?
                                <label class="active" data-cod={cp.Cod} data-cant={cp.Cant} onClick={(e) => props.alBorrarCant(currentComponente,cp.Cod,cp.Cant)}>{cp.Des}</label>
                            :
                                <label                data-cod={cp.Cod} data-cant={cp.Cant} onClick={(e) => props.alAnadirCant(currentComponente,cp.Cod,cp.Cant, cp.Des.toLowerCase())}>{cp.Des}</label>
                            ))
                        }
                    </div>
                </div>
            :
                <div></div>
            }
            { ((currentItemSelected.salesPosibles) && (currentItemSelected.salesPosibles.length>0)  && !negado) ?
                <div class="custom-select-container  active   has-checks style-green " id="custom-select-785" data-placeholder="Sal">
                    <div class="cover cover__select cover__select cover__select__border" onClick={(e) => clickEnCabeceraSC(e)}>
                        <div class="select-label">Sal</div>
                    </div>
                    <div class="dropdown dropdown__radios">
                        { currentItemSelected.salesPosibles.map(sp =>
                            (currentItemSelected.sales.find(ss => (ss.codigo === sp.Codigo)) ?
                                <label class="active" data-codigo={sp.Codigo} onClick={(e) => props.alBorrarSal(currentComponente,sp.Codigo)}>{sp.Valor}</label>
                            :
                                <label                data-codigo={sp.Codigo} onClick={(e) => props.alAnadirSal(currentComponente,sp.Codigo, sp.Valor.toLowerCase())}>{sp.Valor}</label>
                            ))
                        }
                    </div>
                </div>
            :
                <div></div>
            }
        </div>                
        :
        <div class="module module__filters" id="extra-filter">
        </div>                
    );
                    
 
    useEffect(() => {
        
        if (!propiedades.lista || !valuesSelected || !deepEqual(props.lista, propiedades.lista) || !deepEqual(props.valuesSelected, valuesSelected ) ) {
            setListaVisible([]);
            setPropiedades({
                lista: props.lista,
                valuesSelected: props.valuesSelected,
            })
            DescargaInfoValuesSelected();
        }
        if (props.valuesSelected && props.valuesSelected.length>0 && (!infoComponentes || infoComponentes.length==0)){
            DescargaInfoValuesSelected();
        }
    },  [props.lista, props.valuesSelected]);

    const clickEnCabeceraSC= (e) =>{
        var item = e.target;
        var parent = GetAntecesorWhithClassName(item,'custom-select-container');
        parent.classList.toggle('active');
    }

    const DescargaInfoValuesSelected = () =>{
        var listaCodigos = props.valuesSelected.map(item => item.codigo);
        
        if (listaCodigos.length>0){
            BP2022Service.descargaListaInfoSalesYCantidades(listaCodigos,props.tipo)
            .then((response) => {
                var descarga = response.data;
                if (descarga){
                    for (var i=0; i<descarga.length; i++){
                        var itemdescarga = descarga[i];
                        var itemvs = props.valuesSelected.find(item => Math.abs(item.codigo) === itemdescarga.Codigo);
                        if (itemvs.sales){
                            for (var j=0; j<itemvs.sales.length; j++){
                                var salvs = itemvs.sales[j];
                                var saldescarga = itemdescarga.Sales.find(item => item.Codigo === salvs.codigo);
                                if (saldescarga){
                                    salvs.valor = saldescarga.Valor.toLowerCase();
                                }
                            }
                        }
                        if (itemvs.cants){
                            for (var j=0; j<itemvs.cants.length; j++){
                                var cantvs = itemvs.cants[j];
                                var cantdescarga = itemdescarga.Cantidades.find(item => item.Cant === cantvs.cant && item.Cod === cantvs.cod);
                                if (cantdescarga){
                                    cantvs.des = cantdescarga.Des.toLowerCase();
                                }
                            }
                        }
                    }
                    setInfoComponentes(descarga);
                    props.alCambiarPASolo(props.pasolo);
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });    
        }
    }

    const ClickEnCoverSelect  = (e) =>{
        if (!props.disabled){
            const item = e.target;        
            const itemContainer = GetAntecesorWhithClassName(item, 'custom-select-container');
            itemContainer.classList.toggle('active');
        }
    }

    const ClickEnOpcion  = (e) =>{
    	var option = e.target;
        SeleccionaOpcion(option);
    }

    const SeleccionaOpcion = (option) =>{
        var value = option.getAttribute("data-value");

        var container = GetAntecesorWhithClassName(option, "custom-select-container");
        var select = primerHijoConClase(container,'custom-select');
        var input = GetSucesorWhithClassName(container, 'inputSelector');

        container.classList.remove("active");

        //RemoveClassNameToChildClassItems(container, 'dropdown__radios', 'labeloption', 'active');
        RemoveClassNameToChildClassItems(container, 'dropdown__radios', 'labeloption', 'preselected');
        RemoveClassNameToChildClassItems(container, 'dropdown__radios', 'labeloption', 'hide');
        option.classList.add("active");

        if (input){
            input.value='';
        }

        if(!(ExistsClassNameChildClassItems(container,'dropdown','labeloption','hide') || ExistsClassNameChildClassItems(container,'dropdown','labeloption','active')))
            RemoveClassNameToChildClassItems(container,'dropdown',null,'show');

        if (!props.idEsStr)
            value = parseInt(value);
        if (props.alAnadirPA){
            props.alAnadirPA(value);
            setCurrentComponente(value);
        }
           
    }

    const remove_accents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } 
  
    const KeyUpInput = (e) =>{
		var input = e.target;
        const container = GetAntecesorWhithClassName(input, 'custom-select-container');
        var texto = input.value;
        FiltraOpciones(container,texto);
    }

    const FiltraOpciones = (container, texto) =>{
        var search = remove_accents(texto.toLowerCase());
        var search2 = " " + search;

    	if(search != ""){
            var nuevalista = lista.slice(1);
            var textoDividido = search.split(' ');
            for(var i=0; i<textoDividido.length ; i++){
                var search1 = textoDividido[i];
                var search2 = ' ' + textoDividido[i];
                switch (tipoFiltradoLista){
                    case 0:
                        nuevalista = nuevalista.filter(item => remove_accents(item.valor.toLowerCase()).indexOf(search1)===0);
                        break;
                    case 1: 
                        nuevalista = nuevalista.filter(item => ((remove_accents(item.valor.toLowerCase()).indexOf(search1) === 0) || (remove_accents(item.valor.toLowerCase()).indexOf(search2)>0)));
                        break;
                    case 2:
                        nuevalista = nuevalista.filter(item => ((remove_accents(item.valor.toLowerCase()).indexOf(search1) === 0) || (item.codigo.toLowerCase().indexOf(search1) === 0)));
                        break;
                    case 3:
                        nuevalista = nuevalista.filter(item => ((remove_accents(item.valor.toLowerCase()).indexOf(search1) === 0) || (item.codigo.toLowerCase().indexOf(search1) === 0) || (remove_accents(item.valor.toLowerCase()).indexOf(search2)>0)));
                        break;
                    case 4:
                        nuevalista = nuevalista.filter(item => (remove_accents(item.valor.toLowerCase()).indexOf(search1) >= 0));
                        break;
                }
            }
            setListaVisible(nuevalista);
        }else{
            setListaVisible([]);
        }
    }


    const KeyDownSelector = (e) =>{
        const container = GetAntecesorWhithClassName(e.target, 'custom-select-container');
        const ddown = GetSucesorWhithClassName(container, 'dropdown__radios');
        const input = primerHijoConClase(container, 'inputSelector');
       
        if (ddown.classList.contains('show')){
		    var keyCode = e.keyCode;
            var miItem;
            miItem = GetSucesorWhithClassName(ddown,'preselected');
            if (!miItem){
                miItem = GetSucesorWhithClassName(ddown,'active');
            }
            if (keyCode === 27){
                ddown.classList.remove('show');
                input.value = '';
            }

            if (keyCode ===38){
                //UP
                if (miItem)
                    miItem = miItem.previousSibling;
                if (!miItem)
                    miItem = GetLastSucesorWhithClassName(ddown,'labeloption')
                if (miItem){
                    scrollSiNoVisible(ddown,miItem);
                    RemoveClassNameToChildClassItems(ddown,'labeloption',null,'preselected');
                    miItem.classList.add('preselected');
                }
            }
            if (keyCode === 40){
                //DOWN
                if (miItem)
                    miItem = miItem.nextSibling;
                if (!miItem)
                    miItem = GetSucesorWhithClassName(ddown,'labeloption')
                if (miItem){
                    scrollSiNoVisible(ddown,miItem);
                    RemoveClassNameToChildClassItems(ddown,'labeloption',null,'preselected');
                    miItem.classList.add('preselected');
                }
            }
            if (keyCode === 13){
                e.preventDefault();
                e.stopPropagation();
                miItem =  GetSucesorWhithClassName(ddown,'preselected')
                if (miItem){
                    SeleccionaOpcion(miItem);
                }
            }
        }
    }

    const BorraItem = (e) =>{
        var img = e.target;
        const tag = GetAntecesorWhithClassName(img,'tag');
        var value = tag.getAttribute("data-value");
        var negado = tag.getAttribute("data-negado")==="S";
        if (value === currentComponente)
            setCurrentComponente(0);
        setNegado(false);
        if (props.alBorrarPA){
            if (negado)
                props.alBorrarPA(parseInt(value));
            else
                props.alBorrarPA(parseInt(value)*(-1));
        }
        
    }

    const SeleccionaItem = (e) =>{
        var img = e.target;
        if (!img.classList.contains('imagenTagEliminar')){
            const tag = GetAntecesorWhithClassName(img,'tag');
            var value = tag.getAttribute("data-value");
            var negado = tag.getAttribute("data-negado")==="S";
            setCurrentComponente(parseInt(value));
            setNegado(negado);
        }
    }

    const dropdownclases = (listavisible.length>0) ? "dropdown dropdown__radios show" : "dropdown dropdown__radios"

    //const strComponente = (props.pf ? "ingredientes" : "principios activos");
    const strComponente = props.nombreComponentes.toLowerCase();

    const opcionesComposHTML = ((props.muestraChecks) ?
            ((props.pasolo)?
                <div class="cover cover__inputs cover__inputs__radios">
                    <label><input type="radio" name="opcionCompos" value="solo" checked onClick={(e) => props.alCambiarPASolo(true)}/>Únicamente los {strComponente} seleccionados</label>
                    <label><input type="radio" name="opcionCompos" value="aso"          onClick={(e) => props.alCambiarPASolo(false)}/>Puede contener {strComponente} adicionales</label>
                </div>
            :
                <div class="cover cover__inputs cover__inputs__radios">
                    <label><input type="radio" name="opcionCompos" value="solo"        onClick={(e) => props.alCambiarPASolo(true)}/>Únicamente los {strComponente} seleccionados</label>
                    <label><input type="radio" name="opcionCompos" value="aso" checked onClick={(e) => props.alCambiarPASolo(false)}/>Puede contener {strComponente} adicionales</label>
                </div>
        )
        :<div></div>
    );

    return (
        <div>
            <h3 class="title fs fs16 greyColor2" style={{paddingBottom:'20px'}}>{props.titulo}</h3>
            <div class="cover cover__filters cover__filters_3" >
                <div className="module module__filters" style={{paddingRight: '20px'}} >
                    <div class="search search__ratios"  style={estiloModuleSelect}>
                        <div class="custom-select-container search target" id="custom-select-" onKeyDown={(e) => KeyDownSelector(e)}>
                            <div class="cover cover__search">
                                <img class="lupa" src="./img/ficha/lupaPurple.svg" />
                                <input type="text" placeholder={textPlaceholder} className="inputSelector" onKeyUp={(e) => KeyUpInput(e)}/>                        
                            </div>
                            <select class="custom-select" id={idHTML} style={{display:"none"}}  data-target='tags-1' multiple>
                                {optionsHTML}
                            </select>
                            <div class={clasesCoverSelect} onClick={(e) => ClickEnCoverSelect(e)}>
                                <div class="select-label" >
                        
                                </div>
                            </div>
                            <div class={dropdownclases}>
                                {optionsDisplayed}
                            </div>
                        </div>
                    </div>
                    {opcionesComposHTML}
                </div>
                {HTMLTags}
                {HTMLSalesYCantidades}
            </div>
        </div>
    );
}

export default SelectorComposicion;

/*
                        <div class="custom-select-container     has-checks style-green " id="custom-select-785" data-placeholder="Cantidad">
                            <select class="custom-select" multiple="" data-checks="true" data-style="green" style="display: none;">
                                <option value="">Cantidad</option>
                                <option value="43.2 % V/V">43.2 % V/V</option>
                                <option value="0.42 % Gramos">0.42 % Gramos</option>
                                <option value="0.339 Microgramos">0.339 Microgramos</option>
                                <option value="1.42 Microgramos">1.42 Microgramos</option>
                            </select>
                            <div class="cover cover__select cover__select cover__select__border">
                                <div class="select-label">Cantidad</div>
                            </div>
                            <div class="dropdown dropdown__radios">
                                <label data-value="43.2 % V/V">43.2 % V/V</label>
                                <label data-value="0.42 % Gramos">0.42 % Gramos</label>
                                <label data-value="0.339 Microgramos">0.339 Microgramos</label>
                                <label data-value="1.42 Microgramos">1.42 Microgramos</label>
                            </div>
                        </div>
                        <div class="custom-select-container     has-checks style-green " id="custom-select-217" data-placeholder="Sal">
                            <select class="custom-select" multiple="" data-checks="true" data-style="green" style="display: none;">
                                <option value="">Sal</option>
                                <option value="43.2 % V/V">43.2 % V/V</option>
                                <option value="0.42 % Gramos">0.42 % Gramos</option>
                                <option value="0.339 Microgramos">0.339 Microgramos</option>
                                <option value="1.42 Microgramos">1.42 Microgramos</option>
                            </select>
                            <div class="cover cover__select cover__select cover__select__border">
                                <div class="select-label">Sal</div>
                            </div>
                            <div class="dropdown dropdown__radios">
                                <label data-value="43.2 % V/V">43.2 % V/V</label>
                                <label data-value="0.42 % Gramos">0.42 % Gramos</label
                                ><label data-value="0.339 Microgramos">0.339 Microgramos</label>
                                <label data-value="1.42 Microgramos">1.42 Microgramos</label>
                            </div>
                        </div>

*/