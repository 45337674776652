import { useEffect } from 'react/cjs/react.development';
import { IconosListadosPI } from '../../../../services/objsAplicacion.js';
import {numberWithCommas, rootPath} from '../../../../services/utils.js'

function FilaListaEXT(props){
    const item = props.item;
    const pictogramatipo =  rootPath + "img/person.svg" ;
    const vesAFicha = (it) =>{
        props.cambiaPagina({
            newpagina: "FichaEXT",
            newid: it.Id,
            filtros: props.filtros,
            numMostrar: props.numMostrar,
            idxOrden: props.idxOrden,
        });
    }

    const ancho = window.innerWidth;
    const isMobile = (ancho<768);

    return (
        <div class="item " > 
            <div class="product">
                <div class="mask" onClick={() => vesAFicha(props.item)} style={{cursor:'pointer'}}>
                    <img src={pictogramatipo} alt={item.TipoRegistro} />
                </div>
                <div class="box box__text" >
                    <div class="title" >
                        <h3 class="fs fs17 blackColor" >
                            <span title={item.NombreCompleto}  onClick={() => vesAFicha(props.item)} style={{cursor:'pointer'}}>{item.NombreCompleto}</span>
                        </h3>
                    </div>
                    <div class="info" >
                        <p class="fs fs15 purpleColor tag" >{item.AuxStr}</p>
                    </div>
                </div>
            </div>
            <div class="product__right" >
                <div class="module module__top" >
                </div>
            </div>
        </div> 
    )    
}

export default FilaListaEXT;
