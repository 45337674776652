import { FormatoFechaDDMMYYYY } from "../../../../../services/utils";


const FilaLotes = (props) => {
    const lote = props.lote;

    return (
        <div class="table__row table__row__normal fw fw300 uppercase">
            <div class="column fs fs14 blackColor FlamaBook">
                <p>
                    {lote.NumLote}
                </p>
            </div>
            <div class="column fs fs14 blackColor FlamaBook" >
                {(lote.FechaCaducidad) && (new Date(lote.FechaCaducidad).getTime()>0) ?
                    <p>{FormatoFechaDDMMYYYY(lote.FechaCaducidad)}</p>
                :
                    <p>-</p>
                }
            </div>
        </div>
    )
}
export default FilaLotes;
