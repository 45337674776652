import { elementIsOfClass, rootPath } from "../../../../../services/utils";
import { renderToString } from 'react-dom/server'
import { useState } from "react";

const Acordeon = (props) => {
    const [completo, setCompleto] = useState(props.activo) // || renderToString(props.contenido).length<2000)
    var clasDiv = (props.activo ? "accordion__module active" : "accordion__module")
    const tipo = (props.tipo ? props.tipo : 1);
    var htmlTitulo = (<h3 class="title fs fs16 fw fw600 greyColor2">{props.titulo}</h3>)
    var htmlArrow = (<img class="arrow" src = {rootPath + "img/arrowGreen2.svg"} alt="arrowGreen2" />);
    var claseContenido = "accordion__content";

    var estiloHeader = {};
    if (tipo === 2){
        htmlTitulo =(<h3 class="title fs fs14 fw fw600 blackColor">{props.titulo}</h3>);
        claseContenido = "accordion__content accordion__content__paddigLeft";
    }
    if (tipo === 3){
        htmlTitulo =(<h3 class="title fs fs20 purpleColor uppercase">{props.titulo}</h3>);
        claseContenido = "accordion__content";
        clasDiv += " accordion__module__left"
    }
    if (tipo === 4){
        htmlTitulo =(<h3 class="title fs fs16 purpleColor5 title">{props.titulo}</h3>);
        claseContenido = "accordion__content ";
        clasDiv += " accordion__module__left";
        estiloHeader = { paddingBottom: '0px'}
    }

    const handleClickAcordeonHeader = (e) =>{
        var item = e.target;
        while (item && !elementIsOfClass(item,'accordion__header'))
            item = item.parentElement;
        if (item){
            item.parentElement.classList.toggle('active');
            var newActivo = item.parentElement.classList.contains('active');
            var newCompleto = newActivo; // || renderToString(props.contenido).length<2000;
            if (completo !== newCompleto)
                setCompleto(newCompleto);
        }
    }


    return (
        <div class={clasDiv}> 
            <div class="accordion__header" style={estiloHeader} onClick={(e) =>  handleClickAcordeonHeader(e)}>
                {htmlArrow}
                <div class="text">
                    {htmlTitulo}
                </div>
            </div>
            {(completo) ?
            <div class={claseContenido}>
                {props.contenido}
            </div>
            :
            <div class={claseContenido}>
            </div>
            }
        </div>
    )
}
export default Acordeon;
