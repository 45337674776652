
import BP2022Service from "./user.service";
import { rootPath } from "./utils";

export function getDatosFormulario(tipoForm, id){
    var res = {}
    
    if (tipoForm === "FBLaboratorioMUH"){
        res = {
            tipoForm : tipoForm,
            tipo : "H",
            busqueda : "BLaboratoriosMUH",
            seleccionados : id,
            descarga : BP2022Service.getLaboratorios,
            idSelect : "Select__" + tipoForm,
            tituloSelect : "Laboratorio",
            placeholder : "Escriba nombre laboratorio",
            valorNulo : 0,
            aux : '',
            esIdStr : false,
            tipoFiltradoLista: 10,
        }
    }

    if (tipoForm === "FBLaboratorioMUV"){
        res = {
            tipoForm : tipoForm,
            tipo : "V",
            busqueda : "BLaboratoriosMUV",
            seleccionados : id,
            descarga : BP2022Service.getLaboratorios,
            idSelect : "Select__" + tipoForm,
            tituloSelect : "Laboratorio",
            placeholder : "Escriba nombre laboratorio",
            valorNulo : 0,
            aux : '',
            esIdStr : false,
            tipoFiltradoLista: 10,
        }
    }

    if (tipoForm === "FBLaboratorioPPF"){
        res = {
            tipoForm : tipoForm,
            tipo : "P",
            busqueda : "BLaboratoriosPPF",
            seleccionados : id,
            descarga : BP2022Service.getLaboratorios,
            idSelect : "Select__" + tipoForm,
            tituloSelect : "Laboratorio",
            placeholder : "Escriba nombre laboratorio",
            valorNulo : 0,
            aux : '',
            esIdStr : false,
            tipoFiltradoLista: 10,
        }
    }

    if (tipoForm === "FBAgrupHomoMUH"){
        res = {
            tipoForm : tipoForm,
            tipo : "H",
            busqueda : "BAgrupHomoMUH",
            seleccionado : id,
            descarga : BP2022Service.getAgrupaciones,
            idSelect : "Select__" + tipoForm,
            tituloSelect : "Agrupación homogénea",
            placeholder : "Escriba el código o nombre de la agrupación",
            valorNulo : '',
            aux : 'N',
            ancho : true,
            tipoFiltradoLista : 1,
            esIdStr : true,
        }
    }

    if (tipoForm === "FBAgrupHomoPPF"){
        res = {
            tipoForm : tipoForm,
            tipo : "P",
            busqueda : "BAgrupHomoPPF",
            seleccionado : id,
            descarga : BP2022Service.getAgrupaciones,
            idSelect : "Select__" + tipoForm,
            tituloSelect : "Agrupación homogénea nacional",
            placeholder : "Escriba el código o nombre de la agrupación",
            valorNulo : '',
            aux : 'N',
            ancho : true,
            tipoFiltradoLista : 1,
            esIdStr : true,
        }
    }

    if (tipoForm === "FBAgrupHomoAutoMUH"){
        res = {
            tipoForm : tipoForm,
            tipo : "H",
            busqueda : "BAgrupHomoAutoMUH",
            seleccionado : id,
            descarga : BP2022Service.getAgrupaciones,
            idSelect : "Select__" + tipoForm,
            tituloSelect : "Agrupación homogénea",
            placeholder : "Escriba el código o nombre de la agrupación",
            valorNulo : '',
            aux : 'S',
            ancho : true,
            tipoFiltradoLista : 1,
            esIdStr : true,
        }
    }

    if (tipoForm === "FBAgrupHomoAutoPPF"){
        res = {
            tipoForm : tipoForm,
            tipo : "P",
            busqueda : "BAgrupHomoAutoPPF",
            seleccionado : id,
            descarga : BP2022Service.getAgrupaciones,
            idSelect : "Select__" + tipoForm,
            tituloSelect : "Agrupación homogénea",
            placeholder : "Escriba el código o nombre de la agrupación",
            valorNulo : '',
            aux : 'S',
            ancho : true,
            tipoFiltradoLista : 1,
            esIdStr : true,
        }
    }

    if (tipoForm === "FBGTVMPPMUH"){
        res = {
            tipoForm : tipoForm,
            tipo : "H",
            busqueda : "BGTVMPPMUH",
            seleccionado : id,
            descarga : BP2022Service.getArbolGruposVMPP,
            idSelect : "Arbol__" + tipoForm,
            tituloSelect : "Descripción clínica AEMPS",
            placeholder : "Escriba el nombre de un principio activo",
            valorNulo : '',
            aux : '',
            ancho : true,
            tipoFiltradoLista : 0,
            ocultaCodigo: true,
            esIdStr : true,
        }
    }

    if (tipoForm === "FBGTVMPP2MUH"){
        res = {
            tipoForm : tipoForm,
            tipo : "H",
            busqueda : "BGTVMPPMUH",
            seleccionado : id,
            descarga : BP2022Service.getGruposVMPP,
            idSelect : "Arbol__" + tipoForm,
            tituloSelect : "Descripción clínica AEMPS",
            placeholder : "Escriba el nombre de un principio activo",
            valorNulo : '',
            aux : '',
            ancho : true,
            tipoFiltradoLista : 0,
            ocultaCodigo: true,
            esIdStr : true,
        }
    }

    if (tipoForm === "FBFormaFarmaMUH"){
        res = {
            tipoForm : tipoForm,
            tipo : "H",
            busqueda : "BFormasFarmaMUH",
            seleccionados : id,
            descarga : BP2022Service.getFormasFarma,
            idSelect : "Select__" + tipoForm,
            tituloSelect : "Forma farmacéutica",
            placeholder : "Escriba la forma farmacéutica",
            valorNulo : 0,
            aux : '',
            esIdStr : false,
            tipoFiltradoLista: 10,
        }
    }

    if (tipoForm === "FBFormaFarmaMUV"){
        res = {
            tipoForm : tipoForm,
            tipo : "V",
            busqueda : "BFormasFarmaMUV",
            seleccionados : id,
            descarga : BP2022Service.getFormasFarma,
            idSelect : "Select__" + tipoForm,
            tituloSelect : "Forma farmacéutica",
            placeholder : "Escriba la forma farmacéutica",
            valorNulo : 0,
            aux : '',
            esIdStr : false,
            tipoFiltradoLista: 10,
        }
    }

    if (tipoForm === "FBFormaFarmaPPF"){
        res = {
            tipoForm : tipoForm,
            tipo : "P",
            busqueda : "BFormasFarmaPPF",
            seleccionados : id,
            descarga : BP2022Service.getFormasFarma,
            idSelect : "Select__" + tipoForm,
            tituloSelect : "Forma farmacéutica",
            placeholder : "Escriba la forma farmacéutica",
            valorNulo : 0,
            aux : '',
            esIdStr : false,
            tipoFiltradoLista: 10,
        }
    }

    if (tipoForm === "FBAFMUH"){
        res = {
            tipoForm : tipoForm,
            tipo : "H",
            busqueda : "BAFsMUH",
            seleccionados : id,
            descarga : BP2022Service.getActividades,
            idSelect : "Select__" + tipoForm,
            tituloSelect : "Actividad farmacológica",
            placeholder : "Escriba la actividad farmacológica",
            valorNulo : 0,
            aux : 'AF',
            esIdStr : false,
        }
    }
    
    if (tipoForm === "FBAFMUV"){
        res = {
            tipoForm : tipoForm,
            tipo : "V",
            busqueda : "BAFsMUV",
            seleccionados : id,
            descarga : BP2022Service.getActividades,
            idSelect : "Select__" + tipoForm,
            tituloSelect : "Actividad farmacológica",
            placeholder : "Escriba la actividad farmacológica",
            valorNulo : 0,
            aux : 'AF',
            esIdStr : false,
        }
    }
    
    if (tipoForm === "FBAMMUH"){
        res = {
            tipoForm : tipoForm,
            tipo : "H",
            busqueda : "BAMsMUH",
            seleccionados : id,
            descarga : BP2022Service.getActividades,
            idSelect : "Select__" + tipoForm,
            tituloSelect : "Capacidad inductora / inhibidora enzimática",
            placeholder : "Escriba el nombre del enzima metabolizador o proteína transportadora",
            valorNulo : 0,
            aux : 'AM',
            esIdStr : false,
        }
    }

    if (tipoForm === "FBActPPF"){
        res = {
            tipoForm : tipoForm,
            tipo : "P",
            busqueda : "BActsPPF",
            seleccionados : id,
            descarga : BP2022Service.getActividades,
            idSelect : "Select__" + tipoForm,
            tituloSelect : "Actividad",
            placeholder : "Escriba la actividad",
            valorNulo : 0,
            aux : '',
            esIdStr : false,
        }
    }

    if (tipoForm === "FBAnalisisMUH"){
        res = {
            tipoForm : tipoForm,
            tipo : "H",
            busqueda : "BAnalisisMUH",
            seleccionado : id,
            descarga : BP2022Service.getAnalisis,
            idSelect : "Select__" + tipoForm,
            tituloSelect : "Análisis clínico",
            placeholder : "Indique un análisis clínico",
            valorNulo : 0,
            aux : '',
            esIdStr : false,
        }
    }

    if (tipoForm === "FBFarmacogeneticaMUH"){
        res = {
            tipoForm : tipoForm,
            tipo : "H",
            busqueda : "BFarmacogeneticaMUH",
            seleccionado : id,
            descarga : BP2022Service.getBiomarcadores,
            idSelect : "Select__" + tipoForm,
            tituloSelect : "Biomarcador",
            placeholder : "Indique un biomarcador",
            valorNulo : 0,
            aux : '',
            esIdStr : false,
        }
    }

    if (tipoForm === "FBGTATCMUH"){
        res = {
            tipoForm : tipoForm,
            tipo : "H",
            busqueda : "BGTsATCMUH",
            seleccionados : id,
            descarga : BP2022Service.getGruposATC,
            idSelect : "Arbol__" + tipoForm,
            tituloSelect : "Grupo terapéutico ATC",
            placeholder : "Escriba el código o nombre del grupo" ,
            valorNulo : '',
            ancho : true,
            tipoFiltradoLista : 3,
            esIdStr : true,
        }
    }

    if (tipoForm === "FBGTATCMUV"){
        res = {
            tipoForm : tipoForm,
            tipo : "V",
            busqueda : "BGTsATCMUV",
            seleccionados : id,
            descarga : BP2022Service.getGruposATC,
            idSelect : "Arbol__" + tipoForm,
            tituloSelect : "Grupo terapéutico ATC",
            placeholder : "Escriba el código o nombre del grupo" ,
            valorNulo : '',
            aux : '',
            ancho : true,
            tipoFiltradoLista : 3,
            esIdStr : true,
        }
    }

    if (tipoForm === "FBGTConPPF"){
        res = {
            tipoForm : tipoForm,
            tipo : "p",
            busqueda : "BGTConPPF",
            seleccionado : id,
            descarga : BP2022Service.getGruposCon,
            idSelect : "Arbol__" + tipoForm,
            tituloSelect : "Grupo categorización Consejo",
            placeholder : "Escriba el código o nombre de la categoría",
            valorNulo : '',
            ancho : true,
            tipoFiltradoLista : 3,
            esIdStr : true,
        }
    }

    if (tipoForm === "FBGTSanPPF"){
        res = {
            tipoForm : tipoForm,
            tipo : "p",
            busqueda : "BGTSanPPF",
            seleccionado : id,
            descarga : BP2022Service.getGruposSan,
            idSelect : "Arbol__" + tipoForm,
            tituloSelect : "Grupo clasificación Nomenclátor",
            placeholder : "Escriba el código o nombre del grupo",
            valorNulo : '',
            ancho : true,
            tipoFiltradoLista : 3,
            esIdStr : true,
        }
    }

    /*
    if (tipoForm === "FBGTVMPPMUH"){
        res = {
            tipoForm : tipoForm,
            tipo : "H",
            busqueda : "BGTVMPPMUH",
            seleccionado : id,
            descarga : BP2022Service.getGruposVMPP,
            idSelect : "Arbol__" + tipoForm,
            tituloSelect : "Descripción clínica AEMPS",
            placeholder : "Escriba el nombre de un principio activo",
            valorNulo : '',
            aux : '',
            ancho : true,
            tipoFiltradoLista : 3,
            ocultaCodigo: true,
            esIdStr : true,
        }
    }
    */
    return res;
}


export const OpcionesDG = [
    {
        cod: 'EST',
        tit: 'Estado autorización',
        tip: 'CHK',
        ops: [
            {
                tit: 'Autorizado',
                val: '0'
            },
            {
                tit: 'Suspendido',
                val: '1'
            },
            {
                tit: 'Anulado',
                val: '2'
            },
        ],
    },
    {
        cod: 'COM',
        tit: 'Comercializado',
        tip: 'SNX',
    },
]

export const OpcionesDGPF = [
    {
        cod: 'EST',
        tit: 'Estado',
        tip: 'CHK',
        ops: [
            {
                tit: 'Alta',
                val: '0'
            },
            {
                tit: 'Suspendido',
                val: '1'
            },
            {
                tit: 'Baja',
                val: '2'
            },
        ],
    },
    {
        cod: 'COM',
        tit: 'Comercializado',
        tip: 'SNX',
    },
]

export const OpcionesDGPA = [
    {
        cod: 'EST',
        tit: 'Estado autorización',
        tip: 'CHK',
        ops: [
            {
                tit: 'Autorizado',
                val: '0'
            },
            {
                tit: 'Suspendido',
                val: '1'
            },
            {
                tit: 'Anulado',
                val: '2'
            },
        ],
    },
    {
        cod: 'COM',
        tit: 'Comercializado',
        tip: 'SNX',
    },
    {
        cod: 'PSI',
        tit: 'Psicotropo',
        tip: 'SNX',
    },
    {
        cod: 'DRG',
        tit: 'Estupefaciente',
        tip: 'SNX',
    },
]

export const OpcionesDISP = [
    {
        cod: 'R',
        tit: 'Receta',
        tip: 'SNX',
    },
    {
        cod: 'D',
        tit: 'Dispensable en farmacia<br/> comunitaria',
        tip: 'SNX',
    },
    /*{
        cod: 'F',
        tit: 'Financiado en farmacia comunitaria',
        tip: 'CHK',
        ops: [
            {
                tit: 'Financiado',
                val: 'INSNS'
            },
            {
                tit: 'Excluido',
                val: 'EXO'
            },
            {
                tit: 'No financiado',
                val: 'NOF'
            },
        ],
    },*/
    {
        cod: 'F',
        tit: 'Financiado en farmacia<br/> comunitaria',
        tip: 'SNX',
    },
    {
        cod: 'EXO',
        tit: 'Excluido',
        tip: 'SNX',
    },
    {
        cod: 'EXOI',
        tit: 'Excluido con indicaciones<br/> financiadas',
        tip: 'SNX',
    },
    {
        cod: 'S',
        tit: 'Sustituible',
        tip: 'SNX',
    },
    {
        cod: 'A',
        tit: 'Aportación',
        tip: 'CHK',
        ops: [
            {
                tit: 'Reducida',
                val: 'AR'
            },
            {
                tit: 'Normal',
                val: 'AN'
            },
            {
                tit: 'Reducida en HFH',
                val: 'HFH'
            },
        ],
    },
    {
        cod: 'V',
        tit: 'Visado',
        tip: 'CHK',
        ops: [
            {
                tit: 'Sí',
                val: 'V'
            },
            {
                tit: 'Sólo para > 75',
                val: 'M75'
            },
            {
                tit: 'No',
                val: 'NV'
            },
            {
                tit: 'Validación mutualidades',
                val: 'VM'
            },
        ],
    },
    {
        cod: 'TLD',
        tit: 'Tratamiento larga duración',
        tip: 'SNX',
    },
    {
        cod: 'H',
        tit: 'Uso hospitalario',
        tip: 'SNX',
    },
    {
        cod: 'DH',
        tit: 'Diagnóstico hospitalario',
        tip: 'SNX',
    },
    {
        cod: 'DIHSC',
        tit: 'Reserva singular<br/> financiado hospital',
        tip: 'SNX',
    },
    {
        cod: 'VERIF',
        tit: 'Dispositivo seguridad',
        tip: 'SNX',
    },
    {
        cod: 'RGIE',
        tit: 'Con receta, en guía <br/> de enfermería',
        tip: 'SNX',
    },
    {
        cod: 'PN',
        tit: 'Precio notificado',
        tip: 'SNX',
    },
    {
        cod: 'LR',
        tit: 'Anotación libro recetario',
        tip: 'SNX',
    },
    {
        cod: 'LE',
        tit: 'Anotación libro<br/> estupefacientes',
        tip: 'SNX',
    },
]

export const OpcionesDISPPPF = [
    {
        cod: 'R',
        tit: 'Receta',
        tip: 'SNX',
    },
    {
        cod: 'F',
        tit: 'Financiado',
        tip: 'SNX',
    },
    {
        cod: 'A',
        tit: 'Aportación',
        tip: 'CHK',
        ops: [
            {
                tit: 'Reducida',
                val: 'AR'
            },
            {
                tit: 'Normal',
                val: 'AN'
            },
            {
                tit: 'Nula',
                val: 'ANUL'
            },
        ],
    },
    {
        cod: 'V',
        tit: 'Visado',
        tip: 'SNX',
    },
]

export const OpcionesCON = [
    {
        cod: 'FR',
        tit: 'Nevera',
        tip: 'SNX',
    },
    {
        cod: 'CO',
        tit: 'Congelador',
        tip: 'SNX',
    },
    {
        cod: 'LUZ',
        tit: 'Proteger de luz',
        tip: 'SNX',
    },
    {
        cod: 'T25',
        tit: 'Conservar a 25ºC',
        tip: 'SNX',
    },
    {
        cod: 'T30',
        tit: 'Conservar a 30ºC',
        tip: 'SNX',
    },
]

export const OpcionesPAC = [
    {
        cod: 'EMB',
        tit: 'Embarazo',
        tip: 'CHK',
        ops: [
            {
                tit: 'Autorizado',
                val: 'A'
            },
            {
                tit: 'Precaución',
                val: 'P'
            },
            {
                tit: 'No recomendado',
                val: 'C'
            },
        ],
    },
    {
        cod: 'LAC',
        tit: 'Lactancia',
        tip: 'CHK',
        ops: [
            {
                tit: 'Autorizado',
                val: 'A'
            },
            {
                tit: 'Precaución',
                val: 'P'
            },
            {
                tit: 'No recomendado',
                val: 'C'
            },
        ],
    },
]

export const OpcionesADM = [
    {
        cod: 'DG',
        tit: 'Fraccionable',
        tip: 'SNX',
    },
    {
        cod: 'ALM',
        tit: 'Administración con alimentos',
        tip: 'CHK',
        ops: [
            {
                tit: 'Con alimentos',
                val: 'CA'
            },
            {
                tit: 'En ayunas',
                val: 'EA'
            },
            {
                tit: 'Indiferente',
                val: 'IN'
            },
        ],
    },
    {
        cod: 'SPD',
        tit: 'Acondicionable en SPD',
        tip: 'SNX',
    },
]

export const OpcionesESP = [
    {
        cod: 'O',
        tit: 'Ototóxico',
        tip: 'SNX',
    },
    {
        cod: 'FS',
        tit: 'Fotosensible',
        tip: 'SNX',
    },
    {
        cod: 'DOP',
        tit: 'Dopante',
        tip: 'SNX',
    },
    {
        cod: 'COND',
        tit: 'Efecto sobre conducción',
        tip: 'SNX',
    },
    {
        cod: 'CIT',
        tit: 'Citotóxico',
        tip: 'SNX',
    },
    {
        cod: 'HD',
        tit: 'Hemoderivado',
        tip: 'SNX',
    },
]    
export const OpcionesOTR = [
    {
        cod: 'E',
        tit: 'Estupefaciente',
        tip: 'SNX',
    },
    {
        cod: 'PS',
        tit: 'Psicotropo',
        tip: 'SNX',
    },
    {
        cod: 'GEN',
        tit: 'Genérico',
        tip: 'SNX',
    },
    {
        cod: 'PUB',
        tit: 'Publicitario',
        tip: 'SNX',
    },
    {
        cod: 'EC',
        tit: 'Envase clínico',
        tip: 'SNX',
    },
    {
        cod: 'IP',
        tit: 'Importación paralela',
        tip: 'SNX',
    },
    {
        cod: 'UNID',
        tit: 'Unidosis',
        tip: 'SNX',
    },
    {
        cod: 'MTP',
        tit: 'Tradicional plantas',
        tip: 'SNX',
    },
]

export const OpcionesOTRV = [
    {
        cod: 'E',
        tit: 'Estupefaciente',
        tip: 'SNX',
    },
    {
        cod: 'PS',
        tit: 'Psicotropo',
        tip: 'SNX',
    },
    {
        cod: 'HSI',
        tit: 'Medicamento homeopático',
        tip: 'SNX',
    },
    {
        cod: 'IP',
        tit: 'Importación paralela',
        tip: 'SNX',
    },
]

export const OpcionesDISPV = [
    {
        cod: 'R',
        tit: 'Receta',
        tip: 'SNX',
    },
    {
        cod: 'AEV',
        tit: 'Administración exclusiva veterinario',
        tip: 'SNX',
    },
    {
        cod: 'ACV',
        tit: 'Administración control veterinario',
        tip: 'SNX',
    },
    {
        cod: 'LR',
        tit: 'Anotación libro recetario',
        tip: 'SNX',
    },
    {
        cod: 'LE',
        tit: 'Anotación libro estupefacientes',
        tip: 'SNX',
    },
]

export const OpcionesESPV = [
    {
        cod: 'ESVAC',
        tit: 'Notificación ESVAC',
        tip: 'SNX',
    },
]

export const OpcionesCONV = [
    {
        cod: 'FR',
        tit: 'Nevera',
        tip: 'SNX',
    },
    {
        cod: 'CO',
        tit: 'Congelador',
        tip: 'SNX',
    },
    {
        cod: 'LUZ',
        tit: 'Proteger de luz',
        tip: 'SNX',
    },
    {
        cod: 'T25',
        tit: 'Conservar a 25ºC',
        tip: 'SNX',
    },
    {
        cod: 'T30',
        tit: 'Conservar a 30ºC',
        tip: 'SNX',
    },
]

export const OpcionesCONPF = [
    {
        cod: 'FR',
        tit: 'Nevera',
        tip: 'SNX',
    },
]

export const OpcionesPacientePI = [
    {
        cod: 'PAC',
        tit: 'Condiciones fisiológicas',
        tip: 'CHECK',
        ops: [
            {
                tit: 'Embarazada',
                val: 'EMB'
            },
            {
                tit: 'Deportista competición',
                val: 'DEP'
            },
            {
                tit: 'Lactancia',
                val: 'LAC'
            },
            {
                tit: 'Usuario lentes de contacto',
                val: 'LEN'
            },
            {
                tit: 'Niño',
                val: 'NIN'
            },
            {
                tit: 'Dificultad deglución',
                val: 'DEG'
            },
        ],
    },
]

export const OpcionesPacienteProductosIndi = [
    {
        cod: 'PAC',
        tit: 'Condiciones fisiológicas',
        tip: 'CHECK',
        ops: [
            {
                tit: 'Embarazada',
                val: 'EMB'
            },
            {
                tit: 'Lactancia',
                val: 'LAC'
            },
        ],
    },
]

export const OpcionesPatologiasPI = [
    {
        cod: 'PAT',
        tit: 'Otras patologías',
        tip: 'CHECK',
        ops: [
            {
                tit: 'Asma',
                val: '6'
            },
            {
                tit: 'Hipertensión',
                val: '2'
            },
            {
                tit: 'Insuf. hepática',
                val: '5'
            },
            {
                tit: 'Diabetes',
                val: '1'
            },
            {
                tit: 'Insuf. renal',
                val: '4'
            },
            {
                tit: 'Insuf. cardíaca',
                val: '3'
            },
        ],
    },
]

export const IconosListadosPI = [
    {
        cod: 1,
        txt: 'Uso precautorio con alguna enfermedad declarada',
        img: rootPath + 'img/pacienteindicacion/EnfermedadPrecaucion.png',
    },
    {
        cod: 2,
        txt: 'Interacción de importancia moderada detectada',
        img: rootPath + 'img/pacienteindicacion/InteraccionPrecaucion.png',
    },
    {
        cod: 3,
        txt: 'Embarazo: consultar con el médico',
        img: rootPath + 'img/pacienteindicacion/EmbarazoPrecaucion.gif',
    },
    {
        cod: 4,
        txt: 'Lactancia: consultar con el médico',
        img: rootPath + 'img/pacienteindicacion/LactanciaPrecaucion.gif',
    },
    {
        cod: 5,
        txt: 'Niños: medicamento con limitaciones',
        img: rootPath + 'img/pacienteindicacion/NinosPrecaucion.png',
    },
    {
        cod: 6,
        txt: 'Niños: autorización por peso',
        img: rootPath + 'img/pacienteindicacion/NinosPeso.png',
    },
    {
        cod: 7,
        txt: 'No aplicar con lentes de contacto',
        img: rootPath + 'img/pacienteindicacion/LentesPrecaucion.gif',
    },
    {
        cod: 8,
        txt: 'No adaptado a problemas de deglución',
        img: rootPath + 'img/pacienteindicacion/DDeglucionNo.png',
    },
    {
        cod: 9,
        txt: 'Adaptado a problemas de deglución',
        img: rootPath + 'img/pacienteindicacion/DDeglucionSi.png',
    },
]

export const filtrosInteracciones = [
    {
        idGrupo : 'GRAV',
        nombre : 'Gravedad',
        tipo: 'Check',
        excluyente : false,
        campolista: 'IdSemaforo',
        tipoCampolista: 'int',
        abierto: true,
        valores : [
            {
                texto:'Grave',
                value:1,
                otrosValores: [9],
                activo: false,
                /*activo: (seleccionBusqueda.filtros ? 
                    (seleccionBusqueda.filtros.find(x => x.nombre === 'GRAV' && x.valor ===1) ? true : false)
                    : false),*/
                nivel:1,
            },
            {
                texto:'Moderada',
                value:2,
                otrosValores: [],
                activo: false,
                /*activo: (seleccionBusqueda.filtros ? 
                    (seleccionBusqueda.filtros.find(x => x.nombre === 'GRAV' && x.valor ===2) ? true : false)
                    : false),*/
                nivel:1,
            },
            {
                texto:'Leve',
                value:3,
                otrosValores: [],
                activo: false,
                /*activo: (seleccionBusqueda.filtros ? 
                    (seleccionBusqueda.filtros.find(x => x.nombre === 'GRAV' && x.valor ===3) ? true : false)
                    : false),*/
                nivel:1,
            },
        ]
    },
    {
        idGrupo : 'MED',
        nombre : 'Medidas a tomar',
        tipo: 'Check',
        excluyente : false,
        campolista: 'IdMedidas',
        tipoCampolista: 'int',
        abierto: true,
        valores : [
            {
                texto:'Evitar asociación',
                value:0,
                otrosValores: [],
                activo: false,
                /*activo: (seleccionBusqueda.filtros ? 
                    (seleccionBusqueda.filtros.find(x => x.nombre === 'MED' && x.valor ===0) ? true : false)
                    : false),*/
                nivel:1,
            },
            {
                texto:'Vigilar al enfermo',
                value:1,
                otrosValores: [],
                activo: false,
                /*activo: (seleccionBusqueda.filtros ? 
                    (seleccionBusqueda.filtros.find(x => x.nombre === 'MED' && x.valor ===1) ? true : false)
                    : false),*/
                nivel:1,
            },
            {
                texto:'Espaciar administración',
                value:2,
                otrosValores: [],
                activo: false,
                /*activo: (seleccionBusqueda.filtros ? 
                    (seleccionBusqueda.filtros.find(x => x.nombre === 'MED' && x.valor ===2) ? true : false)
                    : false),*/
                nivel:1,
            },
            {
                texto:'Tomar medidas dietéticas',
                value:3,
                otrosValores: [],
                activo: false,
                /*activo: (seleccionBusqueda.filtros ? 
                    (seleccionBusqueda.filtros.find(x => x.nombre === 'MED' && x.valor ===3) ? true : false)
                    : false),*/
                nivel:1,
            },
        ]
    },
]

export const paginasRestringidas = [
    "BLaboratorioMUH",
    "BLaboratorioMUV",
    "BLaboratorioPPF",
    "BAgrupHomoAutoMUH",
    "BAgrupHomoMUH",
    "BAgrupHomoAutoPPF",
    "BAgrupHomoPPF",
    "BFormaFarmaMUH",
    "BFormaFarmaMUV",
    "BFormaFarmaPPF",
    "BAFMUH",
    "BAMMUH",
    "BActPPF",
    "BAFMUV",
    "BAnalisisMUH",
    "BFarmacogeneticaMUH",
    //"BPAMUH",
    //"BAsoMUH",
    //"BPAMUV",
    //"BAsoMUV",
    //"BIngPPF",
    "BTipoPPF",
    "BSubtipoPPF",
    "BIndicacionMUH",
    "BIndicacionMUV",
    "BContraindicacionMUH",
    "BPrecaucionMUH",
    "BReaccionAdversaMUH",
    "BGTATCMUH",
    "BGTATCMUV",
    "BGTVMPPMUH",
    "BGTConPPF",
    "BGTSanPPF",
    "BFormMagistralMUH",
    "BMedEspanolesMUH",
    "BConGluten",
    "BConLactosa",
    "BSinGluten",
    "BSinLactosa",
    "BDopantesMUH",
    "BNoLentes",
    "BComparaPrecioMenorMUH",
    "BPrecioNotificadoMUH",
    "BSeguimientoAdicionalMUH",
    "BDispositivoSeguridadMUH",
    "BLibroRecetarioM",
    "BLibroEstupefacientesM",
    "BNotificacionESVACMUV",
    "BEnfermedadesPATH",
    "BEnfermedadesPATV",
    "BNoSustituiblesMUH",
    "BDeduccionesRDLMUH",
    "BCambioPrecioPrevistoMUH",
    "BSaborEdadPPF",
    "BProblemasSumMUH",
    "BAlertasCMUH",
    "BAlertasCMUV",
    "BAlertasCPPF",
    "BCambioNombre",
    "BCambioLaboratorio",
    "BCambioPrecioMUH",
    "BDesfinanciadosMUH",
    "BMedicamentosPSUltimoMes",
    "BExtranjerosEXT",
    "BEspecieIndicacionMUV",
    "BReaccionesAdversasMUH",
    "BComposicionMUH",
    "BComposicionMUV",
    "BComposicionPPF",
    "BPacienteIndicacionMUH",
    "BFormaColorMUH",
    "BAvanzadaMUH",
    "BAvanzadaMUV",
    "BAvanzadaPAH",
    "BAvanzadaPPF",
    "EditaBuscadores",
    "FBLaboratorioMUH",
    "FBLaboratorioMUV",
    "FBLaboratorioPPF",
    "FBAgrupHomoAutoMUH",
    "FBAgrupHomoMUH",
    "FBAgrupHomoAutoPPF",
    "FBAgrupHomoPPF",
    "FBFormaFarmaMUH",
    "FBFormaFarmaMUV",
    "FBFormaFarmaPPF",
    "FBAFMUH",
    "FBAMMUH",
    "FBActPPF",
    "FBAFMUV",
    "FBAnalisisMUH",
    "FBFarmacogeneticaMUH",
    "FBGTATCMUH",
    "FBGTATCMUV",
    "FBGTVMPPMUH",
    "FBGTConPPF",
    "FBGTSanPPF",    
    "FBSaborEdadPPF",
    "FBEspecieIndicacionMUV",
    "FBProblemasSumMUH",
    "FBAlertasCMUH",
    "FBAlertasCMUV",
    "FBAlertasCPPF",
    "FBReaccionesAdversasMUH",
    "FBComposicionMUH",
    "FBComposicionMUV",
    "FBComposicionPPF",
    "FBPacienteIndicacionMUH",
    "FBFormaColorMUH",                    
    "FBAvanzadaMUH",
    "FBAvanzadaMUV",
    "FBAvanzadaPAH",
    "FBAvanzadaPPF",
    "FBExtranjerosEXT",
    "FBCambioNombre",
    "FBCambioLaboratorio",
    "FBCambioPrecioMUH",
    "FBDesfinanciadosMUH",
    "BInteraccionesMUH",
    "BInteraccionesPPF",
    "BMedsHomeoMUH",
    "BProductosIndicacionPPF",
    "FBMedsHomeoMUH",
    "FBProductosIndicacionPPF",
    "BComparaPrecioMasBajoMUH"
]

export const paginasRestringidasEST = [
    //"BLaboratorioMUH",
    "BLaboratorioMUV",
    "BLaboratorioPPF",
    "BAgrupHomoAutoMUH",
    "BAgrupHomoMUH",
    "BAgrupHomoAutoPPF",
    "BAgrupHomoPPF",
    //"BFormaFarmaMUH",
    "BFormaFarmaMUV",
    "BFormaFarmaPPF",
    //"BAFMUH",
    //"BAMMUH",
    "BActPPF",
    "BAFMUV",
    //"BAnalisisMUH",
    //"BFarmacogeneticaMUH",
    //"BPAMUH",
    //"BAsoMUH",
    "BPAMUV",
    "BAsoMUV",
    "BIngPPF",
    "BTipoPPF",
    "BSubtipoPPF",
    //"BIndicacionMUH",
    "BIndicacionMUV",
    //"BContraindicacionMUH",
    //"BPrecaucionMUH",
    //"BReaccionAdversaMUH",
    //"BGTATCMUH",
    "BGTATCMUV",
    //"BGTVMPPMUH",
    "BGTConPPF",
    "BGTSanPPF",
    "BFormMagistralMUH",
    //"BMedEspanolesMUH",
    //"BConGluten",
    //"BConLactosa",
    //"BSinGluten",
    //"BSinLactosa",
    //"BDopantesMUH",
    //"BNoLentes",
    "BComparaPrecioMenorMUH",
    "BPrecioNotificadoMUH",
    "BSeguimientoAdicionalMUH",
    "BDispositivoSeguridadMUH",
    "BLibroRecetarioM",
    "BLibroEstupefacientesM",
    "BNotificacionESVACMUV",
    //"BEnfermedadesPATH",
    "BEnfermedadesPATV",
    "BNoSustituiblesMUH",
    "BDeduccionesRDLMUH",
    "BCambioPrecioPrevistoMUH",
    "BSaborEdadPPF",
    "BProblemasSumMUH",
    "BAlertasCMUH",
    "BAlertasCMUV",
    "BAlertasCPPF",
    "BCambioNombre",
    "BCambioLaboratorio",
    "BCambioPrecioMUH",
    "BDesfinanciadosMUH",
    "BMedicamentosPSUltimoMes",
    //"BExtranjerosEXT",
    "BEspecieIndicacionMUV",
    //"BReaccionesAdversasMUH",
    //"BComposicionMUH",
    "BComposicionMUV",
    "BComposicionPPF",
    //"BPacienteIndicacionMUH",
    //"BFormaColorMUH",
    "BAvanzadaMUH",
    "BAvanzadaMUV",
    //"BAvanzadaPAH",
    "BAvanzadaPPF",
    //"EditaBuscadores",
    //"FBLaboratorioMUH",
    "FBLaboratorioMUV",
    "FBLaboratorioPPF",
    "FBAgrupHomoAutoMUH",
    "FBAgrupHomoMUH",
    "FBAgrupHomoAutoPPF",
    "FBAgrupHomoPPF",
    //"FBFormaFarmaMUH",
    "FBFormaFarmaMUV",
    "FBFormaFarmaPPF",
    //"FBAFMUH",
    //"FBAMMUH",
    "FBActPPF",
    "FBAFMUV",
    //"FBAnalisisMUH",
    //"FBFarmacogeneticaMUH",
    //"FBGTATCMUH",
    "FBGTATCMUV",
    //"FBGTVMPPMUH",
    "FBGTConPPF",
    "FBGTSanPPF",    
    "FBSaborEdadPPF",
    "FBEspecieIndicacionMUV",
    "FBProblemasSumMUH",
    "FBAlertasCMUH",
    "FBAlertasCMUV",
    "FBAlertasCPPF",
    //"FBReaccionesAdversasMUH",
    //"FBComposicionMUH",
    "FBComposicionMUV",
    "FBComposicionPPF",
    //"FBPacienteIndicacionMUH",
    //"FBFormaColorMUH",                    
    "FBAvanzadaMUH",
    "FBAvanzadaMUV",
    //"FBAvanzadaPAH",
    "FBAvanzadaPPF",
    //"FBExtranjerosEXT",
    "FBCambioNombre",
    "FBCambioLaboratorio",
    "FBCambioPrecioMUH",
    "FBDesfinanciadosMUH",
    //"BInteraccionesMUH",
    "BInteraccionesPPF",
    "BMedsHomeoMUH",
    "BProductosIndicacionPPF",
    "FBMedsHomeoMUH",
    "FBProductosIndicacionPPF",
    "BComparaPrecioMasBajoMUH"
]

export const seccionesFichaRestringidasEST = [
    "PSUM",
    "HIST",
    "ALER",
    "CMED"
]

export function validaAccesoPorRol(pagina, rol) {
    var res = true;
    if (rol === "COL"){
        if (paginasRestringidas.includes(pagina)){
            res = false;
        }
    }else if (rol === "EST"){
        if (paginasRestringidasEST.includes(pagina)){
            res = false;
        }
    }
    return res;
}
