import { useEffect } from 'react/cjs/react.development';
import { IconosListadosPI } from '../../../../services/objsAplicacion.js';
import {numberWithCommas, rootPath} from '../../../../services/utils.js'

function FilaListaFM(props){
    const item = props.item;
    const literalCodigo =  "Cód. Nacional:";
    const pictogramatipo =  rootPath + "img/person.svg" ;
    const vesAFicha = (it) =>{
        props.cambiaPagina({
            newpagina: "FichaFM",
            newid: it.Id,
            filtros: props.filtros,
            numMostrar: props.numMostrar,
            idxOrden: props.idxOrden,
        });
    }

    const ancho = window.innerWidth;
    const isMobile = (ancho<768);
    var txtFina = "";
    var txtApor = "";
    var txtVisa = "";

    if (item.Financiado==="S")
        txtFina = "Sí";
    else if (item.Financiado === "X")
        txtFina = "Según indicación";
    else if (item.Financiado === "N")
        txtFina = "No";

    if (item.AuxStr==="AR")
        txtApor = "Reducida";
    else if (item.AuxStr === "AN")
        txtApor = "Normal";

    if (item.AuxStr2==="S")
        txtVisa = "Sí";
    else if (item.AuxStr2 === "N")
        txtVisa = "No";

    return (
        <div class="item " > 
            <div class="product">
                <div class="mask" onClick={() => vesAFicha(props.item)} style={{cursor:'pointer'}}>
                    <img src={pictogramatipo} alt={item.TipoRegistro} />
                </div>
                <div class="box box__text" >
                    <div class="title" >
                        <h3 class="fs fs17 blackColor" >
                            <span title={item.NombreCompleto}  onClick={() => vesAFicha(props.item)} style={{cursor:'pointer'}}>{item.NombreCompleto}</span>
                        </h3>
                    </div>
                    <div class="info" >
                        <p class="fs fs15 purpleColor tag"  style={{width:'50%'}}>{item.TipoRegistro}</p>
                        <div class="product__right" style={{width:'50%'}}>
                        <div class="module module__bottom" >
                            <span class="codigo codigo__title" ></span>
                            <div class="codigo codigo__number" >{item.Codigo}</div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="product__right" >
                <div class="module module__top" >
                    {
                        ((item.PVPIVA > 0) ?
                            <div class="precio" style={{width:'35%'}} >
                                <p class="fs fs12 greyColor17 iva" >PVP con IVA</p>
                                {
                                    (((item.PVPIVA>999999.99) || (item.PVPIVA>9999.99 && props.comoGrid)) ?
                                        <span class="fs fs19 purpleColor precio__item" >{numberWithCommas(item.PVPIVA)}</span>
                                    : ((item.PVPIVA>9999.99) || (item.PVPIVA>999.99 && props.comoGrid)) ?
                                        <span class="fs fs21 purpleColor precio__item" >{numberWithCommas(item.PVPIVA)}</span>
                                    :
                                        <span class="fs fs23 purpleColor precio__item" >{numberWithCommas(item.PVPIVA)}</span>
                                    )
                                }
                                <p class="fs fs12 greyColor17" >EUR</p>
                            </div>
                        :
                            <div class="precio" style={{width:'35%'}} >
                                <p class="fs fs12 greyColor17 iva" >PVP con IVA</p>
                                    <span class="fs fs23 purpleColor precio__item" >-</span>
                            </div>
                        )
                    }
                    <div class="estado" style={{width:'65%'}}>
                        <div class="module module__bottom module__bottom__code">
                            <span class="codigo codigo__title"  style={{width:'35%'}}>Financiado:</span>
                            <div class="codigo codigo__number" style={{border:'none', letterSpacing:0, textAlign: 'left',width:'65%'}} >{txtFina}</div>
                        </div>
                        <div class="module module__bottom module__bottom__code" >
                            <span class="codigo codigo__title" style={{width:'35%'}}>Aportación:</span>
                            <div class="codigo codigo__number" style={{border:'none', letterSpacing:0, textAlign: 'left',width:'65%'}}>{txtApor}</div>
                        </div>
                        <div class="module module__bottom module__bottom__code" >
                            <span class="codigo codigo__title" style={{width:'35%'}}>Visado:</span>
                            <div class="codigo codigo__number" style={{border:'none', letterSpacing:0, textAlign: 'left',width:'65%'}}>{txtVisa}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    )    
}

export default FilaListaFM;
