import { useState } from "react";
import { useEffect } from "react";
import Selector from "../Selector";
import SelectorComboBox from "../SelectorComboBox";

function SelectorEdad(props){
    const [seleccion, setSeleccion] = useState({
        edad: props.edad,
        unidad: props.unidad,
    });
    const edad = seleccion.edad;

    
    useEffect(() => {
        setSeleccion({
            edad: props.edad,
            unidad: props.unidad,
        })
        
    },  [props.edad, props.unidad]);

    const cambioEdad = (e) =>{
        var item = e.target
        var value = item.value;
        var ivalue = parseInt(value);
        cambiaSeleccion(ivalue, seleccion.unidad);
    }

    const cambioUnidad = (newunidad) =>{
        cambiaSeleccion(seleccion.edad, newunidad)
    }

    const cambiaSeleccion = (edad, unidad) =>{
        props.alCambiar(edad, unidad);
    }

    return (
        <div class="wrap wrap__2">
            <div class="module" >
                <div class="cover cover__input" >
                    <input type="number" id="input_edad" value={seleccion.edad>0 ? seleccion.edad : ''} 
                            name="" min="0" max="100" 
                            className = "input__sel__num"
                            onChange={(e) => cambioEdad(e)}/>
                </div>
            </div>
            <SelectorComboBox id="MesesAnoSaborEdad"
                    lista={[{codigo:'-', valor:''},{codigo:'A', valor:'años'},{codigo:'M', valor:'meses'}]}
                    valueSelected={seleccion.unidad}
                    ancho='40%'
                    alCambiar= {cambioUnidad}
                    idEsStr = {true}
                    ></SelectorComboBox>
        </div>
    )
}
export default SelectorEdad;
/*
                    <div class="arrows" >
                        <span class="arrow arrow__up" ></span>
                        <span class="arrow arrow__down" ></span>
                    </div>
*/