import { AddClassNameToClassItems, FormatoFechaDDMMYYYY, PerfilUsuario, numberWithCommas, rootPath } from "../../../../../services/utils";
import Emergente from "../Cismed/Emergente";
import BloqueNombreValor from "./BloqueNombreValor";
import BloquePrecios from "./BloquePrecios";

const InfoGeneral = (props) => {
    const ficha = props.ficha;
    const tooltipMaxEnvases = 
        <div>
            <p class="fs fs16 FlamaBook blackColor" style={{whiteSpace: 'pre-line', textAlign: 'justify',}} >
            Este número hace referencia a los envases máximos a dispensar en <span style={{fontWeight:"bold"}}>receta en soporte papel</span>, 
            tal y como se recoge en el artículo 5 del Real Decreto 1718/2010. 
            Se recuerda que en el caso de <span style={{fontWeight:"bold"}}>receta electrónica no se ha establecido una limitación en el 
            número máximo de envases que se pueden prescribir por receta</span>, si bien hay una limitación en
            el periodo máximo de prescripción (1 año), así como en la cantidad máxima a dispensar por acto 
            (inferior a un mes de tratamiento, salvo que el formato a dispensar conforme a la prescripción 
            supere dicho periodo según su ficha técnica).
            </p>
        </div>
    ;
    const tooltipMaxEnvasesDieto = 
        <div>
            <p class="fs fs16 FlamaBook blackColor" style={{whiteSpace: 'pre-line', textAlign: 'justify',}} >
            Siempre y cuando no supere el mes de tratamiento.
            </p>
        </div>
    ;
    const bloque1H = [
        {
            nombre : 'Código Nacional',
            valor: ficha.Codigo,
            link: null,
        },
        {
            nombre : 'Nº de registro',
            valor: ficha.NumRegistro,
            link: null,
        },
        {
            nombre : 'Código EAN13',
            valor: ficha.CodEAN13,
            link: null,
        },
        {
            nombre : 'Max. envases por receta',
            valor: ficha.MaxEnvReceta,
            link: null,
            tooltip: tooltipMaxEnvases,
            altotooltip: '360px'
        },
    ]
    const bloque1V = [
        {
            nombre : 'Código Nacional',
            valor: ficha.Codigo,
            link: null,
        },
        {
            nombre : 'Nº de registro',
            valor: ficha.NumRegistro,
            link: null,
        },
        {
            nombre : 'Código EAN13',
            valor: ficha.CodEAN13,
            link: null,
        },
        {
            nombre : 'IVA Aplicable',
            valor: ficha.IVA>0 ? numberWithCommas(ficha.IVA) + ' %' : '',
            link: null,
        },
    ]

    var bloque1PF = [
        {
            nombre : 'Código Nacional',
            valor: ficha.Codigo,
            link: null,
        },
        {
            nombre : 'Tipo de producto',
            valor: ficha.TipoPF,
            link: 'BTipoPPF',
            id: ficha.IdTipoPF
        },
        {
            nombre : ficha.LiteralSubtipo,
            valor: ficha.SubtipoPF,
            link: 'BSubtipoPPF',
            id: ficha.IdSubtipoPF
        },
        {
            nombre : ficha.LiteralCodigoRegistro,
            valor: ficha.NumRegistro,
            link: null,
        },
    ]

    if (ficha.CodTipoRegistro == "PPF"){
        const idTiposConNumRegPF = [ 1, 6, 12, 13, 23, 24, 25 ];
        if (!idTiposConNumRegPF.includes(ficha.IdTipoPF))
            bloque1PF.pop();
        const tieneCodigoUdi =  ((ficha.CodTipoRegistro === "PPF") && (ficha.IdTipoPF) && (ficha.IdTipoPF === 1 || ficha.IdTipoPF === 13 || ficha.IdTipoPF === 25))
        if (tieneCodigoUdi){
            const tieneValorCodigoUdi = tieneCodigoUdi && ficha.CodUDIDI && (ficha.CodUDIDI !== '')
            if (tieneValorCodigoUdi)
            bloque1PF.push({
                nombre : 'Código UDI-DI',
                valor: ficha.CodUDIDI,
                link: null,
            })
            else{
                bloque1PF.push({
                    nombre : 'Código UDI-DI',
                    valor: '-',
                    link: null,
                })
            }
        }
    }

    if (ficha.CodTipoRegistro == "PPF" && ficha.IdTipoPF === 6){
        bloque1PF.push({
            nombre: 'Max. envases por receta',
            valor: 4,
            tooltip : tooltipMaxEnvasesDieto,
            altotooltip: '160px'
        })
    }

    var precioAuto = 0;

    if (ficha.AgrupacionCCAA)
        precioAuto = ficha.AgrupacionCCAA.PVPMenor;
    const formaFarma = (ficha.FormaFarma)  ? ficha.FormaFarma.Formafarmaceutica : '';
    const via = (ficha.FormaFarma) ? ficha.FormaFarma.Via : '';

    const bloque1FM = [
        {
            nombre : 'Código Nacional',
            valor: ficha.Codigo,
            link: null,
        },        
        {
            nombre : (ficha.IdCCAA===5 || ficha.IdCCAA===18 || ficha.IdCCAA===19 ? "PVP" : "PVP con IVA"),
            valor: (precioAuto>0) ? numberWithCommas(precioAuto) + ' EUR': '',
            link: null,
        },
        {
            nombre : 'IVA Aplicable',
            valor: ficha.IVA>0 ? numberWithCommas(ficha.IVA) + ' %' : '',
            link: null,
        },
        {
            nombre : 'Forma farmacéutica',
            valor: formaFarma,
            link: null,
        },
        {
            nombre : 'Vía de administración',
            valor: via,
            link: null,
        },
    ]
    const lab = (ficha.Laboratorio) ? ficha.Laboratorio.Nombre : '';
    const idLab = (ficha.Laboratorio) ? ficha.Laboratorio.Id : 0;

    const bloque1HO = [
        {
            nombre : 'Código Nacional',
            valor: ficha.Codigo,
            link: null,
        },        
        {
            nombre : 'Nº de registro',
            valor: ficha.NumRegistro,
            link: null,
        },
        {
            nombre : 'Forma farmacéutica',
            valor: formaFarma,
            link: null,
        },
        {
            nombre : 'Vía de administración',
            valor: via,
            link: null,
        },
        {
            nombre : 'Laboratorio',
            valor: 
            (<div style={{display: 'flex', alignItems: 'center'}}>
                <span style={{cursor:'pointer', color:'#5c9029'}} 
                    onClick={(e) => props.cambiaPagina({newpagina:'BLaboratorio'+ficha.CodTipoRegistro, newid:idLab})}
                    onMouseOver={(e) => e.target.style.fontWeight='bold'}
                    onMouseOut={(e) => e.target.style.fontWeight='normal'}
                >{lab.toUpperCase()}&nbsp;&nbsp;&nbsp;</span>
                <span style={{cursor:'pointer'}}  float="right" onClick={(e) => abreEmergenteLabo(e)}>
                    <img src={rootPath + "img/infoGreen.svg"} alt=""/>
                </span>
            </div>),
            link: null,
            id: idLab
        },
        {
            nombre : 'Estado autorización',
            valor: (ficha.EstadoAuto) ? ficha.EstadoAuto.Descripcion : '-',
            link: null,
        },
    ]

    var bloque1 = bloque1H

    const abreEmergenteLabo = (e) => {
        e.preventDefault();
        AddClassNameToClassItems(modalKeyName, null, 'active');
    }

    
    var bloque2 = [
        {
            nombre : 'Forma farmacéutica',
            valor: formaFarma,
            link: null,
        },
        {
            nombre : 'Vía de administración',
            valor: via,
            link: null,
        },
        {
            nombre : 'Laboratorio',
            valor: 
            (<div style={{display: 'flex', alignItems: 'center'}}>
                <span style={{cursor:'pointer', color:'#5c9029'}} 
                    onClick={(e) => props.cambiaPagina({newpagina:'BLaboratorio'+ficha.CodTipoRegistro, newid:idLab})} 
                    onMouseOver={(e) => e.target.style.fontWeight='bold'}
                    onMouseOut={(e) => e.target.style.fontWeight='normal'}
                >{lab.toUpperCase()}&nbsp;&nbsp;&nbsp;</span>
                <span style={{cursor:'pointer'}}  float="right" onClick={(e) => abreEmergenteLabo(e)}>
                    <img src={rootPath + "img/infoGreen.svg"} alt=""/>
                </span>
            </div>),
            link: null,
            id: idLab
        },
    ]

    var datosLaboratorio = [
        {
            nombre : 'Dirección',
            valor: (ficha.Laboratorio && ficha.Laboratorio.Direccion) ? ficha.Laboratorio.Direccion : '-',
            link: null,
        },
        {
            nombre : 'Código postal',
            valor: (ficha.Laboratorio && ficha.Laboratorio.CodigoPostal) ? ficha.Laboratorio.CodigoPostal : '-',
            link: null,
        },
        {
            nombre : 'Localidad',
            valor: (ficha.Laboratorio && ficha.Laboratorio.Localidad) ? ficha.Laboratorio.Localidad : '-',
            link: null,
        },
        {
            nombre : 'Provincia',
            valor: (ficha.Laboratorio && ficha.Laboratorio.Provincia) ? ficha.Laboratorio.Provincia : '-',
            link: null,
        },
        {
            nombre : 'País',
            valor: (ficha.Laboratorio && ficha.Laboratorio.Pais) ? ficha.Laboratorio.Pais : '-',
            link: null,
        },
        {
            nombre : 'Teléfono',
            valor: (ficha.Laboratorio && ficha.Laboratorio.Telefono) ? ficha.Laboratorio.Telefono : '-',
            link: null,
        },
        {
            nombre : 'Fax',
            valor: (ficha.Laboratorio && ficha.Laboratorio.Fax) ? ficha.Laboratorio.Fax : '-',
            link: null,
        },
        {
            nombre : 'Email',
            valor: (ficha.Laboratorio && ficha.Laboratorio.Email) ? ficha.Laboratorio.Email : '-',
            link: null,
        },
        {
            nombre : 'Web',
            valor: (ficha.Laboratorio && ficha.Laboratorio.Observaciones) ? ficha.Laboratorio.Observaciones.toLowerCase() : '-',
            link: null,
        },
    ]

    if (ficha.Laboratorio && ficha.Laboratorio.IdPais===1)
        datosLaboratorio.splice(4,1);

    var bloque2PF = [
        {
            nombre : 'Forma farmacéutica',
            valor: formaFarma,
            link: null,
        },
        {
            nombre : 'Vía de administración',
            valor: via,
            link: null,
        },
        {
            nombre : 'Laboratorio',
            valor: 
            (<div style={{display: 'flex', alignItems: 'center'}}>
                <span style={{cursor:'pointer', color:'#5c9029'}} 
                    onClick={(e) => props.cambiaPagina({newpagina:'BLaboratorioPPF', newid:idLab})} 
                    onMouseOver={(e) => e.target.style.fontWeight='bold'}
                    onMouseOut={(e) => e.target.style.fontWeight='normal'}
                >{lab.toUpperCase()}&nbsp;&nbsp;&nbsp;</span>
                    <span style={{cursor:'pointer'}}  float="right" onClick={(e) => abreEmergenteLabo(e)}>
                        <img src={rootPath + "img/infoGreen.svg"} alt=""/>
                    </span>
            </div>),
            link: null,
            id: idLab
        },
        {
            nombre : 'Estéril',
            valor: ficha.Esteril,
            link: null,
        },
    ]

    if (ficha.CodTipoRegistro == "PPF"){
        const idTiposConEsteril = [ 1, 13, 25 ];
        if (!idTiposConEsteril.includes(ficha.IdTipoPF)){
            bloque2PF.pop();
        }

        const idTiposConSubtipo = [ 1, 3, 6, 12, 13, 23, 25 ];
        if (!idTiposConSubtipo.includes(ficha.IdTipoPF)){
            //bloque2PF.splice(4,1);
            bloque1PF.splice(2,1);
        }
            
        const idTiposSinFF = [ 24 ];
        if (idTiposSinFF.includes(ficha.IdTipoPF)){
            bloque2PF.splice(0,2);
        }
    }

    var bloque3 = [
        {
            nombre : 'Estado autorización',
            valor: (ficha.EstadoAuto) ? ficha.EstadoAuto.Descripcion : '-',
            link: null,
        },
        {
            nombre : 'Estado Nomenclátor',
            valor: ficha.EstadoNom ? ficha.EstadoNom.Descripcion : '-',
            link: null,
        },
        {
            nombre : 'Fecha autorización',
            valor: (ficha.FechaAutorizacion && (new Date(ficha.FechaAutorizacion)).getTime()>(new Date('01/01/1900')).getTime() ? FormatoFechaDDMMYYYY(ficha.FechaAutorizacion) : '-'),
            link: null,
        },
    ]


    const fecha0 = new Date(1900,1,1).toJSON();
    if ((ficha.FechaComercializacion > fecha0) && (ficha.IdEstadoAuto !== 3))
        bloque3.push(
            {
                nombre : 'Fecha comercialización',
                valor: FormatoFechaDDMMYYYY(ficha.FechaComercializacion),
                link: null,
            },
        )

    if (ficha.FechaInclusionSNS > fecha0)
        bloque3.push(
            {
                nombre : 'Fecha inclusión en SNS',
                valor: FormatoFechaDDMMYYYY(ficha.FechaInclusionSNS),
                link: null,
            },
        )


    if (ficha.FechaBajaInclusionFinanIndiv > fecha0)
        bloque3.push(
            {
                nombre : 'Fecha baja exclusión financiación individualizada',
                valor: FormatoFechaDDMMYYYY(ficha.FechaBajaInclusionFinanIndiv),
                link: null,
            },
        )

    if (ficha.CodTipoRegistro==="PPF"){
        bloque1 = bloque1PF;
        bloque2 = bloque2PF;
        bloque3.splice(0,1);
        bloque3[0].nombre = "Estado comercialización";
        bloque3[1].nombre = "Fecha de alta";
        if (ficha.IdEstadoNomPF>0 && ficha.EstadoNomPF){
            bloque3.splice(1,0,{
                nombre : 'Estado Nomenclátor',
                valor: ficha.EstadoNomPF.Descripcion,
                link: null,
            });
            if  (ficha.FechaBajaEfectoYAccesorio > fecha0){
                bloque3.push({
                    nombre : 'Fecha baja Efectos y Accesorios',
                    valor: FormatoFechaDDMMYYYY(ficha.FechaBajaEfectoYAccesorio),
                    link: null,
                });
            }
        }
    }

    if (ficha.FechaExclusionSNS > fecha0)
        bloque3.push(
            {
                nombre : 'Fecha exclusión SNS',
                valor: FormatoFechaDDMMYYYY(ficha.FechaExclusionSNS),
                link: null,
            },
        )

    if (ficha.FechaBaja > fecha0)
        bloque3.push(
            {
                nombre : (ficha.IdEstadoAuto === 1) ? 'Fecha de suspensión':'Fecha de baja',
                valor: FormatoFechaDDMMYYYY(ficha.FechaBaja),
                link: null,
            },
        )

    if (ficha.CodTipoRegistro==="MUV"){        
        bloque1 = bloque1V;
        //bloque2[2].link = "BLaboratorioMUV"
        bloque3.splice(1,1);
    }
    
    if (ficha.CodTipoRegistro==="FM" || ficha.CodTipoRegistro === "PO" || ficha.CodTipoRegistro === "VI")
    {
        bloque1 = bloque1FM;
        bloque2 = null;
        bloque3 = null;
    }

    if (ficha.CodTipoRegistro==="MHO")
    {
        bloque1 = bloque1HO;
        bloque2 = null;
        bloque3 = null;
    }

    var ocultaPrecios = (ficha.CodTipoRegistro === "MUV") || (ficha.CodTipoRegistro === "FM") ||(ficha.CodTipoRegistro === "PO")||(ficha.CodTipoRegistro === "XMHO")||(PerfilUsuario()==="EST");

    if ((ficha.CodTipoRegistro === "PPF")){
        const idTiposConPreciosPF = [ 1, 6, 13, 25 ];
        ocultaPrecios = !(idTiposConPreciosPF.includes(ficha.IdTipoPF)) && (!ficha.IVA || ficha.IVA === 0.0)
    }

    const modalTitulo = (ficha.Laboratorio) ? ficha.Laboratorio.NombreLargo : '';
    const estiloEmergenteLabo = {borderBottom: 'none'}
    const modalContent = (
        <BloqueNombreValor bloque={datosLaboratorio} conValoresNumericos={false} cambiaPagina={props.cambiaPagina} estiloEspecial={estiloEmergenteLabo}></BloqueNombreValor>    
    );

    const modalKeyName="ModalDatosLab"

    return (
        <div>
            <Emergente contenido={modalContent} keyName={modalKeyName} titulo={modalTitulo} ></Emergente>
            <BloqueNombreValor bloque={bloque1} conValoresNumericos={true} cambiaPagina={props.cambiaPagina}></BloqueNombreValor>
            {(bloque2) ?
                <BloqueNombreValor bloque={bloque2} conValoresNumericos={false} cambiaPagina={props.cambiaPagina}></BloqueNombreValor>
            :
                <div></div>
            }
            {ocultaPrecios  ?
                <div></div>
            :
                <BloquePrecios ficha={ficha} abreEmergenteInfoPrecio={props.abreEmergenteInfoPrecio}></BloquePrecios>
            }
            {(bloque3) ?
                <div class="infoGeneral__precios">
                    <div class="info_autorizacion">
                        <h3 class="titulo">Información sobre autorización y comercialización</h3>
                        <BloqueNombreValor bloque={bloque3} sonValoresNumericos={false} cambiaPagina={props.cambiaPagina}></BloqueNombreValor>
                    </div>
                </div>
            :
                <div></div>
            }
        </div>
    )
}
export default InfoGeneral;
