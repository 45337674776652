import { useEffect } from "react";
import { AddClassNameToChildClassItems, AddClassNameToClassItems, buscaOptionEnSelectByValue, createElementFromHTML, elementIsOfClass, ExistsClassNameChildClassItems, GetAntecesorWhithClassName, getSelectValues, GetSucesorWhithClassName, primerHijoConClase, RemoveClassNameToChildClassItems, RemoveClassNameToClassItems, wrap } from "../../../services/utils";

function Selector(props){
    const id = props.id; 
    const lista = props.lista;
    const valueSelected = props.valueSelected;
    const options = lista.map(item => (item.codigo === valueSelected ? 
        <option selected value={item.codigo}>{item.valor}</option>
        :<option value={item.codigo}>{item.valor}</option> )
    );
    const colorTexto = (props.disabled ? {color:'#AAAAAA'} : {});

    const optionsDisplayed = lista.map(item => <label class="labeloption" data-value={item.codigo} onClick={(e) => ClickEnDropdownLabel(e)}>{item.valor}</label>);

    const clasesCoverSelect = (props.disabled ? "cover cover__select cover__select cover__select__border disabled" : "cover cover__select cover__select cover__select__border");
    var textSelected = '';
    if (lista)
        if (lista.length>0)
            textSelected = lista[0].valor;
    for (var item of lista){
        if (item.codigo===valueSelected)
            textSelected = item.valor;
    }
    
   
    useEffect(() => {
        /*const select = document.getElementById(id);
        IniciaCustomSelect(select, id);*/
    },  []);


    const ClickEnCoverSelect  = (e) =>{
        if (!props.disabled){
            const item = e.target;        
            const itemContainer = GetAntecesorWhithClassName(item, 'custom-select-container');
            const valor = item.getAttribute('data-value');
            itemContainer.classList.toggle('active');
            const select = GetSucesorWhithClassName(itemContainer,'custom-select');
        }
    }

    const ClickEnDropdownLabel  = (e) =>{
    	var option = e.target;
        var value = option.getAttribute("data-value");
        var html = option.innerHTML;
        var active = (elementIsOfClass(option, "active")) ? true : false;

        var container = GetAntecesorWhithClassName(option, "custom-select-container");
        var select = primerHijoConClase(container,'custom-select');
        var multiple = select.hasAttribute('multiple');

        var id_tag = select.getAttribute("name") + "-" + value;

        var extra_subfield = (select.hasAttribute("data-show-subfield")) ? select.getAttribute("data-show-subfield") : false;

        var target = (select.hasAttribute("data-target")) ? select.getAttribute("data-target") : false;
        var target_label = (select.hasAttribute("data-target-label")) ? select.getAttribute("data-target-label") : false;

        //container.find('.custom-select option[value="' + value + '"]').prop("selected", !active);
        var optionWithValue = buscaOptionEnSelectByValue(select,value);
        if (active)
            optionWithValue.removeAttribute('selected');
        else
            optionWithValue.setAttribute('selected', '');

        if(!multiple && !target){
        	//SIMPLE SELECT
            RemoveClassNameToChildClassItems(container, 'dropdown__radios', 'labeloption', 'active');
        	container.classList.remove("active");

        	if(!active){
                var aux = primerHijoConClase(container, 'select-label');
        		aux.innerHTML = html;
	        	option.classList.add("active");
	        }else{
                var aux2 = primerHijoConClase(container, 'select-label');
                aux2.innerHTML = container.getAttribute('data-placeholder');
	        }
            select.value=value;
            if (props.alCambiar){
                props.alCambiar(value);
            }
        }else{
	        if(!multiple){
                document.getElementById(target).innerHTML="";
                RemoveClassNameToChildClassItems(container, 'dropdown__radios', 'labeloption', 'active');
	        	option.classList.add("active");
	        }else{
	        	//MULTIPLE SELECT
	        	if(!active){
		        	option.classList.add("active");
		        }else{
		        	option.classList.remove("active");
		        }
	        }

	        if(!(ExistsClassNameChildClassItems(container,'dropdown','labeloption','hide') || ExistsClassNameChildClassItems(container,'dropdown','labeloption','active')))
                RemoveClassNameToChildClassItems(container,'dropdown',null,'show');

	        var cloned = option.cloneNode(true);
            var htmlToAdd = '<span class="tag" id="'+id_tag+'" data-value="'+cloned.data("value")+'">' + cloned.innerHTML + '<img class="arrow imagenTagEliminar" src="./img/close-tag.svg" alt="eliminar"></span>';
            var newItem = createElementFromHTML(htmlToAdd);
            document.getElementById(target).appendChild(newItem);
        }

        if(target_label){

			var multiple_labels = "";
            var seleccionados = getSelectValues(select);
            for (var elemento of seleccionados) {
	        	multiple_labels += elemento.innerHTML+", ";
	        };

	        if(multiple_labels != ""){
	        	document.getElementById(target_label).innerHTML = multiple_labels.slice(0, -2);
	        }else{
	        	document.getElementById(target_label).innerHTML = "";
	        }
		}

        var event = new Event('change');
        select.dispatchEvent(event);

        if(extra_subfield && document.getElementById(target).children.length){//si tiene subcampos marcamos el último tag incluido
            var tags = document.getElementById(target).getElementsByClassName('tag');
            var tag = tags[tag.length-1];
            var event2 = new Event('click');
            tag.dispatchEvent(event2);
        }
    }

    const remove_accents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } 
  
    /*container: <div class="custom-select-container '+fixed+' '+search+' '+target_class+'  '+checks+' '+style+' " id="custom-select-'+select_id+'"></div>*/
    return (
        <div class="module module__selects">
            <div class="selects" >
                <div class="custom-select-container" id="custom-select-'+select_id+'">
                    <select class="custom-select" id={id} style={{display:"none"}}  >
                        {options}
                    </select>
                    <div class={clasesCoverSelect} onClick={(e) => ClickEnCoverSelect(e)}>
                        <div class="select-label" style={colorTexto}>
                            {textSelected}
                        </div>
                    </div>
                    <div class="dropdown dropdown__radios">
                        {optionsDisplayed}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Selector;

/*
    function IniciaCustomSelect(select_element, select_id){
    	var select = select_element;
		var multiple = select.hasAttribute('multiple');
		var search = "";
		var fixed = "";
		var target = false, target_box = false, target_class = ""
		var checks = "";
		var style = "";

		if(select.hasAttribute("data-search")){
			search = "search";
		}
		if(select.hasAttribute("data-fixed")){
			fixed = "fixed";
		}
		if(select.hasAttribute("data-checks")){
			checks = "has-checks";
		}
		if(select.hasAttribute("style")){
			style = "style-"+select.getAttribute("style");
		}
		if(select.hasAttribute("data-target")){
			target_class = 'target';
			target_box = select.getAttribute("data-target");
		}
		if(!select_id){
			select_id = Math.floor(Math.random() * (999 - 111 + 1)) + 111;
		}

		select.style.display = 'none';

        wrap(select, createElementFromHTML('<div class="custom-select-container '+fixed+' '+search+' '+target_class+'  '+checks+' '+style+' " id="custom-select-'+select_id+'"></div>'))

		var container = select.parentNode;

		if(search !== ""){
			container.prepend('<div class="cover cover__search"><img class="lupa" src="./img/ficha/lupaPurple.svg"><input class="inputtext" type="text" name="" data-placeholder="Search"></div>');
		}
        var iteminput = container.getElementsByClassName('inputtext')[0];
        if (iteminput)
            iteminput.addEventListener('keyup',KeyUpInput);

        var selected = (select.selectedIndex>=0) ? select.options[select.selectedIndex] : false;
        var placeholder = (selected) ? selected.innerHTML : select.options[0].innerHTML;

		container.setAttribute("data-placeholder", placeholder);

		container.innerHTML = container.innerHTML + '<div class="cover cover__select cover__select cover__select__border"><div class="select-label">'+placeholder+'</div></div><div class="dropdown dropdown__radios">[#options#]</div>';
        container.children[container.children.length - 2].addEventListener('click',ClickEnCoverSelect);

        var options = select.options;
		var custom_options = "";
        for (var elemento of options) {
            var option = elemento;
            if (option.getAttribute("value") !== ""){
                custom_options += '<label class="labeloption" data-value="' + option.getAttribute("value")+'">'+option.innerHTML+'</label>';
            }
        }

		container.innerHTML = container.innerHTML.replaceAll('[#options#]', custom_options);

        var labeloptions = container.getElementsByClassName('labeloption');
        for (var elemento of labeloptions) {
            elemento.addEventListener('click',ClickEnDropdownLabel);
        }
    }




/*
<div class="module module__selects" >
<div class="selects" >
    <select class="custom-select" >
        <option value="">Seleccionar Provincia</option>
        <option value="AAAAA">AAAAA</option>
        <option value="AAAA">AAAA</option>
        <option value="AAA">AAA</option>
        <option value="BBBBB">BBBBB</option>
        <option value="BBBB">BBBB</option>
        <option value="BBB">BBB</option>
        <option value="CCCCC">CCCCC</option>
        <option value="CCCC">CCCC</option>
        <option value="CCC">CCC</option>
        <option value="DDDDD">DDDDD</option>
        <option value="DDDD">DDDD</option>
        <option value="EEEEE">EEEEE</option>
        <option value="EEEE">EEEE</option>
        <option value="FFFFF">FFFFF</option>
        <option value="GGGGG">GGGGG</option>
        <option value="HHHHH">HHHHH</option>
        <option value="GGGGG">GGGGG</option>
    </select>
</div>
</div>
*/

/*
    const KeyUpInput = (e) =>{
		var input = e.target;
        const container = GetAntecesorWhithClassName(input, 'custom-select-container')[0];

        var search = remove_accents(input.value.toLowerCase());

    	var found = false;

    	if(search != ""){
            var labeloptions = container.getElementsByClassName('labeloption');
            for (var elemento of labeloptions) {
	    		var label = elemento;
	    		var html = remove_accents(label.innerHTML.toLowerCase());
	    		if(html.indexOf(search) > -1) {
	    			found = true;
					label.classList.remove("hide");
				}else{
					label.classList.add("hide");
				}
            }    
    	}
    	

    	if(found){
            AddClassNameToChildClassItems(container,'dropdown',null,'show');
            var ddowns = container.getElementsByClassName('ddown');
            for (var elemento of ddowns) {
                var hijos = elemento.children;
                var hijosNiOcultosNiActivos = false;
                for (var hijo of hijos){
                    if (elementIsOfClass('labeloption')){
                        if (!elementIsOfClass('hide') && !elementIsOfClass('active')){
                            hijosNiOcultosNiActivos = true;
                        }
                    }
                }
                if (!hijosNiOcultosNiActivos){
                    elemento.classList.remove('show');
                }
            }
    	}else{
            RemoveClassNameToChildClassItems(container,'dropdown',null,'show');
    	}
    }
*/
