import React, { useState, useEffect } from "react";
import BP2022Service, {GetBusquedasPreferidas} from "../../../services/user.service";
import { rootPath } from "../../../services/utils";
import BusquedaPreferida from "../../Inicio/Principal/BFavoritas/BusquedaPreferida";


const MisBuscadoresCabeceraResultados = (props) => {
    const [listaBuscadores, setListaBuscadores] = useState(null);
    const [estadoMenuMisBuscadores, setEstadoMenuMisBuscadores] = useState(false)

    useEffect(() => {
        descargaBuscadores();
      }, [])
    
    const descargaBuscadores = () => {
        BP2022Service.getBuscadoresPreferidos()
        .then((response) => {
            setListaBuscadores(response.data);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const handleClickMisBuscadores = (e) =>{
        e.target.parentElement.parentElement.classList.toggle('active');
        setEstadoMenuMisBuscadores(!estadoMenuMisBuscadores);
    }

    const CambiaAPaginaSimple = (codigoPagina) => {
        props.cambiaPagina({
            newpagina: codigoPagina
        });
    }


    
    const busquedas = listaBuscadores ? (
        listaBuscadores.map(fila => <BusquedaPreferida item={fila} colorLupa="Grey" cambiaPagina={props.cambiaPagina}></BusquedaPreferida>)
    ):(
        <div></div>
    );

    return (
        <div class="contenedor__buscador--misBuscadores">
            <div onClick={(e) => handleClickMisBuscadores(e)} style={{cursor:'pointer'}}>
                <span>Mis buscadores</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="12.531" height="7.877" viewBox="0 0 12.531 7.877">
                    <path id="Combined_Shape_Copy" data-name="Combined Shape Copy"
                        d="M6.266,7.878h0L0,0H2.386l3.88,4.877L10.145,0h2.386L6.267,7.877Z" transform="translate(0 -0.001)"
                        fill="#757575" />
                </svg>
            </div>
            <div class="misBuscadores" > 
                <h2 class="fs fs13 fw fw600 purpleColor2">MIS BUSCADORES FAVORITOS</h2>
                <div class="separator separator__20"></div>
                <ul class="fs fs16 greyColor FlamaBasic list">
                    {busquedas}
                </ul>
                <span class="fs fs16 FlamaBasic greyColor edit" onClick={(e) => CambiaAPaginaSimple('EditaBuscadores')}>
                    <img src={rootPath + "img/pencilPink.svg"} alt="pencilPink" />
                     Editar mis buscadores
                </span>
            </div> 
        </div>
    );
};

export default MisBuscadoresCabeceraResultados;
