import { validaAccesoPorRol } from "../../../services/objsAplicacion";

const ItemMenuTerciarioMorado = (props) => {
    const CambiaPaginaSimple = (codigoPagina) => {
        props.cambiaPagina({
            newpagina: codigoPagina
        });
    }

    //const estilo = (props.perfil === "COL") ? {cursor:'pointer', color:'#B9B9B9'} : {cursor:'pointer'}
    const estilo = (!validaAccesoPorRol(props.item.pagina,props.perfil)) ? {cursor:'pointer', color:'#B9B9B9'} : {cursor:'pointer'}

    return (
        <li>
            <a style={estilo}  
               onClick={(e) => CambiaPaginaSimple(props.item.pagina)} 
               title={props.item.titulo} >{props.item.titulo}</a>
        </li>
    )
}
export default ItemMenuTerciarioMorado;
