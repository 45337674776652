const ItemMenuIconos = (props) => {

    return (
        <li>
            <span title={props.item.titulo} style={{cursor:'pointer'}} onClick={(e) => props.item.alHacerClick(props.item.parametro)} >   
                <img src={props.item.imagen} alt={props.item.titulo} />
            </span>
        </li>
    )
}
export default ItemMenuIconos;
