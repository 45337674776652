import BP2022Service from "../../services/user.service";
import { useEffect, useState } from "react";
import VolverCabeceraResultados from "../Resultados/CabeceraResultados/VolverCabeceraResultados";
import CabeceraConfiguracion from "./CabeceraConfiguracion";
import { validaAccesoPorRol } from "../../services/objsAplicacion";
import { PerfilUsuario } from "../../services/utils";


const EditaBuscadores= (props) => {
    const [datosFormulario, setDatosFormulario] = useState({
        listaBH: null,
        listaBV: null,
        listaBF: null,
    });
    const [seleccionFormulario, setSeleccionFormulario] = useState({
        idsBusc: [],
    })

    useEffect(() => {
        if (datosFormulario.listaBH === null)
        {
            DescargaDatos();
        }        
    },[]);

    const DescargaDatos = () => {      
        BP2022Service.datosFormularioBucadoresPreferidos()
        .then((response) => {
            var df = {
                listaBH : response.data.BuscadoresMUH,
                listaBV : response.data.BuscadoresMUV,
                listaBF : response.data.BuscadoresPPF,
            };
            var dfVisible = {
                listaBH : [],
                listaBV : [],
                listaBF : [],
            };
            var ids = [];
            for (var i=0; i<df.listaBH.length; i++){
                if (validaAccesoPorRol(df.listaBH[i].CodigoPagina,PerfilUsuario())){
                    dfVisible.listaBH.push(df.listaBH[i]);
                    if (df.listaBH[i].EsVisible){
                        ids.push(df.listaBH[i].IdBuscador);
                    }
                } 
            }
            for (var i=0; i<df.listaBV.length; i++){
                if (validaAccesoPorRol(df.listaBV[i].CodigoPagina,PerfilUsuario())){
                    dfVisible.listaBV.push(df.listaBV[i]);
                    if (df.listaBV[i].EsVisible){
                        ids.push(df.listaBV[i].IdBuscador);
                    } 
                }
            }
            for (var i=0; i<df.listaBF.length; i++){
                if (validaAccesoPorRol(df.listaBF[i].CodigoPagina,PerfilUsuario())){
                    dfVisible.listaBF.push(df.listaBF[i]);
                    if (df.listaBF[i].EsVisible){
                        ids.push(df.listaBF[i].IdBuscador);
                    } 
                }
            }
            setDatosFormulario(dfVisible);
            setSeleccionFormulario({idsBusc : ids});
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
    
    const clickGuardar = (e) =>{
        BP2022Service.setDatosBuscadoresPreferidos(seleccionFormulario.idsBusc)
        .then((response) => {
            props.atras();
        });
    }

    const checkBuscador= (e, valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var activo = e.target.checked;
        if (activo){
            if (!nuevaSeleccion.idsBusc.includes(valor)){
                nuevaSeleccion.idsBusc.push(valor);
            }
        }
        if (!activo){
            if (nuevaSeleccion.idsBusc.includes(valor)){
                var idx = nuevaSeleccion.idsBusc.indexOf(valor);
                nuevaSeleccion.idsBusc.splice(idx,1);
            }
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const disabledAdd = (seleccionFormulario.idsBusc.length>=5);

    const HTMLHumanas = (
        ((datosFormulario.listaBH) ?
            datosFormulario.listaBH.map(busc =>
                ((seleccionFormulario.idsBusc.includes(busc.IdBuscador) || !disabledAdd) ?
                    <label>
                        <input 
                            type="checkbox" 
                            name={busc.CodigoPagina} 
                            value={busc.CodigoPagina} 
                            checked={seleccionFormulario.idsBusc.includes(busc.IdBuscador)} 
                            onClick={e => checkBuscador(e,busc.IdBuscador)} />
                        {busc.Titulo}
                    </label>
                :
                    <label class="disabled" style={{opacity:'0.5'}}>
                        <input type="checkbox" 
                            name={busc.CodigoPagina} 
                            value={busc.CodigoPagina} 
                            checked={seleccionFormulario.idsBusc.includes(busc.IdBuscador)} 
                            onClick={e => checkBuscador(e,busc.IdBuscador)} 
                            disabled = {!seleccionFormulario.idsBusc.includes(busc.IdBuscador)}/>
                        {busc.Titulo}
                    </label>
                )
            )
        :
            <div></div>
        )
    );

    const HTMLVeterinaria = (
        ((datosFormulario.listaBV) ?
            datosFormulario.listaBV.map(busc =>
                ((seleccionFormulario.idsBusc.includes(busc.IdBuscador) || !disabledAdd) ?
                    <label>
                        <input 
                            type="checkbox" 
                            name={busc.CodigoPagina} 
                            value={busc.CodigoPagina} 
                            checked={seleccionFormulario.idsBusc.includes(busc.IdBuscador)} 
                            onClick={e => checkBuscador(e,busc.IdBuscador)} />
                        {busc.Titulo}
                    </label>
                :
                    <label class="disabled" style={{opacity:'0.5'}}>
                        <input type="checkbox" 
                            name={busc.CodigoPagina} 
                            value={busc.CodigoPagina} 
                            checked={seleccionFormulario.idsBusc.includes(busc.IdBuscador)} 
                            onClick={e => checkBuscador(e,busc.IdBuscador)} 
                            disabled = {!seleccionFormulario.idsBusc.includes(busc.IdBuscador)}/>
                        {busc.Titulo}
                    </label>
                )
            )
        :
            <div></div>
        )
    );

    const HTMLParafarmacia = (
        ((datosFormulario.listaBF) ?
            datosFormulario.listaBF.map(busc =>
                ((seleccionFormulario.idsBusc.includes(busc.IdBuscador) || !disabledAdd) ?
                    <label>
                        <input 
                            type="checkbox" 
                            name={busc.CodigoPagina} 
                            value={busc.CodigoPagina} 
                            checked={seleccionFormulario.idsBusc.includes(busc.IdBuscador)} 
                            onClick={e => checkBuscador(e,busc.IdBuscador)} />
                        {busc.Titulo}
                    </label>
                :
                    <label class="disabled" style={{opacity:'0.5'}}>
                        <input type="checkbox" 
                            name={busc.CodigoPagina} 
                            value={busc.CodigoPagina} 
                            checked={seleccionFormulario.idsBusc.includes(busc.IdBuscador)} 
                            onClick={e => checkBuscador(e,busc.IdBuscador)} 
                            disabled = {!seleccionFormulario.idsBusc.includes(busc.IdBuscador)}/>
                        {busc.Titulo}
                    </label>
                )
            )
        :
            <div></div>
        )
    );

    return (
        <section class="section section__buscador section__buscador__busqueda" >  
            <div class="container container__column container__column__favoritos" > 
                <div class="content" >
                    <form class="content__results content__results__filters" id="main-filter"  onSubmit={(e) => e.preventDefault()}>
                        <div class="module module__buttons">
                            <h2 class="fs fs20 fw fw400 blackColor">Selecciona tus buscadores preferidos</h2>
                        </div>
                        <div class="column module__checks__limit__wrap" >                       
                            <div class="cover cover__filters cover__filters__info" >
                                <div class="module" >
                                    <div class="box" >
                                        <svg width="20px" height="20px" viewBox="0 0 20 20" >
                                            <g id="desarollo_v3" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="NUEVA-NECESIDAD!-Pintar-pantalla-buscadores-favoritos" transform="translate(-247.000000, -863.000000)">
                                                    <g id="Group-61-Copy" transform="translate(247.000000, 863.000000)">
                                                        <circle id="Oval-Copy-7" stroke="#7EB04B" stroke-width="2" cx="10" cy="10" r="9"></circle>
                                                        <g id="Group-60" transform="translate(9.000000, 5.000000)" fill="#7EB04B" class="info" >
                                                            <rect id="Rectangle" x="0" y="0" width="2" height="5"></rect>
                                                            <rect id="Rectangle" x="0" y="7" width="2" height="2"></rect>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg> Puedes seleccionar hasta cinco buscadores favoritos. En caso de querer cambiar un buscador favorito por otro, desmarca previamente el que desees eliminar
                                    </div>
                                </div>
                            </div>
                            {(datosFormulario.listaBH && datosFormulario.listaBH.length>0)?
                                <div class="cover cover__filters cover__filters__checks" >
                                    <div class="module module__checks module__checks__limit" >
                                        <h3 class="fs fs16 purpleColor5 title" >Uso humano</h3>
                                        <div class="checks checks__30" >
                                            <div class="cover" >
                                                {HTMLHumanas}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :
                                <></>
                            }
                            {(datosFormulario.listaBV && datosFormulario.listaBV.length>0)?
                                <div class="cover cover__filters cover__filters__checks" >
                                    <div class="module module__checks module__checks__limit" >
                                        <h3 class="fs fs16 purpleColor5 title" >Veterinaria</h3>
                                        <div class="checks checks__30" >
                                            <div class="cover" >
                                                {HTMLVeterinaria}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :
                                <></>
                            }
                            {(datosFormulario.listaBF && datosFormulario.listaBF.length>0)?
                                <div class="cover cover__filters cover__filters__checks" >
                                    <div class="module module__checks module__checks__limit" >
                                        <h3 class="fs fs16 purpleColor5 title" >Parafarmacia</h3>
                                        <div class="checks checks__30" >
                                            <div class="cover" >
                                                {HTMLParafarmacia}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :
                                <></>
                            }
                            <div class="mySearch mySearch__columns" >
                                <div class="module module__buttons module__buttons__buscar">
                                    <button class="button button__arrow button__purple noUppercase" title="Guardar" onClick={e => clickGuardar(e)}>Guardar</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section> 
    );
};

export default EditaBuscadores;
