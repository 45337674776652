import TiemposEsperaEspecie from "./TiemposEsperaEspecie";
const TiemposEspera = (props) => {
    const tiemposEspera = props.tiemposEspera;
    const tiemposEsperaEspecie = tiemposEspera.map(tee => <TiemposEsperaEspecie tiemposEspera={tee} ></TiemposEsperaEspecie>);
    return (
        <div class="infoContent" >
            {tiemposEsperaEspecie}
        </div>
    )
}
export default TiemposEspera;
