import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker3.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import MaskedInput from 'react-text-mask'
import { createAutoCorrectedDatePipe } from "text-mask-addons";

function SelectorFechas(props){
    const [seleccion, setSeleccion] = useState({
        inicio: props.inicio,
        fin: props.fin,
    });
    const inicio = seleccion.inicio;
    const fin = seleccion.fin;
    const id = props.idHMTL
    const idDesde = id + "_Desde";
    const idHasta = id + "_Hasta";

    
    useEffect(() => {
        setSeleccion({
            inicio: props.inicio,
            fin: props.fin,
        })
        
    },  [props.inicio, props.fin]);

    const cambioInicio = (date) =>{
        /*var ts = Math.round(date.getTime()/1000);
        var newdate = new Date(parseInt(ts)*1000);*/
        cambiaSeleccion(date, seleccion.fin);
    }

    const cambioFin = (date) =>{
        /*var ts = Math.round(date.getTime()/1000);
        var newdate = new Date(parseInt(ts)*1000);*/
        cambiaSeleccion(seleccion.inicio, date);
    }

    const cambiaSeleccion = (inicio, fin) =>{
        props.alCambiar(inicio, fin);        
    }

    const keyDateDown = (e) =>{
        var txt = e.target.value;
        var key = e.keyCode || e.charCode;
        if (key !== 8 && key !== 46){
            if (/^[0-3][0-9]$/.test(txt)){
                e.target.value = txt + "/";
            }
            if (/^[0-3][0-9]\/[0-1][0-9]$/.test(txt)){
                e.target.value = txt + "/";
            }
            if (key === 111 || key === 55){
                if (/^[0-9]\/$/.test(txt)){
                    e.target.value = "0" + txt;
                }
                if (/^[0-3][0-9]\/[0-9]\/$/.test(txt)){
                    e.target.value = txt.substring(0,3) + "0" + txt.substring(3);
                }
                e.target.value = e.target.value.replace("//","/");
            }
        }
    }

    const estiloTitulo = (props.estiloTitulo ? props.estiloTitulo : {})

    registerLocale('es', es)

    const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy')

    const estilosubtitulos = {
        fontFace : 'Flama, sans-serif',
        marginTop : '-5px',
        marginBottom : '5px',
        fontSize : '14px',
        fontWeight : '300',
    };


    const subtitulodesde = (props.subtitulodesde) ? 
        <p style={estilosubtitulos}>{props.subtitulodesde}</p> : <></> ;
    const subtitulohasta = (props.subtitulohasta) ? 
        <p style={estilosubtitulos}>{props.subtitulohasta}</p> : <></> ;


    return (
        <div class="module module__filters" >
            <h3 class="title fs fs16 greyColor2" style={estiloTitulo} >{props.titulo}</h3>
            <div class="calendars" >
                <div class="calendar" >
                    {subtitulodesde}
                    <ReactDatePicker
                    locale="es"
                    selected={seleccion.inicio}
                    onChange={(date) => cambioInicio(date)}
                    className="hasDatepicker"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/aaaa"
                    id = {idDesde}
                    customInput = {<input type="text" class="react-datepicker-ignore-onclickoutside" onKeyUp={e=>keyDateDown(e)}/>}
                    /*customInput={
                        <MaskedInput
                            pipe={autoCorrectedDatePipe}
                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                            keepCharPositions= {true}
                            guide = {true}
                        />}*/
                    />
                </div>
                <div class="calendar" >
                    {subtitulohasta}
                    <ReactDatePicker
                    locale="es"
                    selected={seleccion.fin}
                    onChange={(date) => cambioFin(date)}
                    className="hasDatepicker"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/aaaa"
                    id = {idHasta}
                    customInput = {<input type="text" class="react-datepicker-ignore-onclickoutside" onKeyUp={e=>keyDateDown(e)}/>}
                    /*customInput={
                        <MaskedInput
                            pipe={autoCorrectedDatePipe}
                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                            keepCharPositions= {true}
                            guide = {true}
                        />}*/
                    />
                </div>
            </div>
        </div>
    )
}
export default SelectorFechas;
