import React, { useState, useEffect } from "react";
import Ayuda from "./Ayuda/Ayuda";
import Configuracion from "./Configuarcion/Configuracion";
import EditaBuscadores from "./Configuarcion/EditaBuscadores";
import Exporta from "./Exporta/Exporta";
import Ficha from "./Fichas/Ficha";
import FichaMUH from "./Fichas/FichaMUH/FichaMUH";
import FormularioBusqueda from "./FomulariosBusqueda/ForumularioBusqueda";

import Inicio from "./Inicio/Inicio";
import SeccionResultados from "./Resultados/SeccionResultados";


const Cuerpo = (props) => {
    const infoPagina = props.infoPagina;

    useEffect(() => {
    }, [])

    switch(infoPagina.tipoPagina){
        case "ResultadosBusqueda":
            return (
                <SeccionResultados infoPagina={infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras} borraDeLista = {props.borraDeLista} ></SeccionResultados>
            );
            break;
        case "Inicio":
            return (
                <Inicio cambiaPagina={props.cambiaPagina} ></Inicio>
            );
        break;
        case "Ayuda":
            return (
                <Ayuda cambiaPagina={props.cambiaPagina} ></Ayuda>
            );
        break;
        case "Exporta":
            return (
                <Exporta cambiaPagina={props.cambiaPagina} ></Exporta>
            );
        break;
        case "Ficha":
            return (
                <Ficha infoPagina={infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras} descargaHistorial={props.descargaHistorial} ></Ficha>
            );
            break;
        case "FormularioBusqueda":
            return (
                <FormularioBusqueda infoPagina={infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras} descargaHistorial={props.descargaHistorial} ></FormularioBusqueda>
            );
            break;
        case "Conf":
            return (
                <Configuracion infoPagina={infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras} 
                                refresca={props.refresca} returnTo={props.returnTo} 
                                cambiopass={props.cambiopass} 
                                cambiomail={props.cambiomail} 
                                confirmamail={props.confirmamail} 
                                link={props.link}
                                alTerminarCambio={props.alTerminarCambio}
                >                   
                </Configuracion>
            );
            break;
        case "EditaBuscadores":
            return (
                <EditaBuscadores infoPagina={infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras}  refresca={props.refresca} returnTo={props.returnTo}></EditaBuscadores>
            );
            break;
        default:
            return (
                <Inicio cambiaPagina={props.cambiaPagina} ></Inicio>
            );
            break;
    }
};

export default Cuerpo;
