import { rootPath } from "../../../services/utils";

const LinksFichaMUV = (props) => {
    const ficha = props.ficha;

    const CambiaPaginaPorId = (pagina, id) => {
        props.cambiaPagina({
            newpagina: pagina,
            newid: id,
        });
    }


    const mouseOverLink = (e) => {
        e.target.style.fontWeight='bold';
        e.target.style.textDecoration='none';
    }
    const mouseOutLink = (e) => {
        e.target.style.fontWeight='normal';
    }



    return (
        <div class="dispensacion-facturacion">
            <div class="extras">
                {(
                    ficha.Prospecto ?
                        <div class="elemento">
                            <div class="mask" >
                                <a href={ficha.Prospecto.URL} target="_blank" title={ficha.Prospecto.Titulo} >
                                    <img src={ rootPath + "img/ficha/fileGreen.svg"} />
                                </a>
                            </div>
                            <a href={ficha.Prospecto.URL} target="_blank" title={ficha.Prospecto.Titulo} >
                                <span style={{textDecoration:'none'}}
                                    onMouseOver={(e) => mouseOverLink(e)}
                                    onMouseOut={(e) => mouseOutLink(e)}
                                >Prospecto</span>
                            </a>
                        </div>
                    :
                        <span></span>
                )}
                {(
                    ficha.FichaTecnica ?
                        <div class="elemento">
                            <div class="mask" >
                                <a href={ficha.FichaTecnica.URL} target="_blank" title={ficha.FichaTecnica.Titulo} >
                                    <img src={rootPath + "img/ficha/fileGreen2.svg"} />
                                </a>
                            </div>
                            <a href={ficha.FichaTecnica.URL} target="_blank" title={ficha.FichaTecnica.Titulo} >
                                <span style={{textDecoration:'none'}}
                                onMouseOver={(e) => mouseOverLink(e)}
                                onMouseOut={(e) => mouseOutLink(e)}
                                >Ficha técnica</span>
                            </a>
                        </div>
                    :
                        <span></span>
                )}
            </div>
        </div>
    )
}
export default LinksFichaMUV;
