import Acordeon from "./Acordeon";
import Componente from "./Componente";
const Composicion = (props) => {
    const composicion = props.composicion;
    const por = props.por;
    const codTipoRegistro = props.tipo;
    const componentes = composicion.Componentes.map(item => <Componente cambiaPagina={props.cambiaPagina} componente={item} tipo={props.tipo} 
                                                                        siguiente={composicion.Componentes.indexOf(item)===composicion.Componentes.length-1 ? null :
                                                                                    composicion.Componentes[composicion.Componentes.indexOf(item)+1]}></Componente>);
    const excipientes = ((composicion.Excipientes && composicion.Excipientes.length>0) ?
        composicion.Excipientes.map(item => <Componente cambiaPagina={props.cambiaPagina} componente={item}></Componente>)
        : 
        <span></span>        
    );
    const excipientesB = ((composicion.ExcipientesB && composicion.ExcipientesB.length>0) ? 
        composicion.ExcipientesB.map(item => <Componente cambiaPagina={props.cambiaPagina} componente={item}></Componente>)
    : 
        <span></span>        
    );
    const titulo = composicion.Nombre+ ' ' + ((por) ? por : '');
    const tituloE = ((composicion.Excipientes && composicion.Excipientes.length>0) ? composicion.NombreExcipientes : '');
    const tituloE2 = ((composicion.ExcipientesB && composicion.ExcipientesB.length>0) ? composicion.NombreExcipientesB : '');
    const nombreComponentes = (codTipoRegistro==="PPF") ? "Ingredientes" : "Principios activos";

    var contenidoAcordeon = (
        <div class="table" style={{width:'100%',display:'table'}}>
            <div class="table__row table__row__header fs fs12 fw fw600 greenColor2 uppercase" style={{display:'table-row'}}>
                <div class="column" style={{display:'table-cell'}}>
                    <p>{nombreComponentes}</p>
                </div>
                <div class="column"  style={{display:'table-cell'}}>
                    <p>Cantidad</p>
                </div>
                <div class="column"  style={{display:'table-cell'}}>
                    <p>Unidad</p>
                </div>
            </div>
            {componentes}
            {(composicion.Excipientes && composicion.Excipientes.length>0)? 
                <div class="table__row table__row__header fs fs12 fw fw600 greenColor2 uppercase" style={{height:'20px',display:'table-row'}}>
                    <div class="column" style={{display:'table-cell', padding: '4px'}}>
                        <p>{tituloE}</p>
                    </div>
                    <div class="column" style={{display:'table-cell', padding: '4px'}}>
                        &nbsp;
                    </div>
                    <div class="column" style={{display:'table-cell', padding: '4px'}}>
                        &nbsp;
                    </div>
                </div>
            : <></>
            }
            {excipientes}
            {(composicion.ExcipientesB && composicion.ExcipientesB.length>0)? 
                <div class="table__row table__row__full fs fs12 fw fw300 greyColor10 uppercase" style={{height:'20px',display:'table-row'}}>
                    <div class="column" style={{display:'table-cell', padding: '4px'}}>
                        <p>{tituloE2}</p>
                    </div>
                    <div class="column" style={{display:'table-cell', padding: '4px'}}>
                        &nbsp;
                    </div>
                    <div class="column" style={{display:'table-cell', padding: '4px'}}>
                        &nbsp;
                    </div>
                </div>
            : <></>
            }
            {excipientesB}
        </div>
    );
    return (
        <Acordeon titulo = {titulo} contenido={contenidoAcordeon} activo={true}></Acordeon>
    )
}
export default Composicion;
 
