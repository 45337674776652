import AdvertenciasTipo from "./AdvertenciasTipo";

const Advertencias = (props) => {
    const datosAdver = props.datosAdvertencias;
    const advertenciasPorTipo = datosAdver.Advertencias.map(tipoAdver => <AdvertenciasTipo advertenciasTipo={tipoAdver}></AdvertenciasTipo>);
    const textosSeccion = datosAdver.Textos.map(texto => 
        <div class="cover cover__text" >
            <h4 class="fs fs16 fw fw700 greyColor2 uppercase title" >{texto.Epigrafe}</h4>
                <p class="fs fs16 FlamaBook blackColor" style={{whiteSpace: 'pre-line'}} >{texto.Texto}</p>
        </div>
    );
    return (
        <div class="infoContent" >
            {advertenciasPorTipo}
            {textosSeccion}
        </div>
    )
}
export default Advertencias;
