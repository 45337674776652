function FiltroText(props){
    const filtro = props.filtro;
    const idInput = "input__" + filtro.idGrupo;
    
    const avoidEnter = (e) =>{
        if (e.which == '13') {
            e.preventDefault();
            e.stopPropagation();
            e.target.blur();
        }
    }

    const handleCambioLista=(e) =>{
        props.cambiaLista(filtro.idGrupo, e.target.value)
    }

    return (
        <form class="search"  onSubmit={(e) => e.preventDefault()}>
            <input type="search" name="" placeholder="Escribe…" id={idInput}
                    onChange={e => handleCambioLista(e)} 
                    onKeyDown={e => avoidEnter(e)}/>
        </form>
    );
}    
export default FiltroText;
