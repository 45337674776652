import FilaTablaProductos from "./FilaTablaProductos";


const TablaProductos = (props) => {
    const productos = props.productos;
    const HTMLAnalisis = productos.map(prod => <FilaTablaProductos producto={prod} cambiaPagina={props.cambiaPagina}></FilaTablaProductos>);
    return (
        <div class="infoContent" >
            <div class="table" style={{width:'100%'}}>
                <div class="table__row table__row__header table__row__header__noBg fs fs12 fw fw600 greenColor uppercase">
                    <div class="column" style={{maxWidth:'130px'}}>
                        <p>Código nacional</p>
                    </div>
                    <div class="column" >
                        <p>Producto</p>
                    </div>
                </div>
                {HTMLAnalisis}
            </div>
        </div>
    )
}
export default TablaProductos;
