import AcordeonN1 from "./AcordeonN1/AcordeonN1";
import RamaSecundaria from "./RamaSecundaria";

function RamaPrincipal(props){
    const item = props.item; 
    const codigo = item.Codigo
    const titulo = (props.ocultaCodigo) ? item.Valor : item.Valor.substring(codigo.length).trim();
    const contenidoHTML = (item.hijos) ? item.hijos.map(hijo => <RamaSecundaria item = {hijo} valueSelected={props.valueSelected} cambiaSeleccion = {props.cambiaSeleccion} ocultaCodigo = {props.ocultaCodigo}></RamaSecundaria> ) : null ;
    //const contenidoHTML = item.hijos.map(hijo => <div item = {hijo}>{hijo.Valor}</div> )
    return (
        <AcordeonN1 titulo={titulo} codigo={codigo} contenido={contenidoHTML} activo={item.abierta} checked = {item.checked} cambiaSeleccion = {props.cambiaSeleccion} ocultaCodigo = {props.ocultaCodigo} valueSelected={props.valueSelected}></AcordeonN1>
    )
}
export default RamaPrincipal;
