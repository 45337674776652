import { rootPath } from "../../../../services/utils";

const NovedadesInicioPie = (props) => {

    const vesAUltimoMes = (codigo) =>{
        CambiaPaginaSimple("BNovedadesUltimoMes")
    }

    const CambiaPaginaSimple = (codigoPagina) => {
        props.cambiaPagina({
            newpagina: codigoPagina
        });
    }



    return (
        <div class="module module__buttons" >
            <span class="button button__arrow button__purple" title="Ver más" onClick={() => vesAUltimoMes()} style={{cursor:'pointer'}}>
                Ver más <img src={rootPath + "img/arrowRightWhite.svg"} alt="arrowRightWhite" />
            </span>
        </div>
    );
};

export default NovedadesInicioPie;
  