import React, { useState, useEffect } from "react";
import CheckBoxFiltro from "./CheckBoxFiltro";

function GrupoCheckBoxFiltro(props){
    const filtro = props.filtro;
    const items = filtro.valores;
    const grupoActivo = (items.find(item => item.activo) ? true : false);
    const listaCheckBox = items.map(item => <CheckBoxFiltro  cambiaCheck= {props.cambiaCheck}
                                                            texto = {item.texto}
                                                            value = {item.value}
                                                            idValor = {item.idValor}
                                                            nivel = {item.nivel}
                                                            excluyente = {filtro.excluyente}
                                                            activo = {item.activo}
                                                            idGrupo = {filtro.idGrupo}
                                                            grupoActivo = {grupoActivo}
                                            ></CheckBoxFiltro>);


    return(
        <form class="coverFilters" >
            <div class="filters" >
                {listaCheckBox}
            </div>
        </form>
    )
}


export default GrupoCheckBoxFiltro;
