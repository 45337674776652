import React, { useState } from "react";

const VolverCabeceraResultados = (props) => {
    const miestilo = (props.textoVolver==="" ? 
        {
            cursor:'pointer',
            visibility:'hidden'
        } 
        : 
        {
            cursor:'pointer'
        }
    );
    return (
        <div class="contenedor__buscador--volver"  onClick={() => props.atras()} style={miestilo}>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
                <defs>
                    <clipPath id="clip-path">
                        <path id="Combined_Shape" data-name="Combined Shape"
                            d="M2.89,4.092,4.127,2.855,9.572,8.3,8.335,9.537ZM0,8.75V0H8.75V1.75h-7v7Z"
                            transform="translate(13.512 6.744) rotate(135)" fill="#93c462" />
                    </clipPath>
                </defs>
                <g id="icon_more_copy_19" data-name="icon/more copy 19" transform="translate(21 21) rotate(-180)">
                    <g id="Group" transform="translate(3 3.931)">
                        <path id="Combined_Shape-2" data-name="Combined Shape"
                            d="M2.89,4.092,4.127,2.855,9.572,8.3,8.335,9.537ZM0,8.75V0H8.75V1.75h-7v7Z"
                            transform="translate(13.512 6.744) rotate(135)" fill="#93c462" />
                        <g id="Group-2" data-name="Group" transform="translate(0 0)" clip-path="url(#clip-path)">
                            <g id="color_black-green" data-name="color/black-green" transform="translate(-3 -3.931)">
                                <rect id="color_botplus_background" data-name="color/botplus background" width="21" height="21"
                                    fill="none" />
                                <rect id="Rectangle" width="21" height="21" fill="#81509a" />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
            <span>{props.textoVolver}</span>
            <br/>
            <span style={{visibility:'hidden'}}>Volver ver los resultados</span>
        </div>
    );
};

export default VolverCabeceraResultados;
