import { numberWithCommas } from "../../../services/utils";
import AvisoPreciosFuturos from "../FichaMUH/InfoPrincipal/AvisoPreciosFuturos";

const PreciosFM = (props) => {
    const ficha = props.ficha;
    const tienePreciosFuturos = (ficha.PFuturos !== null)
    const tienePMenorCCAA = ((ficha.AgrupacionCCAA && ficha.AgrupacionCCAA.PVPMenor>0) || (ficha.PFuturos && ficha.PFuturos.AgrupacionCCAA && ficha.PFuturos.AgrupacionCCAA.PVPMenor>0))
    const literalPMenor = (ficha.AgrupacionCCAA ? (!ficha.AgrupacionCCAA.LiteralPMenor || ficha.AgrupacionCCAA.LiteralPMenor === '') ? (ficha.IdCCAA===5 || ficha.IdCCAA===18 || ficha.IdCCAA===19 ? 'PVP': 'PVP con IVA') : ficha.AgrupacionCCAA.LiteralPMenor : '');

    return (
        <div class="precios f-f-Flama-Basic">

        <div class="otros__precios">
            {(tienePreciosFuturos ?
                <AvisoPreciosFuturos fecha={ficha.PFuturos.Fecha}></AvisoPreciosFuturos>
            :
                <div></div>                            
            )}
            <div class="otros__precios--precios">
                <div class="precios__comunidad"  style={{maxWidth:'200px'}}>
                    <h3 class="titulo">{ficha.CCAA}</h3>
                    <div class="precios__comunidad--precios">
                        {(!ficha.LiteralPrecioFM || ficha.LiteralPrecioFM === "") ?
                            <div style={{maxWidth:'100%'}} class="precios__iva">
                                <p class="nombre" style={{textTransform:'none'}}>{literalPMenor}</p>                                        
                                {(ficha.AgrupacionCCAA ?
                                    (ficha.AgrupacionCCAA.PVPMenor>0 ?
                                        <p class="precio" style={{color: "#81509a"}}>{numberWithCommas(ficha.AgrupacionCCAA.PVPMenor)}</p>
                                    : 
                                        <p class="precio">-</p>
                                    )
                                :
                                    <p class="precio">-</p>
                                )}
                                {(tienePreciosFuturos ?
                                    (ficha.PFuturos.AgrupacionCCAA ?
                                        (ficha.PFuturos.AgrupacionCCAA.PVPMenor>0 ?
                                            <p class="sin_iva">{numberWithCommas(ficha.PFuturos.AgrupacionCCAA.PVPMenor)}</p>
                                        : 
                                            <p class="sin_iva">-</p>
                                        )
                                    :
                                        <p class="sin_iva">-</p>
                                    )
                                :
                                    <span></span>                            
                                )}
                                {(tienePMenorCCAA ?
                                    <p class="moneda">EUR</p>
                                :
                                    <span></span>
                                )}
                            </div>
                        :
                            <div style={{maxWidth:'100%'}} class="precios__iva">
                                <p class="nombre" style={{textTransform:'none'}}>{ficha.LiteralPrecioFM}</p>                                        
                                {
                                    (ficha.PVPIVA>0 ?
                                        <p class="precio" style={{color: "#81509a"}}>{numberWithCommas(ficha.PVPIVA)}</p>
                                    : 
                                        <p class="precio">-</p>
                                    )
                                }
                                {(tienePreciosFuturos ?
                                    (ficha.PFuturos.PVPIVA>0 ?
                                        <p class="sin_iva">{numberWithCommas(ficha.PFuturos.PVPIVA)}</p>
                                    : 
                                        <p class="sin_iva">-</p>
                                    )
                                :
                                    <span></span>                            
                                )}
                                {(ficha.PVPIVA>0 ?
                                    <p class="moneda">EUR</p>
                                :
                                    <span></span>
                                )}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
)
}
export default PreciosFM;
