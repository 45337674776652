const ItemIndiceFormularios = (props) => {
    const item = props.item;
    const activo = props.activo;
    const claseli = (activo ? "active" : "");
    const colorFill = (activo ? "#B9B9B9" : "#81509a");
    const estiloSpan = (activo ? {cursor:'default'} : {cursor:'pointer'})

    return (
        <li className={claseli}>
            <span title={item.display} onClick={e => props.cambiaPagina({newpagina : item.codigoPagina})} style={estiloSpan}>{item.display}
                <svg data-name="icon/more copy" xmlns="http://www.w3.org/2000/svg" xmlns_xlink="http://www.w3.org/1999/xlink" width="21" height="21" viewBox="0 0 21 21">
                    <defs>
                        <clipPath id="clip-path">
                            <path class="fill" id="Combined_Shape" data-name="Combined Shape" d="M2.89,4.092,4.127,2.855,9.572,8.3,8.335,9.537ZM0,8.75V0H8.75V1.75h-7v7Z" transform="translate(13.512 6.744) rotate(135)" fill="#93c462"></path>
                        </clipPath>
                    </defs>
                    <g id="Group" transform="translate(3 3.931)">
                        <path id="Combined_Shape-2" data-name="Combined Shape" d="M2.89,4.092,4.127,2.855,9.572,8.3,8.335,9.537ZM0,8.75V0H8.75V1.75h-7v7Z" 
                            transform="translate(13.512 6.744) rotate(135)" fill={colorFill}></path>
                        <g id="Group-2" data-name="Group" transform="translate(0 0)" clip-path="url(#clip-path)">
                            <g id="color_black-green" data-name="color/black-green" transform="translate(-3 -3.931)">
                                <rect id="color_botplus_background" data-name="color/botplus background" width="21" height="21" fill="none"></rect>
                                <rect class="fill" id="Rectangle" width="21" height="21" fill={colorFill}></rect>
                            </g>
                        </g>
                    </g>
                </svg>
            </span>
        </li>
    );
};

export default ItemIndiceFormularios;
