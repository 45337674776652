import { useEffect, useState } from "react";
import SelectorTipo2 from "../../Utilidades/Selector/SelectorTipo2";
import { GetAntecesorWhithClassName, GetSucesorWhithClassName, RemoveClassNameToChildClassItems } from "../../../services/utils";
import SelectorUno2 from "../../Utilidades/Selector/SelectorUno2";
import SelectorMulti2 from "../../Utilidades/Selector/SelectorMulti2";

const FBusquedaMultiple = (props) => {
    const [lista,setLista] = useState([]);
    const [datosFormulario, setDatosFormulario] = useState(props.datosFormulario);

    const clasedivmodulefilters = (datosFormulario.ancho) ? "cover cover__filters cover__filters__2 cover__filters__2--1 ancho" : "cover cover__filters cover__filters__2 cover__filters__2--1";

    var seleccionados = datosFormulario.seleccionados;

    var busqueda = datosFormulario.busqueda;

    if ((!datosFormulario.esIdStr) && (seleccionados))
        seleccionados = seleccionados.map(i => parseInt(i));
    
    if (!seleccionados)
        seleccionados = [];
    
    const listaCodValor = lista.map(it => ({codigo: it.Codigo, valor: it.Valor}));


    useEffect(() => {
        if ((lista.length === 0) 
            || props.datosFormulario.tipo !== datosFormulario.tipo 
            || props.datosFormulario.tipoForm !== datosFormulario.tipoForm 
            || props.datosFormulario.aux !== datosFormulario.aux)
        {
            DescargaLista(props.datosFormulario.tipo, props.datosFormulario.aux);
        }        
    },[props.datosFormulario]);

    const DescargaLista = (tipo, aux) => {        
        props.datosFormulario.descarga(tipo, aux)
        .then((response) => {
            setDatosFormulario(props.datosFormulario);
            var newlista = response.data;
            newlista.splice(0,0,{Codigo:props.datosFormulario.valorNulo, Valor:props.datosFormulario.placeholder});
            setLista(newlista);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    /*const clickBuscar = (e) =>{
        const item = document.getElementById(props.datosFormulario.idSelect);
        if (item){
            if (item.value!=datosFormulario.valorNulo){
                var valor = item.value;
                props.cambiaPagina({
                    newpagina: busqueda,
                    newid: valor,
                })
            }
        }
    }*/

    const clickBuscar = (e) =>{
        if (datosFormulario.seleccionados && datosFormulario.seleccionados.length>0){
            //var valor = datosFormulario.seleccionado;
            props.cambiaPagina({
                newpagina: busqueda,
                //newid: valor,
                seleccionados: datosFormulario.seleccionados,
            })
        }
    }

/*    const CambiaValor = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(datosFormulario));
        nuevaSeleccion.seleccionado = nuevovalor;
        setDatosFormulario(nuevaSeleccion);
    }


    const BorraValor = () =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(datosFormulario));
        nuevaSeleccion.seleccionado = datosFormulario.valorNulo;
        setDatosFormulario(nuevaSeleccion);
    }
*/

    const AddValor = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(datosFormulario));
        if (!nuevaSeleccion.seleccionados) { nuevaSeleccion.seleccionados = []};
        nuevaSeleccion.seleccionados.push(nuevovalor);
        setDatosFormulario(nuevaSeleccion);
    }

    const RemoveValor = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(datosFormulario));
        var i = nuevaSeleccion.seleccionados.indexOf(parseInt(valor));
        nuevaSeleccion.seleccionados.splice(i,1);
        setDatosFormulario(nuevaSeleccion);
    }


    const BorraBusqueda = (e) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(datosFormulario));
        nuevaSeleccion.seleccionados = [];
        setDatosFormulario(nuevaSeleccion);
        /*BorraValor();
        var inputs = document.getElementsByClassName('inputSelector');
        if (inputs){
            for (var i=0; i<inputs.length; i++){
                inputs[i].value = "";
                const itemContainer = GetAntecesorWhithClassName(inputs[i], 'custom-select-container');
                if (itemContainer){
                    itemContainer.classList.remove('active');
                }
            }
        }*/
        /*const formulario = GetAntecesorWhithClassName(e.target,'formulario__busqueda');
        const container = GetSucesorWhithClassName(formulario, 'custom-select-container');
        const tags = GetSucesorWhithClassName(formulario,'tags');
        const ddown = GetSucesorWhithClassName(container, 'dropdown__radios');
        const inputText = GetSucesorWhithClassName(container, 'inputSelector');
        const select = GetSucesorWhithClassName(container, 'custom-select');
        
        RemoveClassNameToChildClassItems(ddown,'labeloption',null,'active');
        RemoveClassNameToChildClassItems(ddown,'labeloption',null,'hide');
        ddown.classList.remove('show');
        select.value = 0;
        inputText.value = '';
        for (var i=0; i<tags.children.length; i++){
            var child = tags.children[i];
            child.remove();
        }*/
    }

    const KeyDownForm = (e) =>{
        var keyCode = e.keyCode;
        if (keyCode === 13){
            clickBuscar(e);
        }
    }

    const ancho = window.innerWidth;
    const isMobile = (ancho<768);

    const maxLengthNormal = (datosFormulario.ancho && !isMobile ? 80 : 40);

    return (
        <div class="content" >
            <form class="content__results content__results__filters formulario__busqueda" id="main-filter"  onSubmit={(e) => e.preventDefault()}  onKeyDown={(e) => KeyDownForm(e)} tabIndex="0">
                <div class="module module__buttons">
                    <span class="button button__arrow button__green noUppercase reset-form" data-form="#main-filter" title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} >
                        Vaciar búsqueda
                    </span>
                </div>
                <div class={clasedivmodulefilters} >
                    <div class="module module__filters" >
                        <h3 class="title fs fs16 greyColor2" >
                            {datosFormulario.tituloSelect}:
                        </h3>
                        {/*<SelectorTipo2 lista = {listaCodValor} id={props.datosFormulario.idSelect} valueSelected={seleccionado} tipoFiltradoLista={props.datosFormulario.tipoFiltradoLista} maxLengthNormal = {maxLengthNormal}></SelectorTipo2> */}
                        <SelectorMulti2 lista = {listaCodValor} 
                                    idHTML={"SelMult_"+ datosFormulario.tipoForm}
                                    valuesSelected={datosFormulario.seleccionados}
                                    alAnadir={AddValor} 
                                    alBorrar={RemoveValor} 
                                    idEsStr = {datosFormulario.esIdStr}
                                    ancho="100%"
                        ></SelectorMulti2>
                    </div>
                </div>
                <div class="module module__buttons module__buttons__buscar">
                    <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                </div>
            </form>
        </div>
    );
};

export default FBusquedaMultiple;
