import { useEffect, useState } from "react";
import BP2022AuthService from "../../services/auth.service";

const ModalRecupera = (props) => {

    const [dni, setDni] = useState(props.dni);
    const [nfact, setNfact] = useState(props.nfact);
    const [correo, setCorreo] = useState('');
    const [confirma, setConfirma] = useState('');
    const [opt1, setOpt1] = useState(false);
    const [opt2, setOpt2] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [estado, setEstado] = useState(0);
    const [datosAcceso, setDatosAcceso] = useState(null);


    const cambiaDNI = (txt) =>{
        setDni(txt);
    }

    const cambiaNFact = (txt) =>{
        setNfact(txt);
    }

    const cambiaCorreo = (txt) =>{
        setCorreo(txt);
    }

    const cambiaConfirma = (txt) =>{
        setConfirma(txt);
    }

    const cambiaOpt1 = (checked) =>{
        setOpt1(checked);
    }

    const cambiaOpt2 = (checked) =>{
        setOpt2(checked);
    }

    const getRecuperaInfo = (xdni, xnfact) =>{
        setEstado(2);
        BP2022AuthService.buscaClaves(xdni,xnfact)
        .then((response) => {
            if ((response.ok) && (response.info)){
                setEstado(3);
                setDatosAcceso({
                    mail: response.info.Mail,
                    cliente: response.info.Cliente,
                    usuarioActual: response.info.UsuarioActual,
                    passActual: response.info.PassActual,
                    deseaRecibirInfo: (response.info.DeseaRecibirInfo === 'S'),
                    codigoGrupo : response.info.CodigoGrupo,
                    idPedido: response.info.IdPedido,
                });
                setCorreo(response.info.Mail);
                setConfirma(response.info.Mail);
                setOpt2(response.info.DeseaRecibirInfo === 'S');
            }
            else{
                setMensaje(response.msg);
                setEstado(1);
            } 
        })
        .catch(function (error) {
            console.log('Error', error.message);
            setMensaje(error.message);
            setEstado(1);
        });
    }

    useEffect (() =>{
        if (estado===0 && nfact && dni && nfact != '' && dni != ''){
            getRecuperaInfo(dni,nfact);
        }else if (estado===0){
            setEstado(1);
        }
    },[props.dni, props.nfactura]);

    const clickGetClaves = () =>{
        if (nfact && dni && nfact != '' && dni != ''){
            getRecuperaInfo(dni,nfact);
        }
    }

    const clickNo = () =>{
        setEstado(5);
        BP2022AuthService.actualizaKey(datosAcceso.idPedido);
    }

    const clickSi = () =>{
        setMensaje('');
        setEstado(4);
    }

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

    const clickAceptar = () => {
        if (!opt1)
            setMensaje('Para continuar debe marcar la primera casilla por la que acepta el uso de su correo electrónico.\nLa segunda casilla es opcional.');
        else if (correo === '')
            setMensaje('Debe introducir una dirección de correo electrónico');
        else if (confirma === '')
            setMensaje('Debe introducir una confirmación de la correo electrónico');
        else if (confirma !== correo)
            setMensaje('Las direcciones de correo no coinciden');
        else if (!validateEmail(correo))
            setMensaje('No es una dirección de correo válida');
        else {
            BP2022AuthService.guardaDatosCorreo(datosAcceso.cliente, correo, opt2);
            setEstado(5);
            BP2022AuthService.actualizaKey(datosAcceso.idPedido);
        }
    }

    switch(estado){
        case 1:
            props.cambiaAlto('460px');
            break;
        case 2:
            props.cambiaAlto('230px');
            break;
        case 3:
            props.cambiaAlto('280px');
            break;
        case 4:
            props.cambiaAlto('830px');
            break;
        case 5:
            props.cambiaAlto('300px');
            break;
    }

    const estiloCheckBox = {
        height: '16px',
    }


    return(
        (estado === 0) ?
            <div style={{height: '100%', width: '100%', cursor:'wait', overflowY: 'hidden',}}>
                <p className="fs fs15 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '30%'}}>Iniciando...</p>
            </div>
        :(estado === 1)?
            <form  onSubmit={(e) => e.preventDefault()}>
                <p style={{marginBottom:'20px'}}>Para acceder a su usuario y contraseña de BOT PLUS introduzca su DNI/CIF y el número de la 
                factura o pedido de compra de <i>Colección Consejo</i> o <i>BOT PLUS</i> y pulse "Obtener claves".
                </p>
                {(mensaje != '') ? 
                    <p className="fs fs15 fw fw100 FlamaBook greyColor" style={{textAlign:'center'}}>{mensaje}</p>
                :
                    <></>
                }
                <div className="cover cover__input" >
                    <label className="fs fs15 fw fw400 greyColor2" style={{fontFamily:'Flama, sans-serif'}} >DNI / CIF</label>
                    <input type="text" name="usuario" placeholder="DNI o CIF"  value = {dni} onChange = {(e) => cambiaDNI(e.target.value)}/>
                </div>
                <div className="cover cover__input" >
                    <label className="fs fs15 fw fw400 greyColor2" style={{fontFamily:'Flama, sans-serif'}}  >Número de factura o pedido</label>
                    <input type="text" name="usuario" placeholder="Número de factura o pedido" value={nfact} onChange = {(e) => cambiaNFact(e.target.value)}/>
                </div>
                <div className="cover cover__buttons" >
                    <button className="button button__purple" onClick={(e) => clickGetClaves()}> Obtener Claves</button>
                </div>
            </form>
        :(estado === 2) ?
            <div style={{height: '100%', width: '100%', cursor:'wait', overflowY: 'hidden',}}>
                <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '35px'}}>Buscando información...</p>
            </div>
        :(estado === 3) ?
            <div>
                <p className="fs fs20 fw fw100 FlamaBook greyColor"
                    style={{marginBottom:'40px', textAlign:'center'}}>
                        ¿Desea que el CGCOF guarde su correo electrónico para mejorar
                        nuestro servicio?
                </p>
                <div className="cover cover__buttons" >
                    <button className="button" style={{backgroundColor:'#93C462', color:'#ffffff'}} 
                            onClick={(e) => clickSi()}>
                        <img src="/img/checkGreen.svg" style={{verticalAlign:'middle'}}  />
                        &nbsp;&nbsp;
                        <span  style={{verticalAlign:'middle'}}>Sí</span>
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button className="button" style={{backgroundColor:'#B7B7B7', color:'#ffffff'}} 
                        onClick={(e) => clickNo()}>
                        <img src="/img/checkKO.svg" style={{verticalAlign:'middle'}}  />
                        &nbsp;&nbsp;
                        <span  style={{verticalAlign:'middle'}}>No</span>
                    </button>
                </div>
            </div>
        :(estado === 4)?
            <form  onSubmit={(e) => e.preventDefault()}>
                {(mensaje != '') ? 
                    <p className="fs fs15 fw fw100 FlamaBook greyColor" style={{textAlign:'center'}}>{mensaje}</p>
                :
                    <></>
                }
                <div className="cover cover__input" >
                    <label className="fs fs15 fw fw400 greyColor2" style={{fontFamily:'Flama, sans-serif'}} >Correo electrónico</label>
                    <input type="text" name="usuario" placeholder="Correo electrónico"  value = {correo} 
                        onChange = {(e) => cambiaCorreo(e.target.value)}/>
                </div>
                <div className="cover cover__input" >
                    <label className="fs fs15 fw fw400 greyColor2" style={{fontFamily:'Flama, sans-serif'}}  >Confirmación correo</label>
                    <input type="text" name="usuario" placeholder="Confirmación correo" value={confirma} 
                        onChange = {(e) => cambiaConfirma(e.target.value)}/>
                </div>
                <div className="text" style={{width:'100%', paddingBottom:'6px', fontSize:'16px', lineHeight:'20px', verticalAlign:'baseline'}}  >
                <p className="fs fs16 fw fw100 FlamaBook greyColor" 
                            style={{width:'100%', textAlign:'left',  paddingRight: '10px', fontWeight:'normal', textAlign: 'justify',
                                borderBottomStyle: 'solid', borderBottomWidth: '1px', borderBottomColor: '#e8e8e8'
                                , paddingBottom: '20px'}}>
                        <label>
                            <input type="checkbox" checked={opt1} style={estiloCheckBox} onChange={(e) => cambiaOpt1(e.target.checked)} /> 
                            <span style={{paddingLeft:'10px'}}> 
                                Mediante el marcado de la casilla, el usuario acepta el uso 
                                de su correo electrónico para la gestión de Bot PLUS y para 
                                todas las comunicaciones accesorias derivadas del uso de la 
                                aplicación.
                            </span>
                        </label>                        
                    </p>
                    <p className="fs fs15 fw fw100 FlamaBook greyColor" 
                            style={{width:'100%', textAlign:'left',  paddingRight: '10px', fontWeight:600, textAlign: 'justify'
                                    ,paddingTop: '20px'}}>
                                Asimismo, solicitamos su consentimiento expreso para el envío de comunicaciones 
                                electrónicas relativas a los servicios y productos que ofrecemos e información 
                                que pueda resultar de su interés rellenando previamente su correo electrónico y 
                                marcando la siguiente casilla                                        
                    </p>
                    <p className="fs fs16 fw fw100 FlamaBook greyColor" 
                            style={{width:'100%', textAlign:'left',  paddingRight: '10px', fontWeight:'normal', textAlign: 'justify'
                                    ,paddingTop: '10px'}}>
                        <label>
                            <input type="checkbox" checked={opt2} style={estiloCheckBox} onChange={(e) => cambiaOpt2(e.target.checked)} /> 
                            <span style={{paddingLeft:'10px'}}> 
                                Sí, deseo recibir información comercial (publicidad) sobre productos 
                                y servicios del Consejo General de Colegios Oficiales de Farmacéuticos: 
                                BOT PLUS; contenido de portalfarma.com; Plan Nacional de Formación Continuada; 
                                Plan Estratégico para el desarrollo de la Atención Farmacéutica; campañas sanitarias; 
                                congresos y jornadas profesionales; revista Farmacéuticos e informes técnico 
                                profesionales. <a href='/PDatos/ProteccionDatos.html' target='_blank' style={{color:'#93c462'}}>Información 
                                sobre protección de datos</a>.
                            </span>
                        </label>                        
                    </p>
                </div>
                <div className="cover cover__buttons" >
                    <button className="button" style={{backgroundColor:'#93C462', color:'#ffffff'}} 
                            onClick={(e) => clickAceptar()}>
                        <img src="/img/checkGreen.svg" style={{verticalAlign:'middle'}}  />
                        &nbsp;&nbsp;
                        <span  style={{verticalAlign:'middle'}}>Aceptar</span>
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button className="button" style={{backgroundColor:'#B7B7B7', color:'#ffffff'}} 
                        onClick={(e) => props.cancela()}>
                        <img src="/img/checkKO.svg" style={{verticalAlign:'middle'}}  />
                        &nbsp;&nbsp;
                        <span  style={{verticalAlign:'middle'}}>Cancelar</span>
                    </button>
                </div>
            </form>
        :(estado === 5) ?
            <div>
                <p className="fs fs20 fw fw100 FlamaBook greyColor"
                    style={{marginBottom:'20px', marginTop:'20px', textAlign:'center'}}>
                        Usuario:
                        <span style={{fontWeight: 600, marginLeft:'20px'}}>{datosAcceso.usuarioActual}</span>
                </p>
                <p className="fs fs20 fw fw100 FlamaBook greyColor"
                    style={{marginBottom:'30px', textAlign:'center'}}>
                        Contraseña:
                        <span style={{fontWeight: 600, marginLeft:'20px'}}>{datosAcceso.passActual}</span>
                </p>
                <div className="cover cover__buttons" >
                    <button className="button" style={{backgroundColor:'#B7B7B7', color:'#ffffff'}} 
                        onClick={(e) => props.cancela()}>
                        <img src="/img/checkKO.svg" style={{verticalAlign:'middle'}}  />
                        &nbsp;&nbsp;
                        <span  style={{verticalAlign:'middle'}}>Cerrar</span>
                    </button>
                </div>
            </div>
        :
            <div style={{height: '100%', width: '100%', overflowY: 'hidden',}}>
                <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '30%'}}>Este paso no está implementado</p>
            </div>
    );
}

export default ModalRecupera;
