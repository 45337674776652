import React, { useState } from "react";
import FilaMenuIzquierda from "./FilaMenuIzquierda";

const MenuIzquierda = (props) => {
    const [seccionActiva,setSeccionActiva] = useState(props.activo)
    const [apartados,setApartados] = useState(props.apartados);
    //const apartadosHTML = apartados.map(apartado => <FilaMenuIzquierda apartado={apartado} activo={seccionActiva}></FilaMenuIzquierda>);
    const apartadosHTML = (props.apartados && props.apartados.length>0) ?
        props.apartados.map(apartado => <FilaMenuIzquierda apartado = {apartado}
                                                            activo = {seccionActiva} 
                                                            anclas = {props.anclas} 
                                                            ancla={props.anclas.find(ancla => ancla.idAncla === apartado.Ancla)} 
                                                            cambiaImgIndex = {props.cambiaImgIndex}
                                        ></FilaMenuIzquierda>) :
        <></>;
    //const apartadosHTML = props.apartados.map(apartado => <FilaMenuIzquierda apartado = {apartado} activo = {seccionActiva} ></FilaMenuIzquierda>)
    return (
        <div class="nav__info active">
            {apartadosHTML}
        </div>
    )
}
export default MenuIzquierda;
