import Acordeon from "../Composicion/Acordeon";
import FarmacogeneticaBioPA from "./FamacogeneticaBioPA";

const FarmacogeneticaPA = (props) => {
    const farmacogenetica = props.farmacogenetica;
    const biomarcadores = farmacogenetica.Biomarcadores.map(biom => <FarmacogeneticaBioPA biomarcador={biom}></FarmacogeneticaBioPA>);
    const titulo = farmacogenetica.PrincipioActivo;

    var contenidoAcordeon = (
        <div>
            {biomarcadores}
        </div>
    );
    return (
        <div style={{marginTop: '20px'}}>
        {(props.esAcordeon) ?
            <Acordeon titulo = {titulo} contenido={contenidoAcordeon} activo={true} tipo={2}></Acordeon>
        :
            <div>
                {biomarcadores}
            </div>
        }
        </div>
    )
}
export default FarmacogeneticaPA;
