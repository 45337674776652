import { rootPath } from "../../../services/utils";

const LinksFichaFM = (props) => {
    const ficha = props.ficha;

    const CambiaPaginaPorId = (pagina, id) => {
        props.cambiaPagina({
            newpagina: pagina,
            idsSel: [id,],
        });
    }


    return (
        <div class="dispensacion-facturacion">
            <div class="extras">
                <div class="elemento" onClick={() => CambiaPaginaPorId('BInteraccionesMUH',ficha.Id)} style={{cursor:'pointer'}}>
                    <div class="mask" >
                        <img src={rootPath + "img/ficha/arrowsGreen.svg"} />
                    </div>
                    <span>Interacciones</span>
                </div>
            </div>
        </div>
    )
}
export default LinksFichaFM;
