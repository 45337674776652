import { useEffect, useState } from "react";
import SelectorArbol from "../../Utilidades/Selector/SelectorArbol/SelectorArbol";
import LoadingPopup from "../../Resultados/Loading.Popup";
import BP2022Service from "../../../services/user.service";
import { GetAntecesorWhithClassName, RemoveClassNameToDoubleClassItems } from "../../../services/utils";
import Selector from "../../Utilidades/Selector/Selector";
import SelectorMultiple from "../../Utilidades/Selector/SelectorMultiple";
import SelectorMulti2 from "../../Utilidades/Selector/SelectorMulti2";
import SelectorTree from "../../Utilidades/Selector/SelectorArbol/SelectorTree";
import SelectorEdad from "../../Utilidades/Selector/SelectorEdad/SelectorEdad";
import ResumenBusqueda from "../ResumenBusqueda";
import SelectorArbol3 from "../../Utilidades/Selector/SelectorArbol3/SelectorArbol3";

const FBusquedaSaborEdad = (props) => {
    const [datosFormulario, setDatosFormulario] = useState({
        arbolDieto: null,
        listaSabores: null,
    });
    const [seleccionFormulario, setSeleccionFormulario] = useState({
        itemDieto: (props.idDieto) ? {codigo : props.idDieto, valor : ''} : null,
        idsSabores: props.idsSabores,
        edad: props.edad,
        undEdad: props.undEdad,
        saborSolo: props.saborSolo,
    })
    const [txtInputDieto, setTxtInputDieto] = useState('');


    useEffect(() => {
        if (datosFormulario.arbolDieto === null)
        {
            DescargaDatosSaborEdad();
        }        
    },[]);

    const cambiaTxt = (newtxt) =>{
        setTxtInputDieto(newtxt);
    }



    const DescargaDatosSaborEdad = () => {      
        BP2022Service.datosFormularioSaborEdad()
        .then((response) => {
            var df = {
                arbolDieto : [response.data.Dietoterapicos],
                listaSabores : response.data.Sabores,
            };
            df.listaSabores.splice(0,0,{Codigo:0, Valor:'Seleccione un sabor'});
            setDatosFormulario(df);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const hayBusqueda = true;

    const clickBuscar = (e) =>{
        props.cambiaPagina({
            newpagina: 'BSaborEdadPPF',
            idDieto: (seleccionFormulario.itemDieto) ? seleccionFormulario.itemDieto.codigo : '',
            idsSabores: seleccionFormulario.idsSabores,
            edad: seleccionFormulario.edad,
            undEdad: seleccionFormulario.undEdad,
            saborSolo: seleccionFormulario.saborSolo,
        })
    }

    const BorraBusqueda = (e) =>{
        const formularioVacio={
            itemDieto:{codigo: '', valor: ''},
            idsSabores: [],
            edad: null,
            undEdad: null,
            saborSolo: false,
        }
        setTxtInputDieto('');
        setSeleccionFormulario(formularioVacio);
        var inputs = document.getElementsByClassName('inputSelector');
        if (inputs){
            for (var i=0; i<inputs.length; i++){
                inputs[i].value = "";
                const itemContainer = GetAntecesorWhithClassName(inputs[i], 'custom-select-container');
                if (itemContainer){
                    itemContainer.classList.remove('active');
                }
            }
        }
    }

    const CambiaDieto = (nuevovalor, nuevoTexto) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (nuevovalor !== '')
            nuevaSeleccion.itemDieto = {codigo: nuevovalor, valor: nuevoTexto};
        else
            nuevaSeleccion.itemDieto = null;
            setSeleccionFormulario(nuevaSeleccion);
    }

    const AnadeSabor = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (!nuevaSeleccion.idsSabores){
            nuevaSeleccion.idsSabores = [];
        }
        nuevaSeleccion.idsSabores.push(nuevovalor);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const BorraSabor = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var i = nuevaSeleccion.idsSabores.indexOf(valor);
        nuevaSeleccion.idsSabores.splice(i,1);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambiaCheckSolo = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.saborSolo = valor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioEdad = (nuevaedad, nuevaunidad) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.edad = nuevaedad;
        nuevaSeleccion.undEdad = nuevaunidad;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const construyeResumenBusqueda = () => {
        var res = [];
        if (seleccionFormulario.itemDieto){
            res.push ({titulo: "Dietoterápico", valor: seleccionFormulario.itemDieto.codigo + ' ' + seleccionFormulario.itemDieto.valor})
        }
        if ((seleccionFormulario.idsSabores) && (seleccionFormulario.idsSabores.length>0)){
            if (datosFormulario.listaSabores){
                var valor = '';
                for (var i =0; i<seleccionFormulario.idsSabores.length; i++){
                    var item = datosFormulario.listaSabores.find(x => x.Codigo === seleccionFormulario.idsSabores[i]);
                    if (item){
                        if (valor !== '')
                            valor += ", ";
                        valor += item.Valor;
                    }
                }
                if (valor != '')
                    res.push ({titulo: "Sabores", valor: valor});
            }

            if (seleccionFormulario.saborSolo){
                res.push({titulo: "Sólo puede tener los sabores seleccionados"})
            }else{
                res.push({titulo: "Puede contener sabor adicional"})
            }
        }

        if (seleccionFormulario.edad && seleccionFormulario.edad>0 && seleccionFormulario.undEdad && seleccionFormulario.undEdad != ""){
            var str = seleccionFormulario.edad.toString();
            if (seleccionFormulario.undEdad.toLowerCase()==="m"){
                str += " meses"
            }else{
                str += " años"
            }
            res.push({titulo: "Edad", valor: str});
        }

        
        return res;
    }


    const KeyDownForm = (e) =>{
        var keyCode = e.keyCode;
        if (keyCode === 13){
            clickBuscar(e);
        }
    }

    const estiloBotonesSup = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'space-between'} : {height:'76px', width:'100%', justifyContent: 'space-between', visibility:'hidden'})
    const estiloBotonesInf = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'center'} : {height:'76px', width:'100%', justifyContent: 'center', display:'none'})    

    const resumen = construyeResumenBusqueda(seleccionFormulario);

    const listaCodValor = (datosFormulario.listaSabores) ? datosFormulario.listaSabores.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];

    return (
        <div class="content" >

            { (datosFormulario.arbolDieto === null) ?
                <LoadingPopup titulo="Un momento" texto="Preparando formulario" esPorcentaje={true}></LoadingPopup>
            :
                <form class="content__results content__results__filters formulario__busqueda" id="main-filter"  onSubmit={(e) => e.preventDefault()}  onKeyDown={(e) => KeyDownForm(e)} tabIndex="0">
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesSup}>
                        <span class="button button__arrow button__green noUppercase reset-form" data-form="#main-filter" title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} style={{visibility:'hidden'}} >
                            Vaciar búsqueda
                        </span>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                        <span class="button button__arrow button__green reset-form" data-form="#main-filter" style={{textTransform:'none', fontSize: '14px', width:'146px'}} title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} >
                            Vaciar búsqueda
                        </span>
                    </div>
                    <div class="wrap wrap__title wrap__accordion" >
                        <div class="cover cover__filters cover__filters__full cover__filters__accordion cover__filters__accordion__checks" >
                            {/*<SelectorTree 
                                arbol = {datosFormulario.arbolDieto}
                                id='FBDiteoTree' 
                                valueSelected={(seleccionFormulario.itemDieto) ? seleccionFormulario.itemDieto.codigo : ''} 
                                textSelected={(seleccionFormulario.itemDieto) ? seleccionFormulario.itemDieto.valor : ''} 
                                ocultaCodigo={false} 
                                ocultaInputText={true} 
                                alCambiar={CambiaDieto}
                            ></SelectorTree>*/
                            }
                            {/*
                            <SelectorArbol2 arbol= {datosFormulario.arbolDieto[0].hijos} id="FBDietoArbol2" 
                                            valueSelected={(seleccionFormulario.itemDieto) ? seleccionFormulario.itemDieto.codigo : ''} 
                                            alCambiar={CambiaDieto}
                            />
                            */}
                            
                            <SelectorArbol3 
                                listaCompleta= {(datosFormulario.arbolDieto && datosFormulario.arbolDieto[0] && datosFormulario.arbolDieto[0].hijos) ? datosFormulario.arbolDieto[0].hijos : []} 
                                valorSeleccionado={(seleccionFormulario.itemDieto) ? seleccionFormulario.itemDieto.codigo : ''} 
                                alCambiar={CambiaDieto}
                                codigoJerarquico = {true}
                                muestraCodigo = {true}
                                placeholder = "Escriba el código o nombre del grupo" 
                                texto = {txtInputDieto}
                                cambiaTxt = {cambiaTxt}
                            />

                        </div>
                    </div>
                    <div class="wrap wrap__title" style={{marginTop:'20px'}}>
                        <div class="title" >
                            <h2 class="fs fs20 purpleColor" >Sabores</h2>
                        </div>
                        <div class="cover cover__filters" >
                            <div class="module module__filters module__units module__units__select" >
                                <div class="wrap wrap__2" >
                                    <SelectorMulti2 
                                        lista = {listaCodValor} 
                                        idHTML="FBDietoSabor" 
                                        valuesSelected={seleccionFormulario.idsSabores}
                                        alAnadir={AnadeSabor} 
                                        alBorrar={BorraSabor} 
                                        ancho="100%"
                                        tipoFiltradoLista={4}
                                    ></SelectorMulti2>
                                </div>
                                {(seleccionFormulario.saborSolo) ?
                                    <div class="checks checks__14" style={{marginTop:'15px'}} >
                                        <label><input type="radio" name="relacion" value="relacion1" onClick={(e) => CambiaCheckSolo(true)} checked />Exclusivamente los sabores seleccionados</label>
                                        <label><input type="radio" name="relacion" value="relacion2" onClick={(e) => CambiaCheckSolo(false)}/>Puede contener algún sabor adicional</label>
                                    </div>
                                :
                                    <div class="checks checks__14" style={{marginTop:'15px'}} >
                                        <label><input type="radio" name="relacion" value="relacion1" onClick={(e) => CambiaCheckSolo(true)} />Exclusivamente los sabores seleccionados</label>
                                        <label><input type="radio" name="relacion" value="relacion2" onClick={(e) => CambiaCheckSolo(false)} checked/>Puede contener algún sabor adicional</label>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div class="wrap wrap__title wrap__title__marginBot" >
                        <div class="title" >
                            <h2 class="fs fs20 purpleColor" >Edad</h2>
                        </div>
                        <div class="cover cover__filters" >
                            <div class="module module__filters module__units module__units__select" >
                                <SelectorEdad 
                                    edad={seleccionFormulario.edad} 
                                    unidad={seleccionFormulario.undEdad} 
                                    alCambiar={CambioEdad
                                }></SelectorEdad>
                            </div>
                        </div>
                    </div>
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesInf}>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                    </div>
                    <ResumenBusqueda condiciones={resumen}></ResumenBusqueda>
                </form>
            }
        </div>
    );
};

export default FBusquedaSaborEdad;
