import React, { useState } from "react";
import { divideArrayEnPartes, numberWithCommas, rootPath } from "../../../services/utils";

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import LinksFichaPA from './LinksFichaPA';
import FilaPictogramas from '../FichaMUH/InfoPrincipal/FilaPictogramas';

const MargenDerechoPA = (props) => {
    const [sliderRef, setSliderRef] = useState(null)
    const ficha = props.ficha;
    var imgDefecto = rootPath + "img/person.svg" ;
    if ((ficha.CodTipoRegistro==="PAV") || (ficha.CodTipoRegistro==="ASOV"))
        imgDefecto = rootPath + "img/perro.svg" ;
    if (ficha.CodTipoRegistro==="ING")
        imgDefecto = rootPath + "img/jabonLiquido.svg" ;
    const srcImagen =   (props.ficha.ImagenFormula != null ?
                            props.ficha.ImagenFormula.URL
                        :
                            ''
                        );

    const tieneImagenes = srcImagen !== '';    

    const maxHeight=180;
    const maxWidth=256;

    const estilhorizontal = {maxWidth: maxWidth.toString() + 'px', maxHeight: maxHeight.toString() + 'px', width: '100%', height: 'auto', cursor: 'pointer'}
    const estildefecto = {maxWidth: '180px', maxHeight: maxHeight.toString() + 'px', width: '100%', height: 'auto'}
    const estilvertical = {maxWidth: maxWidth.toString() + 'px', maxHeight: maxHeight.toString() + 'px', height: '100%', width: 'auto', cursor: 'pointer'}

    const loadImage = (e) => {
        var h = e.target.height;
        if (h===maxHeight){
            e.target.style.height = '100%';
            e.target.style.width = 'auto';
            e.target.style.maxWidth = maxWidth.toString() + 'px';
            e.target.style.maxHeight = maxHeight.toString() + 'px';
        }
    }

    const imagenesHTML = tieneImagenes ? <div style={{textAlign:'center'}}><p>&nbsp;</p><img class="imagen__medicamento" src={srcImagen} style={estilhorizontal} 
                                                                                        onLoad={(e) => loadImage(e)}  onClick={(e) => props.clickImagen(e)}/></div>
                                        : <div style={{textAlign:'center'}}><p>&nbsp;</p><img class="imagen__medicamento" src={imgDefecto} style={estildefecto} /></div>
                                                                               
    const pictogramasEnFilas = (((ficha.CodTipoRegistro === 'PAT') || (ficha.CodTipoRegistro === 'PATH') || (ficha.CodTipoRegistro === 'PATV')) ?
        null
        :
        divideArrayEnPartes(ficha.Pictogramas,5)   
    );


    const pictogramas = (pictogramasEnFilas) ? pictogramasEnFilas.map( fila => <FilaPictogramas fila={fila}></FilaPictogramas>) : <div></div>;

    const valorPsicotropo = (ficha.Psicotropo === "Sí") ? 'Sí. ' + ficha.EstupefacientePsicotropo.DescripcionPsicotropo : ficha.Psicotropo;
    const valorDopaje = (ficha.Dopaje === "Sí") ? 'Sí. ' + ficha.DescripcionDopaje.DescripcionPsicotropo : ficha.Dopaje;


    return (
        <div class="medicamento">
            <div class="nombre f-f-Flama-Medium">{ficha.NombreCompleto}</div>
                <div class="contenido__imagen">
                        {imagenesHTML}
                    {pictogramas}
                </div>
            {(ficha.CodTipoRegistro === "PAH" || ficha.CodTipoRegistro === "ASO" || ficha.CodTipoRegistro === "ASOH") ?
                <div class="detalles">
                    <div class="condiciones">
                        <div class="fila">
                            <div class="col-i" style={{width:'160px'}}>Dopaje</div>
                            <div class="col-d" style={{width:'50px'}} title={valorDopaje}>{ficha.Dopaje}</div>
                        </div>
                        <div class="fila">
                            <div class="col-i" style={{width:'160px'}}>Psicotropo/Estupefaciente</div>
                            <div class="col-d" style={{width:'50px'}} title={valorPsicotropo}>{ficha.Psicotropo}</div>
                        </div>
                    </div>
                </div>
            :
                <div>&nbsp;</div>
            }
            <LinksFichaPA ficha={ficha} cambiaPagina={props.cambiaPagina} ></LinksFichaPA>
        </div>
    )
}
export default MargenDerechoPA;

