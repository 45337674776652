import { GetAntecesorWhithClassName, numberWithCommas, rootPath } from "../../../../../services/utils";


const FilaLMR = (props) => {

    const lmr = props.lmr;
    const info = lmr.Observaciones;
    const tieneInfo = (info !== null && info !=='')
    const tooltip =  (!tieneInfo ? '' :
        <span>
            <img src={rootPath + "img/infoGreen.svg"} alt="" onMouseOver={(e) => handleMouseOverIcon(e)} onMouseOut={(e) => handleMouseOutIcon(e)}/>
            <span class="tooltip" >
                {info}
            </span>
        </span>
    );
    const clasesDiv = (tieneInfo ? 'column fs fs14 blackColor FlamaBook hasTooltip' : 'column fs fs14 blackColor FlamaBook');

    const handleMouseOverIcon = (e) =>{
        var itemImg = e.target;
        var itemTooltip = itemImg.parentElement.getElementsByClassName("tooltip")[0];
        var itemAccordionContent = GetAntecesorWhithClassName(itemImg,'accordion__content');
        var itemDropdownContent = GetAntecesorWhithClassName(itemImg,'dropdown__content');
        var itemInfoContent = GetAntecesorWhithClassName(itemImg,'infoContent');
        if (itemTooltip && itemAccordionContent){
            var rect = itemTooltip.getBoundingClientRect();
            var rectdiv = itemAccordionContent.getBoundingClientRect();
            var rectdiv2 = itemDropdownContent.getBoundingClientRect();
            var rectdiv3 = itemInfoContent.getBoundingClientRect();
            var finYdiv = (Math.round(rectdiv.y)+Math.round(rectdiv.height));
            var finYdiv2 = (Math.round(rectdiv2.y)+Math.round(rectdiv2.height));
            var finYdiv3 = (Math.round(rectdiv3.y)+Math.round(rectdiv3.height));
            var finYtootip = (Math.round(rect.y)+Math.round(rect.height));
            if (finYtootip > finYdiv - 10){
                //SetAlturadivinicial(Math.round(rectdiv.height).toString() + 'px');
                var incremento = finYtootip - (finYdiv - 10);
                var newAltura = Math.round(rectdiv.height) + incremento;
                itemAccordionContent.style.height = newAltura.toString() + 'px';
            }
            if (finYtootip > finYdiv2 - 10){
                //SetAlturadivinicial2(Math.round(rectdiv2.height).toString() + 'px');
                var incremento = finYtootip - (finYdiv2 - 10);
                var newAltura = Math.round(rectdiv2.height) + incremento;
                itemDropdownContent.style.height = newAltura.toString() + 'px';
            }
            if (finYtootip > finYdiv3 - 10){
                //SetAlturadivinicial3(Math.round(rectdiv3.height).toString() + 'px');
                var incremento = finYtootip - (finYdiv3 - 10);
                var newAltura = Math.round(rectdiv3.height) + incremento;
                itemInfoContent.style.height = newAltura.toString() + 'px';
            }
        }
    }

    const handleMouseOutIcon = (e) =>{
        var itemImg = e.target;
        var itemAccordionContent = GetAntecesorWhithClassName(itemImg,'accordion__content');
        var itemDropdownContent = GetAntecesorWhithClassName(itemImg,'dropdown__content');
        var itemInfoContent = GetAntecesorWhithClassName(itemImg,'infoContent');
        if (itemInfoContent) { // && alturadivinicial2 !== ''){
            itemInfoContent.style.height = ''; //alturadivinicial2;
        }
        if (itemAccordionContent){ //} && alturadivinicial !== ''){
            itemAccordionContent.style.height ='';
        }
        if (itemDropdownContent){ //} && alturadivinicial !== ''){
            itemDropdownContent.style.height ='';
        }
    }


    return (
        <div class="table__row table__row__normal fw fw300 uppercase">
            <div class={clasesDiv} >
                <p>
                    <span style={{cursor:'pointer'}} >
                        {lmr.Tejido}
                    </span>
                    {tooltip}
                </p>
            </div>
            <div class={clasesDiv} >
                <p>
                    {(lmr.LMR>0) ? 
                        <span>{numberWithCommas(lmr.LMR)}</span>
                    : 
                        <span></span>
                    }
                    {(lmr.Unidad) ? 
                        <span>&nbsp;{lmr.Unidad}</span>
                    : 
                        <span></span>
                    }
                </p>
            </div>
        </div>
    )
}
export default FilaLMR;
