import { useEffect, useState } from "react";
import SelectorArbol from "../../Utilidades/Selector/SelectorArbol/SelectorArbol";
import LoadingPopup from "../../Resultados/Loading.Popup";
import BP2022Service from "../../../services/user.service";
import { RemoveClassNameToDoubleClassItems } from "../../../services/utils";
import Selector from "../../Utilidades/Selector/Selector";
import SelectorMultiple from "../../Utilidades/Selector/SelectorMultiple";
import SelectorComboBox from "../../Utilidades/Selector/SelectorComboBox";
import SelectorUno from "../../Utilidades/Selector/SelectorUno";
import SelectorMulti from "../../Utilidades/Selector/SelectorMulti";
import ResumenBusqueda from "../ResumenBusqueda";
import SelectorTree from "../../Utilidades/Selector/SelectorArbol/SelectorTree";
import SelectorEdad from "../../Utilidades/Selector/SelectorEdad/SelectorEdad";
import SelectorFechas from "../../Utilidades/Selector/SelectorFecha/SelectorFecha";
import SelectorNumero from "../../Utilidades/Selector/SelectorNumero";
import SelectorSNX from "../../Utilidades/Selector/SelectorSNX";
import SelectorEnfRel from "../../Utilidades/Selector/SelectorEnfRel/SelectorEnfRel";
import SelectorMulti2 from "../../Utilidades/Selector/SelectorMulti2";
import SelectorComposicion from "../../Utilidades/Selector/SelectorComposicion/SelectorComposicion";

const FBTest= (props) => {
    const [datosFormulario, setDatosFormulario] = useState({
        arbol: null,
        lista1: null,
        lista2: null,
        lista3: null,
        lista4: null,
    });
    const [seleccionFormulario, setSeleccionFormulario] = useState({
        itemA: (props.idA) ? {codigo : props.idA, valor : ''} : null,
        idL1: props.idL1,
        idL2: props.idL2,
        idsL3: props.idsL3,
        enfsRel: (props.enfsRel ? props.enfsRel : []),
        snx: props.snx,
        edad: props.edad,
        unidad: props.unidad,
        inicio: props.inicio,
        fin: props.fin,
        num: 35,
        pas: (props.pas ? props.pas : []),
        pasolo: (props.pasolo ? props.pasolo : []),
    })

    useEffect(() => {
        if (datosFormulario.arbol === null)
        {
            DescargaDatos();
        }        
    },[]);

    const DescargaDatos = () => {      
        BP2022Service.datosFormularioTest()
        .then((response) => {
            var df = {
                arbol : [response.data.Dietoterapicos],
                lista1 : response.data.Sabores,
                lista2 : JSON.parse(JSON.stringify(response.data.Sabores)),
                lista3 : response.data.NombresMeds,  
                lista4 : response.data.Enfermedades,            
                listaPA : response.data.PrincipiosActivos,
            };
            df.lista1.splice(0,0,{Codigo:0, Valor:'Seleccione un valor'});
            df.lista2.splice(0,0,{Codigo:0, Valor:'Seleccione un valor'});
            df.lista3.splice(0,0,{Codigo:0, Valor:'Seleccione valores'});
            df.lista4.splice(0,0,{Codigo:0, Valor:'Seleccione enfermedad'});
            df.listaPA.splice(0,0,{Codigo:0, Valor: 'Seleccione componente'});
            setDatosFormulario(df);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };


    const construyeResumenBusqueda = () => {
        var res = [];
        if (seleccionFormulario.itemA != null){
            res.push ({titulo: "Arbol", valor: seleccionFormulario.itemA.codigo + ' ' + seleccionFormulario.itemA.valor})
        }
        if (seleccionFormulario.idL1>0){
            if (datosFormulario.lista1){
                var item = datosFormulario.lista1.find(x => x.Codigo === seleccionFormulario.idL1);
                if (item)
                    res.push ({titulo: "Lista 1", valor: item.Valor});
            }
        }
        if (seleccionFormulario.idL2>0){
            if (datosFormulario.lista2){
                var item = datosFormulario.lista2.find(x => x.Codigo === seleccionFormulario.idL2);
                if (item)
                    res.push ({titulo: "Lista 2", valor: item.Valor});
            }
        }
        if ((seleccionFormulario.idsL3) && (seleccionFormulario.idsL3.length>0)){
            if (datosFormulario.lista3){
                var valor = '';
                for (var i =0; i<seleccionFormulario.idsL3.length; i++){
                    var item = datosFormulario.lista3.find(x => x.Codigo === seleccionFormulario.idsL3[i]);
                    if (item){
                        if (valor !== '')
                            valor += ", ";
                        valor += item.Valor;
                    }
                }
                if (valor != '')
                    res.push ({titulo: "Lista 3", valor: valor});
            }
        }
        return res;
    }

    const resumen = construyeResumenBusqueda(seleccionFormulario);

    const clickBuscar = (e) =>{
        const datosnuevapagina = {
            newpagina: 'BTest',
            idA: (seleccionFormulario.itemA) ? seleccionFormulario.itemA.codigo : '',
            idL1: seleccionFormulario.idL1,
            idL2: seleccionFormulario.idL2,
            idsL3: seleccionFormulario.idsL3,
            snx: seleccionFormulario.snx,
            edad: seleccionFormulario.edad,
            unidad: seleccionFormulario.unidad,
            inicio: seleccionFormulario.inicio,
            fin: seleccionFormulario.fin,
            enfsRel: seleccionFormulario.enfsRel,
            pas: seleccionFormulario.pas,
            pasolo: seleccionFormulario.pasolo,
        }
    }

    const BorraBusqueda = (e) =>{
        const formularioVacio={
            itemA: null,
            idL1: 0,
            idL2: 0,
            idsL3: [],
            snx: 'X',
            edad: null,
            unidad: '',
            inicio: null,
            fin: null,
            num: 0,
            enfsRel : [],
            pas : [],
            pasolo : true,
        }
        //let df = JSON.parse(JSON.stringify(datosFormulario));
        //setDatosFormulario(df);
        setSeleccionFormulario(formularioVacio);
    }

    const CambioNumero = (nuevonumero) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.num = nuevonumero;
        setSeleccionFormulario(nuevaSeleccion);
    }
    const CambioSNX = (nuevoSNX) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.snx = nuevoSNX;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioEdad = (nuevaedad, nuevaunidad) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.edad = nuevaedad;
        nuevaSeleccion.unidad = nuevaunidad;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioFechas= (inicio, fin) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.inicio = inicio;
        nuevaSeleccion.fin = fin;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambiaArbol = (nuevovalor, nuevoTexto) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (nuevovalor !== '')
            nuevaSeleccion.itemA = {codigo: nuevovalor, valor: nuevoTexto};
        else
            nuevaSeleccion.itemA = null;
            setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioLista1 = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idL1 = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioLista2 = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idL2 = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const BorraLista2 = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idL2 = null;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddItemLista3 = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idsL3.push(nuevovalor);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveItemLista3 = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var i = nuevaSeleccion.idsL3.indexOf(valor);
        nuevaSeleccion.idsL3.splice(i,1);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const UpdateEnfermedad = (idEnfermedad, epigrafe) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var item = nuevaSeleccion.enfsRel.find(item => item.codigo===idEnfermedad);
        if (item){
            item.epi = epigrafe;
        }else{
            nuevaSeleccion.enfsRel.push({codigo: idEnfermedad, epi: epigrafe});
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveEnfermedad = (idEnfermedad) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.enfsRel = nuevaSeleccion.enfsRel.filter(item => item.codigo!==idEnfermedad);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddSal = (idPA, idSal) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (pa){
            if (!pa.sales){
                pa.sales = []
            }
            var sal = pa.sales.find(item => item.codigo === idSal);
            if (!sal){
                pa.sales.push({codigo:idSal});
            }
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveSal = (idPA, idSal) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (pa){
            if (!pa.sales){
                pa.sales = []
            }
            pa.sales = pa.sales.filter(item => item.codigo !== idSal);
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddCant = (idPA, idUnd, cantidad) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (pa){
            if (!pa.cants){
                pa.cants = []
            }
            var cant = pa.cants.find(item => item.cod === idUnd && item.cant === cantidad);
            if (!cant){
                pa.cants.push({cod: idUnd, cant: cantidad});
            }
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveCant = (idPA, idUnd, cantidad) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (pa){
            if (!pa.cants){
                pa.cants = []
            }
            pa.cants = pa.cants.filter(item => item.cod !== idUnd || item.cant !== cantidad);
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddPA = (idPA) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (!pa){
            nuevaSeleccion.pas.push({
                codigo: idPA,
                sales: [],
                cants: [],
            })
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemovePA = (idPA) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.pas = nuevaSeleccion.pas.filter(item => item.codigo!==idPA);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambiaPASolo = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.pasolo = valor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const ClearLista3 = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idsL3 = [];
        setSeleccionFormulario(nuevaSeleccion);
    }

    const listaCodValor1 = (datosFormulario.lista1) ? datosFormulario.lista1.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValor2 = (datosFormulario.lista2) ? datosFormulario.lista2.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValor3 = (datosFormulario.lista3) ? datosFormulario.lista3.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValor4 = (datosFormulario.lista4) ? datosFormulario.lista4.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorPA = (datosFormulario.listaPA) ? datosFormulario.listaPA.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    
    const clasedivmodulefilters = (datosFormulario.ancho) ? "cover cover__filters cover__filters__2 cover__filters__2--1 ancho" : "cover cover__filters cover__filters__2 cover__filters__2--1";

    return (
        <div class="content" >

            { (datosFormulario.arbol === null) ?
                <LoadingPopup titulo="Un momento" texto="Preparando formulario" esPorcentaje={true}></LoadingPopup>
            :
                <form class="content__results content__results__filters formulario__busqueda" id="main-filter"  onSubmit={(e) => e.preventDefault()}>
                    <div class="module module__buttons">
                        <span class="button button__arrow button__green noUppercase reset-form" data-form="#main-filter" title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} >
                            Vaciar búsqueda
                        </span>
                    </div>
                    <div class="wrap wrap__title wrap__accordion" >
                        <div class="cover cover__filters cover__filters__full cover__filters__accordion cover__filters__accordion__checks" >
                            <SelectorTree arbol = {datosFormulario.arbol} id='FBDiteoTree' valueSelected={(seleccionFormulario.itemA) ? seleccionFormulario.itemA.codigo: ''} textSelected={(seleccionFormulario.itemA) ? seleccionFormulario.itemA.valor: ''} ocultaCodigo={false} ocultaInputText={true} alCambiar={CambiaArbol}></SelectorTree>
                        </div>
                    </div>
                    <div class="wrap wrap__title" >
                        <div class="title" >
                            <h2 class="fs fs20 purpleColor" >Sabores</h2>
                        </div>
                        <div class="cover cover__filters" >
                            <div class="module module__filters module__units module__units__select" >
                                <div class="wrap wrap__2" >
                                    <SelectorComboBox lista = {listaCodValor1} 
                                        idHTML="FBDietoSabor" 
                                        valueSelected={seleccionFormulario.idL1} 
                                        alCambiar={CambioLista1} 
                                        ancho="60%"
                                    ></SelectorComboBox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class={clasedivmodulefilters} >
                        <div class="module module__filters" >
                            <h3 class="title fs fs16 greyColor2" >
                               Tipo selector de una opcion
                            </h3>
                            <SelectorUno lista = {listaCodValor2} 
                                        idHTML="FBDietoSabor2" 
                                        valueSelected={seleccionFormulario.idL2} 
                                        alCambiar={CambioLista2} alBorrar={BorraLista2} 
                                        ancho="100%"
                            ></SelectorUno>
                        </div>
                    </div>
                    <div class={clasedivmodulefilters} >
                        <div class="module module__filters" >
                            <h3 class="title fs fs16 greyColor2" >
                               Tipo selector de muchas opciones
                            </h3>
                            <SelectorMulti2 lista = {listaCodValor3} 
                                        idHTML="FBTestL3" 
                                        valuesSelected={seleccionFormulario.idsL3} 
                                        alAnadir={AddItemLista3} 
                                        alBorrar={RemoveItemLista3} 
                                        ancho="100%"
                                        tipoFiltradoLista={1}
                            ></SelectorMulti2>
                        </div>
                    </div>
                    <div class="wrap wrap__title wrap__title__marginBot" >
                        <div class="title" >
                            <h2 class="fs fs20 purpleColor" >Edad</h2>
                        </div>
                        <div class="cover cover__filters" >
                            <div class="module module__filters module__units module__units__select" >
                                <SelectorEdad edad={seleccionFormulario.edad} unidad={seleccionFormulario.unidad} alCambiar={CambioEdad}></SelectorEdad>
                            </div>
                        </div>
                    </div>
                    <div class="cover cover__filters cover__filters__2 cover__filters__2--1 cover__filters__2--1__50 calendars" >
                        <SelectorFechas titulo="Fechas de ejemplo" alCambiar={CambioFechas} inicio={seleccionFormulario.inicio} fin={seleccionFormulario.fin}></SelectorFechas>
                        <SelectorNumero alCambiar={CambioNumero} num={seleccionFormulario.num} valorVacio={0}></SelectorNumero>
                        <SelectorSNX alCambiar={CambioSNX} valorSNX = {seleccionFormulario.snx} titulo= "Campo S/N" idHTML="FBTest01"></SelectorSNX>
                    </div>
                    <SelectorEnfRel lista = {listaCodValor4} 
                                idHTML="FBTestL3" 
                                valuesSelected={seleccionFormulario.enfsRel} 
                                alUpdate={UpdateEnfermedad} 
                                alBorrar={RemoveEnfermedad} 
                                ancho="100%"
                    ></SelectorEnfRel>

                    <SelectorComposicion lista = {listaCodValorPA} 
                                idHTML="FBTestLCompos" 
                                valuesSelected={seleccionFormulario.pas} 
                                pasolo={seleccionFormulario.pasolo}
                                alAnadirPA={AddPA} 
                                alBorrarPA={RemovePA} 
                                alAnadirSal={AddSal} 
                                alBorrarSal={RemoveSal} 
                                alAnadirCant={AddCant} 
                                alBorrarCant={RemoveCant} 
                                alCambiarPASolo={CambiaPASolo}
                                ancho="100%"
                                titulo={"Principios activos"}
                                muestraChecks={true}
                                nombreComponentes = {"principios activos"}
                                tipo={"H"}
                    ></SelectorComposicion>

                    <div class="module module__buttons module__buttons__buscar">
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                    </div>
                    <ResumenBusqueda condiciones={resumen}></ResumenBusqueda>
                </form>
            }
        </div>
    );
};

export default FBTest;

/*
                    <div class="cover cover__filters cover__filters__full cover__filters__accordion cover__filters__accordion__checks" >
                        <SelectorArbol arbol = {datosFormulario.lista1} id="ddd" valueSelected={seleccionFormulario.idL1} ocultaCodigo={true} placeholder={"filtre la lista"} sinHijos={true}></SelectorArbol>
                    </div>
*/