const FilaTablaProductos = (props) => {

    const producto = props.producto;
    const clasesDivNombre = 'column fs fs12 fw fw600 greenColor4 underline';
    const clasesDivCodigo = 'column fs fs14 blackColor FlamaBook';

    const CambiaPaginaFicha = (id) => {
        props.cambiaPagina({
            newpagina: 'FichaPPF',
            newid: id,
        });
    }

    return (
        <div class="table__row table__row__normal fw fw300 uppercase">
            <div class={clasesDivCodigo} style={{maxWidth:'130px'}}>
                <p>
                    {producto.Codigo}
                </p>
            </div>
            <div class={clasesDivNombre} >
                <p>
                    <span style={{cursor:'pointer'}} onClick={(e) => CambiaPaginaFicha(producto.Id)}>
                        {producto.NombreCompleto}
                    </span>
                </p>
            </div>
        </div>
    )
}
export default FilaTablaProductos;