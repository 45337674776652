import { useEffect, useState } from "react";

function SelectorSNX(props){
    const [valorSNX, setValorSNX] = useState(props.valorSNX);
    const idChkAct = props.idHTML+'__Act';
    const idChkSN = props.idHTML+'__SN';
    const txtS = (props.txtS ? props.txtS : 'Sí');
    const txtN = (props.txtN ? props.txtN : 'No');
    const titulo = props.titulo;
    const idOrigen = props.idOrigen;

    const checkedChkAct = (valorSNX === "X" ? false : true);
    const checkedChkSN = (valorSNX === "N" ? false : true);


    useEffect(() => {
        setValorSNX(props.valorSNX);   
    },  [props.valorSNX]);

    const calculaSNX = (act, SN) =>{
        var valor='X';
        if (act){
            if (SN){
                valor = 'S'
            }else{
                valor = 'N'
            }
        }
        return valor;
    }

    const cambiaCheckActivo = (e) =>{
        var checkAct  = e.target;
        var checkSN = document.getElementById(idChkSN);
        var valor = calculaSNX(checkAct.checked, checkSN.checked);
        cambiaSeleccion(valor);
    }

    const cambiaCheckSN = (e) =>{
        var checkSN  = e.target;
        var checkAct = document.getElementById(idChkAct);
        var valor = calculaSNX(checkAct.checked, checkSN.checked);
        cambiaSeleccion(valor);
    }

    const cambiaSeleccion = (valor) =>{
        setValorSNX(valor);
        props.alCambiar(valor,idOrigen);
    }

    const estiloDiv = (props.estiloDiv ? props.estiloDiv : {})

    return (
        <div class="module module__filters module__filters__toggle" style={estiloDiv}>
            <div class="checks checks__title" >
                <label style={{fontFamily:'Flama',  fontSize: '16px', width: '100%', lineHeight:'20px'}}>
                    <input type="checkbox" id={idChkAct} checked={checkedChkAct} onChange={e => cambiaCheckActivo(e)} style={{cursor:'pointer'}}/>
                    <span dangerouslySetInnerHTML={{__html:titulo}}></span>
                </label>
            </div>
            <div class="toggleIOS" >
                <input type="checkbox" id={idChkSN} checked={checkedChkSN} disabled = {!checkedChkAct} onChange={e => cambiaCheckSN(e)} style={{cursor:'pointer'}}/>

                <label class="bg" for={idChkSN}  style={{boxShadow:'none'}} >
                    <div class="circle" ></div>
                </label>
            </div>
        </div>
    )
}
export default SelectorSNX;
