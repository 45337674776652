import { elementIsOfClass } from "../../../../services/utils";
import FiltroLista from "./FiltroLista";
import FiltroListBox from "./FiltroListBox";
import FiltroText from "./FiltroText";
import GrupoCheckBoxFiltro from "./GrupoCheckBoxFiltro";

function FiltroBusqueda(props){
    const filtro = props.filtro
    var contenido;

    const claseAcordeon = (filtro.abierto ? "accordion__module accordion__module__filters active" : "accordion__module accordion__module__filters");

    const handleClickAcordeonFiltro = (e) =>{
        var item = e.target;
        while (item && !elementIsOfClass(item,'accordion__header'))
            item = item.parentElement;
        if (item)
            item.parentElement.classList.toggle('active');
    }


    if (filtro.tipo ==='List')
         contenido = (
            <FiltroLista filtro = {filtro} cambiaLista={props.cambiaLista}>
            </FiltroLista>
        );
    else if ((filtro.tipo ==='Text')||(filtro.tipo==='CN'))
         contenido = (
            <FiltroText filtro = {filtro} cambiaLista={props.cambiaLista}>
            </FiltroText>
        );
    else if (filtro.tipo ==='ListBox'){
        contenido = (
            <FiltroListBox filtro = {filtro} cambiaLista={props.cambiaLista}>
            </FiltroListBox>
        );
    }else /* filtro.tipo === 'Check' */
    contenido = (
        <GrupoCheckBoxFiltro filtro={filtro} cambiaCheck= {props.cambiaCheck} >                
        </GrupoCheckBoxFiltro>
    );

    return(
        <div class={claseAcordeon}>
            <div class="accordion__header" onClick={e => handleClickAcordeonFiltro(e)}>
                <div class="text">
                    <h3 class="title fs fs16 fw fw400 purpleColor">{filtro.nombre}</h3>
                </div>
            </div>
            <div class="accordion__content">
                {contenido}
            </div>
        </div>
    )

 }    
export default FiltroBusqueda;


