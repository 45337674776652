import Slider from 'react-slick'
import React, { useState } from "react";
import { divideArrayEnPartes, getIdCCAA, numberWithCommas, rootPath } from "../../../services/utils";
import FilaPictogramas from "../FichaMUH/InfoPrincipal/FilaPictogramas";

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import LinksFichaMUH from '../FichaMUH/InfoPrincipal/LinksFichaMUH';
import LinksFichaPPF from './LinksFichaPPF';

const MargenDerechoPPF = (props) => {
    const [sliderRef, setSliderRef] = useState(null)
    const ficha = props.ficha;
    const tienePFuturos = (ficha.PFuturos !== null) && (ficha.PFuturos.PVPIVA>0);
    const imgDefecto = rootPath + "img/jabonLiquido.svg" ;
    const srcImagen =   (props.ficha.ImagenEnvase != null ?
                            (props.ficha.ImagenEnvase.URLFull != null ?
                                props.ficha.ImagenEnvase.URLFull
                            : 
                                props.ficha.ImagenEnvase.URL
                            )
                        :
                            ''
                        );

    const srcImagenFF = (props.ficha.ImagenMatAcond != null ?
                            (props.ficha.ImagenMatAcond.URLFull != null ?
                                props.ficha.ImagenMatAcond.URLFull
                            : 
                                props.ficha.ImagenMatAcond.URL
                            )
                        :
                            ''
                        );
    const tieneImagenes = srcImagen !== '' || srcImagenFF !== '';    
    const arrayImgs = [];
    if (srcImagen != '')
        arrayImgs.push(srcImagen);
    if (srcImagenFF != '')
        arrayImgs.push(srcImagenFF);

    const maxHeight=180;
    const maxWidth=256;

    const estilhorizontal = {maxWidth: maxWidth.toString() + 'px', maxHeight: maxHeight.toString() + 'px', width: '100%', height: 'auto'}
    const estildefecto = {maxWidth: '180px', maxHeight: maxHeight.toString() + 'px', width: '100%', height: 'auto'}
    const estilvertical = {maxWidth: maxWidth.toString() + 'px', maxHeight: maxHeight.toString() + 'px', height: '100%', width: 'auto'}

    const loadImage = (e) => {
        var h = e.target.height;
        if (h===maxHeight){
            e.target.style.height = '100%';
            e.target.style.width = 'auto';
            e.target.style.maxWidth = maxWidth.toString() + 'px';
            e.target.style.maxHeight = maxHeight.toString() + 'px';
        }
    }


    const imagenesHTML = tieneImagenes ? arrayImgs.map(srcImg => <div><img class="imagen__medicamento" src={srcImg} alt={srcImg}  style={estilhorizontal} 
                                                                            onLoad={(e) => loadImage(e)}  onClick={(e) => props.clickImagen(e, arrayImgs.indexOf(srcImg))}/></div>)
                                        : <div><p>&nbsp;</p><img class="imagen__medicamento" src={imgDefecto} style={{height:'180px', cursor:'default'}} /></div>

    const sliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        speed: 300,
        arrows: true,
        touchMove: true,
        accessibility: true,
        adaptativeHeight: true,
        dots: false,
        focusOnSelect: true,
        className: 'single-item slide__1',
    }
                                                                                
    const estiloPreciosAltos = {fontSize:'14px'}
    const estiloVacio = {}
    const estiloPrecio = (ficha.PVPIVA>999999 ? estiloPreciosAltos : estiloVacio)

    const pictogramasEnFilas = divideArrayEnPartes(ficha.Pictogramas,5);    
    const pictogramas = pictogramasEnFilas.map( fila => <FilaPictogramas fila={fila}></FilaPictogramas>);

    var idCCAA = getIdCCAA();
    var literalPVPIVA = (ficha.LiteralPrecio) ? ficha.LiteralPrecio : (idCCAA === 5 || idCCAA === 18 || idCCAA === 19) ? 'PVP sin IVA facturación': 'PVP con IVA facturación';
    var precioAMostrar = ((idCCAA === 5 || idCCAA === 18 || idCCAA === 19) ? ficha.PVPsinIVA: ficha.PVPIVA);
    if ((idCCAA===18) && (ficha.CodTipoRegistro === 'PPF') && (ficha.IdTipoPF===6))
        literalPVPIVA = "Precio Ceuta";
    if ((idCCAA===19) && (ficha.CodTipoRegistro === 'PPF') && (ficha.IdTipoPF===6))
        literalPVPIVA = "Precio Melilla";

    return (
        <div class="medicamento">
            <div class="nombre f-f-Flama-Medium">{ficha.NombreCompleto}</div>
                <div class="contenido__imagen">
                    <Slider ref={sliderRef} {...sliderSettings}>
                        {imagenesHTML}
                    </Slider>
                    {pictogramas}
                </div>
            <div class="detalles">
                {(((ficha.IdTipoPF === 1) || (ficha.IdTipoPF === 13) || (ficha.IdTipoPF === 25) || (ficha.IdTipoPF === 6)) ?
                    <div class="precios__iva f-f-Flama-Basic">
                        <p class="nombre" style={{textAlign:'center'}}>{literalPVPIVA}</p>
                        {ficha.PVPIVA>0 ?
                        <p class="precio" style={estiloPrecio}>{numberWithCommas(precioAMostrar)}</p>
                        :
                        <p class="precio" >-</p>
                        }
                        {(
                            tienePFuturos ? 
                                <p class="sin_iva">{numberWithCommas(ficha.PFuturos.PVPIVA)}</p>
                            :
                                <span></span>
                        )}
                        <p class="moneda">EUR</p>
                    </div>
                :
                    <div></div>
                )}
                <div class="condiciones">
                    <div class="fila">
                        <div class="col-i">Receta</div>
                        <div class="col-d">{ficha.Receta}</div>
                    </div>
                    <div class="fila">
                        <div class="col-i">Dispensable</div>
                        <div class="col-d">{ficha.Dispensable}</div>
                    </div>
                    <div class="fila">
                        <div class="col-i">Financiado</div>
                        <div class="col-d" title={ficha.Financiado}>{ficha.ShortFinanciado}</div>
                    </div>
                </div>
            </div>
            <LinksFichaPPF ficha={ficha} cambiaPagina={props.cambiaPagina} ></LinksFichaPPF>
        </div>
    )
}
export default MargenDerechoPPF;