import { useEffect, useState } from "react";
import ResumenBusqueda from "../ResumenBusqueda";
import SelectorFechas from "../../Utilidades/Selector/SelectorFecha/SelectorFecha";
import { FormatoFechaDDMMYYYY, GetAntecesorWhithClassName } from "../../../services/utils";

const FBFechas= (props) => {
    const fecha = new Date();
    fecha.setMonth(fecha.getMonth() - 3);
    //const timespanDefault = Math.round(fecha.getTime()/1000);
    

    const codigoPagina = props.codigoPagina
    const [seleccionFormulario, setSeleccionFormulario] = useState({
        //inicio: (!props.inicio && !props.fin)? fecha : props.inicio,
        inicio: props.inicio,
        fin: props.fin,
    })

    useEffect(() => {
    },[]);

    const construyeResumenBusqueda = () => {
        var res = [];
        if (seleccionFormulario.inicio){
            if (seleccionFormulario.fin){
                res.push ({titulo: "Fecha ", valor: 'entre las fechas ' + FormatoFechaDDMMYYYY(seleccionFormulario.inicio) + ' y ' + FormatoFechaDDMMYYYY(seleccionFormulario.fin)});
            }else{
                res.push ({titulo: "Fecha ", valor: 'posterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.inicio)});
            }
        }else if (seleccionFormulario.fin){
            res.push ({titulo: "Fecha ", valor: 'anterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.fin)});
        }

        
        return res;
    }

    const resumen = construyeResumenBusqueda(seleccionFormulario);

    const clickBuscar = (e) =>{
        var newpagina = codigoPagina.substring(1);
        props.cambiaPagina({
            newpagina: newpagina,
            inicio: seleccionFormulario.inicio,
            fin: seleccionFormulario.fin,
        })
    }

    const BorraBusqueda = (e) =>{
        const formularioVacio={
            inicio: null,
            fin: null,
        }
        setSeleccionFormulario(formularioVacio);
        var inputs = document.getElementsByClassName('inputSelector');
        if (inputs){
            for (var i=0; i<inputs.length; i++){
                inputs[i].value = "";
                const itemContainer = GetAntecesorWhithClassName(inputs[i], 'custom-select-container');
                if (itemContainer){
                    itemContainer.classList.remove('active');
                }
            }
        }
    };

    const copiaFechas = (origen,destino) =>{
        destino.fin = origen.fin;
        destino.inicio = origen.inicio;
    }

    const CambioFecha= (inicio, fin) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.inicio = inicio;
        nuevaSeleccion.fin = fin;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const KeyDownForm = (e) =>{
        var keyCode = e.keyCode;
        if (keyCode === 13){
            clickBuscar(e);
        }
    }



    const clasedivmodulefilters = "cover cover__filters cover__filters__2 cover__filters__2--1 cover__filters__2--1__50 " ;

    return (
        <div class="content">

            <form class="content__results content__results__filters " id="main-filter"  onSubmit={(e) => e.preventDefault()}  onKeyDown={(e) => KeyDownForm(e)} tabIndex="0">
                <div class="module module__buttons">
                    <span class="button button__arrow button__green noUppercase reset-form" data-form="#main-filter" title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} >
                        Vaciar búsqueda
                    </span>
                </div>

                <div class="module module__buttons module__buttons__buscar">
                    <SelectorFechas 
                                titulo="Seleccione intervalo de fechas" 
                                alCambiar={CambioFecha} 
                                inicio={seleccionFormulario.inicio} 
                                fin={seleccionFormulario.fin}
                                idHTML = {"FBF_" + codigoPagina}
                                estiloTitulo = {{paddingBottom:'20px'}}
                    ></SelectorFechas>
                </div>

                <div class="module module__buttons module__buttons__buscar" >
                    <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                </div>
                <ResumenBusqueda condiciones={resumen}></ResumenBusqueda>
            </form>
        </div>
    );
};

export default FBFechas;
