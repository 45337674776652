import FilaTablaTipoDyF from "./FilaTablaTipoDyF";

const DispensacionFacturacion = (props) => {
    const ficha = props.ficha;
    const tabla = [
        {
            titulo:'Receta médica',
            valor: ficha.Receta,
            valorPositivo: 'Sí',
        },
        {
            titulo:'Dispensable en farmacia comunitaria',
            valor: ficha.Dispensable,
            valorPositivo: 'Sí',
        },
        {
            titulo:'Financiado en farmacia comunitaria',
            valor: ficha.Financiado,
            valorPositivo: 'Sí',
        },
        {
            titulo:'Tipo de aportación',
            valor: ficha.TipoAportacion,
            valorPositivo: 'Normal',
        },
        {
            titulo:'Visado de inspección',
            valor: ficha.Visado,
            valorPositivo: 'Sí',
        },
        {
            titulo:'Sustituible',
            valor: ficha.Sustituible,
            valorPositivo: 'Sí',
        },
    ]
    const tablaV = [
        {
            titulo:'Receta veterinaria',
            valor: ficha.Receta,
            valorPositivo: 'Sí',
        },
        {
            titulo:'Dispensable en farmacia comunitaria',
            valor: ficha.Dispensable,
            valorPositivo: 'Sí',
        },
        {
            titulo:'Financiado en farmacia comunitaria',
            valor: ficha.Financiado,
            valorPositivo: 'Sí',
        },
    ]

    const tablaFM = [
        {
            titulo:'Interoperable',
            valor: ficha.Interoperable,
            valorPositivo: 'Sí',
        },
        {
            titulo:'Receta médica',
            valor: ficha.Receta,
            valorPositivo: 'Sí',
        },
        {
            titulo:'Financiado en farmacia comunitaria',
            valor: ficha.Financiado,
            valorPositivo: 'Sí',
        },
        {
            titulo:'Tipo de aportación',
            valor: ficha.TipoAportacion,
            valorPositivo: 'Normal',
        },
        {
            titulo:'Visado de inspección',
            valor: ficha.Visado,
            valorPositivo: 'Sí',
        },
        {
            titulo:'Anotación en libro recetario',
            valor: ficha.LibroRecetario,
            valorPositivo: 'Sí',
        },
    ]

    if (!ficha.Interoperable) tablaFM.splice(0,1);

    const tablaPF = [
        {
            titulo:'Receta médica',
            valor: ficha.Receta,
            valorPositivo: 'Sí',
        },
        {
            titulo:'Dispensable en farmacia comunitaria',
            valor: ficha.Dispensable,
            valorPositivo: 'Sí',
        },
        {
            titulo:'Financiado en farmacia comunitaria',
            valor: ficha.Financiado,
            valorPositivo: 'Sí',
        },
        {
            titulo:'Tipo de aportación',
            valor: ficha.TipoAportacion,
            valorPositivo: 'Normal',
        },
        {
            titulo:'Visado de inspección',
            valor: ficha.Visado,
            valorPositivo: 'Sí',
        },
    ]

    if (ficha.CodTipoRegistro === "MHO"){
        tabla.splice(3,3);
    }

    const tablaHTML = ((ficha.CodTipoRegistro === "MUV") ? 
                        tablaV.map(fila => <FilaTablaTipoDyF fila = {fila} ></FilaTablaTipoDyF>)
                    : 
                        (((ficha.CodTipoRegistro === "FM") || (ficha.CodTipoRegistro === "PO") || (ficha.CodTipoRegistro === "VI")) ? 
                            tablaFM.map(fila => <FilaTablaTipoDyF fila = {fila} ></FilaTablaTipoDyF>)
                        :
                            ((ficha.CodTipoRegistro === "PPF") ? 
                                tablaPF.map(fila => <FilaTablaTipoDyF fila = {fila} ></FilaTablaTipoDyF>)
                            :
                                tabla.map(fila => <FilaTablaTipoDyF fila = {fila} ></FilaTablaTipoDyF>)
                            )
                        )
                    );
    return (
        <div class="dispensacion-facturacion">
            <h3 class="titulo">Condiciones de dispensación y facturación</h3>
            <div class="facturacion">
                {tablaHTML}
            </div>
        </div>
    )
}
export default DispensacionFacturacion;
