import { useEffect, useState } from "react";
import LoadingPopup from "../../Resultados/Loading.Popup";
import BP2022Service from "../../../services/user.service";
import ResumenBusqueda from "../ResumenBusqueda";
import SelectorFechas from "../../Utilidades/Selector/SelectorFecha/SelectorFecha";
import { FormatoFechaDDMMYYYY, GetAntecesorWhithClassName } from "../../../services/utils";
import SelectorUno2 from "../../Utilidades/Selector/SelectorUno2";

const FBDocumentos= (props) => {
    const [datosFormulario, setDatosFormulario] = useState({
        listaMed: null,
    });
    const [seleccionFormulario, setSeleccionFormulario] = useState({
        idMed: props.idMed,
        inicio: props.inicio,
        fin: props.fin,
    })

    useEffect(() => {
        if (datosFormulario.listaMed === null)
        {
            DescargaDatos();
        }        
    },[]);

    const DescargaDatos = () => {      
        BP2022Service.datosFormularioDocumentos()
        .then((response) => {
            var df = {
                listaMed : response.data.Medicamentos,
            };
            df.listaMed.splice(0,0,{Codigo:0, Valor:'Indique un medicamento o principio activo'});
            setDatosFormulario(df);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };


    const construyeResumenBusqueda = () => {
        var res = [];

        if (seleccionFormulario.idMed>0){
            if (datosFormulario.listaMed){
                var item = datosFormulario.listaMed.find(x => x.Codigo === seleccionFormulario.idMed);
                if (item)
                    res.push ({titulo: "Relacionado con ", valor: item.Valor});
            }
        }

        if (seleccionFormulario.inicio){
            if (seleccionFormulario.fin){
                res.push ({titulo: "Fecha del documento", valor: 'entre las fechas ' + FormatoFechaDDMMYYYY(seleccionFormulario.inicio) + ' y ' + FormatoFechaDDMMYYYY(seleccionFormulario.fin)});
            }else{
                res.push ({titulo: "Fecha del documento", valor: 'posterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.inicio)});
            }
        }else if (seleccionFormulario.fin){
            res.push ({titulo: "Fecha del documento", valor: 'anterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.fin)});
        }

        
        return res;
    }

    const resumen = construyeResumenBusqueda(seleccionFormulario);
    
    const hayBusqueda = true;

    const clickBuscar = (e) =>{
        props.cambiaPagina({
            newpagina: 'BDocumentos',
            idMed: seleccionFormulario.idMed,
            inicio: seleccionFormulario.inicio,
            fin: seleccionFormulario.fin,
        })
    }

    const BorraBusqueda = (e) =>{
        const formularioVacio={
            idMed: 0,
            inicio: null,
            fin: null,
        }
        setSeleccionFormulario(formularioVacio);
        var inputs = document.getElementsByClassName('inputSelector');
        if (inputs){
            for (var i=0; i<inputs.length; i++){
                inputs[i].value = "";
                const itemContainer = GetAntecesorWhithClassName(inputs[i], 'custom-select-container');
                if (itemContainer){
                    itemContainer.classList.remove('active');
                }
            }
        }
    };

    const copiaFechas = (origen,destino) =>{
        destino.fin = origen.fin;
        destino.inicio = origen.inicio;
    }

    const CambioFecha= (inicio, fin) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.inicio = inicio;
        nuevaSeleccion.fin = fin;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioMed = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.idMed = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const BorraMed = () =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.idMed = 0;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const KeyDownForm = (e) =>{
        var keyCode = e.keyCode;
        if (keyCode === 13){
            clickBuscar(e);
        }
    }

    const estiloBotonesSup = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'space-between'} : {height:'76px', width:'100%', justifyContent: 'space-between', visibility:'hidden'})
    const estiloBotonesInf = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'center'} : {height:'76px', width:'100%', justifyContent: 'center', display:'none'})


    const listaCodValorMed = (datosFormulario.listaMed) ? datosFormulario.listaMed.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    
    const clasedivmodulefilters = "cover cover__filters cover__filters__2 cover__filters__2--1 cover__filters__2--1__50 " ;

    return (
        <div class="content" >

            { (datosFormulario.listaMed === null) ?
                <LoadingPopup titulo="Un momento" texto="Preparando formulario" esPorcentaje={true}></LoadingPopup>
            :
                <form class="content__results content__results__filters " id="main-filter"  onSubmit={(e) => e.preventDefault()}  onKeyDown={(e) => KeyDownForm(e)} tabIndex="0">
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesSup}>
                        <span class="button button__arrow button__green noUppercase reset-form" data-form="#main-filter" title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} style={{visibility:'hidden'}} >
                            Vaciar búsqueda
                        </span>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                        <span class="button button__arrow button__green reset-form" data-form="#main-filter" style={{textTransform:'none', fontSize: '14px', width:'146px'}} title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} >
                            Vaciar búsqueda
                        </span>
                    </div>

                    <div class={clasedivmodulefilters} >
                        <div class="module module__filters" >
                            <h3 class="title fs fs16 greyColor2" >Medicamento/Principio activo</h3>
                            <SelectorUno2 lista = {listaCodValorMed} 
                                        idHTML="FBDocsMed" 
                                        valueSelected={seleccionFormulario.idMed} 
                                        alCambiar={CambioMed} 
                                        alBorrar={BorraMed} 
                                        ancho="100%"
                                        titulo="Medicamento/Principio activo"
                            ></SelectorUno2>
                        </div>
                    </div>
                    <div class="cover cover__filters cover__filters__2 cover__filters__2--1 cover__filters__2--1__50 calendars" >
                        <SelectorFechas 
                                    titulo="Fecha del documento" 
                                    alCambiar={CambioFecha} 
                                    inicio={seleccionFormulario.inicio} 
                                    fin={seleccionFormulario.fin}
                                    idHTML = "FBDoc_Inicio"
                        ></SelectorFechas>
                    </div>

                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesInf}>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                    </div>
                    <ResumenBusqueda condiciones={resumen}></ResumenBusqueda>
                </form>
            }
        </div>
    );
};

export default FBDocumentos;
