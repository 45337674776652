import { validaAccesoPorRol } from "../../../services/objsAplicacion";

const SubapartadoSeccionMenuBlanco = (props) => {
    const CambiaPaginaSimple = (codigoPagina) => {
        props.cambiaPagina({
            newpagina: codigoPagina
        });
    }

    //const estilo = (props.perfil === "COL") ? {cursor:'pointer', color:'#999999'} : {cursor:'pointer'}
    const estilo = (!validaAccesoPorRol(props.item.pagina,props.perfil)) ? {cursor:'pointer', color:'#999999'} : {cursor:'pointer'}


    return (
        <li>
            <span style={estilo} onClick={(e) => CambiaPaginaSimple(props.item.pagina)} title={props.item.titulo} >{props.item.titulo}</span></li>

    )
}
export default SubapartadoSeccionMenuBlanco;
