import {FormatoFechaBonito} from '../../../../services/utils.js'

const Nota = (props) => {
    const ano = ( props.item.Fecha ? props.item.Fecha.substring(0,4) : '');
    const fecha = ( ano>1900 ? FormatoFechaBonito (props.item.Fecha) : '');
    const tipoDoc = (props.item.IdTipo===15 ? "Nota seguridad AEMPS": 
                        (props.item.IdTipo===30 ? "Alerta de calidad" : 
                        (props.item.IdTipo===41 ? "Nota de seguridad veterinaria" : 
                        (props.item.IdTipo===44 ? "Nota informativa AEMPS" : 
                        (props.item.IdTipo===48 ? "Nota informativa AESAN" : 
                        (props.item.IdTipo===64 ? "Nota informativa AEMPS" : 
                        "Otro"
                        ))))))
    var titulo = (props.item.Titulo.toLowerCase().endsWith(".pdf") ? props.item.Titulo.substring(0,props.item.Titulo.length - 4) : props.item.Titulo)                            

    if (titulo.substring(0,17).toUpperCase()==="NOTA INFORMATIVA ")
        titulo = titulo.substring(17);
    else if (titulo.substring(0,15).toUpperCase()==="NOTA SEGURIDAD ")
        titulo = titulo.substring(15);
    else if (titulo.substring(0,14).toUpperCase()==="NOTA SEGURIDA ")
        titulo = titulo.substring(14);
    else if (titulo.substring(0,5).toUpperCase()==="NOTA ")
        titulo = titulo.substring(17);
    else if (titulo.substring(0,3).toUpperCase()==="NI ")
        titulo = titulo.substring(17);
    else if (titulo.substring(0,15).toUpperCase()==="ALERTA CALIDAD ")
        titulo = titulo.substring(15);
    else if (titulo.substring(0,23).toUpperCase()==="ALERTA DE MEDICAMENTOS ")
        titulo = titulo.substring(23);
    else if (titulo.substring(0,21).toUpperCase()==="ALERTA DE VIGILANCIA ")
        titulo = titulo.substring(21);
    else if (titulo.substring(0,7).toUpperCase()==="ALERTA ")
        titulo = titulo.substring(7);
    else if (titulo.substring(0,18).toUpperCase()==="AMPLIACION ALERTA ")
        titulo = "Ampliación alerta " + titulo.substring(18);
    else if (titulo.substring(0,11).toUpperCase()==="AMPLIACION ")
        titulo = "Ampliación " + titulo.substring(11);

    if (titulo !== "")
        titulo = titulo.substring(0,1) + titulo.substring(1);

    

    return (

        <li>
            <a href={props.item.URL} target="_blank" title={props.item.Titulo} >
                <span className="fs fs12 greenColor date" >
                    {fecha}
                </span>
                <p class="fs fs15 FlamaBook greyColor2" />
                <h3 class="fs fs24 blackColor title" >
                    {titulo}
                </h3>
                <p class="fs fs15 FlamaBook greyColor2" >
                    {tipoDoc}
                </p>
            </a>
        </li>
    );
};

export default Nota;
