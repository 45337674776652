import {AddClassNameToClassItems
    , GetAntecesorWhithClassName
    , GetIndexFromIndexedClass
    , RemoveClassNameToClassItems} from '../../../services/utils.js'

const ItemMenuPrincipalMorado = (props) => {
    const clasesItemA = "menu__1_item menu__1_item__" + props.idx.toString();
    
    const HandleHover_OpcionMenunMoradaNivel1 = (e, hayclick) =>{
        if ((document.documentElement.clientWidth > 959) || hayclick)
        {
            const numItems = 5;
            const itemMenu = GetAntecesorWhithClassName(e.target,'menu__1_item');
            const idx = GetIndexFromIndexedClass(itemMenu,'menu__1_item__',numItems);
            const namechild = "child__" + idx.toString();
            var i;
            for (i = 1; i<=numItems; i++)
            {
                var namechildtmp = "child__" + i.toString();
                if (namechild != namechildtmp){
                    RemoveClassNameToClassItems(namechildtmp,null,'active');
                    RemoveClassNameToClassItems(namechildtmp,null,'displayblock');
                    AddClassNameToClassItems(namechildtmp,null,'displaynone');
                }
                else{
                    AddClassNameToClassItems(namechildtmp,null, 'active');
                    RemoveClassNameToClassItems(namechildtmp,null,'displaynone');
                    AddClassNameToClassItems(namechildtmp,null,'displayblock');
                }
            }
            AddClassNameToClassItems('nenuChilds',null, 'active');
            AddClassNameToClassItems('coverToggleMenu','historial', 'active');
            RemoveClassNameToClassItems('child__menu2',null,'active');
        }
    }
 
    const HandleClick_OpcionMenunMoradaNivel1 = (e) =>{
        HandleHover_OpcionMenunMoradaNivel1(e,true);
        AddClassNameToClassItems('coverMenuHistorial',null, 'active');
        AddClassNameToClassItems('backItem__1',null, 'active');
        RemoveClassNameToClassItems('backItem__2',null, 'active');
        RemoveClassNameToClassItems('historial',null, 'active');
    }
 

    return (
        <li>
            <a className={clasesItemA} style={{cursor:'default'}} 
                        onClick={(e) => HandleClick_OpcionMenunMoradaNivel1(e)} 
                        onMouseOver={(e) => HandleHover_OpcionMenunMoradaNivel1(e,false)} 
                        title={props.item} >
                    {props.item}
                <svg width="8px" height="13px" viewBox="0 0 8 13" >
                    <defs>
                        <path d="M6.38999457,2.72756493 L6.38999457,4.31855519 L-0.307623382,4.31756493 L-0.307299791,10.6825162 L-1.98162338,10.6825162 L-1.98162338,2.72756493 L6.38999457,2.72756493 Z" id="path-1"></path>
                    </defs>
                    <g id="Home" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <mask id="mask-2" >
                            <use xlinkHref="#path-1"></use>
                        </mask>
                        <use id="Combined-Shape" fill-rule="nonzero" transform="translate(2.204186, 6.705041) rotate(135.000000) translate(-2.204186, -6.705041) " xlinkHref="#path-1"></use>
                    </g>
                </svg>
            </a>
        </li>
    )
}
export default ItemMenuPrincipalMorado;
