import Advertencias from "./Advertencias";

const AdvertenciasTipo = (props) => {
    const advertenciasTipo = props.advertenciasTipo;
    const advertencias = advertenciasTipo.Mensajes.map(mensa =>
        ((mensa.URLPictograma && mensa.URLPictograma != null) ?
            <p class="fs fs16 blackColor" style={{display: 'flex', alignItems: 'center'}} ><span style={{paddingRight:'5px'}}><img src={mensa.URLPictograma} /></span><span>{mensa.Mensaje}</span></p>
        :
            <p class="fs fs16 blackColor" >{mensa.Mensaje}</p>
        )
    )
    const tieneImagen = ((advertenciasTipo.URLPictograma) && (advertenciasTipo.URLPictograma != ''));
    const HTMLImagen = tieneImagen ? <img src={advertenciasTipo.URLPictograma} /> : <span></span>
    return (
        <div class="module__card FlamaBook" > 
            <div class="module__card__title" >
                {HTMLImagen}
                <h3 class="title fs fs13 greyColor" >{advertenciasTipo.Tipo}</h3>
            </div>
            <div class="card module__card__content" >
                {advertencias}
            </div>
        </div> 
        )
}
export default AdvertenciasTipo;
