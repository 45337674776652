import AnalisisPAAso from "./AnalisisPAAso";

const AnalisisAso = (props) => {
    const datosAnalisisAso = props.datosAnalisisAso;
    const HTMLAnalisisAso = datosAnalisisAso.map(datos => <AnalisisPAAso datosAnalisis={datos} cambiaPagina={props.cambiaPagina}></AnalisisPAAso>);
    return (
        <div class="infoContent" >
            {HTMLAnalisisAso}
        </div>
    )
}
export default AnalisisAso;