import {FormatoFechaBonito, rootPath} from '../../../../services/utils.js'

const NovedadCabecera = (props) => {
    const picto = ( props.item.CodTipoRegistro === "MUV" ? rootPath + "img/novedad2.svg" : (props.item.CodTipoRegistro === "PPF" ? rootPath + "img/novedad3.svg" : rootPath + "img/novedad1.svg"));
    const fecha = ( props.item.FechaAlta ? FormatoFechaBonito(props.item.FechaAlta) : '');

    const CambiaPaginaFicha = (it) =>{
        props.cambiaPagina({
            newpagina: "Ficha"+it.CodTipoRegistro,
            newid: it.Id,
        });
    }

    
    return (
        <div class="grid__item" title={props.item.NombreCompleto} onClick={() => CambiaPaginaFicha(props.item)} style={{cursor:'pointer'}}>
            <div class="mask" >
                <img src={picto} />
            </div>
            <div class="text" >
                <span class="fs fs12 greenColor" >{fecha}</span>
            <div class="separator separator__10" ></div>
            <h3 class="fs fs18 blackColor" >
                {props.item.NombreCompleto}
            </h3>
            <div class="separator separator__10" ></div>
                <p class="fs fs15 FlamaBook greyColor2" >
                    {props.item.TipoRegistro}
                </p>
            </div>
        </div>
    );
};

export default NovedadCabecera;

