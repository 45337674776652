import ItemMenuIconos from "./ItemMenuIconos";

const MenuIconos = (props) => {
    const itemsMenu =  props.elementosMenuIconos.map(item => <ItemMenuIconos  item={item}  cambiaPagina = {props.cambiaPagina}></ItemMenuIconos>)

    return (
        <div class="box box__right" >
            <ul class="list list__icons" >
                {itemsMenu}
            </ul>
        </div>
    )
}
export default MenuIconos;
