import BP2022Service from "../services/user.service";
import { rootPath } from "../services/utils";
import AuthService from "../services/auth.service";
import packageJson from '../../package.json';
import { useEffect, useState } from "react";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/botplustt.css';

const Pie = (props) => {

    const hacernada = () => {
    };

    const frontVersion = {Version : packageJson.version, Dateversion : packageJson.dateversion, Serial : packageJson.serial};
    const [backVersion, setBackVersion] = useState(null);
    const urlLicencia = (AuthService.getUserRol()==="COL")? rootPath + "Ayuda/Licencia3.pdf" : rootPath + "Ayuda/Licencia.pdf" 
    const estiloLinks = {cursor:'pointer', fontSize: '12px'}
    const estiloLiRRSSs = {marginRight: '24px'}
    const estiloLiMenus = {paddingLeft: '12px', paddingRight: '12px'}

    useEffect(() => {
        if (!backVersion)
        {
            //ConsultaBackVersion();
        }
    },[]);

    const ConsultaBackVersion = () => {
        BP2022Service.getBackVersion()
        .then((response) => {
            setBackVersion(response.data)
        })
        .catch(function (error) {
            console.log('Error', error.message);
        })
    };


    
    return (
        <footer class="footer" id="app__pie">
            <div class="cover cover__top" >
                <div class="container" >
                    <div class="module module__menu">
                        <img src={rootPath +"img/crossWhite.svg"} alt="" />
                        <ul class="list fs fs12 fw fw400 uppercase whiteColor" >
                            {(props.codigoPagina === "Inicio") ?
                            <li style={estiloLiMenus}><span title="BOT PLUS WEB" onClick={(e) =>window.scroll({ top: 0, behavior: 'smooth' })} style={estiloLinks}>BOT PLUS</span></li>
                            :
                            <li style={estiloLiMenus}><span title="BOT PLUS WEB" onClick={(e) =>props.cambiaPagina({newpagina: 'Inicio'})} style={estiloLinks}>BOT PLUS</span></li>
                            }
                            <li style={estiloLiMenus}><span title="Ayuda" onClick={(e) =>props.cambiaPagina({newpagina: 'Ayuda'})} style={{cursor:'pointer'}}>Ayuda</span></li>
                            <li style={estiloLiMenus}><a target="_blank" href="https://www.farmaceuticos.com/contacto/"><span title="Contacto" onClick={hacernada} style={estiloLinks}>Contacto</span></a></li>
                            <li style={estiloLiMenus}><a target="_blank" href="https://www.farmaceuticos.com/aviso-legal/"><span title="Aviso Legal" onClick={hacernada} style={estiloLinks}>Aviso Legal</span></a></li>
                            <li style={estiloLiMenus}><a target="_blank" href="https://www.farmaceuticos.com/politica-de-privacidad/"><span title="Política de privacidad" onClick={hacernada} style={estiloLinks}>Política de privacidad</span></a></li>
                            <li style={estiloLiMenus}><a target="_blank" href="https://www.farmaceuticos.com/politica-de-cookies/"><span title="Política de cookies" onClick={hacernada} style={estiloLinks}>Política de cookies</span></a></li>
                            <li style={estiloLiMenus}><a target="_blank" href={urlLicencia}><span title="Licencia de uso" onClick={hacernada} style={estiloLinks}>Licencia de uso</span></a></li>
                        </ul>
                    </div>
                    <div class="module module__rrss">
                        <ul class="list" >
                            <li style={estiloLiRRSSs}><a target="_blank" href="https://twitter.com/Farmaceuticos_" title="Twitter" ><img src={rootPath +"img/twitterWhite.svg"} alt="Twitter" /></a></li>
                            <li style={estiloLiRRSSs}><a target="_blank" href="https://www.instagram.com/farmaceuticos__/" title="Instragram" ><img src={rootPath + "img/instagramWhite.svg"} alt="Instagram" /></a></li>
                            <li style={estiloLiRRSSs}><a target="_blank" href="https://www.facebook.com/ConsejoGeneralColegiosFarmaceuticos/" title="Facebook" ><img src={rootPath + "img/facebookWhite.svg"} alt="facebook" /></a></li>
                            <li style={estiloLiRRSSs}><a target="_blank" href="https://www.youtube.com/c/farmaceuticosConsejoGeneral" title="YouTube" ><img src={rootPath + "img/youTubeWhite.svg"} alt="YouTube" /></a></li>
                            <li style={estiloLiRRSSs}><a target="_blank" href="https://www.linkedin.com/company/consejo-general-de-colegios-farmac%C3%A9uticos/mycompany/" title="LinkedIn" ><img src={rootPath + "img/linkedInWhite.svg"} alt="LinkedIn" /></a></li>
                            <li style={estiloLiRRSSs}><a target="_blank" href="https://www.tiktok.com/@farmaceuticos" title="TikTok" ><img src={rootPath + "img/tikTokWhite.svg"} alt="TikTok" /></a></li>
                            <li style={estiloLiRRSSs}><a target="_blank" href="https://t.me/Farmaceuticos_es" title="Telegram" ><img src={rootPath + "img/telegramWhite.svg"} alt="Telegram" /></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="cover cover__bottom" >
                <div class="container" >
                    <div class="module" >
                        <div class="mask mask__auto" >
                            <a target="_blank" href="https://farmaceuticos.com" title="Farmaceuticos" ><img src={rootPath + "img/logoFarmaceuticos.svg"} alt="logoFarmaceuticos" /></a>
                        </div>
                        <div class="text" >
                            <p class="fs fs12 FlamaBook greyColor" >© 2022 Consejo General de Colegios Oficiales de Farmacéuticos. Todos los derechos reservados</p>
                        </div>
                    </div>
                </div>
            </div>
	    </footer>
    )
};

export default Pie
