import { numberWithCommas2 } from "../../../../../services/utils";


const FilaDDD = (props) => {
    const dosificacion = props.dosificacion;
    const dosis = dosificacion.Dosis;
    const clasesDiv = 'column fs fs14 blackColor FlamaBook';

    return (
        <div class="table__row table__row__normal fw fw300 uppercase">
            <div class={clasesDiv} >
                <p>
                    {(dosis>0 ? numberWithCommas2(dosis) : '-')}
                </p>
            </div>
            <div class={clasesDiv} >
                <p>
                    {dosificacion.Unidad}
                </p>
            </div>
            <div class={clasesDiv} >
                <p>
                    {dosificacion.Via}
                </p>
            </div>
        </div>
    )
}
export default FilaDDD;
