import { rootPath } from "../../../services/utils";

const LinksFichaExt = (props) => {
    const ficha = props.ficha;

    const CambiaPaginaPorId = (pagina, id) => {
        props.cambiaPagina({
            newpagina: pagina,
            newid: id,
        });
    }

    var link = "BMedEspanolesMUH";

    return (
        <div class="dispensacion-facturacion">
            <div class="extras">
                <div class="elemento" onClick={() => CambiaPaginaPorId(link,ficha.Id)} style={{cursor:'pointer'}}>
                    <div class="mask" >
                        <img src={rootPath + "img/ficha/lupaVerde.svg"} />
                    </div>
                    <span>Medicamentos españoles</span>                    
                </div>
            </div>
        </div>
    )
}
export default LinksFichaExt;
