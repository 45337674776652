import { useEffect, useState } from "react";
import LoadingPopup from "../../Resultados/Loading.Popup";
import BP2022Service from "../../../services/user.service";
import SelectorUno from "../../Utilidades/Selector/SelectorUno";
import ResumenBusqueda from "../ResumenBusqueda";
import SelectorFechas from "../../Utilidades/Selector/SelectorFecha/SelectorFecha";
import { FormatoFechaDDMMYYYY, GetAntecesorWhithClassName } from "../../../services/utils";

const FBProblemasSum= (props) => {
    const [datosFormulario, setDatosFormulario] = useState({
        listaLab: null,
        listaPA: null,
    });
    const [seleccionFormulario, setSeleccionFormulario] = useState({
        txt: props.txt,
        idLab: props.idLab,
        idPA: props.idPA,
        medExt: props.medExt,
        noSust: props.noSust,
        iniciod: props.iniciod,
        inicioh: props.inicioh,
        find: props.find,
        finh: props.finh,
    })

    useEffect(() => {
        if (datosFormulario.listaPA === null)
        {
            DescargaDatos();
        }        
    },[]);

    const DescargaDatos = () => {      
        BP2022Service.datosFormularioProblemasSuministro()
        .then((response) => {
            var df = {
                listaPA : response.data.PrincipiosActivos,
                listaLab : response.data.Laboratorios,  
            };
            df.listaLab.splice(0,0,{Codigo:0, Valor:'Indique un laboratorio'});
            df.listaPA.splice(0,0,{Codigo:0, Valor:'Indique un principio activo'});
            setDatosFormulario(df);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };


    const construyeResumenBusqueda = () => {
        var res = [];
        if (seleccionFormulario.txt && seleccionFormulario.txt != ""){
            res.push ({titulo: 'Nombre contiene', valor: seleccionFormulario.txt});
        }

        if (seleccionFormulario.idLab>0){
            if (datosFormulario.listaLab){
                var item = datosFormulario.listaLab.find(x => x.Codigo === seleccionFormulario.idLab);
                if (item)
                    res.push ({titulo: "Laboratorio", valor: item.Valor});
            }
        }

        if (seleccionFormulario.idPA>0){
            if (datosFormulario.listaPA){
                var item = datosFormulario.listaPA.find(x => x.Codigo === seleccionFormulario.idPA);
                if (item)
                    res.push ({titulo: "Principio activo", valor: item.Valor});
            }
        }

        if (seleccionFormulario.iniciod){
            if (seleccionFormulario.inicioh){
                res.push ({titulo: "Fecha de inicio del problema", valor: 'entre las fechas ' + FormatoFechaDDMMYYYY(seleccionFormulario.iniciod) + ' y ' + FormatoFechaDDMMYYYY(seleccionFormulario.inicioh)});
            }else{
                res.push ({titulo: "Fecha de inicio del problema", valor: 'posterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.iniciod)});
            }
        }else if (seleccionFormulario.inicioh){
            res.push ({titulo: "Fecha de inicio del problema", valor: 'anterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.inicioh)});
        }

        if (seleccionFormulario.find){
            if (seleccionFormulario.finh){
                res.push ({titulo: "Fecha de finalización prevista del problema", valor: 'entre las fechas ' + FormatoFechaDDMMYYYY(seleccionFormulario.find) + ' y ' + FormatoFechaDDMMYYYY(seleccionFormulario.finh)});
            }else{
                res.push ({titulo: "Fecha de finalización prevista del problema", valor: 'posterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.find)});
            }
        }else if (seleccionFormulario.finoh){
            res.push ({titulo: "Fecha de finalización prevista del problema", valor: 'anterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.finh)});
        }

        if (seleccionFormulario.noSust){
            res.push({titulo: "Medicamento no sustituible"})
        }

        if (seleccionFormulario.medExt){
            res.push({titulo: "Medicamento puede obtenerse como medicación extranjera"})
        }
        
        return res;
    }

    const resumen = construyeResumenBusqueda(seleccionFormulario);

    const hayBusqueda = true;

    const clickBuscar = (e) =>{
        props.cambiaPagina({
            newpagina: 'BProblemasSumMUH',
            txt: seleccionFormulario.txt,
            idLab: seleccionFormulario.idLab,
            idPA: seleccionFormulario.idPA,
            iniciod: seleccionFormulario.iniciod,
            inicioh: seleccionFormulario.inicioh,
            find: seleccionFormulario.find,
            finh: seleccionFormulario.finh,
            noSust: seleccionFormulario.noSust,
            medExt: seleccionFormulario.medExt,
        })
    }

    const BorraBusqueda = (e) =>{
        const formularioVacio={
            txt: '',
            idLab: 0,
            idPA: 0,
            iniciod: null,
            inicioh: null,
            find: null,
            finh: null,
            noSust: false,
            medExt: false,
        }
        setSeleccionFormulario(formularioVacio);
        var inputs = document.getElementsByClassName('inputSelector');
        if (inputs){
            for (var i=0; i<inputs.length; i++){
                inputs[i].value = "";
                const itemContainer = GetAntecesorWhithClassName(inputs[i], 'custom-select-container');
                if (itemContainer){
                    itemContainer.classList.remove('active');
                }
            }
        }
    };

    const copiaFechas = (origen,destino) =>{
        destino.find = origen.find;
        destino.finh = origen.finh;
        destino.iniciod = origen.iniciod;
        destino.inicioh = origen.inicioh;
    }


    const CambioFechaInicio= (inicio, fin) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.iniciod = inicio;
        nuevaSeleccion.inicioh = fin;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioFechaFin= (inicio, fin) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.find = inicio;
        nuevaSeleccion.finh = fin;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioLaboratorio = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.idLab = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const BorraLaboratorio = () =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.idLab = 0;
        setSeleccionFormulario(nuevaSeleccion);
    }
    const CambioPA = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.idPA = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const BorraPA = () =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.idPA = 0;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambiaCheckMedExt = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.medExt = valor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambiaCheckNoSust = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.noSust = valor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambiaTxt = (e) =>{
        const item = e.target;
        if (item){
            const valor = item.value;
            const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
            copiaFechas(seleccionFormulario, nuevaSeleccion);
            nuevaSeleccion.txt = valor;
            setSeleccionFormulario(nuevaSeleccion);
        }
    }

    const KeyDownForm = (e) =>{
        var keyCode = e.keyCode;
        if (keyCode === 13){
            clickBuscar(e);
        }
    }

    const estiloBotonesSup = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'space-between'} : {height:'76px', width:'100%', justifyContent: 'space-between', visibility:'hidden'})
    const estiloBotonesInf = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'center'} : {height:'76px', width:'100%', justifyContent: 'center', display:'none'})

    const listaCodValorLab = (datosFormulario.listaLab) ? datosFormulario.listaLab.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorPA = (datosFormulario.listaPA) ? datosFormulario.listaPA.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    
    const clasedivmodulefilters = "cover cover__filters cover__filters__2 cover__filters__2--1 cover__filters__2--1__50 " ;

    return (
        <div class="content" >

            { (datosFormulario.arbol === null) ?
                <LoadingPopup titulo="Un momento" texto="Preparando formulario" esPorcentaje={true}></LoadingPopup>
            :
                <form class="content__results content__results__filters " id="main-filter"  onSubmit={(e) => e.preventDefault()}  onKeyDown={(e) => KeyDownForm(e)} tabIndex="0">
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesSup}>
                        <span class="button button__arrow button__green noUppercase reset-form" data-form="#main-filter" title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} style={{visibility:'hidden'}} >
                            Vaciar búsqueda
                        </span>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                        <span class="button button__arrow button__green reset-form" data-form="#main-filter" style={{textTransform:'none', fontSize: '14px', width:'146px'}} title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} >
                            Vaciar búsqueda
                        </span>
                    </div>

                    <div class="cover cover__filters cover__filters__2 cover__filters__2--1" >
                        <div class="cover cover__filters cover__filters__code" >
                            <div class="coverModule">
                                <h3 class="title fs fs16 greyColor2">Nombre</h3>
                                <div class="module module__filters">
                                    <div class="box box__inputs">
                                        <div class="cover cover__pad10">
                                            <input type="text" name="" value={seleccionFormulario.txt} placeholder="Escribe nombre medicamento"   onChange={(e) => CambiaTxt(e)}/ >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class={clasedivmodulefilters} >
                        <SelectorUno lista = {listaCodValorLab} 
                                    idHTML="FBPSumLab" 
                                    valueSelected={seleccionFormulario.idLab} 
                                    alCambiar={CambioLaboratorio} 
                                    alBorrar={BorraLaboratorio} 
                                    ancho="100%"
                                    titulo="Laboratorio"
                        ></SelectorUno>
                        <SelectorUno lista = {listaCodValorPA} 
                                    idHTML="FBPSumPA" 
                                    valueSelected={seleccionFormulario.idPA} 
                                    alCambiar={CambioPA} 
                                    alBorrar={BorraPA} 
                                    ancho="100%"
                                    titulo="Principio activo"
                        ></SelectorUno>
                    </div>
                    <div class="cover cover__filters cover__filters__2 cover__filters__2--1 cover__filters__2--1__50 calendars" >
                    <SelectorFechas 
                            titulo="Fechas de inicio" 
                            alCambiar={CambioFechaInicio} 
                            inicio={seleccionFormulario.iniciod} 
                            fin={seleccionFormulario.inicioh}
                            idHTML = "FBPS_Inicio"
                        ></SelectorFechas>
                    </div>
                    <div class="cover cover__filters cover__filters__2 cover__filters__2--1 cover__filters__2--1__50 calendars" >
                        <SelectorFechas 
                            titulo="Fechas de finalización prevista" 
                            alCambiar={CambioFechaFin} 
                            inicio={seleccionFormulario.find} 
                            fin={seleccionFormulario.finh}
                            idHTML = "FBPS_Fin"
                        ></SelectorFechas>
                    </div>

                    <div class="cover cover__filters cover__filters__full" >
                        <div class="module module__filters" >
                            <div class="checks" >
                                {(seleccionFormulario.medExt) ?
                                        <label><input type="checkbox" name="medExt" onClick={(e) => CambiaCheckMedExt(false)} checked />Puede obtenerse como medicación extranjera</label>
                                :
                                        <label><input type="checkbox" name="medExt" onClick={(e) => CambiaCheckMedExt(true)} />Puede obtenerse como medicación extranjera</label>
                                }
                                {(seleccionFormulario.noSust) ?
                                        <label><input type="checkbox" name="noSust" onClick={(e) => CambiaCheckNoSust(false)} checked />Medicación no sustituible</label>
                                :
                                        <label><input type="checkbox" name="noSust" onClick={(e) => CambiaCheckNoSust(true)} />Medicación no sustituible</label>
                                }
                            </div>
                        </div>
                    </div>


                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesInf}>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                    </div>
                    <ResumenBusqueda condiciones={resumen}></ResumenBusqueda>
                </form>
            }
        </div>
    );
};

export default FBProblemasSum;
