import FilaNombreMed from "./FilaNombreMed";


const ConfusionNombresMed = (props) => {
    const nombresMed = props.nombresMed;
    const HTMLNombresMed = nombresMed.map(nombre => <FilaNombreMed nombre={nombre} cambiaPagina={props.cambiaPagina}></FilaNombreMed>);
    return (
        <div class="infoContent" >
            <div class="table" style={{width:'100%'}}>
                <div class="table__row table__row__header table__row__header__noBg fs fs12 fw fw600 greenColor uppercase">
                    <div class="column" >
                        <p>Medicamentos</p>
                    </div>
                    <div class="column" >
                        <p>Composición</p>
                    </div>
                </div>
                {HTMLNombresMed}
            </div>
        </div>
    )
}
export default ConfusionNombresMed;
