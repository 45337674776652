import FarmacogeneticaPA from "./FarmacogeneticaPA";
const Farmacogenetica = (props) => {
    const farmacogeneticaComponentes = props.farmacogeneticaComponentes;
    const esAcordeon = (farmacogeneticaComponentes.length>1);
    const farmacogeneticas = farmacogeneticaComponentes.map(fgpa => <FarmacogeneticaPA farmacogenetica={fgpa} esAcordeon={esAcordeon}></FarmacogeneticaPA>);
    return (
        <div class="infoContent" style={{marginTop: '0px'}} >
            {farmacogeneticas}
        </div>
    )
}
export default Farmacogenetica;
