import React, { useEffect, useState } from "react";
import MisBuscadoresCabeceraResultados from "../Resultados/CabeceraResultados/MisBuscadoresCabeceraResultados";
import VolverCabeceraResultados from "../Resultados/CabeceraResultados/VolverCabeceraResultados";
import KeyEmpresa from "./KeyEmpresa";
import BP2022AuthService from "../../services/auth.service";


const CabeceraConfiguracion = (props) => {
    const titulo = (props.titulo ? props.titulo : "Configuración BOT PLUS");
    const [tieneLicenciaBot, setTieneLicenciaBot] = useState(null);
    const [mostrarEmpresa, setMostrarEmpresa] = useState(false);

    useEffect (() =>{
        if (tieneLicenciaBot===null){
           // getTieneLicenciaBot();
        }
    },[]);

    const getTieneLicenciaBot = () =>{
        BP2022AuthService.tieneLicenciaLocal()
        .then((response) => {
            setTieneLicenciaBot(response)
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    }

    const muestraEmpresa = (valor) =>{
        setMostrarEmpresa(valor);
    }


    return (
        <div class="contenedor__buscador f-f-Flama-Book">
            {mostrarEmpresa ?
                <KeyEmpresa alCerrar={muestraEmpresa}></KeyEmpresa>
            :
                <></>
            }
        <div class="container flex flex--justify-center">
            {
                (tieneLicenciaBot) ?
                <div class="contenedor__buscador--misBuscadores"  onClick={(e) => muestraEmpresa(true)}>
                    <span>Empresa informática</span>
                </div>
                :
                <VolverCabeceraResultados atras={props.atras} textoVolver=""></VolverCabeceraResultados>
            }    
            <div class="contenedor__buscador--buscador">
                <div class="box box__busqueda box__busqueda__center" >
                    <div class="text" >
                        <p class="fs fs20 fw fw400 blackColor" >{titulo}</p>
                    </div>
                </div>
            </div>
            <MisBuscadoresCabeceraResultados  infoPagina={props.infoPagina} cambiaPagina={props.cambiaPagina}></MisBuscadoresCabeceraResultados>
        </div>
        </div>
    );
};

export default CabeceraConfiguracion;
