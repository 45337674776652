import OptionSelect from "../../../Utilidades/Select/OptionSelect";

function FiltroLista(props){
    const filtro = props.filtro;
    const opcionesLista = filtro.posibles.map(opcion => <OptionSelect opt={opcion}></OptionSelect>);
    const idInput = "input__" + filtro.idGrupo;
    
    const avoidEnter = (e) =>{
        if (e.which == '13') {
            e.preventDefault();
            e.stopPropagation();
            e.target.blur();
        }
    }

    const handleCambioLista=(e) =>{
        props.cambiaLista(filtro.idGrupo, e.target.value)
    }

    return (
        <form class="search"  onSubmit={(e) => e.preventDefault()}>
            <input type="search" name="" placeholder="Escribe…" list="posiblesOpciones" id={idInput}
                    onChange={e => handleCambioLista(e)} 
                    onKeyDown={e => avoidEnter(e)}/>
            {   
                (filtro.posibles && filtro.posibles.length>0) ? (
                    <datalist id='posiblesOpciones'>
                        {opcionesLista}
                    </datalist>
                ) : (
                    <div><p></p></div>
                )
            }
        </form>
    );
}    
export default FiltroLista;