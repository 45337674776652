import axios from "axios";
import authHeader from "./auth-header";
import { getApiPath } from "./utils";

//const API_URL = "http://localhost:36823/botplusapi/";
//const API_URL = "http://nitramsoftware.linkpc.net:8899/botplusapi/";
//const API_URL = "/api/botplusapi/";
const API_URL = getApiPath();

const getPalabras = (txt,tipo) => {
  return axios.get(API_URL + "Sugerencias/Palabras?txt="+txt+"&tipo="+tipo, { headers: authHeader() });
};

const sugerenciasService = {
    getPalabras
};  

export default sugerenciasService;
