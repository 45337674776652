const FilaTablaTipoDyF = (props) => {
    const claseValor = (props.fila.valor === props.fila.valorPositivo ? 
                            "col-d positivo"
                        :
                            "col-d"
                        )

    const estiloValor = (props.estiloValor ? props.estiloValor : {});

    return (
        <div class="fila">
            <div class="col-i" style={{width: '40%'}} >{props.fila.titulo}</div>
            <div class= {claseValor} style={estiloValor}>{props.fila.valor}</div>
        </div>
    )
}
export default FilaTablaTipoDyF;
