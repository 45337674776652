import React, { useEffect, useState } from "react";
import BP2022Service, {GetBusquedasPreferidas} from "../../../../services/user.service";
import { PerfilUsuario, rootPath } from "../../../../services/utils";
import BusquedaPreferida from "./BusquedaPreferida";
import { validaAccesoPorRol } from "../../../../services/objsAplicacion";

const BusquedasPreferidasInicio = (props) => {

  const [listaBuscadores, setListaBuscadores] = useState(null);

  useEffect(() => {
    descargaBuscadores();
  }, [])

  const descargaBuscadores = () => {
    BP2022Service.getBuscadoresPreferidos()
    .then((response) => {
        var listaTemp = response.data;
        var lista = [];
        for (var i=0; i<listaTemp.length; i++){
            if (validaAccesoPorRol(listaTemp[i].CodigoPagina,PerfilUsuario())){
                lista.push(listaTemp[i]);
            } 
        }
        setListaBuscadores(lista);
    })
    .catch(function (error) {
        console.log('Error', error.message);
    });
  };

  const busquedas = listaBuscadores ? (
    listaBuscadores.map(fila => <BusquedaPreferida item={fila} colorLupa="White" cambiaPagina={props.cambiaPagina}></BusquedaPreferida>)
  ):(
    <div></div>
  );

  const CambiaPaginaSimple = (codigoPagina) => {
    props.cambiaPagina({
        newpagina: codigoPagina
    });
}

const ancho = window.innerWidth;
const isMobile = (ancho<768);   
const estiloDiv = (isMobile) ? {backgroundColor : 'rgba(0,0,0,0.5)', display: 'block', marginLeft: '0px'} 
    : {backgroundColor : 'rgba(0,0,0,0.5)'};


  return (
    <div class="box box__favs" style={estiloDiv} >
      <h2 class="fs fs13 fw fw400 purpleColor2" >MIS BUSCADORES FAVORITOS</h2>
      <div class="separator separator__20" ></div>
      <ul class="fs fs16 whiteColor FlamaBasic list" >
        {busquedas}
      </ul>
      <span class="fs fs16 whiteColor FlamaBasic pinkColor edit" onClick={(e) => CambiaPaginaSimple('EditaBuscadores')}>
          <img src={rootPath + "img/pencilPink.svg"} alt="pencilPink" /> 
          Editar mis buscadores
       </span>
    </div>
  );
};

export default BusquedasPreferidasInicio;
