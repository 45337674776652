

function SelectorCHK(props){
    const valoresCHK  = props.valoresCHK;
    const opcionesCHK = props.opcionesCHK;
    const titulo = props.titulo;
    const idOrigen = props.idOrigen;

    const cambiaCheck= (e, valor) =>{
        var check  = e.target;
        var idx = valoresCHK.indexOf(valor);
        if (check.checked){
            if (idx<0) valoresCHK.push(valor);
        }else{
            if(idx>=0) valoresCHK.splice(idx,1);
        }
        
        props.alCambiar(valoresCHK,idOrigen);
    }

    const HTMLChecks = opcionesCHK.map(opt => 
            <label>
                <input type="checkbox" value={opt.val} checked={valoresCHK && valoresCHK.indexOf(opt.val)>=0} onChange={e=> cambiaCheck(e, opt.val)}/>
                {opt.tit}
            </label>
        );

    return (
        <div class="module module__filters" >
            <h3 class="title fs fs16 greyColor2" style={{lineHeight:'20px'}}>{titulo}</h3>
            <div class="checks" >
                {HTMLChecks}
            </div>
            {(props.pie) ?
                props.pie
            :
                <div></div>
            }
        </div>
    )
}
export default SelectorCHK;
