import { useState } from "react";
import { containsNumber, rootPath } from "../../../../../services/utils";
import Emergente from "../Cismed/Emergente";

const FilaNombreValor = (props) => {
    const [verEmergenteTooltip,setVerEmergenteTooltip] = useState(false);
    const fila = props.fila;
    const claseFila = (props.claseFila ? props.claseFila : "fila");
    const claseColI = (props.claseCol ? props.claseCol : "col-i");
    var claseColD = (props.claseCol ? props.claseCol : "col-d");
    var esCodigoONumero = false;
    if (props.esNumero){
        claseColD = claseColD + " num ";
        esCodigoONumero = true;
    }
    else{
        if (props.conValoresNumericos && fila.valor && containsNumber(fila.valor.toString())){
            claseColD = claseColD + " num ";
            esCodigoONumero = true;
        }
    }

    const abreEmergenteTooltip = (e) => {
        setVerEmergenteTooltip(true);        
    }

    const cierraEmergenteTooltip = (e) => {
        setVerEmergenteTooltip(false);
    }

    const CambiaPaginaPorId = (pagina, id) => {
        props.cambiaPagina({
            newpagina: pagina,
            newid: id,
        });
    }
    const MostrarAvisoOCambiaPagina = (aviso,pagina, id) => {
        if (!aviso || aviso === ""){
            CambiaPaginaPorId(pagina,id)
        }else{
            props.muestraAviso(aviso,pagina,id);
        }
    }
    var estiloValor = (esCodigoONumero) ? {letterSpacing : '2px'} : {overflowWrap: 'break-word'};

    if (fila.tooltip){
        estiloValor.marginBottom = '4px';
        estiloValor.marginRight = '4px';        
    }

    
        
    const contenidoValor = (
        fila.link ? 
            <span style={{cursor:'pointer', color:'#5C9029'}} 
                onClick={(e) => MostrarAvisoOCambiaPagina(fila.aviso,fila.link,fila.id)}
                onMouseOver={(e) => e.target.style.fontWeight='bold'}
                onMouseOut={(e) => e.target.style.fontWeight='normal'}
        >{fila.valor}</span>
        :
            <span style={estiloValor}>{fila.valor}</span>
    )

    if (fila.tooltip){
    }
    return (
        <div class={claseFila}>
            {(verEmergenteTooltip) && (fila.tooltip) ?
                <Emergente contenido={fila.tooltip} keyName={"filaTooltip"} titulo={""} cerrar = {cierraEmergenteTooltip} activo = {true} alto = {fila.altotooltip}></Emergente>
            :
                <></>
            }
            <div class={claseColI}>{fila.nombre}</div>
            <div class={claseColD}>
                {(fila.tooltip) ?
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {contenidoValor}
                        <span style={{cursor:'pointer'}}  float="right" onClick={(e) => abreEmergenteTooltip(e)}>
                            <img src={rootPath + "img/infoGreen.svg"} alt=""/>
                        </span>
                    </div>
                :
                    contenidoValor
                }
            </div>
        </div>
    )
}
export default FilaNombreValor;
