import Analisis from "../../../FichaMUH/Apartados/Analisis/Analisis";
import Acordeon from "../../../FichaMUH/Apartados/Composicion/Acordeon";

const AnalisisPAAso = (props) => {
    const datosAnalisis = props.datosAnalisis;
    const ContAcordeon = <Analisis datosAnalisis = {datosAnalisis} cambiaPagina={props.cambiaPagina}></Analisis>
    const titulo = datosAnalisis.Causa;
    return (
       <Acordeon titulo = {props.datosAnalisis[0].Causa} contenido = {ContAcordeon} activo={true}></Acordeon>
    )
}
export default AnalisisPAAso;