import React, { useState, useEffect } from "react";
import { numberWithCommas, ToggleClassNameToClassItems } from "../../../../services/utils";
import FiltroBusqueda from "./FiltroBusqueda";

function FiltrosBusqueda(props){
    const filtros = props.filtros;
    const htmlFiltros = (filtros ?
            <div>
                {filtros.filter(f=>!f.oculto).map(filtro =>  <FiltroBusqueda filtro={filtro} 
                                                cambiaCheck= {props.cambiaCheck} 
                                                cambiaLista={props.cambiaLista}>
                                        </FiltroBusqueda>)}
            </div>
        :
            <div></div>
    );

    const handleClickFiltrosHeader = (e) =>{
        ToggleClassNameToClassItems('cover__aside', null, 'active');
    }

    const estiloAsideConPMenores = {borderTopStyle:'none'}
    const estiloAsideNormal = {};
    const estiloAside = (props.conPreciosMenores) ? estiloAsideConPMenores : estiloAsideNormal
    const estiloSegundoAsideNormal = {marginTop:'0px', borderTopStyle: 'none'}
    const estiloSegundoAside = (props.conPreciosMenores) ? estiloAsideConPMenores : estiloSegundoAsideNormal


    return (        
        <aside class="aside noBottom " style={estiloAside} id="divContainerFiltros" >
        {(props.conPreciosMenores) ?
            <div class = "medicamento-info"  style={{borderBottomStyle:'solid', borderBottomColor: '#93c462', borderBottomWidth: '4px'}}>
            <div class = "contenido__desc" style={{width:'100%', border: 'none'}}>
            <div class = "detalles" style = {{borderBottom : 'none', margin: '8px', paddingBottom: '0px'}}>
            <div class = "precios">
            <div class="precios__comunidad">
                <div class="precios__comunidad--precios">
                    <div style={{maxWidth: '100%', backgroundColor:'#f8f0ff'}}>
                        <p class="nombre literalprecio" style={{textAlign:'center'}}>{props.literalPMenor}</p>                                        
                        {(props.pvpMenor && props.pvpMenor>0) ?
                                <p class="precio" style={{color:'#81509a'}}>{numberWithCommas(props.pvpMenor)}</p>
                            : 
                                <p class="precio" style={{color:'#81509a'}}>-</p>
                        }
                        {(props.pvpMenor && props.pvpMenor>0) ?
                                <p class="moneda">EUR</p>
                            : 
                                <p class="precio">&nbsp;</p>
                        }
                    </div>
                    <div style={{maxWidth: '100%', backgroundColor:'#f8f0ff'}}>
                        <p class="nombre literalprecio" style={{textAlign:'center'}}>{props.literalPMasBajo}</p>
                        {(props.pvpMasBajo && props.pvpMasBajo>0) ?
                                <p class="precio" style={{color:'#81509a'}}>{numberWithCommas(props.pvpMasBajo)}</p>
                            : 
                                <p class="precio" style={{color:'#81509a'}}>-</p>
                        }
                        {(props.pvpMenor && props.pvpMasBajo>0) ?
                                <p class="moneda">EUR</p>
                            : 
                                <p class="precio">&nbsp;</p>
                        }
                    </div>
                </div>
            </div>
            </div>
            </div>            
            </div>            
            </div>            
        :
            <span></span>
        }
        <div class="cover__aside"  >
            <span class="show" onClick={(e) =>  handleClickFiltrosHeader(e)}>
                <p>Filtros</p>
            </span>
            <aside class="aside noBottom " style={estiloSegundoAside} >
                <div class="title" >
                    <h2 class="fs fs15 FlamaBook" >Filtrar</h2>
                </div>
                {htmlFiltros}
            </aside>
        </div>
        </aside>
    )
}    
export default FiltrosBusqueda;

