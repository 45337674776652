import { useEffect, useState } from "react";
import BP2022AuthService from "../services/auth.service";
import { getCurrentURL, getParam, getParamsURL, rootPath } from "../services/utils";
import Emergente from "./Fichas/FichaMUH/Apartados/Cismed/Emergente";

const Credenciales = (props) => {

    const [mensaje, setMensaje] = useState('');
    const [estado, setEstado] = useState(0);
    const [datosAcceso, setDatosAcceso] = useState(null);

    const getInfoCredenciales = (str) =>{
        setEstado(1);
        BP2022AuthService.infoCredenciales(str)
        .then((response) => {
            if ((response.ok) && (response.info)){
                setDatosAcceso({
                    usuarioActual: response.info.UsuarioActual,
                    passActual: response.info.PassActual,
                });
                setEstado(2);
            }
            else{
                setMensaje(response.msg);
                setEstado(3);
            } 
        })
        .catch(function (error) {
            console.log('Error', error.message);
            setMensaje(error.message);
            setEstado(3);
        });
    }

    useEffect (() =>{
        if (estado===0){
            var ruta = getCurrentURL();
            if (ruta.length>=rootPath.length)
                ruta = ruta.substring(rootPath.length);
            if (ruta.length>0){
                var params = getParamsURL(ruta);
                var str = getParam(params,'a');
                getInfoCredenciales(str);
            }
        }
    },[]);


    const cancelaProceso = () =>{
        window.location.href = "https://botplusweb.farmaceuticos.com";
    }


    const contenido = (
        (estado === 0) ?
            <div style={{height: '100%', width: '100%', cursor:'wait', overflowY: 'hidden',}}>
                <p className="fs fs15 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '30%'}}>Iniciando...</p>
            </div>
        :(estado === 1) ?
            <div style={{height: '100%', width: '100%', cursor:'wait', overflowY: 'hidden',}}>
                <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '35px'}}>Buscando información...</p>
            </div>
        :(estado === 2) ?
            <div>
                <p className="fs fs20 fw fw100 FlamaBook greyColor"
                    style={{marginBottom:'20px', marginTop:'20px', textAlign:'center'}}>
                        Usuario:
                        <span style={{fontWeight: 600, marginLeft:'20px'}}>{datosAcceso.usuarioActual}</span>
                </p>
                <p className="fs fs20 fw fw100 FlamaBook greyColor"
                    style={{marginBottom:'30px', textAlign:'center'}}>
                        Contraseña:
                        <span style={{fontWeight: 600, marginLeft:'20px'}}>{datosAcceso.passActual}</span>
                </p>
            </div>
        :(estado === 3) ?
            <div style={{height: '100%', width: '100%', cursor:'wait', overflowY: 'hidden',}}>
                <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '35px'}}>{mensaje}</p>
            </div>
        :
            <div style={{height: '100%', width: '100%', overflowY: 'hidden',}}>
                <p className="fs fs18 fw fw100 FlamaBook greyColor" style={{textAlign:'center', marginTop: '30%'}}>Este paso no está implementado</p>
            </div>
    );

    return(
        <Emergente 
            contenido={contenido} 
            keyName={"credenciales"} 
            titulo={"Acceso BOT PLUS"} 
            activo={true} 
            ocultaAspa = {false}
            alto = {'240px'}
            cerrar={cancelaProceso}
        ></Emergente>
    );
}

export default Credenciales;
