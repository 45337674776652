import { RemoveClassNameToClassItems,elementIsOfClass } from "../../../../services/utils";

const FilaMenuIzquierda = (props) => {
    const claseDiv = (props.apartado.Ancla === props.activo) ? "elemento active elemento__menu__ficha" : "elemento elemento__menu__ficha";
    const iddiv = "RefTo" + props.apartado.Ancla;

    const handleScrollToElement = (idAncla,e) => {
        const linkRef = props.anclas.find(ancla => ancla.idAncla === idAncla).refAncla;
        linkRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        if (props.cambiaImgIndex){
            if (idAncla === "IDEN")
                props.cambiaImgIndex(1);
            else if (idAncla === "GEN")
                props.cambiaImgIndex(0);
        }
    }

    return (
        <div class={claseDiv}>
        <span key={props.apartado.Ancla} id={iddiv} onClick={(e) => handleScrollToElement(props.apartado.Ancla,e)} style={{cursor:'pointer', textDecoration:'none', paddingTop: '10px' }} title={props.apartado.Titulo} >{props.apartado.Titulo}</span>
        <svg id="icon_more_copy" data-name="icon/more copy" xmlns="http://www.w3.org/2000/svg"
            xmlns_xlink="http://www.w3.org/1999/xlink" width="21" height="21" viewBox="0 0 21 21">
            <defs>
                <clipPath id="clip-path">
                    <path class="fill" id="Combined_Shape" data-name="Combined Shape"
                        d="M2.89,4.092,4.127,2.855,9.572,8.3,8.335,9.537ZM0,8.75V0H8.75V1.75h-7v7Z"
                        transform="translate(13.512 6.744) rotate(135)" fill="#93c462" />
                </clipPath>
            </defs>
            <g id="Group" transform="translate(3 3.931)">
                <path id="Combined_Shape-2" data-name="Combined Shape"
                    d="M2.89,4.092,4.127,2.855,9.572,8.3,8.335,9.537ZM0,8.75V0H8.75V1.75h-7v7Z"
                    transform="translate(13.512 6.744) rotate(135)" fill="#93c462" />
                <g id="Group-2" data-name="Group" transform="translate(0 0)"
                    clip-path="url(#clip-path)">
                    <g id="color_black-green" data-name="color/black-green"
                        transform="translate(-3 -3.931)">
                        <rect id="color_botplus_background" data-name="color/botplus background"
                            width="21" height="21" fill="none" />
                        <rect class="fill" id="Rectangle" width="21" height="21" fill="#81509a" />
                    </g>
                </g>
            </g>
        </svg>
    </div>
)
}
export default FilaMenuIzquierda;
