import { PerfilUsuario, rootPath } from "../../../../services/utils";

const LinksFichaMUH = (props) => {
    const ficha = props.ficha;

    const CambiaPaginaPorId = (pagina, id) => {
        props.cambiaPagina({
            newpagina: pagina,
            newid: id,
        });
    }

    const CambiaPaginaInteracciones = (pagina, id) => {
        props.cambiaPagina({
            newpagina: pagina,
            idsSel: [id,],
        });
    }

    const perfil = PerfilUsuario();

    const colorlink = (perfil === "COLx" ? {color: '#999999', textDecoration:'none'} : {textDecoration:'none'})

    const mouseOverLink = (e) => {
        e.target.style.fontWeight='bold';
        e.target.style.textDecoration='none';
    }
    const mouseOutLink = (e) => {
        e.target.style.fontWeight='normal';
    }


    return (
        <div class="dispensacion-facturacion">
            <div class="extras">
                {(
                    ficha.CodTipoRegistro==="MUH" ?
                        <div class="elemento" 
                            onClick={() => CambiaPaginaInteracciones('BInteraccionesMUH',ficha.Id)} style={{cursor:'pointer'}}
                        >
                            <div class="mask" >
                                <img src={rootPath + "img/ficha/arrowsGreen.svg"} />
                            </div>
                            <span style={colorlink}
                                onMouseOver={(e) => mouseOverLink(e)}
                                onMouseOut={(e) => mouseOutLink(e)}
                            >Interacciones</span>
                        </div>
                    : <></>
                )}
                {(
                    ficha.CodTipoRegistro==="MUH" ?
                        <div class="elemento" onClick={() => CambiaPaginaPorId('BExtranjerosEXT',ficha.Id)} style={{cursor:'pointer'}}
                        >
                            <div class="mask" >
                                <img src={rootPath + "img/ficha/worldGreen.svg"} />
                            </div>
                            <span style={colorlink}
                                onMouseOver={(e) => mouseOverLink(e)}
                                onMouseOut={(e) => mouseOutLink(e)}
                            >Medicamentos extranjeros</span>
                        </div>
                    : <></>
                )}
                {(
                    ficha.Prospecto ?
                        <div class="elemento" style={{cursor:'pointer', marginTop: '0.5em'}}
                        >
                            <div class="mask" >
                                <a href={ficha.Prospecto.URL} target="_blank" title={ficha.Prospecto.Titulo} >
                                    <img src={ rootPath + "img/ficha/fileGreen.svg"} />
                                </a>
                            </div>
                            <a href={ficha.Prospecto.URL} target="_blank" title={ficha.Prospecto.Titulo} >
                                <span style={{textDecoration:'none'}}
                                    onMouseOver={(e) => mouseOverLink(e)}
                                    onMouseOut={(e) => mouseOutLink(e)}
                                >Prospecto</span>
                            </a>
                        </div>
                    :
                        <span></span>
                )}
                {(
                    ficha.FichaTecnica ?
                        <div class="elemento" style={{cursor:'pointer', marginTop: '0.5em'}}>
                            <div class="mask" >
                                <a href={ficha.FichaTecnica.URL} target="_blank" title={ficha.FichaTecnica.Titulo} >
                                    <img src={rootPath + "img/ficha/fileGreen2.svg"} />
                                </a>
                            </div>
                            <a href={ficha.FichaTecnica.URL} target="_blank" title={ficha.FichaTecnica.Titulo} >
                                <span style={{textDecoration:'none'}}
                                onMouseOver={(e) => mouseOverLink(e)}
                                onMouseOut={(e) => mouseOutLink(e)}
                                >Ficha técnica</span>
                            </a>
                        </div>
                    :
                        <span></span>
                )}
            </div>
        </div>
    )
}
export default LinksFichaMUH;
