import React, { useEffect, useState } from "react";
import AuthService from "../services/auth.service";
import { rootPath } from "../services/utils";
import Emergente from "./Fichas/FichaMUH/Apartados/Cismed/Emergente";
import Recupera from "./Recupera/Recupera";
import RecuperaPassword0 from "./Configuarcion/RecuperaPassword0";
import RecuperaPassword2 from "./Configuarcion/RecuperaPassword2";
import CambioCorreo2 from "./Configuarcion/CambiaCorreo2";
import ConfirmaCorreo2 from "./Configuarcion/ConfirmaCorreo2";

const required = (value) => {   
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };
  
  const Login = (props) => {
  
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [mantActiva, setMantActiva] = useState(false);
    const [cerrarAnt, setCerrarAnte] = useState(false);
    const [status, setStatus] = useState(props.status);
    //const [loading, setLoading] = useState(false);
    const [mensaje, setMensaje] = useState(props.mensaje);
    const [isHelpActive, setHelpActive] = useState("false");
    const [verRecupera, setVerRecupera] = useState(false);
    const [verLinkRecupera, setVerLinkRecupera] = useState(false);
    const [verRecupera2, setVerRecupera2] = useState(props.recupera);
    const [verCambioPass2, setVerCambioPass2] = useState(props.cambiopass);
    const [verCambioCorreo2, setVerCambioCorreo2] = useState(props.cambiomail);
    const [verConfirmaCorreo2, setVerConfirmaCorreo2] = useState(props.confirmamail);
    const [link, setLink] = useState(props.link);

    useEffect (() =>{
        AuthService.moduloCambioPassAnonimo()
            .then((response) => {
                setVerLinkRecupera(response.data);
            });
    },[]);

  
    const onChangeUsername = (e) => {
      const username = e.target.value;
      setUsername(username);
    };
  
    const onChangePassword = (e) => {
      const password = e.target.value;
      setPassword(password);
    };

    const onChangeMantActiva = (e) => {
        const chk = e.target.checked;
        setMantActiva(chk);
    };
      
    const onChangeCerrarAnt = (e) => {
        const chk = e.target.checked;
        setCerrarAnte(chk);
    };
      
    
    const handleHelpToggle = () => {
      setHelpActive(!isHelpActive);
    };

    const muestraRecupera = () =>{
        setVerRecupera(true);
    }
  
    const ocultaRecupera = () =>{
        setVerRecupera(false);
    }
  
    const muestraRecupera2 = () =>{
        setVerRecupera2(true);
    }
  
    const ocultaRecupera2 = () =>{
        setVerRecupera2(false);
        setVerCambioPass2(false);
    }
  
    const muestraCambioCorreo2 = () =>{
        setVerCambioCorreo2(true);
    }
  
    const ocultaCambioCorreo2 = () =>{
        setVerCambioCorreo2(false);
    }
  
    const muestraConfirmaCorreo2 = () =>{
        setVerConfirmaCorreo2(true);
    }
  
    const ocultaConfirmaCorreo2 = () =>{
        setVerConfirmaCorreo2(false);
    }
  
    const handleLogin = (e) => {
        AuthService.login(username, password, cerrarAnt, mantActiva).then(
            (response) => {
                //props.history.push("/Inicio");
                if (response.Status === 0){
                    window.location.reload();
                }
                else{
                    setMensaje (response.Mensaje);
                    if (response.Info)
                        console.log(response.Info);
                }
                setStatus(response.Status);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();
                setMensaje("Error de red");
                setStatus(-1);
                //setLoading(false);
                //setMessage(resMessage);
            }
        );
    };

    const estiloCheckBox = {
        height: '16px',
    }
  
    return (
      <section id="login" className="section section__login" > 
        <div className="container" >
          <div className="box box__img" >
            <div className="mask" >
              <img src={rootPath +"img/logoHeader.svg"} alt="" />
            </div>
          </div>
          {verRecupera ?
                <RecuperaPassword0 alCerrar={ocultaRecupera}></RecuperaPassword0>
            :(verRecupera2 || verCambioPass2) ?
                <RecuperaPassword2 alCerrar={ocultaRecupera2} link={link} esRecupera={verRecupera2} esCambioPass = {verCambioPass2}></RecuperaPassword2>
            :(verCambioCorreo2) ?
                <CambioCorreo2 alCerrar={ocultaCambioCorreo2} link={link} esCambioMail = {verCambioCorreo2}></CambioCorreo2>
            :(verConfirmaCorreo2) ?
                <ConfirmaCorreo2 alCerrar={ocultaConfirmaCorreo2} link={link} esConfirmaMail = {verConfirmaCorreo2}></ConfirmaCorreo2>
            :
                <></>
          }
          <div className="box box__text" >
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="text" >
                <h3 className="fs fs40 fw fw300 blackColor FlamaBook" >Acceso usuarios</h3>
                <div className="separator separator__20" ></div>
                <p className="fs fs18 fw fw100 FlamaBook greyColor">Bienvenido/a a BOT PLUS. Por favor, introduce tus datos para acceder.</p>
              </div>
              <div className="separator separator__10" ></div>
              <div className="cover cover__input" >
                <label className="fs fs15 fw fw400 greyColor2" >Nombre de usuario</label>
                <input type="text" name="username" value={username} onChange={onChangeUsername} placeholder="Escribe nombre de usuario" />
              </div>
              <div className="cover cover__input" >
                <label className="fs fs15 fw fw400 greyColor2" >Contraseña</label>
                <input type="password" name="password" value={password} onChange={onChangePassword} placeholder="Escribe tu contraseña" />
              </div>
                {(status!==0) ?
                    <div className="text" style={{width:'100%', paddingBottom:'6px', fontSize:'16px', lineHeight:'20px', verticalAlign:'baseline'}}  >
                        <p className="fs fs15 fw fw100 FlamaBook redColor" 
                                style={{width:'100%', textAlign:'left', paddingRight: '10px', fontWeight:'normal'}}>
                            <img src={rootPath + 'img/warningRed.svg'} style={{height: '16px', paddingRight: '21px'}}/>
                            {mensaje}
                        </p>
                    </div>
                :
                    <div></div>
                }
                <div className="text" style={{width:'100%', paddingBottom:'6px', fontSize:'16px', lineHeight:'20px', verticalAlign:'baseline'}}  >
                    <p className="fs fs15 fw fw100 FlamaBook greyColor" 
                            style={{width:'100%', textAlign:'left',  paddingRight: '10px', fontWeight:'normal'}}>
                        <label>
                            <input type="checkbox"  style={estiloCheckBox} onChange={(e) => onChangeMantActiva(e)} /> 
                            <span style={{paddingLeft:'10px'}}> Mantener sesión abierta </span>
                        </label>                        
                    </p>
                </div>
                {(status===1) ?
                    <div className="text" style={{width:'100%', paddingBottom:'6px', fontSize:'16px', lineHeight:'20px', verticalAlign:'baseline'}}  >
                        <p className="fs fs15 fw fw100 FlamaBook greyColor" 
                                style={{width:'100%', textAlign:'left', paddingRight: '10px', fontWeight:'normal'}}>
                            <label>
                                <input type="checkbox"  style={estiloCheckBox} onChange={(e) => onChangeCerrarAnt(e)} /> 
                                <span style={{paddingLeft:'10px'}}> Cerrar sesiones anteriores </span> 
                            </label>                        
                        </p>
                    </div>
                :
                    <div></div>
                }
              <div className="cover cover__help" >
                <span className="fs fs15 fw fw100 uppercase help__button"  onClick={handleHelpToggle}>AYUDA</span>
              </div>
              {(verLinkRecupera)?
                    <div className="cover cover__buttons" style={{justifyContent:'space-between'}} >
                        <div className="fs fs16 fw fw100 FlamaBook greenColor" onClick={(e) => muestraRecupera()} style={{marginTop:'12px', cursor:'pointer' }}>¿Ha olvidado su contraseña?</div>
                        <button className="button button__green" onClick={(e) => handleLogin(e)}>Entrar</button>
                    </div>
                :
                    <div className="cover cover__buttons" >
                        <button className="button button__green" onClick={(e) => handleLogin(e)}>Entrar</button>
                    </div>
                }
            </form>
            <div className={isHelpActive ? "help help__content" : "help help__content active"}>
                <div className="module module__text" >
                    <h3 className="fs fs40 fw fw300 FlamaBook blackColor" >Ayuda</h3>
                    <div className="separator separator__20" ></div>
                    <div className="line line__grey" ></div>
                    <div className="separator separator__20" ></div>
                    <h4 className="fs fs18 fw fw400 blackColor" >¿Cómo puedo acceder a BOT PLUS?</h4>
                    <div className="separator separator__20" ></div>
                    <div className="text fs fs16 fw fw100 FlamaBook blackColor" >
                        <p>El acceso a BOT PLUS requiere de la suscripción anual al producto. En caso de que usted sea suscriptor de BOT PLUS o de la Colección CONSEJO, 
                            introduzca el usuario y contraseña que se le remite en el correo de confirmación de su pedido.
                        </p>
                    </div>
                    <div className="separator separator__20" ></div>
                    <div className="columns" >    
                        <div className="text fs fs16 fw fw100 FlamaBook blackColor" >
                            <p>En caso de no disponer ya de dicho correo, o bien en caso de olvido de la clave, puede recuperarla introduciendo en el siguiente cuadro 
                                su DNI / CIF y el número de factura o pedido.
                            </p>
                            <div className="separator separator__20" ></div>
                            <p>En caso de que no pudiera recuperar la clave, contacte con el Consejo General de Colegios Oficiales de Farmacéuticos.
                            </p>
                        </div>
                        <Recupera></Recupera>
                        {/*<div className="box box__claves">
                            <form>
                                <div className="cover cover__input" >
                                    <label className="fs fs15 fw fw400 greyColor2" >DNI / CIF</label>
                                    <input type="text" name="usuario" placeholder="Escribe la aplicación" />
                                </div>
                                <div className="cover cover__input" >
                                    <label className="fs fs15 fw fw400 greyColor2" >Número de factura o pedido</label>
                                    <input type="text" name="usuario" placeholder="Escribe la aplicación" />
                                </div>
                                <div className="cover cover__buttons" >
                                    <button className="button button__purple" >Obtener Claves</button>
                                </div>
                            </form>
                        </div>*/}
                    </div>
                </div>
                <div className="module module__text" >
                    <h4 className="fs fs18 fw fw400 blackColor" >¿Puedo acceder a BOT PLUS con mi usuario y clave de <i>Farmaceuticos.com</i>?</h4>
                    <div className="separator separator__20" ></div>
                    <div className="text fs fs16 fw fw100 blackColor" >
                        <p className="FlamaBook" >No. La clave de acceso a Farmaceuticos.com es diferente que la de BOT PLUS.</p>
                        <div className="separator separator__20" ></div>
                        <p className="FlamaBook">No obstante, los farmacéuticos colegiados pueden acceder de forma gratuita aunque limitada a los contenidos de BOT PLUS. 
                            Para ello, deben registrarse en Farmaceuticos.com: 
                        </p>
                        <div className="separator separator__10" ></div>
                        <img src={rootPath +"img/AccesoAcceso.png"} alt="" style={{width:'100%', marginTop:'10px', marginBottom: '20px'}} />
                        <p className="FlamaBook">Y acceder a "Mi Zona de Usuario": 
                        </p>
                        <div className="separator separator__10" ></div>
                        <img src={rootPath +"img/AccesoMiZona.png"} alt="" style={{width:'100%', marginTop:'10px', marginBottom: '20px'}} />
                        <p className="FlamaBook">
                        A continuación, deberán hacer clic en la opción de herramientas. 
                        </p>
                        <img src={rootPath +"img/AccesoHerramientas.png"} alt="" style={{width:'100%', marginTop:'10px', marginBottom: '20px'}} />
                        <p className="FlamaBook">
                        En esta opción encontrará un acceso gratuito a BOT PLUS web.
                        </p>
                        <img src={rootPath +"img/AccesoBOTPLUS.png"} alt="" style={{width:'100%', marginTop:'10px', marginBottom: '20px'}} />
                    </div>
                </div>
              <div className="module module__text" >
                <h4 className="fs fs18 fw fw400 blackColor" >¿Cambia la clave de BOT PLUS?</h4>
                <div className="separator separator__20" ></div>
                <div className="text fs fs16 fw fw100 FlamaBook blackColor" >
                <p>La suscripción a BOT PLUS permite acceder a la herramienta durante el año de vigencia de la licencia.</p>
                <div className="separator separator__20" ></div>
                <p>En el caso de farmacéuticos suscriptores permanentes de BOT PLUS, podrán seguir usando la misma clave todos los años sin necesidad de cambiarla.</p>
                </div>
              </div>
              <div className="module module__text" >
                <h4 className="fs fs18 fw fw400 blackColor" >¿Desde cuántos dispositivos puedo acceder a BOT PLUS?</h4>
                <div className="separator separator__20" ></div>
                <div className="text fs fs16 fw fw100 FlamaBook blackColor" >
                    <p>BOT PLUS es un programa on-line que permite la consulta de la información recogida en la base de datos. 
                        Puede acceder a BOT PLUS desde cualquier dispositivo electrónico, incluyendo pc, Tablet o smartphone.
                    </p>
                    <div className="separator separator__20" ></div>
                    <p>La suscripción a BOT PLUS le permite un acceso concurrente, por lo que en caso de que su sesión 
                        estuviera abierta en otro dispositivo, la propia herramienta le avisará de que debe cerrar la anterior sesión antes de entrar con el nuevo dispositivo.
                    </p>
                </div>
              </div>
              <div className="module module__text" >
                <h4 className="fs fs18 fw fw400 blackColor" >¿Dónde puedo consultar en caso de tener dudas sobre BOT PLUS?</h4>
                <div className="separator separator__20" ></div>
                <div className="text fs fs16 fw fw100 FlamaBook blackColor" >
                    <p>En caso de necesitar <a href="https://farmaceuticos.com/contacto" target="_new"  style={{color:"#5c9029"}}> contactar con el Consejo General de Colegios Oficiales de Farmacéuticos</a>, podrá hacerlo a través de los siguientes medios:
                    </p>
                    <div className="separator separator__20" ></div>
                    <p>Teléfono: 914312689</p>
                    <p>Correo electrónico: congral@redfarma.org</p>
                </div>
              </div>
              <div className="cover cover__help" >
                <span className="fs fs15 fw fw100 uppercase FlamaBook help__button"  onClick={handleHelpToggle} >Cerrar</span>
              </div>
              <div className="line line__grey" ></div>
            </div>
          </div>
        </div>
      </section>
    );
  };
  
  export default Login;