import LoadingPopup from "../../Resultados/Loading.Popup";
import SelectorUno from "../../Utilidades/Selector/SelectorUno";
import BP2022Service from "../../../services/user.service";
import { useEffect, useState } from "react";
import ResumenBusqueda from "../ResumenBusqueda";
import { GetAntecesorWhithClassName } from "../../../services/utils";


const FBEspecieIndicacion= (props) => {
    const [datosFormulario, setDatosFormulario] = useState({
        listaEnf: null,
        listaEspecie: null,
    });
    const [seleccionFormulario, setSeleccionFormulario] = useState({
        idEspecie: props.idEspecie,
        idEnf: props.idEnf,
    })

    useEffect(() => {
        if (datosFormulario.listaEnf === null)
        {
            DescargaDatos();
        }        
    },[]);

    const DescargaDatos = () => {      
        BP2022Service.datosFormularioEspecieIndicacion()
        .then((response) => {
            var df = {
                listaEnf : response.data.Enfermedades,
                listaEspecie : response.data.Especies,
            };
            df.listaEnf.splice(0,0,{Codigo:0, Valor:'Escriba una enfermedad'});
            df.listaEspecie.splice(0,0,{Codigo:0, Valor:'Escriba una especie animal'});
            setDatosFormulario(df);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const KeyDownForm = (e) =>{
        var keyCode = e.keyCode;
        if (keyCode === 13){
            clickBuscar(e);
        }
    }



    const construyeResumenBusqueda = () => {
        var res = [];
        if (seleccionFormulario.idEnf>0){
            if (datosFormulario.listaEnf){
                var item = datosFormulario.listaEnf.find(x => x.Codigo === seleccionFormulario.idEnf);
                if (item)
                    res.push ({titulo: "Indicación", valor: item.Valor});
            }
        }
        if (seleccionFormulario.idEspecie>0){
            if (datosFormulario.listaEspecie){
                var item = datosFormulario.listaEspecie.find(x => x.Codigo === seleccionFormulario.idEspecie);
                if (item)
                    res.push ({titulo: "Especie destino", valor: item.Valor});
            }
        }
        return res;
    }

    const resumen = construyeResumenBusqueda(seleccionFormulario);

    const hayBusqueda = seleccionFormulario.idEnf>0 || seleccionFormulario.idEspecie>0; 

    const clickBuscar = (e) =>{
        if (hayBusqueda){
            props.cambiaPagina({
                newpagina: 'BEspecieIndicacionMUV',
                idEnf: seleccionFormulario.idEnf,
                idEspecie: seleccionFormulario.idEspecie,
            })
        }
    }

    const BorraBusqueda = (e) =>{
        const formularioVacio={
            idEnf: 0,
            idEspecie: 0,
        }
        setSeleccionFormulario(formularioVacio);
        var inputs = document.getElementsByClassName('inputSelector');
        if (inputs){
            for (var i=0; i<inputs.length; i++){
                inputs[i].value = "";
                const itemContainer = GetAntecesorWhithClassName(inputs[i], 'custom-select-container');
                if (itemContainer){
                    itemContainer.classList.remove('active');
                }
            }
        }
    }

    const CambioEnfermedad = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idEnf = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioEspecie = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idEspecie = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const BorraEnfermedad = () =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idEnf = 0;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const BorraEspecie = () =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idEspecie = 0;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const estiloBotonesSup = (hayBusqueda ? {height:'76px', width:'100%'} : {height:'76px', width:'100%', visibility:'hidden'})
    const estiloBotonesInf = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'center'} : {height:'76px', width:'100%', justifyContent: 'center', display:'none'})

    const listaCodValorEnf = (datosFormulario.listaEnf) ? datosFormulario.listaEnf.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorEspecie = (datosFormulario.listaEspecie) ? datosFormulario.listaEspecie.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    
    const clasedivmodulefilters = "cover cover__filters cover__filters__2 cover__filters__2--1 cover__filters__2--1__50";

    return (
        <div class="content" >

            { (datosFormulario.arbol === null) ?
                <LoadingPopup titulo="Un momento" texto="Preparando formulario" esPorcentaje={true}></LoadingPopup>
            :
                <form class="content__results content__results__filters" id="main-filter"  onSubmit={(e) => e.preventDefault()}  onKeyDown={(e) => KeyDownForm(e)} tabIndex="0">
                    <div class="module module__buttons" style={estiloBotonesSup}>
                        <span class="button button__arrow button__green noUppercase reset-form" data-form="#main-filter" title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} >
                            Vaciar búsqueda
                        </span>
                    </div>
                    <div class={clasedivmodulefilters} >
                        <SelectorUno lista = {listaCodValorEspecie} 
                                    idHTML="FBEspecieIndicacionLEspecie" 
                                    valueSelected={seleccionFormulario.idEspecie} 
                                    alCambiar={CambioEspecie} 
                                    alBorrar={BorraEspecie} 
                                    ancho="100%"
                                    titulo="Especie destino"
                        ></SelectorUno>
                        <SelectorUno lista = {listaCodValorEnf} 
                                    idHTML="FBEspecieIndicacionLEnfermedad" 
                                    valueSelected={seleccionFormulario.idEnf} 
                                    alCambiar={CambioEnfermedad} 
                                    alBorrar={BorraEnfermedad} 
                                    ancho="100%"
                                    titulo="Indicación"
                        ></SelectorUno>
                    </div>
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesInf}>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                    </div>
                    <ResumenBusqueda condiciones={resumen}></ResumenBusqueda>
                </form>
            }
        </div>
    );
};

export default FBEspecieIndicacion;
