import React, { useEffect, useState } from "react";
import Nota from "./Nota";
import BP2022Service, {getUltimasNotasYAlertas} from "../../../../services/user.service";
import { rootPath } from "../../../../services/utils";

const NotasSeguridadYAlertasCalidad = (props) => {
    const [listaItems, setListaItems] = useState(null);

    useEffect(() => {
      descargaNotasYAlertas();
    }, [])
  
    const descargaNotasYAlertas = () => {
      BP2022Service.getUltimasNotasYAlertas(3)
      .then((response) => {
        setListaItems(response.data);
      })
      .catch(function (error) {
        console.log('Error', error.message);
      });
    };

    const notas = listaItems ? (
        listaItems.map(fila => <Nota item={fila} ></Nota>)
      ):(
        <div></div>
      );
    
    const vesAUltimoMes = (codigo) =>{
        CambiaPaginaSimple("FBDocumentos")
    }

    const CambiaPaginaSimple = (codigoPagina) => {
        props.cambiaPagina({
            newpagina: codigoPagina
        });
    }



    return (
        <section class="section section__alertas" >
            <div class="container" >
                <div class="content" >
                    <div class="title" >
                        <div class="mask mask__auto" >
                            <img src={rootPath + "img/alertas.svg"} alt="alertas" />
                        </div>
                        <div class="text" >
                            <h2 class="fs fs20 fw fw500 purpleColor" >Notas de seguridad y Alertas de calidad</h2>
                        </div>
                    </div>
                    <ul class="list" >
                        {notas}
                    </ul>
                    <div class="module module__buttons" >
                        <span class="button button__arrow button__purple" title="Ver más" onClick={() => vesAUltimoMes()} style={{cursor:'pointer'}}>
                            Ver más <img src={rootPath + "img/arrowRightWhite.svg"} alt="arrowRightWhite" />
                        </span>
                    </div>
                </div>
            </div>
	    </section> 
    );
};
  
export default NotasSeguridadYAlertasCalidad;
  