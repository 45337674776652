import DatoFarmaceutico from "./DatoFarmaceutico";

const DatosConservacion = (props) => {
    const datosConservacion = props.datosConservacion;
    const htmlConservacion = datosConservacion.map(dato => <DatoFarmaceutico dato={dato}></DatoFarmaceutico>)
    return (
        <div class="module module__infoColumns module__infoColumns__noBorder" > 
            {(props.titulo) ?
                <div class="text" >
                    <h3 class="fs fs16 fw fw600 greyColor2 title" >{props.titulo}</h3>
                </div>
            :
                <div class="text" >
                </div>
            }

            <div class="columns columns__2" >
                {htmlConservacion}
            </div>
        </div> 
    )
}
export default DatosConservacion;

