import { FormatoFechaDDMMYYYY } from "../../../../services/utils";

const AvisoPriciosFuturos = (props) => {
    const fecha = props.fecha;
    return (
        <div class="alerta">
            <svg id="icon_aviso" width="12px" height="16px" viewBox="0 0 12 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns_xlink="http://www.w3.org/1999/xlink">
                <title>icon/aviso</title>
                <g id="Ficha-medicamento" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                    <g id="301A-Ficha-medicamento-humano" transform="translate(-650.000000, -1633.000000)" stroke="#D9710A">
                        <g id="Group-22" transform="translate(641.000000, 1630.000000)">
                            <g id="icon/aviso" transform="translate(10.200000, 4.400000)">
                                <path d="M5.84920867,2.61184074 L5.84920867,1.05413001 L5.84393409,1.05413001 C5.84393409,0.47210692 5.37641504,0 4.80004795,0 C4.22368087,0 3.75616181,0.47210692 3.75616181,1.05413001 L3.75088723,1.05413001 L3.75088723,2.61184074" id="Stroke-1" stroke-width="0.9"></path>
                                <path d="M3.75088723,12.6986898 L3.75088723,13.0463542 L3.75616181,13.0463542 C3.75616181,13.6283773 4.22368087,14.1 4.80004795,14.1 C5.37641504,14.1 5.84393409,13.6283773 5.84393409,13.0463542 L5.84920867,13.0463542 L5.84920867,12.6986898" id="Stroke-3" stroke-width="0.9"></path>
                                <path d="M0.724288724,9.9263022 L8.87590308,9.9263022" id="Shape" stroke-width="0.9"></path>
                                <path d="M8.87590308,9.9263022 L8.87590308,6.53681662 L8.85480478,6.53681662 C8.85480478,4.27554553 7.03939232,2.44231804 4.8000959,2.44231804 C2.56079949,2.44231804 0.74538702,4.27554553 0.74538702,6.53681662 L0.724288724,6.53681662 L0.724288724,9.9263022" id="Path" stroke-width="1.35"></path>
                                <path d="M8.87580718,9.9263022 L9.50923556,11.4336549 C9.75522251,11.8316773 9.47231355,12.3473633 9.00767153,12.3473633 L0.592328473,12.3473633 C0.127686455,12.3473633 -0.155222514,11.8316773 0.0907644369,11.4336549 L0.724192823,9.9263022" id="Path" stroke-width="1.35"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
            <span>Nuevos precios a partir de {FormatoFechaDDMMYYYY(fecha)}</span>
        </div>
    )
}
export default AvisoPriciosFuturos;
