
function OptionSelect({opt}){
    if (opt != null){
        return (
            <option data-value={opt.id}>{opt.nombre}</option>
        )    
    }
    else return null;
}

export default OptionSelect;