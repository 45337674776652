import Acordeon from "../../../FichaMUH/Apartados/Composicion/Acordeon";

const Cie = (props) => {
    const lista = props.lista;

    const HTMLFila = lista.map(item => 
        <div class="table__row table__row__normal fs fs14 fw fw300 blackColor uppercase" style={{display:'table',width:'100%'}}>
            <div style={{display:'table-row'}}>
                <div class="column" style={{ width:'20%', display:'table-cell', paddingTop:'7px', paddingBottom:'7px',verticalAlign:'middle' }}>
                    <p style={{verticalAlign:'middle'}}>{item.Codigo}</p>
                </div>
                <div class="column" style={{ width:'80%', display:'table-cell', paddingTop:'7px', paddingBottom:'7px', textTransform:'none', textAlign: 'left',verticalAlign:'middle' }}>
                    <p style={{verticalAlign:'middle'}}>{item.Valor}</p>
                </div>
            </div>
        </div>
    );

    const HTMLTablaCie = (
        <div class="infoContent">
            <div class="content__results content__results__filters">
                <div class="table" style={{width:'100%'}}>
                    <div class="table__row table__row__header fs fs12 fw fw600 greenColor2 uppercase" style={{display:'table',width:'100%'}}>
                        <div style={{display:'table-row'}}>
                            <div class="column" style={{ width:'20%', display:'table-cell', paddingTop:'10px', paddingBottom:'5px' }} >
                                <p style={{marginBottom: '0px'}}>Código CIE</p>
                            </div>
                            <div class="column" style={{width:'80%', display:'table-cell', paddingTop:'10px', paddingBottom:'5px' }} >
                                <p>Descripción</p>
                            </div>
                        </div>
                    </div>
                    {HTMLFila}
                </div>
            </div>
        </div>
    )
    return (     
        <div  style={{marginTop: '20px'}}>
        <Acordeon titulo="Clasificación Internacional de Enfermedades (CIE)" contenido={HTMLTablaCie}></Acordeon>
        </div>
    )
}
export default Cie;

