import { useState } from "react";
import { useEffect } from "react";


function SelectorNumero(props){
    const [num, setNum] = useState(props.num);
    //const [txt, setTxt] = useState('');
    //const soloEnteros = props.soloEnteros;
    const valorVacio = props.valorVacio;
    const sufijo = props.sufijo;
    const numNull = (num ===null || num == undefined);
    var pulsaPunto=false;

    
    useEffect(() => {
        setNum(props.num)
    },  [props.num]);

    const cambioNum = (e) =>{
        var item = e.target
        var value = item.value;
        if (!pulsaPunto){
            setNum(value);
            var ivalue = parseFloat(value.replace(',','.'));
            props.alCambiar(ivalue);
        }
    }

    const checkDot= (e) =>{
        var keyCode = e.keyCode;
        if ((keyCode===110)||(keyCode===190)){
            e.stopPropagation();
            e.preventDefault();
            pulsaPunto=true;
        }else{
            pulsaPunto=false;
        }
    }

    const titulo = (props.titulo === "&nbsp;" ? <span>&nbsp;</span> : props.titulo);

    const HTMLSufijo = (props.sufijo) ?
        <span style={{paddingTop:'20%', paddingLeft:'10px'}}>{sufijo}</span>:
        <></>;
    

    

    return (
        <div class="module__units">
            <h3 class="title fs fs16 greyColor2" >{titulo}</h3>
            <div class="cover cover__input" style={{display:"flex", justifyContent:"start"}}>
                <input type="number" 
                        id="input_num" 
                        value={((!numNull) ? (num !== valorVacio) ? num : '' : '') }
                        name=""
                        /*min={props.min} 
                        max={props.max} */
                        onChange={(e) => cambioNum(e)}
                        className = "input__sel__num"
                        onKeyDown={(e) => checkDot(e)}
                        /*onKeyUp={(e) => checkDot(e)}
                        onKeypress={(e) => checkDot(e)} */
                /> 
                {HTMLSufijo}
            </div>
        </div>
    )
}
export default SelectorNumero;
