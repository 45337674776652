import React, { useState } from "react";
import { divideArrayEnPartes, numberWithCommas, rootPath } from "../../../services/utils";

import LinksFichaExt from "./LinksFichaExt";

const MargenDerechoExt = (props) => {
    const ficha = props.ficha;
    var imgDefecto = rootPath + "img/person.svg" ;

    const maxHeight=180;
    const maxWidth=256;

    const estildefecto = {maxWidth: '180px', maxHeight: maxHeight.toString() + 'px', width: '100%', height: 'auto'}

    const imagenesHTML = <div style={{textAlign:'center', borderBottom: 'none', paddingBottom: '10px'}}><p>&nbsp;</p><img class="imagen__medicamento" src={imgDefecto} style={estildefecto} /></div>
                                                                               

    return (
        <div class="medicamento">
            <div class="nombre f-f-Flama-Medium">{ficha.NombreCompleto}</div>
            <div class="contenido__imagen" style={{minHeight:'10px'}}>
                    {/*imagenesHTML*/}
            </div>
            <LinksFichaExt ficha={ficha} cambiaPagina={props.cambiaPagina} ></LinksFichaExt>
        </div>
    )
}
export default MargenDerechoExt;

