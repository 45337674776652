import { DevuelveTextoAsHTML } from "../../../../../services/utils";
import Acordeon from "../Composicion/Acordeon";

const Texto = (props) => {
    const epigrafe = props.epigrafe;
    const titulo = epigrafe.Epigrafe;
    const texto = epigrafe.Texto;
    const textos = texto.split("####");
    var formatedtexto = DevuelveTextoAsHTML(textos[0]);
    for (var i=1; i<textos.length; i++){
        if  (i % 2 === 0){
            formatedtexto = <span>{formatedtexto}{DevuelveTextoAsHTML(textos[i])}</span>
        }
        else{
            formatedtexto = <span>{formatedtexto}<span style={{fontWeight:'bold'}}>&nbsp;&nbsp;&nbsp;&nbsp;{textos[i]}</span></span>
        }
    }

    var contenidoAcordeon = (
        <p class="fs fs16 FlamaBook blackColor" style={{whiteSpace: 'pre-line'}}>
            {formatedtexto}
        </p>
    );
    return (
        <div>
            {props.ancla ? 
                <div style={{position : 'relative', top: '-114px'}} ref={props.ancla.refAncla} key={props.ancla.idAncla} id={props.ancla.idAncla}></div> 
            :
                <></>
            }
            <Acordeon titulo = {titulo} contenido={contenidoAcordeon} activo = {(props.epigrafeActivo.includes(epigrafe.IdEpigrafe))}></Acordeon>
        </div>
    )
}
export default Texto;
