import Slider from 'react-slick'
import React, { useState } from "react";
import { divideArrayEnPartes, numberWithCommas, rootPath } from "../../../services/utils";
import FilaPictogramas from "../FichaMUH/InfoPrincipal/FilaPictogramas";

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import LinksFichaMUV from './LinksFichaMUV';

const MargenDerchoMUV = (props) => {
    const [sliderRef, setSliderRef] = useState(null)
    const ficha = props.ficha;
    const imgDefecto = rootPath + "img/perro.svg" ;
    const srcImagen =   (props.ficha.ImagenEnvase != null ?
                            (props.ficha.ImagenEnvase.URLFull != null ?
                                props.ficha.ImagenEnvase.URLFull
                            : 
                                props.ficha.ImagenEnvase.URL
                            )
                        :
                            ''
                        );

    const srcImagenFF = (props.ficha.ImagenFormaF != null ?
                            (props.ficha.ImagenFormaF.URLFull != null ?
                                props.ficha.ImagenFormaF.URLFull
                            : 
                                props.ficha.ImagenFormaF.URL
                            )
                        :
                            ''
                        );
    const tieneImagenes = srcImagen !== '' || srcImagenFF !== '';    
    const arrayImgs = [];
    if (srcImagen != '')
        arrayImgs.push(srcImagen);
    if (srcImagenFF != '')
        arrayImgs.push(srcImagenFF);

    const imagenesHTML = tieneImagenes ? arrayImgs.map(srcImg => <div><img class="imagen__medicamento" src={srcImg} alt={srcImg} onClick={(e) => props.clickImagen(e, arrayImgs.indexOf(srcImg))} /></div>)
                                        : <div><p>&nbsp;</p><img class="imagen__medicamento" src={imgDefecto} style={{height:'180px', cursor:'default'}} /></div>

    const sliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        speed: 300,
        arrows: true,
        touchMove: true,
        accessibility: true,
        adaptativeHeight: true,
        dots: false,
        focusOnSelect: true,
        className: 'single-item slide__1',
    }
                                                                                
    const pictogramasEnFilas = divideArrayEnPartes(ficha.Pictogramas,5);    
    const pictogramas = pictogramasEnFilas.map( fila => <FilaPictogramas fila={fila}></FilaPictogramas>);
    const txtDispensable = ficha.Dispensable === "No, solo a veterinarios" || ficha.Dispensable === "Solo a veterinarios" ? "Solo a vet." : ficha.Dispensable;
    return (
        <div class="medicamento">
            <div class="nombre f-f-Flama-Medium">{ficha.NombreCompleto}</div>
                <div class="contenido__imagen">
                    <Slider ref={sliderRef} {...sliderSettings}>
                        {imagenesHTML}
                    </Slider>
                    {pictogramas}
                </div>
            <div class="detalles">
                <div class="condiciones">
                    <div class="fila">
                        <div class="col-i">Receta</div>
                        <div class="col-d">{ficha.Receta}</div>
                    </div>
                    <div class="fila" title={ficha.Dispensable}>
                        <div class="col-i">Dispensable</div>
                        <div class="col-d">{txtDispensable}</div>
                    </div>
                    <div class="fila">
                        <div class="col-i">Financiado</div>
                        <div class="col-d">{ficha.Financiado}</div>
                    </div>
                </div>
            </div>
            <LinksFichaMUV ficha={ficha} cambiaPagina={props.cambiaPagina} ></LinksFichaMUV>
        </div>
    )
}
export default MargenDerchoMUV;
