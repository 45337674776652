export default function authHeader() {
    const user = JSON.parse(localStorage.getItem('login'));
    if (user && user.Token) {
        return { 'Authorization': 'Bearer ' + user.Token,
            'Access-Control-Allow-Origin': '*'
        };
    } else {
        return { 'Access-Control-Allow-Origin': '*'
        };
    }
}

export function noAuthHeader() {
    return { 'Access-Control-Allow-Origin': '*'};
}





/*
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': '*',
              'Access-Control-Allow-Headers': '*',
*/