import React, { useEffect, useState } from "react";
import ProblemaSuministro from "./ProblemaSuministro";
import BP2022Service, {getProductosConPS} from "../../../../services/user.service";
import { PerfilUsuario, rootPath } from "../../../../services/utils";

const ProblemasSuministroInicio = (props) => {
    const [listaItems, setListaItems] = useState(null);

    useEffect(() => {
      descargaPS();
    }, [])
  
    const descargaPS = () => {
      BP2022Service.getProductosConPS(6)
      .then((response) => {
        setListaItems(response.data.Resultados);
      })
      .catch(function (error) {
        console.log('Error', error.message);
      });
    };

    const problemas = listaItems ? (
        listaItems.map(fila => <ProblemaSuministro item={fila} cambiaPagina={props.cambiaPagina}></ProblemaSuministro>)
      ):(
        <div></div>
      );

    const CambiaPaginaSimple = (codigoPagina) => {
        props.cambiaPagina({
            newpagina: codigoPagina
        });
    }
    
    const vesAUltimoMes = (codigo) =>{
        //CambiaPaginaSimple("BMedicamentosPSUltimoMes")
        CambiaPaginaSimple("FBProblemasSumMUH")
    }

    const rol = PerfilUsuario();
    const classButton = (rol === "COL" ? "button button__arrow button__grey" : "button button__arrow button__purple")

    return (
        <aside class="aside" >
            <div class="title" >
                <h2 class="fs fs20 fw fw400 purpleColor" >Problemas de suministro</h2>
            </div>
            <ul class="list" >
                {problemas}
            </ul>
            <div class="module module__buttons">
                <span class={classButton} title="Ver más" onClick={() => vesAUltimoMes()} style={{cursor:'pointer'}}>
                    Ver más <img src={rootPath + "img/arrowRightWhite.svg"} alt="arrowRightWhite" />
                </span>
            </div>
        </aside>
    );
};

export default ProblemasSuministroInicio;

