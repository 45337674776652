import ItemResumenFiltros from "./ItemResumenFiltros"

function ResumenFiltros(props){
    const filtros = props.filtros
    var items = []
    for(var i=0 ; i<filtros.length; i++){
        if (filtros[i].tipo==='List'){
            if (filtros[i].valor !== ''){
                items.push(
                    {
                        descripcion: filtros[i].display + ' ' + filtros[i].valor,
                        idFiltro : filtros[i].idGrupo,
                        valor: filtros[i].valor
                    }
                )
            }
        }
        if (filtros[i].tipo==='ListBox'){
            if (filtros[i].valor > -1){
                items.push(
                    {
                        descripcion: filtros[i].display + ' ' + filtros[i].displayValor,
                        idFiltro : filtros[i].idGrupo,
                        valor: filtros[i].valor
                    }
                )
            }
        }
        if (filtros[i].tipo==='Text' || filtros[i].tipo==='CN'){
            if (filtros[i].valor !== ''){
                items.push(
                    {
                        descripcion: filtros[i].display + ' ' + filtros[i].valor,
                        idFiltro : filtros[i].idGrupo,
                        valor: filtros[i].valor
                    }
                )
            }
        }
        if (filtros[i].tipo === 'Check'){
            for (var j=0; j<filtros[i].valores.length; j++){
                if (filtros[i].valores[j].activo){
                    items.push(
                        {
                            descripcion: filtros[i].valores[j].display ? filtros[i].valores[j].display : filtros[i].valores[j].texto,
                            idFiltro : filtros[i].idGrupo,
                            valor: filtros[i].valores[j].value
                        }
                    )
                }
            }
        }
    }

    const itemsHtml = items.map(item => <ItemResumenFiltros item = {item} borraFiltro = {props.borraFiltro}></ItemResumenFiltros>)

    return (
        <form class="content__results content__results__filters" id="main-filter">
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div class="filtersMagistrales filtersMagistrales__historial">
                <div class="title">Filtros</div>
                <div class="cover cover__filters">
                    <div class="cover cover__tags tags related-checks" id="tags-filter">
                        {itemsHtml}
                    </div>
                    <span class="button button__arrow button__green noUppercase reset-form"
                        data-form="#sidebar-filter" title="Limpiar filtros" onClick={e => props.borraTodosFiltros()} style={{width:'120px'}}>
                            Quitar filtros
                    </span>
                </div>
            </div>
        </form>
    );
}

export default ResumenFiltros;
