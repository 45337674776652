import Acordeon from "../../../../Fichas/FichaMUH/Apartados/Composicion/Acordeon";

const GrupoCamposExportables = (props) => {
    const codGrupo = props.codGrupo;
    const titulo = props.titulo;
    const campos = props.campos;
    const activo = props.activo;
    const seleccionados = props.seleccionados;
    const disabledAdd = props.disabledAdd;

    var contenidoAcordeon = (
       ((campos) ?
            campos.map(campo =>
                ((seleccionados.includes(campo.CodCampo) || !disabledAdd) ?
                    <label style={{marginLeft:'2px'}}>
                        <input 
                            type="checkbox" 
                            name={campo.CodCampo} 
                            value={campo.CodCampo} 
                            checked={seleccionados.includes(campo.CodCampo)} 
                            onClick={e => props.onCheck(e,campo.CodCampo)} />
                        {campo.Titulo}
                    </label>
                :
                    <label class="disabled" style={{opacity:'0.5',marginLeft:'2px'}}>
                        <input 
                            type="checkbox" 
                            name={campo.CodCampo} 
                            value={campo.CodCampo} 
                            checked={seleccionados.includes(campo.CodCampo)} 
                            onClick={e => props.onCheck(e,campo.CodCampo)} 
                            disabled = {!seleccionados.includes(campo.CodCampo)}/>
                        {campo.Titulo}
                    </label>
                )
            )
        :
            <div></div>
        )
    );

    const HTMLAcordeon = (
        <div class="checks checks__30" >
            <div class="cover" >
                {contenidoAcordeon}
            </div>
        </div>
    );


    return (
        <Acordeon titulo = {titulo} contenido={HTMLAcordeon} activo={activo} tipo={4}></Acordeon>
    )
}
export default GrupoCamposExportables;
 
