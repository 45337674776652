import React, { useState } from "react";
import { useEffect } from "react";
import {ToggleClassNameToClassItems, RemoveClassNameToClassItems, AddClassNameToClassItems, rootPath} from '../../../../services/utils.js'
import OpcionOrden from './OpcionOrden.js'


function EncabezadoListado(props){
    const [estadoOpcionesOrden, setEstadoOpcionesOrden] = useState(false);
    const ordenesPosibles = ['Nombre ascendente', 'Nombre descendente'
                            , 'Código nacional ascendente', 'Código nacional descendente'
                            , 'Precio de menor a mayor', 'Precio de mayor a menor'];
    if (props.mostrarFVisita)
        ordenesPosibles.push ("Fecha visita");

    if (props.mostrarDocumentos){
        ordenesPosibles.splice(0,6,"Título ascendente")
        ordenesPosibles.push("Título descendente")
        ordenesPosibles.push("Fecha ascendente")
        ordenesPosibles.push("Fecha descendente")
    }
    const currentIndex = props.currentIndex;                           
    
    const opciones = ordenesPosibles.map(opcion => <OpcionOrden texto={opcion} index={ordenesPosibles.indexOf(opcion)} setOrden={props.cambioOrden} currentIndex={currentIndex}></OpcionOrden>);

    const handleClickOrden = (e) =>{
        ToggleClassNameToClassItems('ordenListado',null,'active');
        setEstadoOpcionesOrden(!estadoOpcionesOrden);
    }
    
   
    const handleExport = (e) =>{
        //alert("Exportación de resultados no implementada");
        props.alExportar(true);
    }

    const muestraBotonTabla = !props.mostrarFM && !props.mostrarEXT && !props.mostrarDocumentos;

    var claseSwitchGrid     = 'switch__grid';
    var claseSwitchList     = 'switch__list';
    var claseSwitchPackList = 'switch__packlist';

    if (props.comoGrid && !props.mostrarFM && !props.mostrarEXT)
        claseSwitchGrid += " active";
    else if (props.listaPack && muestraBotonTabla)
        claseSwitchPackList += " active";
    else
        claseSwitchList += " active";
   
    const nFiltrados = props.listaitems.length;
    const nDescargados = props.numDescargados;
    const nTotal =  props.numTotal;
    var texto = nTotal + "resultados";
    if (nFiltrados === nDescargados)
        if (nTotal > nDescargados)
            texto = "Descargados " + nDescargados + " de " + nTotal + ". Descargando el resto... "
        else
            texto = nDescargados.toString() + " resultados " 
    else
        if (nTotal > nDescargados)
            texto =  nFiltrados + " de " + nDescargados + ". Descargando el resto... "
        else
            texto =  nFiltrados + " de " + nDescargados + " "


    return (
        <div class="content__header content__header__border" >
            <div class="info__items" >
                <p class="fs fs15 fw fw400 blackColor results" >{texto}</p>
                {(!props.mostrarDocumentos) ?
                    <span class="fs fs12 fw fw400 purpleColor export"  onClick={(e) => handleExport(e)} >
                        <img src={rootPath + "img/export.svg"} alt="Export" /> Exportar
                    </span>
                :
                    <span></span>
                }
                {(props.mostrarLimpiaHistorial) ?
                    <span class="fs fs12 fw fw400 purpleColor export"  onClick={(e) => props.alVaciar()} style={{paddingLeft:'10px', marginLeft:'10px', borderLeftStyle:'solid', borderLeftColor:'#414139', borderLeftWidth:'1px'}}>
                        <img src={rootPath + "img/empty.svg"} alt="Vaciar historial" /> Vaciar historial
                    </span>
                :
                    <span></span>
                }
            </div>
            <div class="actions" >
            {(!props.mostrarFM && !props.mostrarEXT) ?
                <div class="switch" >
                    {/*<div class={claseSwitchGrid} title="grid" onClick={(e) => props.clickComoGrid(e)}>
                        <img style={{width : '20px'}} src="./img/ImagenListaCompress.svg" />
                    </div>*/}
                    {(muestraBotonTabla) ? 
                    <div class={claseSwitchPackList} title="list" onClick={(e) => props.clickComoPackList(e)}>
                        <svg width="21px" height="12px" viewBox="0 0 21 12" >
                            <g id="Búsqueda" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="201A-Busqueda-textual" transform="translate(-887.000000, -266.000000)" >
                                    <g id="Group-9" transform="translate(915.500000, 272.000000) rotate(-180.000000) translate(-915.500000, -272.000000) translate(878.000000, 256.000000)">
                                        <g id="Group-13" transform="translate(45.000000, 10.000000)">
                                            <rect id="Rectangle-10" x="0" y="0" width="21" height="2"></rect>
                                            <rect id="Rectangle-10" x="0" y="5" width="21" height="2"></rect>
                                            <rect id="Rectangle-10" x="0" y="10" width="21" height="2"></rect>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    :
                    <></>
                    }
                    <div class={claseSwitchList} title="list" onClick={(e) => props.clickComoList(e)}>
                        <svg width="21px" height="12px" viewBox="0 0 21 13" >
                            <g id="Búsqueda" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="201A-Busqueda-textual" >
                                    <g id="Group-9">
                                        <g id="Group-32" >
                                            <rect id="Rectangle-10" x="8" y="2" width="13" height="2"/>
                                            <rect id="Rectangle-10" x="8" y="9" width="13" height="2"/>
                                            <rect id="Rectangle-7" x="0" y="0" width="6" height="6"/>
                                            <rect id="Rectangle-7" x="0" y="7" width="6" height="6"/>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div class={claseSwitchGrid} title="grid" onClick={(e) => props.clickComoGrid(e)}>
                        <svg width="20px" height="13px" viewBox="0 0 20 13" >
                            <g id="Búsqueda" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="201A-Busqueda-textual" transform="translate(-924.000000, -266.000000)" >
                                    <g id="Group-9" transform="translate(915.500000, 272.000000) rotate(-180.000000) translate(-915.500000, -272.000000) translate(878.000000, 256.000000)">
                                        <g id="Group-31" transform="translate(9.000000, 9.000000)">
                                            <rect id="Rectangle-7" x="0" y="0" width="6" height="6"></rect>
                                            <rect id="Rectangle-7" x="7" y="0" width="6" height="6"></rect>
                                            <rect id="Rectangle-7" x="14" y="0" width="6" height="6"></rect>
                                            <rect id="Rectangle-7" x="0" y="7" width="6" height="6"></rect>
                                            <rect id="Rectangle-7" x="7" y="7" width="6" height="6"></rect>
                                            <rect id="Rectangle-7" x="14" y="7" width="6" height="6"></rect>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
            :
            <div></div>
            }
            {(!props.mostrarEXT) ?
                <div class="order" >
                    <div class="pseudoSelect ordenListado"  onClick={(e) => handleClickOrden(e)}>
                        <img src="./img/order.svg" alt="order" />
                        <span>Ordenar por…</span>
                        <div class="pseudoSelect__list" >
                            {opciones}
                        </div>
                    </div>
                </div>
                :
                <div></div>
            }
            </div>
        </div>                
    );
}

export default EncabezadoListado;
