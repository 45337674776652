import { useState } from "react";
import LogoF from "./LogoF";
import MenuIconos from "./MenuIconos/MenuIconos";

const BandaMoradaZonaMenus = (props) => {
    const [provincia, setProvincia] = useState(props.provincia)
    if (provincia !== props.provincia)
        setProvincia(props.provincia);

    const textoProvincia = (provincia && provincia != "" ? "Provincia de sesión: " : "");


    return (
        <div class="module module__top">
            <LogoF />
            <div style={{textAlign:'right', width:'100%', paddingRight:'30px'}}>
            <span class="fs fs14 fw fw300 whiteColor" style={{fontSize:'9pt', fontWeight: 300}}>
                {textoProvincia}
            </span>
            <span class="fs fs14 fw fw300 whiteColor" onClick={(e) => props.cambiaPagina({newpagina: 'Configuracion'})} style={{fontSize:'9pt', fontWeight: 450, cursor:'pointer'}}>
                {provincia}
            </span>
            </div>
            <MenuIconos elementosMenuIconos = {props.elementosMenuIconos} cambiaPagina = {props.cambiaPagina}/>
        </div>
    )
}

export default BandaMoradaZonaMenus;
