import NovedadCabecera from "./NovedadCabecera";


const NovedadesInicioCabecera = (props) => {
    const listaNovedades = props.lista;

    const novedades = listaNovedades ? (
        listaNovedades.map(fila => <NovedadCabecera item={fila} cambiaPagina={props.cambiaPagina}></NovedadCabecera>)
      ):(
        <div></div>
      );
    

    return (
        <div class="module module__grid" >
            <div class="title" >
                <h2 class="fs fs20 fw fw400 purpleColor" >Novedades</h2>
            </div>
            <div class="grid" >
                {novedades}
            </div>
        </div>
    );
};

  export default NovedadesInicioCabecera;
