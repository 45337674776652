import { rootPath } from "../../../../services/utils";

const BusquedaPreferida = (props) => {

    const vesABuscador = (codigo) =>{
        CambiaPaginaSimple(codigo)
    }

    const CambiaPaginaSimple = (codigoPagina) => {
        props.cambiaPagina({
            newpagina: codigoPagina
        });
    }

    
    const sourceImage = rootPath + "img/lupa" + props.colorLupa + ".svg"
    
    return (
        <li onClick={() => vesABuscador(props.item.CodigoPagina)} style={{cursor:'pointer'}}><img src={sourceImage}/> {props.item.Titulo}</li>
    );
};

export default BusquedaPreferida;
