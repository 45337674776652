import Acordeon from "../../../FichaMUH/Apartados/Composicion/Acordeon";

const Jerarquias = (props) => {
    const nivelSuperior = props.datosJerarquia.NivelSuperior;
    const nivelInferior = props.datosJerarquia.NivelInferior;

    const HTMLNS = (nivelSuperior ? nivelSuperior.map(enf => <li onClick={() => CambiaPaginaPorId('FichaPAT',enf.Id)} 
                                                                style={{cursor:'pointer', color:'#5C9029'}}
                                                                onMouseOver={(e) => e.target.style.fontWeight='bold'}
                                                                onMouseOut={(e) => e.target.style.fontWeight='normal'}
                                                            >{enf.Enfermedad}</li>) : <div></div>);
    const HTMLNI = (nivelInferior ? nivelInferior.map(enf => <li onClick={() => CambiaPaginaPorId('FichaPAT',enf.Id)} 
                                                                style={{cursor:'pointer', color:'#5C9029'}}
                                                                onMouseOver={(e) => e.target.style.fontWeight='bold'}
                                                                onMouseOut={(e) => e.target.style.fontWeight='normal'}
                                                            >{enf.Enfermedad}</li>) : <div></div>);
    const contNS = (    
        <div class="cover cover__text">
            <ul class="list list__normal fw fw400 FlamaBook" >
                {HTMLNS}
            </ul>
        </div>
    );
    const contNI = (    
        <div class="cover cover__text">
            <ul class="list list__normal fw fw400 FlamaBook" >
                {HTMLNI}
            </ul>
        </div>
    );

    const CambiaPaginaPorId = (pagina, id) => {
        props.cambiaPagina({
            newpagina: pagina,
            newid: id,
        });
    }

    return (
        <div class="infoContent" >
            {(nivelSuperior && nivelSuperior.length>0) ?
                    <Acordeon titulo="Nivel superior" contenido={contNS}></Acordeon>
                :
                    <div></div>
            }
            {(nivelInferior && nivelInferior.length>0) ?
                    <Acordeon titulo="Nivel inferior" contenido={contNI}></Acordeon>
                :
                    <div></div>
            }
        </div>
    )
}
export default Jerarquias;
