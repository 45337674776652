import React, { useState, useEffect, createRef } from "react";
import CabeceraResultados from "../../Resultados/CabeceraResultados/CabeceraResultados";
import BP2022Service from "../../../services/user.service";
import InfoPrincipalMUH from "../FichaMUH/InfoPrincipal/InfoPrincipalMUH.js";
import MenuIzquierda from "../FichaMUH/MenuIzquierda/MenuIzquierda";
import ContenedorApartados from "../FichaMUH/Apartados/ContenedorApartados";
import MensajesEmergentes from "../FichaMUH/MensajesEmergentes";
import { AnadeApartado, baseURLPictogramas, compruebaAnclas, RemoveClassNameToClassItems } from "../../../services/utils";
import LoadingPopup from "../../Resultados/Loading.Popup";
import MargenDerechoPA from "../FichaPA/MargenDerechoPA";

const FichaASO = (props) => {
    const id = props.infoPagina.id;
    const tipo = props.tipo;
    const [ficha, setFicha] = useState(null);
    const [anclas, setAnclas] = useState([]);
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        if ((ficha==null) || (ficha.Id !== id))
        {
            descargaFicha();
        }        
        else if (refresh==1){
            descargaAdvertencias(ficha.Id);
        }
        else if (refresh==2){
            descargaDopaje(ficha.Id)
        }
        else if (refresh==3){
            if (tipo==="H")
                descargaInterferencias(ficha.Id);
            else
                setRefresh(prev => prev+1);
        }
        else if (refresh==4){            
            if (tipo==="H")
                descargaFarmacogenetica(ficha.Id)
            else
                setRefresh(prev => prev+1);
        }
        else if (refresh==5){
            descargaHistorico(ficha.Id)
        }
        else if (refresh==6){
            descargaInformacionAdicional(ficha.Id)
        }
        else if (refresh==7){
            if (tipo==="V")
                descargaUsoAnimalesProductores(ficha.Id)
            else
                setRefresh(prev => prev+1);
        }
        else if (refresh==8){
            descargaVideos(ficha.Id)
        }
        else if (refresh == 9)
        {            
            //setRefresh(prev => prev + 1);
        }
    })
    const descargaFicha = () => {
        BP2022Service.getFichaASO(id, props.tipo)
        .then((response) => {
            RemoveClassNameToClassItems('lightbox__popup',null,'active');
            //checkAnclas(response.data.Apartados);
            var auxFicha = response.data;
            compruebaAnclas(auxFicha.Apartados,anclas);
            auxFicha.CodTipoRegistro="ASO" + auxFicha.TipoPA;
            if (auxFicha.CodTipoRegistro === "ASOH")
                auxFicha.TipoRegistro="Asociación de uso humano";
            else
                auxFicha.TipoRegistro="Asociación veterinaria";
            setFicha(auxFicha);
            setRefresh(1);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
    
    const descargaAdvertencias = (miId) => {
        BP2022Service.getAdvertenciasASO (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Advertencias){
                    if (response.data.Advertencias.length>0){
                        AnadeApartado({Titulo: "Advertencias",  Ancla:"ADV"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosAdvertencias = response.data;
                    }
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
   
    const descargaInterferencias = (miId) => {
        BP2022Service.getInterferenciasASO (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Análisis clínicos",  Ancla:"ANAASO"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.Interferencias = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
 
    const descargaFarmacogenetica = (miId) => {
        BP2022Service.getFarmacogeneticaASO (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Farmacogenética",  Ancla:"FGEN"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.FarmacogeneticaComponentes = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };


    const descargaHistorico = (miId) => {
        BP2022Service.getHistoricoASO (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Histórico",  Ancla:"HIST"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.DatosHistorico = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };


    const descargaInformacionAdicional = (miId) => {
        BP2022Service.getInformacionAdicionalASO (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Información adicional",  Ancla:"DOCS"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.DatosInfoAdicional = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaVideos = (miId) => {
        BP2022Service.getVideosASO (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Documentos){
                    if (response.data.Documentos.length>0){
                        AnadeApartado({Titulo: "Vídeos",  Ancla:"VID"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosVideos = response.data;
                    }
                }
                props.descargaHistorial();
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaDopaje = (miId) => {
        BP2022Service.getDopajeInfoASO (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Textos){
                    if (response.data.Textos.length>0){
                        AnadeApartado({Titulo: "Dopaje",  Ancla:"DOP"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosDopaje = response.data;
                    }
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaUsoAnimalesProductores = (miId) => {
        if (tipo==="V"){
            /*BP2022Service.getProductoresInfoASO (miId)
            .then((response) => {
                if (ficha != null){
                    var miFicha = ficha;
                    if (response.data.LMRsEspecies){
                        if (response.data.LMRsEspecies.length>0){
                            AnadeApartado({Titulo: "Uso en animales productores",  Ancla:"UAP"},miFicha.Apartados,anclas);
                            //checkAnclas(miFicha.Apartados);
                            miFicha.DatosAnimalesProductores = response.data;
                        }
                    }
                    setRefresh(prev => prev +1);
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });*/
        }
    };

    

    /*const checkAnclas = (aptdos) => {
        for (var i=0; i<aptdos.length; i++){
            const idAncla = aptdos[i].Ancla;
            if (!anclas.find(ancla => ancla.idAncla===idAncla))
            {
                const anclaNueva = createRef();
                anclas.push({idAncla: idAncla, refAncla : anclaNueva});
            }
        }
    }
    */
     
    if (ficha !== null) 
    {
        return (
            <div>
                <CabeceraResultados infoPagina={props.infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras}></CabeceraResultados>
                <InfoPrincipalMUH ficha={ficha} cambiaPagina={props.cambiaPagina} ></InfoPrincipalMUH>
                {
                    (ficha.MensajesEmergentes && ficha.MensajesEmergentes.length>0) ?
                        <MensajesEmergentes mensajes={ficha.MensajesEmergentes} id={ficha.Id}></MensajesEmergentes>
                    :
                        <div></div>
                }
                <section class="medicamento-infoGeneral">
                    <div class="container flex flex--justify-center">
                        <div class="contenido f-f-Flama-Book">
                            <MenuIzquierda apartados={ficha.Apartados} activo={ficha.Apartados[0].Ancla} anclas={anclas}></MenuIzquierda>

                            <ContenedorApartados cambiaPagina={props.cambiaPagina} ficha={ficha} anclas={anclas}></ContenedorApartados> 
                            <MargenDerechoPA cambiaPagina={props.cambiaPagina} ficha={ficha} ></MargenDerechoPA>

                        </div>
                    </div>
                </section>
            </div>
        )
    }
    else 
        return (
            <div>
                <LoadingPopup></LoadingPopup>
            </div>
        )
}
export default FichaASO;
