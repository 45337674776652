import FilaLotes from "./FilaLotes";


const TablaLotes = (props) => {
    const lotes = props.lotes;
    const HTMLLotes = lotes.map(lote => <FilaLotes lote={lote} ></FilaLotes>);
    return (
        <div class="infoContent" >
            <div class="table" style={{width:'100%'}} >
                <div class="table__row table__row__header table__row__header__noBg fs fs12 fw fw600 greenColor uppercase">
                    <div class="column" >
                        <p>Nº LOTE</p>
                    </div>
                    <div class="column" >
                        <p>FECHA DE CADUCIDAD</p>
                    </div>
                </div>
                {HTMLLotes}
            </div>
        </div>
    )
}
export default TablaLotes;
