import React, { useState, useEffect } from "react";
import FilaResultadoBusqueda from './FilaListaResBusqueda';
import FilaListaFM from './FilaListaFM';
import EncabezadoListado from './EncabezadoListado';
import { AddClassNameToClassItems, getIdCCAA, PerfilUsuario, RemoveClassNameToClassItems, rootPath } from "../../../../services/utils";
import FilaListaEXT from "./FilaListaEXT";
import FilaListaDOC from "./FilaListaDOC";

function ListaResBusqueda(props){
    const [numMostrar, setNumMostrar] = useState((props.filtrosYlista.numMostrar) ? parseInt(props.filtrosYlista.numMostrar) : 10);
    const idxOrden = (props.filtrosYlista.idxOrden) ? props.filtrosYlista.idxOrden : 0;
    const [estadoVerComoGrid, setEstadoVerComoGrid] = useState(JSON.parse(localStorage.getItem("comoGrid")));
    const [estadoVerListaPack, setEstadoVerListaPack] = useState(JSON.parse(localStorage.getItem("listaPack")));
    const listaitems = props.filtrosYlista.listaFiltrada;
    const numTotal = props.filtrosYlista.numTotal;
    const numDescargados = props.filtrosYlista.numDescargados;
    const filtros = props.filtrosYlista.filtros;
    const numDefectoListaPack = 50;
    const numDefectoLista = 10;
    const numDefectoGrid = 9;
    const numMedio = 5;
    const ancho = window.innerWidth;
    const numColsGrid = (ancho>=944 ? 3 : ancho>=768 ? 2 : 1);
    const numMostrados = Math.min(numMostrar,listaitems.length);
    const numUltimaFila = numMostrados % numColsGrid;
    const unoOculto = (estadoVerComoGrid && numUltimaFila>1);

    useEffect(() => {
        var divisor = (estadoVerComoGrid ? numDefectoGrid : estadoVerListaPack ? numDefectoListaPack : numDefectoLista);
        var resto = numMostrar % divisor;
        if (resto > 0 ){
            if (resto < numMedio)
                setNumMostrar(numMostrar - resto);
            else
                setNumMostrar(numMostrar + (divisor - resto));
        }
    })

    const vistaComoGrid = () =>{
        AddClassNameToClassItems('switch__grid',null,'active');
        RemoveClassNameToClassItems('switch__list',null,'active');
        RemoveClassNameToClassItems('switch__packlist',null,'active');
        AddClassNameToClassItems('content__results',null,'grid');
    }
    
    const vistaComoList = () =>{
        AddClassNameToClassItems('switch__list',null,'active');
        RemoveClassNameToClassItems('switch__grid',null,'active');
        RemoveClassNameToClassItems('switch__packlist',null,'active');
        RemoveClassNameToClassItems('content__results',null,'grid');
    }

    const vistaComoPackList = () =>{
        AddClassNameToClassItems('switch__packlist',null,'active');
        RemoveClassNameToClassItems('switch__list',null,'active');
        RemoveClassNameToClassItems('switch__grid',null,'active');
        RemoveClassNameToClassItems('content__results',null,'grid');
    }

    const handleClickGrid = (e) =>{
        vistaComoGrid();
        localStorage.setItem("comoGrid", true);
        setEstadoVerComoGrid(true);
    }
    
    const handleClickList = (e) =>{
        vistaComoList();
        localStorage.setItem("comoGrid", false);
        localStorage.setItem("listaPack", false);
        setEstadoVerComoGrid(false);
        setEstadoVerListaPack(false);
    }

    const handleClickPackList = (e) =>{
        vistaComoPackList();
        localStorage.setItem("comoGrid", false);
        localStorage.setItem("listaPack", true);
        setEstadoVerComoGrid(false);
        setEstadoVerListaPack(true);
    }

    const claseContentResults = (estadoVerComoGrid ?  "content__results grid" : "content__results");

    var visitados = JSON.parse(localStorage.getItem("visitados"));
    if (!visitados)
        visitados = [];

    var idCCAA = getIdCCAA();
    const literalPVP = (((idCCAA === 5 || idCCAA === 18 || idCCAA === 19) && props.codigoPagina !== "BAgrupHomoMUH") ? 'PVP sin IVA': 'PVP con IVA');

    const isMobile = (ancho<768);    

    const muestraTabla = !isMobile && !estadoVerComoGrid && estadoVerListaPack && !props.mostrarFM && !props.mostrarEXT && !props.mostrarDocumentos;

    const borraDeLista = (tipo, id) => {
        props.borraDeLista(tipo,id);
    }

    var rol = PerfilUsuario();

       
    const muestraMas = () => {
        var incremento = (estadoVerComoGrid ? numDefectoGrid : estadoVerListaPack ? numDefectoListaPack : numDefectoLista);
        var newLength = numMostrar + incremento;

        if (props.mostrarFM){
            filas = listaitems.slice(0,newLength).map(fila => <FilaListaFM  item={fila} 
                                                                            cambiaPagina={props.cambiaPagina}
                                                                            filtros={filtros} 
                                                                            numMostrar = {numMostrar}
                                                                            idxOrden = {idxOrden}
                                                                            comoGrid={estadoVerComoGrid}
                                                                            comoPackList = {muestraTabla}
                                                                            visitado = {visitados.findIndex(item=> item.id===fila.Id && item.tipo===fila.CodTipoRegistro)>=0}
                                                            ></FilaListaFM>)
        }else if (props.mostrarEXT){
            filas = listaitems.slice(0,newLength).map(fila => <FilaListaEXT  item={fila} 
                                                                            cambiaPagina={props.cambiaPagina}
                                                                            filtros={filtros} 
                                                                            numMostrar = {numMostrar}
                                                                            idxOrden = {idxOrden}
                                                                            comoGrid={estadoVerComoGrid}
                                                                            comoPackList = {muestraTabla}
                                                                            visitado = {visitados.findIndex(item=> item.id===fila.Id && item.tipo===fila.CodTipoRegistro)>=0}
                                                            ></FilaListaEXT>)
        }else if (props.mostrarDocumentos){
            filas = listaitems.slice(0,newLength).map(fila => <FilaListaDOC  item={fila} 
                                                                            filtros={filtros} 
                                                                            numMostrar = {numMostrar}
                                                                            idxOrden = {idxOrden}
                                                                            comoGrid={estadoVerComoGrid}
                                                                            comoPackList = {muestraTabla}
                                                                            visitado = {visitados.findIndex(item=> item.id===fila.Id && item.tipo===fila.CodTipoRegistro)>=0}
                                                            ></FilaListaDOC>)
        }else{
            filas = listaitems.slice(0,newLength).map(fila => <FilaResultadoBusqueda  item={fila} 
                                                                                    cambiaPagina={props.cambiaPagina}
                                                                                    filtros={filtros} 
                                                                                    numMostrar = {numMostrar}
                                                                                    idxOrden = {idxOrden}
                                                                                    comoGrid={estadoVerComoGrid}
                                                                                    comoPackList = {muestraTabla}
                                                                                    mostrarPI = {props.mostrarPI}
                                                                                    mostrarAuxStr = {props.mostrarAuxStr}
                                                                                    mostrarListaAuxStr = {props.mostrarListaAuxStr}
                                                                                    mostrarAuxFecha = {props.mostrarAuxFecha}
                                                                                    mostrarFechaAlta = {props.mostrarFechaAlta}
                                                                                    mostrarFuturo = {props.mostrarFuturo}
                                                                                    mostrarFVisita = {props.mostrarFVisita}
                                                                                    mostrarBotonBorrar = {props.mostrarBotonBorrar}                                                                                    mostrarAvisoPrecio = {props.mostrarAvisoPrecio}
                                                                                    mostrarFechaEntrada = {props.mostrarFechaEntrada}
                                                                                    pvpMenor   = {props.pvpMenor}
                                                                                    pvpMasBajo = {props.pvpMasBajo}
                                                                                    literalPVP = {literalPVP}
                                                                                    visitado = {visitados.findIndex(item=> item.id===fila.Id && item.tipo===fila.CodTipoRegistro)>=0}
                                                                                    primero = {(listaitems.indexOf(fila)===0)}
                                                                                    alBorrar = {borraDeLista}
                                                            ></FilaResultadoBusqueda>)
        }                                                            
        setNumMostrar(newLength);
    };

    var filas;
    if (listaitems != null)
    {
        if (props.mostrarFM){
            filas = listaitems.slice(0,numMostrar).map(fila => <FilaListaFM  item={fila} 
                                                                                    cambiaPagina={props.cambiaPagina}
                                                                                    filtros={filtros} 
                                                                                    numMostrar = {numMostrar}
                                                                                    idxOrden = {idxOrden}
                                                                                    comoGrid={estadoVerComoGrid}
                                                                                    comoPackList = {muestraTabla}
                                                                                    visitado = {visitados.findIndex(item=> item.id===fila.Id && item.tipo===fila.CodTipoRegistro)>=0}
                                                                ></FilaListaFM>)
        }else if (props.mostrarEXT){
            filas = listaitems.slice(0,numMostrar).map(fila => <FilaListaEXT  item={fila} 
                                                                                    cambiaPagina={props.cambiaPagina}
                                                                                    filtros={filtros} 
                                                                                    numMostrar = {numMostrar}
                                                                                    idxOrden = {idxOrden}
                                                                                    comoGrid={estadoVerComoGrid}
                                                                                    comoPackList = {muestraTabla}
                                                                                    visitado = {visitados.findIndex(item=> item.id===fila.Id && item.tipo===fila.CodTipoRegistro)>=0}
                                                                ></FilaListaEXT>)
        }else if (props.mostrarDocumentos){
            filas = listaitems.slice(0,numMostrar).map(fila => <FilaListaDOC  item={fila} 
                                                                            filtros={filtros} 
                                                                            numMostrar = {numMostrar}
                                                                            idxOrden = {idxOrden}
                                                                            comoGrid={estadoVerComoGrid}
                                                                            comoPackList = {muestraTabla}
                                                                            visitado = {visitados.findIndex(item=> item.id===fila.Id && item.tipo===fila.CodTipoRegistro)>=0}
                                                            ></FilaListaDOC>)
        }else{
            filas = listaitems.slice(0,numMostrar).map(fila => <FilaResultadoBusqueda  item={fila} 
                                                                                    cambiaPagina={props.cambiaPagina}
                                                                                    filtros={filtros} 
                                                                                    numMostrar = {numMostrar}
                                                                                    idxOrden = {idxOrden}
                                                                                    comoGrid={estadoVerComoGrid}
                                                                                    comoPackList = {muestraTabla}
                                                                                    mostrarPI = {props.mostrarPI}
                                                                                    mostrarAuxStr = {props.mostrarAuxStr}
                                                                                    mostrarListaAuxStr = {props.mostrarListaAuxStr}
                                                                                    mostrarHist = {props.mostrarHist}
                                                                                    mostrarAuxFecha = {props.mostrarAuxFecha}
                                                                                    mostrarFechaAlta = {props.mostrarFechaAlta}
                                                                                    mostrarFuturo = {props.mostrarFuturo}
                                                                                    mostrarFVisita = {props.mostrarFVisita}
                                                                                    mostrarBotonBorrar = {props.mostrarBotonBorrar} 
                                                                                    mostrarAvisoPrecio = {props.mostrarAvisoPrecio}
                                                                                    mostrarFechaEntrada = {props.mostrarFechaEntrada}
                                                                                    pvpMenor   = {props.pvpMenor}
                                                                                    pvpMasBajo = {props.pvpMasBajo}
                                                                                    literalPVP = {literalPVP}
                                                                                    visitado = {visitados.findIndex(item=> item.id===fila.Id && item.tipo===fila.CodTipoRegistro)>=0}
                                                                                    primero = {(listaitems.indexOf(fila)===0)}
                                                                                    alBorrar = {borraDeLista}
                                                                ></FilaResultadoBusqueda>)
        }                                                        
        var nTotal = listaitems.length;
        var bMostrarMas = (nTotal>numMostrar);
        

        return (
            <div>
                <EncabezadoListado  listaitems={listaitems} cambioOrden = {props.setOrden} currentIndex = {idxOrden} 
                                    clickComoGrid = {handleClickGrid} 
                                    clickComoList = {handleClickList} 
                                    clickComoPackList = {handleClickPackList} 
                                    comoGrid = {estadoVerComoGrid}
                                    listaPack = {estadoVerListaPack}
                                    numTotal = {numTotal}
                                    numDescargados = {numDescargados}
                                    mostrarFM = {props.mostrarFM}
                                    mostrarEXT = {props.mostrarEXT}    
                                    mostrarFVisita = {props.mostrarFVisita}
                                    mostrarDocumentos = {props.mostrarDocumentos} 
                                    mostrarLimpiaHistorial = {props.mostrarLimpiaHistorial} 
                                    alExportar = {props.alExportar}                              
                                    alVaciar = {props.alVaciar}                              
                                    >
                </EncabezadoListado>  
                {(!muestraTabla) ?             
                    <div class={claseContentResults} >
                        {(!props.mostrarEXT) && (!props.mostrarDocumentos) ?
                        <div class="content__results__header" >
                            {(rol !== "EST") ?
                                <div class="precio" >
                                    <p class="fs fs12 greyColor17" >{literalPVP}</p>
                                    {(props.mostrarFuturo) ?
                                    <p class="fs fs12 greyColor17" style={{color:'#d9710a', fontWeight: '400', fontSize: '12px', fontFamily: 'Flama, sans-serif'}}>nuevo</p>
                                    :
                                    <span></span>
                                    }
                                </div>
                            :
                                <></>
                            }
                            <div class="estado" >
                                {(!props.mostrarFM) && (!props.mostrarEXT) ?
                                    <p class="fs fs12 greyColor17" >Estado / Laboratorio</p>
                                :
                                    <p class="fs fs12 greyColor17" >Dispensación/Financiación</p>
                                }
                                
                            </div> 
                        </div>
                        :
                            <div></div>
                        }
                        <div class="content__results__items" sytle={{justifyContent: 'flex-start'}}> 
                            {filas}
                            {
                                (unoOculto)?
                                    <FilaResultadoBusqueda  item={listaitems[0]} 
                                                        cambiaPagina={props.cambiaPagina}
                                                        filtros={filtros} 
                                                        numMostrar = {numMostrar}
                                                        idxOrden = {idxOrden}
                                                        comoGrid={estadoVerComoGrid}
                                                        mostrarPI = {props.mostrarPI}
                                                        mostrarAuxStr = {props.mostrarAuxStr}
                                                        mostrarListaAuxStr = {props.mostrarListaAuxStr}
                                                        mostrarHist = {props.mostrarHist}
                                                        mostrarAuxFecha = {props.mostrarAuxFecha}
                                                        mostrarFechaAlta = {props.mostrarFechaAlta}
                                                        mostrarFuturo = {props.mostrarFuturo}
                                                        mostrarFVisita = {props.mostrarFVisita}
                                                        mostrarBotonBorrar = {props.mostrarBotonBorrar}
                                                        mostrarAvisoPrecio = {props.mostrarAvisoPrecio}
                                                        pvpMenor   = {props.pvpMenor}
                                                        pvpMasBajo = {props.pvpMasBajo}
                                                        literalPVP = {literalPVP}
                                                        oculto = {true}
                                    ></FilaResultadoBusqueda>
                                :
                                <></>
                            }
                        </div>
                        <div class="showing" >
                            <p class="text" >
                                <span class="number number__show" >{filas.length}</span> resultados de <span class="number number__total" >{nTotal}</span>
                            </p>
                            <div class="module module__buttons">
                                {bMostrarMas ?(
                                    <span class="button" title="Ver más"  onClick={() => muestraMas()} style={{cursor:'pointer'}}>
                                        Ver más <img src={rootPath + "img/arrowPurpleDown2.svg"} alt="arrowPurpleDown2" />
                                    </span>
                                ):(
                                    <div></div>
                                )}                            
                            </div>
                        </div>
                    </div>
                :
                    <div class={claseContentResults} >
                        <div style={{display:'table', width:'100%', borderSpacing:'0px'}}>
                            <div class="content__results__header" style={{display:'table-row'}}>
                                <div style={{display: 'table-cell', textAlign: 'center', width:'15px', minWidth:'15px'}}>
                                    <p class="fs fs12 greyColor17" style={{borderBottom: '1px solid #DCDBDB', paddingTop: '12px', paddingBottom: '12px', maginBottom: '20px'}}>&nbsp;</p>
                                </div>
                                <div style={{display: 'table-cell', textAlign: 'center', width:'15px', minWidth:'15px'}}>
                                    <p class="fs fs12 greyColor17" style={{borderBottom: '1px solid #DCDBDB', paddingTop: '12px', paddingBottom: '12px', maginBottom: '20px'}}>&nbsp;</p>
                                </div>
                                <div style={{display: 'table-cell', textAlign: 'center', width:'70px', minWidth:'70px'}}>
                                    <p class="fs fs12 greyColor17" style={{borderBottom: '1px solid #DCDBDB', paddingTop: '12px', paddingBottom: '12px', maginBottom: '20px'}}>Código</p>
                                </div>
                                <div style={{display: 'table-cell', textAlign: 'left', width:'50%'}}>
                                    <p class="fs fs12 greyColor17" style={{borderBottom: '1px solid #DCDBDB', paddingTop: '12px', paddingBottom: '12px', maginBottom: '20px'}} >Nombre</p>
                                </div>
                                {(rol !== "EST") ?
                                    <div style={{display: 'table-cell', textAlign: 'right', width:'80px', minWidth:'80px'}}>
                                        <p class="fs fs12 greyColor17" style={{borderBottom: '1px solid #DCDBDB', paddingTop: '12px', paddingBottom: '12px', maginBottom: '20px'}} >{literalPVP}</p>
                                    </div>
                                :
                                    <></>
                                }
                                <div style={{display: 'table-cell', textAlign: 'center', width:'140px', minWidth:'140px'}}>
                                    <p class="fs fs12 greyColor17" style={{borderBottom: '1px solid #DCDBDB', paddingTop: '12px', paddingBottom: '12px', maginBottom: '20px'}} >Laboratorio</p>
                                </div>
                                <div style={{display: 'table-cell', textAlign: 'center', width:'95px', minWidth:'95px'}}>
                                    <p class="fs fs12 greyColor17" style={{borderBottom: '1px solid #DCDBDB', paddingTop: '12px', paddingBottom: '12px', maginBottom: '20px'}} >Estado</p>
                                </div>
                            </div>
                            {filas}
                            <div class="content__results__header" style={{display:'table-row'}}>
                                <div style={{display: 'table-cell', backgroundColor:'white'}}>&nbsp;</div>
                            </div>
                        </div>
                        <div class="showing" >
                            <p class="text" >
                                <span class="number number__show" >{filas.length}</span> resultados de <span class="number number__total" >{nTotal}</span>
                            </p>
                            <div class="module module__buttons">
                                {bMostrarMas ?(
                                    <span class="button" title="Ver más"  onClick={() => muestraMas()} style={{cursor:'pointer'}}>
                                        Ver más <img src={rootPath + "img/arrowPurpleDown2.svg"} alt="arrowPurpleDown2" />
                                    </span>
                                ):(
                                    <div></div>
                                )}                            
                            </div>
                        </div>
                    </div>
                }
            </div> 
        )
    }    
    else{
        return(
            <div></div>
        )
    }
}

export default ListaResBusqueda;

