function MedsSeleccionados(props){

    const itemsHtml = props.itemsSelected.map(item => 
        <span className="tag">
            {item.Valor}
            <img class="arrow" src="./img/close-tag.svg" alt="eliminar" onClick={e => props.alBorrar(item.Codigo)} />
        </span>);

    const texto = (props.nFiltrados > 0 ? props.nFiltrados.toString() + " interacciones encontradas" : "");

    return (
        <div>
            <div class="module module__buttons">
                <div class="cover cover__tags tags" id="tags-2">
                    {itemsHtml}
                </div>
                <div class="cover cover__button" >
                    <span class="button button__arrow button__green noUppercase reset-form" data-form="#main-filter" title="Vaciar búsqueda" onClick={props.alLimpiar}>
                        Vaciar búsqueda
                    </span>
                </div>
            </div>
            {(texto !==  "") ?
                    <div class="info__items" style={{paddingTop:'10px', paddingBottom: '10px'}}>
                        <p class="fs fs15 fw fw400 blackColor results" >{texto}</p>
                    </div>
                :
                <div></div>
            }
        </div>
    )
}    
export default MedsSeleccionados;
