import { useEffect, useState } from "react";
import LoadingPopup from "../../Resultados/Loading.Popup";
import BP2022Service from "../../../services/user.service";
import SelectorUno from "../../Utilidades/Selector/SelectorUno";
import ResumenBusqueda from "../ResumenBusqueda";
import SelectorFechas from "../../Utilidades/Selector/SelectorFecha/SelectorFecha";
import { FormatoFechaDDMMYYYY, GetAntecesorWhithClassName } from "../../../services/utils";
import SelectorUno2 from "../../Utilidades/Selector/SelectorUno2";
import SelectorMulti2 from "../../Utilidades/Selector/SelectorMulti2";

const FBExtranjeros= (props) => {
    const [datosFormulario, setDatosFormulario] = useState({
        listaPaises: null,
        listaPA: null,
    });
    const [seleccionFormulario, setSeleccionFormulario] = useState({
        txt: props.txt,
        idPais: props.idPais,
        solo: (props.solo ? props.solo : true),
        pas: (props.pas ? props.pas : []),
    })

    useEffect(() => {
        if (datosFormulario.listaPA === null)
        {
            DescargaDatos();
        }        
    },[]);

    const DescargaDatos = () => {      
        BP2022Service.datosFormularioEXT()
        .then((response) => {
            var df = {
                listaPA : response.data.PrincipiosActivos,
                listaPaises : response.data.Paises,  
            };
            df.listaPaises.splice(0,0,{CodPais:0, Nombre:'Indique un país'});
            df.listaPA.splice(0,0,{Codigo:0, Valor:'Indique un principio activo'});
            setDatosFormulario(df);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };


    const construyeResumenBusqueda = () => {
        var res = [];
        if (seleccionFormulario.txt && seleccionFormulario.txt != ""){
            res.push ({titulo: 'Nombre contiene', valor: seleccionFormulario.txt});
        }

        if (seleccionFormulario.idPais>0){
            if (datosFormulario.listaPaises){
                var item = datosFormulario.listaPaises.find(x => x.CodPais === seleccionFormulario.idPais);
                if (item)
                    res.push ({titulo: "País", valor: item.Nombre});
            }
        }

        if ((seleccionFormulario.pas) && (seleccionFormulario.pas.length>0)){
            if (datosFormulario.listaPA){
                var valor = '';
                for (var i =0; i<seleccionFormulario.pas.length; i++){
                    var item = datosFormulario.listaPA.find(x => x.Codigo === seleccionFormulario.pas[i]);
                    if (item){
                        if (valor !== '')
                            valor += ", ";
                        valor += item.Valor;
                    }
                }
                if (valor != '')
                    res.push ({titulo: "Composición", valor: valor});
            }
        }

        
        return res;
    }

    const resumen = construyeResumenBusqueda(seleccionFormulario);

    const hayBusqueda = seleccionFormulario.idPais>0 || seleccionFormulario.txt != "" || (seleccionFormulario.pas && seleccionFormulario.pas.length > 0);

    const clickBuscar = (e) =>{
        if (hayBusqueda)
        props.cambiaPagina({
            newpagina: 'BExtranjerosEXT',
            txt: seleccionFormulario.txt,
            idPais: seleccionFormulario.idPais,
            pas: seleccionFormulario.pas,
            solo: seleccionFormulario.solo,
        })
    }

    const BorraBusqueda = (e) =>{
        const formularioVacio={
            txt: '',
            idPais: 0,
            pas: [],
            solo: true,
        }
        setSeleccionFormulario(formularioVacio);
        var inputs = document.getElementsByClassName('inputSelector');
        if (inputs){
            for (var i=0; i<inputs.length; i++){
                inputs[i].value = "";
                const itemContainer = GetAntecesorWhithClassName(inputs[i], 'custom-select-container');
                if (itemContainer){
                    itemContainer.classList.remove('active');
                }
            }
        }
    };

    const CambioPais = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idPais = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const BorraPais = () =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idPais = 0;
        setSeleccionFormulario(nuevaSeleccion);
    }
    const CambiaTxt = (e) =>{
        const item = e.target;
        if (item){
            const valor = item.value;
            const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
            nuevaSeleccion.txt = valor;
            setSeleccionFormulario(nuevaSeleccion);
        }
    }

    const AddPA = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        if (!nuevaSeleccion.pas) { nuevaSeleccion.pas = []};
        nuevaSeleccion.pas.push(nuevovalor);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemovePA = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        var i = nuevaSeleccion.pas.indexOf(parseInt(valor));
        nuevaSeleccion.pas.splice(i,1);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambiaSolo= (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.solo = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }
    
    const KeyDownForm = (e) =>{
        var keyCode = e.keyCode;
        if (keyCode === 13){
            clickBuscar(e);
        }
    }


    const estiloBotonesSup = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'space-between'} : {height:'76px', width:'100%', justifyContent: 'space-between', visibility:'hidden'})
    const estiloBotonesInf = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'center'} : {height:'76px', width:'100%', justifyContent: 'center', display:'none'})


    const listaCodValorPais = (datosFormulario.listaPaises) ? datosFormulario.listaPaises.map(it => ({codigo: it.CodPais, valor: it.Nombre})) : [];
    const listaCodValorPA   = (datosFormulario.listaPA)     ? datosFormulario.listaPA.map(it =>     ({codigo: it.Codigo, valor: it.Valor})) : [];
    
    const clasedivmodulefilters = "cover cover__filters cover__filters__2 cover__filters__2--1 cover__filters__2--1__50 " ;

    return (
        <div class="content" >

            { (datosFormulario.listaPA === null) ?
                <LoadingPopup titulo="Un momento" texto="Preparando formulario" esPorcentaje={true}></LoadingPopup>
            :
                <form class="content__results content__results__filters " id="main-filter"  onSubmit={(e) => e.preventDefault()}  onKeyDown={(e) => KeyDownForm(e)} tabIndex="0">
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesSup}>
                        <span class="button button__arrow button__green noUppercase reset-form" data-form="#main-filter" title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} style={{visibility:'hidden'}} >
                            Vaciar búsqueda
                        </span>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                        <span class="button button__arrow button__green reset-form" data-form="#main-filter" style={{textTransform:'none', fontSize: '14px', width:'146px'}} title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} >
                            Vaciar búsqueda
                        </span>
                    </div>

                    <div class="cover cover__filters cover__filters__2 cover__filters__2--1"  >
                        <div class="coverModule">
                            <h3 class="title fs fs16 greyColor2">Nombre</h3>
                            <div class="module module__filters">
                                <div class="box box__inputs">
                                    <div class="cover cover__pad10">
                                        <input type="text" name="" value={seleccionFormulario.txt} placeholder="Escribe nombre medicamento"   onChange={(e) => CambiaTxt(e)}/ >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className = "coverModule">
                            <h3 class="title fs fs16 greyColor2">País:</h3>
                                    
                                    <SelectorUno2 lista = {listaCodValorPais} 
                                                idHTML="FBExtPais" 
                                                valueSelected={seleccionFormulario.idPais}
                                                alCambiar={CambioPais} 
                                                alBorrar={BorraPais} 
                                                ancho="100%"
                                    ></SelectorUno2>
                        </div>
                    </div>

                    <div class={clasedivmodulefilters} >
                        <div className = "coverModule">
                            <h3 class="title fs fs16 greyColor2" >Principios activos</h3>
                            <SelectorMulti2 
                                        lista = {listaCodValorPA} 
                                        idHTML="FBExtPA" 
                                        valuesSelected={seleccionFormulario.pas}
                                        alAnadir={AddPA} 
                                        alBorrar={RemovePA} 
                                        ancho="100%"
                                        tipoFiltradoLista={1}
                            ></SelectorMulti2>
                            {((seleccionFormulario.solo)?
                        
                                <div class="cover cover__inputs cover__inputs__radios" style={{marginTop:'10px'}}>
                                    <label style={{marginBottom:'10px'}}><input type="radio" name="opcionCompos" value="solo" checked onClick={(e) => CambiaSolo(true)}/>Únicamente los principios activos seleccionados</label>
                                    <label style={{marginBottom:'10px'}}><input type="radio" name="opcionCompos" value="aso"          onClick={(e) => CambiaSolo(false)}/>Puede contener principios activos adicionales</label>
                                </div>
                            :
                                <div class="cover cover__inputs cover__inputs__radios" style={{marginTop:'10px'}}>
                                    <label style={{marginBottom:'10px'}}><input type="radio" name="opcionCompos" value="solo"        onClick={(e) => CambiaSolo(true)}/>Únicamente los principios activos seleccionados</label>
                                    <label style={{marginBottom:'10px'}}><input type="radio" name="opcionCompos" value="aso" checked onClick={(e) => CambiaSolo(false)}/>Puede contener principios activos adicionales</label>
                                </div>
                            )}   
                        </div>
                    </div>
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesInf}>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                    </div>
                    <ResumenBusqueda condiciones={resumen}></ResumenBusqueda>
                </form>
            }
        </div>
    );
};

export default FBExtranjeros;
