import { RemoveClassNameToClassItems } from "../../services/utils";
import Emergente from "../Fichas/FichaMUH/Apartados/Cismed/Emergente";

const AccesoRestringido = (props) => {
    const modalTitulo = "Acceso restringido";
    const modalKeyName = "AccessNotAllowed"
    const modalContent = (
        <div>
                <p>Para acceder a la funcionalidad solicitada debe estár suscrito a <strong>BOT PLUS</strong>.
                </p>
                <p>Puede encontrar más información acerca de BOT PLUS y de como suscribirse <a href="https://www.farmaceuticos.com/botplus/" target="_blank"><span style={{color:'#93C462'}}>aquí</span></a>
                </p>
                <div class="content__results content__results__filters">
                    <div class="module module__buttons module__buttons__buscar" style={{height:'76px'}}>
                        <button class="button button__arrow button__purple noUppercase" title="Atras"  onClick={(e) => cerrar()} >Cerrar</button>
                    </div>
                </div>
        </div>
    );
    const modalContentEST = (
        <div>
                <p>Este usuario de pruebas está destinado exclusivamente al aprendizaje del manejo de
                    BOT PLUS por parte de alumnos de facultades de Farmacia cuyas Universidades
                    estén adscritas a la Conferencia de Decanos y que hayan suscrito el correspondiente
                    convenio de colaboración con el Consejo General de Colegios Oficiales de
                    Farmacéuticos. Esta funcionalidad no está disponible para este usuario de pruebas.
                </p>
                <div class="content__results content__results__filters">
                    <div class="module module__buttons module__buttons__buscar" style={{height:'76px'}}>
                        <button class="button button__arrow button__purple noUppercase" title="Atras"  onClick={(e) => cerrar()} >Cerrar</button>
                    </div>
                </div>
        </div>
    );

    var miModalContent = modalContent;
    var alto = '260px'
    if (props.rol === "EST"){
        miModalContent = modalContentEST;
        alto='320px'
    }

    const cerrar = () =>{
        if (!props.atras){
            RemoveClassNameToClassItems(modalKeyName, null, 'active');
        }else{
            props.atras();
        }

    }

    return (
        <Emergente contenido={miModalContent} keyName={modalKeyName} titulo={modalTitulo} activo={true} ocultaAspa={true} alto={alto}></Emergente>
    );
};

export default AccesoRestringido;