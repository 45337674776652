import { FormatoFechaDDMMYYYY } from "../../../../../services/utils";
import BloqueNombreValor from "../InfoGeneral/BloqueNombreValor";

const ProblemaS = (props) => {
    const problema = props.problema;
    const bloqueProblema = [
        {
            nombre : 'Estado del problema de suministro según la información de la AEMPS',
            valor: problema.Estado,
        },
        {
            nombre : 'Fecha inicio',
            valor: ((problema.FechaInicio) ? FormatoFechaDDMMYYYY(problema.FechaInicio) : ''),
        },
        {
            nombre : 'Fecha finalización prevista',
            valor: ((problema.FechaFin) ? FormatoFechaDDMMYYYY(problema.FechaFin) : ''),
        },
        {
            nombre : 'Observaciones de la AEMPS',
            valor: problema.Observaciones,
        },
    ]

    return (
        <div>
            <div class="module module__infoColumns module__infoColumns__noBorder" > 
                <BloqueNombreValor bloque={bloqueProblema} 
                                   claseBloque={"columns columns__2"} 
                                   claseFila={"row fs fs14 FlamaBook"}
                                   claseCol={"column"}
                                   filasNumericas ={[1,2]}
                 ></BloqueNombreValor>
            </div>
        </div>
    )
}
export default ProblemaS;
