import ApartadoSimpleSeccionMenuBlanco from "./ApartadoSimpleSeccionMenuBlanco.js";
import ApartadoMultipleSeccionMenuBlanco from "./ApartadoMultipleSeccionMenuBlanco.js";


const ApartadoSeccionMenuBlanco = (props) => {
    const tieneSubapartados = (props.item.subapartados !== null ? true : false)

    if (tieneSubapartados)
        return (
            <ApartadoMultipleSeccionMenuBlanco cambiaPagina={props.cambiaPagina} item={props.item} perfil = {props.perfil}></ApartadoMultipleSeccionMenuBlanco>
        )
    else
        return (
            <ApartadoSimpleSeccionMenuBlanco cambiaPagina={props.cambiaPagina} item={props.item} perfil = {props.perfil}></ApartadoSimpleSeccionMenuBlanco>
        )    
}
export default ApartadoSeccionMenuBlanco;
