import { useState } from "react";
import { RemoveClassNameToClassItems } from "../../../../../services/utils";

const Emergente = (props) => {
    //const [activo, setActivo] = useState(props.activo);
    const contenido = props.contenido;
    const keyName = props.keyName;
    const titulo = props.titulo;
    const ancho = props.ancho;
    const alto = props.alto;
    const imagen = props.imagen;
    var claseLightbox = "lightbox lightbox__modal lightbox__config " + keyName;
    if (props.activo)
        claseLightbox += " active";

            
    const CierraEmergente = () => {
        if (props.cerrar)
            props.cerrar();
        else
            RemoveClassNameToClassItems(keyName, null, 'active');
    }
    
/*
    const CierraEmergente = () => {
        setActivo(false);
    }
*/


    const estiloSection = {
        opacity: 0,
        visibility: 'hidden', 
        pointerEvents: 'none',
    }

    var estiloancho = (ancho ?
        (alto) ?
            {   
                width: ancho,
                height: alto
            }
        :
            {
                width: ancho,
            }
    :
        (alto) ?
            {height: alto}
        :
            {}
    )

    if (props.hiddenOverflow) 
            estiloancho.overflow = 'hidden';

    return (
        <section class={claseLightbox} style={estiloSection} > 
            <div class="lightbox__content whiteColorBg" style={estiloancho}>
                <h2 class="lightbox__content__title fs fs20 FlamaBook uppercase whiteColorBg">
                    {titulo}
                    {(props.ocultaAspa) ?
                    <span></span>
                    :
                    <span class="lightbox__close" onClick={(e) => CierraEmergente()}></span>
                    }
                </h2>
                <div class="fs fs14 FlamaBook blackColor">
                    {
                        (imagen && imagen != '') ?
                            <div class="box box__icon" style={{textAlign: 'center'}}>
                                <div class="mask" >
                                    <center>
                                    <img src={imagen} style={{width:'35px'}} />
                                    </center>      
                                    &nbsp; <br></br>
                                </div>
                            </div>                              
                        :
                            <div></div>
                    }
                    {contenido}
                </div>
            </div>
        </section>
        )
}
export default Emergente;