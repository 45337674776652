import { numberWithCommas } from "../../../../../services/utils";
import Acordeon from "../Composicion/Acordeon";

const CambioHistorico = (props) => {
    const cambio = props.cambio;
    const titulo = cambio.Tipo;
    const esCambioPrecio = (cambio.PreAnterior && cambio.PreAnterior>0);
    const esCambioNombre = (cambio.NomAnterior && cambio.NomAnterior !== '');
    const esCambioLab = (cambio.LabAnterior && cambio.LabAnterior !== '');
    const tieneObservaciones = (cambio.Observaciones && cambio.Observaciones !== '');

    const contenido = (
        <div>
            {(esCambioPrecio) ?
                (props.esECoH) ?
                    <p class="fs fs16 FlamaBook blackColor" >PVL anterior: {numberWithCommas(cambio.PreAnterior)} €</p>
                :
                    <p class="fs fs16 FlamaBook blackColor" >PVPiva anterior: {numberWithCommas(cambio.PreAnterior)} €</p>
            : (esCambioNombre) ?
                <p class="fs fs16 FlamaBook blackColor" >Nombre anterior: {cambio.NomAnterior}</p>
            : (esCambioLab) ?
                <p class="fs fs16 FlamaBook blackColor" >Laboratorio anterior: {cambio.LabAnterior}</p>
            : 
                <span></span>
            }
            {(tieneObservaciones) ?
                <p class="fs fs16 FlamaBook blackColor" style={{whiteSpace: 'pre-line'}} >{cambio.Observaciones}</p>
            :
                <span></span>
            }
        </div>
    );
    return (
        <Acordeon titulo = {titulo} contenido={contenido} activo={props.abierto} tipo={2}></Acordeon>
    )
}
export default CambioHistorico;