import LoadingPopup from "../../Resultados/Loading.Popup";
import BP2022Service from "../../../services/user.service";
import { useEffect, useState } from "react";
import ResumenBusqueda from "../ResumenBusqueda";
import SelectorComposicion from "../../Utilidades/Selector/SelectorComposicion/SelectorComposicion";
import SelectorComboBox from "../../Utilidades/Selector/SelectorComboBox";
import { FormatoFechaDDMMYYYY, GetAntecesorWhithClassName, upperCaseFirstLetter } from "../../../services/utils";
import SelectorUno2 from "../../Utilidades/Selector/SelectorUno2";
import SelectorMulti2 from "../../Utilidades/Selector/SelectorMulti2";
import { OpcionesADM, OpcionesCON, OpcionesDG, OpcionesDISP, OpcionesESP, OpcionesOTR, OpcionesPAC } from "../../../services/objsAplicacion";
import SelectorCHK from "../../Utilidades/Selector/SelectorCHK";
import SelectorSNX from "../../Utilidades/Selector/SelectorSNX";
import Acordeon from "../../Fichas/FichaMUH/Apartados/Composicion/Acordeon";
import SelectorEdad from "../../Utilidades/Selector/SelectorEdad/SelectorEdad";
import SelectorEnfRel from "../../Utilidades/Selector/SelectorEnfRel/SelectorEnfRel";
import ItemResumenFiltros from "../../Resultados/FiltrosYBusqueda/ResumenFiltros/ItemResumenFiltros";
import SelectorNumero from "../../Utilidades/Selector/SelectorNumero";
import SelectorFechas from "../../Utilidades/Selector/SelectorFecha/SelectorFecha";
import SelectorMulti2No from "../../Utilidades/Selector/SelectorMulti2No";
import SelectorAnaRel from "../../Utilidades/Selector/SelectorEnfRel/SelectorAnaRel";
import SelectorTexto from "../../Utilidades/Selector/SelectorTexto";

const FBAvanzadaMUH= (props) => {
    const [datosFormulario, setDatosFormulario] = useState({
        listaPAs: null,
        listaFFs: null,
        listaVias: null,
        listaIntsV: null,
        listaIntsNV: null,
        listaEnfs: null,
        listaGTs: null,
        listaLabs: null,
        listaCamposPrecio: null,
        listaOperadoresPrecio: null,
        listaPredefinidasPrecio: null,
        listaAgrupAuto:null,
        listaAgrupNac:null,
        listaDescrip:null,
        listaAnas: null,
        listaMens: null,
        listaEpis: null,
    });
    const [seleccionFormulario, setSeleccionFormulario] = useState({
        txt: props.txt,
        idsLab: props.idsLab,
        idsGT: props.idsGT,
        pas: props.pas,
        solo: props.solo,
        idVia: props.idVia,
        idFF: props.idFF,
        uds: props.uds,
        emr: props.emr,
        ints: props.ints,
        edad: props.edad,
        undEdad: props.undEdad,
        enfsRel: (props.enfsRel ? props.enfsRel : []),
        anasRel: (props.anasRel ? props.anasRel : []),
        ops: (props.ops ? props.ops : []),
        fechaAutoIni: props.fechaAutoIni,
        fechaAutoFin: props.fechaAutoFin,
        fechaInSNSIni: props.fechaInSNSIni,
        fechaInSNSFin: props.fechaInSNSFin,
        fechaComerIni: props.fechaComerIni,
        fechaComerFin: props.fechaComerFin,
        fechaBajaIni: props.fechaBajaIni,
        fechaBajaFin: props.fechaBajaFin,
        fechaExSNSIni: props.fechaExSNSIni,
        fechaExSNSFin: props.fechaExSNSFin,
        fechaExFIIni: props.fechaExFIIni,
        fechaExFIFin: props.fechaExFIFin,
        idCampoP: props.idCampoP,
        idOpP: props.idOpP,
        pre: props.pre,
        idPredefP: props.idPredefP,
        idsAA: props.idsAA,
        idsAN: props.idsAN,
        idsDC: props.idsDC,
        idsMen: props.idsMen,
        texto: props.texto,
        idEpi: props.idEpi,
        noTexto: props.noTexto,
    })


    const [ocultaIntolerancias, setOcultaIntolerancias] = useState(true);

    const claseIntoleranciasOcultas = (ocultaIntolerancias) ? "cover hide" : "cover hide active";
    const claseBotonIntolerancias = (ocultaIntolerancias) ? "button button__arrow button__purple__line noUppercase verMas" : "button button__arrow button__purple__line noUppercase verMas active";
    const txtbotonIntolerancias = (ocultaIntolerancias) ? "Ver más" : "Ver menos";

    useEffect(() => {
        if (datosFormulario.listaPAs === null){
            DescargaDatos();
        }   
    },[]);

    const DescargaDatos = () => {      
        BP2022Service.datosFormularioAvanzadaMUH()
        .then((response) => {
            var df = {
                listaPAs : response.data.Componentes,
                listaLabs: response.data.Laboratorios,
                listaGTs: response.data.Grupos,
                listaVias : response.data.Vias,
                listaFFs : response.data.FormasFarma,
                listaIntsV : response.data.IntoleranciasV,
                listaIntsNV : response.data.IntoleranciasNoV,
                listaEnfs : response.data.Enfermedades,
                listaAnas : response.data.Analisis,
                listaCamposPrecio: response.data.CamposPrecio,
                listaOperadoresPrecio: response.data.OperadoresPrecio,
                listaPredefinidasPrecio: response.data.PredefinidasPrecio,
                listaAgrupAuto: response.data.AgrupacionesAutonomicas,
                listaAgrupNac: response.data.AgrupacionesNacionales,
                listaDescrip: response.data.DescripcionesClinicas,
                listaMens: response.data.Mensajes,
                listaEpis: response.data.Epigrafes,
            };
            df.listaPAs.splice(0,0,{Codigo:0, Valor:'Indique principio activo'});
            df.listaLabs.splice(0,0,{Codigo:0, Valor:'Indique laboratorio'});
            df.listaGTs.splice(0,0,{Codigo:'', Valor:'Indique nombre o código de grupo'});
            df.listaEnfs.splice(0,0,{Codigo:0, Valor:'Indique enfermedad '});
            df.listaAnas.splice(0,0,{Codigo:0, Valor:'Indique análisis '});
            df.listaVias.splice(0,0,{Codigo:-1, Valor:'Seleccione una vía'},{Codigo:0, Valor:'-'});
            df.listaFFs.splice(0,0,{Codigo:-1, Valor:'Seleccione una forma farmacéutica'},{Codigo:0, Valor:'-'});
            df.listaCamposPrecio.splice(0,0,{Codigo:'-', Valor:'Indique un precio'},{Codigo:'', Valor:'-'});
            df.listaOperadoresPrecio.splice(0,0,{Codigo:-1, Valor:'Indique un operador'},{Codigo:0, Valor:'-'});
            df.listaPredefinidasPrecio.splice(0,0,{Codigo:-1, Valor:'Indique una comparativa'},{Codigo:0, Valor:'-'});
            df.listaAgrupAuto.splice(0,0,{Codigo:'', Valor:'Indique nombre o código de la agrupación'});
            df.listaAgrupNac.splice(0,0,{Codigo:'', Valor:'Indique nombre o código de la agrupación'});
            df.listaDescrip.splice(0,0,{Codigo:'', Valor:'Indique descripción clínica'});
            df.listaMens.splice(0,0,{Codigo:0, Valor:'Indique advertencia'});
            df.listaEpis.splice(0,0,{Codigo:-1, Valor:'Seleccione una epígrafe'},{Codigo:0, Valor:'-'});
            setDatosFormulario(df);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const clickVerIntolerancias=(e) =>{
        setOcultaIntolerancias(!ocultaIntolerancias);
    }

    const obtenDatoResumen = (opciones) =>{
        var strValores=[]
        for(var i=0; i< opciones.length; i++){
            var itemOpcion = opciones[i];
            if (itemOpcion.tip==="CHK"){
                var itemSel = seleccionFormulario.ops.find(item => item.cod===itemOpcion.cod);
                if (itemSel && itemSel.valores && itemSel.valores.length>0){
                    var strVal = "";
                    for(var j=0; j<itemSel.valores.length; j++){
                        if (j>0) strVal += " o ";
                        else strVal += itemOpcion.tit + ": ";
                        var valSel = itemOpcion.ops.find(val=> val.val === itemSel.valores[j]);
                        strVal += valSel.tit;
                    }
                    if (strVal !== "")
                        strValores.push(strVal);
                }
            }else if (itemOpcion.tip==="SNX"){
                var itemSel = seleccionFormulario.ops.find(item =>item.cod===itemOpcion.cod);
                if (itemSel && itemSel.valor && itemSel.valor !== "" && itemSel.valor !== "X"){
                    if (itemSel.valor === "S"){
                        strValores.push(itemOpcion.tit.replace("<br/>",""));
                    }else if (itemSel.valor === "N"){
                        strValores.push("No " + itemOpcion.tit.toLowerCase().replace("<br/>",""));
                    }
                }
            }
        }
        var res="";
        if (strValores.length>0){
            var res = strValores[0];
            for (var i=1; i<strValores.length; i++){
                res += "\n" + strValores[i];
            }
        }
        return res;
    }

    const construyeResumenBusqueda = () => {
        var res = [];

        if (seleccionFormulario.txt && seleccionFormulario.txt != ""){
            res.push ({titulo: 'Nombre contiene', valor: seleccionFormulario.txt});
        }

    
        /*if (seleccionFormulario.idLab>0){
            if (datosFormulario.listaLabs){
                var item = datosFormulario.listaLabs.find(x => x.Codigo === seleccionFormulario.idLab);
                if (item)
                    res.push ({titulo: "Laboratorio", valor: item.Valor});
            }
        }
    
        if (seleccionFormulario.idGT!==''){
            if (datosFormulario.listaGTs){
                var item = datosFormulario.listaGTs.find(x => x.Codigo === seleccionFormulario.idGT);
                if (item)
                    res.push ({titulo: "Grupo terapéutico ATC", valor: item.Valor});
            }
        }
        */
        if ((seleccionFormulario.idsLab) && (seleccionFormulario.idsLab.length>0)){
            if (datosFormulario.listaLabs){
                var valor = '';
                for (var i =0; i<seleccionFormulario.idsLab.length; i++){
                    var item = datosFormulario.listaLabs.find(x => x.Codigo === seleccionFormulario.idsLab[i]);
                    if (item){
                        if (valor !== '')
                            valor += ", ";
                        valor += item.Valor;
                    }
                }
                if (valor != '')
                    res.push ({titulo: "Laboratorio/s", valor: valor});
            }
        }    

        if ((seleccionFormulario.idsGT) && (seleccionFormulario.idsGT.length>0)){
            if (datosFormulario.listaGTs){
                var valor = '';
                for (var i =0; i<seleccionFormulario.idsGT.length; i++){
                    var item = datosFormulario.listaGTs.find(x => x.Codigo === seleccionFormulario.idsGT[i]);
                    if (item){
                        if (valor !== '')
                            valor += ", ";
                        valor += item.Valor;
                    }
                }
                if (valor != '')
                    res.push ({titulo: "Grupo/s terapéutico/s ATC", valor: valor});
            }
        }    

        if (seleccionFormulario.idFF>0){
            if (datosFormulario.listaFFs){
                var item = datosFormulario.listaFFs.find(x => x.Codigo === seleccionFormulario.idFF);
                if (item)
                    res.push ({titulo: "Forma farmacéutica", valor: item.Valor});
            }
        }
    
        if (seleccionFormulario.idVia>0){
            if (datosFormulario.listaVias){
                var item = datosFormulario.listaVias.find(x => x.Codigo === seleccionFormulario.idVia);
                if (item)
                    res.push ({titulo: "Vía", valor: item.Valor});
            }
        }

        if (seleccionFormulario.uds>0){
            res.push ({titulo: "Unidades envase", valor: seleccionFormulario.uds.toString()});
        }
    
        if (seleccionFormulario.emr>0){
            res.push ({titulo: "Envases máximos por receta", valor: seleccionFormulario.emr.toString()});
        }
    
        var idxEstado = seleccionFormulario.ops.findIndex(op => op.cod==="EST");
        if (idxEstado >= 0){
            var itemEstado=seleccionFormulario.ops[idxEstado];
            if (itemEstado.valores.length>0){
                var strValor = '';
                for (var i=0; i<itemEstado.valores.length; i++){
                    if (i>0) strValor += " o "
                    strValor += OpcionesDG[0].ops.find(op=> op.val === itemEstado.valores[i]).tit;
                }
                res.push({titulo: "Estado", valor: strValor});
            }
        }

        var idxCom = seleccionFormulario.ops.findIndex(op => op.cod==="COM");
        if (idxCom >= 0){
            var itemCom=seleccionFormulario.ops[idxCom];
            if (itemCom.valor==="S"){
                res.push({titulo: "Comercializado", valor: "Sí"});
            }else if (itemCom.valor==="N"){
                res.push({titulo: "Comercializado", valor: "No"});
            }
        }

        if (seleccionFormulario.fechaAutoIni){
            if (seleccionFormulario.fechaAutoFin){
                res.push ({titulo: "Fecha de autorización", valor: 'entre las fechas ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaAutoIni) + ' y ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaAutoFin)});
            }else{
                res.push ({titulo: "Fecha de autorización", valor: 'posterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaAutoIni)});
            }
        }else if (seleccionFormulario.fechaAutoFin){
            res.push ({titulo: "Fecha de autorización", valor: 'anterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaAutoFin)});
        }

        if (seleccionFormulario.fechaInSNSIni){
            if (seleccionFormulario.fechaInSNSFin){
                res.push ({titulo: "Fecha de inclusión SNS", valor: 'entre las fechas ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaInSNSIni) + ' y ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaInSNSFin)});
            }else{
                res.push ({titulo: "Fecha de inclusión SNS", valor: 'posterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaInSNSIni)});
            }
        }else if (seleccionFormulario.fechaInSNSFin){
            res.push ({titulo: "Fecha de inclusión SNS", valor: 'anterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaInSNSFin)});
        }

        if (seleccionFormulario.fechaComerIni){
            if (seleccionFormulario.fechaComerFin){
                res.push ({titulo: "Fecha de comercialización", valor: 'entre las fechas ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaComerIni) + ' y ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaComerFin)});
            }else{
                res.push ({titulo: "Fecha de comercialización", valor: 'posterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaComerIni)});
            }
        }else if (seleccionFormulario.fechaComerFin){
            res.push ({titulo: "Fecha de comercialización", valor: 'anterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaComerFin)});
        }

        if (seleccionFormulario.fechaBajaIni){
            if (seleccionFormulario.fechaBajaFin){
                res.push ({titulo: "Fecha de baja", valor: 'entre las fechas ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaBajaIni) + ' y ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaBajaFin)});
            }else{
                res.push ({titulo: "Fecha de baja", valor: 'posterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaBajaIni)});
            }
        }else if (seleccionFormulario.fechaBajaFin){
            res.push ({titulo: "Fecha de baja", valor: 'anterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaBajaFin)});
        }

        if (seleccionFormulario.fechaExSNSIni){
            if (seleccionFormulario.fechaExSNSFin){
                res.push ({titulo: "Fecha de exclusión", valor: 'entre las fechas ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaExSNSIni) + ' y ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaExSNSFin)});
            }else{
                res.push ({titulo: "Fecha de exclusión", valor: 'posterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaExSNSIni)});
            }
        }else if (seleccionFormulario.fechaExSNSFin){
            res.push ({titulo: "Fecha de exclusión", valor: 'anterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaExSNSFin)});
        }

        if (seleccionFormulario.fechaExFIIni){
            if (seleccionFormulario.fechaExFIFin){
                res.push ({titulo: "Fecha de exclusión financiación individualizada", valor: 'entre las fechas ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaExFIIni) + ' y ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaExFIFin)});
            }else{
                res.push ({titulo: "Fecha de exclusión financiación individualizada", valor: 'posterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaExFIIni)});
            }
        }else if (seleccionFormulario.fechaExFIFin){
            res.push ({titulo: "Fecha de exclusión financiación individualizada", valor: 'anterior a ' + FormatoFechaDDMMYYYY(seleccionFormulario.fechaExFIFin)});
        }
        if (seleccionFormulario.idCampoP && seleccionFormulario.idCampoP!=='' && seleccionFormulario.idOpP > 0 && seleccionFormulario.pre && seleccionFormulario.pre > 0){
            if (datosFormulario.listaCamposPrecio && datosFormulario.listaOperadoresPrecio){
                var c = datosFormulario.listaCamposPrecio.find(x => x.Codigo === seleccionFormulario.idCampoP);
                var o = datosFormulario.listaOperadoresPrecio.find(x => x.Codigo === seleccionFormulario.idOpP);
                if (c && o){
                    var val = o.Valor + ' que ' + seleccionFormulario.pre.toString() + " €";
                    if (o.Codigo === 3) val = o.Valor + ' a ' + seleccionFormulario.pre.toString() + " €";
                    res.push ({titulo: c.Valor, valor: val});
                }
            }
        }
        if (seleccionFormulario.idPredefP > 0){
            if (datosFormulario.listaPredefinidasPrecio){
                var item = datosFormulario.listaPredefinidasPrecio.find(x => x.Codigo === seleccionFormulario.idPredefP);
                if (item){
                    res.push ({titulo: "Precio", valor: item.Valor});
                }
            }
        }
        if ((seleccionFormulario.idsAA) && (seleccionFormulario.idsAA.length>0)){
            if (datosFormulario.listaAgrupAuto){
                var valor = '';
                for (var i =0; i<seleccionFormulario.idsAA.length; i++){
                    var item = datosFormulario.listaAgrupAuto.find(x => x.Codigo === seleccionFormulario.idsAA[i]);
                    if (item){
                        if (valor !== '')
                            valor += ", ";
                        valor += item.Valor;
                    }
                }
                if (valor != '')
                    res.push ({titulo: "Agrupación/es autonómica/s", valor: valor});
            }
        }    
        if ((seleccionFormulario.idsAN) && (seleccionFormulario.idsAN.length>0)){
            if (datosFormulario.listaAgrupNac){
                var valor = '';
                for (var i =0; i<seleccionFormulario.idsAN.length; i++){
                    var item = datosFormulario.listaAgrupNac.find(x => x.Codigo === seleccionFormulario.idsAN[i]);
                    if (item){
                        if (valor !== '')
                            valor += ", ";
                        valor += item.Valor;
                    }
                }
                if (valor != '')
                    res.push ({titulo: "Agrupación/es nacional/es", valor: valor});
            }
        }    
        if ((seleccionFormulario.idsDC) && (seleccionFormulario.idsDC.length>0)){
            if (datosFormulario.listaDescrip){
                var valor = '';
                for (var i =0; i<seleccionFormulario.idsDC.length; i++){
                    var item = datosFormulario.listaDescrip.find(x => x.Codigo === seleccionFormulario.idsDC[i]);
                    if (item){
                        if (valor !== '')
                            valor += ", ";
                        valor += item.Valor;
                    }
                }
                if (valor != '')
                    res.push ({titulo: "Descripción/es clínica/s", valor: valor});
            }
        }    

        if ((seleccionFormulario.idsMen) && (seleccionFormulario.idsMen.length>0)){
            if (datosFormulario.listaMens){
                var valor = '';
                for (var i =0; i<seleccionFormulario.idsMen.length; i++){
                    var item = datosFormulario.listaMens.find(x => x.Codigo === Math.abs(seleccionFormulario.idsMen[i]));
                    if (item){
                        if (valor !== '')
                            valor += ",\n";
                        if (seleccionFormulario.idsMen[i]<0)                            
                            valor += "NO "
                        valor += item.Valor;
                    }
                }
                if (valor != '')
                    res.push ({titulo: "Advertencias/s", valor: valor});
            }
        }    

        if ((seleccionFormulario.pas) && (seleccionFormulario.pas.length>0)){
            if (datosFormulario.listaPAs){
                var titulo = "Composición";
                for (var i =0; i<seleccionFormulario.pas.length; i++){
                    var valor = '';
                    var item = datosFormulario.listaPAs.find(x => x.Codigo === Math.abs(seleccionFormulario.pas[i].codigo));
                    if (item){
                        if (seleccionFormulario.pas[i].codigo<0)
                            valor += "No contiene ";
                        valor += item.Valor;
                        var sales = seleccionFormulario.pas[i].sales;
                        if (sales && sales.length>0){
                            for (var j=0; j<sales.length; j++){
                                if (j>0)
                                    valor += " /"
                                else
                                    valor += "        "
                                valor += " " + sales[j].valor;
                            }
                        }    
                        var cants = seleccionFormulario.pas[i].cants;
                        if (cants && cants.length>0){
                            for (var j=0; j<cants.length; j++){
                                if (j>0)
                                    valor += " /"
                                else
                                    valor += "        "
                                valor += " " + cants[j].des;
                            }
                        }    
                    }
                    res.push({titulo:titulo, valor: valor});
                    titulo = "";
                }
                if (seleccionFormulario.solo){                    
                    res.push({titulo: "", valor: "Únicamente los principios activos seleccionados"});
                }
                else{
                    res.push({titulo: "", valor: "Puede contener principios activos adicionales"});
                }
            }
        }

        var str = obtenDatoResumen(OpcionesDISP);
        if (str !== "")
            res.push({titulo: "Opciones de dispensación y faturación", valor:str});

        var str = obtenDatoResumen(OpcionesPAC);
        if (seleccionFormulario.edad && seleccionFormulario.edad>0 && seleccionFormulario.undEdad && seleccionFormulario.undEdad != ""){
            if (str != "") str += "\n";
            str += "Autorizado en niños de " + seleccionFormulario.edad.toString();
            if (seleccionFormulario.undEdad.toLowerCase()==="m"){
                str += " meses"
            }else{
                str += " años"
            }
        }
        if (str !== "")
            res.push({titulo: "Características del paciente", valor:str});

        if ((seleccionFormulario.ints) && (seleccionFormulario.ints.length>0)){
            if (datosFormulario.listaIntsV){
                var valor = '';
                for (var i =0; i<seleccionFormulario.ints.length; i++){
                    var item = datosFormulario.listaIntsV.find(x => x.Codigo === seleccionFormulario.ints[i]);
                    if (!item) item = datosFormulario.listaIntsNV.find(x => x.Codigo === seleccionFormulario.ints[i]);
                    if (item){
                        if (valor !== '')
                            valor += ", ";
                        valor += item.Valor;
                    }
                }
                if (valor != '')
                    res.push ({titulo: "Aptos para las intolerancias del paciente", valor: valor});
            }
        }

        var str = obtenDatoResumen(OpcionesADM);
        if (str !== "")
            res.push({titulo: "Administración y dosificación", valor:str});

        var str = obtenDatoResumen(OpcionesESP);
        if (str !== "")
            res.push({titulo: "Advertencias especiales", valor:str});


        var str = obtenDatoResumen(OpcionesOTR);
        if (str !== "")
            res.push({titulo: "Tipo de medicamento", valor:str});

        var str = obtenDatoResumen(OpcionesCON);
        if (str !== "")
            res.push({titulo: "Conservación", valor:str});

        if (seleccionFormulario.enfsRel && seleccionFormulario.enfsRel.length>0){
            if (datosFormulario.listaEnfs){
                var valor = '';
                for (var i =0; i<seleccionFormulario.enfsRel.length; i++){
                    var item = datosFormulario.listaEnfs.find(x => x.Codigo === seleccionFormulario.enfsRel[i].codigo);
                    if (item){
                        if (valor !== "") valor += "\n";
                        var epi = seleccionFormulario.enfsRel[i].epi;
                        if (Math.abs(epi)===3){
                            if (epi<0)
                                valor += "No indicado para " + item.Valor;
                            else
                                valor += "Indicado para " + item.Valor;
                        }else if (Math.abs(epi)===13){
                            if (epi<0)
                                valor += "No contraindicado en " + item.Valor;
                            else                            
                                valor += "Contraindicado en " + item.Valor;
                        }else if (Math.abs(epi)===14){
                            if (epi<0)
                                valor += "Sin uso precautorio en " + item.Valor;
                            else                            
                                valor += "Uso precautorio en " + item.Valor;
                        }else if (Math.abs(epi)===7){
                            if (epi<0)
                                valor += "Sin " + item.Valor + " como reacción adversa";
                            else                            
                                valor += "Con " + item.Valor + " como reacción adversa";
                        }
                    }
                }
                if (valor != '')
                    res.push ({titulo: "Relación con enfermedades", valor: valor});
            }
        }

        if (seleccionFormulario.anasRel && seleccionFormulario.anasRel.length>0){
            if (datosFormulario.listaAnas){
                var valor = '';
                for (var i =0; i<seleccionFormulario.anasRel.length; i++){
                    var item = datosFormulario.listaAnas.find(x => x.Codigo === seleccionFormulario.anasRel[i].codigo);
                    if (item){
                        if (valor !== "") valor += "\n";
                        var epi = seleccionFormulario.anasRel[i].epi;
                        if (Math.abs(epi)===99){
                            if (epi<0)
                                valor += "No interfiere en " + item.Valor;
                            else
                                valor += "Interfiere en " + item.Valor;
                        }else if (Math.abs(epi)===1){
                            if (epi<0)
                                valor += "No provoca aumento de " + item.Valor;
                            else
                                valor += "Provoca aumento de " + item.Valor;
                        }else if (Math.abs(epi)===2){
                            if (epi<0)
                                valor += "No provoca disminución de " + item.Valor;
                            else                            
                                valor += "Provoca disminución de " + item.Valor;
                        }else if (Math.abs(epi)===3){
                            if (epi<0)
                                valor += "No provoca positivo en " + item.Valor;
                            else                            
                                valor += "Provoca positivo " + item.Valor;
                        }else if (Math.abs(epi)===4){
                            if (epi<0)
                                valor += "No provoca negativo en " + item.Valor;
                            else                            
                                valor += "Provoca negativo " + item.Valor;
                        }
                    }
                }
                if (valor != '')
                    res.push ({titulo: "Interferencia en análisis clínicos", valor: valor});
            }
        }

        if (seleccionFormulario.texto && seleccionFormulario.texto.trim().length>2){
            if (datosFormulario.listaEpis){
                var aux = "Algún epígrafe contiene el texto ";
                if (seleccionFormulario.noTexto)
                    aux = "Ningún epígrafe contiene el texto";
                if (seleccionFormulario.idEpi && seleccionFormulario.idEpi>0){
                    var item = datosFormulario.listaEpis.find(x => x.Codigo === seleccionFormulario.idEpi);
                    if (item){
                        if (seleccionFormulario.noTexto)
                            aux = item.Valor.trim() + " no contiene el texto ";
                        else
                            aux = item.Valor.trim() + " contiene el texto ";
                    }
                }
                aux += "\"" + seleccionFormulario.texto + "\"";
                res.push({titulo: "Búsquedas por texto", valor: aux});
            }
        }

        return res;
    }

    var resumen = construyeResumenBusqueda(seleccionFormulario);

    const hayBusqueda = (seleccionFormulario.pas && seleccionFormulario.pas.length>0)
    || (seleccionFormulario.txt && seleccionFormulario.txt.length>=2)
    || (seleccionFormulario.idsLab && seleccionFormulario.idsLab.length>0)
    || (seleccionFormulario.idsGT && seleccionFormulario.idsGT.length>0)
    || (seleccionFormulario.idFF>0)
    || (seleccionFormulario.idVia>0)
    || (seleccionFormulario.ops && seleccionFormulario.ops.length>0)
    || (seleccionFormulario.edad>0 && seleccionFormulario.undEdad != "")
    || (seleccionFormulario.ints && seleccionFormulario.ints.length>0)
    || (seleccionFormulario.enfsRel && seleccionFormulario.enfsRel.length>0)
    || (seleccionFormulario.anasRel && seleccionFormulario.anasRel.length>0)
    || (seleccionFormulario.uds > 0)
    || (seleccionFormulario.emr > 0)
    || (seleccionFormulario.fechaAutoIni) || (seleccionFormulario.fechaAutoFin)
    || (seleccionFormulario.fechaInSNSIni) || (seleccionFormulario.fechaInSNSFin)
    || (seleccionFormulario.fechaComerIni) || (seleccionFormulario.fechaComerFin)
    || (seleccionFormulario.fechaBajaIni) || (seleccionFormulario.fechaBajaFin)
    || (seleccionFormulario.fechaExSNSIni) || (seleccionFormulario.fechaExSNSFin)
    || (seleccionFormulario.fechaExFIIni) || (seleccionFormulario.fechaExFIFin)
    || (seleccionFormulario.idCampoP && seleccionFormulario.idCampoP!=='' && seleccionFormulario.idOpP > 0 && seleccionFormulario.pre > 0)
    || (seleccionFormulario.idPredefP > 0)
    || (seleccionFormulario.idsAA && seleccionFormulario.idsAA.length>0)
    || (seleccionFormulario.idsAN && seleccionFormulario.idsAN.length>0)
    || (seleccionFormulario.idsDC && seleccionFormulario.idsDC.length>0)
    || (seleccionFormulario.idsMen && seleccionFormulario.idsMen.length>0)
    || (seleccionFormulario.texto && seleccionFormulario.texto.length>2)
    ;


    const clickBuscar = (e) =>{
        if (hayBusqueda){
            var newpagina = "BAvanzadaMUH";

            props.cambiaPagina({
                newpagina: newpagina,
                txt: seleccionFormulario.txt,
                idsLab: seleccionFormulario.idsLab,
                idsGT: seleccionFormulario.idsGT,
                pas: seleccionFormulario.pas,
                solo: seleccionFormulario.solo,
                idVia: seleccionFormulario.idVia,
                idFF: seleccionFormulario.idFF,
                uds: seleccionFormulario.uds,
                emr: seleccionFormulario.emr,
                edad: seleccionFormulario.edad,
                undEdad: seleccionFormulario.undEdad,
                enfsRel: seleccionFormulario.enfsRel,
                anasRel: seleccionFormulario.anasRel,
                ints: seleccionFormulario.ints,
                ops: seleccionFormulario.ops,
                fechaAutoFin: seleccionFormulario.fechaAutoFin,
                fechaAutoIni: seleccionFormulario.fechaAutoIni,
                fechaBajaFin: seleccionFormulario.fechaBajaFin,
                fechaBajaIni: seleccionFormulario.fechaBajaIni,
                fechaComerFin: seleccionFormulario.fechaComerFin,
                fechaComerIni: seleccionFormulario.fechaComerIni,
                fechaExFIFin: seleccionFormulario.fechaExFIFin,
                fechaExFIIni: seleccionFormulario.fechaExFIIni,
                fechaExSNSFin: seleccionFormulario.fechaExSNSFin,
                fechaExSNSIni: seleccionFormulario.fechaExSNSIni,
                fechaInSNSFin: seleccionFormulario.fechaInSNSFin,
                fechaInSNSIni: seleccionFormulario.fechaInSNSIni,
                idCampoP: seleccionFormulario.idCampoP,
                idOpP: seleccionFormulario.idOpP,
                pre: seleccionFormulario.pre,
                idPredefP: seleccionFormulario.idPredefP,
                idsAA: seleccionFormulario.idsAA,
                idsAN: seleccionFormulario.idsAN,
                idsDC: seleccionFormulario.idsDC,
                idsMen: seleccionFormulario.idsMen,
                texto: seleccionFormulario.texto,
                idEpi: seleccionFormulario.idEpi,
                noTexto: seleccionFormulario.noTexto,
            })
        }
    }

    const BorraBusqueda = (e) =>{
        const formularioVacio={
            pas: [],
            txt: '',
            idsLab: [],
            idsGT: [],
            solo: "S",
            idVia: 0,
            idFF: 0,
            uds: 0,
            emr: 0,
            edad: 0,
            undEdad: '',
            ints: [],
            enfsRel: [],
            anasRel: [],
            ops: [],
            fechaAutoFin: null,
            fechaAutoIni: null,
            fechaBajaFin: null,
            fechaBajaIni: null,
            fechaComerFin: null,
            fechaComerIni: null,
            fechaExFIFin: null,
            fechaExFIIni: null,
            fechaExSNSFin: null,
            fechaExSNSIni: null,
            fechaInSNSFin: null,
            fechaInSNSIni: null,
            idCampoP: '',
            idOpP: 0,
            pre: 0,
            idPredefP: 0,
            idsAA: [],
            idsAN: [],
            idsDC: [],
            idsMen: [],
            texto: '',
            idEpi: 0,
            noTexto: false,
        }
        setSeleccionFormulario(formularioVacio);
        var inputs = document.getElementsByClassName('inputSelector');
        if (inputs){
            for (var i=0; i<inputs.length; i++){
                inputs[i].value = "";
                const itemContainer = GetAntecesorWhithClassName(inputs[i], 'custom-select-container');
                if (itemContainer){
                    itemContainer.classList.remove('active');
                }
            }
        }
        inputs = document.getElementsByClassName('input__sel__num');
        if (inputs){
            for (var i=0; i<inputs.length; i++){
                inputs[i].value = "8";
            }
        }
    }

    const copiaFechas = (origen,destino) =>{
        destino.fechaAutoFin = origen.fechaAutoFin;
        destino.fechaAutoIni = origen.fechaAutoIni;
        destino.fechaComerFin = origen.fechaComerFin;
        destino.fechaComerIni = origen.fechaComerIni;
        destino.fechaBajaFin = origen.fechaBajaFin;
        destino.fechaBajaIni = origen.fechaBajaIni;
        destino.fechaInSNSFin = origen.fechaInSNSFin;
        destino.fechaInSNSIni = origen.fechaInSNSIni;
        destino.fechaExSNSFin = origen.fechaExSNSFin;
        destino.fechaExSNSIni = origen.fechaExSNSIni;
        destino.fechaExFIFin = origen.fechaExFIFin;
        destino.fechaExFIIni = origen.fechaExFIIni;
    }

    const CambioFechaAuto= (inicio, fin) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.fechaAutoIni = inicio;
        nuevaSeleccion.fechaAutoFin = fin;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioFechaComer= (inicio, fin) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.fechaComerIni = inicio;
        nuevaSeleccion.fechaComerFin = fin;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioFechaBaja= (inicio, fin) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.fechaBajaIni = inicio;
        nuevaSeleccion.fechaBajaFin = fin;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioFechaInSNS= (inicio, fin) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.fechaInSNSIni = inicio;
        nuevaSeleccion.fechaInSNSFin = fin;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioFechaExSNS= (inicio, fin) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.fechaExSNSIni = inicio;
        nuevaSeleccion.fechaExSNSFin = fin;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioFechaExFI= (inicio, fin) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.fechaExFIIni = inicio;
        nuevaSeleccion.fechaExFIFin = fin;
        setSeleccionFormulario(nuevaSeleccion);
    }


    /*
    const CambioLaboratorio = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idLab = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const BorraLaboratorio = () =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idLab = 0;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioGrupo = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idGT= nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const BorraGrupo = () =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        nuevaSeleccion.idGT = '';
        setSeleccionFormulario(nuevaSeleccion);
    }
    */

    const AddLaboratorio = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        if (!nuevaSeleccion.idsLab) { nuevaSeleccion.idsLab = []};
        nuevaSeleccion.idsLab.push(nuevovalor);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveLaboratorio = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        var i = nuevaSeleccion.idsLab.indexOf(parseInt(valor));
        nuevaSeleccion.idsLab.splice(i,1);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddGrupo = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        if (!nuevaSeleccion.idsGT) { nuevaSeleccion.idsGT = []};
        nuevaSeleccion.idsGT.push(nuevovalor.trim());
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveGrupo = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        var i = nuevaSeleccion.idsGT.indexOf(valor.trim());
        nuevaSeleccion.idsGT.splice(i,1);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddAgrupAuto = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        if (!nuevaSeleccion.idsAA) { nuevaSeleccion.idsAA = []};
        nuevaSeleccion.idsAA.push(nuevovalor.trim());
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveAgrupAuto = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        var i = nuevaSeleccion.idsAA.indexOf(valor.trim());
        nuevaSeleccion.idsAA.splice(i,1);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddAgrupNac = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        if (!nuevaSeleccion.idsAN) { nuevaSeleccion.idsAN = []};
        nuevaSeleccion.idsAN.push(nuevovalor.trim());
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveAgrupNac = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        var i = nuevaSeleccion.idsAN.indexOf(valor.trim());
        nuevaSeleccion.idsAN.splice(i,1);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddDescripC = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        if (!nuevaSeleccion.idsDC) { nuevaSeleccion.idsDC = []};
        nuevaSeleccion.idsDC.push(nuevovalor.trim());
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveDescripC = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        var i = nuevaSeleccion.idsDC.indexOf(valor.trim());
        nuevaSeleccion.idsDC.splice(i,1);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddAdvertencia = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        if (!nuevaSeleccion.idsMen) { nuevaSeleccion.idsMen = []};
        if (!nuevaSeleccion.idsMen.includes(nuevovalor)){
            nuevaSeleccion.idsMen.push(nuevovalor);
            setSeleccionFormulario(nuevaSeleccion);
        }
    }

    const NiegaAdvertencia = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        if (!nuevaSeleccion.idsMen) { nuevaSeleccion.idsMen = []};
        if (nuevaSeleccion.idsMen.includes(nuevovalor)){
            var idx = nuevaSeleccion.idsMen.indexOf(nuevovalor);
            nuevaSeleccion.idsMen.splice(idx,1,nuevovalor * -1);
            setSeleccionFormulario(nuevaSeleccion);
        }
    }

    const RemoveAdvertencia = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        var i = nuevaSeleccion.idsMen.indexOf(parseInt(valor));
        nuevaSeleccion.idsMen.splice(i,1);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambiaTxt = (e) =>{
        const item = e.target;
        if (item){
            const valor = item.value;
            const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
            copiaFechas(seleccionFormulario, nuevaSeleccion);
            nuevaSeleccion.txt = valor;
            setSeleccionFormulario(nuevaSeleccion);
        }
    }

    const AddSal = (idPA, idSal, des) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (pa){
            if (!pa.sales){
                pa.sales = []
            }
            var sal = pa.sales.find(item => item.codigo === idSal);
            if (!sal){
                pa.sales.push({codigo:idSal, valor: des});
            }
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveSal = (idPA, idSal) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (pa){
            if (!pa.sales){
                pa.sales = []
            }
            pa.sales = pa.sales.filter(item => item.codigo !== idSal);
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddCant = (idPA, idUnd, cantidad, des) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (pa){
            if (!pa.cants){
                pa.cants = []
            }
            var cant = pa.cants.find(item => item.cod === idUnd && item.cant === cantidad);
            if (!cant){
                pa.cants.push({cod: idUnd, cant: cantidad, des: des});
            }
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveCant = (idPA, idUnd, cantidad) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (pa){
            if (!pa.cants){
                pa.cants = []
            }
            pa.cants = pa.cants.filter(item => item.cod !== idUnd || item.cant !== cantidad);
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const AddPA = (idPA) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        var pa = nuevaSeleccion.pas.find(item => item.codigo===idPA);
        if (!pa){
            nuevaSeleccion.pas.push({
                codigo: idPA,
                sales: [],
                cants: [],
            })
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const UpdatePA = (idPA, negado) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        var pa = nuevaSeleccion.pas.find(item => Math.abs(item.codigo)===Math.abs(idPA));
        if (pa){
            if (negado){
                pa.codigo = Math.abs(idPA) * (-1);
                pa.sales = [];
                pa.cants = [];
            }else{
                pa.codigo = Math.abs(idPA);
            }
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemovePA = (idPA) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.pas = nuevaSeleccion.pas.filter(item => Math.abs(item.codigo)!==Math.abs(idPA));
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambiaPASolo = (valor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.solo = valor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioVia = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.idVia = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioFF = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.idFF = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioCampoPrecio = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.idCampoP = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioOperadorPrecio = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.idOpP = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioPrecio = (nuevonumero) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.pre = nuevonumero;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioPredefinidaPrecio = (nuevovalor) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.idPredefP = nuevovalor;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioIntolerancia = (e, idInt) =>{
        var item = e.target;
        var idx = (seleccionFormulario.ints) ? seleccionFormulario.ints.indexOf(idInt) : -1;
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        if (item.checked){
            if (!nuevaSeleccion.ints) nuevaSeleccion.ints = [];
            if (idx<0) nuevaSeleccion.ints.push(idInt);
        }else{
            if (idx>=0) nuevaSeleccion.ints.splice(idx,1);
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioUds = (nuevonumero) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.uds = nuevonumero;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioEmr = (nuevonumero) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.emr = nuevonumero;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioEdad = (nuevaedad, nuevaunidad) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.edad = nuevaedad;
        nuevaSeleccion.undEdad = nuevaunidad;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const UpdateEnfermedad = (idEnfermedad, epigrafe) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        var item = nuevaSeleccion.enfsRel.find(item => item.codigo===idEnfermedad);
        if (item){
            item.epi = epigrafe;
        }else{
            nuevaSeleccion.enfsRel.push({codigo: idEnfermedad, epi: epigrafe});
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveEnfermedad = (idEnfermedad) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.enfsRel = nuevaSeleccion.enfsRel.filter(item => item.codigo!==idEnfermedad);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const UpdateAnalisis = (idEnfermedad, epigrafe) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        var item = nuevaSeleccion.anasRel.find(item => item.codigo===idEnfermedad);
        if (item){
            item.epi = epigrafe;
        }else{
            nuevaSeleccion.anasRel.push({codigo: idEnfermedad, epi: epigrafe});
        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const RemoveAnalisis = (idEnfermedad) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.anasRel = nuevaSeleccion.anasRel.filter(item => item.codigo!==idEnfermedad);
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambioBusquedaTexto = (nuevoepi, nuevotexto, negado) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        nuevaSeleccion.idEpi = nuevoepi;
        nuevaSeleccion.texto = nuevotexto;
        nuevaSeleccion.noTexto = negado;
        setSeleccionFormulario(nuevaSeleccion);
    }

    const CambiaValorSNX = (valorSNX, idSNX) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        if (!nuevaSeleccion.ops)
            nuevaSeleccion.ops = [];
        var item = nuevaSeleccion.ops.find(it => it.cod===idSNX);
        if (item){
            if (valorSNX !== 'X'){
                item.valor = valorSNX;
            }else{
                var idx = nuevaSeleccion.ops.indexOf(item);
                nuevaSeleccion.ops.splice(idx,1);
            }
        }else{
            if (valorSNX !=='X'){
                nuevaSeleccion.ops.push({cod:idSNX, valor:valorSNX});
            }
        }
        setSeleccionFormulario(nuevaSeleccion);
    }


    const CambiaValorCHK = (valoresCHK,idCHK) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        if (!nuevaSeleccion.ops)
            nuevaSeleccion.ops = [];
        var item = nuevaSeleccion.ops.find(it => it.cod===idCHK);
        if (item){
            if (valoresCHK.length>0){
                item.valores = valoresCHK;
            }else{
                var idx = nuevaSeleccion.ops.indexOf(item);
                nuevaSeleccion.ops.splice(idx,1);
            }
        }else{
            if (valoresCHK.length>0){
                var newitem = {
                    cod: idCHK,
                    valores: valoresCHK,
                }
                nuevaSeleccion.ops.push(newitem);
            }            
        }
        setSeleccionFormulario(nuevaSeleccion);
    }




    const CambiaValorCheck = (idCheck, valorCheck, checked) =>{
        const nuevaSeleccion = JSON.parse(JSON.stringify(seleccionFormulario));
        copiaFechas(seleccionFormulario, nuevaSeleccion);
        if (!nuevaSeleccion.ops)
            nuevaSeleccion.ops = [];
        var item = nuevaSeleccion.ops.find(it => it.codigo===idCheck);
        if (item){
            if (!item.valores) item.valores = [];
            var idxV = item.valores.indexOf(valorCheck)
            if (idxV>0){
                if (!checked){
                    item.valores.splice(idxV,1);
                }
            }else{
                if(checked){
                    item.valores.push(valorCheck)
                }
            }
        }else{
            if (checked){
                var item = { codigo: idCheck, valores: [valorCheck]};
                nuevaSeleccion.ops.push(item);
            }

        }
        setSeleccionFormulario(nuevaSeleccion);
    }

    const listaCodValorLabs   = (datosFormulario.listaLabs) ? datosFormulario.listaLabs.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorGTs    = (datosFormulario.listaGTs)  ? datosFormulario.listaGTs.map(it =>  ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorPAs    = (datosFormulario.listaPAs) ? datosFormulario.listaPAs.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorFFs    = (datosFormulario.listaFFs) ? datosFormulario.listaFFs.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorVias   = (datosFormulario.listaVias) ? datosFormulario.listaVias.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorEnfs   = (datosFormulario.listaEnfs) ? datosFormulario.listaEnfs.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorAnas   = (datosFormulario.listaAnas) ? datosFormulario.listaAnas.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorCPs    = (datosFormulario.listaCamposPrecio) ? datosFormulario.listaCamposPrecio.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorOPs    = (datosFormulario.listaOperadoresPrecio) ? datosFormulario.listaOperadoresPrecio.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorPPs    = (datosFormulario.listaPredefinidasPrecio) ? datosFormulario.listaPredefinidasPrecio.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorAAs    = (datosFormulario.listaAgrupAuto)  ? datosFormulario.listaAgrupAuto.map(it =>  ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorANs    = (datosFormulario.listaAgrupNac)   ? datosFormulario.listaAgrupNac.map (it =>  ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorDCs    = (datosFormulario.listaDescrip)  ? datosFormulario.listaDescrip.map(it =>  ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorMens   = (datosFormulario.listaMens) ? datosFormulario.listaMens.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];
    const listaCodValorEpis   = (datosFormulario.listaEpis) ? datosFormulario.listaEpis.map(it => ({codigo: it.Codigo, valor: it.Valor})) : [];

    const HTMLIntoleranciasV = (((datosFormulario.listaIntsV) && (datosFormulario.listaIntsV.length>0)) ?
        datosFormulario.listaIntsV.map(int => 
            <label>
                <input type="checkbox" value={int.Codigo} checked = {(seleccionFormulario.ints && seleccionFormulario.ints.indexOf(int.Codigo)>=0)} onClick={(e) => CambioIntolerancia(e, int.Codigo)} />
                {upperCaseFirstLetter(int.Valor)}
            </label>
        )
    :
        <div></div>
    );
    const HTMLIntoleranciasNV = (((datosFormulario.listaIntsNV) && (datosFormulario.listaIntsNV.length>0)) ?
        datosFormulario.listaIntsNV.map(int => 
            <label>
                <input type="checkbox" value={int.Codigo} checked = {(seleccionFormulario.ints && seleccionFormulario.ints.indexOf(int.Codigo)>=0)} onClick={(e) => CambioIntolerancia(e, int.Codigo)} />
                {upperCaseFirstLetter(int.Valor)}
            </label>
        )
    :
        <div></div>
    );
    const clasedivmodulefilters = "cover cover__filters cover__filters__2 cover__filters__2--1 cover__filters__2--1__50";

    const HTMLOpcionesDG = OpcionesDG.map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );

    const HTMLOpcionesDISP1 = OpcionesDISP.slice(0,4).map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );

    const HTMLOpcionesDISP2 = OpcionesDISP.slice(4,8).map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );

    const HTMLOpcionesDISP3 = OpcionesDISP.slice(8,12).map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );

    const HTMLOpcionesDISP4 = OpcionesDISP.slice(12,16).map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );

    const HTMLOpcionesDISP5 = OpcionesDISP.slice(16).map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );

    const HTMLOpcionesPAC = OpcionesPAC.map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );

    const HTMLOpcionesADM = OpcionesADM.map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );

    const HTMLOpcionesESP = OpcionesESP.slice(0,4).map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );

    const HTMLOpcionesESP2 = OpcionesESP.slice(4).map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );

    const HTMLOpcionesOTR1 = OpcionesOTR.slice(0,4).map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );

    const HTMLOpcionesOTR2 = OpcionesOTR.slice(4).map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );

    const HTMLOpcionesCONV = OpcionesCON.map(opt => 
        (opt.tip === "CHK") ?
            <SelectorCHK
                valoresCHK = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valores : [])}
                opcionesCHK = {opt.ops}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorCHK}
            />
        : (opt.tip === "SNX") ?
                <SelectorSNX
                valorSNX = {(seleccionFormulario.ops.find(it => it.cod===opt.cod) ? seleccionFormulario.ops.find(it => it.cod===opt.cod).valor : 'X')}
                idHTML = {"FBAvanzadaSNX"+ opt.cod}
                titulo = {opt.tit}
                idOrigen = {opt.cod}
                alCambiar = {CambiaValorSNX}
            />
        : <div></div>
    );

    const ancho = window.innerWidth;
    const isMobile = (ancho<768);

    const estiloLab = (isMobile) ? {} : {width: '50%'} ;
    const estiloFF = (isMobile) ? {} : {width: '40%'} ;
    const estiloVia = (isMobile) ? {} : {width: '25%'} ;


    const KeyDownForm = (e) =>{
        var keyCode = e.keyCode;
        if (keyCode === 13){
            clickBuscar(e);
        }
    }



    const HTMLDatosGenContenido = (
        <div>
            <div class="cover cover__filters cover__filters__code" >
                <div class="coverModule">
                    <h3 class="title fs fs16 greyColor2">Nombre</h3>
                    <div class="module module__filters">
                        <div class="box box__inputs">
                            <div class="cover">
                                <input type="text" name="" value={seleccionFormulario.txt} placeholder="Nombre del medicamento"   onChange={(e) => CambiaTxt(e)}  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class={clasedivmodulefilters} >
                <div class="module module__filters" style={estiloLab}>
                    <h3 class="title fs fs16 greyColor2" >Laboratorio</h3>
                    <SelectorMulti2 lista = {listaCodValorLabs} 
                                idHTML="FBAvanzadaMUHLLabs" 
                                valuesSelected={seleccionFormulario.idsLab}
                                alAnadir={AddLaboratorio} 
                                alBorrar={RemoveLaboratorio} 
                                ancho="100%"
                    ></SelectorMulti2>
                </div>
                <div class="module module__filters"  style={{width: '50%'}}>
                    <h3 class="title fs fs16 greyColor2" >Grupo terapéutico ATC</h3>
                    <SelectorMulti2 lista = {listaCodValorGTs} 
                                idHTML="FBAvanzadaMUHLGTs" 
                                valuesSelected={seleccionFormulario.idsGT} 
                                alAnadir={AddGrupo} 
                                alBorrar={RemoveGrupo} 
                                ancho="100%"
                                idEsStr={true}
                    ></SelectorMulti2>
                </div>
            </div>
            <div class={clasedivmodulefilters} >
                <div class="module module__selects" style={estiloLab}>
                    <h3 class="title fs fs16 greyColor2" style={{marginBottom:'12px'}}>Forma farmacéutica</h3>
                    <SelectorComboBox lista = {listaCodValorFFs} 
                            idHTML="FBAvanzadaFF" 
                            valueSelected={seleccionFormulario.idFF} 
                            alCambiar={CambioFF} 
                            ancho="100%"
                            maxLengthNormal = {30}
                    ></SelectorComboBox>
                </div>
                <div class="module module__filters" style={estiloLab}>
                    <h3 class="title fs fs16 greyColor2"  style={{marginBottom:'12px'}}>Vía de administración</h3>
                    <SelectorComboBox lista = {listaCodValorVias} 
                            idHTML="FBAvanzadaVia" 
                            valueSelected={seleccionFormulario.idVia} 
                            alCambiar={CambioVia} 
                            ancho="100%"
                    ></SelectorComboBox>
                </div>
            </div>
            <div class="cover cover__filters cover__filters__4" >
                <SelectorNumero 
                            alCambiar={CambioUds} 
                            num={seleccionFormulario.uds} 
                            valorVacio={0}
                            titulo = "Unidades por envase"
                ></SelectorNumero>
                <SelectorNumero 
                            alCambiar={CambioEmr} 
                            num={seleccionFormulario.emr} 
                            valorVacio={0}
                            titulo = "Envases máx. por receta"
                ></SelectorNumero>
            </div>
            <div class="cover cover__filters cover__filters__4" >
                {HTMLOpcionesDG}
            </div>
        </div>
    );
    const HTMLFechasContenido = (
        <div>
            <div class={clasedivmodulefilters} >
                <SelectorFechas 
                            titulo="Fecha de autorización" 
                            alCambiar={CambioFechaAuto} 
                            inicio={seleccionFormulario.fechaAutoIni} 
                            fin={seleccionFormulario.fechaAutoFin}
                            idHTML = "FBAH_FAuto"
                            subtitulodesde = "Posterior a"
                            subtitulohasta = "Anterior a"
                ></SelectorFechas>
                <SelectorFechas 
                            titulo="Fecha de inclusión SNS" 
                            alCambiar={CambioFechaInSNS} 
                            inicio={seleccionFormulario.fechaInSNSIni} 
                            fin={seleccionFormulario.fechaInSNSFin}
                            idHTML = "FBAH_FInSNS"
                            subtitulodesde = "Posterior a"
                            subtitulohasta = "Anterior a"
                ></SelectorFechas>
            </div>
            <div class={clasedivmodulefilters} >
                <SelectorFechas 
                            titulo="Fecha de comercialización" 
                            alCambiar={CambioFechaComer} 
                            inicio={seleccionFormulario.fechaComerIni} 
                            fin={seleccionFormulario.fechaComerFin}
                            idHTML = "FBAH_FComer"
                            subtitulodesde = "Posterior a"
                            subtitulohasta = "Anterior a"
                ></SelectorFechas>
                <SelectorFechas 
                            titulo="Fecha de baja" 
                            alCambiar={CambioFechaBaja} 
                            inicio={seleccionFormulario.fechaBajaIni} 
                            fin={seleccionFormulario.fechaBajaFin}
                            idHTML = "FBAH_FBaja"
                            subtitulodesde = "Posterior a"
                            subtitulohasta = "Anterior a"
                ></SelectorFechas>
            </div>
            <div class={clasedivmodulefilters} >
                <SelectorFechas 
                            titulo="Fecha de exclusión" 
                            alCambiar={CambioFechaExSNS} 
                            inicio={seleccionFormulario.fechaExSNSIni} 
                            fin={seleccionFormulario.fechaExSNSFin}
                            idHTML = "FBAH_FExSNS"
                            subtitulodesde = "Posterior a"
                            subtitulohasta = "Anterior a"
                ></SelectorFechas>
                <SelectorFechas 
                            titulo="Fecha de exclusión financiación individualizada" 
                            alCambiar={CambioFechaExFI} 
                            inicio={seleccionFormulario.fechaExFIIni} 
                            fin={seleccionFormulario.fechaExFIFin}
                            idHTML = "FBAH_FExFI"
                            subtitulodesde = "Posterior a"
                            subtitulohasta = "Anterior a"
                ></SelectorFechas>
            </div>
        </div>
    );

    const HTMLPrecioContenido = (
        <div>
            <div class="cover cover__filters cover__filters__4" >
                <div class="module module__selects">
                    <h3 class="title fs fs16 greyColor2" style={{marginBottom:'12px'}}>Precio</h3>
                    <SelectorComboBox lista = {listaCodValorCPs} 
                            idHTML="FBAvanzadaCP" 
                            valueSelected={seleccionFormulario.idCampoP} 
                            alCambiar={CambioCampoPrecio} 
                            ancho="100%"
                            idEsStr={true}
                            maxLengthNormal = {30}
                    ></SelectorComboBox>
                </div>
                <div class="module module__filters">
                    <h3 class="title fs fs16 greyColor2"  style={{marginBottom:'12px'}}>&nbsp;</h3>
                    <SelectorComboBox lista = {listaCodValorOPs} 
                            idHTML="FBAvanzadaOP" 
                            valueSelected={seleccionFormulario.idOpP} 
                            alCambiar={CambioOperadorPrecio} 
                            ancho="100%"
                    ></SelectorComboBox>
                </div>
                <SelectorNumero 
                            alCambiar={CambioPrecio} 
                            num={seleccionFormulario.pre} 
                            valorVacio={0}
                            titulo = "&nbsp;"
                            sufijo = "€"
                ></SelectorNumero> 
            </div>
            <div class={clasedivmodulefilters} >
                <div class="module module__selects" style={estiloLab}>
                    <h3 class="title fs fs16 greyColor2" style={{marginBottom:'12px'}}>Comparativa con precio menor/más bajo</h3>
                    <SelectorComboBox lista = {listaCodValorPPs} 
                            idHTML="FBAvanzadaPP" 
                            valueSelected={seleccionFormulario.idPredefP} 
                            alCambiar={CambioPredefinidaPrecio} 
                            ancho="100%"
                            maxLengthNormal = {50}
                    ></SelectorComboBox>
                </div>
            </div>
        </div>
    );

    const HTMLGruposContenido = (
        <div>
            <div class={clasedivmodulefilters} >
                <div class="module module__filters" style={estiloLab}>
                    <h3 class="title fs fs16 greyColor2" >Agrupación homogénea nacional</h3>
                    <SelectorMulti2 lista = {listaCodValorANs} 
                                idHTML="FBAvanzadaMUHLANs" 
                                valuesSelected={seleccionFormulario.idsAN}
                                alAnadir={AddAgrupNac} 
                                alBorrar={RemoveAgrupNac} 
                                ancho="100%"
                                idEsStr={true}
                    ></SelectorMulti2>
                </div>
                <div class="module module__filters"  style={{width: '50%'}}>
                    <h3 class="title fs fs16 greyColor2" >Agrupación homogénea autonómica</h3>
                    <SelectorMulti2 lista = {listaCodValorAAs} 
                                idHTML="FBAvanzadaMUHLAAs" 
                                valuesSelected={seleccionFormulario.idsAA} 
                                alAnadir={AddAgrupAuto} 
                                alBorrar={RemoveAgrupAuto} 
                                ancho="100%"
                                idEsStr={true}
                    ></SelectorMulti2>
                </div>
            </div>
            <div class={clasedivmodulefilters} >
                <div class="module module__filters" style={estiloLab}>
                    <h3 class="title fs fs16 greyColor2" >Descripción clínica AEMPS</h3>
                    <SelectorMulti2 lista = {listaCodValorDCs} 
                                idHTML="FBAvanzadaMUHLDescAEMPS" 
                                valuesSelected={seleccionFormulario.idsDC}
                                alAnadir={AddDescripC} 
                                alBorrar={RemoveDescripC} 
                                ancho="100%"
                                idEsStr={true}
                    ></SelectorMulti2>
                </div>
            </div>
        </div>
    );

    const HTMLDatosCompos = (
            <SelectorComposicion lista = {listaCodValorPAs} 
                idHTML="FBAvanzadaCompos" 
                valuesSelected={seleccionFormulario.pas} 
                pasolo={seleccionFormulario.solo}
                alAnadirPA={AddPA} 
                alBorrarPA={RemovePA} 
                alUpdatePA={UpdatePA}
                alAnadirSal={AddSal} 
                alBorrarSal={RemoveSal} 
                alAnadirCant={AddCant} 
                alBorrarCant={RemoveCant} 
                alCambiarPASolo={CambiaPASolo}
                ancho="100%"
                titulo={""}
                muestraChecks={true}
                nombreComponentes = {"principios activos"}
                tipo={"H"}
            ></SelectorComposicion>
    );

    const HTMLDatosDisp = (
        <div>
            <div class="cover cover__filters cover__filters__4" >
                {HTMLOpcionesDISP1}
            </div>
            <div class="cover cover__filters cover__filters__4" >
                {HTMLOpcionesDISP2}
            </div>
            <div class="cover cover__filters cover__filters__4" >
                {HTMLOpcionesDISP3}
            </div>
            <div class="cover cover__filters cover__filters__4" >
                {HTMLOpcionesDISP4}
            </div>
            <div class="cover cover__filters cover__filters__4" >
                {HTMLOpcionesDISP5}
            </div>
        </div>
    );

    const HTMLDatosPac = (
        <div>
            <div class="cover cover__filters cover__filters__4 cover__filters__padTop cover__filters__4__3" >
                {HTMLOpcionesPAC}
                <div class="module module__filters module__units" >
                    <div class="checks checks__title" >
                            <label>Niños</label>
                    </div>
                    <SelectorEdad 
                        edad={seleccionFormulario.edad} 
                        unidad={seleccionFormulario.undEdad} 
                        alCambiar={CambioEdad
                    }></SelectorEdad>
                </div>
            </div>
            <div class="cover cover__filters cover__filters__checks" >
                <div class="module module__checks"style={{width:'100%'}}>
                    <h3 class="fs fs16 greyColor2 title" >Intolerancias</h3>
                    <div class="checks" >
                        <div class="cover" >
                            {HTMLIntoleranciasV}
                        </div>
                        <div class={claseIntoleranciasOcultas} id="IntoleranciasOcultas">
                            {HTMLIntoleranciasNV}
                        </div>
                    </div>
                </div>
            </div>
            <div class="module module__buttons module__buttons__buscar">
                <span class={claseBotonIntolerancias}   onClick={(e) => clickVerIntolerancias(e)}>{txtbotonIntolerancias} <img src="./img/arrowPurpleDown2.svg" alt="arrowPurpleDown2" /></span>
            </div>
        </div>
    );

    const HTMLDatosAdm = (
        <div class="cover cover__filters cover__filters__4 cover__filters__padTop cover__filters__4__2" >
            {HTMLOpcionesADM}
        </div>
    );

    const HTMLDatosEsp = (
        <div>
        <div class="cover cover__filters cover__filters__4" >
            {HTMLOpcionesESP}
        </div>
        <div class="cover cover__filters cover__filters__4" >
            {HTMLOpcionesESP2}
        </div>
        </div>
);

    const HTMLDatosOtr = (
        <div>
            <div class="cover cover__filters cover__filters__4" >
                {HTMLOpcionesOTR1}
            </div>
            <div class="cover cover__filters cover__filters__4" >
                {HTMLOpcionesOTR2}
            </div>
        </div>
    );

    const HTMLDatosCon = (
        <div class="cover cover__filters cover__filters__4" >
            {HTMLOpcionesCONV}
        </div>
    );

    const HTMLAdvertenciasContenido = (
        <div>
            <div class="cover cover__filters cover__filters__4" >
                {HTMLOpcionesESP}
            </div>
            <div class="cover cover__filters cover__filters__4" >
                {HTMLOpcionesESP2}
            </div>
            <div className="cover cover__filters" >
                <SelectorMulti2No lista = {listaCodValorMens} 
                            idHTML="FBAvanzadaMUHMAs" 
                            valuesSelected={seleccionFormulario.idsMen}
                            alAnadir={AddAdvertencia} 
                            alBorrar={RemoveAdvertencia}
                            alNegar={NiegaAdvertencia}
                            titulo={"Otras advertencias"} 
                            ancho="100%"
                            maxLengthNormal = {85}
                ></SelectorMulti2No>
            </div>
        </div>
    );

    const HTMLDatosEnf = (
        <SelectorEnfRel lista = {listaCodValorEnfs} 
            idHTML="FBAvanzadaMUH_EnfRel" 
            valuesSelected={seleccionFormulario.enfsRel} 
            alUpdate={UpdateEnfermedad} 
            alBorrar={RemoveEnfermedad} 
            ancho="100%"
        ></SelectorEnfRel>
    );

    const HTMLDatosAna = (
        <SelectorAnaRel lista = {listaCodValorAnas} 
            idHTML="FBAvanzadaMUH_AnaRel" 
            valuesSelected={seleccionFormulario.anasRel} 
            alUpdate={UpdateAnalisis} 
            alBorrar={RemoveAnalisis} 
            ancho="100%"
        ></SelectorAnaRel>
    );

    const HTMLDatosBusquedaTexto = (
        <SelectorTexto listaEpigrafes = {listaCodValorEpis} 
            idHTML="FBAvanzadaMUH_BTexto" 
            texto = {seleccionFormulario.texto}
            idEpi = {seleccionFormulario.idEpi}
            negado = {seleccionFormulario.noTexto}
            placeholder = "Introduzca el texto a buscar"
            tituloTexto = "Términos a buscar"
            tituloEpigrafe = "Buscar en"
            alCambiar={CambioBusquedaTexto} 
            ancho="100%"
        ></SelectorTexto>
    );

    const estiloBotonesSup = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'space-between'} : {height:'76px', width:'100%', justifyContent: 'space-between', visibility:'hidden'})
    const estiloBotonesInf = (hayBusqueda ? {height:'76px', width:'100%', justifyContent: 'center'} : {height:'76px', width:'100%', justifyContent: 'center', display:'none'})


    return(
        <div class="content" >
            { (datosFormulario.listaPAs === null) ?
                <LoadingPopup titulo="Un momento" texto="Preparando formulario" esPorcentaje={true}></LoadingPopup>
            :
                <form class="content__results content__results__filters" id="main-filter"  onSubmit={(e) => e.preventDefault()}  onKeyDown={(e) => KeyDownForm(e)} tabIndex="0">
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesSup}>
                        <span class="button button__arrow button__green noUppercase reset-form" data-form="#main-filter" title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} style={{visibility:'hidden'}} >
                            Vaciar búsqueda
                        </span>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                        <span class="button button__arrow button__green reset-form" data-form="#main-filter" style={{textTransform:'none', fontSize: '14px', width:'146px'}} title="Vaciar búsqueda"  onClick={(e) => BorraBusqueda(e)} >
                            Vaciar búsqueda
                        </span>
                    </div>
                    <div class="column">
                        <Acordeon 
                            activo={true}
                            titulo = "DATOS GENERALES"
                            contenido = {HTMLDatosGenContenido}
                            tipo = {3}
                        />

                        <Acordeon 
                            activo={false}
                            titulo = "FECHAS"
                            contenido = {HTMLFechasContenido}
                            tipo = {3}
                        />

                        <Acordeon 
                            activo={false}
                            titulo = "PRECIOS"
                            contenido = {HTMLPrecioContenido}
                            tipo = {3}
                        />

                        <Acordeon 
                            activo={false}
                            titulo = "MEDICAMENTOS CON IGUAL COMPOSICIÓN"
                            contenido = {HTMLGruposContenido}
                            tipo = {3}
                        />

                        <Acordeon 
                            activo={false}
                            titulo = "COMPOSICIÓN"
                            contenido = {HTMLDatosCompos}
                            tipo = {3}
                        />

                        <Acordeon 
                            activo={false}
                            titulo = "TIPO DE MEDICAMENTO"
                            contenido = {HTMLDatosOtr}
                            tipo = {3}
                        />

                        <Acordeon 
                            activo={false}
                            titulo = "CONDICIONES DE DISPENSACIÓN Y FINANCIACIÓN"
                            contenido = {HTMLDatosDisp}
                            tipo = {3}
                        />

                        <Acordeon 
                            activo={false}
                            titulo = "ADVERTENCIAS ESPECIALES"
                            contenido = {HTMLAdvertenciasContenido}
                            tipo = {3}
                        />

                        <Acordeon 
                            activo={false}
                            titulo = "CARACTERÍSTICAS DEL PACIENTE"
                            contenido = {HTMLDatosPac}
                            tipo = {3}
                        />

                        <Acordeon 
                            activo={false}
                            titulo = "CONDICIONES DE CONSERVACIÓN"
                            contenido = {HTMLDatosCon}
                            tipo = {3}
                        />

                        <Acordeon 
                            activo={false}
                            titulo = "ENFERMEDADES RELACIONADAS"
                            contenido = {HTMLDatosEnf}
                            tipo = {3}
                        />

                        <Acordeon 
                            activo={false}
                            titulo = "ANÁLISIS CLÍNICOS"
                            contenido = {HTMLDatosAna}
                            tipo = {3}
                        />

                        <Acordeon 
                            activo={false}
                            titulo = "TEXTO"
                            contenido = {HTMLDatosBusquedaTexto}
                            tipo = {3}
                        />

                    </div>
                    <div class="module module__buttons module__buttons__buscar" style={estiloBotonesInf}>
                        <button class="button button__arrow button__purple noUppercase" title="Buscar"  onClick={(e) => clickBuscar(e)} >Buscar</button>
                    </div>
                <ResumenBusqueda condiciones={resumen}></ResumenBusqueda>
                </form>
            }
        </div>
    );
}
    
export default FBAvanzadaMUH;
/*
                        <Acordeon 
                            activo={false}
                            titulo = "ADMINISTRACIÓN Y DOSIFICACIÓN"
                            contenido = {HTMLDatosAdm}
                            tipo = {3}
                        />
*/    