import DocumentosDeTipo from "../InfoAdicional/DocumentosDeTipo";
import ProblemaS from "./ProblemaS";

const ProblemasS = (props) => {
    const datosProblemasS = props.datosProblemasS;
    const htmlProblemas = datosProblemasS.Problemas.map(problema => <ProblemaS problema={problema}></ProblemaS>)
    const htmlInfoAdicional = datosProblemasS.InfoAdicional.map(grupodocs => <DocumentosDeTipo grupoDocs={grupodocs}></DocumentosDeTipo>)
    return (
        <div class="infoContent" >
            {htmlProblemas}
            {(datosProblemasS.InfoAdicional && datosProblemasS.InfoAdicional.length>0) ?
                <div class="cover__subTitle">
                    <h2 class="fs fs16 fw fw600 greenColor">Documentos relacionados</h2>
                </div>
            :
                <span></span>
            }
            {htmlInfoAdicional}
        </div>
    )
}
export default ProblemasS;


