const FilaNombrePA = (props) => {
    const nombre = props.nombre;
    const clasesDivNombre = 'column fs fs12 fw greenColor4';

    const CambiaPaginaPA = (idPA) => {
        props.cambiaPagina({
            newpagina: 'FichaPAH',
            newid: idPA,
        });
    }

    return (
        <div class="table__row table__row__normal fw fw300 uppercase">
            <div class={clasesDivNombre} style={{justifyContent:'left'}} >
                <p>
                    <span style={{cursor:'pointer'}} 
                        onClick={(e) => CambiaPaginaPA(nombre.IdPA)}
                        onMouseOver={(e) => e.target.style.fontWeight='bold'}
                        onMouseOut={(e) => e.target.style.fontWeight='normal'}
                    >
                        {nombre.NombrePA}
                    </span>
                </p>
            </div>
        </div>
    )
}
export default FilaNombrePA;
