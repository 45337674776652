
import { useEffect, useState } from "react";
import { rootPath } from "../../../../services/utils";
import RamaArbol3 from "./RamaArbol3";

function SelectorArbolM(props){
    const [datosSeleccion, setDatosSeleccion] = useState({
        valorSeleccionado: props.valorSeleccionado,
        texto: props.texto
    });
    const [listaCompleta, setListaCompleta] = useState(props.listaCompleta);
    const [listaFiltrada, setListaFiltrada] = useState([]);
    const [numMostrar, setNumMostrar] = useState(20);
    var bMostrarMas = (listaFiltrada.length>=numMostrar);

    const codigoJerarquico = props.codigoJerarquico;

    useEffect(() => {
        /*if ((props.valorSeleccionado && props.valorSeleccionado !== datosSeleccion.valorSeleccionado) 
        || (props.texto && props.texto !== datosSeleccion.texto)
        || (props.listaCompleta && props.listaCompleta != listaCompleta)){*/
            const nuevaSeleccion = JSON.parse(JSON.stringify(datosSeleccion));
            nuevaSeleccion.valorSeleccionado = props.valorSeleccionado ? props.valorSeleccionado : '';
            nuevaSeleccion.texto = props.texto;
            var nuevalistafiltrada = filtraLista(props.listaCompleta,props.valorSeleccionado, props.texto);
            setListaCompleta(props.listaCompleta);
            setListaFiltrada(nuevalistafiltrada);
            setDatosSeleccion(nuevaSeleccion);
        /*}*/
    },  [props.valorSeleccionado, props.texto, props.listaCompleta,numMostrar]);


    const filtraLista = (lista, valor, texto) =>{
        if (valor && valor != ''){
            return filtraValorSeleccionado(lista, valor);
        }else{
            return filtraListaConTexto(lista, texto);
        }
    }

    const filtraListaConTexto = (lista, texto) =>{

        const nuevalista = [];
        const pendientes = [];
        pendientes.push(lista);
        var currentlista = pendientes.pop();
        var listaLlena = false;
        while (((currentlista && currentlista.length>0) || (pendientes && pendientes.length>0)) && !listaLlena){
            if (currentlista && currentlista.length>0){
                for (var i=0; i<currentlista.length && !listaLlena; i++){
                    if (itemContieneTxt(currentlista[i],texto)){
                        var newitem = {
                            codigo: currentlista[i].Codigo,
                            valor: currentlista[i].Valor,
                            abierto: false,
                            seleccionado: false,
                            tieneHijos: (currentlista[i].hijos && currentlista[i].hijos.length>0),
                        }
                        nuevalista.push(newitem);
                        listaLlena = nuevalista.length>=numMostrar;
                    }
                    else{
                        pendientes.push(currentlista[i].hijos);
                    }
                }
            }
            if (!listaLlena)
                currentlista = pendientes.pop();
        }
        bMostrarMas = listaLlena;
        return nuevalista;
    }

    const filtraValorSeleccionado = (lista, seleccion) =>{
        const nuevalista = [];
        const pendientes = [];
        pendientes.push(lista);
        var currentlista = pendientes.pop();
        var encontrado = false;
        while (((currentlista && currentlista.length>0) || (pendientes && pendientes.length>0)) && !encontrado){
            if (currentlista && currentlista.length>0){
                for (var i=0; i<currentlista.length && !encontrado; i++){
                    if (currentlista[i].Codigo === seleccion){
                        var newitem = {
                            codigo: currentlista[i].Codigo,
                            valor: currentlista[i].Valor,
                            abierto: false,
                            seleccionado: true,
                            tieneHijos: (currentlista[i].hijos && currentlista[i].hijos.length>0),
                        }
                        nuevalista.push(newitem);
                        encontrado=true;
                    }
                    else{
                        pendientes.push(currentlista[i].hijos);
                    }
                }
            }
            if (!encontrado)
                currentlista = pendientes.pop();
        }
        bMostrarMas = false;
        return nuevalista;
    }

    const abreRama = (codigoRama) =>{
        const nuevaListaFiltrada = JSON.parse(JSON.stringify(listaFiltrada));
        var pendientes = [];
        pendientes.push(nuevaListaFiltrada);
        var currentlista = pendientes.pop();
        var encontrado = false;
        var itemListaFiltrada = null;
        while (currentlista && currentlista.length>0 && !encontrado){
            for (var i=0; i<currentlista.length && !encontrado; i++){
                if (currentlista[i].codigo === codigoRama){
                    itemListaFiltrada = currentlista[i];
                    encontrado=true;
                }else{
                    if (currentlista[i].hijos && currentlista[i].hijos.length>0){
                        pendientes.push(currentlista[i].hijos);
                    }
                }
            }
            if (!encontrado)
                currentlista = pendientes.pop();
        }
        pendientes = [];
        pendientes.push(listaCompleta);
        var currentlista = pendientes.pop();
        var encontrado = false;
        var itemListaCompleta = null;
        while (currentlista && currentlista.length>0 && !encontrado){
            for (var i=0; i<currentlista.length && !encontrado; i++){
                if (currentlista[i].Codigo === codigoRama){
                    itemListaCompleta = currentlista[i];
                    encontrado=true;
                }else{
                    if (currentlista[i].hijos && currentlista[i].hijos.length>0){
                        if (codigoJerarquico){
                            if (EsCodigoPadre(currentlista[i].Codigo,codigoRama)){
                                pendientes.push(currentlista[i].hijos);        
                            }
                        }else{
                            pendientes.push(currentlista[i].hijos);
                        }                        
                    }
                }
            }
            if (!encontrado)
                currentlista = pendientes.pop();
        }
        if (itemListaFiltrada && itemListaCompleta){
            itemListaFiltrada.hijos = [];
            if (itemListaCompleta.hijos && itemListaCompleta.hijos.length>0){
                for (var i=0; i<itemListaCompleta.hijos.length; i++){
                    var newitem = {
                        codigo: itemListaCompleta.hijos[i].Codigo,
                        valor: itemListaCompleta.hijos[i].Valor,
                        abierto: false,
                        seleccionado: false,
                        tieneHijos: (itemListaCompleta.hijos[i].hijos && itemListaCompleta.hijos[i].hijos.length>0),
                    }
                    itemListaFiltrada.hijos.push(newitem);
                }
                itemListaFiltrada.abierto = true;
            }
        }
        setListaFiltrada(nuevaListaFiltrada);
    }

    const cierraRama = (codigoRama) =>{
        const nuevaListaFiltrada = JSON.parse(JSON.stringify(listaFiltrada));
        const pendientes = [];
        pendientes.push(nuevaListaFiltrada);
        var currentlista = pendientes.pop();
        var encontrado = false;
        var itemListaFiltrada = null;
        while (currentlista && currentlista.length>0 && !encontrado){
            for (var i=0; i<currentlista.length && !encontrado; i++){
                if (currentlista[i].codigo === codigoRama){
                    itemListaFiltrada = currentlista[i];
                    encontrado=true;
                }else{
                    if (currentlista[i].hijos && currentlista[i].hijos.length>0){
                        pendientes.push(currentlista[i].hijos);
                    }
                }
            }
            if (!encontrado)
                currentlista = pendientes.pop();
        }
        if (itemListaFiltrada){
            itemListaFiltrada.hijos = [];
            itemListaFiltrada.abierto = false;
        }
        setListaFiltrada(nuevaListaFiltrada);
    }

    const itemContieneTxt = (item, txt) =>{
        txt = txt.replace('/',' ').replace(',', ' ').replace('\\',' ').replace('-',' ');
        var textoSeparado = txt.split(' ');
        var res = true;
        for (var i=0; i<textoSeparado.length; i++){
            res = res && itemContienePalabra(item, textoSeparado[i]);
        }
        return res;
    }

    const itemContienePalabra = (item, txt) =>{
        if (txt){
            txt = txt.trim().toLowerCase();
            var txt2 = ' ' + txt;
            return ((item.Codigo.toLowerCase().indexOf(txt)===0) || (item.Valor.toLowerCase().indexOf(txt)===0) || (item.Valor.toLowerCase().indexOf(txt2)>=0))
        }else return true;
    }

    const EsCodigoPadre = (padre, hijo) =>{
        if ((hijo) && (hijo !== "")){
            if (padre && padre !== ""){
                var res = (hijo.indexOf(padre)===0);
                if (!res){
                    res = (padre === "0" 
                        &&(hijo.indexOf("1")===0
                            || hijo.indexOf("2")===0
                            || hijo.indexOf("3")===0
                            || hijo.indexOf("4")===0
                        )
                    );
                }
                return res;
            }else return false;
        }else return false;
    }

    const cambiaTexto = (newtxt) =>{
        const nuevosDatosSeleccion = JSON.parse(JSON.stringify(datosSeleccion));
        nuevosDatosSeleccion.texto = newtxt;
        var nuevalistafiltrada = filtraListaConTexto(listaCompleta,newtxt);
        setListaFiltrada(nuevalistafiltrada);
        setDatosSeleccion(nuevosDatosSeleccion);    
        props.cambiaTxt(newtxt);    
    }

    const cambiaSeleccion = (checked, codigo, valor) =>{
        const nuevosDatosSeleccion = JSON.parse(JSON.stringify(datosSeleccion));
        if (checked)
            nuevosDatosSeleccion.valorSeleccionado = valor;
        else
            nuevosDatosSeleccion.valorSeleccionado = '';
        setDatosSeleccion(nuevosDatosSeleccion);
        if (checked)
            props.alCambiar(codigo,valor);
        else
            props.alCambiar('','');
    }

    const muestraMas = () =>{
        setNumMostrar(numMostrar + 20);
    }

    const arbolHTML = <RamaArbol3 arbol = {listaFiltrada} abreRama = {abreRama} cierraRama = {cierraRama} cambiaSeleccion = {cambiaSeleccion} muestraCodigo = {props.muestraCodigo}></RamaArbol3>

    return (
        <div> 
            <div class="custom-select-container search style-radios target" >
                <img class="arrow imagenTagEliminar" src="./img/close-tag.svg" id="imgBorrar" alt="eliminar" style={{display:'none'}} />
                <div class="cover cover__search">
                    <img class="lupa" src="./img/ficha/lupaPurple.svg" />
                    <input type="text" placeholder={props.placeholder} value = {datosSeleccion.texto} className="inputSelector" onChange={(e) => cambiaTexto(e.target.value)} style={{maxWidth:'600px'}}/>                        
                </div>
            </div>
            {arbolHTML}
            <div class="content__results__filters">
            <div class="module module__buttons module__buttons__buscar">
                {bMostrarMas ?(
                    <span class="button button__arrow button__purple__line noUppercase verMas" title="Ver más"  onClick={() => muestraMas()} style={{cursor:'pointer'}}>
                        Ver más <img src={rootPath + "img/arrowPurpleDown2.svg"} alt="arrowPurpleDown2" />
                    </span>
                ):(
                    <div></div>
                )}                            
            </div>
            </div>
        </div>
    )
}
export default SelectorArbolM;
