import { useEffect, useState } from "react";
import { AddClassNameToChildClassItems, buscaOptionEnSelectByValue, createElementFromHTML
    , deepEqual, elementIsOfClass, ExistsClassNameChildClassItems, GetAntecesorWhithClassName
    , GetLastSucesorWhithClassName, getSelectValues, GetSucesorWhithClassName
    , primerHijoConClase, RemoveClassNameToChildClassItems, scrollSiNoVisible } from "../../../services/utils";

function SelectorUno(props){
    const [propiedades, setPropiedades] = useState({
        lista: props.lista,
        valueSelected: props.valueSelected,
    })
    const lista = propiedades.lista;
    const valueSelected = propiedades.valueSelected;
    const idHTML = props.idHTML; 
    const optionsHTML = propiedades.lista.map(item => (item.codigo === propiedades.valueSelected ? 
        <option selected value={item.codigo}>{item.valor}</option>
        :<option value={item.codigo}>{item.valor}</option> )
    );

    const estiloModuleSelect = (props.ancho ? {width: props.ancho} : {});

    const optionsDisplayed = lista.slice(1).map(item => (
        item.codigo===valueSelected ?
            <label class="labeloption active" data-value={item.codigo} onClick={(e) => ClickEnOpcion(e)}>{item.valor}</label>
            :<label class="labeloption" data-value={item.codigo} onClick={(e) => ClickEnOpcion(e)}>{item.valor}</label>
        ));

    const tipoFiltradoLista = (props.tipoFiltradoLista) ? props.tipoFiltradoLista : 1;

    const clasesCoverSelect = (props.disabled ? "cover cover__select cover__select cover__select__border disabled" : "cover cover__select cover__select cover__select__border");

    var textSelected = '';

    const textPlaceholder = (lista && lista.length>0) ? lista[0].valor : '';

    if (lista)
        if (lista.length>0)
            textSelected = lista[0].valor;
    
    for (var item of lista){
        if (item.codigo===valueSelected)
            textSelected = item.valor;
    }
    
    const HTMLImagen = <img class="arrow imagenTagEliminar" src="./img/close-tag.svg" id="imgBorrar" alt="eliminar" onClick={(e) => BorraItem(e) } />
    const HTMLTagSelected = (valueSelected) ? <span class="tag" data-value={valueSelected}>
                                                    {textSelected}
                                                    {HTMLImagen}
                                                </span> : <span></span>;
 
    useEffect(() => {
        if (!deepEqual(props.lista, propiedades.lista) || (props.valueSelected !== valueSelected)){
            setPropiedades({
                lista: props.lista,
                valueSelected: props.valueSelected,
            })
        }
        
    },  [props.lista, props.valueSelected]);


    const ClickEnCoverSelect  = (e) =>{
        if (!props.disabled){
            const item = e.target;        
            const itemContainer = GetAntecesorWhithClassName(item, 'custom-select-container');
            itemContainer.classList.toggle('active');
        }
    }

    const ClickEnOpcion  = (e) =>{
    	var option = e.target;
        SeleccionaOpcion(option);
    }

    const SeleccionaOpcion = (option) =>{
        var value = option.getAttribute("data-value");
        var html = option.innerHTML;

        var container = GetAntecesorWhithClassName(option, "custom-select-container");
        var select = primerHijoConClase(container,'custom-select');
        var input = GetSucesorWhithClassName(container, 'inputSelector');

        container.classList.remove("active");
        select.value=value;

        RemoveClassNameToChildClassItems(container, 'dropdown__radios', 'labeloption', 'active');
        RemoveClassNameToChildClassItems(container, 'dropdown__radios', 'labeloption', 'preselected');
        RemoveClassNameToChildClassItems(container, 'dropdown__radios', 'labeloption', 'hide');
        option.classList.add("active");

        if (input){
            input.value='';
            textSelected = html;
        }

        if(!(ExistsClassNameChildClassItems(container,'dropdown','labeloption','hide') || ExistsClassNameChildClassItems(container,'dropdown','labeloption','active')))
            RemoveClassNameToChildClassItems(container,'dropdown',null,'show');


        if (!props.idEsStr)
            value = parseInt(value);
        if (props.alCambiar){
            props.alCambiar(value);
        }
           
    }

    const remove_accents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } 
  
    const KeyUpInput = (e) =>{
		var input = e.target;
        const container = GetAntecesorWhithClassName(input, 'custom-select-container');
        var texto = input.value;
        FiltraOpciones(container,texto);
    }

    const FiltraOpciones = (container, texto) =>{
        var search = remove_accents(texto.toLowerCase());
        var search2 = " " + search;

    	var found = false;

    	if(search != ""){
            var labeloptions = container.getElementsByClassName('labeloption');
            for (var elemento of labeloptions) {
	    		var label = elemento;
	    		var html = remove_accents(label.innerHTML.toLowerCase());
                var strlabel = label.getAttribute('data-value').toString();
                var bMostrar = false;
                var textoDividido = search.split(' ');
                if (textoDividido.length === 1){
                    switch (tipoFiltradoLista){
                        case 0:
                            bMostrar = ((html.indexOf(search) === 0));
                            break;
                        case 1: 
                            bMostrar = ((html.indexOf(search) === 0) || (html.indexOf(search2)>0));
                            break;
                        case 2:
                            bMostrar = ((html.indexOf(search) === 0) || (strlabel.indexOf(search) === 0));
                            break;
                        case 3:
                            bMostrar = ((html.indexOf(search) === 0) || (strlabel.indexOf(search) === 0) || (html.indexOf(search2)>0));
                            break;
                    }
                }else{
                    bMostrar = true;
                    for(var i=0; i<textoDividido.length && bMostrar; i++){
                        bMostrar = ((html.indexOf(textoDividido[i]) === 0) || (html.replace('/',' ').replace('\\',' ').indexOf(' ' + textoDividido[i])>0));
                    }
                }
	    		if(bMostrar){
	    			found = true;
					label.classList.remove("hide");
				}else{
					label.classList.add("hide");
				}
            }    
    	}
    	

    	if(found){
            AddClassNameToChildClassItems(container,'dropdown',null,'show');
            var ddowns = container.getElementsByClassName('ddown');
            for (var elemento of ddowns) {
                var hijos = elemento.children;
                var hijosNiOcultosNiActivos = false;
                for (var hijo of hijos){
                    if (elementIsOfClass('labeloption')){
                        if (!elementIsOfClass('hide') && !elementIsOfClass('active')){
                            hijosNiOcultosNiActivos = true;
                        }
                    }
                }
                if (!hijosNiOcultosNiActivos){
                    elemento.classList.remove('show');
                }
            }
    	}else{
            container.classList.remove('active');
            //RemoveClassNameToChildClassItems(container,'dropdown',null,'show');
    	}
    }


    const KeyDownSelector = (e) =>{
        const container = GetAntecesorWhithClassName(e.target, 'custom-select-container');
        const ddown = GetSucesorWhithClassName(container, 'dropdown__radios');
        const input = primerHijoConClase(container, 'inputSelector');
       
        if (ddown.classList.contains('show')){
		    var keyCode = e.keyCode;
            var miItem;
            var offset = 160;

            if (keyCode === 27){
                ddown.classList.remove('show');
                input.value = '';
            }

            miItem = GetSucesorWhithClassName(ddown,'preselected');
            if (!miItem){
                miItem = GetSucesorWhithClassName(ddown,'active');
            }
            if (keyCode ===38){
                //UP
                if (miItem)
                    miItem = miItem.previousSibling;
                if (!miItem)
                    miItem = GetLastSucesorWhithClassName(ddown,'labeloption')
                while (miItem && miItem.classList.contains('hide')){
                    miItem = miItem.previousSibling;
                    if (!miItem){
                        miItem = GetLastSucesorWhithClassName(ddown,'labeloption')
                    }
                }
                if (miItem){
                    scrollSiNoVisible(ddown,miItem,offset);
                    RemoveClassNameToChildClassItems(ddown,'labeloption',null,'preselected');
                    miItem.classList.add('preselected');
                }
            }
            if (keyCode === 40){
                //DOWN
                if (miItem)
                    miItem = miItem.nextSibling;
                if (!miItem)
                    miItem = GetSucesorWhithClassName(ddown,'labeloption')
                while (miItem && miItem.classList.contains('hide')){
                    miItem = miItem.nextSibling;
                    if (!miItem){
                        miItem = GetSucesorWhithClassName(ddown,'labeloption')
                    }
                }
                if (miItem){
                    scrollSiNoVisible(ddown,miItem,offset);
                    RemoveClassNameToChildClassItems(ddown,'labeloption',null,'preselected');
                    miItem.classList.add('preselected');
                }
            }
            if (keyCode === 13){
                e.preventDefault();
                e.stopPropagation();
                miItem =  GetSucesorWhithClassName(ddown,'preselected')
                if (miItem){
                    SeleccionaOpcion(miItem);
                }
            }
        }
    }

    const BorraItem = (e) =>{
        var img = e.target;
        const tag = GetAntecesorWhithClassName(img,'tag');
        var value = tag.getAttribute("data-value");
        if (props.alBorrar){
            props.alBorrar(value);
        }
        
    }


    return (
        <div class="module module__filters" >
            <h3 class="title fs fs16 greyColor2" >{props.titulo}</h3>
            <div class="search search__ratios"  style={estiloModuleSelect}>
                <div class="selects" >
                    <div class="custom-select-container search style-radios target" id="custom-select-" onKeyDown={(e) => KeyDownSelector(e)}>
                        <div class="cover cover__search">
                            <img class="lupa" src="./img/ficha/lupaPurple.svg" />
                            <input type="text" placeholder={textPlaceholder} className="inputSelector" onKeyUp={(e) => KeyUpInput(e)}/>                        
                        </div>
                        <select class="custom-select" id={idHTML} style={{display:"none"}}  data-target='tags-1' >
                            {optionsHTML}
                        </select>
                        <div class={clasesCoverSelect} onClick={(e) => ClickEnCoverSelect(e)}>
                            <div class="select-label" >
                                {textSelected}
                            </div>
                        </div>
                        <div class="dropdown dropdown__radios">
                            {optionsDisplayed}
                        </div>
                    </div>
                </div>
            </div>
            <div class="tags" id="tags-1" >
                {HTMLTagSelected}
            </div>
        </div>
    );
}

export default SelectorUno;

