import { RemoveClassNameToClassItems, rootPath } from "../../../../../services/utils";
import Acordeon from "../Composicion/Acordeon";

const Video = (props) => {
    const video = props.video;
    const titulo = video.Titulo
    const url = video.URL.replace('watch?v=','embed/');

    const handleVideoClick = (e) =>{
        var item = e.target;
        var id = item.id;
        var idFrame = id.replace('video','iFrame');
        var idMask = id.replace('video','mask');
        var itemFrame = document.getElementById(idFrame);
        var itemMask = document.getElementById(idMask);
        itemFrame.width = item.width;
        itemFrame.height = item.height;
        item.style.display = 'none';
        itemMask.style.display = 'none';
        itemFrame.style.display = 'block';
        itemFrame.src += '?autoplay=1';
    }

    const contenido = (
        <div>
            <iframe className="iFrameVideo" id={"iFrame"+video.IdDocumento} width="424" height="238" src={url} style={{display:'none'}}></iframe>
            <div className="maskVideo" id={"mask"+video.IdDocumento} class="mask mask__video" >
                <img className="imgVideo" id={"video"+video.IdDocumento} src={rootPath + "img/video.jpg"} onClick={(e) =>  handleVideoClick(e)} />
            </div>
        </div>
    );

    return (
        <Acordeon titulo = {titulo} contenido={contenido} activo={props.abierto} tipo={1}></Acordeon>
    )
}
export default Video;