import { getDescripcionTipo } from "../../../services/utils";

const TituloFormularioBusqueda = (props) => {
    const imagen = (props.tipo === "V") ?
        "/img/ficha/dog.svg"
        : ((props.tipo==="PF")||(props.tipo==="F")||(props.tipo==="P")) ?
        "/img/ficha/paraf.png"
        : (props.codPagina==="FBDocumentos") ?
        "/img/ficha/alertas.svg"
        :
        "/img/ficha/human.svg";
    var textoGen = getDescripcionTipo(props.tipo, props.codPagina);
    if ((props.codPagina==="FBAFMUV")||(props.codPagina==="FBEspecieIndicacionMUV")||(props.codPagina==="FBActPPF")||(props.codPagina==="FBSaborEdadPPF")||(props.codPagina==="FBProductosIndicacionPPF"))
        textoGen="";

    return (
        <div class="contenedor__buscador--buscador">
            <div class="box box__busqueda" >
                <div class="mask" >
                    <img src={imagen} alt="" />
                </div>
                <div class="text" >
                    {(textoGen != "")?
                        <p class="fs fs20 fw fw400 blackColor" >{textoGen}: <strong>{props.titulo}</strong></p>
                    :
                        <p class="fs fs20 fw fw400 blackColor" ><strong>{props.titulo}</strong></p>
                    }
                </div>
            </div>
        </div>
    );
};

export default TituloFormularioBusqueda;
