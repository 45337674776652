import ResumenInfo from "./ResumenInfo";
import ZonaImagenYPictos from "./ZonaImagenYPictos";

const InfoPrincipalMUH = (props) => {
    return (
        <section class="medicamento-info">
            <div class="container flex flex--justify-center">
                <div class="contenido" style={{width:'100%'}}>
                    <ZonaImagenYPictos ficha = {props.ficha} clickImagen = {props.clickImagen}></ZonaImagenYPictos>
                    <ResumenInfo ficha = {props.ficha} cambiaPagina={props.cambiaPagina} abreEmergenteInfoPrecio={props.abreEmergenteInfoPrecio}></ResumenInfo>
                </div>
            </div>
        </section>
    )
}
export default InfoPrincipalMUH;
