import { useState } from "react";
import { AddClassNameToClassItems, PerfilUsuario, RemoveClassNameToClassItems } from "../../../../../services/utils";
import AvisoPriciosFuturos from "../../InfoPrincipal/AvisoPreciosFuturos";
import Emergente from "../Cismed/Emergente";
import BloqueNombreValor from "../InfoGeneral/BloqueNombreValor";
import AgrupacionHomogenea from "./AgrupacionHomogenea";

const Grupos = (props) => {
    const [msgAviso, setMsgAviso] = useState('');
    const [strPaginaTrasAviso, setStrPaginaTrasAviso] = useState('');
    const [idPaginaTrasAviso, setIdPaginaTrasAviso] = useState(0);
    
    var bloqueATC = [];
    var nombreGrupos = "ATC";
    var tipoReg = props.codTipoRegistro;
    if (props.nombreGrupos)
        nombreGrupos = props.nombreGrupos;
    var tituloGrupos = "Grupos terapéuticos ATC"
    if (nombreGrupos === "Con"){
        tituloGrupos = "Categorización Consejo General";
        tipoReg = "PPF"
    }
    var tipoRegLink = tipoReg;
    if ((tipoReg === "PO") || (tipoReg === "FM") || (tipoReg === "VI")){
        tipoRegLink = "MUH";
    }
    var link = "BGT" + nombreGrupos + tipoRegLink;
    
    for (var i=1; i<=5; i++)
    {
        var nombreCampo = "G" + nombreGrupos + "Nivel" + i.toString();
        if (props.datosGrupos[nombreCampo]){
            var titulo = "Nivel " + i.toString();
            var id = props.datosGrupos[nombreCampo].Codigo;
            var valor = props.datosGrupos[nombreCampo].Codigo + " " + props.datosGrupos[nombreCampo].Descripcion;
            bloqueATC.push({
                nombre: titulo,
                valor : valor,
                link : link,
                id : id
            })
        }
    }


    const CambiaPaginaBusquedaGrupo = (tipo, id) => {
        props.cambiaPagina({
            newpagina: tipo,
            newid: id,
        });
    }
    

    var bloqueVTM = [];
    /*const descClinica = ((props.datosGrupos.GVTM !== null && props.datosGrupos.GVTM!== undefined) 
                        || (props.datosGrupos.GTVMP !== null && props.datosGrupos.GTVMP !== undefined) 
                        || (props.datosGrupos.GTVMPP !== null && props.datosGrupos.GTVMPP !== undefined));*/
    const descClinica = (props.datosGrupos.GVTM || props.datosGrupos.GVMP ||props.datosGrupos.GVMPP)                        
    if (props.datosGrupos.GVTM){
        bloqueVTM.push(
            {
                nombre : 'Grupo terapéutico VTM',
                valor: props.datosGrupos.GVTM.Descripcion,
                link: 'BGTVMPPMUH',
                id: props.datosGrupos.GVTM.Codigo,
                aviso: (props.sustituible) ? false : (props.exceptSust) ? 'Se recuerda que este medicamento es sustituible por el farmacéutico de forma excepcional debido a problemas de suministro':'Se recuerda que este medicamento no es sustituible por el farmacéutico.',
            },
        )
    }

    if (props.datosGrupos.GVMP){
        bloqueVTM.push(
            {
                nombre : 'Grupo terapéutico VMP',
                valor: props.datosGrupos.GVMP.Descripcion,
                link: 'BGTVMPPMUH',
                id: props.datosGrupos.GVMP.Codigo,
                aviso: (props.sustituible) ? false : (props.exceptSust) ? 'Se recuerda que este medicamento es sustituible por el farmacéutico de forma excepcional debido a problemas de suministro':'Se recuerda que este medicamento no es sustituible por el farmacéutico.',
            },
        )
    }

    if (props.datosGrupos.GVMPP){
        bloqueVTM.push(
            {
                nombre : 'Grupo terapéutico VMPP',
                valor: props.datosGrupos.GVMPP.Descripcion,
                link: 'BGTVMPPMUH',
                id: props.datosGrupos.GVMPP.Codigo,
                aviso: (props.sustituible) ? false : (props.exceptSust) ? 'Se recuerda que este medicamento es sustituible por el farmacéutico de forma excepcional debido a problemas de suministro':'Se recuerda que este medicamento no es sustituible por el farmacéutico.',
            },
        )
    }

    const estiloSinBorde = {borderBottom: 'none'};
    const estiloGTATC = (descClinica) ? undefined : estiloSinBorde;

    const abreEmergenteAviso = (aviso,pagina,id) => {
        AddClassNameToClassItems(modalKeyName, null, 'active');
        setMsgAviso(aviso);
        setStrPaginaTrasAviso(pagina);
        setIdPaginaTrasAviso(id);
    }

    const cierraEmergenteAviso = () => {
        RemoveClassNameToClassItems(modalKeyName, null, 'active');
    }

    const CambiaPaginaPorId = (pagina, id) => {
        props.cambiaPagina({
            newpagina: pagina,
            newid: id,
        });
    }

    const modalTitulo = 'Recuerde';
    const modalContent = (
        <div>
            <p>
                {msgAviso}
            </p>
            <div className="cover cover__buttons" style={{ display:'flex', justifyContent: 'space-around', marginTop:'30px'}}>
                <button class="button button__arrow button__purple noUppercase" title="Guardar" onClick={e => CambiaPaginaPorId(strPaginaTrasAviso, idPaginaTrasAviso)} style={{marginRight:'10px'}}>Aceptar</button>
                <button class="button button__arrow button__grey noUppercase" title="Salir" onClick={e => cierraEmergenteAviso()} style={{marginLeft:'10px'}}>Cancelar</button>
            </div>
        </div>
    );

    const modalKeyName="ModalAvisoSust"

    const hayPFuturos = props.pFuturos && ((props.pFuturos.AgrupacionNacional && (props.pFuturos.AgrupacionNacional.PVPMenor !== 0 || props.pFuturos.AgrupacionNacional.PVPMasBajo !== 0))
                                            ||(props.pFuturos.AgrupacionCCAA && (props.pFuturos.AgrupacionCCAA.PVPMenor !== 0 || props.pFuturos.AgrupacionCCAA.PVPMasBajo !== 0)))

    const hayAgrupAuto = ((props.agrupCCAA && props.agrupCCAA.Codigo) || (props.pFuturos && (props.pFuturos.AgrupacionCCAA && props.pFuturos.AgrupacionCCAA.Codigo)))
    const hayAgrupNac = ((props.agrupNac && props.agrupNac.Codigo) || (props.pFuturos && (props.pFuturos.AgrupacionNacional && props.pFuturos.AgrupacionNacional.Codigo)))

    const pFuturosHTML = ((hayPFuturos && PerfilUsuario()!=="EST")?
        <div class="module__infoColumns" style={{borderBottom: 'none', paddingBottom: '0px', paddingTop : '10px'}}> 
                <AvisoPriciosFuturos fecha={props.pFuturos.Fecha}></AvisoPriciosFuturos> 
        </div> :
        <> </>
    );

    var estiloPrimerGrupo = (PerfilUsuario()!=="EST") ? {borderBottom: 'none'} : {borderBottom: 'none', borderTop: 'none'};
    var estiloGrupoGT = props.datosGrupos.GNomenclator || PerfilUsuario()!=="EST" ? {borderBottom: 'none'} : {borderBottom: 'none', borderTop: 'none'}


    return (
        <div class="infoContent" >
            <Emergente contenido={modalContent} keyName={modalKeyName} titulo={modalTitulo} alto='220px'></Emergente>
            {pFuturosHTML}
            {hayAgrupAuto && PerfilUsuario()!=="EST" ?
                <AgrupacionHomogenea
                    cambiaPagina={props.cambiaPagina} 
                    agrup = {props.agrupCCAA} 
                    agrupFutura={props.pFuturos ? props.pFuturos.AgrupacionCCAA : null}
                    ccaa = {props.ccaa}
                    tipoReg = {tipoRegLink}
                    noBordeSup = {hayPFuturos}
                    fecha = {props.pFuturos ? props.pFuturos.Fecha : null}
                    mostrarPExtra = {props.mostrarPExtra}>
                </AgrupacionHomogenea>
            :
                <></>
            }
            {hayAgrupNac && PerfilUsuario()!=="EST"?
                    <AgrupacionHomogenea
                        cambiaPagina={props.cambiaPagina} 
                        agrup = {props.agrupNac} 
                        agrupFutura={props.pFuturos ? props.pFuturos.AgrupacionNacional : null}
                        ccaa = {(tipoReg === "PPF") ? 'mutualidades' : 'nacional'}
                        noBordeSup = {!hayAgrupAuto && hayPFuturos}
                        fecha = {props.pFuturos ? props.pFuturos.Fecha : null}
                        tipoReg = {tipoRegLink}>
                    </AgrupacionHomogenea>
                :
                    <></>
            }

                {props.datosGrupos.GNomenclator ?
                    <div class="module module__infoColumns" style={estiloPrimerGrupo}> 
                        <div class="module" style={{borderBottom: 'none'}}> 
                            <p>&nbsp;</p>
                            <div class="text" >
                                <h3 class="fs fs16 fw fw600 greyColor2 title" >Clasificación Nomenclátor</h3>
                                <p class="fs fs14 fw fw400 greenColor4 uppercase" 
                                    style={{cursor:'pointer', color:'#5C9029'}} 
                                    onClick={(e) => CambiaPaginaBusquedaGrupo('BGTSanPPF',props.datosGrupos.GNomenclator.Codigo)}
                                    onMouseOver={(e) => e.target.style.fontWeight='bold'}
                                    onMouseOut={(e) => e.target.style.fontWeight='normal'}>
                                    {props.datosGrupos.GNomenclator.Codigo}&nbsp;{props.datosGrupos.GNomenclator.Descripcion}
                                </p>
                            </div>
                        </div>
                    </div>
                :
                    <></>
                }
    
            <div class="module module__infoColumns" style={estiloGrupoGT}> 
                <div class="text" >
                    <h3 class="fs fs16 fw fw600 greyColor2 title" >{tituloGrupos}</h3>
                </div>
                <BloqueNombreValor bloque={bloqueATC} sonValoresNumericos={false} cambiaPagina={props.cambiaPagina} claseBloque={'bloque2'} estiloEspecial = {estiloGTATC}></BloqueNombreValor>
                
                { (descClinica) ?
                    <div class="module" style={{borderBottom: 'none'}}> 
                        <p>&nbsp;</p>
                        <div class="text" >
                            <h3 class="fs fs16 fw fw600 greyColor2 title" >Descripciones clínicas AEMPS</h3>
                        </div>
                        <BloqueNombreValor bloque={bloqueVTM} sonValoresNumericos={false} cambiaPagina={props.cambiaPagina} muestraAviso={abreEmergenteAviso} claseBloque={'bloque3'} estiloEspecial = {estiloSinBorde}></BloqueNombreValor>
                    </div> 
                :
                    <div></div>
                }
            </div>
        </div>
    )
}
export default Grupos;
 
    /*if (props.datosGrupos["GATCNivel1"]){
        bloqueATC.push(
            {
                nombre : 'Nivel 1',
                valor: props.datosGrupos.GATCNivel1.Codigo + ' ' + props.datosGrupos.GATCNivel1.Descripcion,
                link: 'BGATC',
                id: props.datosGrupos.GATCNivel1.Codigo
            },
        )
    }
    if (props.datosGrupos.GATCNivel2){
        bloqueATC.push(
            {
                nombre : 'Nivel 2',
                valor: props.datosGrupos.GATCNivel2.Codigo + ' ' + props.datosGrupos.GATCNivel2.Descripcion,
                link: 'BGATC',
                id: props.datosGrupos.GATCNivel2.Codigo
            },
        )
    }
    if (props.datosGrupos.GATCNivel3){
        bloqueATC.push(
            {
                nombre : 'Nivel 3',
                valor: props.datosGrupos.GATCNivel3.Codigo + ' ' + props.datosGrupos.GATCNivel3.Descripcion,
                link: 'BGATC',
                id: props.datosGrupos.GATCNivel3.Codigo
            },
        )
    }
    if (props.datosGrupos.GATCNivel4){
        bloqueATC.push(
            {
                nombre : 'Nivel 4',
                valor: props.datosGrupos.GATCNivel4.Codigo + ' ' + props.datosGrupos.GATCNivel4.Descripcion,
                link: 'BGATC',
                id: props.datosGrupos.GATCNivel4.Codigo
            },
        )
    }
    if (props.datosGrupos.GATCNivel5){
        bloqueATC.push(
            {
                nombre : 'Nivel 5',
                valor: props.datosGrupos.GATCNivel5.Codigo + ' ' + props.datosGrupos.GATCNivel5.Descripcion,
                link: 'BGATC',
                id: props.datosGrupos.GATCNivel5.Codigo
            },
        )
    }*/
