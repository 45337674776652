import React, { useState, useEffect  } from "react";
import CabeceraResultados from "../../Resultados/CabeceraResultados/CabeceraResultados";
import BP2022Service from "../../../services/user.service";
import InfoPrincipalMUH from "../FichaMUH/InfoPrincipal/InfoPrincipalMUH.js";
import MenuIzquierda from "../FichaMUH/MenuIzquierda/MenuIzquierda";
import ContenedorApartados from "../FichaMUH/Apartados/ContenedorApartados";
import MargenDerecho from "../FichaMUH/MargenDerecho/MargenDerecho";
import MensajesEmergentes from "../FichaMUH/MensajesEmergentes";
import { AnadeApartado, baseURLPictogramas, compruebaAnclas, RemoveClassNameToClassItems, rootPath, getParam, anadeAncla  } from "../../../services/utils";
import LoadingPopup from "../../Resultados/Loading.Popup";
import MargenDerechoPA from "./MargenDerechoPA";
import Slider from "react-slick";
import {createBrowserHistory} from 'history';


const FichaPA = (props) => {
    const id = props.infoPagina.id;
    const tipo = props.tipo;
    const [ficha, setFicha] = useState(null);
    const [anclas, setAnclas] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [muestraAmpliada, setMuestraAmpliada] = useState(false);
    const [sliderRef2, setSliderRef2] = useState(null)
    const [anclasEpis, setAnclasEpis] = useState([]);
    const history = createBrowserHistory();
    const sEpigrafes = getParam(history.location.search, 'epigrafes');
    const epigrafesActivos = sEpigrafes ? sEpigrafes.split(",").map(Number) : [];
    const [seHaHechoScroll, SetSeHaHechoScroll] = useState(false);
    

    const sliderSettings2 = {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        speed: 300,
        arrows: true,
        touchMove: true,
        accessibility: true,
        adaptativeHeight: true,
        dots: false,
        focusOnSelect: true,
        className: 'single-item slide__2',
    }

    const clickImagen = (e) => {
        setMuestraAmpliada(true);
    }

    const clickCierra = (e) => {
        setMuestraAmpliada(false);
    }

    const loadImageGrande = (e) => {
        var h = e.target.height;
        var w = e.target.width;
        var maxHeightG = window.innerHeight * 0.65;
        var maxWidthG = window.innerWidth * 0.65;
        if (h>=maxHeightG){
            e.target.style.height = maxHeightG.toString() + 'px';
            e.target.style.width = 'auto';
            e.target.style.maxWidth = maxWidthG.toString() + 'px';
            e.target.style.maxHeight = maxHeightG.toString() + 'px';
        }
        else if (w>=maxWidthG){
            e.target.style.height = 'auto';
            e.target.style.width = maxWidthG.toString() + 'px';
            e.target.style.maxWidth = maxWidthG.toString() + 'px';
            e.target.style.maxHeight = maxHeightG.toString() + 'px';
        }
    }

    const estiloImagenFlotante = {verticalAlign:'middle',width:'70%',alignContent:'center',maxHeight:'70%'}
    
    if (ficha && ((ficha.CodTipoRegistro==="PAV")||(ficha.CodTipoRegistro==="PAH"))){
        estiloImagenFlotante.backgroundColor = '#FFFFFF'
    }

    const imgDefecto = (ficha) ? 
        ((ficha.CodTipoRegistro === "MUV") || (ficha.CodTipoRegistro==="PAV") || (ficha.CodTipoRegistro==="ASOV")) ? 
        rootPath + "img/perro.svg" : 
        ((ficha.CodTipoRegistro==="PPF") || (ficha.CodTipoRegistro==="ING")) ? 
        rootPath + "img/jabonLiquido.svg" : 
        ((ficha.CodTipoRegistro==="PAT") || (ficha.CodTipoRegistro==="PATH")) ? 
        rootPath + "img/patHumana.svg" : 
        (ficha.CodTipoRegistro==="PATV") ? 
        rootPath + "img/patVet.svg" : 
        rootPath + "img/person.svg" 
    :
        undefined
    ;

    const srcImagen = (ficha) ?
        ((ficha.ImagenEnvase) ?
            (ficha.ImagenEnvase.URLFull != null ?
                ficha.ImagenEnvase.URLFull
            : 
                ficha.ImagenEnvase.URL
            )
        :
            ((ficha.ImagenFormula) ?
                ficha.ImagenFormula.URL
            :
                imgDefecto
            )
        )
    :
        undefined
    ;

    const srcImagenFF = (ficha) ?
        ((ficha.CodTipoRegistro === "PPF") ?
            ((ficha.ImagenMatAcond) ?
                (ficha.ImagenMatAcond.URLFull != null ?
                    ficha.ImagenMatAcond.URLFull
                : 
                    ficha.ImagenMatAcond.URL
                )
            :
                ''
            )
        :
            ((ficha.ImagenFormaF) ?
                (ficha.ImagenFormaF.URLFull != null ?
                    ficha.ImagenFormaF.URLFull
                : 
                    ficha.ImagenFormaF.URL
                )
            :
                ''
            )
        )
    :
        undefined
    ;

    const tieneImagenes = (srcImagen && srcImagen !== '') || (srcImagenFF && srcImagenFF !== '');    
    const arrayImgs = [];
    if (srcImagen && srcImagen != '')
        arrayImgs.push(srcImagen);
    if (srcImagenFF && srcImagenFF != '')
        arrayImgs.push(srcImagenFF);

    const imagenesHTML = tieneImagenes ? arrayImgs.map(srcImg => <div><img class="imagen__medicamento" src={srcImg} alt={srcImg} onLoad={(e) => loadImageGrande(e)}/></div>)
                                        : <div><p>&nbsp;</p><img class="imagen__medicamento" src={imgDefecto} /></div>


    useEffect(() => {
        if ((ficha==null) || (ficha.Id !== id))
        {
            descargaFicha();
        }        
        else if (refresh==1){
            (ficha.CodTipoRegistro === "PHO") ?
                setRefresh(2)
            :
                descargaMensajesEmergentes(ficha.Id);
        }
        else if (refresh==2){
            descargaTextos(ficha.Id);
        }
        else if (refresh==3){
            descargaAdvertencias(ficha.Id);
        }
        else if (refresh==4){
            (ficha.CodTipoRegistro === "PHO") ?
                setRefresh(10)
            :
                descargaPrecaucionExtrema(ficha.Id);
        }
        else if (refresh==5){
            if (tipo==="H")
                descargaNombresSimilares(ficha.Id)
            else
                setRefresh(prev => prev+1);
        }
        else if (refresh==6){
            if (tipo==="H")
                descargaInterferencias(ficha.Id);
            else
                setRefresh(prev => prev+1);
        }
        else if (refresh==7){
            if (tipo==="H")
                descargaFarmacogenetica(ficha.Id)
            else
                setRefresh(prev => prev+1);
        }
        else if (refresh==8){
            descargaHistorico(ficha.Id)
        }
        else if (refresh==9){
            if (tipo==="V")
                descargaUsoAnimalesProductores(ficha.Id)
            else
                setRefresh(prev => prev+1);
        }
        else if (refresh==10){
            descargaInformacionAdicional(ficha.Id)
        }
        else if (refresh==11){
            descargaVideos(ficha.Id)
        }
        else if (refresh == 12)
        {
            
            //setRefresh(prev => prev + 1);
        }
        if (epigrafesActivos && epigrafesActivos.length>0 && !seHaHechoScroll && anclasEpis && anclasEpis.length>0){
            var currentAncla;
            for (var i=0; i<epigrafesActivos.length && !currentAncla; i++){
                currentAncla = anclasEpis.find(ancla => ancla.idAncla === "Epi" + epigrafesActivos[i].toString())
            }
            if (currentAncla){
                const linkRef = currentAncla.refAncla;
                if (linkRef && linkRef.current){
                    linkRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                    SetSeHaHechoScroll(true);
                }
            }            
        }
    })
    const descargaFicha = () => {
        BP2022Service.getFichaPA(id, props.tipo)
        .then((response) => {
            RemoveClassNameToClassItems('lightbox__popup',null,'active');
            //checkAnclas(response.data.Apartados);
            var auxFicha = response.data;
            compruebaAnclas(auxFicha.Apartados,anclas);
            //auxFicha.CodTipoRegistro="PA" + tipo;

            if (auxFicha.CodTipoRegistro === "PAV")
                auxFicha.TipoRegistro="Principio activo veterinario";
            else if (auxFicha.CodTipoRegistro === "PHO")
                auxFicha.TipoRegistro="Principio activo homeopático de uso humano";
            else
            auxFicha.TipoRegistro="Principio activo de uso humano";
            setFicha(auxFicha);
            setRefresh(1);
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
    
    const descargaMensajesEmergentes = (miId) => {
        BP2022Service.getMensajesEmergentesPAH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    miFicha.MensajesEmergentes = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaTextos = (miId) => {
        BP2022Service.getTextosPA (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Epigrafes){
                    if (response.data.Epigrafes.length>0){
                        AnadeApartado({Titulo: "Información farmacológica",  Ancla:"TEXT"},miFicha.Apartados,anclas);
                        for (var i=0; i<response.data.Epigrafes.length; i++){
                            anadeAncla("Epi"+response.data.Epigrafes[i].IdEpigrafe,anclasEpis);                            
                        }
                        miFicha.DatosTextos = response.data;
                    }
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
    
    const descargaAdvertencias = (miId) => {
        
        BP2022Service.getAdvertenciasPA (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Advertencias){
                    if (response.data.Advertencias.length>0){
                        AnadeApartado({Titulo: "Advertencias",  Ancla:"ADV"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosAdvertencias = response.data;
                    }
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
   
    const descargaNombresSimilares = (miId) => {
        BP2022Service.getNombresSimilaresPAH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Confusiones de nombre",  Ancla:"SIMIL"},miFicha.Apartados,anclas);
                    miFicha.NombresSimilares = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaPrecaucionExtrema = (miId) => {
        BP2022Service.getPrecaucionExtremaPAH (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Advertencias){
                    if (response.data.Advertencias.length>0){
                        AnadeApartado({Titulo: "Precaución extrema de uso",  Ancla:"PEXT"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosPrecaucionExtrema = response.data;
                    }
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaInterferencias = (miId) => {
        BP2022Service.getInterferenciasPA (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Análisis clínicos",  Ancla:"ANA"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.Interferencias = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };
 
    const descargaFarmacogenetica = (miId) => {
        BP2022Service.getFarmacogeneticaPA (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Biomarcadores){
                    if (response.data.Biomarcadores.length>0){
                        AnadeApartado({Titulo: "Farmacogenética",  Ancla:"FGENPA"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.Farmacogenetica = response.data;
                    }
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };


    const descargaHistorico = (miId) => {
        BP2022Service.getHistoricoPA (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Histórico",  Ancla:"HIST"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.DatosHistorico = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };


    const descargaInformacionAdicional = (miId) => {
        BP2022Service.getInformacionAdicionalPA (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Información adicional",  Ancla:"DOCS"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.DatosInfoAdicional = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaVideos = (miId) => {
        BP2022Service.getVideosPA (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Documentos){
                    if (response.data.Documentos.length>0){
                        AnadeApartado({Titulo: "Vídeos",  Ancla:"VID"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosVideos = response.data;
                    }
                }
                props.descargaHistorial();
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaDopaje = (miId) => {
        BP2022Service.getDopajeInfoPA (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.Textos){
                    if (response.data.Textos.length>0){
                        AnadeApartado({Titulo: "Dopaje",  Ancla:"DOP"},miFicha.Apartados,anclas);
                        //checkAnclas(miFicha.Apartados);
                        miFicha.DatosDopaje = response.data;
                    }
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaSinonimias = (miId) => {
        BP2022Service.getSinonimiasPA (miId)
        .then((response) => {
            if (ficha != null){
                var miFicha = ficha;
                if (response.data.length>0){
                    AnadeApartado({Titulo: "Sinonimias",  Ancla:"SINO"},miFicha.Apartados,anclas);
                    //checkAnclas(miFicha.Apartados);
                    miFicha.Sinonimias = response.data;
                }
                setRefresh(prev => prev +1);
            }
        })
        .catch(function (error) {
            console.log('Error', error.message);
        });
    };

    const descargaUsoAnimalesProductores = (miId) => {
        if (tipo==="V"){
            BP2022Service.getProductoresInfoPA (miId)
            .then((response) => {
                if (ficha != null){
                    var miFicha = ficha;
                    if (response.data.LMRsEspecies){
                        if (response.data.LMRsEspecies.length>0){
                            AnadeApartado({Titulo: "Límites máximos de residuos",  Ancla:"UAP"},miFicha.Apartados,anclas);
                            //checkAnclas(miFicha.Apartados);
                            miFicha.DatosAnimalesProductores = response.data;
                        }
                    }
                    setRefresh(prev => prev +1);
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
        }
    };

    

    /*const checkAnclas = (aptdos) => {
        for (var i=0; i<aptdos.length; i++){
            const idAncla = aptdos[i].Ancla;
            if (!anclas.find(ancla => ancla.idAncla===idAncla))
            {
                const anclaNueva = createRef();
                anclas.push({idAncla: idAncla, refAncla : anclaNueva});
            }
        }
    }*/

     
    if ((ficha !== null) && (ficha.Id === id))
    {
        return (
            <div>
                <CabeceraResultados infoPagina={props.infoPagina} cambiaPagina={props.cambiaPagina} atras={props.atras}></CabeceraResultados>
                {(muestraAmpliada) ? 
                    <div className="medicamento-info"><div className="contenido__imagen"><div className="imagen">
                    <div class="imagen-light show" style={{cursor: 'default'}} >
                        <div class="btn__cerrar" onClick={(e) => clickCierra(e)}  style={{cursor: 'pointer'}}>
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="22.512" cy="22.512" r="22.012" stroke="white" />
                                <path d="M22.5793 12.0959V32.2559" stroke="white" stroke-width="2" stroke-linecap="square" />
                                <path d="M32.2562 22.5791H12.0962" stroke="white" stroke-width="2" stroke-linecap="square" />
                            </svg>
                        </div>
                        <div style={estiloImagenFlotante} id="ContenedorImagenFlotante">
                            <Slider ref={sliderRef2} {...sliderSettings2}>
                                {imagenesHTML}
                            </Slider>
                        </div>
                    </div>
                    </div></div></div>
                :
                    <></>
                }
                <InfoPrincipalMUH ficha={ficha} cambiaPagina={props.cambiaPagina}  clickImagen = {clickImagen}></InfoPrincipalMUH>
                {
                    (ficha.MensajesEmergentes && ficha.MensajesEmergentes.length>0) ?
                        <MensajesEmergentes mensajes={ficha.MensajesEmergentes} id={ficha.Id}></MensajesEmergentes>
                    :
                        <div></div>
                }
                <section class="medicamento-infoGeneral">
                    <div class="container flex flex--justify-center">
                        <div class="contenido f-f-Flama-Book">
                            {(ficha.Apartados && ficha.Apartados.length>0) ?
                                <MenuIzquierda apartados={ficha.Apartados} activo={ficha.Apartados[0] ? ficha.Apartados[0].Ancla : ""} anclas={anclas}></MenuIzquierda>
                            :
                                <div style={{width:'22%'}}></div>
                            }    

                            <ContenedorApartados cambiaPagina={props.cambiaPagina} ficha={ficha} anclas={anclas}
                                epigrafesActivos = {epigrafesActivos}
                                anclasEpis = {anclasEpis}>
                            </ContenedorApartados> 
                            <MargenDerechoPA cambiaPagina={props.cambiaPagina} ficha={ficha} clickImagen = {clickImagen}></MargenDerechoPA>

                        </div>
                    </div>
                </section>
            </div>
        )
    }
    else 
        return (
            <div>
                <LoadingPopup></LoadingPopup>
            </div>
        )
}
export default FichaPA;
